/**
 * @generated SignedSource<<c8e9374f44e7c3255c2de32b3e3e4996>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdatePointPoolInput = {
  data: PointPoolInput;
  id: string;
  clientMutationId?: string | null;
};
export type PointPoolInput = {
  name: string;
  triggersExport: boolean;
  descriptionOpt?: string | null;
  clientMutationId?: string | null;
};
export type EditPointPoolScreen_UpdatePointPoolMutation$variables = {
  input: UpdatePointPoolInput;
};
export type EditPointPoolScreen_UpdatePointPoolMutationVariables = EditPointPoolScreen_UpdatePointPoolMutation$variables;
export type EditPointPoolScreen_UpdatePointPoolMutation$data = {
  readonly Admin: {
    readonly Points: {
      readonly updatePointPool: {
        readonly data: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"EditPointPoolScreen_PointPoolFragment">;
          };
        };
      } | null;
    };
  };
};
export type EditPointPoolScreen_UpdatePointPoolMutationResponse = EditPointPoolScreen_UpdatePointPoolMutation$data;
export type EditPointPoolScreen_UpdatePointPoolMutation = {
  variables: EditPointPoolScreen_UpdatePointPoolMutationVariables;
  response: EditPointPoolScreen_UpdatePointPoolMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPointPoolScreen_UpdatePointPoolMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PointsAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Points",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdatePointPoolPayload",
                "kind": "LinkedField",
                "name": "updatePointPool",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PointsPoolsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PointPool",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditPointPoolScreen_PointPoolFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditPointPoolScreen_UpdatePointPoolMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PointsAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Points",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdatePointPoolPayload",
                "kind": "LinkedField",
                "name": "updatePointPool",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PointsPoolsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PointPool",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "triggersExport",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "63e26bd6ce3940caf45fb449e7f34628",
    "id": null,
    "metadata": {},
    "name": "EditPointPoolScreen_UpdatePointPoolMutation",
    "operationKind": "mutation",
    "text": "mutation EditPointPoolScreen_UpdatePointPoolMutation(\n  $input: UpdatePointPoolInput!\n) {\n  Admin {\n    Points {\n      updatePointPool(input: $input) {\n        data {\n          node {\n            ...EditPointPoolScreen_PointPoolFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditPointPoolScreen_PointPoolFragment on PointPool {\n  id\n  name\n  description\n  triggersExport\n}\n"
  }
};
})();

(node as any).hash = "efc1b2e348e128c61f57fa72c8d6b226";

export default node;

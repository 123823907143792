import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dropdown } from "primereact/dropdown";
import React from "react";
import { Message } from "primereact/message";
import { EmailRewardConfigurationForm_TreeNodeFragment$key } from "../../../../__generated__/EmailRewardConfigurationForm_TreeNodeFragment.graphql";
import { EmailRewardConfigurationForm_EmailRewardConfigurationV2Fragment$key } from "../../../../__generated__/EmailRewardConfigurationForm_EmailRewardConfigurationV2Fragment.graphql";
import { EmailRewardConfigurationForm_EditEmailRewardV2Mutation } from "../../../../__generated__/EmailRewardConfigurationForm_EditEmailRewardV2Mutation.graphql";
import {
	DefaultSwitchComponent,
	DefaultTextAreaComponent,
	DefaultTextFieldComponent,
} from "../../../../infecto-lms-admin/components/core/form/DefaultTextInput";
import { ValidatedField } from "../../../../infecto-lms-admin/components/core/form/ValidatedField";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../../lms-admin-impl/i18n/tree.i18n";
import { useTypedDispatch } from "../../../../infecto-lms-admin/redux/Store";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../../infecto-lms-admin/redux/slices/CoreSlice";

const EMAIL_REWARD_CONFIGURATION_FRAGMENT = graphql`
	fragment EmailRewardConfigurationForm_EmailRewardConfigurationV2Fragment on EmailRewardConfiguration {
		id
		subject
		body
		previewText
		recipientType
		attachCertificates
	}
`;

const TREE_NODE_FRAGMENT = graphql`
	fragment EmailRewardConfigurationForm_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ContentTypeDefinition {
				rewards {
					id
					... on PointsRewardConfiguration {
						pointPool {
							name
						}
					}
				}
			}
		}
	}
`;

const EDIT_EMAIL_REWARD_MUTATION = graphql`
	mutation EmailRewardConfigurationForm_EditEmailRewardV2Mutation(
		$input: EditEmailRewardV2Input!
	) {
		Admin {
			Tree {
				editEmailReward(input: $input) {
					contentNode {
						...RewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface FormState {
	subject: string;
	body: string;
	previewText: string;
	recipientType: "admin" | "user";
	attachCertificates: boolean;
}

interface OwnProps {
	emailRewardConfigurationFragmentRef: EmailRewardConfigurationForm_EmailRewardConfigurationV2Fragment$key;
	treeNodeFragmentId: EmailRewardConfigurationForm_TreeNodeFragment$key;
	onBack: () => void;
}

export const EmailRewardConfigurationForm = ({
	emailRewardConfigurationFragmentRef,
	treeNodeFragmentId,
	onBack,
}: OwnProps) => {
	const node = useFragment<EmailRewardConfigurationForm_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentId,
	);
	const eMailRewardConfiguration =
		useFragment<EmailRewardConfigurationForm_EmailRewardConfigurationV2Fragment$key>(
			EMAIL_REWARD_CONFIGURATION_FRAGMENT,
			emailRewardConfigurationFragmentRef,
		);
	const [editEMailReward, isEditingEMailReward] =
		useMutation<EmailRewardConfigurationForm_EditEmailRewardV2Mutation>(
			EDIT_EMAIL_REWARD_MUTATION,
		);
	const formId = "EmailRewardConfigurationForm";
	const dispatch = useTypedDispatch();
	const formik = useFormik<FormState>({
		enableReinitialize: true,
		initialValues: {
			subject: eMailRewardConfiguration.subject,
			body: eMailRewardConfiguration.body,
			previewText: eMailRewardConfiguration.previewText,
			recipientType: eMailRewardConfiguration.recipientType as "admin" | "user",
			attachCertificates: eMailRewardConfiguration.attachCertificates,
		},
		validationSchema: Yup.object().shape({
			subject: Yup.string().required("Das Feld Betreff wird benötigt."),
			body: Yup.string().required("Das Feld Text wird benötigt."),
			recipientType: Yup.string().required("Das Feld Empfänger wird benötigt."),
		}),
		onSubmit: (values, formikHelpers) => {
			editEMailReward({
				variables: {
					input: {
						contentNodeId: node.id,
						rewardId: eMailRewardConfiguration.id,
						subject: values.subject,
						previewText: values.previewText,
						recipientType: values.recipientType,
						body: values.body,
						attachCertificates: values.attachCertificates,
					},
				},
				onCompleted: () => {
					formikHelpers.setTouched({});
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, string>
				name={"subject"}
				label={"Betreff"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
			<ValidatedField<FormState, string>
				name={"previewText"}
				label={"Vorschautext"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>

			<ValidatedField<FormState, string>
				name={"recipientType"}
				label={"Empfänger"}
				component={({ fieldName, fieldValue, updateField }) => (
					<div>
						<Dropdown
							name={fieldName}
							value={fieldValue}
							options={[
								{
									label: "E-Mail an Benutzer",
									value: "user",
								},
								{
									label: "E-Mail an Admin",
									value: "admin",
								},
							]}
							onChange={(e) => {
								updateField(e.value);
								dispatch(addEditedFormToEditedFormsArray({ form: formId! }));
							}}
						/>
					</div>
				)}
				formikConfig={formik}
			/>

			<ValidatedField<FormState, string>
				name={"body"}
				label={"Text"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
				component={DefaultTextAreaComponent}
				formikConfig={formik}
			/>

			<ValidatedField<FormState, boolean>
				name={"attachCertificates"}
				label={"Zertifikate anhängen"}
				helpText={`Wenn der Benutzer für ${TREE_I18N_MAP(
					TREE_I18N_KEY.thisContentNode,
				)} Zertifikate als Belohnung bekommt, dann werden diese als Anhang an diese E-Mail angehangen.`}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
				component={DefaultSwitchComponent}
				formikConfig={formik}
			/>

			<Message
				text={
					<div>
						Es können folgende Variablen in den Feldern der E-Mail verwendet werden.
						Diese werden dann, wenn die E-Mail versendet wird, automatisch ausgetauscht:
						<ul>
							<li>%%RECIPIENT_NAME%% - Name des Empfängers der E-Mail</li>
							{node.typeDefinition.rewards?.map((rc) => {
								if (rc.pointPool?.name) {
									return (
										<li key={rc.id}>
											%%
											{rc.pointPool?.name
												.trim()
												.replace(" ", "_")
												.toUpperCase()}
											%% - Anzahl der Punkte im Punkte-Konto{" "}
											{rc.pointPool?.name} die{" "}
											{TREE_I18N_MAP(TREE_I18N_KEY.inThisContentNode)} als
											Belohung vergeben worden sind.
										</li>
									);
								} else {
									return null;
								}
							})}
						</ul>
					</div>
				}
			/>

			<Button
				disabled={Object.entries(formik.touched).length === 0 || isEditingEMailReward}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};

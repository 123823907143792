import { graphql } from "babel-plugin-relay/macro";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { readInlineData, useFragment } from "react-relay";
import { ContentSubmissionsTable_QueryFragment$key } from "../../../../__generated__/ContentSubmissionsTable_QueryFragment.graphql";
import { StartedAtColumn } from "./StartedAtColumn";
import { LastUpdatedAtColumn } from "./LastUpdatedAtColumn";
import { RootNodeTitleColumn } from "./RootNodeTitleColumn";
import { StatusColumn } from "./StatusColumn";
import { ProjectRewardsColumn } from "../../../../lms-admin-impl/components/relay/ProjectRewardsColumn";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../../lms-admin-impl/i18n/tree.i18n";

const QUERY_FRAGMENT = graphql`
	fragment ContentSubmissionsTable_QueryFragment on Query
	@argumentDefinitions(treeStateId: { type: "ID!" }) {
		Admin {
			LearnV2 {
				GetContentSubmissions(first: 100, treeStateId: $treeStateId) {
					edges {
						node {
							...ContentSubmissionsTable_ContentSubmissionFragment
						}
					}
				}
			}
		}
	}
`;

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment ContentSubmissionsTable_ContentSubmissionFragment on ContentSubmission @inline {
		...LastUpdatedAtColumn_ContentSubmissionFragment
		...StartedAtColumn_ContentSubmissionFragment
		...ProjectRewardsColumn_ContentSubmissionFragment
		...RootNodeTitleColumn_ContentSubmissionFragment
		...StatusColumn_ContentSubmissionFragment
	}
`;

interface OwnProps {
	queryFragmentRef: ContentSubmissionsTable_QueryFragment$key;
}

export const ContentSubmissionsTable = ({ queryFragmentRef }: OwnProps) => {
	const data = useFragment<ContentSubmissionsTable_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);

	const contentSubmissions =
		data.Admin.LearnV2.GetContentSubmissions.edges
			?.filter((e) => !!e?.node)
			.map((e) => e!.node) || [];

	return (
		<DataTable
			className="mb-3"
			value={contentSubmissions.map((item: any) =>
				readInlineData(CONTENT_SUBMISSION_FRAGMENT, item),
			)}
		>
			<Column
				header="Erstellt"
				body={(item) => <StartedAtColumn contentSubmissionFragmentRef={item} />}
			/>
			<Column
				header="Letztes Update"
				body={(item) => <LastUpdatedAtColumn contentSubmissionFragmentRef={item} />}
			/>
			<Column
				header={TREE_I18N_MAP(TREE_I18N_KEY.tree)}
				body={(item) => <RootNodeTitleColumn contentSubmissionFragmentRef={item} />}
			/>
			<Column
				header="Status"
				body={(item) => <StatusColumn contentSubmissionFragmentRef={item} />}
			/>
			<Column
				header="Belohnungen"
				body={(item) => <ProjectRewardsColumn contentSubmissionFragmentRef={item} />}
			/>
		</DataTable>
	);
};

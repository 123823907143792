import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputNumber, InputNumberChangeEvent } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
import { RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment$key } from "../../../../../../../__generated__/RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment.graphql";
import { RandomMultipleChoiceElementForm_EditRandomMultipleChoiceElementMutation } from "../../../../../../../__generated__/RandomMultipleChoiceElementForm_EditRandomMultipleChoiceElementMutation.graphql";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../../../../../infecto-lms-admin/redux/slices/CoreSlice";
import { ValidatedField } from "../../../../../../../infecto-lms-admin/components/core/form/ValidatedField";
import { useTypedDispatch } from "../../../../../../../infecto-lms-admin/redux/Store";

const ELEMENT_FRAGMENT = graphql`
	fragment RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment on RandomMultipleChoiceElement {
		id
		count
	}
`;

const EDIT_RANDOM_MULTIPLE_CHOICE_ELEMENT_MUTATION = graphql`
	mutation RandomMultipleChoiceElementForm_EditRandomMultipleChoiceElementMutation(
		$input: EditRandomMultipleChoiceElementInput!
	) {
		Admin {
			ElearningV2 {
				editRandomMultipleChoiceElement(input: $input) {
					randomMultipleChoiceElement {
						...RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment
					}
				}
			}
		}
	}
`;

interface FormState {
	count: number;
}

interface OwnProps {
	randomMultipleChoiceElementFragmentRef: RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment$key;
	onBack: () => void;
}

export const RandomMultipleChoiceElementForm = ({
	randomMultipleChoiceElementFragmentRef,
	onBack,
}: OwnProps) => {
	const element =
		useFragment<RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment$key>(
			ELEMENT_FRAGMENT,
			randomMultipleChoiceElementFragmentRef,
		);

	const [editRandomMultipleChoiceElementElement, isEditingRandomMultipleChoiceElementElement] =
		useMutation<RandomMultipleChoiceElementForm_EditRandomMultipleChoiceElementMutation>(
			EDIT_RANDOM_MULTIPLE_CHOICE_ELEMENT_MUTATION,
		);

	const formId = "RandomMultipleChoiceElementForm";

	const dispatch = useTypedDispatch();

	const formik = useFormik<FormState>({
		initialValues: {
			count: element.count,
		},
		validationSchema: Yup.object().shape({
			count: Yup.number().required("Das Feld Anzahl wird benötigt."),
		}),
		onSubmit: (values) => {
			editRandomMultipleChoiceElementElement({
				variables: {
					input: {
						randomMultipleChoiceElementId: element.id,
						count: values.count,
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, number>
				name={"count"}
				label={"Anzahl"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				component={({ fieldValue, updateField, onChange, fieldName, isValid }) => {
					return (
						<InputNumber
							id={fieldName}
							name={fieldName}
							value={fieldValue}
							onChange={(e: InputNumberChangeEvent) => {
								updateField(e.value ?? undefined);

								if (onChange) {
									onChange();
								}
							}}
							className={classNames({ "p-invalid": !isValid })}
						/>
					);
				}}
				formikConfig={formik}
			/>

			<Button
				disabled={isEditingRandomMultipleChoiceElementElement}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};

import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { PointsPoolColumn_PointsAdditionFragment$key } from "../../../../__generated__/PointsPoolColumn_PointsAdditionFragment.graphql";

const POINTS_ADDITION_FRAGMENT = graphql`
	fragment PointsPoolColumn_PointsAdditionFragment on PointsAdditionV2 {
		pointsPool {
			name
		}
	}
`;

interface OwnProps {
	pointsAdditionFragmentRef: PointsPoolColumn_PointsAdditionFragment$key;
}

export const PointsPoolColumn = ({ pointsAdditionFragmentRef }: OwnProps) => {
	const pointsAddition = useFragment<PointsPoolColumn_PointsAdditionFragment$key>(
		POINTS_ADDITION_FRAGMENT,
		pointsAdditionFragmentRef,
	);

	return <div>{pointsAddition.pointsPool.name}</div>;
};

/**
 * @generated SignedSource<<459f9a632b1f2b918ef0a6bd4fd89bbb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StartContentConfigType = "StartContent_SpecificUserStatusesCan" | "StartContent_CanIfInTreeFlow" | "StartContent_CanNot" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StartContentConfigEditor_TreeNodeFragment$data = {
  readonly id: string;
  readonly typeDefinition: {
    readonly startConfigs?: ReadonlyArray<{
      readonly id: string;
      readonly configType: StartContentConfigType;
      readonly " $fragmentSpreads": FragmentRefs<"ProjectExtendStartContentConfigEditDialogs_StartContentConfigFragment">;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ProjectExtendStartContentConfigEditDialogs_TreeNodeFragment">;
  readonly " $fragmentType": "StartContentConfigEditor_TreeNodeFragment";
};
export type StartContentConfigEditor_TreeNodeFragment = StartContentConfigEditor_TreeNodeFragment$data;
export type StartContentConfigEditor_TreeNodeFragment$key = {
  readonly " $data"?: StartContentConfigEditor_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"StartContentConfigEditor_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StartContentConfigEditor_TreeNodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "startConfigs",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "configType",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProjectExtendStartContentConfigEditDialogs_StartContentConfigFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectExtendStartContentConfigEditDialogs_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "cfb930f47e6b1d940a75afeb12a6b813";

export default node;

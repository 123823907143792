/**
 * @generated SignedSource<<fc8c62fc0e163696a2554a004f822350>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PointsSum_Query$variables = {
  userId: string;
  pointsPoolId: string;
  filterByMinDateTime?: string | null;
  skip: boolean;
};
export type PointsSum_QueryVariables = PointsSum_Query$variables;
export type PointsSum_Query$data = {
  readonly Admin: {
    readonly PointsV2: {
      readonly SumPoints?: {
        readonly points: number;
        readonly pointPoolName: string;
      };
    };
  };
};
export type PointsSum_QueryResponse = PointsSum_Query$data;
export type PointsSum_Query = {
  variables: PointsSum_QueryVariables;
  response: PointsSum_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByMinDateTime"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pointsPoolId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminQueryType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PointsV2AdminSchema",
        "kind": "LinkedField",
        "name": "PointsV2",
        "plural": false,
        "selections": [
          {
            "condition": "skip",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "filterByMinDateTime",
                    "variableName": "filterByMinDateTime"
                  },
                  {
                    "kind": "Variable",
                    "name": "filterByPointsPoolId",
                    "variableName": "pointsPoolId"
                  },
                  {
                    "kind": "Variable",
                    "name": "filterByUserId",
                    "variableName": "userId"
                  }
                ],
                "concreteType": "CountedPointsV2",
                "kind": "LinkedField",
                "name": "SumPoints",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "points",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pointPoolName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PointsSum_Query",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "PointsSum_Query",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "8274ca72bcd5280bfffc5e60ddf52e52",
    "id": null,
    "metadata": {},
    "name": "PointsSum_Query",
    "operationKind": "query",
    "text": "query PointsSum_Query(\n  $userId: ID!\n  $pointsPoolId: ID!\n  $filterByMinDateTime: ZonedDateTIme\n  $skip: Boolean!\n) {\n  Admin {\n    PointsV2 {\n      SumPoints(filterByUserId: $userId, filterByPointsPoolId: $pointsPoolId, filterByMinDateTime: $filterByMinDateTime) @skip(if: $skip) {\n        points\n        pointPoolName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f9255e4bf85a3d336a7cf4ed35b321c1";

export default node;

import { ConfigsLocalization } from "../../infecto-lms-admin/i18n/config/i18n";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "./tree.i18n";

export const EXTEND_AND_OVERRIDE_CONFIGS_TRANSLATIONS: ConfigsLocalization = {
	StartTree_SpecificUserStatusesCan:
		"Bestimmten Benutzer-Status voraussetzen (bis einem bestimmten Datum)",
	VisibilityTree_OnlyFachrichtungen:
		"Modul verstecken, außer für Benutzer bestimmter Fachrichtungen",
	StartContent_SpecificUserStatusesCan:
		"Bestimmten Benutzer-Status voraussetzen (bis einem bestimmten Datum)",
	StartTree_SpecificUserRolesCan: "Bestimmten Benutzer-Rollen voraussetzen",
	VisibilityTree_OnlySpecificPermissions: `${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)} verstecken (außer für Admins)  (bis einem bestimmten Datum)`,
	VisibilityTree_OnlyIfTreeStateOrAdmin: `${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)} verstecken (außer für Nutzer mit aktivem Lernstatus)`,
	VisibilityTree_OnlyFachberater: `${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)} verstecken (außer für Fachberater)`,
	VisibilityTree_OnlyBasicSeminar: `${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)} verstecken (außer für Teilnehmer Bassisseminar)`,
	VisibilityTree_HideForFachberater: `${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)} verstecken (für Fachberater)`,
};

/**
 * @generated SignedSource<<292ee1580fd89908c7169ce56c06d3bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContainerTag_TagFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly colorHex: string;
  readonly " $fragmentType": "ContainerTag_TagFragment";
};
export type ContainerTag_TagFragment = ContainerTag_TagFragment$data;
export type ContainerTag_TagFragment$key = {
  readonly " $data"?: ContainerTag_TagFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContainerTag_TagFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContainerTag_TagFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "colorHex",
      "storageKey": null
    }
  ],
  "type": "Tag",
  "abstractKey": null
};

(node as any).hash = "8fa4735c2259e338db50124be0639c7b";

export default node;

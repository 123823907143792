import { SHARED_VARIABLES } from "../_shared/i18n";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../lms-admin-impl/i18n/tree.i18n";

export const NOTIFICATION_TEMPLATE_KEYS: { [key: string]: string } = {
	"inform-expiration-notification": `${TREE_I18N_MAP(TREE_I18N_KEY.tree)} ist abgelaufen (V1)`,
	"remind-retry-notification": `${TREE_I18N_MAP(
		TREE_I18N_KEY.contentNode,
	)} kann erneut versucht werden (V1)`,
	"inform-expiration-notification-v2": `${TREE_I18N_MAP(TREE_I18N_KEY.tree)} ist abgelaufen`,
	"remind-retry-notification-v2": `${TREE_I18N_MAP(
		TREE_I18N_KEY.contentNode,
	)} kann erneut versucht werden`,
};

export const NOTIFICATION_VARIABLES: { [key: string]: string } = {
	...SHARED_VARIABLES,
	authenticationToken: "Aktivierungs-Token (muss in den Link)",
	frontendUrl: "URL des Nutzersystem-User-Frontends",
	brand: "Name der Marke unter der sich der Kunde registriert hat",
	brandUrl: "URL der Marke auf der sich der Kunde registriert hat",
	username: "Benutzername des Nutzers, im Regelfall die E-Mail-Adresse",
	brandIdBase64:
		"ID der Marke mit der sich registriert wurde, für Verwendung in Links zum Frontend.",
	containerTitle: "Titel des Inhalts",
	containerIdBase64: "ID des Inhaltes, kann für Links verwendet werden.",
	brandDomain:
		"Domain der Marke über die sich ein Nutzer angemeldet hat (bei www.infectopharm.com zB infectopharm.com)",
	brandIcon: "Marken-Bild",
	brandColor: "Farbcode der Marke",
	lmsUrl: "URL des LMS",
	rootNodeTitle: `Titel des ${TREE_I18N_MAP(TREE_I18N_KEY.treeAttributiveGenitive)}`,
	contentNodeTitle: `Titel des ${TREE_I18N_MAP(TREE_I18N_KEY.contentNodeAttributiveGenitive)}.`,
};

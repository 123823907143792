import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ContentsColumn_LandingPageFragment$key } from "../../../../__generated__/ContentsColumn_LandingPageFragment.graphql";
import styled from "styled-components";
import React from "react";

const LANDING_PAGE_FRAGMENT = graphql`
	fragment ContentsColumn_LandingPageFragment on LandingPageV2 {
		rootNodes {
			... on LearnOpportunitiesV2Connection {
				edges {
					node {
						id
						structureDefinition {
							title
							... on LearnOpportunityRootStructureDefinition {
								extension {
									... on LearnOpportunityRootExtensionImpl {
										isVisibleOnlyForAdmins
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	landingPageFragmentRef: ContentsColumn_LandingPageFragment$key;
}

export const ContentsColumn = ({ landingPageFragmentRef }: OwnProps) => {
	const landingPage = useFragment<ContentsColumn_LandingPageFragment$key>(
		LANDING_PAGE_FRAGMENT,
		landingPageFragmentRef,
	);
	const rootNodes = landingPage.rootNodes.edges?.map((n) => n?.node!);

	return (
		<div className={"flex flex-wrap "}>
			{rootNodes &&
				rootNodes.map(
					(cont: {
						id: string;
						structureDefinition: {
							title: string;
							extension?: { isVisibleOnlyForAdmins?: boolean | undefined };
						};
					}) => (
						<ContentRootNode
							isVisibleOnlyForAdmins={
								cont.structureDefinition.extension?.isVisibleOnlyForAdmins
							}
							key={cont.id}
						>
							{cont.structureDefinition.title}
						</ContentRootNode>
					),
				)}
		</div>
	);
};

const ContentRootNode = styled.div<{ isVisibleOnlyForAdmins: boolean | undefined }>`
	border-radius: 20px;
	margin: 5px;
	font-size: 0.65rem;
	padding: 5px;
	border: solid 1px;
	${(props) => (props.isVisibleOnlyForAdmins ? "border-color: #5f6bfb;" : "border-color: grey;")}
`;

import { graphql } from "babel-plugin-relay/macro";
import { commitMutation, Environment } from "react-relay";
import { AddOnlyIfTreeStateOrAdminVisibilityTreeConfigInput } from "../../../../../../../../__generated__/OnlyIfTreeStateOrAdminVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation.graphql";
import { OnlyFachberaterVisibilityConfig_AddMutation } from "../../../../../../../../__generated__/OnlyFachberaterVisibilityConfig_AddMutation.graphql";

const MUTATION = graphql`
	mutation OnlyFachberaterVisibilityConfig_AddMutation(
		$input: AddOnlyFachberaterVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				AddOnlyFachberaterVisibilityTreeConfig(input: $input) {
					rootNode {
						... on TreeNode {
							id
							structureDefinition {
								... on RootStructureDefinition {
									visibilityConfigs {
										... on OnlyFachberaterVisibilityTreeConfig {
											id
											configType
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

function CommitOnlyFachberaterVisibilityConfig(
	environment: Environment,
	input: AddOnlyIfTreeStateOrAdminVisibilityTreeConfigInput,
) {
	return commitMutation<OnlyFachberaterVisibilityConfig_AddMutation>(environment, {
		mutation: MUTATION,
		variables: { input },
		onCompleted: () => {},
		onError: () => {},
	});
}

export { CommitOnlyFachberaterVisibilityConfig as AddInfectopharmOnlyFachberaterVisibilityConfig };

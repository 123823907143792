import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery, useMutation, usePaginationFragment } from "react-relay";
import { EmailTesterFeedbackEmailForm_FeedbackQuery } from "../../../__generated__/EmailTesterFeedbackEmailForm_FeedbackQuery.graphql";
import { EmailTesterFeedbackEmailForm_Refetch } from "../../../__generated__/EmailTesterFeedbackEmailForm_Refetch.graphql";
import { EmailTesterFeedbackEmailForm_FeedbackQueryFragment$key } from "../../../__generated__/EmailTesterFeedbackEmailForm_FeedbackQueryFragment.graphql";
import { EmailTesterFeedbackEmailForm_Mutation } from "../../../__generated__/EmailTesterFeedbackEmailForm_Mutation.graphql";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { ValidatedField } from "../core/form/ValidatedField";
import { DefaultTextFieldComponent } from "../core/form/DefaultTextInput";
import { Dropdown } from "primereact/dropdown";
import { EmailTesterDialogContainer } from "./EmailTesterDialogContainer";

const QUERY_FEEDBACK = graphql`
	query EmailTesterFeedbackEmailForm_FeedbackQuery {
		...EmailTesterFeedbackEmailForm_FeedbackQueryFragment
	}
`;
const QUERY_FRAGMENT_FEEDBACK = graphql`
	fragment EmailTesterFeedbackEmailForm_FeedbackQueryFragment on Query
	@refetchable(queryName: "EmailTesterFeedbackEmailForm_Refetch")
	@argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
		Admin {
			FeedbackV2 {
				Feedbacks(first: $first, after: $after)
					@connection(key: "EmailTesterFeedbackEmailForm_Feedbacks") {
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							body
						}
					}
				}
			}
		}
	}
`;

const MUTATION_TESTER = graphql`
	mutation EmailTesterFeedbackEmailForm_Mutation($input: EmailTesterFeedbackEmailInput!) {
		Admin {
			TesterEmail {
				EmailTesterFeedbackEmail(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
interface FormState {
	email: string;
	feedbackId: string;
}
interface OwnProps {
	modalVisible: boolean;
	setModalVisible: (boolean: boolean) => void;
}

export const EmailTesterFeedbackEmailForm = ({ modalVisible, setModalVisible }: OwnProps) => {
	const feedbackquery = useLazyLoadQuery<EmailTesterFeedbackEmailForm_FeedbackQuery>(
		QUERY_FEEDBACK,
		{},
		{ fetchPolicy: "network-only" },
	);
	const { data } = usePaginationFragment<
		EmailTesterFeedbackEmailForm_Refetch,
		EmailTesterFeedbackEmailForm_FeedbackQueryFragment$key
	>(QUERY_FRAGMENT_FEEDBACK, feedbackquery);

	const feedbacks = data.Admin.FeedbackV2.Feedbacks.edges?.map((e) => e!.node)!;
	const [testerEmailSend, isSending] =
		useMutation<EmailTesterFeedbackEmailForm_Mutation>(MUTATION_TESTER);

	const formik = useFormik<FormState>({
		initialValues: {
			email: "karl.kroeber@thekey.technology",
			feedbackId: "",
		},
		onSubmit: (values) => {
			testerEmailSend({
				variables: {
					input: {
						email: values.email,
						feedbackId: values.feedbackId,
					},
				},
				onCompleted: () => {
					toast.success("Email gesendet");
				},
				onError: (err) => {
					toast.error(err.message);
				},
			});
		},
	});

	return (
		<div>
			<EmailTesterDialogContainer
				headerText={"Test Feedback Email"}
				setModalVisible={() => setModalVisible(false)}
				modalVisible={modalVisible}
			>
				<form onSubmit={formik.handleSubmit} className="p-fluid">
					<ValidatedField<FormState, string>
						name={"email"}
						label={"Email"}
						component={DefaultTextFieldComponent}
						formikConfig={formik}
					/>

					<ValidatedField<FormState, string>
						name={"feedbackId"}
						label={"Feedback"}
						component={({ fieldName, fieldValue, updateField }) => (
							<div>
								<Dropdown
									name={fieldName}
									value={fieldValue}
									options={
										feedbacks
											? feedbacks?.map((f) => ({
													label: f.body.substring(0, 20),
													value: f.id,
											  }))
											: [{ label: "N/A", value: "" }]
									}
									onChange={(e) => {
										updateField(e.value);
									}}
								/>
							</div>
						)}
						formikConfig={formik}
					/>
					<Button
						disabled={Object.entries(formik.touched).length === 0 || isSending}
						type="submit"
						label="Speichern"
						className="p-mt-2"
					/>
				</form>
			</EmailTesterDialogContainer>
		</div>
	);
};

import { SHARED_VARIABLES } from "../_shared/i18n";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../lms-admin-impl/i18n/tree.i18n";

export const EMAIL_TEMPLATE_KEYS: { [key: string]: string } = {
	"inform-expiration": `${TREE_I18N_MAP(TREE_I18N_KEY.tree)} ist abgelaufen (V1)`,
	"remind-retry": `${TREE_I18N_MAP(TREE_I18N_KEY.contentNode)} kann erneut versucht werden (V1)`,
	"admin-feedback": "Feedback erhalten (V1)",
	"inform-expiration-v2": `${TREE_I18N_MAP(TREE_I18N_KEY.tree)} ist abgelaufen`,
	"remind-retry-v2": `${TREE_I18N_MAP(TREE_I18N_KEY.contentNode)} kann erneut versucht werden`,
	"admin-feedback-v2": "Feedback erhalten",
	"remind-basisseminar-exam": "Reminder Online-Abschlussprüfung Fachberater*in Pädiatrie",
};

export const EMAIL_VARIABLES: { [key: string]: string } = {
	...SHARED_VARIABLES,
	authenticationToken: "Aktivierungs-Token (muss in den Link)",
	feedbackIdBase64: "Interne Id dieses Feedbacks",
	feedbackContent: "Feedback Inhalt",
	frontendUrl: "URL des Nutzersystem-User-Frontends",
	brand: "Name der Marke des Systems",
	brandUrl: "URL der Marke des Systems",
	userEmail: "Email-Adresse des Nutzers",
	greeting: "Höfliche Anrede (geehrte/ geehrter/ geehrte/r)",
	brandIdBase64: "ID der Marke des Systems, für Verwendung in Links zum Frontend.",
	containerTitle: "Titel des Inhalts.",
	containerIdBase64: "ID des Inhaltes, kann für Links verwendet werden.",
	brandDomain: "Domain der Marke des Systems hat (bei www.infectopharm.com zB infectopharm.com)",
	brandIcon: "Marken-Bild",
	brandColor: "Farbcode der Marke",
	lmsUrl: "URL des LMS",
	rootNodeTitle: `Titel des ${TREE_I18N_MAP(TREE_I18N_KEY.treeAttributiveGenitive)}.`,
	rootNodeIdBase64: `ID des  ${TREE_I18N_MAP(
		TREE_I18N_KEY.treeAttributiveGenitive,
	)}, kann für Links verwendet werden.`,
	contentNodeTitle: `Titel des ${TREE_I18N_MAP(TREE_I18N_KEY.contentNodeAttributiveGenitive)}.`,
	contentNodeIdBase64: `ID des ${TREE_I18N_MAP(
		TREE_I18N_KEY.contentNodeAttributiveGenitive,
	)}, kann für Links verwendet werden.`,
	lastDayBasisseminarExam: "Letzter Tag, an dem das Basisseminar sichtbar ist",
	copyrightYear: "Copyright-Jahr",
};

export const EMAIL_SENDING_CONDITION_DESCRIPTION: { [key: string]: string } = {
	"remind-basisseminar-exam":
		"Versand-Bedingungen: Es darf nur ein Modul mit einem Basisseminar-Tag aktiv sein. Eine Woche bevor das Modul deaktiviert wird, erhalten alle Benutzer mit dem Fachberater-Status „Für Basisseminar zugelassen“, die das Modul noch nicht begonnen haben oder weniger als drei Fehlversuche haben, zwischen 9 und 10 Uhr vormittags eine E-Mail. ",
};

import React from "react";
import graphql from "babel-plugin-relay/macro";
import styled from "styled-components";
import { Dialog } from "primereact/dialog";
import { useFragment, useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { formatDateTime } from "../core/datetime/DateTimeDisplay";
import { UserContentSubmissionDialog_Query } from "../../../__generated__/UserContentSubmissionDialog_Query.graphql";
import { DeleteContentSubmissionButton } from "./DeleteContentSubmissionButton";
import { Card } from "primereact/card";
import { UserContentSubmissionDialog_UserFragment$key } from "../../../__generated__/UserContentSubmissionDialog_UserFragment.graphql";
import { REWARD_KINDS, REWARD_RESULT_KINDS } from "../../i18n/rewards";
import { UserContentSubmissionDialog_Refetch } from "../../../__generated__/UserContentSubmissionDialog_Refetch.graphql";
import { UserContentSubmissionDialog_QueryFragment$key } from "../../../__generated__/UserContentSubmissionDialog_QueryFragment.graphql";

const QUERY = graphql`
	query UserContentSubmissionDialog_Query($userId: ID, $contentNodeId: ID) {
		...UserContentSubmissionDialog_QueryFragment
			@arguments(userId: $userId, contentNodeId: $contentNodeId)
	}
`;
const FRAGMENT_QUERY = graphql`
	fragment UserContentSubmissionDialog_QueryFragment on Query
	@refetchable(queryName: "UserContentSubmissionDialog_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		userId: { type: "ID" }
		contentNodeId: { type: "ID" }
	) {
		Admin {
			UsersContentSubmission {
				ContentSubmissions(
					first: $first
					after: $after
					userId: $userId
					contentId: $contentNodeId
				) @connection(key: "UserContentSubmissionDialog_ContentSubmissions") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							startedAt
							lastUpdated
							definition {
								status
								... on PassedELearningContentSubmissionDefinition {
									rewardResults {
										kind
										rewardKind
									}
								}
								... on FailedELearningContentSubmissionDefinition {
									status
									contentKind
									rewardResults {
										kind
										rewardId
										rewardKind
									}
								}
							}
							learnOpportunity {
								root {
									structureDefinition {
										title
										... on LearnOpportunityRootStructureDefinition {
											extension {
												... on LearnOpportunityRootExtensionImpl {
													configConsequences {
														restartTriesCount
													}
												}
											}
										}
									}
								}
								structureDefinition {
									title
								}
							}
						}
					}
				}
			}
		}
	}
`;

const USER_FRAGMENT = graphql`
	fragment UserContentSubmissionDialog_UserFragment on User {
		id
		extension {
			... on InfectopharmUserExtensionImpl {
				firstName
				lastName
			}
		}
	}
`;

interface OwnProps {
	showDialog: boolean;
	setShowDialog?: any;
	userFragmentRef: UserContentSubmissionDialog_UserFragment$key;
	contentNodeId: string;
}

export const UserContentSubmissionDialog = ({
	setShowDialog,
	contentNodeId,
	userFragmentRef,
}: OwnProps) => {
	const user = useFragment<UserContentSubmissionDialog_UserFragment$key>(
		USER_FRAGMENT,
		userFragmentRef,
	);
	const query = useLazyLoadQuery<UserContentSubmissionDialog_Query>(
		QUERY,
		{
			contentNodeId: contentNodeId,
			userId: user.id,
		},
		{ fetchPolicy: "network-only" },
	);

	const { data, refetch } = usePaginationFragment<
		UserContentSubmissionDialog_Refetch,
		UserContentSubmissionDialog_QueryFragment$key
	>(FRAGMENT_QUERY, query);

	const contentSubmissions = data.Admin.UsersContentSubmission.ContentSubmissions.edges?.map(
		(n) => n?.node,
	) as any[];

	const userContentSubmissionsConnection =
		data.Admin.UsersContentSubmission.ContentSubmissions || [];

	const userContentSubmissionsConnectionId = userContentSubmissionsConnection.__id;

	const failedTries = contentSubmissions
		?.filter((co) => co.definition.status == "failed")
		.map((c) => c.id);

	const passedContentSubmission = contentSubmissions?.filter(
		(co) => co.definition.status == "passed",
	);

	const allowedTriesNum = contentSubmissions?.map(
		(cont) =>
			cont.learnOpportunity?.root?.structureDefinition.extension?.configConsequences
				?.restartTriesCount,
	)[0];

	interface CardFieldProps {
		label: any;
		value: any;
	}
	const CardField = ({ label, value }: CardFieldProps) => {
		return (
			<p>
				<strong>{label}: </strong>
				<span>{value}</span>
			</p>
		);
	};

	return (
		<div>
			<DialogContainer
				header="Ergebnisse"
				className="flex"
				position="center"
				contentClassName="flex"
				visible={true}
				onHide={() => {
					setShowDialog(false);
				}}
			>
				<div className="flex flex-column m-2">
					<div className="mb-2">
						<strong>User:</strong> {user.extension.firstName} {user.extension.lastName}
					</div>
					{allowedTriesNum &&
						failedTries.length >= allowedTriesNum &&
						passedContentSubmission.length == 0 && (
							<DeleteContentSubmissionButton
								failedContentSubmissionIds={failedTries}
								userContentSubmissionsConnectionId={
									userContentSubmissionsConnectionId
								}
								refetch={() => refetch({}, { fetchPolicy: "network-only" })}
							/>
						)}
					{contentSubmissions.length > 0 ? (
						<div className="flex flex-row w-full flex-wrap grid">
							{contentSubmissions.map((cont, index) => (
								<div className="col-6" key={index}>
									<Card className="m-2">
										<CardField
											label={"Modul Name"}
											value={
												cont.learnOpportunity?.root.structureDefinition
													.title
											}
										></CardField>
										<CardField
											label={"Letzte Aktualisierung"}
											value={formatDateTime(cont.lastUpdated!)}
										></CardField>
										<CardField
											label={"Gestartet am"}
											value={formatDateTime(cont.startedAt!)}
										></CardField>
										<CardField
											label={"State"}
											value={cont.definition.status}
										></CardField>
										{cont.definition.rewardResults &&
											cont.definition.rewardResults.map(
												(result: any, index: number) => (
													<div key={index}>
														<CardField
															label={
																REWARD_KINDS[result?.rewardKind] ||
																result?.rewardKind
															}
															value={
																REWARD_RESULT_KINDS[result.kind] ||
																result.kind
															}
														></CardField>
													</div>
												),
											)}
									</Card>
								</div>
							))}
						</div>
					) : (
						<p>Keine Benutzereingabe </p>
					)}
				</div>
			</DialogContainer>
		</div>
	);
};

const DialogContainer = styled(Dialog)`
	width: 80%;
	.p-dialog-content {
		justify-content: space-between;
		display: flex;
		flex-direction: column;
	}
`;

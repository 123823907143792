import React, { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { readInlineData, useLazyLoadQuery } from "react-relay";
import {
	getSingleModulePath,
	MODULES_ID_URL_PARAM,
} from "../../../app/routes/PublishedNodesAndUsersModuleRoutes";
import { UsersWhoMadeContentNodeTable_Query } from "../../../__generated__/UsersWhoMadeContentNodeTable_Query.graphql";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { UserNameColumn } from "./UserNameColumn";
import { UserActionColumn } from "./UserActionColumn";

const QUERY = graphql`
	query UsersWhoMadeContentNodeTable_Query($contentNodeId: ID) {
		Admin {
			UsersList {
				AllUsers(contentNodeId: $contentNodeId) {
					id
					extension {
						... on InfectopharmUserExtensionImpl {
							lastName
							firstName
						}
					}
					...UsersWhoMadeContentNodeTable_Fragment
					...UserContentSubmissionDialog_UserFragment
				}
			}
		}
	}
`;
const FRAGMENT = graphql`
	fragment UsersWhoMadeContentNodeTable_Fragment on User @inline {
		...UserNameColumn_Fragment
		...UserActionColumn_NodeFragment
	}
`;

export const UsersWhoMadeContentNodeTable = () => {
	const match = useMatch(getSingleModulePath(MODULES_ID_URL_PARAM));

	const query = useLazyLoadQuery<UsersWhoMadeContentNodeTable_Query>(
		QUERY,
		{
			contentNodeId: match?.params.moduleId!,
		},
		{ fetchPolicy: "network-only" },
	);
	const users = query.Admin.UsersList.AllUsers;
	const [filterLastName, setFilterLastName] = useState("");

	const [filterFirstName, setFilterFirstName] = useState("");

	const [filteredArray, setFilteredArray] = useState(users);

	const filteredUsers = () => {
		const filtered = users.filter((item) => {
			const firstMatch = item.extension.firstName
				?.toLowerCase()
				.includes(filterFirstName.toLowerCase());
			const secondMatch = item.extension.lastName
				?.toLowerCase()
				.includes(filterLastName.toLowerCase());

			if (filterFirstName && filterLastName) {
				return firstMatch && secondMatch;
			} else if (filterFirstName) {
				return firstMatch;
			} else if (filterLastName) {
				return secondMatch;
			}
			return true; // No filter applied
		});
		setFilteredArray(filtered);
	};
	useEffect(() => {
		filteredUsers();
	}, [filterFirstName, filterLastName]);
	return (
		<div>
			<InputText
				placeholder={"Lastname..."}
				value={filterLastName}
				onChange={(e) => {
					setFilterLastName(e.target.value);
				}}
				className="m-2"
			/>
			<InputText
				placeholder={"Vorname..."}
				value={filterFirstName}
				onChange={(e) => {
					setFilterFirstName(e.target.value);
				}}
				className="m-2"
			/>
			<Button
				className="m-2"
				onClick={() => {
					setFilterFirstName("");
					setFilterLastName("");
				}}
				label={"Zurücksetzen"}
				icon="pi pi-times"
			/>
			{users.length > 0 ? (
				<DataTable
					className="mb-3"
					value={filteredArray.map((item: any) => readInlineData(FRAGMENT, item))}
				>
					<Column
						header={"Name"}
						body={(item) => <UserNameColumn userFragmentRef={item} />}
					/>
					<Column
						header={"Aktionen"}
						body={(item) => (
							<UserActionColumn
								userId={item.id}
								contentNodeId={match?.params.moduleId!}
								userFragmentRef={item}
							/>
						)}
					/>
				</DataTable>
			) : (
				<p className="m-2">Keine Benutzer haben dieses Modul gestartet</p>
			)}
		</div>
	);
};

import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { ContentNodeSelectionField_Query } from "../../../__generated__/ContentNodeSelectionField_Query.graphql";
import { RenderConfig } from "../core/form/ValidatedField";

const QUERY = graphql`
	query ContentNodeSelectionField_Query {
		Admin {
			Tree {
				GetContentNodes(first: 200) {
					edges {
						node {
							id
							structureDefinition {
								title
							}
						}
					}
				}
			}
		}
	}
`;

export const ContentNodeSelectionField = ({
	isValid,
	fieldName,
	fieldValue,
	updateField,
	disabled,
}: RenderConfig<string>) => {
	const data = useLazyLoadQuery<ContentNodeSelectionField_Query>(QUERY, {});

	return (
		<div className="flex">
			<Dropdown
				className={`mr-2 flex-grow-1 w-auto ${classNames({ "p-invalid": !isValid })}`}
				name={fieldName}
				disabled={disabled}
				options={data.Admin.Tree.GetContentNodes.edges
					?.filter((e) => !!e?.node)
					.map((e) => e!.node!)
					.map((n) => ({
						label: n.structureDefinition.title,
						value: n.id,
					}))}
				value={fieldValue}
				onChange={(e) => updateField(e.value)}
			/>
		</div>
	);
};

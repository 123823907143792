import React, { useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import {
	CreateTreeForm,
	CreateTreeFormState,
	TreeCreationMutation,
} from "../../../app/components/tree/tree/CreateTreeForm";
import { Button } from "primereact/button";
import { getTreeEditorPath } from "../../../app/routes/GraphEditorRoutes";
import { ProjectCreateTreeButton_CreateBranchRootNodeMutation } from "../../../__generated__/ProjectCreateTreeButton_CreateBranchRootNodeMutation.graphql";

const CREATE_BRANCH_ROOT_NODE_MUTATION = graphql`
	mutation ProjectCreateTreeButton_CreateBranchRootNodeMutation(
		$input: PaediaPredefinedTemplateCreateBranchRootNodeInput!
		$connections: [ID!]!
	) {
		Admin {
			Tree {
				paediaPredefinedTemplateCreateBranchRootNode(input: $input) {
					createdRootNode @appendEdge(connections: $connections) {
						node {
							id
							...RootNodesTable_TreeNodeFragment
						}
					}
				}
			}
		}
	}
`;

interface Props {
	rootNodesConnectionId: string;
}

export const ProjectCreateTreeButton = ({ rootNodesConnectionId }: Props) => {
	const navigate = useNavigate();
	const [displayMaximizable, setDisplayMaximizable] = useState(false);

	const [createBranchRootNode, isCreatingBranchRootNode] =
		useMutation<ProjectCreateTreeButton_CreateBranchRootNodeMutation>(
			CREATE_BRANCH_ROOT_NODE_MUTATION,
		);

	const option: TreeCreationMutation = {
		mutation: (formValues: CreateTreeFormState) =>
			createBranchRootNode({
				variables: {
					input: {
						title: formValues.title,
						vnrNumber: formValues.vnrNumber,
						vnrInternalNumber: formValues.vnrInternalNumber,
						vnrPassword: formValues.vnrPassword,
						validFrom: formValues.validFrom,
						validTo: formValues.validTo,
						pacPointsAmount: formValues.pacPointsAmount,
						pharmaPointsAmount: formValues.bakPointsAmount,
						certificateTemplateId: formValues.certificateTemplateId,
						category: formValues.category,
						certifierRef: formValues.certifierRef,
						tags: formValues.tags,
						length: formValues.length,
					},
					connections: [rootNodesConnectionId],
				},
				onCompleted: (response) =>
					navigate(
						getTreeEditorPath(
							response.Admin.Tree.paediaPredefinedTemplateCreateBranchRootNode
								?.createdRootNode.node.id || "",
						),
					),
			}),
	};
	return (
		<>
			<Button
				label={"Neues Modul"}
				className={"mr-2"}
				disabled={isCreatingBranchRootNode}
				onClick={() => setDisplayMaximizable(true)}
			/>

			<CreateTreeForm
				type={"create"}
				displayMaximizable={displayMaximizable}
				setDisplayMaximizable={setDisplayMaximizable}
				onSubmitCallback={(formValues: CreateTreeFormState) => {
					option.mutation(formValues);
				}}
			/>
		</>
	);
};

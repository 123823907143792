import { useFormik } from "formik";
import { Button } from "primereact/button";
import React from "react";
import * as Yup from "yup";
import { VariableMappingField } from "./VariableMappingField";
import { VariableMappingInput } from "../../../__generated__/EditCertificateTemplateScreen_UpdateCertificateTemplateMutation.graphql";
import { useTypedDispatch, useTypedSelector } from "../../redux/Store";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
	selectArrayOfEditedForms,
} from "../../redux/slices/CoreSlice";
import { useCallbackPrompt } from "../../functions/hooks/UseCallBackPrompt";
import { WarningUnsavedChangesDialog } from "../core/dialog/WarningUnsavedChangesDialog";
import { RenderConfig, ValidatedField } from "../core/form/ValidatedField";
import { DefaultTextFieldComponent } from "../core/form/DefaultTextInput";
import { FileSelectionField } from "../files/FileSelectionField";

interface FormState {
	name?: string;
	templateFileRef?: string;
	variableMappings: VariableMappingInput[];
}

interface OwnProps {
	loading?: boolean;
	initialValues?: FormState;
	onSubmit: (values: FormState, onCompleted: () => void) => void;
}

export const EditCertificateTemplateForm = ({ loading, initialValues, onSubmit }: OwnProps) => {
	const dispatch = useTypedDispatch();
	const formId = "EditCertificateTemplateForm";
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);
	const [showPrompt, confirmNavigation, cancelNavigation, setShowPrompt] = useCallbackPrompt(
		arrayOfEditedForms.length > 0,
	);
	const formik = useFormik<FormState>({
		initialValues: {
			name: initialValues?.name ?? "Neue Zertifikats-Vorlage",
			templateFileRef: initialValues?.templateFileRef ?? "",
			variableMappings: initialValues?.variableMappings || [],
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Name wird benötigt"),
		}),
		onSubmit: (values, { setSubmitting, setTouched }) => {
			onSubmit(
				{
					...values,
				},
				() => {
					setTouched({});
					setSubmitting(false);
					dispatch(resetArrayOfEditedForms());
				},
			);
		},
	});

	return (
		<>
			{showPrompt && (
				<WarningUnsavedChangesDialog
					confirmNavigation={confirmNavigation}
					setShowDialog={setShowPrompt}
					cancelNavigation={cancelNavigation}
				/>
			)}
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, string>
					name={"name"}
					label={"Name"}
					required={true}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
					formikConfig={formik}
				/>
				<ValidatedField<FormState, string>
					name={"templateFileRef"}
					label={"HTML-Vorlage"}
					required={true}
					helpText={
						"Verwenden Sie Variablen die mit %% umschlossen sind (zB: %%EMPFAENGER_NAME%%) in der HTML-Datei. Nach dem Hochladen der Vorlage können Sie speichern und danach unten die Variablen zuordnen."
					}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
					component={(x: RenderConfig<string>) => {
						return (
							<FileSelectionField
								name={x.fieldName}
								selectedFileId={x.fieldValue}
								setSelectedFileId={x.updateField}
								filterByFileTypes={["text/html"]}
								onChange={x.onChange}
							/>
						);
					}}
					formikConfig={formik}
				/>

				<h3>Variablen-Zuordnung</h3>
				<ValidatedField<FormState, VariableMappingInput[]>
					name={"variableMappings"}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
					component={VariableMappingField}
					formikConfig={formik}
				/>

				<Button
					disabled={Object.entries(formik.touched).length === 0 || loading}
					type="submit"
					label="Speichern"
					className="p-mt-2"
				/>
			</form>
		</>
	);
};

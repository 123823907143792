/**
 * @generated SignedSource<<a284deca594bbb5604da0e4e3fa78506>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "order" | "randomMultipleChoice" | "clozeText" | "podcast" | "multipleChoice" | "video" | "file" | "text" | "%future added value";
export type TypeDefinitionType = "content" | "branch" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PublishedNodesActionColumn_NodeFragment$data = {
  readonly id: string;
  readonly typeDefinition: {
    readonly definitionType: TypeDefinitionType;
    readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
    readonly children?: ReadonlyArray<{
      readonly id: string;
      readonly typeDefinition: {
        readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
      };
    }>;
  };
  readonly " $fragmentType": "PublishedNodesActionColumn_NodeFragment";
};
export type PublishedNodesActionColumn_NodeFragment = PublishedNodesActionColumn_NodeFragment$data;
export type PublishedNodesActionColumn_NodeFragment$key = {
  readonly " $data"?: PublishedNodesActionColumn_NodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PublishedNodesActionColumn_NodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "containedElementTypes",
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityELearningContentTypeDefinition",
  "abstractKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PublishedNodesActionColumn_NodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LearnOpportunityV2",
              "kind": "LinkedField",
              "name": "children",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "typeDefinition",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityBranchTypeDefinition",
          "abstractKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "definitionType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "b30b6281e06b13c0d810fa5d73bc831d";

export default node;

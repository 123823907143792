import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import React, { Suspense, useContext } from "react";
import { useDebouncesFilters } from "../../../infecto-lms-admin/components/core/filters/useDebouncesFilters";
import {
	ProjectRootNodesSearchContext,
	RootNodesFilters,
} from "../ui/ProjectRootNodesSearchContainer";
import { ProjectRootNodesTable_Query } from "../../../__generated__/ProjectRootNodesTable_Query.graphql";
import { ProjectRootNodesTable_QueryFragment$key } from "../../../__generated__/ProjectRootNodesTable_QueryFragment.graphql";
import { ProjectRootNodesTable_Refetch } from "../../../__generated__/ProjectRootNodesTable_Refetch.graphql";
import { RootNodesTable } from "../../../app/components/tree/table/RootNodesTable";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { ProjectCreateTreeButton } from "./ProjectCreateTreeButton";

const QUERY = graphql`
	query ProjectRootNodesTable_Query {
		...ProjectRootNodesTable_QueryFragment
	}
`;

const QUERY_FRAGMENT = graphql`
	fragment ProjectRootNodesTable_QueryFragment on Query
	@refetchable(queryName: "ProjectRootNodesTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		filterByIsRewardedByAny: { type: "Boolean" }
		filterByTagIds: { type: "[ID!]" }
		filterByTitleOrDescriptionOrShortDescription: { type: "String" }
		filterByVnr: { type: "String" }
		filterByInternalVnr: { type: "String" }
		filterByVisibility: { type: "Boolean" }
	) {
		Admin {
			Tree {
				GetRootNodes(
					first: $first
					after: $after
					filterByIsRewardedByAny: $filterByIsRewardedByAny
					filterByTagIds: $filterByTagIds
					filterByTitleOrDescriptionOrShortDescription: $filterByTitleOrDescriptionOrShortDescription
					filterByVnr: $filterByVnr
					filterByInternalVnr: $filterByInternalVnr
					filterByVisibility: $filterByVisibility
				) @connection(key: "RootNodesTable_GetRootNodes") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							...RootNodesTable_TreeNodeFragment
						}
					}
				}
			}
		}
	}
`;

export const ProjectRootNodesTable = () => {
	const { filters } = useContext(ProjectRootNodesSearchContext);

	const query = useLazyLoadQuery<ProjectRootNodesTable_Query>(QUERY, {});

	const { data, hasPrevious, hasNext, refetch, loadPrevious, loadNext } = usePaginationFragment<
		ProjectRootNodesTable_Refetch,
		ProjectRootNodesTable_QueryFragment$key
	>(QUERY_FRAGMENT, query);

	const rootNodesConnection = data.Admin.Tree.GetRootNodes || [];
	const rootNodesConnectionId = rootNodesConnection.__id;

	useDebouncesFilters<RootNodesFilters>(filters, refetch);

	return (
		<>
			<Suspense
				fallback={
					<div className="flex justify-content-end">
						<Button className={"mr-2"}>
							<ProgressSpinner style={{ width: "95px", height: "19px" }} />
						</Button>
					</div>
				}
			>
				<div className="flex justify-content-end">
					<ProjectCreateTreeButton rootNodesConnectionId={rootNodesConnectionId} />
				</div>
			</Suspense>
			<RootNodesTable
				rootNodesConnectionId={rootNodesConnectionId}
				rootNodesConnection={rootNodesConnection}
				hasPrevious={hasPrevious}
				loadPrevious={loadPrevious}
				hasNext={hasNext}
				loadNext={loadNext}
			/>
		</>
	);
};

export const SHARED_VARIABLES: { [key: string]: string } = {
	city: "Stadt die der Benutzer bei der Adresse angeben hat",
	efnNumber: "EFN-Nummer des Benutzers",
	fachrichtung: "Fachrichtung des Benutzers",
	firstName: "Vorname des Benutzers",
	globalStatus: "Status des Benutzers",
	lastName: "Nachname des Benutzers",
	oneKeyId: "OneKey-Id des Benutzers",
	salutation: "Anrede des Benutzers (Herr / Frau)",
	title: "Titel des Benutzers",
};

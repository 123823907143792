import React from "react";
import { InputSwitch } from "primereact/inputswitch";
import { DefaultTextFieldComponent } from "../../core/form/DefaultTextInput";

export interface NotificationDefinition {
	title: string;
	body: string;
}

interface OwnProps {
	fieldValue: NotificationDefinition | undefined;
	updateField: (notificationDefinition?: NotificationDefinition) => void;
	onChange?: () => void;
}

export const EditMessageDefinitionNotificationDefinitionSubform = ({
	fieldValue,
	updateField,
	onChange,
}: OwnProps) => {
	return (
		<div className="mt-2">
			<div className="p-field mb-3">
				<InputSwitch
					checked={fieldValue !== undefined}
					onChange={(e) => updateField(e.value ? { title: "", body: "" } : undefined)}
				/>
			</div>
			{fieldValue ? (
				<div className="p-field mb-3">
					<label>Titel *</label>
					<DefaultTextFieldComponent
						disabled={false}
						onChange={onChange}
						fieldName={"notificationDefinitionTitle"}
						fieldValue={fieldValue.title}
						updateField={(title: string | undefined) =>
							updateField({
								...fieldValue,
								title: title || "",
							})
						}
						isValid={true}
						required={true}
					/>
				</div>
			) : undefined}
			{fieldValue ? (
				<div className="p-field mb-3">
					<label>Nachricht *</label>
					<DefaultTextFieldComponent
						disabled={false}
						onChange={onChange}
						fieldName={"notificationDefinitionBody"}
						fieldValue={fieldValue.body}
						updateField={(body: string | undefined) =>
							updateField({
								...fieldValue,
								body: body || "",
							})
						}
						isValid={true}
						required={true}
					/>
				</div>
			) : undefined}
		</div>
	);
};

/**
 * @generated SignedSource<<6d2fc98776239bcd76a78f1d72eb74df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectExtendActionButtons_TreeNodeFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PreviewPublishedNodeActionButton_TreeNodeFragment">;
  readonly " $fragmentType": "ProjectExtendActionButtons_TreeNodeFragment";
};
export type ProjectExtendActionButtons_TreeNodeFragment = ProjectExtendActionButtons_TreeNodeFragment$data;
export type ProjectExtendActionButtons_TreeNodeFragment$key = {
  readonly " $data"?: ProjectExtendActionButtons_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectExtendActionButtons_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectExtendActionButtons_TreeNodeFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PreviewPublishedNodeActionButton_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "87988894766204295191498df74c8240";

export default node;

import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { DateTimeDisplay } from "../../../core/datetime/DateTimeDisplay";
import { Button } from "primereact/button";
import { BaseScreenNoBackground } from "../../../../screens/BaseScreen";
import { PublishingPart_PublishingV2Fragment$key } from "../../../../../__generated__/PublishingPart_PublishingV2Fragment.graphql";
import { PublishingPart_AcknowledgePublishingV2Mutation } from "../../../../../__generated__/PublishingPart_AcknowledgePublishingV2Mutation.graphql";

const PUBLISHING_FRAGMENT = graphql`
	fragment PublishingPart_PublishingV2Fragment on PublishingV2 {
		id
		initiatedAt
		finishedAt
		messages {
			timestamp
			message
			additionalMessage
		}
		status
		canBeAcknowledged
	}
`;

const ACKNOWLEDGE_PUBLISHING_MUTATION = graphql`
	mutation PublishingPart_AcknowledgePublishingV2Mutation($input: AcknowledgePublishingV2Input!) {
		Admin {
			PublishingV2 {
				acknowledgePublishingV2(input: $input) {
					publishing {
						...PublishingPart_PublishingV2Fragment
					}
				}
			}
		}
	}
`;

interface OwnProps {
	publishingFragmentRef: PublishingPart_PublishingV2Fragment$key;
}

export const PublishingPart = ({ publishingFragmentRef }: OwnProps) => {
	const publishing = useFragment<PublishingPart_PublishingV2Fragment$key>(
		PUBLISHING_FRAGMENT,
		publishingFragmentRef,
	);

	const [acknowledgePublishing, isAcknowledgingPublishing] =
		useMutation<PublishingPart_AcknowledgePublishingV2Mutation>(
			ACKNOWLEDGE_PUBLISHING_MUTATION,
		);

	if (!publishing) return null;

	return (
		<BaseScreenNoBackground>
			<Card>
				<h2>Veröffentlichung</h2>
				<p>Status: {publishing.status}</p>
				<p>
					gestartet <DateTimeDisplay value={publishing.initiatedAt} hideTimezone={true} />
				</p>
				{publishing.finishedAt && (
					<p>
						abgeschlossen{" "}
						<DateTimeDisplay value={publishing.initiatedAt} hideTimezone={true} />
					</p>
				)}

				{publishing.canBeAcknowledged && (
					<Button
						disabled={isAcknowledgingPublishing}
						label={"Als gelesen markieren"}
						onClick={() => {
							acknowledgePublishing({
								variables: { input: { publishingId: publishing.id } },
								onCompleted: () => {
									window.location.reload();
								},
							});
						}}
					/>
				)}

				<h3>Nachrichten</h3>
				<DataTable emptyMessage={"Noch keine Nachrichten"} value={[...publishing.messages]}>
					<Column
						header="Zeit"
						body={(item) => <DateTimeDisplay value={item.timestamp} />}
					/>
					<Column field="message" header="Nachricht" />
					<Column field="additionalMessage" header="Weitere Informationen" />
				</DataTable>
			</Card>
		</BaseScreenNoBackground>
	);
};

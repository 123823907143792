import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../../lms-admin-impl/i18n/tree.i18n";
import { ValidatedField } from "../../../../infecto-lms-admin/components/core/form/ValidatedField";
import {
	DefaultIntegerFieldComponent,
	DefaultTextFieldComponent,
} from "../../../../infecto-lms-admin/components/core/form/DefaultTextInput";
import { CertificateTemplateSelectionField } from "../CertificateTemplateSelectionField";
import { InstructorDropdown } from "../InstructorDropdown";
import { TagSelectionField } from "../../../../infecto-lms-admin/components/tags/TagSelectionField";
import { CONFIGS_TRANSLATIONS } from "../../../../infecto-lms-admin/i18n/config/i18n";
import { DefaultCalendarComponent } from "../../../../infecto-lms-admin/components/core/form/DefaultCalendarComponent";

export interface CreateTreeFormState {
	title: string;
	vnrNumber?: string;
	vnrInternalNumber?: string;
	vnrPassword?: string;
	validFrom: string;
	validTo: string;
	pacPointsAmount?: number;
	bakPointsAmount?: number;
	certificateTemplateId?: string;
	category?: string;
	certifierRef?: string;
	tags: string[];
	length?: number;
}

export interface TreeCreationMutation {
	mutation: (formValues: CreateTreeFormState) => void;
}

interface Props {
	type: "create" | "clone";
	onSubmitCallback: (values: CreateTreeFormState) => void;
	displayMaximizable: boolean;
	setDisplayMaximizable: (visible: boolean) => void;
}

export const formInitialValues: CreateTreeFormState = {
	title: "",
	vnrNumber: "",
	vnrInternalNumber: "",
	vnrPassword: "",
	validFrom: "",
	validTo: "",
	pacPointsAmount: undefined,
	bakPointsAmount: undefined,
	certificateTemplateId: undefined,
	category: "",
	certifierRef: undefined,
	tags: [],
	length: 0,
};

const SideBySideWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const SideBySideDiv = styled.div`
	flex: 1;
`;

export const CreateTreeForm = ({
	type,
	onSubmitCallback,
	displayMaximizable,
	setDisplayMaximizable,
}: Props) => {
	const formik = useFormik<CreateTreeFormState>({
		enableReinitialize: false,
		initialValues: formInitialValues,
		onSubmit: (values, { setSubmitting }) => {
			onSubmitCallback(values);
			setSubmitting(false);
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Titel muss befüllt sein"),
			validFrom: Yup.string().when("type", {
				is: "create",
				then: Yup.string().required("'Von' muss befüllt sein"),
				otherwise: Yup.string().notRequired(),
			}),
			validTo: Yup.string().when("type", {
				is: "create",
				then: Yup.string().required("'Bis' muss befüllt sein"),
				otherwise: Yup.string().notRequired(),
			}),
			vnrNumber: Yup.string().when("type", {
				is: "create",
				then: Yup.string().required("Veranstaltungsnummer muss befüllt sein"),
				otherwise: Yup.string().notRequired(),
			}),
			vnrInternalNumber: Yup.string().when("type", {
				is: "create",
				then: Yup.string().required("Veranstaltungsnummer (Intern) muss befüllt sein"),
				otherwise: Yup.string().notRequired(),
			}),
			vnrPassword: Yup.string().when("type", {
				is: "create",
				then: Yup.string().required("Passwort muss befüllt sein"),
				otherwise: Yup.string().notRequired(),
			}),
			pacPointsAmount: Yup.number(),
			bakPointsAmount: Yup.number(),
			certificateTemplateId: Yup.string().when("bakPointsAmount", (bakPointsAmount) => {
				return bakPointsAmount > 0
					? Yup.string().required("Zertifikat wird benötigt.")
					: Yup.string().notRequired();
			}),
			category: Yup.string().when("type", {
				is: "create",
				then: Yup.string().required("Kategorie muss befüllt sein"),
				otherwise: Yup.string().notRequired(),
			}),
			certifierRef: Yup.string(),
			length: Yup.string().when("type", {
				is: "create",
				then: Yup.string().required("Länge muss befüllt sein"),
				otherwise: Yup.string().notRequired(),
			}),
		}),
	});

	return (
		<>
			<Dialog
				header={`${TREE_I18N_MAP(TREE_I18N_KEY.tree)} ${
					type === "create" ? "erstellen" : "duplizieren"
				}`}
				visible={displayMaximizable}
				maximizable
				modal
				style={{ width: "50vw" }}
				onHide={() => setDisplayMaximizable(false)}
			>
				<form onSubmit={formik.handleSubmit} className="p-fluid">
					<ValidatedField<CreateTreeFormState, string>
						name={"title"}
						label={"Titel"}
						component={DefaultTextFieldComponent}
						formikConfig={formik}
					/>

					{type === "create" && (
						<>
							<SideBySideWrapper>
								<SideBySideDiv className={"ml-1"}>
									<ValidatedField<CreateTreeFormState, string>
										name={"vnrPassword"}
										label={"VNR Passwort"}
										component={DefaultTextFieldComponent}
										formikConfig={formik}
									/>
								</SideBySideDiv>
							</SideBySideWrapper>
							<SideBySideWrapper>
								<SideBySideDiv className={"ml-1"}>
									<ValidatedField<CreateTreeFormState, string>
										name={"validFrom"}
										label={"von"}
										component={DefaultCalendarComponent}
										formikConfig={formik}
									/>
								</SideBySideDiv>
								<SideBySideDiv className={"ml-1"}>
									<ValidatedField<CreateTreeFormState, string>
										name={"validTo"}
										label={"bis"}
										component={DefaultCalendarComponent}
										formikConfig={formik}
									/>
								</SideBySideDiv>
							</SideBySideWrapper>
							<SideBySideWrapper>
								<SideBySideDiv className={"ml-1"}>
									<ValidatedField<CreateTreeFormState, string>
										name={"vnrNumber"}
										label={"1. Veranstaltungsnummer"}
										component={DefaultTextFieldComponent}
										formikConfig={formik}
									/>
								</SideBySideDiv>
								<SideBySideDiv className={"ml-1"}>
									<ValidatedField<CreateTreeFormState, string>
										name={"vnrInternalNumber"}
										label={"Veranstaltungsnummer (intern)"}
										component={DefaultTextFieldComponent}
										formikConfig={formik}
									/>
								</SideBySideDiv>
							</SideBySideWrapper>
							<SideBySideWrapper>
								<SideBySideDiv className={"ml-1"}>
									<ValidatedField<CreateTreeFormState, number>
										name={"bakPointsAmount"}
										label={"Anzahl BAK Punkte"}
										component={DefaultIntegerFieldComponent}
										formikConfig={formik}
									/>
								</SideBySideDiv>
								{formik.values.bakPointsAmount &&
									formik.values.bakPointsAmount > 0 && (
										<SideBySideDiv className={"ml-1"}>
											<ValidatedField<CreateTreeFormState, string>
												name={"certificateTemplateId"}
												label={"Zertifikats-Vorlage"}
												component={CertificateTemplateSelectionField}
												formikConfig={formik}
											/>
										</SideBySideDiv>
									)}
							</SideBySideWrapper>
							<SideBySideWrapper>
								<SideBySideDiv className={"ml-1"}>
									<ValidatedField<CreateTreeFormState, number>
										name={"pacPointsAmount"}
										label={"Anzahl PAC Punkte"}
										component={DefaultIntegerFieldComponent}
										formikConfig={formik}
									/>
								</SideBySideDiv>
								<SideBySideDiv className={"ml-1"}>
									<ValidatedField<CreateTreeFormState, string>
										name={"category"}
										label={"Kategorie"}
										component={DefaultTextFieldComponent}
										formikConfig={formik}
									/>
								</SideBySideDiv>
							</SideBySideWrapper>
							<SideBySideWrapper>
								<SideBySideDiv className={"ml-1"}>
									<ValidatedField<CreateTreeFormState, string>
										name={"certifierRef"}
										label={"Wissenschaftlicher Leiter"}
										component={InstructorDropdown}
										formikConfig={formik}
									/>
								</SideBySideDiv>
							</SideBySideWrapper>

							<ValidatedField<CreateTreeFormState, string[]>
								name={"tags"}
								label={"Schlagworte"}
								component={TagSelectionField}
								formikConfig={formik}
							/>

							<ValidatedField<CreateTreeFormState, string>
								name={"validFrom"}
								label={CONFIGS_TRANSLATIONS["VisibilityTree_AfterDateTime"]}
								component={DefaultCalendarComponent}
								formikConfig={formik}
							/>
							<ValidatedField<CreateTreeFormState, string>
								name={"validTo"}
								label={CONFIGS_TRANSLATIONS["VisibilityTree_NotAfterDateTime"]}
								component={DefaultCalendarComponent}
								formikConfig={formik}
							/>
							<ValidatedField<CreateTreeFormState, number>
								name={"length"}
								label={"Länge"}
								component={DefaultIntegerFieldComponent}
								formikConfig={formik}
							/>
						</>
					)}

					<Button
						disabled={Object.entries(formik.touched).length === 0 || !formik.isValid}
						type="submit"
						label="Speichern"
						className="mt-2"
					/>

					<Button
						type="button"
						onClick={() => {
							setDisplayMaximizable(false);
						}}
						label="Zurück"
						className="p-button-secondary mt-2"
					/>
				</form>
			</Dialog>
		</>
	);
};

import React, { useEffect } from "react";
import { RestartIfFailedContentConfigType } from "../../../__generated__/RestartIfFailedContentConfigEditor_TreeNodeFragment.graphql";
import {
	Config,
	ExtendConfigInterface,
} from "../../../infecto-lms-admin/components/tree/editor/node/config/components/Config.interfaces";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ProjectExtendRestartIfFailedContentConfigEditor_TreeNodeFragment$key } from "../../../__generated__/ProjectExtendRestartIfFailedContentConfigEditor_TreeNodeFragment.graphql";
import { ProjectExtendRestartIfFailedContentConfigEditor_RestartIfFailedContentConfigFragment$key } from "../../../__generated__/ProjectExtendRestartIfFailedContentConfigEditor_RestartIfFailedContentConfigFragment.graphql";
export const ExtendRestartIfFailedConfigs: Config<RestartIfFailedContentConfigType, any>[] = [];

const TREE_NODE_FRAGMENT = graphql`
	fragment ProjectExtendRestartIfFailedContentConfigEditor_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ContentTypeDefinition {
				restartIfFailedConfigs {
					id
					configType
				}
			}
		}
	}
`;

const CONFIGURATION_FRAGMENT = graphql`
	fragment ProjectExtendRestartIfFailedContentConfigEditor_RestartIfFailedContentConfigFragment on RestartIfFailedContentConfig {
		id
		configType
	}
`;

interface Props extends ExtendConfigInterface<RestartIfFailedContentConfigType> {
	treeNodeFragmentRef: ProjectExtendRestartIfFailedContentConfigEditor_TreeNodeFragment$key;
	configurationFragmentRef: ProjectExtendRestartIfFailedContentConfigEditor_RestartIfFailedContentConfigFragment$key;
}
export const ProjectExtendRestartIfFailedContentConfigEditor = (props: Props) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const treeNode =
		useFragment<ProjectExtendRestartIfFailedContentConfigEditor_TreeNodeFragment$key>(
			TREE_NODE_FRAGMENT,
			props.treeNodeFragmentRef,
		);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const configuration =
		useFragment<ProjectExtendRestartIfFailedContentConfigEditor_RestartIfFailedContentConfigFragment$key>(
			CONFIGURATION_FRAGMENT,
			props.configurationFragmentRef,
		);
	useEffect(() => {
		if (props.setCustomLabels) {
			props.setCustomLabels([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [treeNode, configuration]);
	return <div></div>;
};

import React, { useState } from "react";
import { Card } from "primereact/card";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { EditMessageDefinitionPageForm } from "./EditMessageDefinitionPageForm";
import { MessageDefinitionPagesOrderList } from "./MessageDefinitionPagesOrderList";
import { EditMessageDefinitionPageForm_MessageDefinitionPageFragment$key } from "../../../../../__generated__/EditMessageDefinitionPageForm_MessageDefinitionPageFragment.graphql";
import { MessageDefinitionPages_MessageDefinitionFragment$key } from "../../../../../__generated__/MessageDefinitionPages_MessageDefinitionFragment.graphql";

const CUSTOMER_MESSAGE_DEFINITION__FRAGMENT = graphql`
	fragment MessageDefinitionPages_MessageDefinitionFragment on MessageDefinition {
		...MessageDefinitionPagesOrderList_MessageDefinitionFragment
	}
`;

interface State {
	messageDefinitionPage?: EditMessageDefinitionPageForm_MessageDefinitionPageFragment$key;
}

interface OwnProps {
	messageDefinitionFragmentRef: MessageDefinitionPages_MessageDefinitionFragment$key;
}

export const MessageDefinitionPages = ({ messageDefinitionFragmentRef }: OwnProps) => {
	const [state, setState] = useState<State>({});

	const messageDefinition = useFragment<MessageDefinitionPages_MessageDefinitionFragment$key>(
		CUSTOMER_MESSAGE_DEFINITION__FRAGMENT,
		messageDefinitionFragmentRef,
	);

	return (
		<Card className="mb-2 mt-5">
			{state.messageDefinitionPage ? (
				<EditMessageDefinitionPageForm
					onClose={() => setState(() => ({}))}
					messageDefinitionPageFragmentRef={state.messageDefinitionPage}
				/>
			) : (
				<MessageDefinitionPagesOrderList
					editPage={(
						item: EditMessageDefinitionPageForm_MessageDefinitionPageFragment$key,
					) => setState({ messageDefinitionPage: item })}
					messageDefinitionFragmentRef={messageDefinition}
				/>
			)}
		</Card>
	);
};

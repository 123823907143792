import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { EmailRewardConfigurationForm } from "./email/EmailRewardConfigurationForm";
import { CertificatesRewardConfigurationForm } from "./certificate/CertificatesRewardConfigurationForm";
import { REWARD_TYPE_TRANSLATIONS } from "../../../infecto-lms-admin/i18n/graph/reward-type";
import { RewardForm_TreeNodeFragment$key } from "../../../__generated__/RewardForm_TreeNodeFragment.graphql";
import { RewardForm_RewardConfigurationV2Fragment$key } from "../../../__generated__/RewardForm_RewardConfigurationV2Fragment.graphql";
import { useTypedSelector } from "../../../infecto-lms-admin/redux/Store";
import { selectArrayOfEditedForms } from "../../../infecto-lms-admin/redux/slices/CoreSlice";
import { EditAwardPacPointsConfigurationForm } from "./pacpoints/EditAwardPacPointsConfigurationForm";
import { EditAwardPharmaPointsConfigurationForm } from "./pharmapoints/EditAwardPharmaPointsConfigurationForm";
import { EditFachberaterRewardConfigurationForm } from "./fachberater/EditFachberaterRewardConfigurationForm";

const TREE_NODE_FRAGMENT = graphql`
	fragment RewardForm_TreeNodeFragment on TreeNode {
		id
		...EmailRewardConfigurationForm_TreeNodeFragment
		...EditAwardPacPointsConfigurationForm_TreeNodeFragment
		...EditAwardPharmaPointsConfigurationForm_TreeNodeFragment
	}
`;

const REWARD_CONFIGURATION_FRAGMENT = graphql`
	fragment RewardForm_RewardConfigurationV2Fragment on Reward {
		id
		kind
		... on EmailRewardConfiguration {
			...EmailRewardConfigurationForm_EmailRewardConfigurationV2Fragment
		}
		... on AwardPacPointsRewardConfiguration {
			...EditAwardPacPointsConfigurationForm_AwardPacPointsFragment
		}
		... on AwardPharmaPointsRewardConfiguration {
			...EditAwardPharmaPointsConfigurationForm_AwardPharmaPointsFragment
		}
		... on CertificateRewardConfiguration {
			...CertificatesRewardConfigurationForm_CertificateRewardConfigurationV2Fragment
		}
		... on MakeFachberaterRewardConfiguration {
			...EditFachberaterRewardConfigurationForm_MakeFachberaterRewardConfigurationFragment
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: RewardForm_TreeNodeFragment$key;
	rewardConfigurationFragmentRef: RewardForm_RewardConfigurationV2Fragment$key;
	onBack: () => void;
}

export const RewardForm = ({
	treeNodeFragmentRef,
	rewardConfigurationFragmentRef,
	onBack,
}: OwnProps) => {
	const node = useFragment<RewardForm_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const rewardConfiguration = useFragment<RewardForm_RewardConfigurationV2Fragment$key>(
		REWARD_CONFIGURATION_FRAGMENT,
		rewardConfigurationFragmentRef,
	);

	const arrayOfEditedForm = useTypedSelector(selectArrayOfEditedForms);

	const Form = (() => {
		switch (rewardConfiguration.kind) {
			case "Email":
				return (
					<EmailRewardConfigurationForm
						treeNodeFragmentId={node}
						emailRewardConfigurationFragmentRef={rewardConfiguration}
						onBack={onBack}
					/>
				);
			case "Certificate":
				return (
					<CertificatesRewardConfigurationForm
						contentNodeId={node.id}
						certificateRewardConfigurationFragmentRef={rewardConfiguration}
						onBack={onBack}
					/>
				);
			case "PacPoints":
				return (
					<EditAwardPacPointsConfigurationForm
						rewardConfigurationFragmentRef={rewardConfiguration}
						treeNodeFragmentRef={node}
						onBack={onBack}
					/>
				);
			case "PharmaPoints":
				return (
					<EditAwardPharmaPointsConfigurationForm
						rewardConfigurationFragmentRef={rewardConfiguration}
						treeNodeFragmentRef={node}
						onBack={onBack}
					/>
				);
			case "MakeFachberater":
				return (
					<EditFachberaterRewardConfigurationForm
						contentNodeId={node.id}
						makeFachberaterRewardConfigurationFragmentRef={rewardConfiguration}
						onBack={onBack}
					/>
				);
			default:
				return null;
		}
	})();

	return (
		<div>
			<div className="flex align-items-center">
				<h2>{REWARD_TYPE_TRANSLATIONS[rewardConfiguration.kind]}</h2>
				<Button
					disabled={arrayOfEditedForm.length > 0}
					className="ml-auto"
					icon="pi pi-times"
					onClick={() => {
						onBack();
					}}
				/>
			</div>
			{Form}
		</div>
	);
};

import { Button } from "primereact/button";
import { OrderList } from "primereact/orderlist";
import { AnswerOptionDisplay } from "./AnswerOptionDisplay";
import { useState } from "react";
import { EditAnswerOptionForm } from "./EditAnswerOptionForm";
import { AnswerOptionV2Input } from "../../../../../../../../__generated__/MultipleChoiceElementForm_EditMultipleChoiceElementV2Mutation.graphql";

interface State {
	answerOptionIndexUnderEditing?: number;
}

interface OwnProps {
	answerOptions: AnswerOptionV2Input[];
	onUpdate: (answerOptions?: AnswerOptionV2Input[]) => void;
	onChange?: () => void;
}

export const AnswerOptionsField = ({ answerOptions, onUpdate, onChange }: OwnProps) => {
	const [state, setState] = useState<State>({});
	const answerOptionUnderEditing =
		state.answerOptionIndexUnderEditing !== undefined
			? answerOptions[state.answerOptionIndexUnderEditing]
			: undefined;

	return (
		<div>
			<div className="flex align-items-center">
				<div className="p-field flex-grow-1 flex-column mr-2">
					{answerOptionUnderEditing ? (
						<>
							<EditAnswerOptionForm
								answerOption={answerOptionUnderEditing}
								onUpdate={(value) => {
									onUpdate(
										answerOptions.map((ao, i) =>
											i === state.answerOptionIndexUnderEditing ? value : ao,
										),
									);
									if (onChange) {
										onChange();
									}
								}}
								onBack={() => {
									setState((state) => ({
										...state,
										answerOptionIndexUnderEditing: undefined,
									}));
								}}
							/>{" "}
						</>
					) : (
						<>
							<Button
								type="button"
								className="mb-2"
								label={"Neue Antwortmöglichkeit"}
								onClick={() => {
									onUpdate([
										...answerOptions,
										{ answer: "Neue Antwort", isCorrect: false },
									]);
								}}
							/>
							<OrderList
								value={answerOptions}
								onChange={(e) => onUpdate(e.value)}
								itemTemplate={(item) => {
									const index = answerOptions.indexOf(item);

									return (
										<AnswerOptionDisplay
											answerOption={item}
											index={index}
											onDelete={() =>
												onUpdate(
													[...answerOptions].filter(
														(ao, i) => i !== index,
													),
												)
											}
											onEdit={() =>
												setState((state) => ({
													...state,
													answerOptionIndexUnderEditing: index,
												}))
											}
										/>
									);
								}}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

/**
 * @generated SignedSource<<d45edaa0d8af5639549551105b4571d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UsersWhoMadeContentNodeTable_Query$variables = {
  contentNodeId?: string | null;
};
export type UsersWhoMadeContentNodeTable_QueryVariables = UsersWhoMadeContentNodeTable_Query$variables;
export type UsersWhoMadeContentNodeTable_Query$data = {
  readonly Admin: {
    readonly UsersList: {
      readonly AllUsers: ReadonlyArray<{
        readonly id: string;
        readonly extension: {
          readonly lastName?: string | null;
          readonly firstName?: string | null;
        };
        readonly " $fragmentSpreads": FragmentRefs<"UsersWhoMadeContentNodeTable_Fragment" | "UserContentSubmissionDialog_UserFragment">;
      }>;
    };
  };
};
export type UsersWhoMadeContentNodeTable_QueryResponse = UsersWhoMadeContentNodeTable_Query$data;
export type UsersWhoMadeContentNodeTable_Query = {
  variables: UsersWhoMadeContentNodeTable_QueryVariables;
  response: UsersWhoMadeContentNodeTable_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contentNodeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "contentNodeId",
    "variableName": "contentNodeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    }
  ],
  "type": "InfectopharmUserExtensionImpl",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UsersWhoMadeContentNodeTable_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UsersListAdminSchema",
            "kind": "LinkedField",
            "name": "UsersList",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "AllUsers",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "extension",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "UsersWhoMadeContentNodeTable_Fragment",
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "UserNameColumn_Fragment"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "UserActionColumn_NodeFragment"
                      }
                    ]
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserContentSubmissionDialog_UserFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UsersWhoMadeContentNodeTable_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UsersListAdminSchema",
            "kind": "LinkedField",
            "name": "UsersList",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "AllUsers",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "extension",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "40ffa25e80d0a5d3574cff14242cae39",
    "id": null,
    "metadata": {},
    "name": "UsersWhoMadeContentNodeTable_Query",
    "operationKind": "query",
    "text": "query UsersWhoMadeContentNodeTable_Query(\n  $contentNodeId: ID\n) {\n  Admin {\n    UsersList {\n      AllUsers(contentNodeId: $contentNodeId) {\n        id\n        extension {\n          __typename\n          ... on InfectopharmUserExtensionImpl {\n            lastName\n            firstName\n          }\n        }\n        ...UsersWhoMadeContentNodeTable_Fragment\n        ...UserContentSubmissionDialog_UserFragment\n      }\n    }\n  }\n}\n\nfragment UserActionColumn_NodeFragment on User {\n  id\n  ...UserContentSubmissionDialog_UserFragment\n}\n\nfragment UserContentSubmissionDialog_UserFragment on User {\n  id\n  extension {\n    __typename\n    ... on InfectopharmUserExtensionImpl {\n      firstName\n      lastName\n    }\n  }\n}\n\nfragment UserNameColumn_Fragment on User {\n  extension {\n    __typename\n    ... on InfectopharmUserExtensionImpl {\n      firstName\n      lastName\n    }\n  }\n}\n\nfragment UsersWhoMadeContentNodeTable_Fragment on User {\n  ...UserNameColumn_Fragment\n  ...UserActionColumn_NodeFragment\n}\n"
  }
};
})();

(node as any).hash = "a8f1264e8ab85664076f4e8ff51d3b8b";

export default node;

import { Dropdown } from "primereact/dropdown";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { NewNotificationTemplateButton_AvailableTemplatesFragment$key } from "../../../__generated__/NewNotificationTemplateButton_AvailableTemplatesFragment.graphql";
import { useState } from "react";
import { Button } from "primereact/button";
import { NewNotificationTemplateButton_CreateMutation } from "../../../__generated__/NewNotificationTemplateButton_CreateMutation.graphql";
import { useNavigate } from "react-router-dom";
import { NOTIFICATION_TEMPLATE_KEYS } from "../../i18n/notifications/i18n";

const AVAILABLE_Notification_TEMPLATES_FRAGMENT = graphql`
	fragment NewNotificationTemplateButton_AvailableTemplatesFragment on Query {
		Admin {
			Notification {
				AvailableSystemTemplates {
					key
					title
					message
					variables
				}
			}
		}
	}
`;

const NEW_Notification_TEMPLATE_MUTATION = graphql`
	mutation NewNotificationTemplateButton_CreateMutation(
		$input: CreatePersonalizedNotificationTemplateInput!
	) {
		Admin {
			Notification {
				createPersonalizedNotificationTemplate(input: $input) {
					data {
						node {
							id
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	availableTemplatesFragmentRef: NewNotificationTemplateButton_AvailableTemplatesFragment$key;
}

export const NewNotificationTemplateButton = ({ availableTemplatesFragmentRef }: OwnProps) => {
	const navigate = useNavigate();
	const [create, isCreating] = useMutation<NewNotificationTemplateButton_CreateMutation>(
		NEW_Notification_TEMPLATE_MUTATION,
	);
	const [selectedTemplate, setSelectedTemplate] = useState<string | undefined>(undefined);

	const availableTemplates =
		useFragment<NewNotificationTemplateButton_AvailableTemplatesFragment$key>(
			AVAILABLE_Notification_TEMPLATES_FRAGMENT,
			availableTemplatesFragmentRef,
		);

	return (
		<div>
			<Dropdown
				className="mr-2"
				emptyMessage="Alle Benachrichtigungs-Vorlagen wurden bereits erstellt"
				placeholder="Bitte Template auswählen"
				value={selectedTemplate}
				onChange={(e) => setSelectedTemplate(e.value)}
				options={availableTemplates.Admin.Notification.AvailableSystemTemplates.map(
					(t) => ({
						value: t.key,
						label: NOTIFICATION_TEMPLATE_KEYS[t.key]
							? NOTIFICATION_TEMPLATE_KEYS[t.key]
							: t.key,
					}),
				)}
			/>
			<Button
				onClick={() => {
					const selected =
						availableTemplates.Admin.Notification.AvailableSystemTemplates.find(
							(t) => t.key === selectedTemplate,
						)!;
					create({
						variables: {
							input: {
								data: { ...selected, variables: [...selected.variables] },
							},
						},
						onCompleted: (r) => {
							navigate(
								`/notification-templates/${r.Admin.Notification.createPersonalizedNotificationTemplate?.data.node.id}/edit`,
							);
						},
					});
				}}
				disabled={
					availableTemplates.Admin.Notification.AvailableSystemTemplates.length === 0 ||
					isCreating ||
					!selectedTemplate
				}
				label={"Erstellen"}
			/>
		</div>
	);
};

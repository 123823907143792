/**
 * @generated SignedSource<<3681556935051e79d25ef037a2301545>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteContentNodeActiveContentSubmissionsAndElementsInput = {
  contentNodeId: string;
  clientMutationId?: string | null;
};
export type DeleteActiveContentSubmissionsButton_DeleteMutation$variables = {
  input: DeleteContentNodeActiveContentSubmissionsAndElementsInput;
};
export type DeleteActiveContentSubmissionsButton_DeleteMutationVariables = DeleteActiveContentSubmissionsButton_DeleteMutation$variables;
export type DeleteActiveContentSubmissionsButton_DeleteMutation$data = {
  readonly Admin: {
    readonly DeleteContentNodeActiveContentSubmissionAndLearnElements: {
      readonly DeleteContentNodeActiveContentSubmissionsAndElements: {
        readonly clientMutationId: string | null;
      } | null;
    };
  };
};
export type DeleteActiveContentSubmissionsButton_DeleteMutationResponse = DeleteActiveContentSubmissionsButton_DeleteMutation$data;
export type DeleteActiveContentSubmissionsButton_DeleteMutation = {
  variables: DeleteActiveContentSubmissionsButton_DeleteMutationVariables;
  response: DeleteActiveContentSubmissionsButton_DeleteMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeleteContentNodeActiveContentSubmissionAndLearnElementsAdminMutationSchema",
        "kind": "LinkedField",
        "name": "DeleteContentNodeActiveContentSubmissionAndLearnElements",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "DeleteContentNodeActiveContentSubmissionsAndElementsPayload",
            "kind": "LinkedField",
            "name": "DeleteContentNodeActiveContentSubmissionsAndElements",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteActiveContentSubmissionsButton_DeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteActiveContentSubmissionsButton_DeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d17f56a011efffdfc66cc71297f8fe22",
    "id": null,
    "metadata": {},
    "name": "DeleteActiveContentSubmissionsButton_DeleteMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteActiveContentSubmissionsButton_DeleteMutation(\n  $input: DeleteContentNodeActiveContentSubmissionsAndElementsInput!\n) {\n  Admin {\n    DeleteContentNodeActiveContentSubmissionAndLearnElements {\n      DeleteContentNodeActiveContentSubmissionsAndElements(input: $input) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "510a2cb3883908cf85799cfae25bfaf1";

export default node;

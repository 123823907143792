import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ContentColumn_TreeNodeFragment$key } from "../../../../../__generated__/ContentColumn_TreeNodeFragment.graphql";

const TREE_NODE_FRAGMENT = graphql`
	fragment ContentColumn_TreeNodeFragment on TreeNode {
		typeDefinition {
			definitionType
			... on ELearningContentTypeDefinition {
				elements {
					__id
				}
			}
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: ContentColumn_TreeNodeFragment$key;
}

export const ContentColumn = ({ treeNodeFragmentRef }: OwnProps) => {
	const treeNode = useFragment<ContentColumn_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const content = (() => {
		switch (treeNode.typeDefinition.definitionType) {
			case "branch": {
				return "Komplex";
			}
			case "content": {
				return `E-Learning (${treeNode.typeDefinition.elements?.length} Bausteine)`;
			}
			default: {
				return null;
			}
		}
	})();

	return <div>{content}</div>;
};

/**
 * @generated SignedSource<<2a25dff516a1a1844552cc5b4238637f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnPercentagePassContentConfigForm_TreeNodeFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "OnPercentagePassContentConfigForm_TreeNodeFragment";
};
export type OnPercentagePassContentConfigForm_TreeNodeFragment = OnPercentagePassContentConfigForm_TreeNodeFragment$data;
export type OnPercentagePassContentConfigForm_TreeNodeFragment$key = {
  readonly " $data"?: OnPercentagePassContentConfigForm_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnPercentagePassContentConfigForm_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnPercentagePassContentConfigForm_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "3b0e5a7d5b8e11f326216445f73fbdee";

export default node;

import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ProjectExtendElementForms_ElementV2Fragment$key } from "../../../__generated__/ProjectExtendElementForms_ElementV2Fragment.graphql";
import { RandomMultipleChoiceElementForm } from "../../../app/components/tree/editor/node/content/elearning/RandomMultipleChoiceElementForm";

const ELEMENT_FRAGMENT = graphql`
	fragment ProjectExtendElementForms_ElementV2Fragment on ElementV2 {
		title
		elementType
		...RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment
	}
`;

interface OwnProps {
	elementFragmentRef: ProjectExtendElementForms_ElementV2Fragment$key;
	onBack: () => void;
}

export const ProjectExtendElementForms = ({ elementFragmentRef, onBack }: OwnProps) => {
	const element = useFragment<ProjectExtendElementForms_ElementV2Fragment$key>(
		ELEMENT_FRAGMENT,
		elementFragmentRef,
	);

	const Form = (() => {
		switch (element.elementType) {
			case "randomMultipleChoice":
				return (
					<RandomMultipleChoiceElementForm
						randomMultipleChoiceElementFragmentRef={element}
						onBack={onBack}
					/>
				);

			default:
				return null;
		}
	})();
	return <div>{Form}</div>;
};

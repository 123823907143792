/**
 * @generated SignedSource<<ded83b1b1fadc7cdaa8db30a767b2953>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CertificateTemplatesScreen_CertificateTemplatesQuery$variables = {};
export type CertificateTemplatesScreen_CertificateTemplatesQueryVariables = CertificateTemplatesScreen_CertificateTemplatesQuery$variables;
export type CertificateTemplatesScreen_CertificateTemplatesQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CertificateTemplatesTable_QueryFragment">;
};
export type CertificateTemplatesScreen_CertificateTemplatesQueryResponse = CertificateTemplatesScreen_CertificateTemplatesQuery$data;
export type CertificateTemplatesScreen_CertificateTemplatesQuery = {
  variables: CertificateTemplatesScreen_CertificateTemplatesQueryVariables;
  response: CertificateTemplatesScreen_CertificateTemplatesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CertificateTemplatesScreen_CertificateTemplatesQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CertificateTemplatesTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CertificateTemplatesScreen_CertificateTemplatesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CertificatesAdminSchema",
            "kind": "LinkedField",
            "name": "Certificates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CertificateTemplatesConnection",
                "kind": "LinkedField",
                "name": "CertificateTemplates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CertificateTemplatesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CertificateTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "templateFile",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": [
                  "filterByName"
                ],
                "handle": "connection",
                "key": "CertificateTemplatesTable_CertificateTemplates",
                "kind": "LinkedHandle",
                "name": "CertificateTemplates"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "28007387013abfd9bfeb5970a9249e87",
    "id": null,
    "metadata": {},
    "name": "CertificateTemplatesScreen_CertificateTemplatesQuery",
    "operationKind": "query",
    "text": "query CertificateTemplatesScreen_CertificateTemplatesQuery {\n  ...CertificateTemplatesTable_QueryFragment\n}\n\nfragment CertificateTemplatesTable_QueryFragment on Query {\n  Admin {\n    Certificates {\n      CertificateTemplates {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            name\n            ...ShowCertificateButton_CertificateFragment\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n\nfragment ShowCertificateButton_CertificateFragment on CertificateTemplate {\n  name\n  templateFile {\n    url\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5afc8f0338b0cb63bf2a306a38f9c376";

export default node;

import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { DescriptionColumn_LandingPageFragment$key } from "../../../../__generated__/DescriptionColumn_LandingPageFragment.graphql";
import styled from "styled-components";

const LANDING_PAGE_FRAGMENT = graphql`
	fragment DescriptionColumn_LandingPageFragment on LandingPageV2 {
		description
	}
`;

interface OwnProps {
	landingPageFragmentRef: DescriptionColumn_LandingPageFragment$key;
}

export const DescriptionColumn = ({ landingPageFragmentRef }: OwnProps) => {
	const landingPage = useFragment<DescriptionColumn_LandingPageFragment$key>(
		LANDING_PAGE_FRAGMENT,
		landingPageFragmentRef,
	);

	return (
		<DescriptionField>
			{landingPage.description.replace(/<[^>]+>/g, "").substring(0, 150)}...
		</DescriptionField>
	);
};

const DescriptionField = styled.div`
	font-size: 0.75rem;
`;

/**
 * @generated SignedSource<<6e2821f963665928aa1de216d294e9f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentSubmissionStatus = "active" | "passed" | "failed" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StatusColumn_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly status: ContentSubmissionStatus;
  };
  readonly " $fragmentType": "StatusColumn_ContentSubmissionFragment";
};
export type StatusColumn_ContentSubmissionFragment = StatusColumn_ContentSubmissionFragment$data;
export type StatusColumn_ContentSubmissionFragment$key = {
  readonly " $data"?: StatusColumn_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"StatusColumn_ContentSubmissionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StatusColumn_ContentSubmissionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};

(node as any).hash = "31d1530d3f81332075ea8d865c3c8a61";

export default node;

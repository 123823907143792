import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { useContext } from "react";
import { PointsAdditionSearchContext } from "./additions/PointsAdditionSearchContainer";
import { PointsSum_Query } from "../../../__generated__/PointsSum_Query.graphql";

const QUERY = graphql`
	query PointsSum_Query(
		$userId: ID!
		$pointsPoolId: ID!
		$filterByMinDateTime: ZonedDateTIme
		$skip: Boolean!
	) {
		Admin {
			PointsV2 {
				SumPoints(
					filterByUserId: $userId
					filterByPointsPoolId: $pointsPoolId
					filterByMinDateTime: $filterByMinDateTime
				) @skip(if: $skip) {
					points
					pointPoolName
				}
			}
		}
	}
`;

export const PointsSum = () => {
	const {
		filters: { filterByPointsPool, filterByUser, filterByMinDateTime },
	} = useContext(PointsAdditionSearchContext);

	const skip = !filterByUser || !filterByPointsPool;

	const query = useLazyLoadQuery<PointsSum_Query>(QUERY, {
		pointsPoolId: filterByPointsPool || "",
		userId: filterByUser || "",
		filterByMinDateTime: filterByMinDateTime,
		skip: skip,
	});

	if (skip) {
		return (
			<p>
				Wählen Sie einen Nutzer und einen Punkte-Pool in den Filtern aus um den Punktestand
				des Benutzers anzuzeigen.
			</p>
		);
	}

	return (
		<div className="mb-3">
			<strong>Punktestand:</strong> {query.Admin.PointsV2.SumPoints?.points}{" "}
			{query.Admin.PointsV2.SumPoints?.pointPoolName}
		</div>
	);
};

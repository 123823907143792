/**
 * @generated SignedSource<<506ce355351bccd2db40fbb1d46f5fad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditInstructorScreen_InstructorFragment$data = {
  readonly id: string;
  readonly superId: string;
  readonly name: string;
  readonly title: string | null;
  readonly description: string | null;
  readonly location: string | null;
  readonly portraitFile: {
    readonly id: string;
    readonly url: string | null;
  } | null;
  readonly signatureFile: {
    readonly id: string;
    readonly url: string | null;
  } | null;
  readonly " $fragmentType": "EditInstructorScreen_InstructorFragment";
};
export type EditInstructorScreen_InstructorFragment = EditInstructorScreen_InstructorFragment$data;
export type EditInstructorScreen_InstructorFragment$key = {
  readonly " $data"?: EditInstructorScreen_InstructorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditInstructorScreen_InstructorFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditInstructorScreen_InstructorFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "superId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "portraitFile",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "signatureFile",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "InstructorImpl",
  "abstractKey": null
};
})();

(node as any).hash = "999b795a2437aaf9ea6484d935782837";

export default node;

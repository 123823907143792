/**
 * @generated SignedSource<<e20c273953d9d5fa1122e66192877552>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddOnlySpecificPermissionsVisibilityTreeConfigInput = {
  dateTime: string;
  rootNodeId: string;
  clientMutationId?: string | null;
};
export type OnlySpecificPermissionsVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation$variables = {
  input: AddOnlySpecificPermissionsVisibilityTreeConfigInput;
};
export type OnlySpecificPermissionsVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutationVariables = OnlySpecificPermissionsVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation$variables;
export type OnlySpecificPermissionsVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly addOnlySpecificPermissionsVisibilityTreeConfig: {
        readonly rootNode: {
          readonly " $fragmentSpreads": FragmentRefs<"OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment">;
        };
      } | null;
    };
  };
};
export type OnlySpecificPermissionsVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutationResponse = OnlySpecificPermissionsVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation$data;
export type OnlySpecificPermissionsVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation = {
  variables: OnlySpecificPermissionsVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutationVariables;
  response: OnlySpecificPermissionsVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OnlySpecificPermissionsVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddOnlySpecificPermissionsVisibilityTreeConfigPayload",
                "kind": "LinkedField",
                "name": "addOnlySpecificPermissionsVisibilityTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OnlySpecificPermissionsVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddOnlySpecificPermissionsVisibilityTreeConfigPayload",
                "kind": "LinkedField",
                "name": "addOnlySpecificPermissionsVisibilityTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "visibilityConfigs",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "TypeDiscriminator",
                                    "abstractKey": "__isVisibilityTreeConfig"
                                  },
                                  (v2/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "configType",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "dateTime",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "OnlySpecificPermissionsVisibilityTreeConfig",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e520e1172fa096d0524a3587f78efb6",
    "id": null,
    "metadata": {},
    "name": "OnlySpecificPermissionsVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation",
    "operationKind": "mutation",
    "text": "mutation OnlySpecificPermissionsVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation(\n  $input: AddOnlySpecificPermissionsVisibilityTreeConfigInput!\n) {\n  Admin {\n    Tree {\n      addOnlySpecificPermissionsVisibilityTreeConfig(input: $input) {\n        rootNode {\n          ...OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment on TreeNode {\n  id\n  structureDefinition {\n    __typename\n    ... on RootStructureDefinition {\n      visibilityConfigs {\n        __typename\n        ...OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment\n        id\n      }\n    }\n  }\n}\n\nfragment OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment on VisibilityTreeConfig {\n  __isVisibilityTreeConfig: __typename\n  ... on OnlySpecificPermissionsVisibilityTreeConfig {\n    id\n    configType\n    dateTime\n  }\n}\n"
  }
};
})();

(node as any).hash = "b48a4f62ac211861788752f8ea4be4e1";

export default node;

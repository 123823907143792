/**
 * @generated SignedSource<<485285f117843fb592a8d0dbb24089d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AcknowledgePublishingV2Input = {
  publishingId: string;
  clientMutationId?: string | null;
};
export type PublishingPart_AcknowledgePublishingV2Mutation$variables = {
  input: AcknowledgePublishingV2Input;
};
export type PublishingPart_AcknowledgePublishingV2MutationVariables = PublishingPart_AcknowledgePublishingV2Mutation$variables;
export type PublishingPart_AcknowledgePublishingV2Mutation$data = {
  readonly Admin: {
    readonly PublishingV2: {
      readonly acknowledgePublishingV2: {
        readonly publishing: {
          readonly " $fragmentSpreads": FragmentRefs<"PublishingPart_PublishingV2Fragment">;
        };
      } | null;
    };
  };
};
export type PublishingPart_AcknowledgePublishingV2MutationResponse = PublishingPart_AcknowledgePublishingV2Mutation$data;
export type PublishingPart_AcknowledgePublishingV2Mutation = {
  variables: PublishingPart_AcknowledgePublishingV2MutationVariables;
  response: PublishingPart_AcknowledgePublishingV2Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PublishingPart_AcknowledgePublishingV2Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PublishingV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "PublishingV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AcknowledgePublishingV2Payload",
                "kind": "LinkedField",
                "name": "acknowledgePublishingV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PublishingV2",
                    "kind": "LinkedField",
                    "name": "publishing",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "PublishingPart_PublishingV2Fragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PublishingPart_AcknowledgePublishingV2Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PublishingV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "PublishingV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AcknowledgePublishingV2Payload",
                "kind": "LinkedField",
                "name": "acknowledgePublishingV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PublishingV2",
                    "kind": "LinkedField",
                    "name": "publishing",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "initiatedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "finishedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PublishingMessageV2",
                        "kind": "LinkedField",
                        "name": "messages",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "timestamp",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "message",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "additionalMessage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canBeAcknowledged",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "390cfb7fdf76723b2f77f72e46b54115",
    "id": null,
    "metadata": {},
    "name": "PublishingPart_AcknowledgePublishingV2Mutation",
    "operationKind": "mutation",
    "text": "mutation PublishingPart_AcknowledgePublishingV2Mutation(\n  $input: AcknowledgePublishingV2Input!\n) {\n  Admin {\n    PublishingV2 {\n      acknowledgePublishingV2(input: $input) {\n        publishing {\n          ...PublishingPart_PublishingV2Fragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment PublishingPart_PublishingV2Fragment on PublishingV2 {\n  id\n  initiatedAt\n  finishedAt\n  messages {\n    timestamp\n    message\n    additionalMessage\n  }\n  status\n  canBeAcknowledged\n}\n"
  }
};
})();

(node as any).hash = "0a8d300afaaa5078ec8a936bfe691f00";

export default node;

/**
 * @generated SignedSource<<266fa90622fc7d3b6cb48658787165c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserRolesInput = "Doctor" | "Apothecary" | "Midwife" | "PTA" | "PKA" | "MTA" | "PharmaceuticalEngineer" | "Doccheck" | "Unknown" | "Other" | "%future added value";
export type EditSpecificUserRolesCanStartTreeConfigInput = {
  rootNodeId: string;
  configId: string;
  newUserRoles: ReadonlyArray<UserRolesInput>;
  clientMutationId?: string | null;
};
export type SpecificUserRolesCanStartTreeConfigForm_EditMutation$variables = {
  input: EditSpecificUserRolesCanStartTreeConfigInput;
};
export type SpecificUserRolesCanStartTreeConfigForm_EditMutationVariables = SpecificUserRolesCanStartTreeConfigForm_EditMutation$variables;
export type SpecificUserRolesCanStartTreeConfigForm_EditMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly EditSpecificUserRolesCanStartTreeConfig: {
        readonly rootNode: {
          readonly " $fragmentSpreads": FragmentRefs<"SpecificUserRolesCanStartTreeConfigForm_TreeNodeFragment">;
        };
      } | null;
    };
  };
};
export type SpecificUserRolesCanStartTreeConfigForm_EditMutationResponse = SpecificUserRolesCanStartTreeConfigForm_EditMutation$data;
export type SpecificUserRolesCanStartTreeConfigForm_EditMutation = {
  variables: SpecificUserRolesCanStartTreeConfigForm_EditMutationVariables;
  response: SpecificUserRolesCanStartTreeConfigForm_EditMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SpecificUserRolesCanStartTreeConfigForm_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditSpecificUserRolesCanStartTreeConfigPayload",
                "kind": "LinkedField",
                "name": "EditSpecificUserRolesCanStartTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "SpecificUserRolesCanStartTreeConfigForm_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SpecificUserRolesCanStartTreeConfigForm_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditSpecificUserRolesCanStartTreeConfigPayload",
                "kind": "LinkedField",
                "name": "EditSpecificUserRolesCanStartTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "startConfigs",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "TypeDiscriminator",
                                    "abstractKey": "__isStartTreeConfig"
                                  },
                                  (v2/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "configType",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "userRoles",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "SpecifiedUserRolesCanStartTreeConfig",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3d2e7f920c43190f6bc024679943c681",
    "id": null,
    "metadata": {},
    "name": "SpecificUserRolesCanStartTreeConfigForm_EditMutation",
    "operationKind": "mutation",
    "text": "mutation SpecificUserRolesCanStartTreeConfigForm_EditMutation(\n  $input: EditSpecificUserRolesCanStartTreeConfigInput!\n) {\n  Admin {\n    Tree {\n      EditSpecificUserRolesCanStartTreeConfig(input: $input) {\n        rootNode {\n          ...SpecificUserRolesCanStartTreeConfigForm_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment SpecificUserRolesCanStartTreeConfigForm_StartTreeConfigFragment on StartTreeConfig {\n  __isStartTreeConfig: __typename\n  ... on SpecifiedUserRolesCanStartTreeConfig {\n    id\n    configType\n    userRoles\n  }\n}\n\nfragment SpecificUserRolesCanStartTreeConfigForm_TreeNodeFragment on TreeNode {\n  id\n  structureDefinition {\n    __typename\n    ... on RootStructureDefinition {\n      startConfigs {\n        __typename\n        ...SpecificUserRolesCanStartTreeConfigForm_StartTreeConfigFragment\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8037903490f9c02c388eb057bc95e828";

export default node;

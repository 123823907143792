import { InstructorsScreen } from "../../infecto-lms-admin/screens/InstructorsScreen";
import {
	EDIT_INSTRUCTOR_ROUTE,
	EditInstructorScreen,
} from "../../infecto-lms-admin/screens/EditInstructorScreen";
import { PrivateRouteDefinition } from "../../infecto-lms-admin/components/core/model/PrivateRouteDefinition";

export const InstructorModuleRoutes: PrivateRouteDefinition[] = [
	{
		path: "/instructors",
		element: <InstructorsScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_Instructors_ReadInstructors",
		],
	},
	{
		path: EDIT_INSTRUCTOR_ROUTE,
		element: <EditInstructorScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_Instructors_UpdateInstructors",
		],
	},
];

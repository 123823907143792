/**
 * @generated SignedSource<<56298e0e14fcf0e535e9f1ffa7acf2e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentSubmissionsTable_ContentSubmissionFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LastUpdatedAtColumn_ContentSubmissionFragment" | "StartedAtColumn_ContentSubmissionFragment" | "ProjectRewardsColumn_ContentSubmissionFragment" | "RootNodeTitleColumn_ContentSubmissionFragment" | "StatusColumn_ContentSubmissionFragment">;
  readonly " $fragmentType": "ContentSubmissionsTable_ContentSubmissionFragment";
};
export type ContentSubmissionsTable_ContentSubmissionFragment = ContentSubmissionsTable_ContentSubmissionFragment$data;
export type ContentSubmissionsTable_ContentSubmissionFragment$key = {
  readonly " $data"?: ContentSubmissionsTable_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentSubmissionsTable_ContentSubmissionFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ContentSubmissionsTable_ContentSubmissionFragment"
};

(node as any).hash = "ebf2c490cf1973bbe4c9b42e7d10ec54";

export default node;

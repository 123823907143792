import { OrderList } from "primereact/orderlist";
import { ELEMENT_TYPE_TRANSLATIONS } from "../../../../infecto-lms-admin/i18n/graph/element-type";
import { Button } from "primereact/button";
import { BaseContentEditor } from "../../../../infecto-lms-admin/components/tree/editor/node/content/BaseContentEditor";
import { useCallback, useState } from "react";
import { selectArrayOfEditedForms } from "../../../../infecto-lms-admin/redux/slices/CoreSlice";
import { graphql } from "babel-plugin-relay/macro";
import { WarningUnsavedChangesDialog } from "../../../../infecto-lms-admin/components/core/dialog/WarningUnsavedChangesDialog";
import { PoolElementForm_ElementV2Fragment$key } from "../../../../__generated__/PoolElementForm_ElementV2Fragment.graphql";
import { PoolElementForm } from "./PoolElementForm";
import { useLazyLoadQuery, useMutation } from "react-relay";
import { MultipleChoiceElementsPool_Query } from "../../../../__generated__/MultipleChoiceElementsPool_Query.graphql";
import { MultipleChoiceElementsPool_CreateMultipleChoiceElementV2Mutation } from "../../../../__generated__/MultipleChoiceElementsPool_CreateMultipleChoiceElementV2Mutation.graphql";
import { htmlTagsInTextRemover } from "../../../../infecto-lms-admin/functions/htmlTagsInTextRemover";
import styled from "styled-components";
import { MultipleChoiceElementsPool_DeleteMultipleChoiceElementV2Mutation } from "../../../../__generated__/MultipleChoiceElementsPool_DeleteMultipleChoiceElementV2Mutation.graphql";
import { FetchPolicy } from "relay-runtime";
import { ImportRandomMultipleChoiceElementsButton } from "./ImportRandomMultipleChoiceElementsButton";
import { useTypedSelector } from "../../../../infecto-lms-admin/redux/Store";

const QUERY = graphql`
	query MultipleChoiceElementsPool_Query($elementsPoolId: ID!) {
		Admin {
			Elementspool {
				MultipleChoiceElementsPool(id: $elementsPoolId) {
					id
					elements {
						id
						elementType
						title
						question
						...PoolElementForm_ElementV2Fragment
					}
				}
			}
		}
	}
`;

const CREATE_MULTIPLE_CHOICE_POOL_ELEMENT_MUTATION = graphql`
	mutation MultipleChoiceElementsPool_CreateMultipleChoiceElementV2Mutation(
		$input: AddElementsPoolElementInput!
	) {
		Admin {
			Elementspool {
				addElementsPoolElement(input: $input) {
					pool {
						id
						elements {
							id
							elementType
							title
							question
							...PoolElementForm_ElementV2Fragment
						}
					}
				}
			}
		}
	}
`;

const DELETE_MULTIPLE_CHOICE_POOL_ELEMENT_MUTATION = graphql`
	mutation MultipleChoiceElementsPool_DeleteMultipleChoiceElementV2Mutation(
		$input: DeleteElementsPoolElementInput!
	) {
		Admin {
			Elementspool {
				deleteElementsPoolElement(input: $input) {
					pool {
						id
						elements {
							id
							elementType
							title
							question
							...PoolElementForm_ElementV2Fragment
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	elementsPoolId: string;
}

const OrderListWithoutControls = styled(OrderList)`
	.p-orderlist-controls {
		display: none;
	}

	.p-orderlist-list {
		.p-disabled {
			opacity: 1;
		}
	}
`;

export const MultipleChoiceElementsPool = ({ elementsPoolId }: OwnProps) => {
	const networkOnly: FetchPolicy = "network-only";
	const [queryArgs, setQueryArgs] = useState({ fetchKey: 0, fetchPolicy: networkOnly });

	const query = useLazyLoadQuery<MultipleChoiceElementsPool_Query>(
		QUERY,
		{
			elementsPoolId: elementsPoolId,
		},

		queryArgs,
	);

	const refresh = useCallback(() => {
		setQueryArgs((prev) => ({
			fetchKey: (prev?.fetchKey ?? 0) + 1,
			fetchPolicy: "network-only",
		}));
	}, []);

	const [createMultipleChoicePoolElement, isCreatingMultipleChoicePoolElement] =
		useMutation<MultipleChoiceElementsPool_CreateMultipleChoiceElementV2Mutation>(
			CREATE_MULTIPLE_CHOICE_POOL_ELEMENT_MUTATION,
		);

	const [deleteMultipleChoicePoolElement, isDeletingMultipleChoicePoolElement] =
		useMutation<MultipleChoiceElementsPool_DeleteMultipleChoiceElementV2Mutation>(
			DELETE_MULTIPLE_CHOICE_POOL_ELEMENT_MUTATION,
		);

	const [currentlyEditingElement, setCurrentlyEditingElement] = useState<
		PoolElementForm_ElementV2Fragment$key | undefined
	>();

	const [showDialog, setShowDialog] = useState(false);

	const arrayOfEditedForm = useTypedSelector(selectArrayOfEditedForms);

	return currentlyEditingElement ? (
		<>
			{showDialog && (
				<WarningUnsavedChangesDialog
					setShowDialog={setShowDialog}
					callback={setCurrentlyEditingElement}
					value={undefined}
				/>
			)}
			<PoolElementForm
				poolId={elementsPoolId}
				onBack={() => {
					if (arrayOfEditedForm.length > 0) {
						setShowDialog(true);
					} else {
						setCurrentlyEditingElement(undefined);
					}
				}}
				elementFragmentRef={currentlyEditingElement}
			/>
		</>
	) : (
		<BaseContentEditor>
			<div className="p-buttonset mb-2">
				<Button
					className={"mr-2"}
					disabled={isCreatingMultipleChoicePoolElement}
					icon="pi pi-plus"
					onClick={() => {
						createMultipleChoicePoolElement({
							variables: {
								input: {
									title: "Neues Element",
									question: "Neue Frage",
									answeringType: "allowOnlyOneAnswer",
									answerOptions: [],
									poolId: elementsPoolId,
								},
							},
						});
					}}
				/>

				<ImportRandomMultipleChoiceElementsButton
					refresh={refresh}
					poolId={elementsPoolId}
				/>
			</div>
			<OrderListWithoutControls
				dragdrop={false}
				itemTemplate={(item) => (
					<div
						className="flex align-items-center"
						onDoubleClick={() => {
							setCurrentlyEditingElement(item);
						}}
					>
						{ELEMENT_TYPE_TRANSLATIONS[item.elementType]}:{" "}
						{htmlTagsInTextRemover(item.question)}
						<Button
							disabled={isDeletingMultipleChoicePoolElement}
							className="ml-auto"
							icon="pi pi-trash"
							onClick={() => {
								deleteMultipleChoicePoolElement({
									variables: {
										input: {
											poolId: elementsPoolId,
											elementId: item.id,
										},
									},
								});
							}}
						/>
						<Button
							disabled={false}
							className="ml-2"
							icon="pi pi-pencil"
							onClick={() => {
								setCurrentlyEditingElement(item);
							}}
						/>
					</div>
				)}
				value={[...(query.Admin.Elementspool.MultipleChoiceElementsPool.elements || [])]}
			/>
		</BaseContentEditor>
	);
};

/**
 * @generated SignedSource<<f07e7b13dea30a9ac84f542e5c47d25f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmailTesterFeedbackEmailForm_FeedbackQueryFragment$data = {
  readonly Admin: {
    readonly FeedbackV2: {
      readonly Feedbacks: {
        readonly pageInfo: {
          readonly endCursor: string | null;
          readonly hasPreviousPage: boolean;
          readonly hasNextPage: boolean;
          readonly startCursor: string | null;
        };
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly body: string;
          };
        } | null> | null;
      };
    };
  };
  readonly " $fragmentType": "EmailTesterFeedbackEmailForm_FeedbackQueryFragment";
};
export type EmailTesterFeedbackEmailForm_FeedbackQueryFragment = EmailTesterFeedbackEmailForm_FeedbackQueryFragment$data;
export type EmailTesterFeedbackEmailForm_FeedbackQueryFragment$key = {
  readonly " $data"?: EmailTesterFeedbackEmailForm_FeedbackQueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmailTesterFeedbackEmailForm_FeedbackQueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Admin",
  "FeedbackV2",
  "Feedbacks"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./EmailTesterFeedbackEmailForm_Refetch.graphql')
    }
  },
  "name": "EmailTesterFeedbackEmailForm_FeedbackQueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FeedbackV2AdminSchema",
          "kind": "LinkedField",
          "name": "FeedbackV2",
          "plural": false,
          "selections": [
            {
              "alias": "Feedbacks",
              "args": null,
              "concreteType": "FeedbacksConnection",
              "kind": "LinkedField",
              "name": "__EmailTesterFeedbackEmailForm_Feedbacks_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasPreviousPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startCursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FeedbacksEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FeedbackV2",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "body",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "ea9d70e68e109c4f8aeb35254e14aaa3";

export default node;

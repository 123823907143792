import { PrivateRouteDefinition } from "../../infecto-lms-admin/components/core/model/PrivateRouteDefinition";
import { StagingScreenV2 } from "../../infecto-lms-admin/screens/StagingScreenV2";

export const STAGING_PATH_V2 = "/staging";

export const StagingModuleRoutes: PrivateRouteDefinition[] = [
	{
		path: STAGING_PATH_V2,
		element: <StagingScreenV2 />,
		requiredPermissions: ["AccountPermission_System_Root"],
	},
];

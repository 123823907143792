/**
 * @generated SignedSource<<aefdfa46568f21c33e50fec3ae41ef25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectUserDescription_UserFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserDescription_UserFragment">;
  readonly " $fragmentType": "ProjectUserDescription_UserFragment";
};
export type ProjectUserDescription_UserFragment = ProjectUserDescription_UserFragment$data;
export type ProjectUserDescription_UserFragment$key = {
  readonly " $data"?: ProjectUserDescription_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectUserDescription_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectUserDescription_UserFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserDescription_UserFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "859e98bb28e2ed207b162f8f68d3668a";

export default node;

/**
 * @generated SignedSource<<7b10e70c564eafd71f18d789063892b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditAwardPharmaPointsConfigurationForm_AwardPharmaPointsFragment$data = {
  readonly id: string;
  readonly amount: number;
  readonly " $fragmentType": "EditAwardPharmaPointsConfigurationForm_AwardPharmaPointsFragment";
};
export type EditAwardPharmaPointsConfigurationForm_AwardPharmaPointsFragment = EditAwardPharmaPointsConfigurationForm_AwardPharmaPointsFragment$data;
export type EditAwardPharmaPointsConfigurationForm_AwardPharmaPointsFragment$key = {
  readonly " $data"?: EditAwardPharmaPointsConfigurationForm_AwardPharmaPointsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditAwardPharmaPointsConfigurationForm_AwardPharmaPointsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditAwardPharmaPointsConfigurationForm_AwardPharmaPointsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    }
  ],
  "type": "AwardPharmaPointsRewardConfiguration",
  "abstractKey": null
};

(node as any).hash = "9b96afa06b229dc237987058dc242bcc";

export default node;

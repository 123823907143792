/**
 * @generated SignedSource<<1046156f2a6743dfa7a26a4ace0ee6be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateLandingPageV2Input = {
  data: LandingPageV2Input;
  clientMutationId?: string | null;
};
export type LandingPageV2Input = {
  title: string;
  description: string;
  urlParam: string;
  clientMutationId?: string | null;
  imageId?: string | null;
  rootNodeIds: ReadonlyArray<string>;
};
export type EditLandingPageV2Screen_CreateLandingPageMutation$variables = {
  input: CreateLandingPageV2Input;
  connections: ReadonlyArray<string>;
};
export type EditLandingPageV2Screen_CreateLandingPageMutationVariables = EditLandingPageV2Screen_CreateLandingPageMutation$variables;
export type EditLandingPageV2Screen_CreateLandingPageMutation$data = {
  readonly Admin: {
    readonly LandingPageV2: {
      readonly createLandingPageV2: {
        readonly data: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"EditLandingPageV2Screen_LandingPageV2Fragment">;
          };
        };
      } | null;
    };
  };
};
export type EditLandingPageV2Screen_CreateLandingPageMutationResponse = EditLandingPageV2Screen_CreateLandingPageMutation$data;
export type EditLandingPageV2Screen_CreateLandingPageMutation = {
  variables: EditLandingPageV2Screen_CreateLandingPageMutationVariables;
  response: EditLandingPageV2Screen_CreateLandingPageMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditLandingPageV2Screen_CreateLandingPageMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LandingPageV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "LandingPageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateLandingPageV2Payload",
                "kind": "LinkedField",
                "name": "createLandingPageV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LandingPageV2Edge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LandingPageV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditLandingPageV2Screen_LandingPageV2Fragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditLandingPageV2Screen_CreateLandingPageMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LandingPageV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "LandingPageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateLandingPageV2Payload",
                "kind": "LinkedField",
                "name": "createLandingPageV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LandingPageV2Edge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LandingPageV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "urlParam",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "image",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LearnOpportunitiesV2Connection",
                            "kind": "LinkedField",
                            "name": "rootNodes",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LearnOpportunitiesV2Edge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LearnOpportunityV2",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "data",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6756fa8b544bdaea51e7ca120044112d",
    "id": null,
    "metadata": {},
    "name": "EditLandingPageV2Screen_CreateLandingPageMutation",
    "operationKind": "mutation",
    "text": "mutation EditLandingPageV2Screen_CreateLandingPageMutation(\n  $input: CreateLandingPageV2Input!\n) {\n  Admin {\n    LandingPageV2 {\n      createLandingPageV2(input: $input) {\n        data {\n          node {\n            ...EditLandingPageV2Screen_LandingPageV2Fragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditLandingPageV2Screen_LandingPageV2Fragment on LandingPageV2 {\n  id\n  title\n  description\n  urlParam\n  image {\n    id\n    url\n  }\n  rootNodes {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "90b9b7868cae1603c695ae83a736b701";

export default node;

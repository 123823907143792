/**
 * @generated SignedSource<<411f67c21a4079936aa93d4625e2f935>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteNewstickerMessageInput = {
  ids: ReadonlyArray<string>;
  clientMutationId?: string | null;
};
export type NewsTickerDefinitionOverviewScreen_DeleteNewsTickerDefinitionMutation$variables = {
  input: DeleteNewstickerMessageInput;
  connections: ReadonlyArray<string>;
};
export type NewsTickerDefinitionOverviewScreen_DeleteNewsTickerDefinitionMutationVariables = NewsTickerDefinitionOverviewScreen_DeleteNewsTickerDefinitionMutation$variables;
export type NewsTickerDefinitionOverviewScreen_DeleteNewsTickerDefinitionMutation$data = {
  readonly Admin: {
    readonly Newsticker: {
      readonly deleteNewstickerMessage: {
        readonly deletedIds: ReadonlyArray<string>;
      } | null;
    };
  };
};
export type NewsTickerDefinitionOverviewScreen_DeleteNewsTickerDefinitionMutationResponse = NewsTickerDefinitionOverviewScreen_DeleteNewsTickerDefinitionMutation$data;
export type NewsTickerDefinitionOverviewScreen_DeleteNewsTickerDefinitionMutation = {
  variables: NewsTickerDefinitionOverviewScreen_DeleteNewsTickerDefinitionMutationVariables;
  response: NewsTickerDefinitionOverviewScreen_DeleteNewsTickerDefinitionMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedIds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewsTickerDefinitionOverviewScreen_DeleteNewsTickerDefinitionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NewstickerAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Newsticker",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteNewstickerMessagePayload",
                "kind": "LinkedField",
                "name": "deleteNewstickerMessage",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewsTickerDefinitionOverviewScreen_DeleteNewsTickerDefinitionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NewstickerAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Newsticker",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteNewstickerMessagePayload",
                "kind": "LinkedField",
                "name": "deleteNewstickerMessage",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "deleteEdge",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "deletedIds",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5c5b48e34cf82232fcf39288165352b9",
    "id": null,
    "metadata": {},
    "name": "NewsTickerDefinitionOverviewScreen_DeleteNewsTickerDefinitionMutation",
    "operationKind": "mutation",
    "text": "mutation NewsTickerDefinitionOverviewScreen_DeleteNewsTickerDefinitionMutation(\n  $input: DeleteNewstickerMessageInput!\n) {\n  Admin {\n    Newsticker {\n      deleteNewstickerMessage(input: $input) {\n        deletedIds\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "46b42e640a92e041fae846ba2757ebff";

export default node;

import React, { Suspense } from "react";
import { Button } from "primereact/button";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { useFormik } from "formik";
import * as Yup from "yup";
import { EditMessageDefinitionPageForm_MessageDefinitionPageFragment$key } from "../../../../../__generated__/EditMessageDefinitionPageForm_MessageDefinitionPageFragment.graphql";
import { EditMessageDefinitionPageForm_UpdateMessageDefinitionPageMutation } from "../../../../../__generated__/EditMessageDefinitionPageForm_UpdateMessageDefinitionPageMutation.graphql";
import { ProgressSpinner } from "primereact/progressspinner";
import { RenderConfig, ValidatedField } from "../../../core/form/ValidatedField";
import { DefaultTextFieldComponent } from "../../../core/form/DefaultTextInput";
import { FileSelectionField } from "../../../files/FileSelectionField";
import {
	addEditedFormToEditedFormsArray,
	removeEditedFormFromEditedFormsArray,
} from "../../../../redux/slices/CoreSlice";
import { useTypedDispatch } from "../../../../redux/Store";

const CUSTOMER_MESSAGE_DEFINITION_PAGE_FRAGMENT = graphql`
	fragment EditMessageDefinitionPageForm_MessageDefinitionPageFragment on MessageDefinitionPage {
		id
		internalTitle
		image {
			fileType
			id
			name
			uploadDateTime
			url
		}
	}
`;

const EDIT_CUSTOMER_MESSAGE_DEFINITION_PAGE_MUTATION = graphql`
	mutation EditMessageDefinitionPageForm_UpdateMessageDefinitionPageMutation(
		$input: EditMessageDefinitionPageInput!
	) {
		Admin {
			Messaging {
				editMessageDefinitionPage(input: $input) {
					edge {
						node {
							...EditMessageDefinitionPageForm_MessageDefinitionPageFragment
						}
					}
				}
			}
		}
	}
`;

interface FormState {
	internalTitle: string;
	imageRef?: string;
}

interface OwnProps {
	onClose: () => void;
	messageDefinitionPageFragmentRef: EditMessageDefinitionPageForm_MessageDefinitionPageFragment$key;
}

export const EditMessageDefinitionPageForm = ({
	onClose,
	messageDefinitionPageFragmentRef,
}: OwnProps) => {
	const dispatch = useTypedDispatch();
	const formId = "EditMessageDefinitionPageForm";
	const page = useFragment<EditMessageDefinitionPageForm_MessageDefinitionPageFragment$key>(
		CUSTOMER_MESSAGE_DEFINITION_PAGE_FRAGMENT,
		messageDefinitionPageFragmentRef,
	);

	const [editPageRaw, isEditingPage] =
		useMutation<EditMessageDefinitionPageForm_UpdateMessageDefinitionPageMutation>(
			EDIT_CUSTOMER_MESSAGE_DEFINITION_PAGE_MUTATION,
		);

	const formik = useFormik<FormState>({
		initialValues: {
			internalTitle: page.internalTitle,
			imageRef: page.image?.id,
		},
		validationSchema: Yup.object().shape({
			internalTitle: Yup.string().required("Interner Titel wird benötigt."),
			imageRef: Yup.string().required("Bild wird benötigt."),
		}),
		onSubmit: (values, { setSubmitting }) => {
			editPageRaw({
				variables: {
					input: {
						id: page.id,
						internalTitle: values.internalTitle,
						imageRef: values.imageRef,
					},
				},
			});
			setSubmitting(false);
			dispatch(removeEditedFormFromEditedFormsArray({ form: formId }));
			onClose();
		},
	});

	return (
		<Suspense fallback={<ProgressSpinner />}>
			{!isEditingPage ? (
				<form onSubmit={formik.handleSubmit} className="p-fluid">
					<ValidatedField<FormState, string>
						name={"internalTitle"}
						label={"Interner titel"}
						required={true}
						onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
						component={DefaultTextFieldComponent}
						formikConfig={formik}
					/>

					<ValidatedField<FormState, string>
						name={"imageRef"}
						label={"Bild"}
						required={true}
						onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
						component={(x: RenderConfig<string>) => {
							return (
								<FileSelectionField
									name={x.fieldName}
									selectedFileId={x.fieldValue}
									setSelectedFileId={x.updateField}
									filterByFileTypes={["image/png", "image/jpg"]}
									onChange={x.onChange}
								/>
							);
						}}
						formikConfig={formik}
					/>

					<div className=" pt-2">
						<Button disabled={isEditingPage} type="submit" label={"Speichern"} />
						<Button
							disabled={isEditingPage}
							onClick={onClose}
							label={"Abbrechen"}
							className="mt-5 p-button-secondary"
						/>
					</div>
				</form>
			) : (
				<ProgressSpinner />
			)}
		</Suspense>
	);
};

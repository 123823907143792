import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import graphql from "babel-plugin-relay/macro";
import { ConnectionHandler, useMutation, usePaginationFragment } from "react-relay";
import { PointPoolsTable_Refetch } from "../../../../__generated__/PointPoolsTable_Refetch.graphql";
import { useNavigate } from "react-router-dom";
import { PointPoolsTable_QueryFragment$key } from "../../../../__generated__/PointPoolsTable_QueryFragment.graphql";
import { PointPoolsTable_DeletePointPoolMutation } from "../../../../__generated__/PointPoolsTable_DeletePointPoolMutation.graphql";
import { getEditPointPoolPath } from "../../../../app/routes/PointsModuleRoutes";
import { useDialogLogic } from "../../core/dialog/useDialogLogic";

const QUERY_FRAGMENT = graphql`
	fragment PointPoolsTable_QueryFragment on Query
	@refetchable(queryName: "PointPoolsTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int" }
		after: { type: "String" }
		filterByName: { type: "String" }
	) {
		Admin {
			Points {
				PointPools(first: $first, after: $after, filterByName: $filterByName)
					@connection(key: "PointPoolsTable_PointPools") {
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							name
							description
						}
					}
				}
			}
		}
	}
`;

const DELETE_POINT_POOL_MUTATION = graphql`
	mutation PointPoolsTable_DeletePointPoolMutation(
		$input: DeletePointPoolInput!
		$connections: [ID!]!
	) {
		Admin {
			Points {
				deletePointPool(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;

interface OwnProps {
	queryFragmentRef: PointPoolsTable_QueryFragment$key;
}

export const PointPoolsTable = ({ queryFragmentRef }: OwnProps) => {
	const navigate = useNavigate();

	const {
		data: {
			Admin: {
				Points: {
					PointPools: { edges: pointPools },
				},
			},
		},
		hasPrevious,
		hasNext,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<PointPoolsTable_Refetch, PointPoolsTable_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);

	const [deletePointPool, isDeletingPointPool] =
		useMutation<PointPoolsTable_DeletePointPoolMutation>(DELETE_POINT_POOL_MUTATION);

	const { showDialog, dialogComponent } = useDialogLogic();

	return (
		<>
			{dialogComponent}
			<div className="flex justify-content-end mb-3">
				<Button onClick={() => navigate(getEditPointPoolPath("new"))}>
					Neues Punkte-Konto
				</Button>
			</div>
			<DataTable
				emptyMessage="Keine Punkte-Konten"
				className="mb-3"
				value={pointPools?.map((b) => b!.node!) as any[]}
			>
				<Column
					header="Name"
					body={(row) => <span style={{ color: row.pointPoolColorHex }}>{row.name}</span>}
				/>
				<Column
					header="Aktionen"
					style={{ width: "20%" }}
					body={(item) => (
						<>
							<Button
								className="mr-2"
								onClick={() => navigate(getEditPointPoolPath(item.id))}
								icon={"pi pi-pencil"}
							/>

							<Button
								disabled={isDeletingPointPool}
								onClick={() => {
									showDialog({
										title: "Punkte-Konto löschen",
										content:
											"Möchten Sie dieses Punkte-Konto wirklich löschen? Das kann nicht rückgängig gemacht werden.",
										dialogCallback: (result) => {
											if (result === "Accept") {
												deletePointPool({
													variables: {
														input: {
															ids: [item.id],
														},
														connections: [
															ConnectionHandler.getConnectionID(
																"client:root:Admin:Points",
																"PointPoolsTable_PointPools",
															),
														],
													},
												});
											}
										},
									});
								}}
								icon={"pi pi-trash"}
							/>
						</>
					)}
				/>
			</DataTable>
			<div className="flex justify-content-center align-items-center">
				<Button
					disabled={!hasPrevious}
					onClick={() => loadPrevious(20)}
					className="mr-3 p-button-secondary"
				>
					Zurück
				</Button>
				<Button
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={() => loadNext(20)}
				>
					Weiter
				</Button>
			</div>
		</>
	);
};

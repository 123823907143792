import React from "react";
import { useUrlFilterState } from "../../core/filters/useUrlFilterState";

export interface PointsAdditionsFilterState {
	filterByUser?: string;
	filterByPointsPool?: string;
	filterByMinDateTime?: string;
}

interface Search {
	filters: PointsAdditionsFilterState;
	setFilters: (filters: PointsAdditionsFilterState) => void;
	clearFilters: () => void;
}

export const PointsAdditionSearchContext = React.createContext<Search>({
	setFilters: () => {},
	clearFilters: () => {},
	filters: {},
});

interface OwnProps {
	children: any;
}

export const PointsAdditionSearchContainer = ({ children }: OwnProps) => {
	const { state, setState } = useUrlFilterState<PointsAdditionsFilterState>();

	return (
		<PointsAdditionSearchContext.Provider
			value={{
				filters: state,
				setFilters: (filters) => {
					setState(filters);
				},
				clearFilters: () => {
					setState({});
				},
			}}
		>
			{children}
		</PointsAdditionSearchContext.Provider>
	);
};

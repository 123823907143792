/**
 * @generated SignedSource<<0312eee3668a88f818652e9e03e66432>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddAfterDateTimeDoNotRewardTreeConfigInput = {
  dateTime: string;
  rootNodeId: string;
  clientMutationId?: string | null;
};
export type RewardTreeConfigEditor_AddAfterDateTimeDoNotRewardConfigMutation$variables = {
  input: AddAfterDateTimeDoNotRewardTreeConfigInput;
};
export type RewardTreeConfigEditor_AddAfterDateTimeDoNotRewardConfigMutationVariables = RewardTreeConfigEditor_AddAfterDateTimeDoNotRewardConfigMutation$variables;
export type RewardTreeConfigEditor_AddAfterDateTimeDoNotRewardConfigMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly addAfterDateTimeDoNotRewardTreeConfig: {
        readonly rootNode: {
          readonly " $fragmentSpreads": FragmentRefs<"RewardTreeConfigEditor_TreeNodeFragment">;
        };
      } | null;
    };
  };
};
export type RewardTreeConfigEditor_AddAfterDateTimeDoNotRewardConfigMutationResponse = RewardTreeConfigEditor_AddAfterDateTimeDoNotRewardConfigMutation$data;
export type RewardTreeConfigEditor_AddAfterDateTimeDoNotRewardConfigMutation = {
  variables: RewardTreeConfigEditor_AddAfterDateTimeDoNotRewardConfigMutationVariables;
  response: RewardTreeConfigEditor_AddAfterDateTimeDoNotRewardConfigMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RewardTreeConfigEditor_AddAfterDateTimeDoNotRewardConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddAfterDateTimeDoNotRewardTreeConfigPayload",
                "kind": "LinkedField",
                "name": "addAfterDateTimeDoNotRewardTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "RewardTreeConfigEditor_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RewardTreeConfigEditor_AddAfterDateTimeDoNotRewardConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddAfterDateTimeDoNotRewardTreeConfigPayload",
                "kind": "LinkedField",
                "name": "addAfterDateTimeDoNotRewardTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "rewardConfigs",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "configType",
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "dateTime",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "AfterDateTimeDoNotRewardTreeConfig",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "499f64ed19915529d8a80a1210912794",
    "id": null,
    "metadata": {},
    "name": "RewardTreeConfigEditor_AddAfterDateTimeDoNotRewardConfigMutation",
    "operationKind": "mutation",
    "text": "mutation RewardTreeConfigEditor_AddAfterDateTimeDoNotRewardConfigMutation(\n  $input: AddAfterDateTimeDoNotRewardTreeConfigInput!\n) {\n  Admin {\n    Tree {\n      addAfterDateTimeDoNotRewardTreeConfig(input: $input) {\n        rootNode {\n          ...RewardTreeConfigEditor_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment NotAfterDateTimeRewardConfigForm_AfterDateTimeDoNotRewardTreeConfigFragment on AfterDateTimeDoNotRewardTreeConfig {\n  id\n  configType\n  dateTime\n}\n\nfragment NotAfterDateTimeRewardConfigForm_TreeNodeFragment on TreeNode {\n  id\n}\n\nfragment RewardTreeConfigEditor_TreeNodeFragment on TreeNode {\n  id\n  structureDefinition {\n    __typename\n    ... on RootStructureDefinition {\n      rewardConfigs {\n        __typename\n        id\n        configType\n        ... on AfterDateTimeDoNotRewardTreeConfig {\n          dateTime\n        }\n        ...NotAfterDateTimeRewardConfigForm_AfterDateTimeDoNotRewardTreeConfigFragment\n      }\n    }\n  }\n  ...NotAfterDateTimeRewardConfigForm_TreeNodeFragment\n}\n"
  }
};
})();

(node as any).hash = "612b00ccea39394e57bf51dfaa2cb0aa";

export default node;

/**
 * @generated SignedSource<<b9cd9e2e525616e931c5a94c3d76db10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "order" | "randomMultipleChoice" | "clozeText" | "podcast" | "multipleChoice" | "video" | "file" | "text" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ELearningContentEditor_TreeNodeFragment$data = {
  readonly id: string;
  readonly typeDefinition: {
    readonly elements?: ReadonlyArray<{
      readonly id: string;
      readonly elementType: ElementTypeV2;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"ElementForm_ElementV2Fragment">;
    }>;
  };
  readonly " $fragmentType": "ELearningContentEditor_TreeNodeFragment";
};
export type ELearningContentEditor_TreeNodeFragment = ELearningContentEditor_TreeNodeFragment$data;
export type ELearningContentEditor_TreeNodeFragment$key = {
  readonly " $data"?: ELearningContentEditor_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ELearningContentEditor_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ELearningContentEditor_TreeNodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "elements",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "elementType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ElementForm_ElementV2Fragment"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "16c350d215ffb0c09130d2f592d9c030";

export default node;

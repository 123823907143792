import { useFormik } from "formik";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery, useRelayEnvironment } from "react-relay";
import { UrlTextFieldComponent } from "./UrlTextFieldComponent";
import styled from "styled-components";
import { EditLandingPageV2Form_Query } from "../../../__generated__/EditLandingPageV2Form_Query.graphql";
import { ValidatedField } from "../core/form/ValidatedField";
import { DefaultTextFieldComponent } from "../core/form/DefaultTextInput";
import { DefaultTextEditorComponent } from "../core/form/DefaultTextEditorComponent";
import { LimitedFileSelectionField } from "../files/FileSelectionField";
import { ContentsMultiselectV2 } from "./ContentsMultiselectV2";
import { useTypedDispatch, useTypedSelector } from "../../redux/Store";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
	selectArrayOfEditedForms,
} from "../../redux/slices/CoreSlice";
import { useCallbackPrompt } from "../../functions/hooks/UseCallBackPrompt";
import { WarningUnsavedChangesDialog } from "../core/dialog/WarningUnsavedChangesDialog";

const QUERY = graphql`
	query EditLandingPageV2Form_Query($first: Int, $after: String) {
		Admin {
			LandingPageV2 {
				LandingPages(first: $first, after: $after) {
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							urlParam
						}
					}
				}
			}
		}
	}
`;

export interface FormState {
	title: string;
	description: string;
	urlParam: string;
	imageId?: string;
	rootNodeIds: readonly string[];
}

interface UrlParamProps {
	urlParam: string;
}

interface OwnProps {
	loading?: boolean;
	initialValues?: FormState;
	onSubmit: (values: FormState) => void;
}

export const EditLandingPageV2Form = ({ loading, initialValues, onSubmit }: OwnProps) => {
	const [urlList, setUrlList] = useState<UrlParamProps[]>([]);

	const [errorUrl, setErrorUrl] = useState(false);
	const formId = "EditLandingPageV2Form";
	const environment = useRelayEnvironment();
	const dispatch = useTypedDispatch();
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);

	const [showPrompt, confirmNavigation, cancelNavigation, setShowPrompt] = useCallbackPrompt(
		arrayOfEditedForms.length > 0,
	);
	useEffect(() => {
		fetchQuery<EditLandingPageV2Form_Query>(environment, QUERY, {})
			.toPromise()
			.then((result) => {
				setUrlList(() =>
					result!.Admin.LandingPageV2.LandingPages.edges!.map((e) => e!.node!),
				);
			});
		// eslint-disable-next-line
    }, [])

	const formik = useFormik<FormState>({
		initialValues: {
			title: initialValues?.title ?? "Neue Seite",
			description: initialValues?.description ?? "Diese ist eine neue Seite",
			urlParam: initialValues?.urlParam ?? "new-page",
			imageId: initialValues?.imageId,
			rootNodeIds: initialValues?.rootNodeIds ?? [],
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Titel wird benötigt"),
			description: Yup.string().required("Bescreibung wird benötigt"),
			urlParam: Yup.string().required("Url value wird benötigt"),
		}),
		onSubmit: (values, { setSubmitting }) => {
			if (
				(urlList.filter((url) => url.urlParam === values.urlParam).length > 0 &&
					values.urlParam !== initialValues?.urlParam) ||
				values.urlParam === "new-page"
			) {
				setErrorUrl(true);
			} else {
				onSubmit({
					...values,
				});
				setSubmitting(false);
				setErrorUrl(false);
				dispatch(resetArrayOfEditedForms());
			}
		},
	});

	return (
		<>
			{" "}
			{showPrompt && (
				<WarningUnsavedChangesDialog
					confirmNavigation={confirmNavigation}
					setShowDialog={setShowPrompt}
					cancelNavigation={cancelNavigation}
				/>
			)}
			<form onSubmit={formik.handleSubmit} className="p-fluid w-10 my-0 mx-auto">
				<ValidatedField<FormState, string>
					name={"title"}
					label={"Titel"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					required={true}
					formikConfig={formik}
				/>
				<ValidatedField<FormState, string>
					name={"description"}
					label={"Beschreibung"}
					required={true}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={DefaultTextEditorComponent}
					showListButtons={true}
					formikConfig={formik}
				/>
				{errorUrl ? (
					<ErrorContainer>
						Url Pfad nicht verfügbar. Bitte wählen Sie einen anderen
					</ErrorContainer>
				) : null}
				<ValidatedField<FormState, string>
					name={"urlParam"}
					label={"Url Pfad"}
					formikConfig={formik}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					required={true}
					helpText={`Wählen Sie einen repräsentativen Url-Pfad für Ihre Seite hoch.`}
					component={UrlTextFieldComponent}
				/>

				<ValidatedField<FormState, string[]>
					name={"rootNodeIds"}
					label={"Module"}
					helpText={"Laden Sie repräsentative Module für Ihre Seite hoch."}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={ContentsMultiselectV2}
					formikConfig={formik}
				/>
				<ValidatedField<FormState, string>
					name={"imageId"}
					label={"Bild"}
					helpText={"Laden Sie ein repräsentatives Foto für Ihre Seite hoch."}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={LimitedFileSelectionField(["image/png", "image/jpg", "image/jpeg"])}
					formikConfig={formik}
				/>

				<Button disabled={loading} type="submit" label="Speichern" className="p-mt-2" />
			</form>
		</>
	);
};

const ErrorContainer = styled.div`
	font-size: 12px;
	color: red;
	margin-bottom: 5px;
`;

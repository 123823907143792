/**
 * @generated SignedSource<<2dac9f543f90913a69aa6100fe62f728>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectAdvertisementForm_TreeNodeFragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly advertisementConfiguration?: {
      readonly isFeatured: boolean;
    } | null;
  };
  readonly " $fragmentType": "ProjectAdvertisementForm_TreeNodeFragment";
};
export type ProjectAdvertisementForm_TreeNodeFragment = ProjectAdvertisementForm_TreeNodeFragment$data;
export type ProjectAdvertisementForm_TreeNodeFragment$key = {
  readonly " $data"?: ProjectAdvertisementForm_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectAdvertisementForm_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectAdvertisementForm_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AdvertisementConfiguration",
              "kind": "LinkedField",
              "name": "advertisementConfiguration",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isFeatured",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "b76062fe5be6ebedde7db4fce89f915a";

export default node;

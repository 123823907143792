/**
 * @generated SignedSource<<63b94bde6b8055d3b08ff282a5d059e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConfigurationBadgesColumn_TreeNodeFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NodeConfigurationBadges_TreeNodeFragment" | "ProjectExtendNodeConfigurationBadges_TreeNodeFragment">;
  readonly " $fragmentType": "ConfigurationBadgesColumn_TreeNodeFragment";
};
export type ConfigurationBadgesColumn_TreeNodeFragment = ConfigurationBadgesColumn_TreeNodeFragment$data;
export type ConfigurationBadgesColumn_TreeNodeFragment$key = {
  readonly " $data"?: ConfigurationBadgesColumn_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConfigurationBadgesColumn_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConfigurationBadgesColumn_TreeNodeFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeConfigurationBadges_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectExtendNodeConfigurationBadges_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "a86f912e18cff9ca4205db2d919bd7e2";

export default node;

/**
 * @generated SignedSource<<c240ce6aadcde89e498fbe76d4dd5f79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TreeStatesTable_TreeStateFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ActionColumn_TreeStateFragment" | "TitleColumn_TreeStateFragment" | "UserColumn_TreeStateFragment">;
  readonly " $fragmentType": "TreeStatesTable_TreeStateFragment";
};
export type TreeStatesTable_TreeStateFragment = TreeStatesTable_TreeStateFragment$data;
export type TreeStatesTable_TreeStateFragment$key = {
  readonly " $data"?: TreeStatesTable_TreeStateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TreeStatesTable_TreeStateFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TreeStatesTable_TreeStateFragment"
};

(node as any).hash = "29954ec090c2a420308e11471b09b367";

export default node;

/**
 * @generated SignedSource<<eb8e5129af1c3cba261396f55b2d8941>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateCertificateTemplateInput = {
  data: CertificateTemplateInput;
  clientMutationId?: string | null;
};
export type CertificateTemplateInput = {
  name: string;
  variableMappings: ReadonlyArray<VariableMappingInput>;
  clientMutationId?: string | null;
  templateFileRef: string;
};
export type VariableMappingInput = {
  templateVariable: string;
  certificateVariable: string;
};
export type EditCertificateTemplateScreen_CreateCertificateTemplateMutation$variables = {
  input: CreateCertificateTemplateInput;
  connections: ReadonlyArray<string>;
};
export type EditCertificateTemplateScreen_CreateCertificateTemplateMutationVariables = EditCertificateTemplateScreen_CreateCertificateTemplateMutation$variables;
export type EditCertificateTemplateScreen_CreateCertificateTemplateMutation$data = {
  readonly Admin: {
    readonly Certificates: {
      readonly createCertificateTemplate: {
        readonly data: {
          readonly node: {
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"EditCertificateTemplateScreen_CertificateTemplateFragment">;
          };
        };
      } | null;
    };
  };
};
export type EditCertificateTemplateScreen_CreateCertificateTemplateMutationResponse = EditCertificateTemplateScreen_CreateCertificateTemplateMutation$data;
export type EditCertificateTemplateScreen_CreateCertificateTemplateMutation = {
  variables: EditCertificateTemplateScreen_CreateCertificateTemplateMutationVariables;
  response: EditCertificateTemplateScreen_CreateCertificateTemplateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditCertificateTemplateScreen_CreateCertificateTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CertificatesAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Certificates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateCertificateTemplatePayload",
                "kind": "LinkedField",
                "name": "createCertificateTemplate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CertificateTemplatesEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CertificateTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditCertificateTemplateScreen_CertificateTemplateFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditCertificateTemplateScreen_CreateCertificateTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CertificatesAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Certificates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateCertificateTemplatePayload",
                "kind": "LinkedField",
                "name": "createCertificateTemplate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CertificateTemplatesEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CertificateTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "templateFileRef",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "VariableMapping",
                            "kind": "LinkedField",
                            "name": "variableMappings",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "certificateVariable",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "templateVariable",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "data",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "839f5757c43bd6e27269bb682173d8d8",
    "id": null,
    "metadata": {},
    "name": "EditCertificateTemplateScreen_CreateCertificateTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation EditCertificateTemplateScreen_CreateCertificateTemplateMutation(\n  $input: CreateCertificateTemplateInput!\n) {\n  Admin {\n    Certificates {\n      createCertificateTemplate(input: $input) {\n        data {\n          node {\n            id\n            ...EditCertificateTemplateScreen_CertificateTemplateFragment\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditCertificateTemplateScreen_CertificateTemplateFragment on CertificateTemplate {\n  id\n  name\n  templateFileRef\n  variableMappings {\n    certificateVariable\n    templateVariable\n  }\n}\n"
  }
};
})();

(node as any).hash = "9e77b3303425cb5870ca6d93278b495b";

export default node;

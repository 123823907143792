export interface Fachrichtung {
	tid: number;
	name: string;
	parent_target_id: number;
}

export const FACHRICHTUNGEN: Fachrichtung[] = [
	{
		tid: 476,
		name: "Anästhesiologie",
		parent_target_id: 475,
	},
	{
		tid: 460,
		name: "Apotheken",
		parent_target_id: 0,
	},
	{
		tid: 749,
		name: "Apotheker",
		parent_target_id: 460,
	},
	{
		tid: 466,
		name: "Dermatologe",
		parent_target_id: 464,
	},
	{
		tid: 816,
		name: "Fachwissenschaftler der Medizin",
		parent_target_id: 0,
	},
	{
		tid: 472,
		name: "Hals-Nasen-Ohrenheilkunde",
		parent_target_id: 471,
	},
	{
		tid: 470,
		name: "Hebamme",
		parent_target_id: 469,
	},
	{
		tid: 779,
		name: "Innere Medizin / Endokrinologie und Diabetologie ",
		parent_target_id: 0,
	},
	{
		tid: 468,
		name: "Innere Medizin / Geriatrie",
		parent_target_id: 467,
	},
	{
		tid: 756,
		name: "Kinder- und Jugendpsychiatrie und -psychotherapie",
		parent_target_id: 524,
	},
	{
		tid: 609,
		name: "Neurophysiologie",
		parent_target_id: 550,
	},
	{
		tid: 549,
		name: "Pharmazeutisch-technischer Assistent",
		parent_target_id: 547,
	},
	{
		tid: 461,
		name: "Apotheken / Apotheker",
		parent_target_id: 460,
	},
	{
		tid: 517,
		name: "Apotheker Klinik",
		parent_target_id: 475,
	},
	{
		tid: 465,
		name: "Haut- und Geschlechtskrankheiten",
		parent_target_id: 464,
	},
	{
		tid: 474,
		name: "HNO-Arzt / HNO",
		parent_target_id: 471,
	},
	{
		tid: 533,
		name: "Kinder- und Jugendmedizin / Pädiatrische Gastroenterologie",
		parent_target_id: 524,
	},
	{
		tid: 597,
		name: "Krankenschwester / Pflegedienst",
		parent_target_id: 550,
	},
	{
		tid: 548,
		name: "PTA",
		parent_target_id: 547,
	},
	{
		tid: 525,
		name: "Allgemeinmedizin",
		parent_target_id: 524,
	},
	{
		tid: 463,
		name: "Apotheker Offizin",
		parent_target_id: 460,
	},
	{
		tid: 477,
		name: "Bakteriologie",
		parent_target_id: 475,
	},
	{
		tid: 598,
		name: "Laboratoriumsmedizin",
		parent_target_id: 550,
	},
	{
		tid: 759,
		name: "Neurodermitis-Fachkraft",
		parent_target_id: 464,
	},
	{
		tid: 473,
		name: "Phoniatrie und Pädaudiologie",
		parent_target_id: 471,
	},
	{
		tid: 478,
		name: "Chirurgie",
		parent_target_id: 475,
	},
	{
		tid: 750,
		name: "HNO-Arzt",
		parent_target_id: 471,
	},
	{
		tid: 526,
		name: "Innere und Allgemeinmedizin",
		parent_target_id: 524,
	},
	{
		tid: 462,
		name: "Internationale Apotheke",
		parent_target_id: 460,
	},
	{
		tid: 599,
		name: "Laserzentrum",
		parent_target_id: 550,
	},
	{
		tid: 479,
		name: "Chirurgie / Gefäßchirurgie",
		parent_target_id: 475,
	},
	{
		tid: 544,
		name: "Kinder- und Jugendlichenpsychotherapeut",
		parent_target_id: 524,
	},
	{
		tid: 600,
		name: "Logopädie",
		parent_target_id: 550,
	},
	{
		tid: 480,
		name: "Chirurgie / Herzchirurgie",
		parent_target_id: 475,
	},
	{
		tid: 542,
		name: "Kinder- und Jugendlichenpsychotherapeut / Kinder- und Jugendpsychiatrie-Psychotherapie",
		parent_target_id: 524,
	},
	{
		tid: 601,
		name: "Medizincontrolling",
		parent_target_id: 550,
	},
	{
		tid: 481,
		name: "Chirurgie / Mund-Kiefer-Gesichtschirurgie",
		parent_target_id: 475,
	},
	{
		tid: 527,
		name: "Kinder- und Jugendmedizin",
		parent_target_id: 524,
	},
	{
		tid: 602,
		name: "Medizinisch technischer Radiologieassistent",
		parent_target_id: 550,
	},
	{
		tid: 482,
		name: "Chirurgie / Plastische und Ästhetische Chirurgie",
		parent_target_id: 475,
	},
	{
		tid: 545,
		name: "Kinder- und Jugendmedizin / Hämatologische und Päd",
		parent_target_id: 524,
	},
	{
		tid: 603,
		name: "Medizinische Fachangestellte",
		parent_target_id: 550,
	},
	{
		tid: 483,
		name: "Chirurgie / Thoraxchirurgie",
		parent_target_id: 475,
	},
	{
		tid: 528,
		name: "Kinder- und Jugendmedizin / Hämatologische und Pädiatrische Onkologie",
		parent_target_id: 524,
	},
	{
		tid: 604,
		name: "Medizintechnik",
		parent_target_id: 550,
	},
	{
		tid: 484,
		name: "Chirurgie / Unfallchirurgie",
		parent_target_id: 475,
	},
	{
		tid: 529,
		name: "Kinder- und Jugendmedizin / Kinderkardiologie",
		parent_target_id: 524,
	},
	{
		tid: 605,
		name: "MTA",
		parent_target_id: 550,
	},
	{
		tid: 485,
		name: "Chirurgie / Viszeralchirurgie",
		parent_target_id: 475,
	},
	{
		tid: 530,
		name: "Kinder- und Jugendmedizin / Neonatologie",
		parent_target_id: 524,
	},
	{
		tid: 606,
		name: "Naturheilverfahren",
		parent_target_id: 550,
	},
	{
		tid: 486,
		name: "Herzzentrum",
		parent_target_id: 475,
	},
	{
		tid: 531,
		name: "Kinder- und Jugendmedizin / Neuropädiatrie",
		parent_target_id: 524,
	},
	{
		tid: 607,
		name: "Nervenheilkunde",
		parent_target_id: 550,
	},
	{
		tid: 487,
		name: "Hygiene und Umweltmedizin",
		parent_target_id: 475,
	},
	{
		tid: 532,
		name: "Kinder- und Jugendmedizin / Pädiatrische Endokrinologie",
		parent_target_id: 524,
	},
	{
		tid: 608,
		name: "Neuropathologie",
		parent_target_id: 550,
	},
	{
		tid: 488,
		name: "Innere Medizin",
		parent_target_id: 475,
	},
	{
		tid: 534,
		name: "Kinder- und Jugendmedizin / Pädiatrische Nephrologie",
		parent_target_id: 524,
	},
	{
		tid: 610,
		name: "Nuklearmedizin",
		parent_target_id: 550,
	},
	{
		tid: 489,
		name: "Innere Medizin / Angiologie",
		parent_target_id: 475,
	},
	{
		tid: 535,
		name: "Kinder- und Jugendmedizin / Pädiatrische Pneumonologie",
		parent_target_id: 524,
	},
	{
		tid: 595,
		name: "Klinische Chemie",
		parent_target_id: 550,
	},
	{
		tid: 514,
		name: "Innere Medizin / Endokrinologie",
		parent_target_id: 475,
	},
	{
		tid: 538,
		name: "Kinder- und Jugendmedizin/Hämatologische und Pädiatrische Onkologie / Kinder- und Jugendmedizin / Hämatologische und Päd",
		parent_target_id: 524,
	},
	{
		tid: 683,
		name: "Öffentlicher Gesundheitsdienst (Arzt)",
		parent_target_id: 550,
	},
	{
		tid: 490,
		name: "Innere Medizin / Endokrinologie und Diabetologie",
		parent_target_id: 475,
	},
	{
		tid: 539,
		name: "Kinder- und Jugendmedizin/Kinderkardiologie",
		parent_target_id: 524,
	},
	{
		tid: 611,
		name: "Öffentliches Gesundheitswesen",
		parent_target_id: 550,
	},
	{
		tid: 491,
		name: "Innere Medizin / Gastroenterologie",
		parent_target_id: 475,
	},
	{
		tid: 540,
		name: "Kinder- und Jugendmedizin/Neonatologie",
		parent_target_id: 524,
	},
	{
		tid: 612,
		name: "Ökotrophologie",
		parent_target_id: 550,
	},
	{
		tid: 492,
		name: "Innere Medizin / Hämatologie und Internistische Onkologie",
		parent_target_id: 475,
	},
	{
		tid: 536,
		name: "Kinder- und Jugendpsychiatrie",
		parent_target_id: 524,
	},
	{
		tid: 613,
		name: "OP",
		parent_target_id: 550,
	},
	{
		tid: 493,
		name: "Innere Medizin / Infektiologie",
		parent_target_id: 475,
	},
	{
		tid: 546,
		name: "Kinder- und Jugendpsychiatrie-Psychotherapie",
		parent_target_id: 524,
	},
	{
		tid: 614,
		name: "Oralchirurgie",
		parent_target_id: 550,
	},
	{
		tid: 494,
		name: "Innere Medizin / Kardiologie",
		parent_target_id: 475,
	},
	{
		tid: 541,
		name: "Neuropädiatrie",
		parent_target_id: 524,
	},
	{
		tid: 615,
		name: "Orthopädie / Rheumatologie",
		parent_target_id: 550,
	},
	{
		tid: 495,
		name: "Innere Medizin / Nephrologie",
		parent_target_id: 475,
	},
	{
		tid: 616,
		name: "Palliativmedizin",
		parent_target_id: 550,
	},
	{
		tid: 543,
		name: "Praktischer Arzt",
		parent_target_id: 524,
	},
	{
		tid: 496,
		name: "Innere Medizin / Pneumologie",
		parent_target_id: 475,
	},
	{
		tid: 617,
		name: "Parodontologie",
		parent_target_id: 550,
	},
	{
		tid: 537,
		name: "Praktischer Arzt / Praktische Ärztin",
		parent_target_id: 524,
	},
	{
		tid: 497,
		name: "Innere Medizin / Rheumatologie",
		parent_target_id: 475,
	},
	{
		tid: 673,
		name: "Pathologe",
		parent_target_id: 550,
	},
	{
		tid: 522,
		name: "Innere Medizin/Hämatologie u. Intern. Onkologie",
		parent_target_id: 475,
	},
	{
		tid: 618,
		name: "Pathologie",
		parent_target_id: 550,
	},
	{
		tid: 515,
		name: "Innere Medizin/Hämatologie und intern Onkologie",
		parent_target_id: 475,
	},
	{
		tid: 619,
		name: "Pflegeüberleitung",
		parent_target_id: 550,
	},
	{
		tid: 523,
		name: "Innnere Medizin",
		parent_target_id: 475,
	},
	{
		tid: 620,
		name: "Pharmakologie und Toxikologie",
		parent_target_id: 550,
	},
	{
		tid: 498,
		name: "Intensivmedizin",
		parent_target_id: 475,
	},
	{
		tid: 596,
		name: "Kosmetiker",
		parent_target_id: 550,
	},
	{
		tid: 516,
		name: "Intensivmediziner",
		parent_target_id: 475,
	},
	{
		tid: 671,
		name: "Kinderchirurgie",
		parent_target_id: 550,
	},
	{
		tid: 499,
		name: "Klinische Pharmakologie",
		parent_target_id: 475,
	},
	{
		tid: 621,
		name: "Pharmazieingenieur",
		parent_target_id: 550,
	},
	{
		tid: 583,
		name: "Frauenheilkunde und Geburtshilfe / Spezielle Geburtshilfe und Perinatalmedizin",
		parent_target_id: 550,
	},
	{
		tid: 500,
		name: "Krankenhaus-Apotheke / Krankenhaus-Apotheker",
		parent_target_id: 475,
	},
	{
		tid: 757,
		name: "Chirurg",
		parent_target_id: 475,
	},
	{
		tid: 572,
		name: "Dialysegroßzentrum",
		parent_target_id: 550,
	},
	{
		tid: 573,
		name: "Diätassistent/in",
		parent_target_id: 550,
	},
	{
		tid: 518,
		name: "Mikrobiologe (Arzt)",
		parent_target_id: 475,
	},
	{
		tid: 574,
		name: "Einkauf",
		parent_target_id: 550,
	},
	{
		tid: 501,
		name: "Mikrobiologie, Virologie und Infektionsepidemiologie",
		parent_target_id: 475,
	},
	{
		tid: 575,
		name: "Einkaufsgemeinschaft - medical -",
		parent_target_id: 550,
	},
	{
		tid: 502,
		name: "Mukoviszidose",
		parent_target_id: 475,
	},
	{
		tid: 576,
		name: "Einkaufsgemeinschaft - pharma -",
		parent_target_id: 550,
	},
	{
		tid: 519,
		name: "Neurochirurg",
		parent_target_id: 475,
	},
	{
		tid: 577,
		name: "Endoskopie",
		parent_target_id: 550,
	},
	{
		tid: 503,
		name: "Neurochirurgie",
		parent_target_id: 475,
	},
	{
		tid: 578,
		name: "Ergotherapie",
		parent_target_id: 550,
	},
	{
		tid: 520,
		name: "Neurologen",
		parent_target_id: 475,
	},
	{
		tid: 682,
		name: "Ernährungswissenschaft",
		parent_target_id: 550,
	},
	{
		tid: 504,
		name: "Neurologie",
		parent_target_id: 475,
	},
	{
		tid: 579,
		name: "Flugmedizin",
		parent_target_id: 550,
	},
	{
		tid: 505,
		name: "Onkologie",
		parent_target_id: 475,
	},
	{
		tid: 580,
		name: "Frauenheilkunde und Geburtshilfe",
		parent_target_id: 550,
	},
	{
		tid: 521,
		name: "Orthopäde",
		parent_target_id: 475,
	},
	{
		tid: 581,
		name: "Frauenheilkunde und Geburtshilfe / Gynäkologische Endokrinologie und Reproduktionsmedizin",
		parent_target_id: 550,
	},
	{
		tid: 506,
		name: "Orthopädie",
		parent_target_id: 475,
	},
	{
		tid: 582,
		name: "Frauenheilkunde und Geburtshilfe / Gynäkologische Onkologie",
		parent_target_id: 550,
	},
	{
		tid: 507,
		name: "Orthopädie und Unfallchirurgie",
		parent_target_id: 475,
	},
	{
		tid: 672,
		name: "Frauenheilkunde/Geburtshilfe Gyn.Endokrinologie",
		parent_target_id: 550,
	},
	{
		tid: 508,
		name: "Orthopädische Chirurgie",
		parent_target_id: 475,
	},
	{
		tid: 594,
		name: "Kieferorthopädie",
		parent_target_id: 550,
	},
	{
		tid: 512,
		name: "Thoraxchirurgie",
		parent_target_id: 475,
	},
	{
		tid: 584,
		name: "Gerontopsychiatrie",
		parent_target_id: 550,
	},
	{
		tid: 513,
		name: "Unfallchirurg",
		parent_target_id: 475,
	},
	{
		tid: 585,
		name: "Großhändler",
		parent_target_id: 550,
	},
	{
		tid: 753,
		name: "Viszeralchirurgie (Chirurgie)",
		parent_target_id: 475,
	},
	{
		tid: 586,
		name: "Gynäkologische Zytologie",
		parent_target_id: 550,
	},
	{
		tid: 509,
		name: "Wirbelsaeulenchirurgie",
		parent_target_id: 475,
	},
	{
		tid: 587,
		name: "Handchirurgie",
		parent_target_id: 550,
	},
	{
		tid: 510,
		name: "Wirbelsäulenzentrum",
		parent_target_id: 475,
	},
	{
		tid: 588,
		name: "Heilpraktiker",
		parent_target_id: 550,
	},
	{
		tid: 511,
		name: "Zentrum für Schwerbrandverletzte",
		parent_target_id: 475,
	},
	{
		tid: 589,
		name: "Heimdialyse / Zentralisierte Heimdialyse / LC-Dialyse",
		parent_target_id: 550,
	},
	{
		tid: 754,
		name: "KrankenhausApotheker",
		parent_target_id: 475,
	},
	{
		tid: 590,
		name: "Herzkatheterlabor",
		parent_target_id: 550,
	},
	{
		tid: 751,
		name: "Internist",
		parent_target_id: 475,
	},
	{
		tid: 591,
		name: "Homöopathie",
		parent_target_id: 550,
	},
	{
		tid: 592,
		name: "Humangenetik",
		parent_target_id: 550,
	},
	{
		tid: 681,
		name: "Hygiene-Beauftragter (Arzt)",
		parent_target_id: 550,
	},
	{
		tid: 680,
		name: "Hygienefachkraft",
		parent_target_id: 550,
	},
	{
		tid: 593,
		name: "IMS Health test",
		parent_target_id: 550,
	},
	{
		tid: 684,
		name: "Pharmazeutisch-kaufmännischer Assistent",
		parent_target_id: 550,
	},
	{
		tid: 622,
		name: "Phlebologie",
		parent_target_id: 550,
	},
	{
		tid: 678,
		name: "Urologen",
		parent_target_id: 550,
	},
	{
		tid: 647,
		name: "Sozialmedizin",
		parent_target_id: 550,
	},
	{
		tid: 648,
		name: "Sozialpädiatrisches Zentrum",
		parent_target_id: 550,
	},
	{
		tid: 649,
		name: "Sozialstation",
		parent_target_id: 550,
	},
	{
		tid: 650,
		name: "Spezialisierte ambulante Palliativversorgung (SAPV)",
		parent_target_id: 550,
	},
	{
		tid: 651,
		name: "Sportmedizin",
		parent_target_id: 550,
	},
	{
		tid: 652,
		name: "Strahlentherapie",
		parent_target_id: 550,
	},
	{
		tid: 653,
		name: "Stroke Unit",
		parent_target_id: 550,
	},
	{
		tid: 654,
		name: "Suchtkrankheiten",
		parent_target_id: 550,
	},
	{
		tid: 655,
		name: "Transfusionsmedizin",
		parent_target_id: 550,
	},
	{
		tid: 656,
		name: "Traumatologie",
		parent_target_id: 550,
	},
	{
		tid: 657,
		name: "Tropenmedizin",
		parent_target_id: 550,
	},
	{
		tid: 658,
		name: "Tumorzentrum",
		parent_target_id: 550,
	},
	{
		tid: 659,
		name: "Urologie",
		parent_target_id: 550,
	},
	{
		tid: 645,
		name: "Sozialarbeiter",
		parent_target_id: 550,
	},
	{
		tid: 660,
		name: "Venereologie",
		parent_target_id: 550,
	},
	{
		tid: 661,
		name: "Versandhandel",
		parent_target_id: 550,
	},
	{
		tid: 662,
		name: "Verwaltung",
		parent_target_id: 550,
	},
	{
		tid: 663,
		name: "Veterinärmedizin",
		parent_target_id: 550,
	},
	{
		tid: 664,
		name: "Vorstand",
		parent_target_id: 550,
	},
	{
		tid: 679,
		name: "Wundpflege",
		parent_target_id: 550,
	},
	{
		tid: 665,
		name: "Wundversorgung",
		parent_target_id: 550,
	},
	{
		tid: 666,
		name: "Zahnmedizin",
		parent_target_id: 550,
	},
	{
		tid: 667,
		name: "ZentGefäßmedizin",
		parent_target_id: 550,
	},
	{
		tid: 668,
		name: "Zentrallager",
		parent_target_id: 550,
	},
	{
		tid: 669,
		name: "Zentrum für angeborene und erworbene Fehlbildungen",
		parent_target_id: 550,
	},
	{
		tid: 670,
		name: "Zytostatika",
		parent_target_id: 550,
	},
	{
		tid: 646,
		name: "Sozialdienst",
		parent_target_id: 550,
	},
	{
		tid: 644,
		name: "Sonstiges",
		parent_target_id: 550,
	},
	{
		tid: 623,
		name: "Physikalische Therapie",
		parent_target_id: 550,
	},
	{
		tid: 676,
		name: "Psychologe",
		parent_target_id: 550,
	},
	{
		tid: 624,
		name: "Physikalische und Rehabilitative Medizin",
		parent_target_id: 550,
	},
	{
		tid: 625,
		name: "Physiologie",
		parent_target_id: 550,
	},
	{
		tid: 626,
		name: "Physiotherapie",
		parent_target_id: 550,
	},
	{
		tid: 674,
		name: "Physiotherapie und Reha-Medizin",
		parent_target_id: 550,
	},
	{
		tid: 627,
		name: "PKA",
		parent_target_id: 550,
	},
	{
		tid: 628,
		name: "Podologie",
		parent_target_id: 550,
	},
	{
		tid: 629,
		name: "Proktologie",
		parent_target_id: 550,
	},
	{
		tid: 688,
		name: "Psychiater",
		parent_target_id: 550,
	},
	{
		tid: 689,
		name: "Psychiater und Psychotherapie",
		parent_target_id: 550,
	},
	{
		tid: 675,
		name: "Psychiater und Psychotherapie / Psychiater",
		parent_target_id: 550,
	},
	{
		tid: 630,
		name: "Psychiatrie und Psychotherapie",
		parent_target_id: 550,
	},
	{
		tid: 631,
		name: "Psychiatrie und Psychotherapie / Forensische Psychiatrie",
		parent_target_id: 550,
	},
	{
		tid: 632,
		name: "Psychologie",
		parent_target_id: 550,
	},
	{
		tid: 686,
		name: "Sonstiger Arzt",
		parent_target_id: 550,
	},
	{
		tid: 633,
		name: "Psychologischer Psychotherapeut",
		parent_target_id: 550,
	},
	{
		tid: 634,
		name: "Psychosomatische Medizin und Psychotherapie",
		parent_target_id: 550,
	},
	{
		tid: 685,
		name: "Psychotherapeut Kinder",
		parent_target_id: 550,
	},
	{
		tid: 635,
		name: "Psychotherapeutische Medizin",
		parent_target_id: 550,
	},
	{
		tid: 677,
		name: "Psychotherapie",
		parent_target_id: 550,
	},
	{
		tid: 636,
		name: "Psychotherapie**",
		parent_target_id: 550,
	},
	{
		tid: 637,
		name: "Radiologie",
		parent_target_id: 550,
	},
	{
		tid: 638,
		name: "Radiologie / Kinderradiologie",
		parent_target_id: 550,
	},
	{
		tid: 639,
		name: "Radiologie / Neuroradiologie",
		parent_target_id: 550,
	},
	{
		tid: 640,
		name: "Rechtsmedizin",
		parent_target_id: 550,
	},
	{
		tid: 641,
		name: "Schlaflabor",
		parent_target_id: 550,
	},
	{
		tid: 642,
		name: "Schmerztherapie",
		parent_target_id: 550,
	},
	{
		tid: 643,
		name: "Sekretärin",
		parent_target_id: 550,
	},
	{
		tid: 571,
		name: "Dialyse - Zweigstelle",
		parent_target_id: 550,
	},
	{
		tid: 570,
		name: "Dialyse",
		parent_target_id: 550,
	},
	{
		tid: 569,
		name: "Diabetologie",
		parent_target_id: 550,
	},
	{
		tid: 555,
		name: "Anatomie",
		parent_target_id: 550,
	},
	{
		tid: 568,
		name: "Darmzentrum",
		parent_target_id: 550,
	},
	{
		tid: 551,
		name: "AIDS / HIV",
		parent_target_id: 550,
	},
	{
		tid: 552,
		name: "Allergologie",
		parent_target_id: 550,
	},
	{
		tid: 687,
		name: "Altenheim (Fachperson)",
		parent_target_id: 550,
	},
	{
		tid: 554,
		name: "Ambulanz Poliklinik",
		parent_target_id: 550,
	},
	{
		tid: 553,
		name: "Ambulantes OP-Zentrum",
		parent_target_id: 550,
	},
	{
		tid: 556,
		name: "Arbeitsmedizin",
		parent_target_id: 550,
	},
	{
		tid: 557,
		name: "Arzt in Ausbildung",
		parent_target_id: 550,
	},
	{
		tid: 567,
		name: "Chirurgie / Kinderchirurgie",
		parent_target_id: 550,
	},
	{
		tid: 566,
		name: "Chirotherapie",
		parent_target_id: 550,
	},
	{
		tid: 565,
		name: "Chest Pain Unit",
		parent_target_id: 550,
	},
	{
		tid: 564,
		name: "Brustzentrum",
		parent_target_id: 550,
	},
	{
		tid: 563,
		name: "Biologie",
		parent_target_id: 550,
	},
	{
		tid: 562,
		name: "Biochemie",
		parent_target_id: 550,
	},
	{
		tid: 561,
		name: "Betriebsmedizin",
		parent_target_id: 550,
	},
	{
		tid: 560,
		name: "Betreutes Wohnen",
		parent_target_id: 550,
	},
	{
		tid: 559,
		name: "Behindertenheim",
		parent_target_id: 550,
	},
	{
		tid: 558,
		name: "Augenheilkunde",
		parent_target_id: 550,
	},
	{
		tid: 758,
		name: "Physikal. und Rehabilit. Medizin",
		parent_target_id: 550,
	},
	{
		tid: 755,
		name: "Frauenheilkunde und Geburtshilfe / Gynäkologische",
		parent_target_id: 550,
	},
	{
		tid: 547,
		name: "PTA",
		parent_target_id: 0,
	},
	{
		tid: 524,
		name: "Pädiatrie",
		parent_target_id: 0,
	},
	{
		tid: 475,
		name: "Klinik",
		parent_target_id: 0,
	},
	{
		tid: 464,
		name: "Dermatologie",
		parent_target_id: 0,
	},
	{
		tid: 690,
		name: "Fachberater",
		parent_target_id: 0,
	},
	{
		tid: 467,
		name: "Geriatrie",
		parent_target_id: 0,
	},
	{
		tid: 752,
		name: "Gynäkologe",
		parent_target_id: 550,
	},
	{
		tid: 469,
		name: "Hebammen",
		parent_target_id: 0,
	},
	{
		tid: 471,
		name: "HNO",
		parent_target_id: 0,
	},
	{
		tid: 550,
		name: "Sonstige",
		parent_target_id: 0,
	},
];

/**
 * @generated SignedSource<<7b2e87b8829e99817f567ca090461f23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentNodeTitleColumn_PointsAdditionFragment$data = {
  readonly contentSubmission: {
    readonly learnOpportunity: {
      readonly structureDefinition: {
        readonly title: string;
      };
    } | null;
  } | null;
  readonly " $fragmentType": "ContentNodeTitleColumn_PointsAdditionFragment";
};
export type ContentNodeTitleColumn_PointsAdditionFragment = ContentNodeTitleColumn_PointsAdditionFragment$data;
export type ContentNodeTitleColumn_PointsAdditionFragment$key = {
  readonly " $data"?: ContentNodeTitleColumn_PointsAdditionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentNodeTitleColumn_PointsAdditionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentNodeTitleColumn_PointsAdditionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentSubmission",
      "kind": "LinkedField",
      "name": "contentSubmission",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnOpportunityV2",
          "kind": "LinkedField",
          "name": "learnOpportunity",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "structureDefinition",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PointsAdditionV2",
  "abstractKey": null
};

(node as any).hash = "34102da6072e0c9a460d0544cbd7ff42";

export default node;

import React from "react";
import { Dropdown } from "primereact/dropdown";
import { DefaultTextFieldComponent } from "../../core/form/DefaultTextInput";

export interface ButtonDefinition {
	type: string;
	title?: string;
	link?: string;
}

interface OwnProps {
	fieldValue: ButtonDefinition | undefined;
	updateField: (button?: ButtonDefinition) => void;
	onChange?: () => void;
}

export const EditMessageDefinitionButtonSubform = ({
	fieldValue,
	updateField,
	onChange,
}: OwnProps) => {
	return (
		<div className="mt-2">
			<div className="p-field mb-3">
				<label>Button *</label>
				<Dropdown
					value={fieldValue?.type}
					options={[
						{
							label: "Keiner",
							value: "none",
						},
						{
							label: "Link",
							value: "link",
						},
						{
							label: "Bestätigen",
							value: "confirm",
						},
					]}
					onChange={(e) => {
						updateField({
							...fieldValue,
							type: e.value,
						});
						if (onChange) {
							onChange();
						}
					}}
				/>
			</div>
			{fieldValue?.type === "link" || fieldValue?.type === "confirm" ? (
				<div className="p-field mb-3">
					<label>Text *</label>
					<DefaultTextFieldComponent
						disabled={false}
						fieldName={"buttonText"}
						fieldValue={fieldValue.title}
						onChange={onChange}
						updateField={(title: string | undefined) =>
							updateField({
								...fieldValue,
								title: title,
							})
						}
						isValid={true}
						required={true}
					/>
				</div>
			) : undefined}
			{fieldValue?.type === "link" ? (
				<div className="p-field mb-3">
					<label>Link *</label>
					<DefaultTextFieldComponent
						disabled={false}
						fieldName={"link"}
						fieldValue={fieldValue.link}
						onChange={onChange}
						updateField={(link: string | undefined) =>
							updateField({
								...fieldValue,
								link: link,
							})
						}
						isValid={true}
						required={true}
					/>
				</div>
			) : undefined}
		</div>
	);
};

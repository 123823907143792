import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { FileSelectionField } from "../../../../../../files/FileSelectionField";
import { DefaultTextEditorComponent } from "../../../../../../core/form/DefaultTextEditorComponent";
import { AnswerOptionV2Input } from "../../../../../../../../__generated__/MultipleChoiceElementForm_EditMultipleChoiceElementV2Mutation.graphql";
import { CorrectSelectionText } from "./AnswerOptionDisplay";

interface OwnProps {
	answerOption: AnswerOptionV2Input;
	onUpdate: (answerOption: AnswerOptionV2Input) => void;
	onBack: () => void;
}

export const EditAnswerOptionForm = ({ answerOption, onUpdate, onBack }: OwnProps) => {
	return (
		<>
			<h3>Antwort bearbeiten</h3>
			<DefaultTextEditorComponent
				noLineBreak={true}
				fieldName={"answer"}
				fieldValue={answerOption.answer}
				updateField={(value) => onUpdate({ ...answerOption, answer: value || "" })}
				required={false}
				isValid={true}
				disabled={false}
			/>
			<div className="flex align-items-center mt-2 justify-content-between">
				<FileSelectionField
					name={"name"}
					selectedFileId={answerOption.imageRef || undefined}
					setSelectedFileId={(fileId) => onUpdate({ ...answerOption, imageRef: fileId })}
					filterByFileTypes={["image/png", "image/jpg", "image/jpeg"]}
				/>
				<div className="p-field-checkbox flex align-items-center" style={{ minWidth: 150 }}>
					<Checkbox
						className="mr-2"
						inputId="item"
						onChange={(e) =>
							onUpdate({ ...answerOption, isCorrect: e.target.checked! })
						}
						checked={answerOption.isCorrect}
					/>
					<label htmlFor="city4">{CorrectSelectionText}</label>
				</div>
			</div>
			<Button
				type="button"
				onClick={onBack}
				label="Zurück zu allen Antworten"
				className="p-button-secondary mt-2"
			/>
		</>
	);
};

import { PreviewPublishedNodeActionButton } from "../../../app/components/tree/table/actionButtons/PreviewPublishedNodeActionButton";
import graphql from "babel-plugin-relay/macro";
import { ProjectExtendActionButtons_TreeNodeFragment$key } from "../../../__generated__/ProjectExtendActionButtons_TreeNodeFragment.graphql";
import { useFragment } from "react-relay";

const TREE_NODE_FRAGMENT = graphql`
	fragment ProjectExtendActionButtons_TreeNodeFragment on TreeNode {
		...PreviewPublishedNodeActionButton_TreeNodeFragment
	}
`;

interface OwnProps {
	treeNodeFragmentRef: ProjectExtendActionButtons_TreeNodeFragment$key;
}

export const ProjectExtendActionButtons = ({ treeNodeFragmentRef }: OwnProps) => {
	const treeNode = useFragment<ProjectExtendActionButtons_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	return (
		<>
			<PreviewPublishedNodeActionButton treeNodeFragmentRef={treeNode} />
		</>
	);
};

import { EditCertificateTemplateScreen } from "../../infecto-lms-admin/screens/EditCertificateTemplateScreen";
import { PrivateRouteDefinition } from "../../infecto-lms-admin/components/core/model/PrivateRouteDefinition";
import { CertificateTemplatesScreen } from "../../infecto-lms-admin/screens/CertificateTemplatesScreen";

export const CERTIFICATE_TEMPLATES_PATH_V2 = "/certificate-templates";

export const CERTIFICATE_TEMPLATE_ID_URL_PARAM_V2 = ":certificateTemplateId";
export const getEditCertificateTemplatePathV2 = (certificateTemplateId: string) => {
	return `/certificate-templates/${certificateTemplateId}/edit`;
};

export const CertificateModuleRoutes: PrivateRouteDefinition[] = [
	{
		path: CERTIFICATE_TEMPLATES_PATH_V2,
		element: <CertificateTemplatesScreen />,
		requiredPermissions: ["AccountPermission_System_Root"],
	},
	{
		path: getEditCertificateTemplatePathV2(CERTIFICATE_TEMPLATE_ID_URL_PARAM_V2),
		element: <EditCertificateTemplateScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_Certificates_UpdateCertificates",
		],
	},
];

/**
 * @generated SignedSource<<bcf902909da142847f9adebc3f5f048a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditRootNodePartForm_TreeNodeFragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly cursors?: {
      readonly relevancy: any | null;
    } | null;
    readonly firstReleasedAt?: string | null;
    readonly icon?: {
      readonly id: string;
    } | null;
    readonly searchWords?: string | null;
    readonly tags?: ReadonlyArray<{
      readonly id: string;
    }>;
  };
  readonly " $fragmentType": "EditRootNodePartForm_TreeNodeFragment";
};
export type EditRootNodePartForm_TreeNodeFragment = EditRootNodePartForm_TreeNodeFragment$data;
export type EditRootNodePartForm_TreeNodeFragment$key = {
  readonly " $data"?: EditRootNodePartForm_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditRootNodePartForm_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditRootNodePartForm_TreeNodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RootNodeCursors",
              "kind": "LinkedField",
              "name": "cursors",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "relevancy",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstReleasedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "icon",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "searchWords",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "tags",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "type": "RootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "6bab08b9a6fc5cf0d933f6ad38bf02a3";

export default node;

import React, { useEffect } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { RelayEnvironment } from "../../../infecto-lms-admin/relay/RelayEnvironment";
import {
	ExtendConfigInterface,
	Config,
} from "../../../infecto-lms-admin/components/tree/editor/node/config/components/Config.interfaces";
import { AddSpecificUserStatusesCanStartTreeConfigInput } from "../../../__generated__/SpecificUserStatusesCanStartTreeConfigForm_AddMutation.graphql";
import { ProjectExtendStartTreeConfigEditDialogs_StartTreeConfigFragment$key } from "../../../__generated__/ProjectExtendStartTreeConfigEditDialogs_StartTreeConfigFragment.graphql";
import { ProjectExtendStartTreeConfigEditDialogs_TreeNodeFragment$key } from "../../../__generated__/ProjectExtendStartTreeConfigEditDialogs_TreeNodeFragment.graphql";
import { StartTreeConfigType } from "../../../__generated__/StartTreeConfigEditor_TreeNodeFragment.graphql";
import moment from "moment-timezone";
import { AddSpecificUserRolesCanStartTreeConfigInput } from "../../../__generated__/SpecificUserRolesCanStartTreeConfigForm_AddMutation.graphql";
import {
	AddSpecificUserStatusesCanStartTreeConfigMutation,
	SpecificUserStatusesCanStartTreeConfigForm,
} from "../../../app/components/tree/editor/node/config/tree/startTree/SpecificUserStatusesCanStartTreeConfigForm";
import {
	AddSpecificUserRolesCanStartTreeConfigMutation,
	SpecificUserRolesCanStartTreeConfigForm,
} from "../../../app/components/tree/editor/node/config/tree/startTree/SpecificUserRolesCanStartTreeConfigForm";

const TREE_NODE_FRAGMENT = graphql`
	fragment ProjectExtendStartTreeConfigEditDialogs_TreeNodeFragment on TreeNode {
		...SpecificUserStatusesCanStartTreeConfigForm_TreeNodeFragment
		...SpecificUserRolesCanStartTreeConfigForm_TreeNodeFragment
		structureDefinition {
			... on RootStructureDefinition {
				startConfigs {
					configType
					... on SpecifiedUserStatusesCanStartTreeConfig {
						userStatuses
						dateTime
					}
					... on SpecifiedUserRolesCanStartTreeConfig {
						userRoles
					}
				}
			}
		}
	}
`;

const CONFIGURATION_FRAGMENT = graphql`
	fragment ProjectExtendStartTreeConfigEditDialogs_StartTreeConfigFragment on StartTreeConfig {
		configType
		...SpecificUserStatusesCanStartTreeConfigForm_StartTreeConfigFragment
		...SpecificUserRolesCanStartTreeConfigForm_StartTreeConfigFragment
	}
`;

interface Props extends ExtendConfigInterface<StartTreeConfigType> {
	treeNodeFragmentRef: ProjectExtendStartTreeConfigEditDialogs_TreeNodeFragment$key;
	configurationFragmentRef: ProjectExtendStartTreeConfigEditDialogs_StartTreeConfigFragment$key;
}

export const ExtendStartTreeConfigs: Config<StartTreeConfigType, any>[] = [
	{
		configKey: "StartTree_SpecificUserStatusesCan",
		addMutation: (input: AddSpecificUserStatusesCanStartTreeConfigInput) =>
			AddSpecificUserStatusesCanStartTreeConfigMutation(RelayEnvironment, input),
		addMutationPayload: {
			dateTime: moment().add(1, "year").format(),
			userStatuses: [],
		},
		editable: true,
	},
	{
		configKey: "StartTree_SpecificUserRolesCan",
		addMutation: (input: AddSpecificUserRolesCanStartTreeConfigInput) =>
			AddSpecificUserRolesCanStartTreeConfigMutation(RelayEnvironment, input),
		addMutationPayload: {
			userRoles: [],
		},
		editable: true,
	},
];

export const ProjectExtendStartTreeConfigEditDialogs = (props: Props) => {
	const treeNode = useFragment<ProjectExtendStartTreeConfigEditDialogs_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		props.treeNodeFragmentRef,
	);
	const configuration =
		useFragment<ProjectExtendStartTreeConfigEditDialogs_StartTreeConfigFragment$key>(
			CONFIGURATION_FRAGMENT,
			props.configurationFragmentRef,
		);

	useEffect(() => {
		if (props.setCustomLabels) {
			props.setCustomLabels([
				{
					configKey: "StartTree_SpecificUserStatusesCan",
					label: treeNode.structureDefinition.startConfigs?.find(
						(c) => c.configType === "StartTree_SpecificUserStatusesCan",
					)?.dateTime,
				},
				{
					configKey: "StartTree_SpecificUserRolesCan",
					label: treeNode.structureDefinition.startConfigs?.find(
						(c) => c.configType === "StartTree_SpecificUserStatusesCan",
					)?.userRoles,
				},
			]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return props.setCustomLabels === undefined ? (
		<>
			{configuration.configType === "StartTree_SpecificUserStatusesCan" && (
				<SpecificUserStatusesCanStartTreeConfigForm
					{...props}
					treeNodeFragmentRef={treeNode}
					configurationFragmentRef={configuration}
				/>
			)}
			{configuration.configType === "StartTree_SpecificUserRolesCan" && (
				<SpecificUserRolesCanStartTreeConfigForm
					{...props}
					treeNodeFragmentRef={treeNode}
					configurationFragmentRef={configuration}
				/>
			)}
		</>
	) : (
		<></>
	);
};

/**
 * @generated SignedSource<<7d806d29ad17a5a398d4f96939da78a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddOnlyFachrichtungenVisibilityTreeConfigInput = {
  fachrichtungen: ReadonlyArray<string>;
  rootNodeId: string;
  clientMutationId?: string | null;
};
export type OnlySpecializationVisibilityConfigForm_AddOnlyFachrichtungenVisibilityConfigMutation$variables = {
  input: AddOnlyFachrichtungenVisibilityTreeConfigInput;
};
export type OnlySpecializationVisibilityConfigForm_AddOnlyFachrichtungenVisibilityConfigMutationVariables = OnlySpecializationVisibilityConfigForm_AddOnlyFachrichtungenVisibilityConfigMutation$variables;
export type OnlySpecializationVisibilityConfigForm_AddOnlyFachrichtungenVisibilityConfigMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly addOnlyFachrichtungenVisibilityTreeConfig: {
        readonly rootNode: {
          readonly " $fragmentSpreads": FragmentRefs<"OnlySpecializationVisibilityConfigForm_TreeNodeFragment">;
        };
      } | null;
    };
  };
};
export type OnlySpecializationVisibilityConfigForm_AddOnlyFachrichtungenVisibilityConfigMutationResponse = OnlySpecializationVisibilityConfigForm_AddOnlyFachrichtungenVisibilityConfigMutation$data;
export type OnlySpecializationVisibilityConfigForm_AddOnlyFachrichtungenVisibilityConfigMutation = {
  variables: OnlySpecializationVisibilityConfigForm_AddOnlyFachrichtungenVisibilityConfigMutationVariables;
  response: OnlySpecializationVisibilityConfigForm_AddOnlyFachrichtungenVisibilityConfigMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OnlySpecializationVisibilityConfigForm_AddOnlyFachrichtungenVisibilityConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddOnlyFachrichtungenVisibilityTreeConfigPayload",
                "kind": "LinkedField",
                "name": "addOnlyFachrichtungenVisibilityTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "OnlySpecializationVisibilityConfigForm_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OnlySpecializationVisibilityConfigForm_AddOnlyFachrichtungenVisibilityConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddOnlyFachrichtungenVisibilityTreeConfigPayload",
                "kind": "LinkedField",
                "name": "addOnlyFachrichtungenVisibilityTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "visibilityConfigs",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "TypeDiscriminator",
                                    "abstractKey": "__isVisibilityTreeConfig"
                                  },
                                  (v2/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "configType",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "fachrichtungen",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "OnlyFachrichtungenVisibilityTreeConfig",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2767550c53387d5b552780593c7eb3df",
    "id": null,
    "metadata": {},
    "name": "OnlySpecializationVisibilityConfigForm_AddOnlyFachrichtungenVisibilityConfigMutation",
    "operationKind": "mutation",
    "text": "mutation OnlySpecializationVisibilityConfigForm_AddOnlyFachrichtungenVisibilityConfigMutation(\n  $input: AddOnlyFachrichtungenVisibilityTreeConfigInput!\n) {\n  Admin {\n    Tree {\n      addOnlyFachrichtungenVisibilityTreeConfig(input: $input) {\n        rootNode {\n          ...OnlySpecializationVisibilityConfigForm_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment OnlySpecializationVisibilityConfigForm_TreeNodeFragment on TreeNode {\n  id\n  structureDefinition {\n    __typename\n    ... on RootStructureDefinition {\n      visibilityConfigs {\n        __typename\n        ...OnlySpecializationVisibilityConfigForm_VisibilityTreeConfigFragment\n        id\n      }\n    }\n  }\n}\n\nfragment OnlySpecializationVisibilityConfigForm_VisibilityTreeConfigFragment on VisibilityTreeConfig {\n  __isVisibilityTreeConfig: __typename\n  ... on OnlyFachrichtungenVisibilityTreeConfig {\n    id\n    configType\n    fachrichtungen\n  }\n}\n"
  }
};
})();

(node as any).hash = "28b4fddf10e16dd744113d6b70e82c17";

export default node;

import React from "react";
import { Calendar } from "primereact/calendar";
import moment from "moment-timezone";
import { RenderConfig } from "../core/form/ValidatedField";

interface OwnProps extends RenderConfig<string> {
	dateFormat?: string;
}

export function InfectoDefaultCalendarComponent({
	fieldName,
	fieldValue,
	updateField,
	disabled,
	onChange,
	dateFormat,
}: OwnProps) {
	const format = dateFormat ?? "YYYY-MM-DDTHH:mmZ";

	return (
		<Calendar
			name={fieldName}
			showTime
			hourFormat="24"
			dateFormat={"dd.mm.yy"}
			disabled={disabled}
			value={fieldValue ? moment(fieldValue.replace("[UTC]", "")).toDate() : undefined}
			onChange={(e) => {
				updateField(e.value ? moment(e.value as Date).format(format) : undefined);
				if (onChange) {
					onChange();
				}
			}}
		/>
	);
}

import { Button } from "primereact/button";
import React, { useState } from "react";
import { ConfigDropdown, ConfigDropdownOptions } from "./ConfigDropdown";
import { Dialog } from "primereact/dialog";
import { DropdownChangeEvent } from "primereact/dropdown";

export function ConfigItem<ConfigType, ConfigOptionType>(props: {
	configType: ConfigType;
	isPresentational: boolean;
	configOptions?: ConfigDropdownOptions<ConfigOptionType>[];
	canEdit: boolean;
	editDialog?: (props: any) => React.ReactElement;
	onChange?: (e: DropdownChangeEvent) => void;
	onDelete?: () => void;
	isLoading?: boolean;
}) {
	const [displayMaximizable, setDisplayMaximizable] = useState(false);

	const showEditDialog = () => {
		setDisplayMaximizable(true);
	};

	const onHide = () => {
		setDisplayMaximizable(false);
	};

	return (
		<>
			<div className={"flex align-items-center mb-2"}>
				<ConfigDropdown
					configOptions={props.configOptions}
					onChange={props.onChange}
					isPresentational={props.isPresentational}
					dropdownIcon={props.isPresentational ? "" : "pi pi-chevron-down"}
					value={props.configType}
					isLoading={props.isLoading}
				/>

				{props.isPresentational && (
					<Button
						disabled={props.isLoading}
						className="ml-2"
						icon="pi pi-trash"
						onClick={() => {
							props.onDelete!();
						}}
					/>
				)}

				{props.canEdit && (
					<Button
						className="ml-2"
						icon="pi pi-pencil"
						onClick={() => {
							showEditDialog();
						}}
					/>
				)}
			</div>

			{props.canEdit && (
				<Dialog
					header="Bearbeiten"
					visible={displayMaximizable}
					maximizable
					modal
					style={{ width: "50vw" }}
					onHide={() => onHide()}
				>
					{props.editDialog!({ onBack: onHide })}
				</Dialog>
			)}
		</>
	);
}

/**
 * @generated SignedSource<<f55aff55b1276601a40d4f5aae1d283a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AttachmentForm_InfectopharmFileAttachmentFragment$data = {
  readonly id: string;
  readonly file: {
    readonly id: string;
  } | null;
  readonly title: string | null;
  readonly description: string | null;
  readonly attachmentType: string | null;
  readonly " $fragmentType": "AttachmentForm_InfectopharmFileAttachmentFragment";
};
export type AttachmentForm_InfectopharmFileAttachmentFragment = AttachmentForm_InfectopharmFileAttachmentFragment$data;
export type AttachmentForm_InfectopharmFileAttachmentFragment$key = {
  readonly " $data"?: AttachmentForm_InfectopharmFileAttachmentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AttachmentForm_InfectopharmFileAttachmentFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AttachmentForm_InfectopharmFileAttachmentFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "file",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attachmentType",
      "storageKey": null
    }
  ],
  "type": "InfectopharmFileAttachment",
  "abstractKey": null
};
})();

(node as any).hash = "885417fc26b71c38476724afcf91991e";

export default node;

import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { ProjectEditRootExtensionForm_RootExtensionFragment$key } from "../../../__generated__/ProjectEditRootExtensionForm_RootExtensionFragment.graphql";
import { useFragment } from "react-relay";

const ROOT_EXTENSION_IMPL_FRAGMENT = graphql`
	fragment ProjectEditRootExtensionForm_RootExtensionFragment on RootExtension {
		doNotUse
	}
`;

interface OwnProps {
	rootId?: string;
	rootExtensionFragmentRef: ProjectEditRootExtensionForm_RootExtensionFragment$key;
}
export const ProjectEditRootExtensionForm = ({ rootExtensionFragmentRef }: OwnProps) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const data = useFragment<ProjectEditRootExtensionForm_RootExtensionFragment$key>(
		ROOT_EXTENSION_IMPL_FRAGMENT,
		rootExtensionFragmentRef,
	);

	return <></>;
};

import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { BaseScreen } from "./BaseScreen";
import { LandingPagesV2Screen_Query } from "../../__generated__/LandingPagesV2Screen_Query.graphql";
import { LandingPagesV2Table } from "../components/landing-pages/table/LandingPagesV2Table";

const QUERY = graphql`
	query LandingPagesV2Screen_Query {
		...LandingPagesV2Table_QueryFragment
	}
`;

export const LandingPagesV2Screen = () => {
	const data = useLazyLoadQuery<LandingPagesV2Screen_Query>(
		QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);

	return (
		<BaseScreen>
			<h1>Seiten</h1>
			<LandingPagesV2Table queryFragmentRef={data} />
		</BaseScreen>
	);
};

/**
 * @generated SignedSource<<d5635642956719db23e302882d24ce25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditTextElementV2Input = {
  title: string;
  text: string;
  textElementId: string;
  clientMutationId?: string | null;
};
export type TextElementForm_EditTextElementV2Mutation$variables = {
  input: EditTextElementV2Input;
};
export type TextElementForm_EditTextElementV2MutationVariables = TextElementForm_EditTextElementV2Mutation$variables;
export type TextElementForm_EditTextElementV2Mutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly editTextElement: {
        readonly textElement: {
          readonly " $fragmentSpreads": FragmentRefs<"TextElementForm_TextElementV2Fragment">;
        };
      } | null;
    };
  };
};
export type TextElementForm_EditTextElementV2MutationResponse = TextElementForm_EditTextElementV2Mutation$data;
export type TextElementForm_EditTextElementV2Mutation = {
  variables: TextElementForm_EditTextElementV2MutationVariables;
  response: TextElementForm_EditTextElementV2Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TextElementForm_EditTextElementV2Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditTextElementV2Payload",
                "kind": "LinkedField",
                "name": "editTextElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TextElementV2",
                    "kind": "LinkedField",
                    "name": "textElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "TextElementForm_TextElementV2Fragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TextElementForm_EditTextElementV2Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditTextElementV2Payload",
                "kind": "LinkedField",
                "name": "editTextElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TextElementV2",
                    "kind": "LinkedField",
                    "name": "textElement",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "546574c9ae591d7a0dc362f192d8c802",
    "id": null,
    "metadata": {},
    "name": "TextElementForm_EditTextElementV2Mutation",
    "operationKind": "mutation",
    "text": "mutation TextElementForm_EditTextElementV2Mutation(\n  $input: EditTextElementV2Input!\n) {\n  Admin {\n    ElearningV2 {\n      editTextElement(input: $input) {\n        textElement {\n          ...TextElementForm_TextElementV2Fragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment TextElementForm_TextElementV2Fragment on TextElementV2 {\n  id\n  title\n  text\n}\n"
  }
};
})();

(node as any).hash = "58f82580fb7f8822c2ac3e193dd7f7c6";

export default node;

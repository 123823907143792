import { Calendar } from "primereact/calendar";
import moment from "moment-timezone";
import React from "react";

interface Props {
	name: string;
	value: string | undefined;
	onUpdate: (newValue: string | undefined) => void;
	disabled: boolean;
	withTimeInput?: boolean;
	isEndOfTheDay?: boolean;
}

export function ConfigDatePicker({
	name,
	value,
	onUpdate,
	disabled,
	withTimeInput,
	isEndOfTheDay,
}: Props) {
	return (
		<Calendar
			name={name}
			showTime={withTimeInput}
			visible={true}
			hourFormat="24"
			dateFormat={"dd.mm.yy"}
			disabled={disabled}
			value={value ? moment(value.replace("[UTC]", "")).toDate() : undefined}
			onChange={(e) => {
				onUpdate(
					e.value
						? isEndOfTheDay
							? moment(e.value as Date)
									.endOf("day")
									.format("YYYY-MM-DDTHH:mmZ")
							: moment(e.value as Date).format("YYYY-MM-DDTHH:mmZ")
						: undefined,
				);
			}}
		/>
	);
}

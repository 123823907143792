import { PrivateRouteDefinition } from "../../infecto-lms-admin/components/core/model/PrivateRouteDefinition";
import { NewsTickerDefinitionOverviewScreen } from "../screens/NewsTickerDefinitionOverviewScreen";
import {
	EDIT_NEWSTICKER_DEFINITION_ROUTE,
	EditNewsTickerDefinitionScreen,
} from "../screens/EditNewsTickerDefinitionScreen";

export const NewstickerRoutes: PrivateRouteDefinition[] = [
	{
		path: "/newsticker",
		element: <NewsTickerDefinitionOverviewScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_Messaging_ReadMessages",
		],
	},
	{
		path: EDIT_NEWSTICKER_DEFINITION_ROUTE,
		element: <EditNewsTickerDefinitionScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_Messaging_UpdateMessages",
		],
	},
];

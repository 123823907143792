import { graphql } from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { CreatePodcastElementButton_CreatePodcastElementMutation } from "../../../../../../../../__generated__/CreatePodcastElementButton_CreatePodcastElementMutation.graphql";

const CREATE_VIMEO_PODCAST_ELEMENT_MUTATION = graphql`
	mutation CreatePodcastElementButton_CreatePodcastElementMutation(
		$input: CreatePodcastElementV2Input!
	) {
		Admin {
			ElearningV2 {
				createPodcastElement(input: $input) {
					eLearningContentNode {
						...ContentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface OwnProps {
	eLearningContentNodeId: string;
}

export const CreatePodcastElementButton = ({ eLearningContentNodeId }: OwnProps) => {
	const [createPodcastElement, isCreatingPodcastElement] =
		useMutation<CreatePodcastElementButton_CreatePodcastElementMutation>(
			CREATE_VIMEO_PODCAST_ELEMENT_MUTATION,
		);

	return (
		<Button
			className={"mr-2"}
			disabled={isCreatingPodcastElement}
			tooltip={"Podcast-Baustein anlegen"}
			icon="pi pi-play"
			onClick={() => {
				createPodcastElement({
					variables: {
						input: {
							title: "Neues Element",
							eLearningContentNodeId: eLearningContentNodeId,
						},
					},
				});
			}}
		/>
	);
};

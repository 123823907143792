/**
 * @generated SignedSource<<0f1e1a599a71ee707b2702e1ff55c0ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PointPoolsScreen_PointsPoolsQuery$variables = {};
export type PointPoolsScreen_PointsPoolsQueryVariables = PointPoolsScreen_PointsPoolsQuery$variables;
export type PointPoolsScreen_PointsPoolsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PointPoolsTable_QueryFragment">;
};
export type PointPoolsScreen_PointsPoolsQueryResponse = PointPoolsScreen_PointsPoolsQuery$data;
export type PointPoolsScreen_PointsPoolsQuery = {
  variables: PointPoolsScreen_PointsPoolsQueryVariables;
  response: PointPoolsScreen_PointsPoolsQuery$data;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PointPoolsScreen_PointsPoolsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PointPoolsTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PointPoolsScreen_PointsPoolsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PointsAdminSchema",
            "kind": "LinkedField",
            "name": "Points",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PointsPoolsConnection",
                "kind": "LinkedField",
                "name": "PointPools",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PointsPoolsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PointPool",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": [
                  "filterByName"
                ],
                "handle": "connection",
                "key": "PointPoolsTable_PointPools",
                "kind": "LinkedHandle",
                "name": "PointPools"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4c06e2ec6ded51599f4a91038404da14",
    "id": null,
    "metadata": {},
    "name": "PointPoolsScreen_PointsPoolsQuery",
    "operationKind": "query",
    "text": "query PointPoolsScreen_PointsPoolsQuery {\n  ...PointPoolsTable_QueryFragment\n}\n\nfragment PointPoolsTable_QueryFragment on Query {\n  Admin {\n    Points {\n      PointPools {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            name\n            description\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n"
  }
};

(node as any).hash = "d7a3f28a2ef2266a72b75e744c217ff8";

export default node;

/**
 * @generated SignedSource<<f239f1699c7f2fce5c2224a3e4e14614>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmailTemplatesTable_Refetch$variables = {
  after?: string | null;
  first?: number | null;
};
export type EmailTemplatesTable_RefetchVariables = EmailTemplatesTable_Refetch$variables;
export type EmailTemplatesTable_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EmailTemplatesTable_EmailTemplatesListFragment">;
};
export type EmailTemplatesTable_RefetchResponse = EmailTemplatesTable_Refetch$data;
export type EmailTemplatesTable_Refetch = {
  variables: EmailTemplatesTable_RefetchVariables;
  response: EmailTemplatesTable_Refetch$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subject",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailTemplatesTable_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "EmailTemplatesTable_EmailTemplatesListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailTemplatesTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmailAdminSchema",
            "kind": "LinkedField",
            "name": "Email",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "PersonalizedEmailTemplatesConnection",
                "kind": "LinkedField",
                "name": "PersonalizedEmailTemplates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonalizedEmailTemplatesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PersonalizedEmailTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EmailTemplate",
                            "kind": "LinkedField",
                            "name": "template",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "EmailTemplatesTable_PersonalizedEmailTemplates",
                "kind": "LinkedHandle",
                "name": "PersonalizedEmailTemplates"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EmailTemplate",
                "kind": "LinkedField",
                "name": "AvailableSystemTemplates",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "previewText",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "body",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "variables",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4f4a2ca21aefa716f78dd86d50fbb73a",
    "id": null,
    "metadata": {},
    "name": "EmailTemplatesTable_Refetch",
    "operationKind": "query",
    "text": "query EmailTemplatesTable_Refetch(\n  $after: String\n  $first: Int\n) {\n  ...EmailTemplatesTable_EmailTemplatesListFragment_2HEEH6\n}\n\nfragment EmailTemplatesTable_EmailTemplatesListFragment_2HEEH6 on Query {\n  Admin {\n    Email {\n      PersonalizedEmailTemplates(first: $first, after: $after) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            template {\n              key\n              subject\n            }\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n  ...NewEmailTemplateButton_AvailableTemplatesFragment\n}\n\nfragment NewEmailTemplateButton_AvailableTemplatesFragment on Query {\n  Admin {\n    Email {\n      AvailableSystemTemplates {\n        key\n        previewText\n        subject\n        body\n        variables\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5c28ae44a0b76430d0311a33568b6a83";

export default node;

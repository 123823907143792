import { useFormik } from "formik";
import { Button } from "primereact/button";
import React from "react";
import * as Yup from "yup";
import { useTypedDispatch, useTypedSelector } from "../../../redux/Store";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
	selectArrayOfEditedForms,
} from "../../../redux/slices/CoreSlice";
import { useCallbackPrompt } from "../../../functions/hooks/UseCallBackPrompt";
import { WarningUnsavedChangesDialog } from "../../core/dialog/WarningUnsavedChangesDialog";
import { ValidatedField } from "../../core/form/ValidatedField";
import {
	DefaultSwitchComponent,
	DefaultTextAreaComponent,
	DefaultTextFieldComponent,
} from "../../core/form/DefaultTextInput";

interface FormState {
	name?: string;
	description?: string;
	triggersExport?: boolean;
}

interface OwnProps {
	loading?: boolean;
	initialValues?: FormState;
	onSubmit: (values: FormState) => void;
}

export const EditPointPoolsForm = ({ loading, initialValues, onSubmit }: OwnProps) => {
	const dispatch = useTypedDispatch();
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);
	const formId = "EditPointPoolsForm";
	const [showPrompt, confirmNavigation, cancelNavigation, setShowPrompt] = useCallbackPrompt(
		arrayOfEditedForms.length > 0,
	);
	const formik = useFormik<FormState>({
		initialValues: {
			name: initialValues?.name ?? "Neues Punkte-Konto",
			description: initialValues?.description ?? "#000000",
			triggersExport: initialValues?.triggersExport ?? false,
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Name wird benötigt"),
		}),
		onSubmit: (values, { setSubmitting }) => {
			onSubmit({
				...values,
			});
			setSubmitting(false);
			dispatch(resetArrayOfEditedForms());
		},
	});

	return (
		<>
			{showPrompt && (
				<WarningUnsavedChangesDialog
					confirmNavigation={confirmNavigation}
					setShowDialog={setShowPrompt}
					cancelNavigation={cancelNavigation}
				/>
			)}
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, string>
					name={"name"}
					label={"Name"}
					required={true}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={DefaultTextFieldComponent}
					formikConfig={formik}
				/>
				<ValidatedField<FormState, string>
					name={"description"}
					label={"Beschreibung"}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={DefaultTextAreaComponent}
					formikConfig={formik}
				/>
				<ValidatedField<FormState, boolean>
					name={"triggersExport"}
					label={"Wird automatisch exportiert"}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={DefaultSwitchComponent}
					formikConfig={formik}
				/>

				<Button disabled={loading} type="submit" label="Speichern" className="p-mt-2" />
			</form>
		</>
	);
};

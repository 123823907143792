import { graphql } from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { CreateTextElementButton_CreateTextElementV2Mutation } from "../../../../../../../../__generated__/CreateTextElementButton_CreateTextElementV2Mutation.graphql";

const CREATE_TEXT_ELEMENT_MUTATION = graphql`
	mutation CreateTextElementButton_CreateTextElementV2Mutation(
		$input: CreateTextElementV2Input!
	) {
		Admin {
			ElearningV2 {
				createTextElement(input: $input) {
					eLearningContentNode {
						...ContentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface OwnProps {
	eLearningContentNodeId: string;
}

export const CreateTextElementButton = ({ eLearningContentNodeId }: OwnProps) => {
	const [createTextElement, isCreatingTextElement] =
		useMutation<CreateTextElementButton_CreateTextElementV2Mutation>(
			CREATE_TEXT_ELEMENT_MUTATION,
		);

	return (
		<Button
			className={"mr-2"}
			disabled={isCreatingTextElement}
			tooltip={"Text-Baustein anlegen"}
			icon="pi pi-align-center"
			onClick={() => {
				createTextElement({
					variables: {
						input: {
							title: "Neues Element",
							text: "",
							eLearningContentNodeId: eLearningContentNodeId,
						},
					},
				});
			}}
		/>
	);
};

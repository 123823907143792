import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery, useMutation } from "react-relay";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { ValidatedField } from "../core/form/ValidatedField";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { EMAIL_TEMPLATE_KEYS } from "../../i18n/emails/i18n";
import { EmailTesterInformExpirationOrRemindRetryForm_EmailTemplatesQuery } from "../../../__generated__/EmailTesterInformExpirationOrRemindRetryForm_EmailTemplatesQuery.graphql";
import { EmailTesterInformExpirationOrRemindRetryForm_Mutation } from "../../../__generated__/EmailTesterInformExpirationOrRemindRetryForm_Mutation.graphql";

const QUERY = graphql`
	query EmailTesterInformExpirationOrRemindRetryForm_EmailTemplatesQuery($id: ID!) {
		node(id: $id) {
			... on PersonalizedEmailTemplate {
				id
				template {
					key
					subject
					variables
				}
			}
		}
	}
`;

const MUTATION_TESTER = graphql`
	mutation EmailTesterInformExpirationOrRemindRetryForm_Mutation(
		$input: TesterSendInformExpirationRemindRetryEmailInput!
	) {
		Admin {
			TesterEmail {
				EmailTesterInformExpirationRemindRetryEmail(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;

interface FormState {
	rootNodeId: string;
}
interface OwnProps {
	templateId: string;
	modalVisible: boolean;
	setModalVisible: (boolean: boolean) => void;
}
export const EmailTesterInformExpirationOrRemindRetryForm = ({
	templateId,
	modalVisible,
	setModalVisible,
}: OwnProps) => {
	const query =
		useLazyLoadQuery<EmailTesterInformExpirationOrRemindRetryForm_EmailTemplatesQuery>(
			QUERY,
			{ id: templateId },
			{ fetchPolicy: "network-only" },
		);

	const template = query?.node?.template;
	const [testerEmailSend, isSending] =
		useMutation<EmailTesterInformExpirationOrRemindRetryForm_Mutation>(MUTATION_TESTER);

	const formik = useFormik<FormState>({
		initialValues: {
			rootNodeId: "",
		},
		onSubmit: (values) => {
			testerEmailSend({
				variables: {
					input: {
						rootNodeId: values.rootNodeId,
						templateId: templateId,
					},
				},
				onCompleted: () => {
					toast.success("Email gesendet");
				},
				onError: (err) => {
					toast.error(err.message);
				},
			});
		},
	});

	return (
		<div>
			<Dialog
				className="w-9 md:w-6 lg:w-4"
				header={<h3 className="m-0">Test {EMAIL_TEMPLATE_KEYS[template?.key!]}</h3>}
				onHide={() => setModalVisible(false)}
				visible={modalVisible}
			>
				<form onSubmit={formik.handleSubmit} className="p-fluid">
					<div>
						<p>User ist Email Tester</p>
						<ValidatedField<FormState, string>
							name={"rootNodeId"}
							label={"Module"}
							component={({ fieldName, fieldValue, updateField }) => (
								<div>
									<Dropdown
										name={fieldName}
										value={fieldValue}
										options={[
											{
												label: "Staging Inhalt",
												value: "staging-root",
											},
										]}
										onChange={(e) => {
											updateField(e.value);
										}}
									/>
								</div>
							)}
							formikConfig={formik}
						/>
					</div>
					<Button
						disabled={Object.entries(formik.touched).length === 0 || isSending}
						type="submit"
						label="Speichern"
						className="p-mt-2"
					/>
				</form>
			</Dialog>
		</div>
	);
};

import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ActionColumn_FeedbackFragment$key } from "../../../__generated__/ActionColumn_FeedbackFragment.graphql";
import { getSingleFeedbackV2Path } from "../../../app/routes/FeedbackModuleRoutes";

const FEEDBACK_FRAGMENT = graphql`
	fragment ActionColumn_FeedbackFragment on FeedbackV2 {
		id
	}
`;

interface OwnProps {
	feedbackFragmentRef: ActionColumn_FeedbackFragment$key;
}

export const ActionColumn = ({ feedbackFragmentRef }: OwnProps) => {
	const navigate = useNavigate();

	const feedback = useFragment<ActionColumn_FeedbackFragment$key>(
		FEEDBACK_FRAGMENT,
		feedbackFragmentRef,
	);

	return (
		<Button
			className="mr-2"
			onClick={() => navigate(getSingleFeedbackV2Path(feedback.id))}
			icon={"pi pi-search"}
		/>
	);
};

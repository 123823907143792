import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { TitleColumnPublishedNodes_PublishedNodesFragment$key } from "../../../__generated__/TitleColumnPublishedNodes_PublishedNodesFragment.graphql";

const TREE_STATE_FRAGMENT = graphql`
	fragment TitleColumnPublishedNodes_PublishedNodesFragment on LearnOpportunityV2 {
		structureDefinition {
			title
		}
	}
`;

interface OwnProps {
	publishedNodesFragmentRef: TitleColumnPublishedNodes_PublishedNodesFragment$key;
}

export const TitleColumnPublishedNodes = ({ publishedNodesFragmentRef }: OwnProps) => {
	const publishedNodes = useFragment<TitleColumnPublishedNodes_PublishedNodesFragment$key>(
		TREE_STATE_FRAGMENT,
		publishedNodesFragmentRef,
	);

	return <div>{publishedNodes.structureDefinition.title}</div>;
};

import { PrivateRouteDefinition } from "../../infecto-lms-admin/components/core/model/PrivateRouteDefinition";
import { TreeStatesScreen } from "../../infecto-lms-admin/screens/TreeStatesScreen";
import { SingleTreeStateScreen } from "../../infecto-lms-admin/screens/SingleTreeStateScreen";

export const TREE_STATES_PATH = "/tree-states";

export const TREE_STATE_ID_URL_PARAM = ":treeStateId";
export const getSingleTreeStatePath = (treeStateId: string) => {
	return `/tree-states/${treeStateId}`;
};

export const LearnStatesModuleRoutes: PrivateRouteDefinition[] = [
	{
		path: TREE_STATES_PATH,
		element: <TreeStatesScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_LearnStates_ReadLearnStates",
		],
	},
	{
		path: getSingleTreeStatePath(TREE_STATE_ID_URL_PARAM),
		element: <SingleTreeStateScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_LearnStates_ReadLearnStates",
		],
	},
];

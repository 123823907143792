/**
 * @generated SignedSource<<3a3da91e6d1cae24e57c43f89dfd86cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FileSelectionField_Query$variables = {
  fileId: string;
  skip: boolean;
  filterByFileTypes?: ReadonlyArray<string> | null;
};
export type FileSelectionField_QueryVariables = FileSelectionField_Query$variables;
export type FileSelectionField_Query$data = {
  readonly node?: {
    readonly name?: string;
    readonly url?: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"FilesTable_FilesListFragment">;
};
export type FileSelectionField_QueryResponse = FileSelectionField_Query$data;
export type FileSelectionField_Query = {
  variables: FileSelectionField_QueryVariables;
  response: FileSelectionField_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fileId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByFileTypes"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "fileId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "type": "File",
  "abstractKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "fileType",
    "variableName": "filterByFileTypes"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  {
    "kind": "Literal",
    "name": "tagsExcluded",
    "value": [
      "IssuedCertificate"
    ]
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FileSelectionField_Query",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "filterByFileTypes",
            "variableName": "filterByFileTypes"
          }
        ],
        "kind": "FragmentSpread",
        "name": "FilesTable_FilesListFragment"
      },
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "FileSelectionField_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FilesAdminSchema",
            "kind": "LinkedField",
            "name": "Files",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "FilesConnection",
                "kind": "LinkedField",
                "name": "Files",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FilesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "accessType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "uploadDateTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thumbnail",
                            "storageKey": null
                          },
                          (v5/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "filters": [
                  "name",
                  "fileType",
                  "fromDateTimeInclusive",
                  "toDateTimeInclusive",
                  "tagsIncluded",
                  "tagsExcluded"
                ],
                "handle": "connection",
                "key": "FilesTable_Files",
                "kind": "LinkedHandle",
                "name": "Files"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "0076cdf1736968296ec82ba173512ad8",
    "id": null,
    "metadata": {},
    "name": "FileSelectionField_Query",
    "operationKind": "query",
    "text": "query FileSelectionField_Query(\n  $fileId: ID!\n  $skip: Boolean!\n  $filterByFileTypes: [String!]\n) {\n  ...FilesTable_FilesListFragment_BM721\n  node(id: $fileId) @skip(if: $skip) {\n    __typename\n    ... on File {\n      name\n      url\n    }\n    id\n  }\n}\n\nfragment FilesTable_FilesListFragment_BM721 on Query {\n  Admin {\n    Files {\n      Files(first: 20, fileType: $filterByFileTypes, tagsExcluded: [\"IssuedCertificate\"]) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            name\n            fileType\n            accessType\n            uploadDateTime\n            thumbnail\n            url\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "73ba8fc56c446665dc6785b177daf0e0";

export default node;

import { graphql } from "babel-plugin-relay/macro";
import { Card } from "primereact/card";
import React from "react";
import {
	RewardContentConfigEditor_TreeNodeFragment$key,
	RewardContentConfigType,
} from "../../../../../../../__generated__/RewardContentConfigEditor_TreeNodeFragment.graphql";
import { useFragment, useMutation } from "react-relay";
import {
	RewardContentConfigEditor_SetIfRepeatedDoNotRewardContentConfigMutation,
	SetIfRepeatedDoNotRewardContentConfigInput,
} from "../../../../../../../__generated__/RewardContentConfigEditor_SetIfRepeatedDoNotRewardContentConfigMutation.graphql";
import {
	RewardContentConfigEditor_SetDoRewardContentConfigMutation,
	SetDoRewardContentConfigInput,
} from "../../../../../../../__generated__/RewardContentConfigEditor_SetDoRewardContentConfigMutation.graphql";
import { Config } from "../components/Config.interfaces";
import { ConfigItem } from "../components/ConfigItem";
import { ConfigDropdownOptions } from "../components/ConfigDropdown";

const TREE_NODE_FRAGMENT = graphql`
	fragment RewardContentConfigEditor_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ContentTypeDefinition {
				rewardConfig {
					configType
				}
			}
		}
	}
`;

const SET_DO_REWARD_CONTENT_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation RewardContentConfigEditor_SetDoRewardContentConfigMutation(
		$input: SetDoRewardContentConfigInput!
	) {
		Admin {
			Tree {
				setDoRewardContentConfig(input: $input) {
					contentNode {
						...RewardContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const SET_IF_REPEATED_DO_NOT_REWARD_CONTENT_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation RewardContentConfigEditor_SetIfRepeatedDoNotRewardContentConfigMutation(
		$input: SetIfRepeatedDoNotRewardContentConfigInput!
	) {
		Admin {
			Tree {
				setIfRepeatedDoNotRewardContentConfig(input: $input) {
					contentNode {
						...RewardContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: RewardContentConfigEditor_TreeNodeFragment$key;
}

export const RewardContentConfigEditor = ({ treeNodeFragmentRef }: OwnProps) => {
	const treeNode = useFragment<RewardContentConfigEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const [setDoRewardContentConfig] =
		useMutation<RewardContentConfigEditor_SetDoRewardContentConfigMutation>(
			SET_DO_REWARD_CONTENT_CONDITION_CONFIGURATION_MUTATION,
		);
	const [setIfRepeatedDoNotRewardContentConfig] =
		useMutation<RewardContentConfigEditor_SetIfRepeatedDoNotRewardContentConfigMutation>(
			SET_IF_REPEATED_DO_NOT_REWARD_CONTENT_CONDITION_CONFIGURATION_MUTATION,
		);

	const configType: RewardContentConfigType = treeNode.typeDefinition.rewardConfig
		?.configType as RewardContentConfigType;

	const doReward: Config<RewardContentConfigType, SetDoRewardContentConfigInput> = {
		configKey: "RewardContent_Do",
		addMutation: (input: SetDoRewardContentConfigInput) => {
			setDoRewardContentConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const doNotRewardIfRepeated: Config<
		RewardContentConfigType,
		SetIfRepeatedDoNotRewardContentConfigInput
	> = {
		configKey: "RewardContent_IfRepeatedDoNot",
		addMutation: (input: SetIfRepeatedDoNotRewardContentConfigInput) => {
			setIfRepeatedDoNotRewardContentConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const configs: Config<RewardContentConfigType, any | never>[] = [
		doReward,
		doNotRewardIfRepeated,
	];

	const options: ConfigDropdownOptions<RewardContentConfigType>[] = configs.map((c) => {
		return {
			label: c.configKey,
			value: c.configKey,
		};
	});

	return (
		<Card className="mb-2">
			<h2>Belohnungen</h2>

			<ConfigItem<RewardContentConfigType, string>
				isPresentational={false}
				configType={configType}
				canEdit={
					configs.find(
						(c) => c.configKey === treeNode.typeDefinition.rewardConfig?.configType,
					)?.editable as boolean
				}
				configOptions={options}
				onChange={(e) => {
					const selectedConfig = configs.find((c) => c.configKey === e.value);

					if (selectedConfig) {
						selectedConfig.addMutation({
							contentNodeId: treeNode.id,
							...selectedConfig.addMutationPayload,
						});
					}
				}}
			/>
		</Card>
	);
};

import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { useTypedDispatch } from "../../../../infecto-lms-admin/redux/Store";
import { AnsweringTypeV2 } from "../../../../__generated__/MultipleChoiceElementForm_MultipleChoiceElementV2Fragment.graphql";
import { AnswerOptionV2Input } from "../../../../__generated__/MultipleChoiceElementForm_EditMultipleChoiceElementV2Mutation.graphql";
import { useContext } from "react";
import { ImplContextV2, ImplV2 } from "../../../../infecto-lms-admin/impl/ImplContextV2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ValidatedField } from "../../../../infecto-lms-admin/components/core/form/ValidatedField";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../../infecto-lms-admin/redux/slices/CoreSlice";
import { DefaultTextEditorComponent } from "../../../../infecto-lms-admin/components/core/form/DefaultTextEditorComponent";
import { FileSelectionField } from "../../../../infecto-lms-admin/components/files/FileSelectionField";
import { Dropdown } from "primereact/dropdown";
import { AnswerOptionsField } from "../../../../infecto-lms-admin/components/tree/editor/node/content/elearning/multiplechoice/AnswerOptionsField";
import { Button } from "primereact/button";
import { MultipleChoicePoolElementForm_MultipleChoiceElementV2Fragment$key } from "../../../../__generated__/MultipleChoicePoolElementForm_MultipleChoiceElementV2Fragment.graphql";
import { MultipleChoicePoolElementForm_EditMultipleChoiceElementV2Mutation } from "../../../../__generated__/MultipleChoicePoolElementForm_EditMultipleChoiceElementV2Mutation.graphql";

const MULTIPLE_CHOICE_POOL_ELEMENT_FRAGMENT = graphql`
	fragment MultipleChoicePoolElementForm_MultipleChoiceElementV2Fragment on MultipleChoiceElementV2 {
		id
		title
		question
		image {
			id
		}
		answeringType
		answerOptions {
			answer
			isCorrect
			image {
				id
			}
		}
	}
`;

const EDIT_MULTIPLE_CHOICE_POOL_ELEMENT_MUTATION = graphql`
	mutation MultipleChoicePoolElementForm_EditMultipleChoiceElementV2Mutation(
		$input: EditElementsPoolElementInput!
	) {
		Admin {
			Elementspool {
				editElementsPoolElement(input: $input) {
					pool {
						id
						elements {
							...MultipleChoicePoolElementForm_MultipleChoiceElementV2Fragment
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	poolId: String;
	elementFragmentRef: MultipleChoicePoolElementForm_MultipleChoiceElementV2Fragment$key;
	onBack: () => void;
}

interface FormState {
	title: string;
	question: string;
	imageIdOpt?: string;
	answeringType: AnsweringTypeV2;
	answerOptions: AnswerOptionV2Input[];
}

export const MultipleChoicePoolElementForm = ({ elementFragmentRef, onBack, poolId }: OwnProps) => {
	const implContext = useContext<ImplV2>(ImplContextV2);
	const multipleChoiceElement =
		useFragment<MultipleChoicePoolElementForm_MultipleChoiceElementV2Fragment$key>(
			MULTIPLE_CHOICE_POOL_ELEMENT_FRAGMENT,
			elementFragmentRef,
		);
	const [editMultipleChoicePoolElement, isEditingMultipleChoicePoolElement] =
		useMutation<MultipleChoicePoolElementForm_EditMultipleChoiceElementV2Mutation>(
			EDIT_MULTIPLE_CHOICE_POOL_ELEMENT_MUTATION,
		);

	const dispatch = useTypedDispatch();

	const formId = "MultipleChoiceElementForm";

	const formik = useFormik<FormState>({
		initialValues: {
			title: multipleChoiceElement.title,
			question: multipleChoiceElement.question,
			imageIdOpt: multipleChoiceElement.image?.id || undefined,
			answeringType: multipleChoiceElement.answeringType,
			answerOptions: [
				...multipleChoiceElement.answerOptions.map((ao) => ({
					answer: ao.answer,
					isCorrect: ao.isCorrect,
					imageRef: ao.image?.id,
				})),
			],
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Das Feld Titel wird benötigt."),
			question: Yup.string().required("Das Feld Frage wird benötigt."),
			answeringType: Yup.string().required("Das Feld Antwort-Typ wird benötigt."),
			answerOptions: Yup.array()
				.required("Antwortmöglichkeiten werden benötigt")
				.min(1, "Es muss mindestens eine Antwort geben")
				.test("test2", "test2", function (value) {
					const options = value as AnswerOptionV2Input[];
					if (!options.find((ao) => ao.isCorrect)) {
						return this.createError({
							path: "answerOptions",
							message: "Es muss mindestens eine richtige Antwort geben.",
						});
					}
					if (options.find((o) => !o.answer)) {
						return this.createError({
							path: "answerOptions",
							message: "Jede Antwort benötigt einen Antworttext.",
						});
					}
					if (new Set(options.map((o) => o.answer)).size < options.length) {
						return this.createError({
							path: "answerOptions",
							message: "Jede Antwort muss einzigartig sein.",
						});
					}
					return true;
				})
				.typeError("Es gab ein Fehler bei der Validierung"),
		}),
		onSubmit: (values) => {
			editMultipleChoicePoolElement({
				variables: {
					input: {
						poolId: poolId as string,
						elementId: multipleChoiceElement.id,
						title: values.title,
						question: values.question,
						imageId: values.imageIdOpt,
						answeringType: values.answeringType,
						answerOptions: values.answerOptions,
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	const isTitleFieldRequired = implContext.node?.isTitleFieldForMultipleChoiceRequired ?? true;

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			{isTitleFieldRequired && (
				<ValidatedField<FormState, string>
					name={"title"}
					label={"Titel"}
					onChange={() => {
						dispatch(addEditedFormToEditedFormsArray({ form: formId }));
					}}
					component={DefaultTextEditorComponent}
					formikConfig={formik}
				/>
			)}
			<ValidatedField<FormState, string>
				name={"question"}
				label={"Frage"}
				onChange={() => {
					dispatch(addEditedFormToEditedFormsArray({ form: formId }));
				}}
				component={DefaultTextEditorComponent}
				showListButtons={true}
				formikConfig={formik}
			/>

			<ValidatedField<FormState, string>
				name={"imageIdOpt"}
				label={"Bild zur Frage"}
				onChange={() => {
					dispatch(addEditedFormToEditedFormsArray({ form: formId }));
				}}
				component={({ fieldName, fieldValue, updateField, onChange }) => (
					<FileSelectionField
						name={fieldName}
						selectedFileId={fieldValue}
						setSelectedFileId={updateField}
						filterByFileTypes={["image/png", "image/jpg", "image/jpeg"]}
						onChange={onChange}
					/>
				)}
				formikConfig={formik}
			/>

			<ValidatedField<FormState, AnsweringTypeV2>
				name={"answeringType"}
				label={"Erlaubte Antworten"}
				component={({ fieldName, fieldValue, updateField }) => {
					return (
						<div>
							<Dropdown
								name={fieldName}
								value={fieldValue}
								options={[
									{
										label: "Nur eine Antwort erlauben",
										value: "allowOnlyOneAnswer",
									},
									{
										label: "Mehrere Antworten erlauben, aber es müssen nicht alle richtigen Möglichkeiten ausgewählt werden.",
										value: "allowMultipleAnswersOnlySomeCorrectMustBeGiven",
									},
									{
										label: "Mehrere Antworten erlauben - es müssen alle richtigen Möglichkeiten ausgewählt werden.",
										value: "allowMultipleAnswersAllCorrectMustBeGiven",
									},
								]}
								onChange={(e) => {
									updateField(e.value);
									dispatch(addEditedFormToEditedFormsArray({ form: formId }));
								}}
							/>
						</div>
					);
				}}
				formikConfig={formik}
			/>

			<ValidatedField<FormState, AnswerOptionV2Input[]>
				name={"answerOptions"}
				label={"Antwortmöglichkeiten"}
				onChange={() => {
					dispatch(addEditedFormToEditedFormsArray({ form: formId }));
				}}
				component={({ fieldValue, updateField, onChange }) => (
					<AnswerOptionsField
						answerOptions={fieldValue || []}
						onUpdate={updateField}
						onChange={onChange}
					/>
				)}
				formikConfig={formik}
			/>

			<Button
				disabled={
					Object.entries(formik.touched).length === 0 ||
					isEditingMultipleChoicePoolElement
				}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				disabled={!formik.isValid}
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};

/**
 * @generated SignedSource<<6fa987c5e5674521d41117eaf55aa13b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RestartIfFailedContentConfigType = "RestartIfFailedContent_BlockPermanently_WhenRewarded" | "RestartIfFailedContent_BlockTemporarily_WhenRewarded" | "RestartIfFailedContent_BlockPermanently" | "RestartIfFailedContent_BlockTemporarily" | "RestartIfFailedContent_CanNot" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RestartIfFailedContentConfigEditor_TreeNodeFragment$data = {
  readonly id: string;
  readonly typeDefinition: {
    readonly restartIfFailedConfigs?: ReadonlyArray<{
      readonly id: string;
      readonly configType: RestartIfFailedContentConfigType;
      readonly daysToBlock?: number;
      readonly maximumTries?: number;
      readonly " $fragmentSpreads": FragmentRefs<"BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment" | "BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment" | "ProjectExtendRestartIfFailedContentConfigEditor_RestartIfFailedContentConfigFragment">;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"BlockTemporarilyRestartIfFailedContentConfigForm_TreeNodeFragment" | "BlockPermanentlyRestartIfFailedContentConfigForm_TreeNodeFragment" | "ProjectExtendRestartIfFailedContentConfigEditor_TreeNodeFragment">;
  readonly " $fragmentType": "RestartIfFailedContentConfigEditor_TreeNodeFragment";
};
export type RestartIfFailedContentConfigEditor_TreeNodeFragment = RestartIfFailedContentConfigEditor_TreeNodeFragment$data;
export type RestartIfFailedContentConfigEditor_TreeNodeFragment$key = {
  readonly " $data"?: RestartIfFailedContentConfigEditor_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RestartIfFailedContentConfigEditor_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RestartIfFailedContentConfigEditor_TreeNodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "restartIfFailedConfigs",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "configType",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "daysToBlock",
                      "storageKey": null
                    }
                  ],
                  "type": "BlockTemporarilyRestartIfFailedContentConfig",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "maximumTries",
                      "storageKey": null
                    }
                  ],
                  "type": "BlockPermanentlyRestartIfFailedContentConfig",
                  "abstractKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProjectExtendRestartIfFailedContentConfigEditor_RestartIfFailedContentConfigFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ContentTypeDefinition",
          "abstractKey": "__isContentTypeDefinition"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BlockTemporarilyRestartIfFailedContentConfigForm_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BlockPermanentlyRestartIfFailedContentConfigForm_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectExtendRestartIfFailedContentConfigEditor_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "2d02197dcb625a69240cad12db0d3373";

export default node;

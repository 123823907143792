/**
 * @generated SignedSource<<22af599ac09d92aa0eb7676fb7fbdd74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationTemplatesTable_NotificationTemplatesListFragment$data = {
  readonly Admin: {
    readonly Notification: {
      readonly PersonalizedNotificationTemplates: {
        readonly pageInfo: {
          readonly endCursor: string | null;
          readonly hasPreviousPage: boolean;
          readonly hasNextPage: boolean;
          readonly startCursor: string | null;
        };
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly template: {
              readonly key: string;
              readonly title: string;
            };
          };
        } | null> | null;
      };
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"NewNotificationTemplateButton_AvailableTemplatesFragment">;
  readonly " $fragmentType": "NotificationTemplatesTable_NotificationTemplatesListFragment";
};
export type NotificationTemplatesTable_NotificationTemplatesListFragment = NotificationTemplatesTable_NotificationTemplatesListFragment$data;
export type NotificationTemplatesTable_NotificationTemplatesListFragment$key = {
  readonly " $data"?: NotificationTemplatesTable_NotificationTemplatesListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationTemplatesTable_NotificationTemplatesListFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Admin",
  "Notification",
  "PersonalizedNotificationTemplates"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./NotificationTemplatesTable_Refetch.graphql')
    }
  },
  "name": "NotificationTemplatesTable_NotificationTemplatesListFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NotificationAdminSchema",
          "kind": "LinkedField",
          "name": "Notification",
          "plural": false,
          "selections": [
            {
              "alias": "PersonalizedNotificationTemplates",
              "args": null,
              "concreteType": "PersonalizedNotificationTemplatesConnection",
              "kind": "LinkedField",
              "name": "__NotificationTemplatesTable_PersonalizedNotificationTemplates_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasPreviousPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startCursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PersonalizedNotificationTemplatesEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PersonalizedNotificationTemplate",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "NotificationTemplate",
                          "kind": "LinkedField",
                          "name": "template",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "key",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "title",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewNotificationTemplateButton_AvailableTemplatesFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "6692d079b2472fef37900423c051d781";

export default node;

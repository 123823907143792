import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { TreeConfigurationEditDialogProps } from "../../components/Config.interfaces";
import { useFragment, useMutation } from "react-relay";
import { ValidatedField } from "../../../../../../core/form/ValidatedField";
import { BlockTemporarilyRestartIfFailedContentConfigForm_TreeNodeFragment$key } from "../../../../../../../../__generated__/BlockTemporarilyRestartIfFailedContentConfigForm_TreeNodeFragment.graphql";
import { BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment$key } from "../../../../../../../../__generated__/BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment.graphql";
import { BlockTemporarilyRestartIfFailedContentConfigForm_EditMutation } from "../../../../../../../../__generated__/BlockTemporarilyRestartIfFailedContentConfigForm_EditMutation.graphql";
import { InputNumber } from "primereact/inputnumber";
import { CONFIGS_TRANSLATIONS } from "../../../../../../../i18n/config/i18n";

const CONFIGURATION_FRAGMENT = graphql`
	fragment BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment on BlockTemporarilyRestartIfFailedContentConfig {
		id
		configType
		daysToBlock
	}
`;

const TREE_NODE_FRAGMENT = graphql`
	fragment BlockTemporarilyRestartIfFailedContentConfigForm_TreeNodeFragment on TreeNode {
		id
	}
`;

const EDIT_CONFIGURATION_MUTATION = graphql`
	mutation BlockTemporarilyRestartIfFailedContentConfigForm_EditMutation(
		$input: EditBlockTemporarilyRestartIfFailedContentConfigInput!
	) {
		Admin {
			Tree {
				editBlockTemporarilyRestartIfFailedContentConfig(input: $input) {
					contentNode {
						...RestartIfFailedContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface FormState {
	daysToBlock: number;
}

interface Props extends TreeConfigurationEditDialogProps {
	configurationFragmentRef: BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment$key;
	treeNodeFragmentRef: BlockTemporarilyRestartIfFailedContentConfigForm_TreeNodeFragment$key;
	onBack?: () => void;
}

export const BlockTemporarilyRestartIfFailedContentConfigForm = ({
	configurationFragmentRef,
	treeNodeFragmentRef,
	onBack,
}: Props) => {
	const treeNode =
		useFragment<BlockTemporarilyRestartIfFailedContentConfigForm_TreeNodeFragment$key>(
			TREE_NODE_FRAGMENT,
			treeNodeFragmentRef,
		);
	const configuration =
		useFragment<BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment$key>(
			CONFIGURATION_FRAGMENT,
			configurationFragmentRef,
		);
	const [edit, isEditing] =
		useMutation<BlockTemporarilyRestartIfFailedContentConfigForm_EditMutation>(
			EDIT_CONFIGURATION_MUTATION,
		);

	const formik = useFormik<FormState>({
		enableReinitialize: true,
		initialValues: {
			daysToBlock: configuration.daysToBlock,
		},
		onSubmit: (values, { setSubmitting }) => {
			edit({
				variables: {
					input: {
						contentNodeId: treeNode.id,
						configId: configuration.id,
						newDaysToBlock: values.daysToBlock,
					},
				},
			});
			setSubmitting(false);
			onBack!();
		},
	});

	return (
		<>
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, number>
					name={"daysToBlock"}
					label={CONFIGS_TRANSLATIONS[configuration.configType]}
					component={({ fieldName, updateField, fieldValue }) => (
						<InputNumber
							name={fieldName}
							value={fieldValue}
							onChange={(e) => updateField(e.value || undefined)}
						/>
					)}
					formikConfig={formik}
				/>

				<Button
					disabled={Object.entries(formik.touched).length === 0 || isEditing}
					type="submit"
					label="Speichern"
					className="mt-2"
				/>

				<Button
					type="button"
					onClick={() => {
						onBack!();
					}}
					label="Zurück"
					className="p-button-secondary mt-2"
				/>
			</form>
		</>
	);
};

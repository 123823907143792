import { Button } from "primereact/button";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { GenerateTestCertificateModal } from "./GenerateTestCertificateModal";

interface OwnProps {
	className?: string;
}

export const GenerateTestCertificateButton = ({ className }: OwnProps) => {
	const [modalVisible, setModalVisible] = useState(false);

	return (
		<>
			{modalVisible && (
				<Dialog
					style={{ minHeight: 1000 }}
					header={<h1>Testzertifikat erstellen</h1>}
					className="w-8 flex"
					visible={true}
					onHide={() => setModalVisible(false)}
				>
					<GenerateTestCertificateModal />
				</Dialog>
			)}
			<Button
				className={className}
				onClick={() => setModalVisible(true)}
				label={"Testzertifikat erstellen"}
			/>
		</>
	);
};

import { PrivateRouteDefinition } from "../../infecto-lms-admin/components/core/model/PrivateRouteDefinition";
import { FeedbacksScreenV2 } from "../../infecto-lms-admin/screens/FeedbacksScreenV2";
import { SingleFeedbackScreenV2 } from "../../infecto-lms-admin/screens/SingleFeedbackScreenV2";

export const FEEDBACKS_V2_PATH = "/feedbacks";

export const FEEDBACK_ID_URL_PARAM = ":feedbackId";
export const getSingleFeedbackV2Path = (feedbackId: string) => {
	return `/feedbacks/${feedbackId}/edit`;
};

export const FeedbackModuleRoutes: PrivateRouteDefinition[] = [
	{
		path: FEEDBACKS_V2_PATH,
		element: <FeedbacksScreenV2 />,
		requiredPermissions: ["AccountPermission_System_Root"],
	},
	{
		path: getSingleFeedbackV2Path(FEEDBACK_ID_URL_PARAM),
		element: <SingleFeedbackScreenV2 />,
		requiredPermissions: ["AccountPermission_System_Root"],
	},
];

/**
 * @generated SignedSource<<818b4197879d412862cdd15855d74027>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TreeStatesTable_Query$variables = {
  first?: number | null;
  filterByRootNodeId?: string | null;
  filterByUserId?: string | null;
};
export type TreeStatesTable_QueryVariables = TreeStatesTable_Query$variables;
export type TreeStatesTable_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TreeStatesTable_QueryFragment">;
};
export type TreeStatesTable_QueryResponse = TreeStatesTable_Query$data;
export type TreeStatesTable_Query = {
  variables: TreeStatesTable_QueryVariables;
  response: TreeStatesTable_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByRootNodeId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByUserId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = [
  {
    "kind": "Variable",
    "name": "filterByRootNodeId",
    "variableName": "filterByRootNodeId"
  },
  {
    "kind": "Variable",
    "name": "filterByUserId",
    "variableName": "filterByUserId"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TreeStatesTable_Query",
    "selections": [
      {
        "args": (v3/*: any*/),
        "kind": "FragmentSpread",
        "name": "TreeStatesTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "TreeStatesTable_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnV2AdminSchema",
            "kind": "LinkedField",
            "name": "LearnV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "TreeStatesConnection",
                "kind": "LinkedField",
                "name": "GetTreeStates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeStatesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TreeState",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TreeNode",
                            "kind": "LinkedField",
                            "name": "rootNode",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "structureDefinition",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v6/*: any*/)
                                    ],
                                    "type": "RootStructureDefinition",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "extension",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "firstName",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "lastName",
                                        "storageKey": null
                                      },
                                      (v6/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "salutation",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "InfectopharmUserExtensionImpl",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "filters": [
                  "filterByRootNodeId",
                  "filterByUserId"
                ],
                "handle": "connection",
                "key": "TreeStatesTable_GetTreeStates",
                "kind": "LinkedHandle",
                "name": "GetTreeStates"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0bb4822697db9b34fc4f47400d637920",
    "id": null,
    "metadata": {},
    "name": "TreeStatesTable_Query",
    "operationKind": "query",
    "text": "query TreeStatesTable_Query(\n  $first: Int\n  $filterByRootNodeId: ID\n  $filterByUserId: ID\n) {\n  ...TreeStatesTable_QueryFragment_4uQq6X\n}\n\nfragment ActionColumn_TreeStateFragment on TreeState {\n  id\n}\n\nfragment ProjectUserDescription_UserFragment on User {\n  ...UserDescription_UserFragment\n}\n\nfragment TitleColumn_TreeStateFragment on TreeState {\n  rootNode {\n    structureDefinition {\n      __typename\n      ... on RootStructureDefinition {\n        title\n      }\n    }\n    id\n  }\n}\n\nfragment TreeStatesTable_QueryFragment_4uQq6X on Query {\n  Admin {\n    LearnV2 {\n      GetTreeStates(first: $first, filterByRootNodeId: $filterByRootNodeId, filterByUserId: $filterByUserId) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            ...TreeStatesTable_TreeStateFragment\n            id\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n\nfragment TreeStatesTable_TreeStateFragment on TreeState {\n  ...ActionColumn_TreeStateFragment\n  ...TitleColumn_TreeStateFragment\n  ...UserColumn_TreeStateFragment\n}\n\nfragment UserColumn_TreeStateFragment on TreeState {\n  user {\n    ...ProjectUserDescription_UserFragment\n    id\n  }\n}\n\nfragment UserDescription_UserFragment on User {\n  extension {\n    __typename\n    ... on InfectopharmUserExtensionImpl {\n      firstName\n      lastName\n      title\n      salutation\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9320f0a2ee365fb88b43f4e05d884dc2";

export default node;

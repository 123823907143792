/**
 * @generated SignedSource<<55124c66a3c67fe002b722b890ec6f82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type InstructorDropdown_Query$variables = {};
export type InstructorDropdown_QueryVariables = InstructorDropdown_Query$variables;
export type InstructorDropdown_Query$data = {
  readonly Admin: {
    readonly Instructor: {
      readonly InstructorImpls: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly superId: string;
            readonly name: string;
          };
        } | null> | null;
      };
    };
  };
};
export type InstructorDropdown_QueryResponse = InstructorDropdown_Query$data;
export type InstructorDropdown_Query = {
  variables: InstructorDropdown_QueryVariables;
  response: InstructorDropdown_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminQueryType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InstructorAdminSchema",
        "kind": "LinkedField",
        "name": "Instructor",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 100
              }
            ],
            "concreteType": "InstructorImplConnection",
            "kind": "LinkedField",
            "name": "InstructorImpls",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InstructorImplEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InstructorImpl",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "superId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "InstructorImpls(first:100)"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InstructorDropdown_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "InstructorDropdown_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "d8c91bde1629bd1eb1a8be2531e38ee9",
    "id": null,
    "metadata": {},
    "name": "InstructorDropdown_Query",
    "operationKind": "query",
    "text": "query InstructorDropdown_Query {\n  Admin {\n    Instructor {\n      InstructorImpls(first: 100) {\n        edges {\n          node {\n            id\n            superId\n            name\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "24417f648a78a5744f5b996e38f650f6";

export default node;

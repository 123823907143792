import { graphql } from "babel-plugin-relay/macro";
import { Card } from "primereact/card";
import { useFragment } from "react-relay";
import { ELearningContentEditor } from "./elearning/ELearningContentEditor";
import { ContentCard_TreeNodeFragment$key } from "../../../../../../__generated__/ContentCard_TreeNodeFragment.graphql";
import { BaseContentEditor } from "./BaseContentEditor";

const TREE_NODE_FRAGMENT = graphql`
	fragment ContentCard_TreeNodeFragment on TreeNode {
		typeDefinition {
			definitionType
		}
		...ELearningContentEditor_TreeNodeFragment
	}
`;

interface OwnProps {
	treeNodeFragmentRef: ContentCard_TreeNodeFragment$key;
}

export const ContentCard = ({ treeNodeFragmentRef }: OwnProps) => {
	const node = useFragment<ContentCard_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	return (
		<Card className="mb-2">
			{(() => {
				switch (node.typeDefinition.definitionType) {
					case "branch":
						return (
							<BaseContentEditor>
								<p>Ein Verzweig-Ordner kann keine Elemente haben.</p>
							</BaseContentEditor>
						);
					case "content":
						return <ELearningContentEditor treeNodeFragmentRef={node} />;
					default:
						return null;
				}
			})()}
		</Card>
	);
};

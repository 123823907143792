import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import graphql from "babel-plugin-relay/macro";
import { readInlineData, useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { useContext } from "react";
import { ReceivedAtColumn } from "./ReceivedAtColumn";
import { PointsPoolColumn } from "./PointsPoolColumn";
import { UserColumn } from "./UserColumn";
import { AmountColumn } from "./AmountColumn";
import { ContentNodeTitleColumn } from "./ContentNodeTitleColumn";
import { PointsAdditionsTable_Query } from "../../../../__generated__/PointsAdditionsTable_Query.graphql";
import {
	PointsAdditionSearchContext,
	PointsAdditionsFilterState,
} from "../additions/PointsAdditionSearchContainer";
import { PointsAdditionsTable_QueryFragment$key } from "../../../../__generated__/PointsAdditionsTable_QueryFragment.graphql";
import { useDebouncesFilters } from "../../core/filters/useDebouncesFilters";
import { PointsAdditionsTable_Refetch } from "../../../../__generated__/PointsAdditionsTable_Refetch.graphql";

const QUERY = graphql`
	query PointsAdditionsTable_Query($first: Int, $filterByPointsPoolId: ID, $filterByUserId: ID) {
		...PointsAdditionsTable_QueryFragment
			@arguments(
				first: $first
				filterByPointsPoolId: $filterByPointsPoolId
				filterByUserId: $filterByUserId
			)
	}
`;

const QUERY_FRAGMENT = graphql`
	fragment PointsAdditionsTable_QueryFragment on Query
	@refetchable(queryName: "PointsAdditionsTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		filterByPointsPoolId: { type: "ID" }
		filterByUserId: { type: "ID" }
	) {
		Admin {
			PointsV2 {
				GetFilteredPointsAdditions(
					first: $first
					after: $after
					filterByPointsPoolId: $filterByPointsPoolId
					filterByUserId: $filterByUserId
				) @connection(key: "PointsAdditionsTable_GetFilteredPointsAdditions") {
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							...PointsAdditionsTable_PointsAdditionFragment
						}
					}
				}
			}
		}
	}
`;

const POINTS_ADDITION_FRAGMENT = graphql`
	fragment PointsAdditionsTable_PointsAdditionFragment on PointsAdditionV2 @inline {
		...ContentNodeTitleColumn_PointsAdditionFragment
		...PointsPoolColumn_PointsAdditionFragment
		...ReceivedAtColumn_PointsAdditionFragment
		...UserColumn_PointsAdditionFragment
	}
`;

export const PointsAdditionsTable = () => {
	const query = useLazyLoadQuery<PointsAdditionsTable_Query>(QUERY, { first: 20 });
	const { filters } = useContext(PointsAdditionSearchContext);

	const { data, hasPrevious, hasNext, refetch, loadPrevious, loadNext } = usePaginationFragment<
		PointsAdditionsTable_Refetch,
		PointsAdditionsTable_QueryFragment$key
	>(QUERY_FRAGMENT, query);

	const pointsAdditionsConnection = data.Admin.PointsV2.GetFilteredPointsAdditions || [];

	useDebouncesFilters<PointsAdditionsFilterState>(filters, refetch);

	return (
		<>
			<DataTable
				className="mb-3"
				value={
					pointsAdditionsConnection.edges
						?.filter((e) => !!e?.node)
						.map((e) => e!.node)
						.map((item: any) => readInlineData(POINTS_ADDITION_FRAGMENT, item)) || []
				}
			>
				<Column
					header="Erhalten"
					body={(item) => <ReceivedAtColumn pointsAdditionFragmentRef={item} />}
				/>
				<Column
					header="Punkte-Pool"
					body={(item) => <PointsPoolColumn pointsAdditionFragmentRef={item} />}
				/>
				<Column
					header="Benutzer"
					body={(item) => <UserColumn pointsAdditionFragmentRef={item} />}
				/>
				<Column
					header="Menge"
					body={(item) => <AmountColumn pointsAdditionFragmentRef={item} />}
				/>
				<Column
					header="Für"
					body={(item) => <ContentNodeTitleColumn pointsAdditionFragmentRef={item} />}
				/>
			</DataTable>

			<div className="flex justify-content-center align-items-center">
				<Button
					disabled={!hasPrevious}
					onClick={() => loadPrevious(20)}
					className="mr-3 p-button-secondary"
				>
					Zurück
				</Button>
				<Button
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={() => loadNext(20)}
				>
					Weiter
				</Button>
			</div>
		</>
	);
};

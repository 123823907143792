/**
 * @generated SignedSource<<820c78703b16d759474d29a14dc08a55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TypeDefinitionType = "content" | "branch" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Node_TreeNodeFragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly title: string;
  };
  readonly typeDefinition: {
    readonly definitionType: TypeDefinitionType;
    readonly childRefs?: ReadonlyArray<string>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"NodeConfigurationBadges_TreeNodeFragment" | "ProjectExtendNodeConfigurationBadges_TreeNodeFragment">;
  readonly " $fragmentType": "Node_TreeNodeFragment";
};
export type Node_TreeNodeFragment = Node_TreeNodeFragment$data;
export type Node_TreeNodeFragment$key = {
  readonly " $data"?: Node_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"Node_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Node_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "definitionType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "childRefs",
              "storageKey": null
            }
          ],
          "type": "BranchTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeConfigurationBadges_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectExtendNodeConfigurationBadges_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "ab894416990a9310cd69abed9b3c80a8";

export default node;

import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";
import { VimeoPodcastElementForm } from "./VimeoPodcastElementForm";
import { PodigeePodcastElementForm } from "./PodigeePodcastElementForm";
import {
	PodcastElementForm_PodcastElementV2Fragment$key,
	PodcastSourceTypeV2,
} from "../../../../../../../../__generated__/PodcastElementForm_PodcastElementV2Fragment.graphql";
import { useTypedDispatch } from "../../../../../../../redux/Store";
import { addEditedFormToEditedFormsArray } from "../../../../../../../redux/slices/CoreSlice";

const PODCAST_ELEMENT_FRAGMENT = graphql`
	fragment PodcastElementForm_PodcastElementV2Fragment on PodcastElementV2 {
		id
		podcastSource {
			sourceType
		}
		...VimeoPodcastElementForm_PodcastElementV2Fragment
		...PodigeePodcastElementForm_PodcastElementV2Fragment
	}
`;

interface OwnProps {
	podcastElementFragmentRef: PodcastElementForm_PodcastElementV2Fragment$key;
	onBack: () => void;
}

export const PodcastElementForm = ({ podcastElementFragmentRef, onBack }: OwnProps) => {
	const podcastElement = useFragment<PodcastElementForm_PodcastElementV2Fragment$key>(
		PODCAST_ELEMENT_FRAGMENT,
		podcastElementFragmentRef,
	);
	const formId = "PodcastElementForm";
	const dispatch = useTypedDispatch();
	const [selectedPodcastSourceType, setSelectedPodcastSourceType] = useState<PodcastSourceTypeV2>(
		podcastElement.podcastSource.sourceType,
	);

	return (
		<div className={`field`}>
			<label htmlFor={"podcast-source"}>Quelle</label>
			<div>
				<Dropdown
					id={"podcast-source"}
					options={[
						{ value: "vimeo", label: "Vimeo" },
						{ value: "podigee", label: "Podigee" },
						{ value: "none", label: "Keine Quelle" },
					]}
					onChange={(e) => {
						setSelectedPodcastSourceType(e.value);
						dispatch(addEditedFormToEditedFormsArray({ form: formId }));
					}}
					value={selectedPodcastSourceType}
				/>
			</div>

			{selectedPodcastSourceType === "vimeo" && (
				<VimeoPodcastElementForm
					podcastElementFragmentRef={podcastElement}
					onBack={onBack}
					formId={formId}
				/>
			)}
			{selectedPodcastSourceType === "podigee" && (
				<PodigeePodcastElementForm
					podcastElementFragmentRef={podcastElement}
					onBack={onBack}
					formId={formId}
				/>
			)}
		</div>
	);
};

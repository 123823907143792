import { useFormik } from "formik";
import { Button } from "primereact/button";
import React from "react";
import * as Yup from "yup";
import { ColorPicker } from "primereact/colorpicker";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { DefaultTextFieldComponent } from "../core/form/DefaultTextInput";
import { ValidatedField } from "../core/form/ValidatedField";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
	selectArrayOfEditedForms,
} from "../../redux/slices/CoreSlice";
import { useTypedDispatch, useTypedSelector } from "../../redux/Store";
import { WarningUnsavedChangesDialog } from "../core/dialog/WarningUnsavedChangesDialog";
import { useCallbackPrompt } from "../../functions/hooks/UseCallBackPrompt";

interface FormState {
	name?: string;
	colorHex?: string;
}

interface OwnProps {
	loading?: boolean;
	initialValues?: FormState;
	onSubmit: (values: FormState) => void;
}

export const EditTagForm = ({ loading, initialValues, onSubmit }: OwnProps) => {
	const dispatch = useTypedDispatch();
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);
	const [showPrompt, confirmNavigation, cancelNavigation, setShowPrompt] = useCallbackPrompt(
		arrayOfEditedForms.length > 0,
	);

	const formId = "EditTagForm";
	const formik = useFormik<FormState>({
		initialValues: {
			name: initialValues?.name ?? "Neues Schlagwort",
			colorHex: initialValues?.colorHex ?? "#000000",
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Name wird benötigt"),
			colorHex: Yup.string().required("Farbe wird benötigt"),
		}),
		onSubmit: (values, { setSubmitting }) => {
			onSubmit({
				...values,
				colorHex: values.colorHex?.startsWith("#")
					? values.colorHex
					: "#" + values.colorHex,
			});
			setSubmitting(false);
			dispatch(resetArrayOfEditedForms());
		},
	});

	return (
		<>
			{showPrompt && (
				<WarningUnsavedChangesDialog
					confirmNavigation={confirmNavigation}
					setShowDialog={setShowPrompt}
					cancelNavigation={cancelNavigation}
				/>
			)}
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, string>
					name={"name"}
					label={"Name"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>

				<ValidatedField<FormState, string>
					name={"colorHex"}
					label={"Farbe"}
					component={({ fieldName, fieldValue, updateField, isValid, disabled }) => (
						<div className="flex align-items-center">
							<InputText
								name={fieldName + "-string"}
								disabled={disabled}
								className={classNames({ "p-invalid": !isValid }) + " mr-2"}
								value={fieldValue}
								onChange={(event) => {
									updateField(event.target.value);
									dispatch(addEditedFormToEditedFormsArray({ form: formId }));
								}}
							/>
							<ColorPicker
								name={fieldName + "-picker"}
								value={fieldValue}
								disabled={disabled}
								onChange={(e) => {
									updateField(e.value as string);
									dispatch(addEditedFormToEditedFormsArray({ form: formId }));
								}}
								className={classNames({ "p-invalid": !isValid })}
							/>
						</div>
					)}
					formikConfig={formik}
				/>

				<Button disabled={loading} type="submit" label="Speichern" className="p-mt-2" />
			</form>
		</>
	);
};

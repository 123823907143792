/**
 * @generated SignedSource<<f2a91a590532405c3c3aa35611369a3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdatePersonalizedNotificationTemplateInput = {
  data: PersonalizedNotificationTemplateInput;
  id: string;
  clientMutationId?: string | null;
};
export type PersonalizedNotificationTemplateInput = {
  key: string;
  title: string;
  message: string;
  variables: ReadonlyArray<string>;
};
export type EditNotificationTemplateScreen_UpdatePersonalizedNotificationTemplateMutation$variables = {
  input: UpdatePersonalizedNotificationTemplateInput;
};
export type EditNotificationTemplateScreen_UpdatePersonalizedNotificationTemplateMutationVariables = EditNotificationTemplateScreen_UpdatePersonalizedNotificationTemplateMutation$variables;
export type EditNotificationTemplateScreen_UpdatePersonalizedNotificationTemplateMutation$data = {
  readonly Admin: {
    readonly Notification: {
      readonly updatePersonalizedNotificationTemplate: {
        readonly data: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"EditNotificationTemplateScreen_NotificationTemplateFragment">;
          };
        };
      } | null;
    };
  };
};
export type EditNotificationTemplateScreen_UpdatePersonalizedNotificationTemplateMutationResponse = EditNotificationTemplateScreen_UpdatePersonalizedNotificationTemplateMutation$data;
export type EditNotificationTemplateScreen_UpdatePersonalizedNotificationTemplateMutation = {
  variables: EditNotificationTemplateScreen_UpdatePersonalizedNotificationTemplateMutationVariables;
  response: EditNotificationTemplateScreen_UpdatePersonalizedNotificationTemplateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditNotificationTemplateScreen_UpdatePersonalizedNotificationTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Notification",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdatePersonalizedNotificationTemplatePayload",
                "kind": "LinkedField",
                "name": "updatePersonalizedNotificationTemplate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonalizedNotificationTemplatesEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PersonalizedNotificationTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditNotificationTemplateScreen_NotificationTemplateFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditNotificationTemplateScreen_UpdatePersonalizedNotificationTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Notification",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdatePersonalizedNotificationTemplatePayload",
                "kind": "LinkedField",
                "name": "updatePersonalizedNotificationTemplate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonalizedNotificationTemplatesEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PersonalizedNotificationTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "NotificationTemplate",
                            "kind": "LinkedField",
                            "name": "template",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "key",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "message",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "variables",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "16f877d8cca4012a99a2b29e3932e30a",
    "id": null,
    "metadata": {},
    "name": "EditNotificationTemplateScreen_UpdatePersonalizedNotificationTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation EditNotificationTemplateScreen_UpdatePersonalizedNotificationTemplateMutation(\n  $input: UpdatePersonalizedNotificationTemplateInput!\n) {\n  Admin {\n    Notification {\n      updatePersonalizedNotificationTemplate(input: $input) {\n        data {\n          node {\n            ...EditNotificationTemplateScreen_NotificationTemplateFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditNotificationTemplateScreen_NotificationTemplateFragment on PersonalizedNotificationTemplate {\n  id\n  template {\n    key\n    title\n    message\n    variables\n  }\n}\n"
  }
};
})();

(node as any).hash = "413876d201722bdf3d0378e47415ae01";

export default node;

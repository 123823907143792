import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useTypedDispatch } from "../../../redux/Store";
import { resetArrayOfEditedForms } from "../../../redux/slices/CoreSlice";
import styled from "styled-components";

interface OwnProps {
	showDialog?: boolean;
	setShowDialog?: any;
	callback?: any;
	value?: any;
	confirmNavigation?: any;
	cancelNavigation?: any;
}

export const WarningUnsavedChangesDialog = ({
	callback,
	value,
	confirmNavigation,
	cancelNavigation,
	setShowDialog,
}: OwnProps) => {
	const isShowDialogFalseDefined = () => {
		if (setShowDialog) {
			setShowDialog(false);
		}
	};

	const defineNavigation = () => {
		if (confirmNavigation) {
			confirmNavigation();
		}
	};
	const blockNavigation = () => {
		if (cancelNavigation) {
			cancelNavigation();
		}
	};
	const isCallbackDefined = () => {
		if (callback) {
			callback(value);
		}
	};

	const dispatch = useTypedDispatch();
	return (
		<div>
			<DialogContainer
				header="Es gibt einige Änderungen"
				className="flex"
				position="center"
				contentClassName="flex"
				visible={true}
				onHide={() => {
					isShowDialogFalseDefined();
					blockNavigation();
				}}
			>
				<div className="mb-2">Wenn Sie fortfahren, gehen Ihre Änderungen verloren.</div>
				<div className="mb-4">Sind Sie sicher, dass Sie fortfahren wollen?</div>
				<ButtonsWrapper>
					<LeftButton
						onClick={() => {
							isShowDialogFalseDefined();
							blockNavigation();
						}}
					>
						Abbrechen
					</LeftButton>
					<Button
						onClick={() => {
							dispatch(resetArrayOfEditedForms());
							isCallbackDefined();
							isShowDialogFalseDefined();
							defineNavigation();
						}}
					>
						Ok
					</Button>
				</ButtonsWrapper>
			</DialogContainer>
		</div>
	);
};

const ButtonsWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;
const LeftButton = styled(Button)`
	margin-right: 10px;
`;
const DialogContainer = styled(Dialog)`
	width: 30%;
	.p-dialog-content {
		justify-content: space-between;
		display: flex;
		flex-direction: column;
	}
`;

/**
 * @generated SignedSource<<cce38a329a402c69d2d70c88ff95b944>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TextElementForm_TextElementV2Fragment$data = {
  readonly id: string;
  readonly title: string;
  readonly text: string;
  readonly " $fragmentType": "TextElementForm_TextElementV2Fragment";
};
export type TextElementForm_TextElementV2Fragment = TextElementForm_TextElementV2Fragment$data;
export type TextElementForm_TextElementV2Fragment$key = {
  readonly " $data"?: TextElementForm_TextElementV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TextElementForm_TextElementV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TextElementForm_TextElementV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    }
  ],
  "type": "TextElementV2",
  "abstractKey": null
};

(node as any).hash = "20d807e89758bbaa9313525a943bdf78";

export default node;

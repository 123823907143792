/**
 * @generated SignedSource<<97c34458f5c128737f2eb185e9643268>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateTagInput = {
  data: TagInput;
  id: string;
  clientMutationId?: string | null;
};
export type TagInput = {
  name: string;
  colorHex: string;
  clientMutationId?: string | null;
};
export type EditTagScreen_UpdateTagMutation$variables = {
  input: UpdateTagInput;
};
export type EditTagScreen_UpdateTagMutationVariables = EditTagScreen_UpdateTagMutation$variables;
export type EditTagScreen_UpdateTagMutation$data = {
  readonly Admin: {
    readonly Tag: {
      readonly updateTag: {
        readonly data: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"EditTagScreen_TagFragment">;
          };
        };
      } | null;
    };
  };
};
export type EditTagScreen_UpdateTagMutationResponse = EditTagScreen_UpdateTagMutation$data;
export type EditTagScreen_UpdateTagMutation = {
  variables: EditTagScreen_UpdateTagMutationVariables;
  response: EditTagScreen_UpdateTagMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditTagScreen_UpdateTagMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TagAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tag",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateTagPayload",
                "kind": "LinkedField",
                "name": "updateTag",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TagsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Tag",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditTagScreen_TagFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditTagScreen_UpdateTagMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TagAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tag",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateTagPayload",
                "kind": "LinkedField",
                "name": "updateTag",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TagsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Tag",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "colorHex",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5bbb29a6ff32f1036dcc52135d46d9eb",
    "id": null,
    "metadata": {},
    "name": "EditTagScreen_UpdateTagMutation",
    "operationKind": "mutation",
    "text": "mutation EditTagScreen_UpdateTagMutation(\n  $input: UpdateTagInput!\n) {\n  Admin {\n    Tag {\n      updateTag(input: $input) {\n        data {\n          node {\n            ...EditTagScreen_TagFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditTagScreen_TagFragment on Tag {\n  id\n  name\n  colorHex\n}\n"
  }
};
})();

(node as any).hash = "c8148dd2a10ba617cda375c6b324da69";

export default node;

import React, { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { debounce } from "lodash";

interface State {
	value: string;
}

interface OwnProps {
	refetch: (value: string) => void;
}

export const MessageDefinitionFilters = ({ refetch }: OwnProps) => {
	const [state, setState] = useState<State>({
		value: "",
	});

	const delayedRefetch = debounce((input: string) => refetch(input), 500);

	return (
		<div className="flex pb-4">
			<span className="p-input-icon-left flex-grow-1">
				<i className={"pi pi-filter"} />
				<InputText
					value={state.value}
					placeholder={"Nach Titel filtern"}
					className="w-full"
					onChange={(e) => {
						setState((state) => ({ ...state, value: e.target.value }));
						delayedRefetch(e.target.value);
					}}
				/>
			</span>
			<Button
				className="ml-3"
				type="button"
				label={"Zurücksetzen"}
				icon="pi pi-filter-slash"
				iconPos="right"
				onClick={() => {
					setState((state) => ({ ...state, value: "" }));
					refetch("");
				}}
			/>
		</div>
	);
};

import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { Dropdown } from "primereact/dropdown";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../../../lms-admin-impl/i18n/tree.i18n";
import { TypeDefinitionType } from "../../../../../__generated__/NodePart_Query.graphql";
import { AddNodeButton_CreateBranchChildNodeMutation } from "../../../../../__generated__/AddNodeButton_CreateBranchChildNodeMutation.graphql";
import { AddNodeButton_CreateContentChildNodeMutation } from "../../../../../__generated__/AddNodeButton_CreateContentChildNodeMutation.graphql";

const CREATE_BRANCH_CHILD_NODE_MUTATION = graphql`
	mutation AddNodeButton_CreateBranchChildNodeMutation($input: CreateBranchChildInput!) {
		Admin {
			Tree {
				createBranchChild(input: $input) {
					createdChildNode {
						id
					}
					tree {
						...TreeEditorScreen_TreeFragment
					}
				}
			}
		}
	}
`;

const CREATE_CONTENT_CHILD_NODE_MUTATION = graphql`
	mutation AddNodeButton_CreateContentChildNodeMutation($input: CreateContentChildInput!) {
		Admin {
			Tree {
				createContentChild(input: $input) {
					createdChildNode {
						id
					}
					tree {
						...TreeEditorScreen_TreeFragment
					}
				}
			}
		}
	}
`;

const options: { typeDefinitionType: TypeDefinitionType; name: string }[] = [
	{
		typeDefinitionType: "branch",
		name: `${TREE_I18N_MAP(TREE_I18N_KEY.branchNode)} erstellen`,
	},
	{
		typeDefinitionType: "content",
		name: `${TREE_I18N_MAP(TREE_I18N_KEY.eLearningContentNode)} erstellen`,
	},
];

interface OwnProps {
	parentBranchNodeId?: string;
	onSuccessful: (newId?: string) => void;
}

export const AddNodeButton = ({ parentBranchNodeId, onSuccessful }: OwnProps) => {
	const [createBranchChildNode, isCreatingBranchChildNode] =
		useMutation<AddNodeButton_CreateBranchChildNodeMutation>(CREATE_BRANCH_CHILD_NODE_MUTATION);

	const [createContentChildNode, isCreatingContentChildNode] =
		useMutation<AddNodeButton_CreateContentChildNodeMutation>(
			CREATE_CONTENT_CHILD_NODE_MUTATION,
		);

	const title = (typeDefinitionType?: TypeDefinitionType | null) => {
		switch (typeDefinitionType) {
			case "branch":
				return TREE_I18N_MAP(TREE_I18N_KEY.newBranchNode);
			case "content":
				return TREE_I18N_MAP(TREE_I18N_KEY.newELearningContentNode);
			default:
				return "Neu";
		}
	};

	return (
		<Dropdown
			options={options}
			className="static w-full"
			onChange={(e) => {
				if (e.value.typeDefinitionType == "branch") {
					createBranchChildNode({
						variables: {
							input: {
								parentBranchId: parentBranchNodeId as string,
								title: title(e.value.typeDefinitionType),
							},
						},
						onCompleted: (response) =>
							onSuccessful(
								response.Admin.Tree.createBranchChild?.createdChildNode.id,
							),
					});
				} else {
					createContentChildNode({
						variables: {
							input: {
								parentBranchId: parentBranchNodeId as string,
								title: title(e.value.typeDefinitionType),
								contentKind: "ELearning",
							},
						},
						onCompleted: (response) =>
							onSuccessful(
								response.Admin.Tree.createContentChild?.createdChildNode.id,
							),
					});
				}
			}}
			placeholder={TREE_I18N_MAP(TREE_I18N_KEY.newNode)}
			optionLabel={"name"}
			disabled={
				parentBranchNodeId === undefined ||
				isCreatingBranchChildNode ||
				isCreatingContentChildNode
			}
		/>
	);
};

import { RenderConfig } from "./ValidatedField";
import { Calendar } from "primereact/calendar";
import moment from "moment-timezone";
import React from "react";
import { toJSDate } from "../datetime/DateTimeDisplay";

export function DefaultCalendarComponent({
	fieldName,
	fieldValue,
	updateField,
	disabled,
	onChange,
}: RenderConfig<string>) {
	return (
		<Calendar
			name={fieldName}
			showTime={false}
			hourFormat="24"
			dateFormat={"dd.mm.yy"}
			disabled={disabled}
			value={fieldValue ? toJSDate(fieldValue) : undefined}
			onChange={(e) => {
				updateField(e.value ? moment(e.value as Date).format("YYYY-MM-DD") : undefined);

				if (onChange) {
					onChange();
				}
			}}
		/>
	);
}

/**
 * @generated SignedSource<<c2ec863183881d8c16c55c2f7b1cb442>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditNewstickerMessageInput = {
  text: string;
  forwardingUrl?: string | null;
  isActive: boolean;
  id: string;
  clientMutationId?: string | null;
};
export type EditNewsTickerDefinitionForm_EditMutation$variables = {
  input: EditNewstickerMessageInput;
};
export type EditNewsTickerDefinitionForm_EditMutationVariables = EditNewsTickerDefinitionForm_EditMutation$variables;
export type EditNewsTickerDefinitionForm_EditMutation$data = {
  readonly Admin: {
    readonly Newsticker: {
      readonly editNewstickerMessage: {
        readonly newstickerMessage: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"EditNewsTickerDefinitionForm_MessageDefinitionFragment">;
        };
      } | null;
    };
  };
};
export type EditNewsTickerDefinitionForm_EditMutationResponse = EditNewsTickerDefinitionForm_EditMutation$data;
export type EditNewsTickerDefinitionForm_EditMutation = {
  variables: EditNewsTickerDefinitionForm_EditMutationVariables;
  response: EditNewsTickerDefinitionForm_EditMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditNewsTickerDefinitionForm_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NewstickerAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Newsticker",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditNewstickerMessagePayload",
                "kind": "LinkedField",
                "name": "editNewstickerMessage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NewstickerMessage",
                    "kind": "LinkedField",
                    "name": "newstickerMessage",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "EditNewsTickerDefinitionForm_MessageDefinitionFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditNewsTickerDefinitionForm_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NewstickerAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Newsticker",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditNewstickerMessagePayload",
                "kind": "LinkedField",
                "name": "editNewstickerMessage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NewstickerMessage",
                    "kind": "LinkedField",
                    "name": "newstickerMessage",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isActive",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "forwardingUrl",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4f34a2a01c00c1fcf6bc9a36a255995e",
    "id": null,
    "metadata": {},
    "name": "EditNewsTickerDefinitionForm_EditMutation",
    "operationKind": "mutation",
    "text": "mutation EditNewsTickerDefinitionForm_EditMutation(\n  $input: EditNewstickerMessageInput!\n) {\n  Admin {\n    Newsticker {\n      editNewstickerMessage(input: $input) {\n        newstickerMessage {\n          id\n          ...EditNewsTickerDefinitionForm_MessageDefinitionFragment\n        }\n      }\n    }\n  }\n}\n\nfragment EditNewsTickerDefinitionForm_MessageDefinitionFragment on NewstickerMessage {\n  id\n  text\n  isActive\n  forwardingUrl\n}\n"
  }
};
})();

(node as any).hash = "63f0b5a6886e99f0cc84f6cb2bc9a981";

export default node;

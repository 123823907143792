import React from "react";
import * as Yup from "yup";
import { TreeNode } from "primereact/treenode";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation, Environment, useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { TreeSelect } from "primereact/treeselect";
import { OnlySpecializationVisibilityConfigForm_TreeNodeFragment$key } from "../../../../../../../../__generated__/OnlySpecializationVisibilityConfigForm_TreeNodeFragment.graphql";
import { OnlySpecializationVisibilityConfigForm_EditMutation } from "../../../../../../../../__generated__/OnlySpecializationVisibilityConfigForm_EditMutation.graphql";
import { ValidatedField } from "../../../../../../../../infecto-lms-admin/components/core/form/ValidatedField";
import { Fachrichtung, FACHRICHTUNGEN } from "./Fachrichtungen";
import { TreeConfigurationEditDialogProps } from "../../../../../../../../infecto-lms-admin/components/tree/editor/node/config/components/Config.interfaces";
import {
	AddOnlyFachrichtungenVisibilityTreeConfigInput,
	OnlySpecializationVisibilityConfigForm_AddOnlyFachrichtungenVisibilityConfigMutation,
} from "../../../../../../../../__generated__/OnlySpecializationVisibilityConfigForm_AddOnlyFachrichtungenVisibilityConfigMutation.graphql";
import { OnlySpecializationVisibilityConfigForm_VisibilityTreeConfigFragment$key } from "../../../../../../../../__generated__/OnlySpecializationVisibilityConfigForm_VisibilityTreeConfigFragment.graphql";
import { CONFIGS_TRANSLATIONS } from "../../../../../../../../infecto-lms-admin/i18n/config/i18n";

const CONFIGURATION_FRAGMENT = graphql`
	fragment OnlySpecializationVisibilityConfigForm_VisibilityTreeConfigFragment on VisibilityTreeConfig {
		... on OnlyFachrichtungenVisibilityTreeConfig {
			id
			configType
			fachrichtungen
		}
	}
`;

const TREE_NODE_FRAGMENT = graphql`
	fragment OnlySpecializationVisibilityConfigForm_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			... on RootStructureDefinition {
				visibilityConfigs {
					...OnlySpecializationVisibilityConfigForm_VisibilityTreeConfigFragment
				}
			}
		}
	}
`;

const ADD_ONLY_FACHRICHTUNGEN_VISIBILITY_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation OnlySpecializationVisibilityConfigForm_AddOnlyFachrichtungenVisibilityConfigMutation(
		$input: AddOnlyFachrichtungenVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				addOnlyFachrichtungenVisibilityTreeConfig(input: $input) {
					rootNode {
						...OnlySpecializationVisibilityConfigForm_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const EDIT_CONFIGURATION_MUTATION = graphql`
	mutation OnlySpecializationVisibilityConfigForm_EditMutation(
		$input: EditOnlyFachrichtungenVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				editOnlyFachrichtungenVisibilityTreeConfig(input: $input) {
					rootNode {
						...OnlySpecializationVisibilityConfigForm_TreeNodeFragment
					}
				}
			}
		}
	}
`;

function CommitAddOnlyFachrichtungVisibilityConfig(
	environment: Environment,
	input: AddOnlyFachrichtungenVisibilityTreeConfigInput,
) {
	return commitMutation<OnlySpecializationVisibilityConfigForm_AddOnlyFachrichtungenVisibilityConfigMutation>(
		environment,
		{
			mutation: ADD_ONLY_FACHRICHTUNGEN_VISIBILITY_CONDITION_CONFIGURATION_MUTATION,
			variables: { input },
			onCompleted: () => {},
			onError: () => {},
		},
	);
}

interface FormState {
	fachrichtungen?: string[];
}

interface Props extends TreeConfigurationEditDialogProps {
	treeNodeFragmentRef: OnlySpecializationVisibilityConfigForm_TreeNodeFragment$key;
	configurationFragmentRef: OnlySpecializationVisibilityConfigForm_VisibilityTreeConfigFragment$key;
	onBack?: () => void;
}

export const OnlySpecializationVisibilityConfigForm = ({
	configurationFragmentRef,
	treeNodeFragmentRef,
	onBack,
}: Props) => {
	const treeNode = useFragment<OnlySpecializationVisibilityConfigForm_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const configuration =
		useFragment<OnlySpecializationVisibilityConfigForm_VisibilityTreeConfigFragment$key>(
			CONFIGURATION_FRAGMENT,
			configurationFragmentRef,
		);
	const [edit, isEditing] = useMutation<OnlySpecializationVisibilityConfigForm_EditMutation>(
		EDIT_CONFIGURATION_MUTATION,
	);

	const formik = useFormik<FormState>({
		initialValues: {
			fachrichtungen: [...(configuration.fachrichtungen as string[])],
		},
		validationSchema: Yup.object().shape({
			fachrichtungen: Yup.array().required("Das Feld Fachrichtungen wird benötigt."),
		}),
		onSubmit: (values, formikHelpers) => {
			edit({
				variables: {
					input: {
						rootNodeId: treeNode.id,
						configId: configuration.id as string,
						newFachrichtungen: values.fachrichtungen!,
					},
				},
				onCompleted: () => {
					formikHelpers.setTouched({});
					onBack!();
				},
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, string[]>
				name={"fachrichtungen"}
				label={CONFIGS_TRANSLATIONS[configuration.configType!]}
				component={({ fieldName, updateField, fieldValue }) => {
					return (
						<TreeSelect
							name={fieldName}
							value={
								fieldValue
									? fieldValue
											.map((f) => ({ [f]: true }))
											.reduce((a, b) => ({ ...a, ...b }), {})
									: {}
							}
							onChange={(e) => {
								updateField(e.value ? Object.keys(e.value) : []);
							}}
							selectionMode="multiple"
							options={FACHRICHTUNGEN.filter((f) => f.parent_target_id === 0).map(
								fachrichtungToTreeNode,
							)}
						/>
					);
				}}
				formikConfig={formik}
			/>

			<Button
				disabled={Object.entries(formik.touched).length === 0 || isEditing}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					// formik.resetForm();
					onBack!();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};

const fachrichtungToTreeNode = (fachrichtung: Fachrichtung): TreeNode => {
	return {
		key: fachrichtung.name,
		label: fachrichtung.name,
		children: FACHRICHTUNGEN.filter((child) => child.parent_target_id === fachrichtung.tid).map(
			fachrichtungToTreeNode,
		),
	};
};

export { CommitAddOnlyFachrichtungVisibilityConfig as AddOnlySpecializationVisibilityConfigMutation };

import { graphql } from "babel-plugin-relay/macro";
import { Card } from "primereact/card";
import React from "react";
import {
	ShowAnswerTreeConfigEditor_TreeNodeFragment$key,
	ShowAnswerTreeConfigType,
} from "../../../../../../../__generated__/ShowAnswerTreeConfigEditor_TreeNodeFragment.graphql";
import { useFragment, useMutation } from "react-relay";
import {
	SetAfterDateTimeDoShowAnswerTreeConfigInput,
	ShowAnswerTreeConfigEditor_SetAfterDateTimeDoShowAnswerTreeConfigMutation,
} from "../../../../../../../__generated__/ShowAnswerTreeConfigEditor_SetAfterDateTimeDoShowAnswerTreeConfigMutation.graphql";
import {
	SetDoNotShowAnswerTreeConfigInput,
	ShowAnswerTreeConfigEditor_SetDoNotShowAnswerTreeConfigMutation,
} from "../../../../../../../__generated__/ShowAnswerTreeConfigEditor_SetDoNotShowAnswerTreeConfigMutation.graphql";
import { Config } from "../components/Config.interfaces";
import moment from "moment-timezone";
import { ConfigItem } from "../components/ConfigItem";
import { AfterDateTimeDoShowAnswerTreeConfigForm } from "./answer/AfterDateTimeDoShowAnswerTreeConfigForm";
import { AfterDateTimeDoShowAnswerTreeConfigForm_AfterDateTimeDoShowAnswerTreeConfigFragment$key } from "../../../../../../../__generated__/AfterDateTimeDoShowAnswerTreeConfigForm_AfterDateTimeDoShowAnswerTreeConfigFragment.graphql";
import { ConfigDropdownOptions } from "../components/ConfigDropdown";
import { utcFormatDateTime } from "../../../../../core/datetime/DateTimeDisplay";

const TREE_NODE_FRAGMENT = graphql`
	fragment ShowAnswerTreeConfigEditor_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			... on RootStructureDefinition {
				showAnswerConfig {
					configType
					... on AfterDateTimeDoShowAnswerTreeConfig {
						dateTime
					}
					...AfterDateTimeDoShowAnswerTreeConfigForm_AfterDateTimeDoShowAnswerTreeConfigFragment
				}
			}
		}
		...AfterDateTimeDoShowAnswerTreeConfigForm_TreeNodeFragment
	}
`;

const SET_AFTER_DATETIME_DO_SHOW_ANSWER_TREE_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation ShowAnswerTreeConfigEditor_SetAfterDateTimeDoShowAnswerTreeConfigMutation(
		$input: SetAfterDateTimeDoShowAnswerTreeConfigInput!
	) {
		Admin {
			Tree {
				setAfterDateTimeDoShowAnswerTreeConfig(input: $input) {
					rootNode {
						...ShowAnswerTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const SET_DO_NOT_SHOW_ANSWER_TREE_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation ShowAnswerTreeConfigEditor_SetDoNotShowAnswerTreeConfigMutation(
		$input: SetDoNotShowAnswerTreeConfigInput!
	) {
		Admin {
			Tree {
				setDoNotShowAnswerTreeConfig(input: $input) {
					rootNode {
						...ShowAnswerTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface Props {
	treeNodeFragmentRef: ShowAnswerTreeConfigEditor_TreeNodeFragment$key;
}

export const ShowAnswerTreeConfigEditor = ({ treeNodeFragmentRef }: Props) => {
	const treeNode = useFragment<ShowAnswerTreeConfigEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const [setAfterDateTimeDoShowAnswerTreeConfig] =
		useMutation<ShowAnswerTreeConfigEditor_SetAfterDateTimeDoShowAnswerTreeConfigMutation>(
			SET_AFTER_DATETIME_DO_SHOW_ANSWER_TREE_CONDITION_CONFIGURATION_MUTATION,
		);
	const [setDoNotShowAnswerTreeConfig] =
		useMutation<ShowAnswerTreeConfigEditor_SetDoNotShowAnswerTreeConfigMutation>(
			SET_DO_NOT_SHOW_ANSWER_TREE_CONDITION_CONFIGURATION_MUTATION,
		);

	const configType: ShowAnswerTreeConfigType = treeNode.structureDefinition.showAnswerConfig
		?.configType as ShowAnswerTreeConfigType;

	const doNotShowAnswers: Config<ShowAnswerTreeConfigType, SetDoNotShowAnswerTreeConfigInput> = {
		configKey: "ShowAnswerTree_DoNot",
		addMutation: (input: SetDoNotShowAnswerTreeConfigInput) => {
			setDoNotShowAnswerTreeConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const showAnswersAfterDateTime: Config<
		ShowAnswerTreeConfigType,
		SetAfterDateTimeDoShowAnswerTreeConfigInput
	> = {
		configKey: "ShowAnswerTree_AfterDateTimeDo",
		addMutation: (input: SetAfterDateTimeDoShowAnswerTreeConfigInput) => {
			setAfterDateTimeDoShowAnswerTreeConfig({
				variables: {
					input,
				},
			});
		},
		addMutationPayload: {
			dateTime: moment().add(1, "year").format(),
		},
		editable: true,
	};

	const configs: Config<ShowAnswerTreeConfigType, any | never>[] = [
		doNotShowAnswers,
		showAnswersAfterDateTime,
	];

	const options: ConfigDropdownOptions<ShowAnswerTreeConfigType>[] = configs.map((c) => {
		const selectedConfig = treeNode.structureDefinition.showAnswerConfig;

		if (
			selectedConfig?.dateTime &&
			selectedConfig?.configType === c.configKey &&
			c.configKey === "ShowAnswerTree_AfterDateTimeDo"
		) {
			return {
				label: `(${utcFormatDateTime(selectedConfig?.dateTime as string, false)})`,
				value: c.configKey,
			};
		} else {
			return {
				label: c.configKey,
				value: c.configKey,
			};
		}
	});

	return (
		<Card className="mb-2">
			<h2>Anzeigen von Antworten</h2>

			<ConfigItem<ShowAnswerTreeConfigType, string>
				isPresentational={false}
				configType={configType}
				canEdit={
					configs.find(
						(c) =>
							c.configKey ===
							treeNode.structureDefinition.showAnswerConfig?.configType,
					)?.editable as boolean
				}
				configOptions={options}
				onChange={(e) => {
					const selectedConfig = configs.find((c) => c.configKey === e.value);

					if (selectedConfig) {
						selectedConfig.addMutation({
							rootNodeId: treeNode.id,
							...selectedConfig.addMutationPayload,
						});
					}
				}}
				editDialog={(props: any) => (
					<>
						{configType === "ShowAnswerTree_AfterDateTimeDo" && (
							<AfterDateTimeDoShowAnswerTreeConfigForm
								{...props}
								treeNodeFragmentRef={treeNode}
								configurationFragmentRef={
									treeNode.structureDefinition
										.showAnswerConfig as unknown as AfterDateTimeDoShowAnswerTreeConfigForm_AfterDateTimeDoShowAnswerTreeConfigFragment$key
								}
							/>
						)}
					</>
				)}
			/>
		</Card>
	);
};

/**
 * @generated SignedSource<<98afb0ee99b9bc19e21cdef72f379ac5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditRandomMultipleChoiceElementInput = {
  count: number;
  randomMultipleChoiceElementId: string;
  clientMutationId?: string | null;
};
export type RandomMultipleChoiceElementForm_EditRandomMultipleChoiceElementMutation$variables = {
  input: EditRandomMultipleChoiceElementInput;
};
export type RandomMultipleChoiceElementForm_EditRandomMultipleChoiceElementMutationVariables = RandomMultipleChoiceElementForm_EditRandomMultipleChoiceElementMutation$variables;
export type RandomMultipleChoiceElementForm_EditRandomMultipleChoiceElementMutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly editRandomMultipleChoiceElement: {
        readonly randomMultipleChoiceElement: {
          readonly " $fragmentSpreads": FragmentRefs<"RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment">;
        };
      } | null;
    };
  };
};
export type RandomMultipleChoiceElementForm_EditRandomMultipleChoiceElementMutationResponse = RandomMultipleChoiceElementForm_EditRandomMultipleChoiceElementMutation$data;
export type RandomMultipleChoiceElementForm_EditRandomMultipleChoiceElementMutation = {
  variables: RandomMultipleChoiceElementForm_EditRandomMultipleChoiceElementMutationVariables;
  response: RandomMultipleChoiceElementForm_EditRandomMultipleChoiceElementMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RandomMultipleChoiceElementForm_EditRandomMultipleChoiceElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditRandomMultipleChoiceElementPayload",
                "kind": "LinkedField",
                "name": "editRandomMultipleChoiceElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RandomMultipleChoiceElement",
                    "kind": "LinkedField",
                    "name": "randomMultipleChoiceElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RandomMultipleChoiceElementForm_EditRandomMultipleChoiceElementMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditRandomMultipleChoiceElementPayload",
                "kind": "LinkedField",
                "name": "editRandomMultipleChoiceElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RandomMultipleChoiceElement",
                    "kind": "LinkedField",
                    "name": "randomMultipleChoiceElement",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "count",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "363ca54488242347e20f846a55895587",
    "id": null,
    "metadata": {},
    "name": "RandomMultipleChoiceElementForm_EditRandomMultipleChoiceElementMutation",
    "operationKind": "mutation",
    "text": "mutation RandomMultipleChoiceElementForm_EditRandomMultipleChoiceElementMutation(\n  $input: EditRandomMultipleChoiceElementInput!\n) {\n  Admin {\n    ElearningV2 {\n      editRandomMultipleChoiceElement(input: $input) {\n        randomMultipleChoiceElement {\n          ...RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment on RandomMultipleChoiceElement {\n  id\n  count\n}\n"
  }
};
})();

(node as any).hash = "c32947abeee563d9dc0a7c3b5aef18f4";

export default node;

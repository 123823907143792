/**
 * @generated SignedSource<<fb0481a3d836722590d8af143039f74a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateLandingPageV2Input = {
  data: LandingPageV2Input;
  id: string;
  clientMutationId?: string | null;
};
export type LandingPageV2Input = {
  title: string;
  description: string;
  urlParam: string;
  clientMutationId?: string | null;
  imageId?: string | null;
  rootNodeIds: ReadonlyArray<string>;
};
export type EditLandingPageV2Screen_UpdateLandingPageMutation$variables = {
  input: UpdateLandingPageV2Input;
};
export type EditLandingPageV2Screen_UpdateLandingPageMutationVariables = EditLandingPageV2Screen_UpdateLandingPageMutation$variables;
export type EditLandingPageV2Screen_UpdateLandingPageMutation$data = {
  readonly Admin: {
    readonly LandingPageV2: {
      readonly updateLandingPageV2: {
        readonly data: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"EditLandingPageV2Screen_LandingPageV2Fragment">;
          };
        };
      } | null;
    };
  };
};
export type EditLandingPageV2Screen_UpdateLandingPageMutationResponse = EditLandingPageV2Screen_UpdateLandingPageMutation$data;
export type EditLandingPageV2Screen_UpdateLandingPageMutation = {
  variables: EditLandingPageV2Screen_UpdateLandingPageMutationVariables;
  response: EditLandingPageV2Screen_UpdateLandingPageMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditLandingPageV2Screen_UpdateLandingPageMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LandingPageV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "LandingPageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateLandingPageV2Payload",
                "kind": "LinkedField",
                "name": "updateLandingPageV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LandingPageV2Edge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LandingPageV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditLandingPageV2Screen_LandingPageV2Fragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditLandingPageV2Screen_UpdateLandingPageMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LandingPageV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "LandingPageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateLandingPageV2Payload",
                "kind": "LinkedField",
                "name": "updateLandingPageV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LandingPageV2Edge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LandingPageV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "urlParam",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "image",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LearnOpportunitiesV2Connection",
                            "kind": "LinkedField",
                            "name": "rootNodes",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LearnOpportunitiesV2Edge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LearnOpportunityV2",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bc892185e1c684ccb2bc0ff2f38ef0ae",
    "id": null,
    "metadata": {},
    "name": "EditLandingPageV2Screen_UpdateLandingPageMutation",
    "operationKind": "mutation",
    "text": "mutation EditLandingPageV2Screen_UpdateLandingPageMutation(\n  $input: UpdateLandingPageV2Input!\n) {\n  Admin {\n    LandingPageV2 {\n      updateLandingPageV2(input: $input) {\n        data {\n          node {\n            ...EditLandingPageV2Screen_LandingPageV2Fragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditLandingPageV2Screen_LandingPageV2Fragment on LandingPageV2 {\n  id\n  title\n  description\n  urlParam\n  image {\n    id\n    url\n  }\n  rootNodes {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "24cd0e074bc796d58ca23b40a1abc726";

export default node;

/**
 * @generated SignedSource<<7fc56490378583d3e52bb8f1467bc17c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PublishingStatus = "succeeded" | "inprogress" | "failed" | "acknowledged" | "%future added value";
export type TreeEditorScreen_PublishingQuery$variables = {
  input: string;
};
export type TreeEditorScreen_PublishingQueryVariables = TreeEditorScreen_PublishingQuery$variables;
export type TreeEditorScreen_PublishingQuery$data = {
  readonly Admin: {
    readonly PublishingV2: {
      readonly ActivePublishing: {
        readonly status: PublishingStatus;
        readonly " $fragmentSpreads": FragmentRefs<"PublishingPart_PublishingV2Fragment">;
      } | null;
    };
  };
};
export type TreeEditorScreen_PublishingQueryResponse = TreeEditorScreen_PublishingQuery$data;
export type TreeEditorScreen_PublishingQuery = {
  variables: TreeEditorScreen_PublishingQueryVariables;
  response: TreeEditorScreen_PublishingQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rootId",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TreeEditorScreen_PublishingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PublishingV2AdminSchema",
            "kind": "LinkedField",
            "name": "PublishingV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "PublishingV2",
                "kind": "LinkedField",
                "name": "ActivePublishing",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PublishingPart_PublishingV2Fragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TreeEditorScreen_PublishingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PublishingV2AdminSchema",
            "kind": "LinkedField",
            "name": "PublishingV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "PublishingV2",
                "kind": "LinkedField",
                "name": "ActivePublishing",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "initiatedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "finishedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PublishingMessageV2",
                    "kind": "LinkedField",
                    "name": "messages",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timestamp",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "message",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "additionalMessage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canBeAcknowledged",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9711beb59dce8003cd34da3678931cf5",
    "id": null,
    "metadata": {},
    "name": "TreeEditorScreen_PublishingQuery",
    "operationKind": "query",
    "text": "query TreeEditorScreen_PublishingQuery(\n  $input: ID!\n) {\n  Admin {\n    PublishingV2 {\n      ActivePublishing(rootId: $input) {\n        status\n        ...PublishingPart_PublishingV2Fragment\n        id\n      }\n    }\n  }\n}\n\nfragment PublishingPart_PublishingV2Fragment on PublishingV2 {\n  id\n  initiatedAt\n  finishedAt\n  messages {\n    timestamp\n    message\n    additionalMessage\n  }\n  status\n  canBeAcknowledged\n}\n"
  }
};
})();

(node as any).hash = "ccc6bdaa9afdbad8cffa8d3c3a22a73c";

export default node;

/**
 * @generated SignedSource<<5aa3d6d274554048d1c7de5b834055c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AmountColumn_PointsAdditionFragment$data = {
  readonly amount: number;
  readonly " $fragmentType": "AmountColumn_PointsAdditionFragment";
};
export type AmountColumn_PointsAdditionFragment = AmountColumn_PointsAdditionFragment$data;
export type AmountColumn_PointsAdditionFragment$key = {
  readonly " $data"?: AmountColumn_PointsAdditionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AmountColumn_PointsAdditionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AmountColumn_PointsAdditionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    }
  ],
  "type": "PointsAdditionV2",
  "abstractKey": null
};

(node as any).hash = "6c63bf90ab84497d712ba23199f459c1";

export default node;

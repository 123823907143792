import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import styled from "styled-components";

interface OwnProps {
	showDialogUpload: boolean;
	uploadButton: JSX.Element;
	setOverride: React.Dispatch<React.SetStateAction<boolean>>;
	cancelButton: JSX.Element;
}

export const FileIsAlreadyInDBDialog = ({
	showDialogUpload,
	uploadButton,
	setOverride,
	cancelButton,
}: OwnProps) => {
	useEffect(() => {
		setOverride(true);
	}, []);
	return (
		<div>
			{showDialogUpload && (
				<DialogContainer
					header={`Eine Datei mit diesem Name bereits vorhanden ist. Möchten Sie sie überschreiben?`}
					visible={showDialogUpload}
					position="center"
					modal
					contentClassName="flex"
					onHide={() => {}}
					footer={() => {
						return (
							<div className=" flex justify-content-end">
								{cancelButton}
								{uploadButton}
							</div>
						);
					}}
				></DialogContainer>
			)}
		</div>
	);
};

const DialogContainer = styled(Dialog)`
	width: 30%;
	.p-dialog-header-icon {
		display: none;
	}
`;

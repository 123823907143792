import { PrivateRouteDefinition } from "../../infecto-lms-admin/components/core/model/PrivateRouteDefinition";
import { TreesScreen } from "../../infecto-lms-admin/screens/TreesScreen";
import { TreeEditorScreen } from "../screens/TreeEditorScreen";

export const TREES_PATH = "/trees";

export const ROOT_NODE_ID_URL_PARAM = ":rootNodeId";
export const getTreeEditorPath = (rootNodeId: string) => {
	return `/trees/${rootNodeId}`;
};

export const PAGE_URL_PARAM = ":page";
export const getTreeEditorWithPagePath = (rootNodeId: string, page: string) => {
	return `/trees/${rootNodeId}/${page}`;
};

export const CHILD_NODE_ID_URL_PARAM = ":childNodeId";
export const getTreeEditorChildNodePath = (rootNodeId: string, childNodeId: string) => {
	return `/trees/${rootNodeId}/${childNodeId}`;
};

export const getTreeEditorChildNodeWithPagePath = (
	rootNodeId: string,
	childNodeId: string,
	page: string,
) => {
	return `/trees/${rootNodeId}/${childNodeId}/${page}`;
};

export const GraphEditorModuleRoutes: PrivateRouteDefinition[] = [
	{
		path: TREES_PATH,
		element: <TreesScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_Nodes_ReadNodes",
		],
	},
	{
		path: getTreeEditorPath(ROOT_NODE_ID_URL_PARAM),
		element: <TreeEditorScreen />,
		requiredPermissions: ["AccountPermission_System_Root"],
	},
	{
		path: getTreeEditorChildNodePath(ROOT_NODE_ID_URL_PARAM, CHILD_NODE_ID_URL_PARAM),
		element: <TreeEditorScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_Nodes_UpdateNodes",
		],
	},
	{
		path: getTreeEditorWithPagePath(ROOT_NODE_ID_URL_PARAM, PAGE_URL_PARAM),
		element: <TreeEditorScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_Nodes_UpdateNodes",
		],
	},
	{
		path: getTreeEditorChildNodeWithPagePath(
			ROOT_NODE_ID_URL_PARAM,
			CHILD_NODE_ID_URL_PARAM,
			PAGE_URL_PARAM,
		),
		element: <TreeEditorScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_Nodes_UpdateNodes",
		],
	},
];

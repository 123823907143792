import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { UrlPathColumn_LandingPageFragment$key } from "../../../../__generated__/UrlPathColumn_LandingPageFragment.graphql";

const LANDING_PAGE_FRAGMENT = graphql`
	fragment UrlPathColumn_LandingPageFragment on LandingPageV2 {
		urlParam
	}
`;

interface OwnProps {
	landingPageFragmentRef: UrlPathColumn_LandingPageFragment$key;
}

export const UrlPathColumn = ({ landingPageFragmentRef }: OwnProps) => {
	const landingPage = useFragment<UrlPathColumn_LandingPageFragment$key>(
		LANDING_PAGE_FRAGMENT,
		landingPageFragmentRef,
	);

	return <p className="text-sm">{landingPage.urlParam}</p>;
};

/**
 * @generated SignedSource<<fbdcef3868907ff0481371847623f2f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UsersWhoMadeContentNodeTable_Fragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserNameColumn_Fragment" | "UserActionColumn_NodeFragment">;
  readonly " $fragmentType": "UsersWhoMadeContentNodeTable_Fragment";
};
export type UsersWhoMadeContentNodeTable_Fragment = UsersWhoMadeContentNodeTable_Fragment$data;
export type UsersWhoMadeContentNodeTable_Fragment$key = {
  readonly " $data"?: UsersWhoMadeContentNodeTable_Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UsersWhoMadeContentNodeTable_Fragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "UsersWhoMadeContentNodeTable_Fragment"
};

(node as any).hash = "c175ee8b796a8da1c150876e9b55d99b";

export default node;

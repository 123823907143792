/**
 * @generated SignedSource<<a9d4f9e6e662a60ddf51d686328ade60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PublishingStatus = "succeeded" | "inprogress" | "failed" | "acknowledged" | "%future added value";
export type TreeContainer_PublishingQuery$variables = {
  input: string;
};
export type TreeContainer_PublishingQueryVariables = TreeContainer_PublishingQuery$variables;
export type TreeContainer_PublishingQuery$data = {
  readonly Admin: {
    readonly PublishingV2: {
      readonly ActivePublishing: {
        readonly status: PublishingStatus;
      } | null;
    };
  };
};
export type TreeContainer_PublishingQueryResponse = TreeContainer_PublishingQuery$data;
export type TreeContainer_PublishingQuery = {
  variables: TreeContainer_PublishingQueryVariables;
  response: TreeContainer_PublishingQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rootId",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TreeContainer_PublishingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PublishingV2AdminSchema",
            "kind": "LinkedField",
            "name": "PublishingV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "PublishingV2",
                "kind": "LinkedField",
                "name": "ActivePublishing",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TreeContainer_PublishingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PublishingV2AdminSchema",
            "kind": "LinkedField",
            "name": "PublishingV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "PublishingV2",
                "kind": "LinkedField",
                "name": "ActivePublishing",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b7d793a1561617ce1c71e406c14e3c4a",
    "id": null,
    "metadata": {},
    "name": "TreeContainer_PublishingQuery",
    "operationKind": "query",
    "text": "query TreeContainer_PublishingQuery(\n  $input: ID!\n) {\n  Admin {\n    PublishingV2 {\n      ActivePublishing(rootId: $input) {\n        status\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff2e63b476ec22f259e39692ae9b15ba";

export default node;

import { Dialog } from "primereact/dialog";
interface OwnProps {
	modalVisible: boolean;
	setModalVisible: (boolean: boolean) => void;
	headerText: string;
	children: any;
}
export const EmailTesterDialogContainer = ({
	modalVisible,
	setModalVisible,
	headerText,
	children,
}: OwnProps) => {
	return (
		<Dialog
			className="w-9 md:w-6 lg:w-4"
			header={<h3 className="m-0">{headerText}</h3>}
			onHide={() => setModalVisible(false)}
			visible={modalVisible}
		>
			{children}
		</Dialog>
	);
};

/**
 * @generated SignedSource<<10956a861b674f4dd4661eeef3f870e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StartTreeConfigType = "StartTree_CMECertifiedNeedsSpecificGlobalUserStatus" | "StartTree_SpecificUserRolesCan" | "StartTree_CanNot" | "StartTree_SpecificUserStatusesCan" | "%future added value";
export type UserStatus = "noProcess" | "initialValidation" | "changeInProgress" | "doccheck" | "inactive" | "efnNeeded" | "active" | "activeEfnNeeded" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SpecificUserStatusesCanStartTreeConfigForm_StartTreeConfigFragment$data = {
  readonly id?: string;
  readonly configType?: StartTreeConfigType;
  readonly dateTime?: string | null;
  readonly userStatuses?: ReadonlyArray<UserStatus>;
  readonly " $fragmentType": "SpecificUserStatusesCanStartTreeConfigForm_StartTreeConfigFragment";
};
export type SpecificUserStatusesCanStartTreeConfigForm_StartTreeConfigFragment = SpecificUserStatusesCanStartTreeConfigForm_StartTreeConfigFragment$data;
export type SpecificUserStatusesCanStartTreeConfigForm_StartTreeConfigFragment$key = {
  readonly " $data"?: SpecificUserStatusesCanStartTreeConfigForm_StartTreeConfigFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SpecificUserStatusesCanStartTreeConfigForm_StartTreeConfigFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpecificUserStatusesCanStartTreeConfigForm_StartTreeConfigFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "configType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dateTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userStatuses",
          "storageKey": null
        }
      ],
      "type": "SpecifiedUserStatusesCanStartTreeConfig",
      "abstractKey": null
    }
  ],
  "type": "StartTreeConfig",
  "abstractKey": "__isStartTreeConfig"
};

(node as any).hash = "20bed38ad3b8f2e64b1dc21b3b00cbcb";

export default node;

import { useFragment, useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { EditRootNodePartForm_TreeNodeFragment$key } from "../../../../../../__generated__/EditRootNodePartForm_TreeNodeFragment.graphql";
import { EditRootNodePartForm_EditRootNodePartMutation } from "../../../../../../__generated__/EditRootNodePartForm_EditRootNodePartMutation.graphql";
import {
	DefaultIntegerFieldComponent,
	DefaultTextAreaComponent,
} from "../../../../core/form/DefaultTextInput";
import { TagSelectionField } from "../../../../tags/TagSelectionField";
import { RenderConfig, ValidatedField } from "../../../../core/form/ValidatedField";
import { FileSelectionField } from "../../../../files/FileSelectionField";
import {
	addEditedFormToEditedFormsArray,
	removeEditedFormFromEditedFormsArray,
} from "../../../../../redux/slices/CoreSlice";
import { useTypedDispatch } from "../../../../../redux/Store";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../../../../lms-admin-impl/i18n/tree.i18n";
import { ConfigDatePicker } from "../config/components/ConfigDatePicker";
import React from "react";

const TREE_NODE_FRAGMENT = graphql`
	fragment EditRootNodePartForm_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			... on RootStructureDefinition {
				cursors {
					relevancy
				}
				firstReleasedAt
				icon {
					id
				}
				searchWords
				tags {
					id
				}
			}
		}
	}
`;

const EDIT_ROOT_NODE_PART_MUTATION = graphql`
	mutation EditRootNodePartForm_EditRootNodePartMutation($input: EditRootNodePartInput!) {
		Admin {
			Tree {
				editRootNodePart(input: $input) {
					editedNode {
						...EditRootNodePartForm_TreeNodeFragment
						...Node_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface FormState {
	searchWords?: string;
	iconRef?: string;
	tags?: string[];
	firstReleasedAt?: string;
	relevancy?: number;
}

interface OwnProps {
	treeNodeFragmentRef: EditRootNodePartForm_TreeNodeFragment$key;
}

export const EditRootNodePartForm = ({ treeNodeFragmentRef }: OwnProps) => {
	const node = useFragment<EditRootNodePartForm_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const [editRootNodePart, isEditingRootNodePart] =
		useMutation<EditRootNodePartForm_EditRootNodePartMutation>(EDIT_ROOT_NODE_PART_MUTATION);

	const dispatch = useTypedDispatch();

	const formId = "EditRootNodePartForm";
	const formik = useFormik<FormState>({
		enableReinitialize: true,
		initialValues: {
			searchWords: node.structureDefinition.searchWords || undefined,
			iconRef: node.structureDefinition.icon?.id || undefined,
			tags: node.structureDefinition.tags?.map((tag) => tag.id),
			firstReleasedAt: node.structureDefinition.firstReleasedAt || undefined,
			relevancy: node.structureDefinition.cursors?.relevancy || undefined,
		},
		onSubmit: (values, { setSubmitting }) => {
			editRootNodePart({
				variables: {
					input: {
						rootNodeId: node.id,
						iconId: values.iconRef,
						tagIds: values.tags || [],
						firstReleasedAt: values.firstReleasedAt,
						searchWords: values.searchWords,
					},
				},
				onCompleted: () => {
					formik.setTouched({});
					setSubmitting(false);
					dispatch(removeEditedFormFromEditedFormsArray({ form: formId }));
				},
			});
		},
	});

	return (
		<Card className="mb-2">
			<h2>{TREE_I18N_MAP(TREE_I18N_KEY.tree)}-Einstellungen</h2>
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, string>
					name={"searchWords"}
					label={"Suchworte"}
					helpText={`Dieser Text wird in der Suche mit durchsucht aber nirgendwo angezeigt.`}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={DefaultTextAreaComponent}
					formikConfig={formik}
				/>

				<ValidatedField<FormState, string>
					name={"iconRef"}
					label={"Icon"}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={({ fieldName, fieldValue, updateField, onChange }) => (
						<FileSelectionField
							name={fieldName}
							selectedFileId={fieldValue}
							setSelectedFileId={updateField}
							filterByFileTypes={["image/png", "image/jpg", "image/jpeg"]}
							onChange={onChange}
						/>
					)}
					formikConfig={formik}
				/>

				<ValidatedField<FormState, string[]>
					name={"tags"}
					label={"Schlagworte"}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={TagSelectionField}
					formikConfig={formik}
				/>
				{node.structureDefinition.firstReleasedAt && (
					<ValidatedField<FormState, string>
						name={"firstReleasedAt"}
						label={"Veröffentlichungszeitpunkt"}
						helpText={
							"Der Zeitpunkt der Veröffentlichung wird für die Sortierung verwendet. Diese Einstellung hat KEINEN Einfluss auf Sichtbarkeit."
						}
						component={({
							fieldName,
							fieldValue,
							updateField,
							disabled,
						}: RenderConfig<string>) => {
							return (
								<ConfigDatePicker
									name={fieldName}
									value={fieldValue}
									onUpdate={updateField}
									disabled={disabled}
									isEndOfTheDay={true}
								/>
							);
						}}
						onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
						formikConfig={formik}
					/>
				)}

				{node.structureDefinition.firstReleasedAt && (
					<ValidatedField<FormState, number>
						name={"relevancy"}
						label={"Relevanz"}
						disabled={true}
						helpText={
							"Die Relevanz wird aus verschiedenen Einstellungen berechnet (zB. gefeaturet oder nicht). Sie wird zur Sortierung im Frontend verwendet."
						}
						component={DefaultIntegerFieldComponent}
						onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
						formikConfig={formik}
					/>
				)}

				<Button
					disabled={Object.entries(formik.touched).length === 0 || isEditingRootNodePart}
					type="submit"
					label="Speichern"
					className="p-mt-2"
				/>
			</form>
		</Card>
	);
};

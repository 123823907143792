import React, { useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Button } from "primereact/button";
import { UserActionColumn_NodeFragment$key } from "../../../__generated__/UserActionColumn_NodeFragment.graphql";
import { UserContentSubmissionDialog } from "./UserContentSubmissionDialog";

const NODE_FRAGMENT = graphql`
	fragment UserActionColumn_NodeFragment on User {
		id
		...UserContentSubmissionDialog_UserFragment
	}
`;

interface OwnProps {
	contentNodeId: string;
	userId: string;
	userFragmentRef: UserActionColumn_NodeFragment$key;
}

export const UserActionColumn = ({ userFragmentRef, contentNodeId }: OwnProps) => {
	const [showContentSubmissionDialog, setShowContentSubmissionDialog] = useState(false);
	const user = useFragment<UserActionColumn_NodeFragment$key>(NODE_FRAGMENT, userFragmentRef);

	return (
		<div>
			{showContentSubmissionDialog && (
				<UserContentSubmissionDialog
					showDialog={showContentSubmissionDialog}
					userFragmentRef={user}
					contentNodeId={contentNodeId}
					setShowDialog={setShowContentSubmissionDialog}
				/>
			)}
			<Button
				className="mr-2"
				onClick={() => {
					setShowContentSubmissionDialog(true);
				}}
				icon={"pi pi-search"}
			/>
		</div>
	);
};

/**
 * @generated SignedSource<<1d95a9f2d3267f041a5f91f1e3872f4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditNotificationTemplateScreen_NotificationTemplateFragment$data = {
  readonly id: string;
  readonly template: {
    readonly key: string;
    readonly title: string;
    readonly message: string;
    readonly variables: ReadonlyArray<string>;
  };
  readonly " $fragmentType": "EditNotificationTemplateScreen_NotificationTemplateFragment";
};
export type EditNotificationTemplateScreen_NotificationTemplateFragment = EditNotificationTemplateScreen_NotificationTemplateFragment$data;
export type EditNotificationTemplateScreen_NotificationTemplateFragment$key = {
  readonly " $data"?: EditNotificationTemplateScreen_NotificationTemplateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditNotificationTemplateScreen_NotificationTemplateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditNotificationTemplateScreen_NotificationTemplateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NotificationTemplate",
      "kind": "LinkedField",
      "name": "template",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "variables",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PersonalizedNotificationTemplate",
  "abstractKey": null
};

(node as any).hash = "e9aee421e8270b4a3a8c7fbe40924bf9";

export default node;

import { graphql } from "babel-plugin-relay/macro";
import { OrderList } from "primereact/orderlist";
import { useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useContext, useState } from "react";
import { ELearningContentEditor_TreeNodeFragment$key } from "../../../../../../../__generated__/ELearningContentEditor_TreeNodeFragment.graphql";
import { ELEMENT_TYPE_TRANSLATIONS } from "../../../../../../i18n/graph/element-type";
import { CreateFileElementButton } from "./file/CreateFileElementButton";
import { ElementForm } from "./ElementForm";
import { BaseContentEditor } from "../BaseContentEditor";
import { ElementForm_ElementV2Fragment$key } from "../../../../../../../__generated__/ElementForm_ElementV2Fragment.graphql";
import { ELearningContentEditor_DeleteElementV2Mutation } from "../../../../../../../__generated__/ELearningContentEditor_DeleteElementV2Mutation.graphql";
import { ELearningContentEditor_RearrangeElementsMutation } from "../../../../../../../__generated__/ELearningContentEditor_RearrangeElementsMutation.graphql";
import { CreateMultipleChoiceElementButton } from "./multiplechoice/CreateMultipleChoiceElementButton";
import { CreatePodcastElementButton } from "./podcast/CreatePodcastElementButton";
import { CreateVideoElementButton } from "./video/CreateVideoElementButton";
import { CreateTextElementButton } from "./text/CreateTextElementButton";
import { ImplContextV2, ImplV2 } from "../../../../../../impl/ImplContextV2";
import { useTypedSelector } from "../../../../../../redux/Store";
import { selectArrayOfEditedForms } from "../../../../../../redux/slices/CoreSlice";
import { WarningUnsavedChangesDialog } from "../../../../../core/dialog/WarningUnsavedChangesDialog";
import { htmlTagsInTextRemover } from "../../../../../../functions/htmlTagsInTextRemover";
import { ProjectExtendElementButtons } from "../../../../../../../lms-admin-impl/components/ui/ProjectExtendElementButtons";

const TREE_NODE_FRAGMENT = graphql`
	fragment ELearningContentEditor_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ELearningContentTypeDefinition {
				elements {
					id
					elementType
					title
					...ElementForm_ElementV2Fragment
				}
			}
		}
	}
`;

const REARRANGE_ELEMENTS_MUTATION = graphql`
	mutation ELearningContentEditor_RearrangeElementsMutation($input: RearrangeElementsV2Input!) {
		Admin {
			ElearningV2 {
				rearrangeElements(input: $input) {
					eLearningContentNode {
						...ELearningContentEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const DELETE_ELEMENT_MUTATION = graphql`
	mutation ELearningContentEditor_DeleteElementV2Mutation($input: DeleteElementV2Input!) {
		Admin {
			ElearningV2 {
				deleteElement(input: $input) {
					eLearningContentNode {
						...ELearningContentEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: ELearningContentEditor_TreeNodeFragment$key;
}

export const ELearningContentEditor = ({ treeNodeFragmentRef }: OwnProps) => {
	const implContext = useContext<ImplV2>(ImplContextV2);

	const node = useFragment<ELearningContentEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const arrayOfEditedForm = useTypedSelector(selectArrayOfEditedForms);
	const [rearrangeElements, isRearrangingElements] =
		useMutation<ELearningContentEditor_RearrangeElementsMutation>(REARRANGE_ELEMENTS_MUTATION);
	const [deleteElement, isDeletingElement] =
		useMutation<ELearningContentEditor_DeleteElementV2Mutation>(DELETE_ELEMENT_MUTATION);
	const [showDialog, setShowDialog] = useState(false);
	const [currentlyEditingElement, setCurrentlyEditingElement] = useState<
		ElementForm_ElementV2Fragment$key | undefined
	>();

	return currentlyEditingElement ? (
		<>
			{showDialog && (
				<WarningUnsavedChangesDialog
					setShowDialog={setShowDialog}
					callback={setCurrentlyEditingElement}
					value={undefined}
				/>
			)}
			<ElementForm
				onBack={() => {
					if (arrayOfEditedForm.length > 0) {
						setShowDialog(true);
					} else {
						setCurrentlyEditingElement(undefined);
					}
				}}
				elementFragmentRef={currentlyEditingElement}
			/>
		</>
	) : (
		<BaseContentEditor>
			<div className="p-buttonset mb-2">
				<CreateTextElementButton eLearningContentNodeId={node.id} />
				<CreateFileElementButton eLearningContentNodeId={node.id} />
				<CreateVideoElementButton eLearningContentNodeId={node.id} />
				<CreateMultipleChoiceElementButton eLearningContentNodeId={node.id} />
				<CreatePodcastElementButton eLearningContentNodeId={node.id} />
				<ProjectExtendElementButtons eLearningContentNodeId={node.id} />
				{implContext.node?.elements?.createElementButtons(node.id)}
			</div>
			<OrderList
				dragdrop={false}
				onChange={(e) =>
					rearrangeElements({
						variables: {
							input: {
								eLearningContentNodeId: node.id,
								newElementIds: e.value.map((e: any) => e.id!),
							},
						},
					})
				}
				itemTemplate={(item) => (
					<div
						className="flex align-items-center"
						onDoubleClick={() => {
							setCurrentlyEditingElement(item);
						}}
					>
						{ELEMENT_TYPE_TRANSLATIONS[item.elementType]}:{" "}
						{htmlTagsInTextRemover(item.title)}
						<Button
							disabled={isDeletingElement}
							className="ml-auto"
							icon="pi pi-trash"
							onClick={() => {
								deleteElement({
									variables: {
										input: {
											elementId: item.id,
										},
									},
								});
							}}
						/>
						<Button
							disabled={isRearrangingElements || isDeletingElement}
							className="ml-2"
							icon="pi pi-pencil"
							onClick={() => {
								setCurrentlyEditingElement(item);
							}}
						/>
					</div>
				)}
				value={[...(node.typeDefinition.elements || [])]}
			/>
		</BaseContentEditor>
	);
};

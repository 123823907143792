import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import graphql from "babel-plugin-relay/macro";
import { ConnectionHandler, useMutation, usePaginationFragment } from "react-relay";
import { CertificateTemplatesTable_Refetch } from "../../../__generated__/CertificateTemplatesTable_Refetch.graphql";
import { useNavigate } from "react-router-dom";
import { ShowCertificateButton } from "./ShowCertificateButton";
import { CertificateTemplatesTable_DeleteCertificateTemplateMutation } from "../../../__generated__/CertificateTemplatesTable_DeleteCertificateTemplateMutation.graphql";
import { CertificateTemplatesTable_QueryFragment$key } from "../../../__generated__/CertificateTemplatesTable_QueryFragment.graphql";
import { getEditCertificateTemplatePathV2 } from "../../../app/routes/CertificateModuleRoutes";
import { useContext } from "react";
import { ImplContextV2, ImplV2 } from "../../impl/ImplContextV2";
import { useDialogLogic } from "../core/dialog/useDialogLogic";

const QUERY_FRAGMENT = graphql`
	fragment CertificateTemplatesTable_QueryFragment on Query
	@refetchable(queryName: "CertificateTemplatesTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int" }
		after: { type: "String" }
		filterByName: { type: "String" }
	) {
		Admin {
			Certificates {
				CertificateTemplates(first: $first, after: $after, filterByName: $filterByName)
					@connection(key: "CertificateTemplatesTable_CertificateTemplates") {
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							name
							...ShowCertificateButton_CertificateFragment
						}
					}
				}
			}
		}
	}
`;

const DELETE_CERTIFICATE_TEMPLATE_MUTATION = graphql`
	mutation CertificateTemplatesTable_DeleteCertificateTemplateMutation(
		$input: DeleteCertificateTemplateInput!
		$connections: [ID!]!
	) {
		Admin {
			Certificates {
				deleteCertificateTemplate(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;

interface OwnProps {
	queryFragmentRef: CertificateTemplatesTable_QueryFragment$key;
}

export const CertificateTemplatesTable = ({ queryFragmentRef }: OwnProps) => {
	const navigate = useNavigate();
	const implContext = useContext<ImplV2>(ImplContextV2);

	const {
		data: {
			Admin: {
				Certificates: {
					CertificateTemplates: { edges: pointPools },
				},
			},
		},
		hasPrevious,
		hasNext,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<
		CertificateTemplatesTable_Refetch,
		CertificateTemplatesTable_QueryFragment$key
	>(QUERY_FRAGMENT, queryFragmentRef);

	const [deleteCertificateTemplate, isDeletingCertificateTemplate] =
		useMutation<CertificateTemplatesTable_DeleteCertificateTemplateMutation>(
			DELETE_CERTIFICATE_TEMPLATE_MUTATION,
		);

	const { showDialog, dialogComponent } = useDialogLogic();

	return (
		<>
			{dialogComponent}
			<div className="flex justify-content-end mb-3">
				{implContext.node?.generateTestCertificateButton()}

				<Button onClick={() => navigate(getEditCertificateTemplatePathV2("new"))}>
					Neue Zertifikatsvorlage
				</Button>
			</div>
			<DataTable
				emptyMessage="Keine Zertifikats-Vorlagen"
				className="mb-3"
				value={pointPools?.map((b) => b!.node!) as any[]}
			>
				<Column
					header="Name"
					body={(row) => <span style={{ color: row.pointPoolColorHex }}>{row.name}</span>}
				/>
				<Column
					header="Aktionen"
					style={{ width: "20%" }}
					body={(item) => (
						<>
							<ShowCertificateButton
								className="mr-2"
								certificateTemplateFragmentRef={item}
							/>

							<Button
								className="mr-2"
								onClick={() => {
									navigate(getEditCertificateTemplatePathV2(item.id));
								}}
								icon={"pi pi-pencil"}
							/>

							<Button
								disabled={isDeletingCertificateTemplate}
								onClick={() => {
									showDialog({
										title: "Zertifikatsvorlage löschen",
										content:
											"Möchten Sie dieses Zertifikatsvorlage wirklich löschen? Das kann nicht rückgängig gemacht werden.",
										dialogCallback: (result) => {
											if (result === "Accept") {
												deleteCertificateTemplate({
													variables: {
														input: {
															ids: [item.id],
														},
														connections: [
															ConnectionHandler.getConnectionID(
																"client:root:Admin:Certificates",
																"CertificateTemplatesTable_CertificateTemplates",
															),
														],
													},
												});
											}
										},
									});
								}}
								icon={"pi pi-trash"}
							/>
						</>
					)}
				/>
			</DataTable>
			<div className="flex justify-content-center align-items-center">
				<Button
					disabled={!hasPrevious}
					onClick={() => loadPrevious(20)}
					className="mr-3 p-button-secondary"
				>
					Zurück
				</Button>
				<Button
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={() => loadNext(20)}
				>
					Weiter
				</Button>
			</div>
		</>
	);
};

/**
 * @generated SignedSource<<0d39b4dfd8e9176192459666acd5795a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RewardEditor_CertificateTemplatesConnectionV2Fragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CreateCertificatesRewardButton_CertificateTemplatesConnectionV2Fragment" | "CreateFachberaterRewardButton_CertificateTemplatesConnectionV2Fragment">;
  readonly " $fragmentType": "RewardEditor_CertificateTemplatesConnectionV2Fragment";
};
export type RewardEditor_CertificateTemplatesConnectionV2Fragment = RewardEditor_CertificateTemplatesConnectionV2Fragment$data;
export type RewardEditor_CertificateTemplatesConnectionV2Fragment$key = {
  readonly " $data"?: RewardEditor_CertificateTemplatesConnectionV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RewardEditor_CertificateTemplatesConnectionV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RewardEditor_CertificateTemplatesConnectionV2Fragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateCertificatesRewardButton_CertificateTemplatesConnectionV2Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateFachberaterRewardButton_CertificateTemplatesConnectionV2Fragment"
    }
  ],
  "type": "CertificateTemplatesConnection",
  "abstractKey": null
};

(node as any).hash = "8b03e1030eda46cc51fcd41aae79ef97";

export default node;

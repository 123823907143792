import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ActionColumn_TreeStateFragment$key } from "../../../__generated__/ActionColumn_TreeStateFragment.graphql";
import { getSingleTreeStatePath } from "../../../app/routes/LearnStatesModuleRoutes";

const TREE_STATE_FRAGMENT = graphql`
	fragment ActionColumn_TreeStateFragment on TreeState {
		id
	}
`;

interface OwnProps {
	treeStateFragmentRef: ActionColumn_TreeStateFragment$key;
}

export const ActionColumn = ({ treeStateFragmentRef }: OwnProps) => {
	const history = useNavigate();

	const treeState = useFragment<ActionColumn_TreeStateFragment$key>(
		TREE_STATE_FRAGMENT,
		treeStateFragmentRef,
	);

	return (
		<Button
			className="mr-2"
			onClick={() => history(getSingleTreeStatePath(treeState.id))}
			icon={"pi pi-pencil"}
		/>
	);
};

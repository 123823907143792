/**
 * @generated SignedSource<<56030ba90e2930007ea6b9654fd72056>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment$data = {
  readonly id: string;
  readonly count: number;
  readonly " $fragmentType": "RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment";
};
export type RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment = RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment$data;
export type RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment$key = {
  readonly " $data"?: RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    }
  ],
  "type": "RandomMultipleChoiceElement",
  "abstractKey": null
};

(node as any).hash = "c2ca96b0c256d95e58190c6238db7dad";

export default node;

/**
 * @generated SignedSource<<96680276c1f2921e0bd1b0c0e66f4734>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditMessageDefinitionScreen_Query$variables = {
  id: string;
};
export type EditMessageDefinitionScreen_QueryVariables = EditMessageDefinitionScreen_Query$variables;
export type EditMessageDefinitionScreen_Query$data = {
  readonly node: {
    readonly internalTitle?: string;
    readonly " $fragmentSpreads": FragmentRefs<"MessageDefinitionPages_MessageDefinitionFragment" | "EditMessageDefinitionForm_MessageDefinitionFragment">;
  } | null;
};
export type EditMessageDefinitionScreen_QueryResponse = EditMessageDefinitionScreen_Query$data;
export type EditMessageDefinitionScreen_Query = {
  variables: EditMessageDefinitionScreen_QueryVariables;
  response: EditMessageDefinitionScreen_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalTitle",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditMessageDefinitionScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MessageDefinitionPages_MessageDefinitionFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditMessageDefinitionForm_MessageDefinitionFragment"
              }
            ],
            "type": "MessageDefinition",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditMessageDefinitionScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MessageDefinitionPagesConnection",
                "kind": "LinkedField",
                "name": "pages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessageDefinitionPagesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MessageDefinitionPage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "image",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "fileType",
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "uploadDateTime",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "connection",
                "key": "MessageDefinitionPagesOrderList_pages",
                "kind": "LinkedHandle",
                "name": "pages"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "buttonOpt",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "link",
                        "storageKey": null
                      }
                    ],
                    "type": "MessageDefinitionLinkButton",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showUntilOpt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NotificationDefinitionType",
                "kind": "LinkedField",
                "name": "notificationDefinitionOpt",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "body",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MessageDefinition",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6d87cc5019d22655a1479ad12e5858f2",
    "id": null,
    "metadata": {},
    "name": "EditMessageDefinitionScreen_Query",
    "operationKind": "query",
    "text": "query EditMessageDefinitionScreen_Query(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on MessageDefinition {\n      internalTitle\n      ...MessageDefinitionPages_MessageDefinitionFragment\n      ...EditMessageDefinitionForm_MessageDefinitionFragment\n    }\n    id\n  }\n}\n\nfragment EditMessageDefinitionForm_MessageDefinitionFragment on MessageDefinition {\n  id\n  internalTitle\n  buttonOpt {\n    __typename\n    type\n    title\n    ... on MessageDefinitionLinkButton {\n      link\n    }\n  }\n  showUntilOpt\n  notificationDefinitionOpt {\n    title\n    body\n  }\n}\n\nfragment EditMessageDefinitionPageForm_MessageDefinitionPageFragment on MessageDefinitionPage {\n  id\n  internalTitle\n  image {\n    fileType\n    id\n    name\n    uploadDateTime\n    url\n  }\n}\n\nfragment MessageDefinitionPagesOrderList_MessageDefinitionFragment on MessageDefinition {\n  id\n  pages {\n    edges {\n      node {\n        id\n        internalTitle\n        ...EditMessageDefinitionPageForm_MessageDefinitionPageFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment MessageDefinitionPages_MessageDefinitionFragment on MessageDefinition {\n  ...MessageDefinitionPagesOrderList_MessageDefinitionFragment\n}\n"
  }
};
})();

(node as any).hash = "e80eeca25befa76f4321fded7ea6dd39";

export default node;

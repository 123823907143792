import React, { Suspense } from "react";
import { BrowserRouter, NavLink, Route, Routes } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import { MenuItem, MenuItemOptions } from "primereact/menuitem";
import { Button } from "primereact/button";
import _ from "lodash";
import graphql from "babel-plugin-relay/macro";
import { Loader } from "../../infecto-lms-admin/components/core/Loader";
import { InstructorModuleRoutes } from "./InstructorModuleRoutes";
import { LANDING_PAGES_PATH_V2, LandingPageModuleRoutes } from "./LandingPageModuleRoutes";
import { BaseScreen } from "../../infecto-lms-admin/screens/BaseScreen";
import { MessagingRoutes } from "./MessagingRoutes";
import { CERTIFICATE_TEMPLATES_PATH_V2, CertificateModuleRoutes } from "./CertificateModuleRoutes";
import { PointsModuleRoutes } from "./PointsModuleRoutes";
import { MaintenanceModuleRoutes } from "./MaintenanceModuleRoutes";
import { EmailModuleRoutes } from "./EmailModuleRoutes";
import { FeedbackModuleRoutes, FEEDBACKS_V2_PATH } from "./FeedbackModuleRoutes";
import { LearnStatesModuleRoutes } from "./LearnStatesModuleRoutes";
import { FilesModuleRoutes } from "./FilesModuleRoutes";
import { NotificationModuleRoutes } from "./NotificationModuleRoutes";
import { STAGING_PATH_V2, StagingModuleRoutes } from "./StagingModuleRoutes";
import { TagModuleRoutes } from "./TagModuleRoutes";
import { GraphEditorModuleRoutes, TREES_PATH } from "./GraphEditorRoutes";
import { NewstickerRoutes } from "./NewstickerRoutes";
import {
	MODULES_PATH,
	PublishedNodesAndUsersModuleRoutes,
} from "./PublishedNodesAndUsersModuleRoutes";
import { useLazyLoadQuery } from "react-relay";
import { useDispatch, useSelector } from "react-redux";
import { MainNavigation_Query, Permission } from "../../__generated__/MainNavigation_Query.graphql";
import {
	CurrentUserData,
	selectHasPermission,
	selectHasPermissions,
	setCurrentUser,
} from "../../infecto-lms-admin/redux/slices/CurrentUserSlice";
import { logout } from "../../infecto-lms-admin/redux/slices/AuthSlice";
import { LoginPrompt } from "../../infecto-lms-admin/components/auth/LoginPrompt";

const MenuTemplate = (item: MenuItem, options: MenuItemOptions) => (
	<NavLink className={options.className} target={item.target} to={item.url!}>
		{item.label}
	</NavLink>
);

const PERMISSION_QUERY = graphql`
	query MainNavigation_Query {
		Viewer {
			Auth {
				currentUser {
					permissionsInAccount
				}
			}
		}
	}
`;

export const MainNavigation = () => {
	const query = useLazyLoadQuery<MainNavigation_Query>(PERMISSION_QUERY, {});
	const dispatch = useDispatch();

	if (query.Viewer.Auth.currentUser) {
		dispatch(setCurrentUser(query.Viewer.Auth.currentUser as CurrentUserData));
	}

	const checkPermission = (permission: Permission) =>
		useSelector((state: any) => selectHasPermission(state, permission));

	const checkPermissions = (permissions: Permission[]) =>
		useSelector((state: any) => selectHasPermissions(state, permissions));

	const isAdmin = checkPermissions([
		"AccountPermission_System_Root",
		"UserInAccountPermission_System_Owner",
	]);

	const hasReadNodesPermission = checkPermission("UserInAccountPermission_Nodes_ReadNodes");

	const items: MenuItem[] = [
		{
			label: "Hauptseite",
			url: "/",
			template: MenuTemplate,
		},
	];

	if (isAdmin || checkPermission("UserInAccountPermission_Nodes_ReadNodes")) {
		items.push({
			label: "Module",
			url: TREES_PATH,
			template: MenuTemplate,
		});
	}

	if (isAdmin || checkPermission("UserInAccountPermission_Nodes_ReadNodes")) {
		items.push({
			label: "Fortschritte",
			url: MODULES_PATH,
			template: MenuTemplate,
		});
	}

	if (isAdmin) {
		items.push({
			label: "Feedbacks",
			url: FEEDBACKS_V2_PATH,
			template: MenuTemplate,
		});
	}

	if (isAdmin || checkPermission("UserInAccountPermission_Tags_ReadTags")) {
		items.push({
			label: "Schlagworte",
			url: "/tags",
			template: MenuTemplate,
		});
	}

	if (isAdmin || checkPermission("UserInAccountPermission_Instructors_ReadInstructors")) {
		items.push({
			label: "Lehrende",
			url: "/instructors",
			template: MenuTemplate,
		});
	}

	if (isAdmin || checkPermission("UserInAccountPermission_LandingPages_ReadLandingPages")) {
		items.push({
			label: "Seiten",
			url: LANDING_PAGES_PATH_V2,
			template: MenuTemplate,
		});
	}

	if (isAdmin || checkPermission("UserInAccountPermission_Certificates_ReadCertificates")) {
		items.push({
			label: "Zertifikate",
			url: CERTIFICATE_TEMPLATES_PATH_V2,
			template: MenuTemplate,
		});
	}

	/*if(isAdmin || checkPermission("Points_ReadPoints")){
        items.push(
            {
                label: "Punkte-Pools",
                url: POINT_POOLS_PATH,
                template: MenuTemplate,
            }
        );
    }*/

	if (isAdmin) {
		items.push({
			label: "Kommunikation",
			items: [
				{
					label: "Newsticker",
					url: "/newsticker",
					template: MenuTemplate,
				},
				{
					label: "E-Mail-Vorlagen",
					url: "/email-templates",
					template: MenuTemplate,
				},
				/*{
					label: "Benachrichtigungs-Vorlagen",
					url: "/notification-templates",
					template: MenuTemplate,
				},
				{
					label: "Nachrichten",
					url: "/messaging",
					template: MenuTemplate,
				},*/
			],
		});
	}

	// @TODO: replace nodes_readnodes with read files
	if (isAdmin || checkPermission("UserInAccountPermission_Nodes_ReadNodes")) {
		items.push({
			label: "Dateien",
			url: "/files",
			template: MenuTemplate,
		});
	}

	if (["staging", "dev"].includes(process.env.REACT_APP_APP_ENVIRONMENT || "")) {
		items.push({
			label: "Staging",
			url: STAGING_PATH_V2,
			template: MenuTemplate,
		});
	}

	if (isAdmin) {
		items.push({
			label: "Wartung",
			url: "/maintenance",
			template: MenuTemplate,
		});
	}

	return (
		<>
			{!query.Viewer.Auth.currentUser && <LoginPrompt />}
			{query.Viewer.Auth.currentUser && !isAdmin && !hasReadNodesPermission && (
				<div>Kein Zugriff</div>
			)}
			{query.Viewer.Auth.currentUser && (
				<BrowserRouter>
					<Menubar
						model={items}
						end={
							<Button
								label="Ausloggen"
								onClick={() => dispatch(logout())}
								className="mr-2"
								icon="pi pi-power-off"
							/>
						}
					/>
					<Suspense fallback={<Loader />}>
						<Routes>
							{[
								...FilesModuleRoutes,
								...GraphEditorModuleRoutes,
								...TagModuleRoutes,
								...StagingModuleRoutes,
								...PointsModuleRoutes,
								...CertificateModuleRoutes,
								...EmailModuleRoutes,
								...FeedbackModuleRoutes,
								...LearnStatesModuleRoutes,
								...InstructorModuleRoutes,
								...LandingPageModuleRoutes,
								...NotificationModuleRoutes,
								...MessagingRoutes,
								...NewstickerRoutes,
								...MaintenanceModuleRoutes,
								...PublishedNodesAndUsersModuleRoutes,
								{
									path: "/",
									element: <BaseScreen>Willkommen!</BaseScreen>,
									requiredPermissions: [],
								},
							].map((prd) => {
								if (
									prd.requiredPermissions?.length &&
									_.difference(
										prd.requiredPermissions || [],
										query.Viewer.Auth.currentUser?.permissionsInAccount || [],
									).length >= (prd.requiredPermissions?.length || 0)
								) {
									return (
										<Route
											key={"verboten"}
											path={"*"}
											element={<div>VERBOTEN</div>}
										/>
									);
								}

								return (
									<Route key={prd.path} path={prd.path} element={prd.element} />
								);
							})}
						</Routes>
					</Suspense>
				</BrowserRouter>
			)}
		</>
	);
};

import { Button } from "primereact/button";
import { toast } from "react-toastify";
import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { ProjectExtendStagingButtons_ResetElementPoolsMutation } from "../../../__generated__/ProjectExtendStagingButtons_ResetElementPoolsMutation.graphql";

const RESET_ELEMENTS_POOL_MUTATION = graphql`
	mutation ProjectExtendStagingButtons_ResetElementPoolsMutation {
		Admin {
			Elementspool {
				ResetStagingElementPools(input: {}) {
					clientMutationId
				}
			}
		}
	}
`;
export const ProjectExtendStagingButtons = () => {
	const [resetElementPools, isResettingElementsPool] =
		useMutation<ProjectExtendStagingButtons_ResetElementPoolsMutation>(
			RESET_ELEMENTS_POOL_MUTATION,
		);
	return (
		<Button
			className={"mb-5"}
			label={"Staging Fragen Pools resetten"}
			disabled={isResettingElementsPool}
			onClick={() => {
				resetElementPools({
					variables: {},
					onCompleted: () => {
						toast.success("Fragen pools erfolgreich zurückgesetzt.");
					},
				});
			}}
		/>
	);
};

import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { UserColumn_TreeStateFragment$key } from "../../../../__generated__/UserColumn_TreeStateFragment.graphql";
import { ProjectUserDescription } from "../../../../lms-admin-impl/components/relay/ProjectUserDescription";

const TREE_STATE_FRAGMENT = graphql`
	fragment UserColumn_TreeStateFragment on TreeState {
		user {
			...ProjectUserDescription_UserFragment
		}
	}
`;

interface OwnProps {
	treeStateFragmentRef: UserColumn_TreeStateFragment$key;
}

export const UserColumn = ({ treeStateFragmentRef }: OwnProps) => {
	const treeState = useFragment<UserColumn_TreeStateFragment$key>(
		TREE_STATE_FRAGMENT,
		treeStateFragmentRef,
	);

	return (
		<div>
			<ProjectUserDescription userFragmentRef={treeState.user} />
		</div>
	);
};

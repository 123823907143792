/**
 * @generated SignedSource<<cd5786b7a04940e357cf192b147866c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteMessageDefinitionInput = {
  ids: ReadonlyArray<string>;
  clientMutationId?: string | null;
};
export type MessageDefinitionOverviewScreen_DeleteMessageDefinitionMutation$variables = {
  input: DeleteMessageDefinitionInput;
  connections: ReadonlyArray<string>;
};
export type MessageDefinitionOverviewScreen_DeleteMessageDefinitionMutationVariables = MessageDefinitionOverviewScreen_DeleteMessageDefinitionMutation$variables;
export type MessageDefinitionOverviewScreen_DeleteMessageDefinitionMutation$data = {
  readonly Admin: {
    readonly Messaging: {
      readonly deleteMessageDefinition: {
        readonly deletedIds: ReadonlyArray<string>;
      } | null;
    };
  };
};
export type MessageDefinitionOverviewScreen_DeleteMessageDefinitionMutationResponse = MessageDefinitionOverviewScreen_DeleteMessageDefinitionMutation$data;
export type MessageDefinitionOverviewScreen_DeleteMessageDefinitionMutation = {
  variables: MessageDefinitionOverviewScreen_DeleteMessageDefinitionMutationVariables;
  response: MessageDefinitionOverviewScreen_DeleteMessageDefinitionMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedIds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageDefinitionOverviewScreen_DeleteMessageDefinitionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessagingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Messaging",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteMessageDefinitionPayload",
                "kind": "LinkedField",
                "name": "deleteMessageDefinition",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MessageDefinitionOverviewScreen_DeleteMessageDefinitionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessagingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Messaging",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteMessageDefinitionPayload",
                "kind": "LinkedField",
                "name": "deleteMessageDefinition",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "deleteEdge",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "deletedIds",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f6a0ec9262c919360fdc31bfae511e7b",
    "id": null,
    "metadata": {},
    "name": "MessageDefinitionOverviewScreen_DeleteMessageDefinitionMutation",
    "operationKind": "mutation",
    "text": "mutation MessageDefinitionOverviewScreen_DeleteMessageDefinitionMutation(\n  $input: DeleteMessageDefinitionInput!\n) {\n  Admin {\n    Messaging {\n      deleteMessageDefinition(input: $input) {\n        deletedIds\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3fbb2cd865a68277a24af4cb39be3eef";

export default node;

import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import "./style.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-toastify/dist/ReactToastify.css";
import { ReduxStore } from "./infecto-lms-admin/redux/Store";
import { Provider } from "react-redux";

ReactDOM.render(
	<Provider store={ReduxStore}>
		<React.StrictMode>
			<App />
		</React.StrictMode>
	</Provider>,
	document.getElementById("root"),
);

/**
 * @generated SignedSource<<fa3cd301073aa5e3a4f439f8ec5c410d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditPointPoolScreen_Query$variables = {
  id: string;
  skip: boolean;
};
export type EditPointPoolScreen_QueryVariables = EditPointPoolScreen_Query$variables;
export type EditPointPoolScreen_Query$data = {
  readonly node?: {
    readonly " $fragmentSpreads": FragmentRefs<"EditPointPoolScreen_PointPoolFragment">;
  } | null;
};
export type EditPointPoolScreen_QueryResponse = EditPointPoolScreen_Query$data;
export type EditPointPoolScreen_Query = {
  variables: EditPointPoolScreen_QueryVariables;
  response: EditPointPoolScreen_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPointPoolScreen_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EditPointPoolScreen_PointPoolFragment"
                  }
                ],
                "type": "PointPool",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditPointPoolScreen_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "triggersExport",
                    "storageKey": null
                  }
                ],
                "type": "PointPool",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "1edf1d378cb3db5b9fd31e34027b2078",
    "id": null,
    "metadata": {},
    "name": "EditPointPoolScreen_Query",
    "operationKind": "query",
    "text": "query EditPointPoolScreen_Query(\n  $id: ID!\n  $skip: Boolean!\n) {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on PointPool {\n      ...EditPointPoolScreen_PointPoolFragment\n    }\n    id\n  }\n}\n\nfragment EditPointPoolScreen_PointPoolFragment on PointPool {\n  id\n  name\n  description\n  triggersExport\n}\n"
  }
};
})();

(node as any).hash = "d1c9ba9bbc028364bcc049eeb10ded37";

export default node;

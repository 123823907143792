/**
 * @generated SignedSource<<de2bbc2106aad086633ae6a8888643fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StartedAtColumn_ContentSubmissionFragment$data = {
  readonly startedAt: string;
  readonly " $fragmentType": "StartedAtColumn_ContentSubmissionFragment";
};
export type StartedAtColumn_ContentSubmissionFragment = StartedAtColumn_ContentSubmissionFragment$data;
export type StartedAtColumn_ContentSubmissionFragment$key = {
  readonly " $data"?: StartedAtColumn_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"StartedAtColumn_ContentSubmissionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StartedAtColumn_ContentSubmissionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startedAt",
      "storageKey": null
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};

(node as any).hash = "23a7ee9e209cf7998ad8970039452e51";

export default node;

import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useDialogLogic } from "../core/dialog/useDialogLogic";
import { DeleteActiveContentSubmissionsButton_DeleteMutation } from "../../../__generated__/DeleteActiveContentSubmissionsButton_DeleteMutation.graphql";
import { toast } from "react-toastify";

const DELETE_BUTTON_MUTATION = graphql`
	mutation DeleteActiveContentSubmissionsButton_DeleteMutation(
		$input: DeleteContentNodeActiveContentSubmissionsAndElementsInput!
	) {
		Admin {
			DeleteContentNodeActiveContentSubmissionAndLearnElements {
				DeleteContentNodeActiveContentSubmissionsAndElements(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
interface OwnProps {
	nodeId: string;
}

export const DeleteActiveContentSubmissionsButton = ({ nodeId }: OwnProps) => {
	const { showDialog, dialogComponent } = useDialogLogic();
	const [deleteActiveContentSubmissionAndLearnElements, isDeleting] =
		useMutation<DeleteActiveContentSubmissionsButton_DeleteMutation>(DELETE_BUTTON_MUTATION);
	return (
		<div>
			{dialogComponent}
			<p>
				Wenn Bausteine wie Fragen, oder Hefte ausgetauscht werden, dann müssen die aktiven
				Learnstates gelöscht werden, sonst sieht der User weiterhin die alten Versionen.
			</p>
			<br />
			<Button
				disabled={isDeleting}
				type="button"
				onClick={() =>
					showDialog({
						title: `Die Aktive Learnstates löschen`,
						content: `Möchten Sie die Aktive Learnstates wirklich löschen? Das kann nicht rückgängig gemacht werden.`,
						dialogCallback: (result) => {
							if (result === "Accept") {
								deleteActiveContentSubmissionAndLearnElements({
									variables: {
										input: {
											contentNodeId: nodeId,
										},
									},
									onCompleted: () => {
										toast("Aktive Learnstates wurden erfolgreich gelöscht", {
											type: "success",
										});
									},
								});
							}
						},
					})
				}
				label={"Aktive Learnstates löschen"}
			/>
		</div>
	);
};

import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { NodeTabsTop_TreeNodeFragment$key } from "../../../../../__generated__/NodeTabsTop_TreeNodeFragment.graphql";

const TREE_NODE_FRAGMENT = graphql`
	fragment NodeTabsTop_TreeNodeFragment on TreeNode {
		id
		hasBeenPublishedOnce
		typeDefinition {
			definitionType
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: NodeTabsTop_TreeNodeFragment$key;
}

export const NodeTabsTop = ({ treeNodeFragmentRef }: OwnProps) => {
	const node = useFragment<NodeTabsTop_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	return node.typeDefinition.definitionType === "content" && node.hasBeenPublishedOnce ? (
		<div className="mb-3 text-orange-500">
			<i className="pi pi-exclamation-triangle mr-2" />
			Dieses Modul wurde bereits veröffentlicht. Bei Änderungen (z. B. der Konfiguration von
			Bedingungen oder dem Hinzufügen von Elementen) werden alle zu diesem Modul gehörenden
			aktiven Lernzustände beim Veröffentlichungsvorgang gelöscht: Nutzer, die sich innerhalb
			des gestarteten Moduls befinden, müssen dieses erneut beginnen. Abgeschlossene Module
			bleiben hiervon unberührt.
		</div>
	) : null;
};

/**
 * @generated SignedSource<<32cf96780d1c40d74687dae373c9884c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type ContentKind = "ELearning" | "%future added value";
export type ContentSubmissionStatus = "active" | "passed" | "failed" | "%future added value";
export type RewardKind = "Certificate" | "Email" | "PacPoints" | "PharmaPoints" | "MakeFachberater" | "Points" | "%future added value";
export type RewardResultKind = "CertificateRewardAlreadyRewardedResult" | "CertificateRewardWasPossibleResult" | "CertificateRewardWasNotPossibleResult" | "EmailRewardAlreadyRewardedResult" | "EmailRewardWasPossibleResult" | "EmailRewardWasNotPossibleResult" | "PacPointsRewardAlreadyRewardedResult" | "PacPointsRewardWasPossibleResult" | "PacPointsRewardWasNotPossibleResult" | "PharmaPointsRewardAlreadyRewardedResult" | "PharmaPointsRewardWasPossibleResult" | "PharmaPointsRewardWasNotPossibleResult" | "MakeFachberaterRewardAlreadyRewardedResult" | "FachberaterWasAwarded" | "MakeFachberaterRewardWasNotPossibleResult" | "PointsRewardAlreadyRewardedResult" | "PointsRewardWasPossibleResult" | "PointsRewardWasNotPossibleResult" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserContentSubmissionDialog_QueryFragment$data = {
  readonly Admin: {
    readonly UsersContentSubmission: {
      readonly ContentSubmissions: {
        readonly __id: string;
        readonly pageInfo: {
          readonly endCursor: string | null;
          readonly hasPreviousPage: boolean;
          readonly hasNextPage: boolean;
          readonly startCursor: string | null;
        };
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly startedAt: string;
            readonly lastUpdated: string | null;
            readonly definition: {
              readonly status: ContentSubmissionStatus;
              readonly rewardResults?: ReadonlyArray<{
                readonly kind: RewardResultKind;
                readonly rewardKind: RewardKind;
                readonly rewardId?: string;
              }>;
              readonly contentKind?: ContentKind;
            };
            readonly learnOpportunity: {
              readonly root: {
                readonly structureDefinition: {
                  readonly title: string;
                  readonly extension?: {
                    readonly configConsequences?: {
                      readonly restartTriesCount: number | null;
                    };
                  };
                };
              } | null;
              readonly structureDefinition: {
                readonly title: string;
              };
            } | null;
          };
        } | null> | null;
      };
    };
  };
  readonly " $fragmentType": "UserContentSubmissionDialog_QueryFragment";
};
export type UserContentSubmissionDialog_QueryFragment = UserContentSubmissionDialog_QueryFragment$data;
export type UserContentSubmissionDialog_QueryFragment$key = {
  readonly " $data"?: UserContentSubmissionDialog_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserContentSubmissionDialog_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Admin",
  "UsersContentSubmission",
  "ContentSubmissions"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rewardKind",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "contentNodeId"
    },
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./UserContentSubmissionDialog_Refetch.graphql')
    }
  },
  "name": "UserContentSubmissionDialog_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UsersContentSubmissionAdminSchema",
          "kind": "LinkedField",
          "name": "UsersContentSubmission",
          "plural": false,
          "selections": [
            {
              "alias": "ContentSubmissions",
              "args": [
                {
                  "kind": "Variable",
                  "name": "contentId",
                  "variableName": "contentNodeId"
                },
                {
                  "kind": "Variable",
                  "name": "userId",
                  "variableName": "userId"
                }
              ],
              "concreteType": "ContentSubmissionsConnection",
              "kind": "LinkedField",
              "name": "__UserContentSubmissionDialog_ContentSubmissions_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasPreviousPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startCursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentSubmissionsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ContentSubmission",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "startedAt",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "lastUpdated",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "definition",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "status",
                              "storageKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": null,
                                  "kind": "LinkedField",
                                  "name": "rewardResults",
                                  "plural": true,
                                  "selections": [
                                    (v1/*: any*/),
                                    (v2/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "type": "PassedELearningContentSubmissionDefinition",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "contentKind",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": null,
                                  "kind": "LinkedField",
                                  "name": "rewardResults",
                                  "plural": true,
                                  "selections": [
                                    (v1/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "rewardId",
                                      "storageKey": null
                                    },
                                    (v2/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "type": "FailedELearningContentSubmissionDefinition",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "LearnOpportunityV2",
                          "kind": "LinkedField",
                          "name": "learnOpportunity",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "LearnOpportunityV2",
                              "kind": "LinkedField",
                              "name": "root",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": null,
                                  "kind": "LinkedField",
                                  "name": "structureDefinition",
                                  "plural": false,
                                  "selections": [
                                    (v3/*: any*/),
                                    {
                                      "kind": "InlineFragment",
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": null,
                                          "kind": "LinkedField",
                                          "name": "extension",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "kind": "InlineFragment",
                                              "selections": [
                                                {
                                                  "alias": null,
                                                  "args": null,
                                                  "concreteType": "InfectopharmTreeConfigConsequences",
                                                  "kind": "LinkedField",
                                                  "name": "configConsequences",
                                                  "plural": false,
                                                  "selections": [
                                                    {
                                                      "alias": null,
                                                      "args": null,
                                                      "kind": "ScalarField",
                                                      "name": "restartTriesCount",
                                                      "storageKey": null
                                                    }
                                                  ],
                                                  "storageKey": null
                                                }
                                              ],
                                              "type": "LearnOpportunityRootExtensionImpl",
                                              "abstractKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "type": "LearnOpportunityRootStructureDefinition",
                                      "abstractKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "structureDefinition",
                              "plural": false,
                              "selections": [
                                (v3/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__id",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "30156e1d3b18350d1628427f40998765";

export default node;

import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "../Store";

export interface Core {
	arrayOfEditedForms: Array<string>;
}

const CoreSlice = createSlice({
	name: "core",
	initialState: {
		arrayOfEditedForms: [] as Array<string>,
	},
	reducers: {
		addEditedFormToEditedFormsArray: (state, action: PayloadAction<{ form: string }>) => {
			const isFormInArray = state.arrayOfEditedForms.includes(action.payload.form);
			if (!isFormInArray) {
				state.arrayOfEditedForms.push(action.payload.form);
			}
		},
		removeEditedFormFromEditedFormsArray: (state, action: PayloadAction<{ form: string }>) => {
			const index = state.arrayOfEditedForms.indexOf(action.payload.form);
			state.arrayOfEditedForms.splice(index, 1);
		},
		resetArrayOfEditedForms: (state) => {
			state.arrayOfEditedForms = [];
		},
	},
});

export const {
	addEditedFormToEditedFormsArray,
	removeEditedFormFromEditedFormsArray,
	resetArrayOfEditedForms,
} = CoreSlice.actions;
export const CoreSliceReducer = CoreSlice.reducer;

const selectCoreSlice = (state: ReduxState) => state.core;

export const selectArrayOfEditedForms = createSelector(
	selectCoreSlice,
	(state) => state.arrayOfEditedForms,
);

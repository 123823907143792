import { E_LEARNING_I18N_KEY, E_LEARNING_I18N_MAP } from "../../../lms-admin-impl/i18n/tree.i18n";
import { EXTEND_ELEMENT_TYPE_TRANSLATIONS } from "../../../lms-admin-impl/i18n/extendElementType.i18n";

export const ELEMENT_TYPE_TRANSLATIONS: { [key: string]: string } = {
	text: "Text",
	file: E_LEARNING_I18N_MAP(E_LEARNING_I18N_KEY.file),
	video: "Video",
	multipleChoice: E_LEARNING_I18N_MAP(E_LEARNING_I18N_KEY.multipleChoice),
	podcast: "Podcast",
	...EXTEND_ELEMENT_TYPE_TRANSLATIONS,
};

/**
 * @generated SignedSource<<1db25e701fb20290810bbf1fdec204f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteChildNodeButton_TreeNodeFragment$data = {
  readonly id: string;
  readonly hasBeenPublishedOnce: boolean;
  readonly rootId: string;
  readonly " $fragmentType": "DeleteChildNodeButton_TreeNodeFragment";
};
export type DeleteChildNodeButton_TreeNodeFragment = DeleteChildNodeButton_TreeNodeFragment$data;
export type DeleteChildNodeButton_TreeNodeFragment$key = {
  readonly " $data"?: DeleteChildNodeButton_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteChildNodeButton_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteChildNodeButton_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasBeenPublishedOnce",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rootId",
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "09bdac1a95da99ea162dd5a7c8a1a3a9";

export default node;

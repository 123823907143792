import { Suspense } from "react";
import { TreeStatesSearchContainer } from "../containers/TreeStatesSearchContainer";
import { BaseScreen } from "./BaseScreen";
import { TreeStatesTable } from "../components/learn/treestatestable/TreeStatesTable";

export const TreeStatesScreen = () => {
	return (
		<BaseScreen>
			<TreeStatesSearchContainer>
				<Suspense fallback={<div>Lade...</div>}>
					<TreeStatesTable />
				</Suspense>
			</TreeStatesSearchContainer>
		</BaseScreen>
	);
};

/**
 * @generated SignedSource<<513ee195a17effb5c160f9b69eab6587>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProjectExtendStagingButtons_ResetElementPoolsMutation$variables = {};
export type ProjectExtendStagingButtons_ResetElementPoolsMutationVariables = ProjectExtendStagingButtons_ResetElementPoolsMutation$variables;
export type ProjectExtendStagingButtons_ResetElementPoolsMutation$data = {
  readonly Admin: {
    readonly Elementspool: {
      readonly ResetStagingElementPools: {
        readonly clientMutationId: string | null;
      } | null;
    };
  };
};
export type ProjectExtendStagingButtons_ResetElementPoolsMutationResponse = ProjectExtendStagingButtons_ResetElementPoolsMutation$data;
export type ProjectExtendStagingButtons_ResetElementPoolsMutation = {
  variables: ProjectExtendStagingButtons_ResetElementPoolsMutationVariables;
  response: ProjectExtendStagingButtons_ResetElementPoolsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ElementspoolAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Elementspool",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "input",
                "value": {}
              }
            ],
            "concreteType": "ResetStagingElementPoolsPayload",
            "kind": "LinkedField",
            "name": "ResetStagingElementPools",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": "ResetStagingElementPools(input:{})"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectExtendStagingButtons_ResetElementPoolsMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProjectExtendStagingButtons_ResetElementPoolsMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "36b3d9d936085dcb579b8e4c4bd5a2eb",
    "id": null,
    "metadata": {},
    "name": "ProjectExtendStagingButtons_ResetElementPoolsMutation",
    "operationKind": "mutation",
    "text": "mutation ProjectExtendStagingButtons_ResetElementPoolsMutation {\n  Admin {\n    Elementspool {\n      ResetStagingElementPools(input: {}) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5732e44c823cd28d9f9216b93b5c926a";

export default node;

export const GLOBAL_USER_STATUS_CONTENT_TRANSLATIONS: { [key: string]: string } = {
	initialValidation: "Initiale Freischaltung",
	changeInProgress: "Validierung nach Änderung",
	doccheck: "DocCheck",
	inactive: "Benutzer validiert, aber inaktiv",
	efnNeeded: "Benutzer validiert (Aktiv oder Inaktiv) und Arzt, aber keine EFN",
	active: "Benutzer validiert und hat alle Daten",
};

export const GLOBAL_USER_STATUS_TREE_TRANSLATIONS: { [key: string]: string } = {
	initialValidation: "Initiale Freischaltung",
	changeInProgress: "Validierung nach Änderung",
	inactive: "Benutzer validiert, aber inaktiv",
	efnNeeded: "Benutzer validiert (Aktiv oder Inaktiv) und Arzt, aber keine EFN",
	active: "Benutzer validiert und hat alle Daten",
};

export const GLOBAL_USER_ROLES_TRANSLATIONS: { [key: string]: string } = {
	Doctor: "Arzt",
	Apothecary: "Apotheker",
	Doccheck: "DocCheck",
	Dentist: "Zahnarzt",
	AlliedHealthProfessionals: "Angehöriger anderer Heilberufes",
};

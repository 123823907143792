import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { StatusColumn_ContentSubmissionFragment$key } from "../../../../__generated__/StatusColumn_ContentSubmissionFragment.graphql";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment StatusColumn_ContentSubmissionFragment on ContentSubmission {
		definition {
			status
		}
	}
`;

interface OwnProps {
	contentSubmissionFragmentRef: StatusColumn_ContentSubmissionFragment$key;
}

export const StatusColumn = ({ contentSubmissionFragmentRef }: OwnProps) => {
	const contentSubmission = useFragment<StatusColumn_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	return <div>{contentSubmission.definition.status}</div>;
};

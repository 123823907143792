import { useFragment, useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
import { SetNodeExtensionsForm_TreeNodeFragment$key } from "../../../__generated__/SetNodeExtensionsForm_TreeNodeFragment.graphql";
import { SetNodeExtensionsForm_SetRootNodeStructureDefinitionExtensionMutation } from "../../../__generated__/SetNodeExtensionsForm_SetRootNodeStructureDefinitionExtensionMutation.graphql";
import { ValidatedField } from "../../../infecto-lms-admin/components/core/form/ValidatedField";
import {
	DefaultIntegerFieldComponent,
	DefaultTextFieldComponent,
} from "../../../infecto-lms-admin/components/core/form/DefaultTextInput";
import { InstructorDropdown } from "./InstructorDropdown";
import { RootContainerCodeMetaFields } from "./RootContainerCodeMetaFields";
import {
	addEditedFormToEditedFormsArray,
	removeEditedFormFromEditedFormsArray,
} from "../../../infecto-lms-admin/redux/slices/CoreSlice";
import { useTypedDispatch } from "../../../infecto-lms-admin/redux/Store";
import { SetNodeExtensionsForm_SetChildNodeTypeDefinitionExtensionMutation } from "../../../__generated__/SetNodeExtensionsForm_SetChildNodeTypeDefinitionExtensionMutation.graphql";
import { Divider } from "primereact/divider";
import { VNumbersEditor } from "../../../infecto-lms-admin/components/form/VNumbersEditor";

const TREE_NODE_FRAGMENT = graphql`
	fragment SetNodeExtensionsForm_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			definitionType
			... on RootStructureDefinition {
				extension {
					... on RootExtensionImpl {
						vNumbers {
							number
							from
							to
							password
						}
						category
						certifier
						certifierRef
						location
						internalCode
						signatureFile
						lengthInSeconds
						lengthInSecondsOverride
					}
				}
			}
		}
		typeDefinition {
			... on ELearningContentTypeDefinition {
				extension {
					... on ContentExtensionImpl {
						lengthInSecondsOverride
					}
				}
			}
		}
		...VNumbersEditor_TreeNodeFragment
	}
`;

const SET_ROOT_NODE_STRUCTURE_DEFINITION_EXTENSION_MUTATION = graphql`
	mutation SetNodeExtensionsForm_SetRootNodeStructureDefinitionExtensionMutation(
		$input: SetRootNodeStructureDefinitionExtensionInput!
	) {
		Admin {
			Tree {
				setRootNodeStructureDefinitionExtension(input: $input) {
					node {
						...SetNodeExtensionsForm_TreeNodeFragment
						...TreeContainer_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const SET_CHILD_NODE_TYPE_DEFINITION_EXTENSION_MUTATION = graphql`
	mutation SetNodeExtensionsForm_SetChildNodeTypeDefinitionExtensionMutation(
		$input: SetChildNodeTypeDefinitionExtensionInput!
	) {
		Admin {
			Tree {
				setChildNodeTypeDefinitionExtension(input: $input) {
					node {
						...SetNodeExtensionsForm_TreeNodeFragment
						...TreeContainer_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: SetNodeExtensionsForm_TreeNodeFragment$key;
}

interface FormState {
	internalContainerCode?: string;
	certifier?: string;
	certifierRef?: string;
	drupalQuizId?: number;
	lengthOverride?: number;
	category?: string;
	location?: string;
	signatureFile?: string;
}

export const SetNodeExtensionsForm = ({ treeNodeFragmentRef }: OwnProps) => {
	const node = useFragment<SetNodeExtensionsForm_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const isRoot = node.structureDefinition.definitionType === "root";

	const dispatch = useTypedDispatch();

	const [setRootNodeStructureDefinitionExtension, isSettingRootNodeStructureDefinitionExtension] =
		useMutation<SetNodeExtensionsForm_SetRootNodeStructureDefinitionExtensionMutation>(
			SET_ROOT_NODE_STRUCTURE_DEFINITION_EXTENSION_MUTATION,
		);

	const [setChildNodeTypeDefinitionExtension, isSettingChildNodeTypeDefinitionExtension] =
		useMutation<SetNodeExtensionsForm_SetChildNodeTypeDefinitionExtensionMutation>(
			SET_CHILD_NODE_TYPE_DEFINITION_EXTENSION_MUTATION,
		);

	const formId = "SetNodeExtensionsForm";

	const onFormSubmit = (setOnSubmit: () => void) => {
		formik.setTouched({});
		setOnSubmit();
		dispatch(removeEditedFormFromEditedFormsArray({ form: formId }));
	};

	const formik = useFormik<FormState>({
		enableReinitialize: true,
		initialValues: {
			internalContainerCode: node?.structureDefinition.extension?.internalCode || undefined,
			certifier: node?.structureDefinition.extension?.certifier || undefined,
			certifierRef: node?.structureDefinition.extension?.certifierRef || undefined,
			lengthOverride:
				((isRoot
					? node?.structureDefinition.extension?.lengthInSecondsOverride
					: node?.typeDefinition.extension?.lengthInSecondsOverride) || 0) / 60,
			category: node?.structureDefinition.extension?.category || undefined,
			location: node?.structureDefinition.extension?.location || undefined,
			signatureFile: node?.structureDefinition.extension?.signatureFile || undefined,
		},
		onSubmit: (values, { setSubmitting }) => {
			if (isRoot) {
				setRootNodeStructureDefinitionExtension({
					variables: {
						input: {
							nodeId: node.id,
							internalContainerCode: values.internalContainerCode,
							certifier: values.certifier,
							certifierRef: values.certifierRef,
							lengthOverride: values.lengthOverride
								? values.lengthOverride * 60
								: undefined,
							signatureFileRef: null,
							containerCategory: values.category,
							containerLocation: values.location,
						},
					},
					onCompleted: () => {
						onFormSubmit(() => {
							setSubmitting(false);
						});
					},
				});
			} else {
				setChildNodeTypeDefinitionExtension({
					variables: {
						input: {
							nodeId: node.id,
							lengthOverride: values.lengthOverride
								? values.lengthOverride * 60
								: undefined,
						},
					},
					onCompleted: () => {
						onFormSubmit(() => {
							setSubmitting(false);
						});
					},
				});
			}
		},
	});

	return (
		<Card className="mb-2">
			<h2>Zusätzliche Daten(Komplett Automatisiert)</h2>
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				{isRoot && <RootContainerCodeMetaFields formId={formId} formik={formik} />}

				{isRoot && (
					<>
						<Divider className={"my-5"} />
						<VNumbersEditor treeNodeFragmentRef={node} />
						<Divider className={"my-5"} />
					</>
				)}

				{isRoot && (
					<ValidatedField<FormState, string>
						name={"certifierRef"}
						label={"Wissenschaftlicher Leiter"}
						helpText={
							"Der Lehrende der auf dem Zertifikat erscheint mitsamt Namen, Signatur und Unterschrift."
						}
						component={InstructorDropdown}
						onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
						formikConfig={formik}
					/>
				)}

				{isRoot && (
					<ValidatedField<FormState, string>
						name={"category"}
						label={"Kategorie"}
						helpText={"Kategorie des Inhaltes für das Zertifikat."}
						component={DefaultTextFieldComponent}
						onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
						formikConfig={formik}
					/>
				)}

				{isRoot && (
					<ValidatedField<FormState, number>
						name={"drupalQuizId"}
						disabled={true}
						label={"Drupal ID"}
						component={DefaultIntegerFieldComponent}
						onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
						formikConfig={formik}
					/>
				)}

				<ValidatedField<FormState, number>
					name={"lengthOverride"}
					label={"Länge (in min) überschreiben"}
					helpText={
						"Wenn im Frontend statt der eigentlichen Länge des Moduls eine andere Länge angezeigt werden soll, nutzen Sie dieses Feld. Die Länge ist in Minuten einzutragen."
					}
					component={({ fieldName, fieldValue, updateField, isValid, disabled }) => (
						<InputNumber
							id={fieldName}
							name={fieldName}
							value={fieldValue}
							onChange={(e) => {
								updateField(e.value || undefined);
								dispatch(addEditedFormToEditedFormsArray({ form: formId }));
							}}
							disabled={disabled}
							className={classNames({ "p-invalid": !isValid })}
							step={1}
							min={0}
						/>
					)}
					formikConfig={formik}
				/>

				<Button
					disabled={
						Object.entries(formik.touched).length === 0 ||
						isSettingRootNodeStructureDefinitionExtension ||
						isSettingChildNodeTypeDefinitionExtension
					}
					type="submit"
					label="Speichern"
					className="p-mt-2"
				/>
			</form>
		</Card>
	);
};

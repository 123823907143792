/**
 * @generated SignedSource<<1552139d1421b1e38b36acdeac0b0414>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RewardTreeConfigType = "RewardTree_AfterDateTimeDoNot" | "RewardTree_DoNot" | "RewardTree_IfRepeatedDoNot" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RewardTreeConfigEditor_TreeNodeFragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly rewardConfigs?: ReadonlyArray<{
      readonly id: string;
      readonly configType: RewardTreeConfigType;
      readonly dateTime?: string;
      readonly " $fragmentSpreads": FragmentRefs<"NotAfterDateTimeRewardConfigForm_AfterDateTimeDoNotRewardTreeConfigFragment">;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"NotAfterDateTimeRewardConfigForm_TreeNodeFragment">;
  readonly " $fragmentType": "RewardTreeConfigEditor_TreeNodeFragment";
};
export type RewardTreeConfigEditor_TreeNodeFragment = RewardTreeConfigEditor_TreeNodeFragment$data;
export type RewardTreeConfigEditor_TreeNodeFragment$key = {
  readonly " $data"?: RewardTreeConfigEditor_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RewardTreeConfigEditor_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RewardTreeConfigEditor_TreeNodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "rewardConfigs",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "configType",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dateTime",
                      "storageKey": null
                    }
                  ],
                  "type": "AfterDateTimeDoNotRewardTreeConfig",
                  "abstractKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "NotAfterDateTimeRewardConfigForm_AfterDateTimeDoNotRewardTreeConfigFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotAfterDateTimeRewardConfigForm_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "39a6f5ead0bd254a857d02a941b9bf8c";

export default node;

import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ContentColumn_FeedbackFragment$key } from "../../../__generated__/ContentColumn_FeedbackFragment.graphql";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../lms-admin-impl/i18n/tree.i18n";

const FEEDBACK_FRAGMENT = graphql`
	fragment ContentColumn_FeedbackFragment on FeedbackV2 {
		learnOpportunity {
			structureDefinition {
				title
			}
		}
	}
`;

interface OwnProps {
	feedbackFragmentRef: ContentColumn_FeedbackFragment$key;
}

export const ContentColumn = ({ feedbackFragmentRef }: OwnProps) => {
	const feedback = useFragment<ContentColumn_FeedbackFragment$key>(
		FEEDBACK_FRAGMENT,
		feedbackFragmentRef,
	);

	return (
		<div>
			{feedback.learnOpportunity?.structureDefinition.title ||
				`Kein ${TREE_I18N_MAP(TREE_I18N_KEY.node)}`}
		</div>
	);
};

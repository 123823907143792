import React from "react";
import { ImportMultipleChoiceElementsButton } from "../components/ImportMultipleChoiceElementsButton";
import { SetNodeExtensionsForm } from "../../app/components/tree/SetNodeExtensionsForm";
import { ImplContextV2 } from "../impl/ImplContextV2";
import { GenerateTestCertificateButton } from "../components/certificate/GenerateTestCertificateButton";
import { ProjectCloneTreeButton } from "../../lms-admin-impl/components/relay/ProjectCloneTreeButton";
import { ProjectCreateTreeButton } from "../../lms-admin-impl/components/relay/ProjectCreateTreeButton";

interface OwnProps {
	children: any;
}

export const ImplContainer = ({ children }: OwnProps) => {
	return (
		<ImplContextV2.Provider
			value={{
				node: {
					createMetaForm: (node) => <SetNodeExtensionsForm treeNodeFragmentRef={node} />,
					createTreeButton: (rootNodesConnectionId: string) => (
						<ProjectCreateTreeButton rootNodesConnectionId={rootNodesConnectionId} />
					),
					cloneTreeButton: (rootNodesConnectionId: string, rootNodeId: string) => (
						<ProjectCloneTreeButton
							rootNodesConnectionId={rootNodesConnectionId}
							className="mr-2"
							rootNodeId={rootNodeId}
						/>
					),
					generateTestCertificateButton: () => (
						<GenerateTestCertificateButton className="mr-2" />
					),
					elements: {
						createElementButtons: (eLearningContentNodeId) => (
							<ImportMultipleChoiceElementsButton
								eLearningContentNodeId={eLearningContentNodeId}
							/>
						),
					},
					isTitleFieldForMultipleChoiceRequired: false,
					attachmentTypes: ["Heft"],
				},
			}}
		>
			{children}
		</ImplContextV2.Provider>
	);
};

/**
 * @generated SignedSource<<808f80b8ff0f3553f4ac5e3c7ef72b0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationTemplatesScreen_NotificationTemplatesQuery$variables = {};
export type NotificationTemplatesScreen_NotificationTemplatesQueryVariables = NotificationTemplatesScreen_NotificationTemplatesQuery$variables;
export type NotificationTemplatesScreen_NotificationTemplatesQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NotificationTemplatesTable_NotificationTemplatesListFragment">;
};
export type NotificationTemplatesScreen_NotificationTemplatesQueryResponse = NotificationTemplatesScreen_NotificationTemplatesQuery$data;
export type NotificationTemplatesScreen_NotificationTemplatesQuery = {
  variables: NotificationTemplatesScreen_NotificationTemplatesQueryVariables;
  response: NotificationTemplatesScreen_NotificationTemplatesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationTemplatesScreen_NotificationTemplatesQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "NotificationTemplatesTable_NotificationTemplatesListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationTemplatesScreen_NotificationTemplatesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationAdminSchema",
            "kind": "LinkedField",
            "name": "Notification",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PersonalizedNotificationTemplatesConnection",
                "kind": "LinkedField",
                "name": "PersonalizedNotificationTemplates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonalizedNotificationTemplatesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PersonalizedNotificationTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "NotificationTemplate",
                            "kind": "LinkedField",
                            "name": "template",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "connection",
                "key": "NotificationTemplatesTable_PersonalizedNotificationTemplates",
                "kind": "LinkedHandle",
                "name": "PersonalizedNotificationTemplates"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NotificationTemplate",
                "kind": "LinkedField",
                "name": "AvailableSystemTemplates",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "variables",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "071c620cb27ea8c5143a4ad406cb545d",
    "id": null,
    "metadata": {},
    "name": "NotificationTemplatesScreen_NotificationTemplatesQuery",
    "operationKind": "query",
    "text": "query NotificationTemplatesScreen_NotificationTemplatesQuery {\n  ...NotificationTemplatesTable_NotificationTemplatesListFragment\n}\n\nfragment NewNotificationTemplateButton_AvailableTemplatesFragment on Query {\n  Admin {\n    Notification {\n      AvailableSystemTemplates {\n        key\n        title\n        message\n        variables\n      }\n    }\n  }\n}\n\nfragment NotificationTemplatesTable_NotificationTemplatesListFragment on Query {\n  Admin {\n    Notification {\n      PersonalizedNotificationTemplates {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            template {\n              key\n              title\n            }\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n  ...NewNotificationTemplateButton_AvailableTemplatesFragment\n}\n"
  }
};
})();

(node as any).hash = "93c9217c9e47c773578138aac7da1dc1";

export default node;

/**
 * @generated SignedSource<<d669af67fecf4a45f6ef0a00ca341992>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "order" | "randomMultipleChoice" | "clozeText" | "podcast" | "multipleChoice" | "video" | "file" | "text" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ElementForm_ElementV2Fragment$data = {
  readonly title: string;
  readonly elementType: ElementTypeV2;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectExtendElementForms_ElementV2Fragment" | "TextElementForm_TextElementV2Fragment" | "FileElementForm_FileElementV2Fragment" | "VideoElementForm_VideoElementFragment" | "MultipleChoiceElementForm_MultipleChoiceElementV2Fragment" | "PodcastElementForm_PodcastElementV2Fragment">;
  readonly " $fragmentType": "ElementForm_ElementV2Fragment";
};
export type ElementForm_ElementV2Fragment = ElementForm_ElementV2Fragment$data;
export type ElementForm_ElementV2Fragment$key = {
  readonly " $data"?: ElementForm_ElementV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElementForm_ElementV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ElementForm_ElementV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TextElementForm_TextElementV2Fragment"
        }
      ],
      "type": "TextElementV2",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FileElementForm_FileElementV2Fragment"
        }
      ],
      "type": "FileElementV2",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VideoElementForm_VideoElementFragment"
        }
      ],
      "type": "VideoElement",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MultipleChoiceElementForm_MultipleChoiceElementV2Fragment"
        }
      ],
      "type": "MultipleChoiceElementV2",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PodcastElementForm_PodcastElementV2Fragment"
        }
      ],
      "type": "PodcastElementV2",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectExtendElementForms_ElementV2Fragment"
    }
  ],
  "type": "ElementV2",
  "abstractKey": "__isElementV2"
};

(node as any).hash = "0ad6640e0f17d87511f903f044c069cd";

export default node;

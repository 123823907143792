import { graphql } from "babel-plugin-relay/macro";
import { OrderList } from "primereact/orderlist";
import { useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useState } from "react";
import { Card } from "primereact/card";
import styled from "styled-components";
import { AttachmentForm } from "./AttachmentForm";
import { CreateAttachmentButton } from "./CreateAttachmentButton";
import { AttachmentEditor_TreeNodeFragment$key } from "../../../../../../__generated__/AttachmentEditor_TreeNodeFragment.graphql";
import { AttachmentEditor_RemoveAttachmentV2Mutation } from "../../../../../../__generated__/AttachmentEditor_RemoveAttachmentV2Mutation.graphql";
import { WarningUnsavedChangesDialog } from "../../../../core/dialog/WarningUnsavedChangesDialog";
import { useTypedSelector } from "../../../../../redux/Store";
import { selectArrayOfEditedForms } from "../../../../../redux/slices/CoreSlice";
import { htmlTagsInTextRemover } from "../../../../../functions/htmlTagsInTextRemover";
import { AttachmentForm_InfectopharmFileAttachmentFragment$key } from "../../../../../../__generated__/AttachmentForm_InfectopharmFileAttachmentFragment.graphql";

const TREE_NODE_FRAGMENT = graphql`
	fragment AttachmentEditor_TreeNodeFragment on TreeNode {
		id
		attachments {
			id
			... on InfectopharmFileAttachment {
				title
				description
				attachmentType
				file {
					id
					name
				}
			}
			...AttachmentForm_InfectopharmFileAttachmentFragment
		}
	}
`;

const REMOVE_ATTACHMENT_MUTATION = graphql`
	mutation AttachmentEditor_RemoveAttachmentV2Mutation($input: RemoveAttachmentInput!) {
		Admin {
			Tree {
				removeAttachment(input: $input) {
					node {
						...AttachmentEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: AttachmentEditor_TreeNodeFragment$key;
}

export const AttachmentEditor = ({ treeNodeFragmentRef }: OwnProps) => {
	const node = useFragment<AttachmentEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const [removeAttachment, isRemovingAttachment] =
		useMutation<AttachmentEditor_RemoveAttachmentV2Mutation>(REMOVE_ATTACHMENT_MUTATION);

	const [currentlyEditingAttachment, setCurrentlyEditingAttachment] = useState<
		AttachmentForm_InfectopharmFileAttachmentFragment$key | undefined
	>();
	const [showDialog, setShowDialog] = useState(false);
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);

	console.log(node.attachments);

	return (
		<Card className="mb-2">
			<h2>Anhänge</h2>
			{currentlyEditingAttachment ? (
				<>
					{showDialog && (
						<WarningUnsavedChangesDialog
							setShowDialog={setShowDialog}
							callback={setCurrentlyEditingAttachment}
							value={undefined}
						/>
					)}
					<AttachmentForm
						nodeId={node.id}
						onBack={() => {
							if (arrayOfEditedForms.length > 0) {
								setShowDialog(true);
							} else {
								setCurrentlyEditingAttachment(undefined);
								setShowDialog(false);
							}
						}}
						setCurrentEditingAttachment={setCurrentlyEditingAttachment}
						attachmentFragmentRef={currentlyEditingAttachment}
						formType={"edit"}
					/>{" "}
				</>
			) : (
				<div>
					<div className="p-buttonset mb-2">
						<CreateAttachmentButton contentNodeId={node.id} />
					</div>
					<OrderListWithoutControls
						dragdrop={false}
						itemTemplate={(item) => {
							return (
								<div
									className="flex align-items-center"
									onDoubleClick={() => {
										setCurrentlyEditingAttachment(item);
									}}
								>
									<span>
										{item.file.name}{" "}
										{item.description
											? ` - ${htmlTagsInTextRemover(
													item.description,
											  ).substring(0, 50)}${
													item.description.length > 50 ? "..." : ""
											  }`
											: ""}
									</span>
									<Button
										disabled={isRemovingAttachment}
										className="ml-auto"
										icon="pi pi-trash"
										onClick={() => {
											removeAttachment({
												variables: {
													input: {
														nodeId: node.id,
														attachmentId: item.id,
													},
												},
											});
										}}
									/>
									<Button
										disabled={isRemovingAttachment}
										className="ml-2"
										icon="pi pi-pencil"
										onClick={() => {
											setCurrentlyEditingAttachment(item);
										}}
									/>
								</div>
							);
						}}
						value={[...(node.attachments || [])]}
					/>
				</div>
			)}
		</Card>
	);
};

const OrderListWithoutControls = styled(OrderList)`
	.p-orderlist-controls {
		display: none;
	}
`;

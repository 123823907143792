import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { RenderConfig } from "../../../infecto-lms-admin/components/core/form/ValidatedField";
import { CertificateTemplateSelectionField_Query } from "../../../__generated__/CertificateTemplateSelectionField_Query.graphql";

const QUERY = graphql`
	query CertificateTemplateSelectionField_Query {
		Admin {
			Certificates {
				CertificateTemplates {
					edges {
						node {
							id
							name
						}
					}
				}
			}
		}
	}
`;

export const CertificateTemplateSelectionField = ({
	isValid,
	fieldName,
	fieldValue,
	updateField,
	disabled,
	onChange,
}: RenderConfig<string>) => {
	const data = useLazyLoadQuery<CertificateTemplateSelectionField_Query>(QUERY, {});
	return (
		<div className="flex">
			<Dropdown
				className={`mr-2 flex-grow-1 w-auto ${classNames({ "p-invalid": !isValid })}`}
				name={fieldName}
				disabled={disabled}
				options={data.Admin.Certificates.CertificateTemplates.edges
					?.map((e) => e!.node!)
					.map((n) => ({
						label: n.name,
						value: n.id,
					}))}
				value={fieldValue}
				onChange={(e) => {
					updateField(e.value);
					if (onChange) {
						onChange();
					}
				}}
			/>
		</div>
	);
};

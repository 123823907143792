import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import graphql from "babel-plugin-relay/macro";
import { ConnectionHandler, useMutation, usePaginationFragment } from "react-relay";
import { NotificationTemplatesTable_Refetch } from "../../../__generated__/NotificationTemplatesTable_Refetch.graphql";
import { NotificationTemplatesTable_NotificationTemplatesListFragment$key } from "../../../__generated__/NotificationTemplatesTable_NotificationTemplatesListFragment.graphql";
import { useDialogLogic } from "../core/dialog/useDialogLogic";
import { NotificationTemplatesTable_DeleteMutation } from "../../../__generated__/NotificationTemplatesTable_DeleteMutation.graphql";
import { NewNotificationTemplateButton } from "./NewNotificationTemplateButton";
import { useNavigate } from "react-router-dom";
import { NOTIFICATION_TEMPLATE_KEYS } from "../../i18n/notifications/i18n";

const NotificationTemplates_FRAGMENT = graphql`
	fragment NotificationTemplatesTable_NotificationTemplatesListFragment on Query
	@refetchable(queryName: "NotificationTemplatesTable_Refetch")
	@argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
		Admin {
			Notification {
				PersonalizedNotificationTemplates(first: $first, after: $after)
					@connection(
						key: "NotificationTemplatesTable_PersonalizedNotificationTemplates"
					) {
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							template {
								key
								title
							}
						}
					}
				}
			}
		}
		...NewNotificationTemplateButton_AvailableTemplatesFragment
	}
`;

const DELETE_MUTATION = graphql`
	mutation NotificationTemplatesTable_DeleteMutation(
		$input: DeletePersonalizedNotificationTemplateInput!
		$connections: [ID!]!
	) {
		Admin {
			Notification {
				deletePersonalizedNotificationTemplate(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;

interface OwnProps {
	notificationTemplatesFragmentRef: NotificationTemplatesTable_NotificationTemplatesListFragment$key;
}

export const NotificationTemplatesTable = ({ notificationTemplatesFragmentRef }: OwnProps) => {
	const navigate = useNavigate();
	const { data, hasPrevious, hasNext, loadPrevious, loadNext } = usePaginationFragment<
		NotificationTemplatesTable_Refetch,
		NotificationTemplatesTable_NotificationTemplatesListFragment$key
	>(NotificationTemplates_FRAGMENT, notificationTemplatesFragmentRef);

	const [deleteNotificationTemplate, isDeleting] =
		useMutation<NotificationTemplatesTable_DeleteMutation>(DELETE_MUTATION);

	const { showDialog, dialogComponent } = useDialogLogic();

	return (
		<>
			{dialogComponent}
			<div className="flex justify-content-end mb-3">
				<NewNotificationTemplateButton availableTemplatesFragmentRef={data} />
			</div>
			<DataTable
				className="mb-3"
				value={
					data.Admin.Notification.PersonalizedNotificationTemplates.edges?.map(
						(b) => b!.node!,
					) as any[]
				}
				emptyMessage={"Keine Vorlagen"}
			>
				<Column
					header="Schlüssel"
					body={(item) => NOTIFICATION_TEMPLATE_KEYS[item.template.key]}
				/>
				<Column header="Titel" field="template.title" />
				<Column
					header="Aktionen"
					style={{ width: "20%" }}
					body={(item) => (
						<>
							<Button
								className="mr-2"
								onClick={() => {
									navigate(`/notification-templates/${item.id}/edit`);
								}}
								icon={"pi pi-pencil"}
							/>

							<Button
								disabled={isDeleting}
								onClick={() => {
									showDialog({
										title: "Benachrichtigungs-Vorlage löschen",
										content:
											"Möchten Sie diese E-Mail-Vorlage wirklich löschen? Das kann nicht rückgängig gemacht werden. Es wird dann die Systemvorlage verwendet, welche eventuell nicht ordentlich funktioniert.",
										dialogCallback: (result) => {
											if (result === "Accept") {
												deleteNotificationTemplate({
													variables: {
														input: {
															ids: [item.id],
														},
														connections: [
															ConnectionHandler.getConnectionID(
																"client:root:Admin:Notification",
																"NotificationTemplatesTable_PersonalizedNotificationTemplates",
															),
														],
													},
												});
											}
										},
									});
								}}
								icon={"pi pi-trash"}
							/>
						</>
					)}
				/>
			</DataTable>

			<div className="flex justify-content-center align-items-center">
				<Button
					disabled={!hasPrevious}
					onClick={() => loadPrevious(20)}
					className="mr-3 p-button-secondary"
				>
					Zurück
				</Button>
				<Button
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={() => loadNext(20)}
				>
					Weiter
				</Button>
			</div>
		</>
	);
};

import { useFragment, useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { Card } from "primereact/card";
import React from "react";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { ProjectAdvertisementForm_TreeNodeFragment$key } from "../../../__generated__/ProjectAdvertisementForm_TreeNodeFragment.graphql";
import { ProjectAdvertisementForm_SetAdvertisementConfigurationV2Mutation } from "../../../__generated__/ProjectAdvertisementForm_SetAdvertisementConfigurationV2Mutation.graphql";
import { ValidatedField } from "../../../infecto-lms-admin/components/core/form/ValidatedField";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../infecto-lms-admin/redux/slices/CoreSlice";
import { useTypedDispatch } from "../../../infecto-lms-admin/redux/Store";

const TREE_NODE_FRAGMENT = graphql`
	fragment ProjectAdvertisementForm_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			... on RootStructureDefinition {
				advertisementConfiguration {
					isFeatured
				}
			}
		}
	}
`;

const SET_ADVERTISEMENT_CONFIGURATION_MUTATION = graphql`
	mutation ProjectAdvertisementForm_SetAdvertisementConfigurationV2Mutation(
		$input: SetAdvertisementConfigurationV2Input!
	) {
		Admin {
			Tree {
				setAdvertisementConfiguration(input: $input) {
					rootNode {
						...ProjectAdvertisementForm_TreeNodeFragment
						...TreeContainer_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface FormState {
	isFeatured: boolean;
}

interface OwnProps {
	treeNodeFragmentRef: ProjectAdvertisementForm_TreeNodeFragment$key;
}

export const ProjectAdvertisementForm = ({ treeNodeFragmentRef }: OwnProps) => {
	const rootNode = useFragment<ProjectAdvertisementForm_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const dispatch = useTypedDispatch();
	const formId = "ProjectIsBasisSeminarForm";
	const [setAdvertisementConfiguration, isSettingAdvertisementConfiguration] =
		useMutation<ProjectAdvertisementForm_SetAdvertisementConfigurationV2Mutation>(
			SET_ADVERTISEMENT_CONFIGURATION_MUTATION,
		);

	const formik = useFormik<FormState>({
		enableReinitialize: true,
		initialValues: {
			isFeatured:
				rootNode.structureDefinition.advertisementConfiguration?.isFeatured || false,
		},
		onSubmit: (values, { setSubmitting }) => {
			setAdvertisementConfiguration({
				variables: {
					input: {
						rootNodeId: rootNode.id,
						isFeatured: values.isFeatured,
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
			setSubmitting(false);
		},
	});

	return (
		<Card className="mb-2">
			<h2>Bewerbung des Moduls</h2>

			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, boolean>
					name={"isFeatured"}
					label={"Ist gefeatured?"}
					component={({ fieldName, fieldValue, updateField }) => {
						return (
							<div>
								<Dropdown
									name={fieldName}
									value={fieldValue}
									options={[
										{
											label: `Ja, ist gefeatured`,
											value: true,
										},
										{
											label: `Nein, nicht gefeatured`,
											value: false,
										},
									]}
									onChange={(e) => {
										updateField(e.value);
										dispatch(
											addEditedFormToEditedFormsArray({ form: formId! }),
										);
									}}
								/>
							</div>
						);
					}}
					formikConfig={formik}
				/>
				<Button
					disabled={
						Object.entries(formik.touched).length === 0 ||
						isSettingAdvertisementConfiguration
					}
					type="submit"
					label="Speichern"
					className="p-mt-2"
				/>
			</form>
		</Card>
	);
};

/**
 * @generated SignedSource<<fbf0a943a1c0cc61865e5d9fb88c74f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditPointPoolScreen_PointPoolFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly description: string | null;
  readonly triggersExport: boolean;
  readonly " $fragmentType": "EditPointPoolScreen_PointPoolFragment";
};
export type EditPointPoolScreen_PointPoolFragment = EditPointPoolScreen_PointPoolFragment$data;
export type EditPointPoolScreen_PointPoolFragment$key = {
  readonly " $data"?: EditPointPoolScreen_PointPoolFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditPointPoolScreen_PointPoolFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditPointPoolScreen_PointPoolFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "triggersExport",
      "storageKey": null
    }
  ],
  "type": "PointPool",
  "abstractKey": null
};

(node as any).hash = "d16b40a06b664ea7588ad6fe5fd32a7e";

export default node;

/**
 * @generated SignedSource<<c7776017d73a3674304d26d8378aa203>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StagingScreenV2_ResetLearnStatesContentSubmissionsAndIssuedCertificatesMutation$variables = {};
export type StagingScreenV2_ResetLearnStatesContentSubmissionsAndIssuedCertificatesMutationVariables = StagingScreenV2_ResetLearnStatesContentSubmissionsAndIssuedCertificatesMutation$variables;
export type StagingScreenV2_ResetLearnStatesContentSubmissionsAndIssuedCertificatesMutation$data = {
  readonly Admin: {
    readonly Staging: {
      readonly cleanUpUserInputRelatedToStaging: {
        readonly clientMutationId: string | null;
      } | null;
    };
  };
};
export type StagingScreenV2_ResetLearnStatesContentSubmissionsAndIssuedCertificatesMutationResponse = StagingScreenV2_ResetLearnStatesContentSubmissionsAndIssuedCertificatesMutation$data;
export type StagingScreenV2_ResetLearnStatesContentSubmissionsAndIssuedCertificatesMutation = {
  variables: StagingScreenV2_ResetLearnStatesContentSubmissionsAndIssuedCertificatesMutationVariables;
  response: StagingScreenV2_ResetLearnStatesContentSubmissionsAndIssuedCertificatesMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StagingAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Staging",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "input",
                "value": {}
              }
            ],
            "concreteType": "CleanUpUserInputRelatedToStagingPayload",
            "kind": "LinkedField",
            "name": "cleanUpUserInputRelatedToStaging",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": "cleanUpUserInputRelatedToStaging(input:{})"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StagingScreenV2_ResetLearnStatesContentSubmissionsAndIssuedCertificatesMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StagingScreenV2_ResetLearnStatesContentSubmissionsAndIssuedCertificatesMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4f8b50f298a56bf401b0635eb391c545",
    "id": null,
    "metadata": {},
    "name": "StagingScreenV2_ResetLearnStatesContentSubmissionsAndIssuedCertificatesMutation",
    "operationKind": "mutation",
    "text": "mutation StagingScreenV2_ResetLearnStatesContentSubmissionsAndIssuedCertificatesMutation {\n  Admin {\n    Staging {\n      cleanUpUserInputRelatedToStaging(input: {}) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ea991a2a5ee880a045beed0edfc28ef7";

export default node;

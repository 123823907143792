/**
 * @generated SignedSource<<4e9eea549176bbeb038bfa6475a39f3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessageDefinitionPagesOrderList_MessageDefinitionFragment$data = {
  readonly id: string;
  readonly pages: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly internalTitle: string;
        readonly " $fragmentSpreads": FragmentRefs<"EditMessageDefinitionPageForm_MessageDefinitionPageFragment">;
      };
    } | null> | null;
  };
  readonly " $fragmentType": "MessageDefinitionPagesOrderList_MessageDefinitionFragment";
};
export type MessageDefinitionPagesOrderList_MessageDefinitionFragment = MessageDefinitionPagesOrderList_MessageDefinitionFragment$data;
export type MessageDefinitionPagesOrderList_MessageDefinitionFragment$key = {
  readonly " $data"?: MessageDefinitionPagesOrderList_MessageDefinitionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessageDefinitionPagesOrderList_MessageDefinitionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "pages"
        ]
      }
    ]
  },
  "name": "MessageDefinitionPagesOrderList_MessageDefinitionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "pages",
      "args": null,
      "concreteType": "MessageDefinitionPagesConnection",
      "kind": "LinkedField",
      "name": "__MessageDefinitionPagesOrderList_pages_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MessageDefinitionPagesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MessageDefinitionPage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "internalTitle",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "EditMessageDefinitionPageForm_MessageDefinitionPageFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MessageDefinition",
  "abstractKey": null
};
})();

(node as any).hash = "50c7b5aba5fda7718a5506e8738c9d0b";

export default node;

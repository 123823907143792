/**
 * @generated SignedSource<<167c92683bde2f7b7dee23cf3ecdf7a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditLandingPageV2Screen_Query$variables = {
  id: string;
  skip: boolean;
};
export type EditLandingPageV2Screen_QueryVariables = EditLandingPageV2Screen_Query$variables;
export type EditLandingPageV2Screen_Query$data = {
  readonly node?: {
    readonly " $fragmentSpreads": FragmentRefs<"EditLandingPageV2Screen_LandingPageV2Fragment">;
  } | null;
};
export type EditLandingPageV2Screen_QueryResponse = EditLandingPageV2Screen_Query$data;
export type EditLandingPageV2Screen_Query = {
  variables: EditLandingPageV2Screen_QueryVariables;
  response: EditLandingPageV2Screen_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditLandingPageV2Screen_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EditLandingPageV2Screen_LandingPageV2Fragment"
                  }
                ],
                "type": "LandingPageV2",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditLandingPageV2Screen_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "urlParam",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LearnOpportunitiesV2Connection",
                    "kind": "LinkedField",
                    "name": "rootNodes",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LearnOpportunitiesV2Edge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LearnOpportunityV2",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "LandingPageV2",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "5d754c4dd94e480a228b7ca056f5992e",
    "id": null,
    "metadata": {},
    "name": "EditLandingPageV2Screen_Query",
    "operationKind": "query",
    "text": "query EditLandingPageV2Screen_Query(\n  $id: ID!\n  $skip: Boolean!\n) {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on LandingPageV2 {\n      ...EditLandingPageV2Screen_LandingPageV2Fragment\n    }\n    id\n  }\n}\n\nfragment EditLandingPageV2Screen_LandingPageV2Fragment on LandingPageV2 {\n  id\n  title\n  description\n  urlParam\n  image {\n    id\n    url\n  }\n  rootNodes {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3e0e4b08ff34b482d347941ad8eaeaa3";

export default node;

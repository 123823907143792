/**
 * @generated SignedSource<<6a6488c9fca2fe91c52a6a4022d8a6df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "order" | "randomMultipleChoice" | "clozeText" | "podcast" | "multipleChoice" | "video" | "file" | "text" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectExtendElementForms_ElementV2Fragment$data = {
  readonly title: string;
  readonly elementType: ElementTypeV2;
  readonly " $fragmentSpreads": FragmentRefs<"RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment">;
  readonly " $fragmentType": "ProjectExtendElementForms_ElementV2Fragment";
};
export type ProjectExtendElementForms_ElementV2Fragment = ProjectExtendElementForms_ElementV2Fragment$data;
export type ProjectExtendElementForms_ElementV2Fragment$key = {
  readonly " $data"?: ProjectExtendElementForms_ElementV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectExtendElementForms_ElementV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectExtendElementForms_ElementV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RandomMultipleChoiceElementForm_RandomMultipleChoiceElementFragment"
    }
  ],
  "type": "ElementV2",
  "abstractKey": "__isElementV2"
};

(node as any).hash = "7fb4549f2c24afeda7d29292aa036778";

export default node;

import React, { Suspense } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useFragment, useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import {
	addEditedFormToEditedFormsArray,
	removeEditedFormFromEditedFormsArray,
	selectArrayOfEditedForms,
} from "../../../infecto-lms-admin/redux/slices/CoreSlice";
import { useCallbackPrompt } from "../../../infecto-lms-admin/functions/hooks/UseCallBackPrompt";
import { WarningUnsavedChangesDialog } from "../../../infecto-lms-admin/components/core/dialog/WarningUnsavedChangesDialog";
import { ValidatedField } from "../../../infecto-lms-admin/components/core/form/ValidatedField";
import {
	DefaultSwitchComponent,
	DefaultTextFieldComponent,
} from "../../../infecto-lms-admin/components/core/form/DefaultTextInput";
import { EditNewsTickerDefinitionForm_MessageDefinitionFragment$key } from "../../../__generated__/EditNewsTickerDefinitionForm_MessageDefinitionFragment.graphql";
import { EditNewsTickerDefinitionForm_EditMutation } from "../../../__generated__/EditNewsTickerDefinitionForm_EditMutation.graphql";
import { UrlRegexp } from "../../../infecto-lms-admin/functions/url-regexp";
import { useNavigate } from "react-router-dom";
import { useTypedDispatch, useTypedSelector } from "../../../infecto-lms-admin/redux/Store";

const CUSTOMER_MESSAGE_DEFINITION_FRAGMENT = graphql`
	fragment EditNewsTickerDefinitionForm_MessageDefinitionFragment on NewstickerMessage {
		id
		text
		isActive
		forwardingUrl
	}
`;

const EDIT_CORE_MUTATION = graphql`
	mutation EditNewsTickerDefinitionForm_EditMutation($input: EditNewstickerMessageInput!) {
		Admin {
			Newsticker {
				editNewstickerMessage(input: $input) {
					newstickerMessage {
						id
						...EditNewsTickerDefinitionForm_MessageDefinitionFragment
					}
				}
			}
		}
	}
`;

interface FormState {
	text: string;
	forwardingUrl: string;
	isActive: boolean;
}

interface OwnProps {
	newsTickerDefinitionFragmentRef: EditNewsTickerDefinitionForm_MessageDefinitionFragment$key;
}

export const EditNewsTickerDefinitionForm = ({ newsTickerDefinitionFragmentRef }: OwnProps) => {
	const formId = "EditMessageDefinitionForm";

	const navigate = useNavigate();

	const messageDefinition =
		useFragment<EditNewsTickerDefinitionForm_MessageDefinitionFragment$key>(
			CUSTOMER_MESSAGE_DEFINITION_FRAGMENT,
			newsTickerDefinitionFragmentRef,
		);

	const [edit, isEditing] =
		useMutation<EditNewsTickerDefinitionForm_EditMutation>(EDIT_CORE_MUTATION);

	const dispatch = useTypedDispatch();

	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);

	const [showPrompt, confirmNavigation, cancelNavigation, setShowPrompt] = useCallbackPrompt(
		arrayOfEditedForms.length > 0,
	);

	const formik = useFormik<FormState>({
		initialValues: {
			text: messageDefinition.text,
			forwardingUrl: messageDefinition.forwardingUrl ?? "",
			isActive: messageDefinition.isActive ?? false,
		},
		validationSchema: Yup.object().shape({
			text: Yup.string().required("Newsticker Inhalt wird benötigt."),
			forwardingUrl: Yup.string().matches(UrlRegexp, "Ungültige URL Format"),
		}),
		onSubmit: (values, { setSubmitting }) => {
			edit({
				variables: {
					input: {
						id: messageDefinition.id,
						text: values.text,
						forwardingUrl: values.forwardingUrl,
						isActive: values.isActive,
					},
				},
				onCompleted: () => {
					dispatch(removeEditedFormFromEditedFormsArray({ form: formId }));

					navigate(-1);
				},
			});

			setSubmitting(false);
		},
	});

	return (
		<Card>
			<Suspense fallback={<ProgressSpinner />}>
				{!isEditing ? (
					<>
						{" "}
						{showPrompt && (
							<WarningUnsavedChangesDialog
								confirmNavigation={confirmNavigation}
								setShowDialog={setShowPrompt}
								cancelNavigation={cancelNavigation}
							/>
						)}
						<form onSubmit={formik.handleSubmit} className="p-fluid">
							<ValidatedField<FormState, string>
								name={"text"}
								label={"Newsticker Inhalt"}
								required={true}
								onChange={() =>
									dispatch(addEditedFormToEditedFormsArray({ form: formId }))
								}
								component={DefaultTextFieldComponent}
								formikConfig={formik}
							/>

							<ValidatedField<FormState, string>
								name={"forwardingUrl"}
								label={"Newsticker Link"}
								required={false}
								onChange={() =>
									dispatch(addEditedFormToEditedFormsArray({ form: formId }))
								}
								component={DefaultTextFieldComponent}
								formikConfig={formik}
							/>

							<ValidatedField<FormState, boolean>
								name={"isActive"}
								label={"Aktiv"}
								required={false}
								onChange={() =>
									dispatch(addEditedFormToEditedFormsArray({ form: formId }))
								}
								component={DefaultSwitchComponent}
								formikConfig={formik}
							/>

							<div className="flex pt-2">
								<Button
									disabled={false}
									type="submit"
									label={"Speichern"}
									className="mr-5"
								/>
							</div>
						</form>
					</>
				) : (
					<ProgressSpinner />
				)}
			</Suspense>
		</Card>
	);
};

/**
 * @generated SignedSource<<581686f88c07db0c6e0e51a3511df828>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MessageSenderScreen_sendMessagesMutation$variables = {
  messageDefinitionId: string;
  selectAllUsers: boolean;
  selectedUserIds?: ReadonlyArray<string> | null;
};
export type MessageSenderScreen_sendMessagesMutationVariables = MessageSenderScreen_sendMessagesMutation$variables;
export type MessageSenderScreen_sendMessagesMutation$data = {
  readonly Admin: {
    readonly Messaging: {
      readonly sendMessages: {
        readonly clientMutationId: string | null;
      } | null;
    };
  };
};
export type MessageSenderScreen_sendMessagesMutationResponse = MessageSenderScreen_sendMessagesMutation$data;
export type MessageSenderScreen_sendMessagesMutation = {
  variables: MessageSenderScreen_sendMessagesMutationVariables;
  response: MessageSenderScreen_sendMessagesMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "messageDefinitionId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "selectAllUsers"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "selectedUserIds"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessagingAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Messaging",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "definitionRef",
                    "variableName": "messageDefinitionId"
                  },
                  {
                    "kind": "Variable",
                    "name": "selectAllUsers",
                    "variableName": "selectAllUsers"
                  },
                  {
                    "kind": "Variable",
                    "name": "selectUserIds",
                    "variableName": "selectedUserIds"
                  }
                ],
                "kind": "ObjectValue",
                "name": "input"
              }
            ],
            "concreteType": "SendMessageDefinitionPayload",
            "kind": "LinkedField",
            "name": "sendMessages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageSenderScreen_sendMessagesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MessageSenderScreen_sendMessagesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "15982b1cca18f7d0a43f34e59e4dca66",
    "id": null,
    "metadata": {},
    "name": "MessageSenderScreen_sendMessagesMutation",
    "operationKind": "mutation",
    "text": "mutation MessageSenderScreen_sendMessagesMutation(\n  $messageDefinitionId: ID!\n  $selectAllUsers: Boolean!\n  $selectedUserIds: [ID!]\n) {\n  Admin {\n    Messaging {\n      sendMessages(input: {definitionRef: $messageDefinitionId, selectAllUsers: $selectAllUsers, selectUserIds: $selectedUserIds}) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "37700cc03b37e53fd233ce2954ac26f0";

export default node;

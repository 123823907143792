import { EmailTesterFeedbackEmailForm } from "../../../infecto-lms-admin/components/email-tester/EmailTesterFeedbackEmailForm";
import { EmailTesterInformExpirationOrRemindRetryForm } from "../../../infecto-lms-admin/components/email-tester/EmailTesterInformExpirationOrRemindRetryForm";
import { ModalType } from "../../../infecto-lms-admin/components/emails/EmailTemplatesTable";
import { EmailTesterRemindUserToFinishBasisseminarNode } from "./EmailTesterRemindUserToFinishBasisseminarNode";

interface ModalContainerProps {
	modalType: ModalType | null;
	closeModal: () => void;
}

export const EmailTesterFormDialogsContainer = ({
	modalType,
	closeModal,
}: ModalContainerProps): JSX.Element | null => {
	if (!modalType) return null;
	switch (modalType.type) {
		case "admin-feedback-v2":
			return (
				<EmailTesterFeedbackEmailForm modalVisible={true} setModalVisible={closeModal} />
			);
		case "inform-expiration-v2":
			return (
				<EmailTesterInformExpirationOrRemindRetryForm
					templateId={modalType.templateId}
					modalVisible={true}
					setModalVisible={closeModal}
				/>
			);
		case "remind-retry-v2":
			return (
				<EmailTesterInformExpirationOrRemindRetryForm
					templateId={modalType.templateId}
					modalVisible={true}
					setModalVisible={closeModal}
				/>
			);
		case "remind-basisseminar-exam":
			return (
				<EmailTesterRemindUserToFinishBasisseminarNode
					modalVisible={true}
					setModalVisible={closeModal}
				/>
			);
		default:
			return null;
	}
};

import { graphql } from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { useFragment, useMutation } from "react-relay";
import { CreateFachberaterRewardButton_AddEmailRewardV2Mutation } from "../../../../__generated__/CreateFachberaterRewardButton_AddEmailRewardV2Mutation.graphql";
import { CreateFachberaterRewardButton_TreeNodeFragment$key } from "../../../../__generated__/CreateFachberaterRewardButton_TreeNodeFragment.graphql";
import { CreateFachberaterRewardButton_CertificateTemplatesConnectionV2Fragment$key } from "../../../../__generated__/CreateFachberaterRewardButton_CertificateTemplatesConnectionV2Fragment.graphql";

const CERTIFICATE_TEMPLATES_CONNECTION_FRAGMENT = graphql`
	fragment CreateFachberaterRewardButton_CertificateTemplatesConnectionV2Fragment on CertificateTemplatesConnection {
		edges {
			node {
				id
			}
		}
	}
`;
const MUTATION = graphql`
	mutation CreateFachberaterRewardButton_AddEmailRewardV2Mutation(
		$input: AddFachberaterRewardInput!
	) {
		Admin {
			Tree {
				addFachberaterReward(input: $input) {
					contentNode {
						...RewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const FRAGMENT = graphql`
	fragment CreateFachberaterRewardButton_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ELearningContentTypeDefinition {
				rewards {
					id
					kind
				}
			}
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: CreateFachberaterRewardButton_TreeNodeFragment$key;
	certificateTemplatesConnectionFragmentRef: CreateFachberaterRewardButton_CertificateTemplatesConnectionV2Fragment$key;
}

export const CreateFachberaterRewardButton = ({
	treeNodeFragmentRef,
	certificateTemplatesConnectionFragmentRef,
}: OwnProps) => {
	const node = useFragment<CreateFachberaterRewardButton_TreeNodeFragment$key>(
		FRAGMENT,
		treeNodeFragmentRef,
	);
	const certificateTemplatesConnection =
		useFragment<CreateFachberaterRewardButton_CertificateTemplatesConnectionV2Fragment$key>(
			CERTIFICATE_TEMPLATES_CONNECTION_FRAGMENT,
			certificateTemplatesConnectionFragmentRef,
		);
	const [addReward, isAdding] =
		useMutation<CreateFachberaterRewardButton_AddEmailRewardV2Mutation>(MUTATION);
	const hasPacPointsReward = node.typeDefinition.rewards
		?.map((r) => r.kind)
		.includes("PacPoints");

	return (
		<Button
			className={"mr-2"}
			disabled={isAdding || hasPacPointsReward}
			tooltip={
				hasPacPointsReward
					? "Die PAC Punkte Belohnung und Mache zum Fachberater Belohnung können nicht zusammen verwendet werden"
					: "Zum Fachberater machen"
			}
			tooltipOptions={{ position: "top", showOnDisabled: true }}
			icon="pi pi-user-plus"
			onClick={() => {
				addReward({
					variables: {
						input: {
							contentNodeId: node.id,
							certificateTemplateId:
								certificateTemplatesConnection.edges![0]!.node.id,
						},
					},
				});
			}}
		/>
	);
};

import { Button } from "primereact/button";
import { useState } from "react";
import { AttachmentForm } from "./AttachmentForm";

interface OwnProps {
	contentNodeId: string;
}

export const CreateAttachmentButton = ({ contentNodeId }: OwnProps) => {
	const [isOpen, setOpen] = useState(false);

	return isOpen ? (
		<AttachmentForm
			nodeId={contentNodeId}
			attachmentFragmentRef={null}
			onBack={() => setOpen(false)}
			formType={"add"}
		/>
	) : (
		<Button
			className={"mr-2"}
			tooltip={"Datei anhängen"}
			icon="pi pi-paperclip"
			onClick={() => {
				setOpen(true);
			}}
		/>
	);
};

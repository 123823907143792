import { PrivateRouteDefinition } from "../../infecto-lms-admin/components/core/model/PrivateRouteDefinition";
import { PointPoolsScreen } from "../../infecto-lms-admin/screens/PointPoolsScreen";
import { EditPointPoolScreen } from "../../infecto-lms-admin/screens/EditPointPoolScreen";
import { PointsAdditionsScreen } from "../../infecto-lms-admin/screens/PointsAdditionsScreen";

export const POINT_POOLS_PATH = "/point-pools";

export const POINT_POOL_ID_URL_PARAM = ":learnStateId";
export const getEditPointPoolPath = (pointPoolId: string) => {
	return `/point-pools/${pointPoolId}/edit`;
};

export const POINT_ADDITIONS_PATH_V2 = "/points-additions";

export const PointsModuleRoutes: PrivateRouteDefinition[] = [
	{
		path: POINT_POOLS_PATH,
		element: <PointPoolsScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_Points_ReadPoints",
		],
	},
	{
		path: getEditPointPoolPath(POINT_POOL_ID_URL_PARAM),
		element: <EditPointPoolScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_Points_UpdatePoints",
		],
	},
	{
		path: POINT_ADDITIONS_PATH_V2,
		element: <PointsAdditionsScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_Points_ReadPoints",
		],
	},
];

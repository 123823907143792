import { useFormik } from "formik";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ValidatedField } from "../core/form/ValidatedField";
import { DefaultTextFieldComponent } from "../core/form/DefaultTextInput";
import { useFragment, useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { VNumbersEditor_TreeNodeFragment$key } from "../../../__generated__/VNumbersEditor_TreeNodeFragment.graphql";
import { VNumbersEditor_AddVNumberMutation } from "../../../__generated__/VNumbersEditor_AddVNumberMutation.graphql";
import { utcFormatDateTime } from "../core/datetime/DateTimeDisplay";
import { Button } from "primereact/button";
import { VNumbersEditor_UpdateVNumberMutation } from "../../../__generated__/VNumbersEditor_UpdateVNumberMutation.graphql";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { DefaultCalendarComponent } from "../core/form/DefaultCalendarComponent";
import * as Yup from "yup";

const TREE_NODE_FRAGMENT = graphql`
	fragment VNumbersEditor_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			definitionType
			... on RootStructureDefinition {
				extension {
					... on RootExtensionImpl {
						vNumbers {
							number
							from
							to
							password
							active
						}
					}
				}
			}
		}
	}
`;

const ADD_V_NUMBER_MUTATION = graphql`
	mutation VNumbersEditor_AddVNumberMutation(
		$input: AddVNumberRootNodeStructureDefinitionExtensionInput!
	) {
		Admin {
			Tree {
				addVNumberRootNodeStructureDefinitionExtension(input: $input) {
					node {
						id
						...VNumbersEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const UPDATE_V_NUMBER_MUTATION = graphql`
	mutation VNumbersEditor_UpdateVNumberMutation($input: UpdateNodeVNumberInput!) {
		Admin {
			Tree {
				updateNodeVNumber(input: $input) {
					node {
						id
						...VNumbersEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface FormState {
	number: string;
	from: string;
	to: string;
	password: string;
	active: boolean;
}

interface OwnProps {
	treeNodeFragmentRef: VNumbersEditor_TreeNodeFragment$key;
}

const VNRDatesWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const VNRDatePicker = styled.div`
	flex: 1;
`;

type VEditorModalContext = "add" | "edit";

export const VNumbersEditor = (props: OwnProps) => {
	const [modalVisible, setModalVisible] = useState(false);
	const [modalContext, setModalContext] = useState<VEditorModalContext>("add");

	const [addVNumber, isAddingVNumber] =
		useMutation<VNumbersEditor_AddVNumberMutation>(ADD_V_NUMBER_MUTATION);

	const [updateVNumber, isUpdatingVNumber] =
		useMutation<VNumbersEditor_UpdateVNumberMutation>(UPDATE_V_NUMBER_MUTATION);

	const node = useFragment<VNumbersEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		props.treeNodeFragmentRef,
	);

	const vNumbers = node.structureDefinition.extension?.vNumbers;

	const [selectedVNumber, setSelectedVNumber] = useState(vNumbers?.find((vnr) => vnr.active));

	const isRoot = node.structureDefinition.definitionType === "root";

	const emptyInitialValues = {
		number: "",
		from: "",
		to: "",
		password: "",
		active: false,
	};

	const [initialValues, setInitialValues] = useState<FormState>(emptyInitialValues);

	const formik = useFormik<FormState>({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: Yup.object().shape({
			number: Yup.string().required("Das Feld Veranstaltungsnummer wird benötigt."),
			from: Yup.string().required("Das Feld von wird benötigt."),
			to: Yup.string().required("Das Feld bis wird benötigt."),
			password: Yup.string().required("Das Feld Passwort wird benötigt."),
		}),
		onSubmit: (values) => {
			if (isRoot) {
				switch (modalContext) {
					case "add":
						addVNumber({
							variables: {
								input: {
									nodeId: node.id,
									number: values.number,
									from: values.from,
									to: values.to,
									password: values.password,
									isActive: values.active,
								},
							},
							onCompleted: () => {
								toast.success("VNR ist erfolgreich hinzugefügt!");
								setModalVisible(false);
								window.location.reload();
							},
						});
						break;

					case "edit":
						updateVNumber({
							variables: {
								input: {
									nodeId: node.id,
									vNumber: values.number,
									password: values.password,
									isActive: values.active,
								},
							},
							onCompleted: () => {
								toast.success("VNR ist erfolgreich gespeichert!");
								setModalVisible(false);
								window.location.reload();
							},
						});
						break;
					default:
				}
			} else {
				toast.error("VNumbers Editor can only be used on Root Nodes!");
			}
		},
	});

	const vNumberDate = (vNumberDate: string) => {
		return utcFormatDateTime(vNumberDate, false);
	};

	const vNumberActive = (isActive: boolean) => {
		return isActive ? (
			<p style={{ color: "var(--primary-color)" }}>Aktiv</p>
		) : (
			<p>Nicht Aktiv</p>
		);
	};

	const actionBodyTemplate = (rowData: FormState) => {
		return (
			<React.Fragment>
				<Button
					type={"button"}
					icon="pi pi-pencil"
					rounded
					outlined
					disabled={isAddingVNumber || isUpdatingVNumber}
					className="mr-2"
					onClick={() => {
						setInitialValues({
							number: rowData.number,
							from: rowData.from,
							to: rowData.to,
							password: rowData.password,
							active: rowData.active,
						});

						setModalContext("edit");

						setModalVisible(true);
					}}
				/>
			</React.Fragment>
		);
	};

	return (
		<>
			<label>Veranstaltungsnummer (extern)</label>

			<DataTable<any>
				value={vNumbers as any[]}
				selectionMode={"single"}
				selection={selectedVNumber?.active}
				onSelectionChange={(e: any) => setSelectedVNumber(e.value)}
				dataKey="id"
				className={"mt-2 p-0"}
			>
				<Column field="number" header="Veranstaltungsnummer"></Column>
				<Column
					field="active"
					header="Aktiv"
					body={(v) => vNumberActive(v.active)}
				></Column>
				<Column field="from" header="von" body={(v) => vNumberDate(v.from)}></Column>
				<Column field="to" header="bis" body={(v) => vNumberDate(v.to)}></Column>
				<Column field="password" header="Veranstaltung Passwort"></Column>
				<Column body={actionBodyTemplate} exportable={false}></Column>
			</DataTable>

			<Button
				className={"justify-content-center"}
				type={"button"}
				disabled={isAddingVNumber || isUpdatingVNumber}
				onClick={async () => {
					setModalContext("add");
					setInitialValues(emptyInitialValues);
					setModalVisible(true);
				}}
			>
				Veranstaltungsnummer hinzufügen
			</Button>

			{modalVisible && (
				<Dialog
					header={<h3 className={"m-0"}>Veranstaltungsnummer hinzufügen</h3>}
					className="w-8 flex"
					visible={true}
					onHide={() => setModalVisible(false)}
				>
					<div className="mb-2">
						<form onSubmit={formik.handleSubmit} className="p-fluid">
							<label>Veranstaltung ist Aktiv?</label>

							<Checkbox
								className={"ml-2"}
								onChange={(e) => {
									formik.setFieldValue("active", e.checked);
								}}
								checked={formik.values.active}
							/>

							<Divider />

							{modalContext == "add" && (
								<>
									<ValidatedField<FormState, string>
										name={"number"}
										label={"Veranstaltungsnummer"}
										helpText={
											"Eine Nummer die für automatische Exporte verwendet werden kann. Kann nur für den obersten Ordner festgelegt werden."
										}
										component={DefaultTextFieldComponent}
										formikConfig={formik}
									/>

									<VNRDatesWrapper>
										<VNRDatePicker className={"mr-1"}>
											<ValidatedField<FormState, string>
												name={"from"}
												label={"von"}
												helpText={
													"Der Start-Zeitpunkt der 1. Veranstaltung"
												}
												component={DefaultCalendarComponent}
												formikConfig={formik}
											/>
										</VNRDatePicker>

										<VNRDatePicker className={"ml-1"}>
											<ValidatedField<FormState, string>
												name={"to"}
												label={"bis"}
												helpText={"Der End-Zeitpunkt der 1. Veranstaltung"}
												component={DefaultCalendarComponent}
												formikConfig={formik}
											/>
										</VNRDatePicker>
									</VNRDatesWrapper>
								</>
							)}

							<ValidatedField<FormState, string>
								name={"password"}
								label={"Veranstaltung Passwort"}
								helpText={"Ein Passwort für die Punktemeldung an den EIV"}
								component={DefaultTextFieldComponent}
								formikConfig={formik}
							/>
						</form>

						<ButtonsWrapper>
							<LeftButton
								severity="secondary"
								onClick={() => {
									setModalVisible(false);
								}}
							>
								Abbrechen
							</LeftButton>
							<Button
								type={"button"}
								disabled={isAddingVNumber || isUpdatingVNumber || !formik.isValid}
								onClick={async () => {
									await formik.submitForm();
								}}
							>
								Speichern
							</Button>
						</ButtonsWrapper>
					</div>
				</Dialog>
			)}
		</>
	);
};

const ButtonsWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

const LeftButton = styled(Button)`
	margin-right: 10px;
`;

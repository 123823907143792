/**
 * @generated SignedSource<<81646056896a2490d9d4d63ad5cfed1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "order" | "randomMultipleChoice" | "clozeText" | "podcast" | "multipleChoice" | "video" | "file" | "text" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PoolElementForm_ElementV2Fragment$data = {
  readonly title: string;
  readonly elementType: ElementTypeV2;
  readonly " $fragmentSpreads": FragmentRefs<"MultipleChoicePoolElementForm_MultipleChoiceElementV2Fragment">;
  readonly " $fragmentType": "PoolElementForm_ElementV2Fragment";
};
export type PoolElementForm_ElementV2Fragment = PoolElementForm_ElementV2Fragment$data;
export type PoolElementForm_ElementV2Fragment$key = {
  readonly " $data"?: PoolElementForm_ElementV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PoolElementForm_ElementV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PoolElementForm_ElementV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MultipleChoicePoolElementForm_MultipleChoiceElementV2Fragment"
        }
      ],
      "type": "MultipleChoiceElementV2",
      "abstractKey": null
    }
  ],
  "type": "ElementV2",
  "abstractKey": "__isElementV2"
};

(node as any).hash = "0c72e034100e8d2cb9f379a835fe8192";

export default node;

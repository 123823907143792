import { useMatch } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { Card } from "primereact/card";
import {
	getSingleTreeStatePath,
	TREE_STATE_ID_URL_PARAM,
} from "../../app/routes/LearnStatesModuleRoutes";
import { SingleTreeStateScreenScreen_Query } from "../../__generated__/SingleTreeStateScreenScreen_Query.graphql";
import { SingleTreeStateScreen_TreeStateFragment$key } from "../../__generated__/SingleTreeStateScreen_TreeStateFragment.graphql";
import { ProjectUserDescription } from "../../lms-admin-impl/components/relay/ProjectUserDescription";
import { BaseScreen } from "./BaseScreen";
import { DateTimeDisplay } from "../components/core/datetime/DateTimeDisplay";
import { ContentSubmissionsTable } from "../components/learn/contentsubmissionstable/ContentSubmissionsTable";

const QUERY = graphql`
	query SingleTreeStateScreenScreen_Query($id: ID!) {
		node(id: $id) {
			...SingleTreeStateScreen_TreeStateFragment
		}
		...ContentSubmissionsTable_QueryFragment @arguments(treeStateId: $id)
	}
`;

const TREE_STATE_FRAGMENT = graphql`
	fragment SingleTreeStateScreen_TreeStateFragment on TreeState {
		id
		progressPercentage
		rootNode {
			structureDefinition {
				title
			}
		}
		startedAt
		status
		user {
			id
			...ProjectUserDescription_UserFragment
		}
	}
`;

export const SingleTreeStateScreen = () => {
	const match = useMatch(getSingleTreeStatePath(TREE_STATE_ID_URL_PARAM));

	const query = useLazyLoadQuery<SingleTreeStateScreenScreen_Query>(QUERY, {
		id: match?.params.treeStateId!,
	});
	const treeState = useFragment<SingleTreeStateScreen_TreeStateFragment$key>(
		TREE_STATE_FRAGMENT,
		query.node!,
	);

	return (
		<BaseScreen>
			{
				<h1 className="mb-3">
					Lern-Fortschritt für {treeState.rootNode.structureDefinition.title} von{" "}
					<ProjectUserDescription userFragmentRef={treeState.user} />
				</h1>
			}

			<Card
				className="mb-4"
				header={
					<div className="p-2">
						<h3 className="flex align-items-center m-0">Daten</h3>
					</div>
				}
			>
				<div className="p-fluid">
					<div className="field grid">
						<label className="col-fixed font-bold" style={{ width: 150 }}>
							Erstellt
						</label>
						<div className="col">
							<DateTimeDisplay value={treeState.startedAt} />
						</div>
					</div>
					<div className="field grid">
						<label className="col-fixed font-bold" style={{ width: 150 }}>
							Benutzer
						</label>
						<div className="col">
							<ProjectUserDescription userFragmentRef={treeState.user} />
						</div>
					</div>
					<div className="field grid">
						<label className="col-fixed font-bold" style={{ width: 150 }}>
							Status
						</label>
						<div className="col">{treeState.status}</div>
					</div>
					<div className="field grid">
						<label className="col-fixed font-bold" style={{ width: 150 }}>
							Fortschritt
						</label>
						<div className="col">{treeState.progressPercentage}%</div>
					</div>
				</div>
			</Card>

			<Card
				className="mb-4"
				header={
					<div className="p-2">
						<h3 className="flex align-items-center m-0">Einreichungen</h3>
					</div>
				}
			>
				<ContentSubmissionsTable queryFragmentRef={query} />
			</Card>
		</BaseScreen>
	);
};

import { authMiddleware } from "react-relay-network-modern";
import { logout, refreshLogin, selectCurrentAccountId } from "../redux/slices/AuthSlice";
import { ReduxStore } from "../redux/Store";

export const JwtMiddleware = authMiddleware({
	allowEmptyToken: true,
	token: () => ReduxStore.getState().auth.loginData?.accessToken || "",
	tokenRefreshPromise: () => {
		const state = ReduxStore.getState();
		return fetch(`${process.env.REACT_APP_API_BASE}/api/refresh-token`, {
			method: "POST",
			headers: new Headers({ "content-type": "application/json" }),
			body: JSON.stringify({
				refreshToken: state.auth.loginData?.refreshToken,
				accountId: selectCurrentAccountId(state),
			}),
		})
			.then((res) => res.json())
			.then((json) => {
				const token = json.accessToken;

				ReduxStore.dispatch(refreshLogin({ loginData: json }));

				return token;
			})
			.catch(() => {
				ReduxStore.dispatch(logout());
			});
	},
});

import React from "react";
import { Dropdown, DropdownChangeEvent, DropdownProps } from "primereact/dropdown";
import styled from "styled-components";
import { CONFIGS_TRANSLATIONS } from "../../../../../../i18n/config/i18n";

const StyledConfigDropdown = styled(Dropdown)`
	background-color: "#fff";
`;

interface Props<T> extends DropdownProps {
	isPresentational: boolean;
	configOptions?: ConfigDropdownOptions<T>[];
	onChange?: (e: DropdownChangeEvent) => void;
	isLoading?: boolean;
}

export interface ConfigDropdownOptions<T> {
	label: string;
	value: T;
}

export function ConfigDropdown<OptionType>(props: Props<OptionType>) {
	const options = props.configOptions?.map((e) => {
		return {
			// @ts-ignore
			label: `${CONFIGS_TRANSLATIONS[e.value]} ${e.label !== e.value ? e.label : ""}`,
			value: e.value,
		};
	});

	return (
		<StyledConfigDropdown
			{...props}
			className={"w-full"}
			options={options}
			optionLabel="label"
			optionValue="value"
			onChange={(e: DropdownChangeEvent) => {
				props.onChange!(e);
			}}
			placeholder="auswählen..."
			disabled={props.isPresentational || props.isLoading}
		/>
	);
}

/**
 * @generated SignedSource<<829ac2ec39f8cdb1a2efb774165ff6e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CertificatesRewardConfigurationForm_CertificateRewardConfigurationV2Fragment$data = {
  readonly id: string;
  readonly certificateTemplateRef: string;
  readonly " $fragmentType": "CertificatesRewardConfigurationForm_CertificateRewardConfigurationV2Fragment";
};
export type CertificatesRewardConfigurationForm_CertificateRewardConfigurationV2Fragment = CertificatesRewardConfigurationForm_CertificateRewardConfigurationV2Fragment$data;
export type CertificatesRewardConfigurationForm_CertificateRewardConfigurationV2Fragment$key = {
  readonly " $data"?: CertificatesRewardConfigurationForm_CertificateRewardConfigurationV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CertificatesRewardConfigurationForm_CertificateRewardConfigurationV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CertificatesRewardConfigurationForm_CertificateRewardConfigurationV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "certificateTemplateRef",
      "storageKey": null
    }
  ],
  "type": "CertificateRewardConfiguration",
  "abstractKey": null
};

(node as any).hash = "a799b998a8275080412376996933221f";

export default node;

import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { RewardTreeConfigEditor } from "./RewardTreeConfigEditor";
import { useFragment } from "react-relay";
import { TreeConfigEditor_TreeNodeFragment$key } from "../../../../../../../__generated__/TreeConfigEditor_TreeNodeFragment.graphql";
import { StartTreeConfigEditor } from "./StartTreeConfigEditor";
import { ShowAnswerTreeConfigEditor } from "./ShowAnswerTreeConfigEditor";
import { VisibilityTreeConfigEditor } from "./VisibilityTreeConfigEditor";
import { AdvertisementForm } from "../../root/AdvertisementForm";
import { ProjectAdvertisementForm } from "../../../../../../../lms-admin-impl/components/ui/ProjectAdvertisementForm";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../../../../../lms-admin-impl/i18n/tree.i18n";

const TREE_NODE_FRAGMENT = graphql`
	fragment TreeConfigEditor_TreeNodeFragment on TreeNode {
		...RewardTreeConfigEditor_TreeNodeFragment
		...ShowAnswerTreeConfigEditor_TreeNodeFragment
		...StartTreeConfigEditor_TreeNodeFragment
		...VisibilityTreeConfigEditor_TreeNodeFragment
		...AdvertisementForm_TreeNodeFragment
		...ProjectAdvertisementForm_TreeNodeFragment
	}
`;

interface OwnProps {
	treeNodeFragmentRef: TreeConfigEditor_TreeNodeFragment$key;
}

export const TreeConfigEditor = ({ treeNodeFragmentRef }: OwnProps) => {
	const treeNodeFragment = useFragment<TreeConfigEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	return (
		<>
			<h2>Auf {TREE_I18N_MAP(TREE_I18N_KEY.treeLevel)}</h2>
			{ProjectAdvertisementForm ? (
				<ProjectAdvertisementForm treeNodeFragmentRef={treeNodeFragment} />
			) : (
				<AdvertisementForm treeNodeFragmentRef={treeNodeFragment} />
			)}
			<ShowAnswerTreeConfigEditor treeNodeFragmentRef={treeNodeFragment} />
			<RewardTreeConfigEditor treeNodeFragmentRef={treeNodeFragment} />
			<StartTreeConfigEditor treeNodeFragmentRef={treeNodeFragment} />
			<VisibilityTreeConfigEditor treeNodeFragmentRef={treeNodeFragment} />
		</>
	);
};

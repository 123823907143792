import { useMatch, useNavigate } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { ConnectionHandler, useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { EditCertificateTemplateScreen_Query } from "../../__generated__/EditCertificateTemplateScreen_Query.graphql";
import { EditCertificateTemplateScreen_CertificateTemplateFragment$key } from "../../__generated__/EditCertificateTemplateScreen_CertificateTemplateFragment.graphql";
import { EditCertificateTemplateForm } from "../components/certificate/EditCertificateTemplateForm";
import { EditCertificateTemplateScreen_CreateCertificateTemplateMutation } from "../../__generated__/EditCertificateTemplateScreen_CreateCertificateTemplateMutation.graphql";
import {
	CertificateTemplateInput,
	EditCertificateTemplateScreen_UpdateCertificateTemplateMutation,
} from "../../__generated__/EditCertificateTemplateScreen_UpdateCertificateTemplateMutation.graphql";
import {
	CERTIFICATE_TEMPLATE_ID_URL_PARAM_V2,
	getEditCertificateTemplatePathV2,
} from "../../app/routes/CertificateModuleRoutes";
import { useTypedDispatch } from "../redux/Store";
import { BaseScreen } from "./BaseScreen";
import { resetArrayOfEditedForms } from "../redux/slices/CoreSlice";
import { Permission } from "../../__generated__/MainNavigation_Query.graphql";
import { useSelector } from "react-redux";
import { selectHasPermissions } from "../redux/slices/CurrentUserSlice";

const QUERY = graphql`
	query EditCertificateTemplateScreen_Query($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on CertificateTemplate {
				...EditCertificateTemplateScreen_CertificateTemplateFragment
			}
		}
	}
`;

const CERTIFICATE_TEMPLATE_FRAGMENT = graphql`
	fragment EditCertificateTemplateScreen_CertificateTemplateFragment on CertificateTemplate {
		id
		name
		templateFileRef
		variableMappings {
			certificateVariable
			templateVariable
		}
	}
`;

const CREATE_CERTIFICATE_TEMPLATE_MUTATION = graphql`
	mutation EditCertificateTemplateScreen_CreateCertificateTemplateMutation(
		$input: CreateCertificateTemplateInput!
		$connections: [ID!]!
	) {
		Admin {
			Certificates {
				createCertificateTemplate(input: $input) {
					data @appendEdge(connections: $connections) {
						node {
							id
							...EditCertificateTemplateScreen_CertificateTemplateFragment
						}
					}
				}
			}
		}
	}
`;

const UPDATE_CERTIFICATE_TEMPLATE_MUTATION = graphql`
	mutation EditCertificateTemplateScreen_UpdateCertificateTemplateMutation(
		$input: UpdateCertificateTemplateInput!
	) {
		Admin {
			Certificates {
				updateCertificateTemplate(input: $input) {
					data {
						node {
							...EditCertificateTemplateScreen_CertificateTemplateFragment
						}
					}
				}
			}
		}
	}
`;

export const EditCertificateTemplateScreen = () => {
	const navigate = useNavigate();
	const dispatch = useTypedDispatch();

	const {
		params: { certificateTemplateId },
	} = useMatch(getEditCertificateTemplatePathV2(CERTIFICATE_TEMPLATE_ID_URL_PARAM_V2))!;

	const query = useLazyLoadQuery<EditCertificateTemplateScreen_Query>(QUERY, {
		id: certificateTemplateId!,
		skip: !certificateTemplateId || certificateTemplateId === "new",
	});

	const certificateTemplate =
		useFragment<EditCertificateTemplateScreen_CertificateTemplateFragment$key>(
			CERTIFICATE_TEMPLATE_FRAGMENT,
			query.node || null,
		);

	const [createCertificateTemplate, isCreatingCertificateTemplate] =
		useMutation<EditCertificateTemplateScreen_CreateCertificateTemplateMutation>(
			CREATE_CERTIFICATE_TEMPLATE_MUTATION,
		);

	const [updateCertificateTemplate, isUpdatingCertificateTemplate] =
		useMutation<EditCertificateTemplateScreen_UpdateCertificateTemplateMutation>(
			UPDATE_CERTIFICATE_TEMPLATE_MUTATION,
		);

	const checkPermission = (permissions: Permission[]) =>
		useSelector((state: any) => selectHasPermissions(state, permissions));

	const isAdmin = checkPermission([
		"AccountPermission_System_Root",
		"UserInAccountPermission_System_Owner",
	]);

	if (!isAdmin) {
		return <div>Kein Zugriff</div>;
	}

	return (
		<BaseScreen>
			<h1 className="mb-3">Bearbeite Zertifikatsvorlage</h1>

			<EditCertificateTemplateForm
				initialValues={{
					name: certificateTemplate?.name,
					templateFileRef: certificateTemplate?.templateFileRef || "",
					variableMappings: [...(certificateTemplate?.variableMappings || [])],
				}}
				loading={isCreatingCertificateTemplate || isUpdatingCertificateTemplate}
				onSubmit={(values, onCompleted) => {
					const data: CertificateTemplateInput = {
						name: values.name!,
						templateFileRef: values.templateFileRef!,
						variableMappings: values.variableMappings,
					};
					if (certificateTemplate) {
						updateCertificateTemplate({
							variables: {
								input: {
									id: certificateTemplate.id,
									data: data,
								},
							},
							onCompleted: () => {
								onCompleted();
								dispatch(resetArrayOfEditedForms());
							},
						});
					} else {
						createCertificateTemplate({
							variables: {
								input: {
									data: data,
								},
								connections: [
									ConnectionHandler.getConnectionID(
										"client:root:Admin:CertificateTemplate",
										"CertificateTemplatesTable_CertificateTemplates",
									),
								],
							},
							onCompleted: (data) => {
								onCompleted();
								navigate(
									getEditCertificateTemplatePathV2(
										data.Admin.Certificates.createCertificateTemplate?.data.node
											.id || "",
									),
								);
							},
						});
					}
				}}
			/>
		</BaseScreen>
	);
};

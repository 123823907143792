/**
 * @generated SignedSource<<ceb4f164fd13d2736280b3ce6235479c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmailTesterFeedbackEmailForm_FeedbackQuery$variables = {};
export type EmailTesterFeedbackEmailForm_FeedbackQueryVariables = EmailTesterFeedbackEmailForm_FeedbackQuery$variables;
export type EmailTesterFeedbackEmailForm_FeedbackQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EmailTesterFeedbackEmailForm_FeedbackQueryFragment">;
};
export type EmailTesterFeedbackEmailForm_FeedbackQueryResponse = EmailTesterFeedbackEmailForm_FeedbackQuery$data;
export type EmailTesterFeedbackEmailForm_FeedbackQuery = {
  variables: EmailTesterFeedbackEmailForm_FeedbackQueryVariables;
  response: EmailTesterFeedbackEmailForm_FeedbackQuery$data;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailTesterFeedbackEmailForm_FeedbackQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "EmailTesterFeedbackEmailForm_FeedbackQueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EmailTesterFeedbackEmailForm_FeedbackQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FeedbackV2AdminSchema",
            "kind": "LinkedField",
            "name": "FeedbackV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FeedbacksConnection",
                "kind": "LinkedField",
                "name": "Feedbacks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FeedbacksEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FeedbackV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "body",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "connection",
                "key": "EmailTesterFeedbackEmailForm_Feedbacks",
                "kind": "LinkedHandle",
                "name": "Feedbacks"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0e5c6ba6e26a0b59d8bddf7ee869a48c",
    "id": null,
    "metadata": {},
    "name": "EmailTesterFeedbackEmailForm_FeedbackQuery",
    "operationKind": "query",
    "text": "query EmailTesterFeedbackEmailForm_FeedbackQuery {\n  ...EmailTesterFeedbackEmailForm_FeedbackQueryFragment\n}\n\nfragment EmailTesterFeedbackEmailForm_FeedbackQueryFragment on Query {\n  Admin {\n    FeedbackV2 {\n      Feedbacks {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            body\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n"
  }
};

(node as any).hash = "c9024e85de3ab2c4eb1462ed926e4baa";

export default node;

import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ContainerTag } from "../../../graph/ContainerTag";
import { TagsColumn_TreeNodeFragment$key } from "../../../../../__generated__/TagsColumn_TreeNodeFragment.graphql";

const TREE_NODE_FRAGMENT = graphql`
	fragment TagsColumn_TreeNodeFragment on TreeNode {
		structureDefinition {
			... on RootStructureDefinition {
				tags {
					id
					...ContainerTag_TagFragment
				}
			}
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: TagsColumn_TreeNodeFragment$key;
	className?: string;
}

export const TagsColumn = ({ treeNodeFragmentRef, className }: OwnProps) => {
	const treeNode = useFragment<TagsColumn_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	return treeNode.structureDefinition.tags?.length ? (
		<div className={`flex flex-wrap ${className}`}>
			{treeNode.structureDefinition.tags.map((tag) => (
				<ContainerTag key={tag.id} tagFragmentRef={tag} />
			))}
		</div>
	) : null;
};

/**
 * @generated SignedSource<<a5a537639fcc70b361d9f7f04069fec2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditVimeoPodcastElementV2Input = {
  title: string;
  vimeoId: string;
  vimeoPodcastElementId: string;
  clientMutationId?: string | null;
};
export type VimeoPodcastElementForm_EditVimeoPodcastElementV2Mutation$variables = {
  input: EditVimeoPodcastElementV2Input;
};
export type VimeoPodcastElementForm_EditVimeoPodcastElementV2MutationVariables = VimeoPodcastElementForm_EditVimeoPodcastElementV2Mutation$variables;
export type VimeoPodcastElementForm_EditVimeoPodcastElementV2Mutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly editVimeoPodcastElement: {
        readonly vimeoPodcastElement: {
          readonly " $fragmentSpreads": FragmentRefs<"VimeoPodcastElementForm_PodcastElementV2Fragment">;
        };
      } | null;
    };
  };
};
export type VimeoPodcastElementForm_EditVimeoPodcastElementV2MutationResponse = VimeoPodcastElementForm_EditVimeoPodcastElementV2Mutation$data;
export type VimeoPodcastElementForm_EditVimeoPodcastElementV2Mutation = {
  variables: VimeoPodcastElementForm_EditVimeoPodcastElementV2MutationVariables;
  response: VimeoPodcastElementForm_EditVimeoPodcastElementV2Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VimeoPodcastElementForm_EditVimeoPodcastElementV2Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditVimeoPodcastElementV2Payload",
                "kind": "LinkedField",
                "name": "editVimeoPodcastElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PodcastElementV2",
                    "kind": "LinkedField",
                    "name": "vimeoPodcastElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "VimeoPodcastElementForm_PodcastElementV2Fragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VimeoPodcastElementForm_EditVimeoPodcastElementV2Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditVimeoPodcastElementV2Payload",
                "kind": "LinkedField",
                "name": "editVimeoPodcastElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PodcastElementV2",
                    "kind": "LinkedField",
                    "name": "vimeoPodcastElement",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "podcastSource",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sourceType",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "vimeoId",
                                "storageKey": null
                              }
                            ],
                            "type": "VimeoPodcastSourceV2",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lengthInSeconds",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "podigeeUrl",
                                "storageKey": null
                              }
                            ],
                            "type": "PodigeePodcastSourceV2",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "568c6cc978e933dde40938499d4eabf0",
    "id": null,
    "metadata": {},
    "name": "VimeoPodcastElementForm_EditVimeoPodcastElementV2Mutation",
    "operationKind": "mutation",
    "text": "mutation VimeoPodcastElementForm_EditVimeoPodcastElementV2Mutation(\n  $input: EditVimeoPodcastElementV2Input!\n) {\n  Admin {\n    ElearningV2 {\n      editVimeoPodcastElement(input: $input) {\n        vimeoPodcastElement {\n          ...VimeoPodcastElementForm_PodcastElementV2Fragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment VimeoPodcastElementForm_PodcastElementV2Fragment on PodcastElementV2 {\n  id\n  title\n  podcastSource {\n    __typename\n    sourceType\n    ... on VimeoPodcastSourceV2 {\n      sourceType\n      vimeoId\n    }\n    ... on PodigeePodcastSourceV2 {\n      lengthInSeconds\n      podigeeUrl\n    }\n    ... on NoPodcastSourceV2 {\n      sourceType\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8becf2991e2d85eee69b82e7c3669787";

export default node;

import { useContext } from "react";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment-timezone";
import { PointPoolDropdown } from "./PointPoolDropdown";
import { PointsAdditionSearchContext } from "./PointsAdditionSearchContainer";
import { UserDropdown } from "./UserDropdown";

export const PointsAdditionFilters = () => {
	const { filters, setFilters, clearFilters } = useContext(PointsAdditionSearchContext);

	return (
		<div className="flex mb-3 ">
			<div>
				<UserDropdown
					selectedUserId={filters.filterByUser}
					onSelect={(e) => {
						setFilters({ ...filters, filterByUser: e });
					}}
				/>
				<PointPoolDropdown
					selectedPointPoolId={filters.filterByPointsPool}
					onSelect={(e) => {
						setFilters({ ...filters, filterByPointsPool: e });
					}}
				/>

				<Calendar
					className="mr-2"
					showTime
					hourFormat="24"
					dateFormat={"dd.mm.yy"}
					placeholder={"Mindestdatum"}
					value={
						filters.filterByMinDateTime
							? moment(filters.filterByMinDateTime.replace("[UTC]", "")).toDate()
							: undefined
					}
					onChange={(e) => {
						setFilters({
							...filters,
							filterByMinDateTime: e.value
								? moment(e.value as Date).format("YYYY-MM-DDTHH:mmZ")
								: undefined,
						});
					}}
				/>
			</div>

			<div className="flex align-items-center">
				<Button
					className=""
					label={"Zurücksetzen"}
					icon="pi pi-times"
					onClick={() => clearFilters()}
				/>
			</div>
		</div>
	);
};

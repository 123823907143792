/**
 * @generated SignedSource<<bdad119c2ef661aa2e8380165735e157>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteUnpublishedTreeInput = {
  rootNodeId: string;
  clientMutationId?: string | null;
};
export type ActionColumn_DeleteUnpublishedTreeMutation$variables = {
  input: DeleteUnpublishedTreeInput;
  connections: ReadonlyArray<string>;
};
export type ActionColumn_DeleteUnpublishedTreeMutationVariables = ActionColumn_DeleteUnpublishedTreeMutation$variables;
export type ActionColumn_DeleteUnpublishedTreeMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly deleteUnpublishedTree: {
        readonly rootNodeId: string;
      } | null;
    };
  };
};
export type ActionColumn_DeleteUnpublishedTreeMutationResponse = ActionColumn_DeleteUnpublishedTreeMutation$data;
export type ActionColumn_DeleteUnpublishedTreeMutation = {
  variables: ActionColumn_DeleteUnpublishedTreeMutationVariables;
  response: ActionColumn_DeleteUnpublishedTreeMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rootNodeId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ActionColumn_DeleteUnpublishedTreeMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteUnpublishedTreePayload",
                "kind": "LinkedField",
                "name": "deleteUnpublishedTree",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ActionColumn_DeleteUnpublishedTreeMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteUnpublishedTreePayload",
                "kind": "LinkedField",
                "name": "deleteUnpublishedTree",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "deleteEdge",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "rootNodeId",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b78d8a343434ad67097bc0334c00951b",
    "id": null,
    "metadata": {},
    "name": "ActionColumn_DeleteUnpublishedTreeMutation",
    "operationKind": "mutation",
    "text": "mutation ActionColumn_DeleteUnpublishedTreeMutation(\n  $input: DeleteUnpublishedTreeInput!\n) {\n  Admin {\n    Tree {\n      deleteUnpublishedTree(input: $input) {\n        rootNodeId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3489faa6720c62c38d9e1438a25fc22d";

export default node;

import { graphql } from "babel-plugin-relay/macro";
import { Card } from "primereact/card";
import React from "react";
import {
	PassContentConfigEditor_TreeNodeFragment$key,
	PassContentConfigType,
} from "../../../../../../../__generated__/PassContentConfigEditor_TreeNodeFragment.graphql";
import { useFragment, useMutation } from "react-relay";
import {
	PassContentConfigEditor_SetOnPercentagePassContentConfigMutation,
	SetOnPercentagePassContentConfigInput,
} from "../../../../../../../__generated__/PassContentConfigEditor_SetOnPercentagePassContentConfigMutation.graphql";
import {
	PassContentConfigEditor_SetAlwaysPassContentConfigMutation,
	SetAlwaysPassContentConfigInput,
} from "../../../../../../../__generated__/PassContentConfigEditor_SetAlwaysPassContentConfigMutation.graphql";
import { Config } from "../components/Config.interfaces";
import { ConfigItem } from "../components/ConfigItem";
import { OnPercentagePassContentConfigForm } from "./pass/OnPercentagePassContentConfigForm";
import { OnPercentagePassContentConfigForm_OnPercentagePassContentConfigFragment$key } from "../../../../../../../__generated__/OnPercentagePassContentConfigForm_OnPercentagePassContentConfigFragment.graphql";
import { ConfigDropdownOptions } from "../components/ConfigDropdown";

const TREE_NODE_FRAGMENT = graphql`
	fragment PassContentConfigEditor_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ContentTypeDefinition {
				passConfig {
					configType
					... on OnPercentagePassContentConfig {
						requiredPercentage
					}
					...OnPercentagePassContentConfigForm_OnPercentagePassContentConfigFragment
				}
			}
		}
		...OnPercentagePassContentConfigForm_TreeNodeFragment
	}
`;

const SET_ALWAYS_PASS_CONTENT_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation PassContentConfigEditor_SetAlwaysPassContentConfigMutation(
		$input: SetAlwaysPassContentConfigInput!
	) {
		Admin {
			Tree {
				setAlwaysPassContentConfig(input: $input) {
					contentNode {
						...PassContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const SET_ON_PERCENTAGE_PASS_CONTENT_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation PassContentConfigEditor_SetOnPercentagePassContentConfigMutation(
		$input: SetOnPercentagePassContentConfigInput!
	) {
		Admin {
			Tree {
				setOnPercentagePassContentConfig(input: $input) {
					contentNode {
						...PassContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface Props {
	treeNodeFragmentRef: PassContentConfigEditor_TreeNodeFragment$key;
}

export const PassContentConfigEditor = ({ treeNodeFragmentRef }: Props) => {
	const treeNode = useFragment<PassContentConfigEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const [setAlwaysPassContentConfig] =
		useMutation<PassContentConfigEditor_SetAlwaysPassContentConfigMutation>(
			SET_ALWAYS_PASS_CONTENT_CONDITION_CONFIGURATION_MUTATION,
		);
	const [setOnPercentagePassContentConfig] =
		useMutation<PassContentConfigEditor_SetOnPercentagePassContentConfigMutation>(
			SET_ON_PERCENTAGE_PASS_CONTENT_CONDITION_CONFIGURATION_MUTATION,
		);

	const configType: PassContentConfigType = treeNode.typeDefinition.passConfig
		?.configType as PassContentConfigType;

	const alwaysPass: Config<PassContentConfigType, SetAlwaysPassContentConfigInput> = {
		configKey: "PassContent_Always",
		addMutation: (input: SetAlwaysPassContentConfigInput) => {
			setAlwaysPassContentConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const onPercentagePass: Config<PassContentConfigType, SetOnPercentagePassContentConfigInput> = {
		configKey: "PassContent_OnPercentage",
		addMutation: (input: SetOnPercentagePassContentConfigInput) => {
			setOnPercentagePassContentConfig({
				variables: {
					input,
				},
			});
		},
		addMutationPayload: {
			requiredPercentage: 75,
		},
		editable: true,
	};

	const configs: Config<PassContentConfigType, any | never>[] = [alwaysPass, onPercentagePass];

	const options: ConfigDropdownOptions<PassContentConfigType>[] = configs.map((c) => {
		const selectedConfig = treeNode.typeDefinition.passConfig;

		if (
			selectedConfig?.configType === c.configKey &&
			c.configKey === "PassContent_OnPercentage"
		) {
			return {
				label: `(${selectedConfig?.requiredPercentage})`,
				value: c.configKey,
			};
		} else {
			return {
				label: c.configKey,
				value: c.configKey,
			};
		}
	});

	return (
		<Card className="mb-2">
			<h2>Bestehen</h2>

			<ConfigItem<PassContentConfigType, string>
				isPresentational={false}
				configType={configType}
				canEdit={
					configs.find(
						(c) => c.configKey === treeNode.typeDefinition.passConfig?.configType,
					)?.editable as boolean
				}
				configOptions={options}
				onChange={(e) => {
					const selectedConfig = configs.find((c) => c.configKey === e.value);

					if (selectedConfig) {
						selectedConfig.addMutation({
							contentNodeId: treeNode.id,
							...selectedConfig.addMutationPayload,
						});
					}
				}}
				editDialog={(props: any) => (
					<>
						{configType === "PassContent_OnPercentage" && (
							<OnPercentagePassContentConfigForm
								{...props}
								treeNodeFragmentRef={treeNode}
								configurationFragmentRef={
									treeNode.typeDefinition
										.passConfig as unknown as OnPercentagePassContentConfigForm_OnPercentagePassContentConfigFragment$key
								}
							/>
						)}
					</>
				)}
			/>
		</Card>
	);
};

import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { EditMessageDefinitionForm } from "../components/messaging/edit-customer-message-definition/EditMessageDefinitionForm";
import { EditMessageDefinitionScreen_Query } from "../../__generated__/EditMessageDefinitionScreen_Query.graphql";
import { useMatch } from "react-router-dom";
import { MessageDefinitionPages } from "../components/messaging/edit-customer-message-definition/edit-customer-message-definition-pages/MessageDefinitionPages";
import { BaseScreen } from "./BaseScreen";

const QUERY = graphql`
	query EditMessageDefinitionScreen_Query($id: ID!) {
		node(id: $id) {
			... on MessageDefinition {
				internalTitle
				...MessageDefinitionPages_MessageDefinitionFragment
				...EditMessageDefinitionForm_MessageDefinitionFragment
			}
		}
	}
`;

export const EDIT_MESSAGE_DEFINITION_ROUTE = "/messaging/:messageDefinitionId/edit";

export const EditMessageDefinitionScreen = () => {
	const match = useMatch(EDIT_MESSAGE_DEFINITION_ROUTE);

	const query = useLazyLoadQuery<EditMessageDefinitionScreen_Query>(QUERY, {
		id: match?.params.messageDefinitionId!,
	});

	return (
		query.node && (
			<BaseScreen>
				<h1 className="mb-3">Bearbeite Nachrichten-Vorlage</h1>
				<EditMessageDefinitionForm messageDefinitionFragmentRef={query.node} />

				<h2 className="mt-3">Seiten</h2>
				<MessageDefinitionPages messageDefinitionFragmentRef={query.node} />
			</BaseScreen>
		)
	);
};

import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { Card } from "primereact/card";
import {
	VisibilityTreeConfigEditor_TreeNodeFragment$key,
	VisibilityTreeConfigType,
} from "../../../../../../../__generated__/VisibilityTreeConfigEditor_TreeNodeFragment.graphql";
import { useFragment, useMutation } from "react-relay";
import { VisibilityTreeConfigEditor_RemoveVisibilityConfigMutation } from "../../../../../../../__generated__/VisibilityTreeConfigEditor_RemoveVisibilityConfigMutation.graphql";
import {
	AddHideVisibilityTreeConfigInput,
	VisibilityTreeConfigEditor_AddHideVisibilityConfigMutation,
} from "../../../../../../../__generated__/VisibilityTreeConfigEditor_AddHideVisibilityConfigMutation.graphql";
import { ConfigDropdownOptions, ConfigDropdown } from "../components/ConfigDropdown";
import { ConfigItem } from "../components/ConfigItem";
import { ConfigPreview } from "../components/ConfigPreview";
import { ConfigurationGraphqlInterface, Config } from "../components/Config.interfaces";
import {
	AddOnlyAdminsVisibilityTreeConfigInput,
	VisibilityTreeConfigEditor_AddOnlyAdminsVisibilityConfigMutation,
} from "../../../../../../../__generated__/VisibilityTreeConfigEditor_AddOnlyAdminsVisibilityConfigMutation.graphql";
import {
	AddNotAfterDateTimeVisibilityTreeConfigInput,
	VisibilityTreeConfigEditor_AddNotAfterDateTimeVisibilityConfigMutation,
} from "../../../../../../../__generated__/VisibilityTreeConfigEditor_AddNotAfterDateTimeVisibilityConfigMutation.graphql";
import {
	AddAfterDateTimeVisibilityTreeConfigInput,
	VisibilityTreeConfigEditor_AddAfterDateTimeVisibilityConfigMutation,
} from "../../../../../../../__generated__/VisibilityTreeConfigEditor_AddAfterDateTimeVisibilityConfigMutation.graphql";
import {
	AddOnlyIfTreeStateVisibilityTreeConfigInput,
	VisibilityTreeConfigEditor_AddOnlyIfTreeStateVisibilityConfigMutation,
} from "../../../../../../../__generated__/VisibilityTreeConfigEditor_AddOnlyIfTreeStateVisibilityConfigMutation.graphql";
import moment from "moment-timezone";
import {
	ProjectExtendVisibilityConfigEditDialogs,
	ExtendVisibilityConfigs,
	HiddenVisibilityConfigs,
} from "../../../../../../../lms-admin-impl/components/relay/ProjectExtendVisibilityConfigEditDialogs";
import { formatDateTime } from "../../../../../core/datetime/DateTimeDisplay";
import { NotAfterDateTimeVisibilityConfigForm } from "./visibility/NotAfterDateTimeVisibilityConfigForm";
import { NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$key } from "../../../../../../../__generated__/NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment.graphql";
import { AfterDateTimeVisibilityConfigForm } from "./visibility/AfterDateTimeVisibilityConfigForm";
import { AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$key } from "../../../../../../../__generated__/AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment.graphql";
import { VISIBILITY_LABEL } from "../../../../../../i18n/graph/visibility_label";

const TREE_NODE_FRAGMENT = graphql`
	fragment VisibilityTreeConfigEditor_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			... on RootStructureDefinition {
				visibilityConfigs {
					id
					configType
					... on NotAfterDateTimeVisibilityTreeConfig {
						dateTime
					}
					... on AfterDateTimeVisibilityTreeConfig {
						dateTime
					}
					...NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment
					...AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment
					...ProjectExtendVisibilityConfigEditDialogs_VisibilityTreeConfigFragment
				}
			}
		}
		...NotAfterDateTimeVisibilityConfigForm_TreeNodeFragment
		...AfterDateTimeVisibilityConfigForm_TreeNodeFragment
		...ProjectExtendVisibilityConfigEditDialogs_TreeNodeFragment
	}
`;

const ADD_HIDE_VISIBILITY_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation VisibilityTreeConfigEditor_AddHideVisibilityConfigMutation(
		$input: AddHideVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				addHideVisibilityTreeConfig(input: $input) {
					rootNode {
						...VisibilityTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const ADD_ONLY_ADMINS_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation VisibilityTreeConfigEditor_AddOnlyAdminsVisibilityConfigMutation(
		$input: AddOnlyAdminsVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				addOnlyAdminsVisibilityTreeConfig(input: $input) {
					rootNode {
						...VisibilityTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const ADD_NOT_AFTER_DATE_TIME_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation VisibilityTreeConfigEditor_AddNotAfterDateTimeVisibilityConfigMutation(
		$input: AddNotAfterDateTimeVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				addNotAfterDateTimeVisibilityTreeConfig(input: $input) {
					rootNode {
						...VisibilityTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const ADD_AFTER_DATE_TIME_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation VisibilityTreeConfigEditor_AddAfterDateTimeVisibilityConfigMutation(
		$input: AddAfterDateTimeVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				addAfterDateTimeVisibilityTreeConfig(input: $input) {
					rootNode {
						...VisibilityTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const ADD_ONLY_IF_TREE_STATE_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation VisibilityTreeConfigEditor_AddOnlyIfTreeStateVisibilityConfigMutation(
		$input: AddOnlyIfTreeStateVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				AddOnlyIfTreeStateVisibilityTreeConfig(input: $input) {
					rootNode {
						...VisibilityTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const REMOVE_VISIBILITY_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation VisibilityTreeConfigEditor_RemoveVisibilityConfigMutation(
		$input: RemoveVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				removeVisibilityTreeConfig(input: $input) {
					rootNode {
						...VisibilityTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface Props {
	treeNodeFragmentRef: VisibilityTreeConfigEditor_TreeNodeFragment$key;
}

export const VisibilityTreeConfigEditor = ({ treeNodeFragmentRef }: Props) => {
	const treeNode = useFragment<VisibilityTreeConfigEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const [removeVisibilityConfig, isRemovingVisibilityConfig] =
		useMutation<VisibilityTreeConfigEditor_RemoveVisibilityConfigMutation>(
			REMOVE_VISIBILITY_CONDITION_CONFIGURATION_MUTATION,
		);
	const [addHideVisibilityConfig] =
		useMutation<VisibilityTreeConfigEditor_AddHideVisibilityConfigMutation>(
			ADD_HIDE_VISIBILITY_CONDITION_CONFIGURATION_MUTATION,
		);
	const [addOnlyAdminsVisibilityConfig] =
		useMutation<VisibilityTreeConfigEditor_AddOnlyAdminsVisibilityConfigMutation>(
			ADD_ONLY_ADMINS_CONDITION_CONFIGURATION_MUTATION,
		);
	const [addAfterDateTimeVisibilityConfig] =
		useMutation<VisibilityTreeConfigEditor_AddAfterDateTimeVisibilityConfigMutation>(
			ADD_AFTER_DATE_TIME_CONDITION_CONFIGURATION_MUTATION,
		);
	const [addNotAfterDateTimeVisibilityConfig] =
		useMutation<VisibilityTreeConfigEditor_AddNotAfterDateTimeVisibilityConfigMutation>(
			ADD_NOT_AFTER_DATE_TIME_CONDITION_CONFIGURATION_MUTATION,
		);
	const [addOnlyIfTreeStateVisibilityConfig] =
		useMutation<VisibilityTreeConfigEditor_AddOnlyIfTreeStateVisibilityConfigMutation>(
			ADD_ONLY_IF_TREE_STATE_CONDITION_CONFIGURATION_MUTATION,
		);

	const hideVisibilityConfig: Config<VisibilityTreeConfigType, AddHideVisibilityTreeConfigInput> =
		{
			configKey: "VisibilityTree_Hide",
			addMutation: (input: AddHideVisibilityTreeConfigInput) => {
				addHideVisibilityConfig({
					variables: {
						input,
					},
				});
			},
			editable: false,
		};

	const onlyAdminsVisibilityConfig: Config<
		VisibilityTreeConfigType,
		AddOnlyAdminsVisibilityTreeConfigInput
	> = {
		configKey: "VisibilityTree_OnlyAdmins",
		addMutation: (input: AddOnlyAdminsVisibilityTreeConfigInput) => {
			addOnlyAdminsVisibilityConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const onlyIfTreeState: Config<
		VisibilityTreeConfigType,
		AddOnlyIfTreeStateVisibilityTreeConfigInput
	> = {
		configKey: "VisibilityTree_OnlyIfTreeState",
		addMutation: (input: AddOnlyIfTreeStateVisibilityTreeConfigInput) => {
			addOnlyIfTreeStateVisibilityConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const notAfterDateTimeVisibilityConfig: Config<
		VisibilityTreeConfigType,
		AddNotAfterDateTimeVisibilityTreeConfigInput
	> = {
		configKey: "VisibilityTree_NotAfterDateTime",
		addMutation: (input: AddNotAfterDateTimeVisibilityTreeConfigInput) => {
			addNotAfterDateTimeVisibilityConfig({
				variables: {
					input,
				},
			});
		},
		addMutationPayload: {
			dateTime: moment().add(1, "year").format(),
		},
		editable: true,
	};

	const afterDateTimeVisibilityConfig: Config<
		VisibilityTreeConfigType,
		AddAfterDateTimeVisibilityTreeConfigInput
	> = {
		configKey: "VisibilityTree_AfterDateTime",
		addMutation: (input: AddAfterDateTimeVisibilityTreeConfigInput) => {
			addAfterDateTimeVisibilityConfig({
				variables: {
					input,
				},
			});
		},
		addMutationPayload: {
			dateTime: moment().add(1, "week").format(),
		},
		editable: true,
	};

	const configs: Config<VisibilityTreeConfigType, any | never>[] = [
		hideVisibilityConfig,
		onlyAdminsVisibilityConfig,
		notAfterDateTimeVisibilityConfig,
		afterDateTimeVisibilityConfig,
		onlyIfTreeState,
		...ExtendVisibilityConfigs,
	].filter((c) => HiddenVisibilityConfigs.indexOf(c.configKey) === -1);

	const options: ConfigDropdownOptions<VisibilityTreeConfigType>[] = configs.map((c) => {
		return {
			label: c.configKey,
			value: c.configKey,
		};
	});

	const previewOptions: ConfigDropdownOptions<VisibilityTreeConfigType>[] = configs.map((c) => {
		const selectedConfig = treeNode.structureDefinition.visibilityConfigs?.find(
			(r) => r.configType === c.configKey,
		);

		if (selectedConfig?.dateTime) {
			return {
				label: `(${formatDateTime(selectedConfig?.dateTime as string)})`,
				value: c.configKey,
			};
		} else {
			return {
				label: c.configKey,
				value: c.configKey,
			};
		}
	});

	return (
		<Card className="mb-2">
			<h2>{VISIBILITY_LABEL["visibility_label"]}</h2>

			<ConfigDropdown<VisibilityTreeConfigType>
				configOptions={options}
				onChange={(e) => {
					const selectedConfig = configs.find((c) => c.configKey === e.value);

					if (selectedConfig) {
						selectedConfig.addMutation({
							rootNodeId: treeNode.id,
							...selectedConfig.addMutationPayload,
						});
					}
				}}
				isPresentational={false}
			/>

			<ConfigPreview<ConfigurationGraphqlInterface<VisibilityTreeConfigType>>
				selectedConfigs={treeNode.structureDefinition.visibilityConfigs as any[]}
				template={(
					configuration: ConfigurationGraphqlInterface<VisibilityTreeConfigType>,
				) => (
					<>
						<ConfigItem<VisibilityTreeConfigType, string>
							isPresentational={true}
							isLoading={isRemovingVisibilityConfig}
							configType={configuration.configType}
							canEdit={
								configs.find((c) => c.configKey === configuration.configType)
									?.editable as boolean
							}
							configOptions={previewOptions}
							onDelete={() => {
								removeVisibilityConfig({
									variables: {
										input: {
											rootNodeId: treeNode.id,
											configId: configuration.id,
										},
									},
								});
							}}
							editDialog={(props: any) => (
								<>
									{configuration.configType ===
										"VisibilityTree_NotAfterDateTime" && (
										<NotAfterDateTimeVisibilityConfigForm
											treeNodeFragmentRef={treeNode}
											configurationFragmentRef={
												configuration as unknown as NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$key
											}
										/>
									)}

									{configuration.configType ===
										"VisibilityTree_AfterDateTime" && (
										<AfterDateTimeVisibilityConfigForm
											treeNodeFragmentRef={treeNode}
											configurationFragmentRef={
												configuration as unknown as AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$key
											}
										/>
									)}

									<ProjectExtendVisibilityConfigEditDialogs
										{...props}
										treeNodeFragmentRef={treeNode}
										configurationFragmentRef={configuration}
									/>
								</>
							)}
						/>
					</>
				)}
			/>
		</Card>
	);
};

import React from "react";
import { Button } from "primereact/button";

interface OwnProps {
	createNewsTickerDefinition: () => void;
}

export const CreateNewsTickerDefinitionButton = ({ createNewsTickerDefinition }: OwnProps) => {
	return (
		<div className="flex justify-content-start pb-4">
			<Button label={"Erstellen"} onClick={createNewsTickerDefinition} />
		</div>
	);
};

/**
 * @generated SignedSource<<28863b6a43db0cf591f32d8162a00091>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentSubmissionsTable_QueryFragment$data = {
  readonly Admin: {
    readonly LearnV2: {
      readonly GetContentSubmissions: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"ContentSubmissionsTable_ContentSubmissionFragment">;
          };
        } | null> | null;
      };
    };
  };
  readonly " $fragmentType": "ContentSubmissionsTable_QueryFragment";
};
export type ContentSubmissionsTable_QueryFragment = ContentSubmissionsTable_QueryFragment$data;
export type ContentSubmissionsTable_QueryFragment$key = {
  readonly " $data"?: ContentSubmissionsTable_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentSubmissionsTable_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "treeStateId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentSubmissionsTable_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnV2AdminSchema",
          "kind": "LinkedField",
          "name": "LearnV2",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 100
                },
                {
                  "kind": "Variable",
                  "name": "treeStateId",
                  "variableName": "treeStateId"
                }
              ],
              "concreteType": "ContentSubmissionsConnection",
              "kind": "LinkedField",
              "name": "GetContentSubmissions",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentSubmissionsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ContentSubmission",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "ContentSubmissionsTable_ContentSubmissionFragment",
                          "selections": [
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "LastUpdatedAtColumn_ContentSubmissionFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "StartedAtColumn_ContentSubmissionFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "ProjectRewardsColumn_ContentSubmissionFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "RootNodeTitleColumn_ContentSubmissionFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "StatusColumn_ContentSubmissionFragment"
                            }
                          ]
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "3a72a6537114bd5b22781cafafc8eb66";

export default node;

import { Dropdown } from "primereact/dropdown";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery, useRelayEnvironment } from "react-relay";
import { useState } from "react";
import { FragmentRefs } from "relay-runtime";
import { UserDropdown_Query } from "../../../../__generated__/UserDropdown_Query.graphql";
import { ProjectUserDescription } from "../../../../lms-admin-impl/components/relay/ProjectUserDescription";

const QUERY = graphql`
	query UserDropdown_Query($filterByLastName: String, $first: Int!, $after: String) {
		Admin {
			Auth {
				Users(first: $first, after: $after, filterByLastName: $filterByLastName) {
					edges {
						node {
							id
							...ProjectUserDescription_UserFragment
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	selectedUserId?: string;
	onSelect: (userId?: string) => void;
}

export const UserDropdown = ({ selectedUserId, onSelect }: OwnProps) => {
	const [users, setUsers] = useState<
		{
			readonly id: string;
			readonly " $fragmentSpreads": FragmentRefs<"ProjectUserDescription_UserFragment">;
		}[]
	>([]);
	const environment = useRelayEnvironment();

	return (
		<Dropdown
			className={"mr-2"}
			onFilter={(e) => {
				fetchQuery<UserDropdown_Query>(environment, QUERY, {
					first: 10,
					filterByLastName: e.filter,
				})
					.toPromise()
					.then((result) =>
						setUsers(result!.Admin.Auth.Users.edges!.map((e) => e!.node!)),
					);
			}}
			placeholder={"Tippen um nach Nachnamen zu suchen"}
			filter={true}
			showClear={true}
			value={selectedUserId}
			options={users.map((u) => ({
				value: u.id,
				label: <ProjectUserDescription userFragmentRef={u} />,
			}))}
			onChange={(e) => onSelect(e.value)}
		/>
	);
};

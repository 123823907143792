import { useFormik } from "formik";
import { Button } from "primereact/button";
import React from "react";
import * as Yup from "yup";
import { DefaultTextFieldComponent } from "../core/form/DefaultTextInput";
import { ValidatedField } from "../core/form/ValidatedField";
import { EMAIL_SENDING_CONDITION_DESCRIPTION, EMAIL_VARIABLES } from "../../i18n/emails/i18n";
import { WarningUnsavedChangesDialog } from "../core/dialog/WarningUnsavedChangesDialog";
import { useTypedDispatch, useTypedSelector } from "../../redux/Store";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
	selectArrayOfEditedForms,
} from "../../redux/slices/CoreSlice";
import { useCallbackPrompt } from "../../functions/hooks/UseCallBackPrompt";
import { TextEditorComponent } from "../core/form/TextEditorComponent";

export interface FormState {
	subject?: string;
	preview?: string;
	body?: string;
}

interface OwnProps {
	loading?: boolean;
	initialValues?: FormState;
	variables: readonly string[];
	onSubmit: (values: FormState) => void;
	templateKey: string;
}

export const EditEmailTemplateForm = ({
	variables,
	loading,
	initialValues,
	onSubmit,
	templateKey,
}: OwnProps) => {
	const dispatch = useTypedDispatch();
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);
	const formId = "EditEmailTemplateForm";
	const [showPrompt, confirmNavigation, cancelNavigation, setShowPrompt] = useCallbackPrompt(
		arrayOfEditedForms.length > 0,
	);
	const formik = useFormik<FormState>({
		initialValues: {
			subject: initialValues?.subject ?? "Betreff",
			preview: initialValues?.preview ?? "Vorschautext",
			body: initialValues?.body ?? "Nachricht",
		},
		validationSchema: Yup.object().shape({
			subject: Yup.string().required("Betreff wird benötigt"),
			body: Yup.string().required("Nachricht Code wird benötigt"),
		}),
		onSubmit: (values, { setSubmitting }) => {
			onSubmit({
				...values,
			});
			setSubmitting(false);
			dispatch(resetArrayOfEditedForms());
		},
	});

	return (
		<>
			{showPrompt && (
				<WarningUnsavedChangesDialog
					confirmNavigation={confirmNavigation}
					setShowDialog={setShowPrompt}
					cancelNavigation={cancelNavigation}
				/>
			)}
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, string>
					name={"subject"}
					label={"Betreff"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<p>{EMAIL_SENDING_CONDITION_DESCRIPTION[templateKey]}</p>
				<ValidatedField<FormState, string>
					name={"preview"}
					label={"Vorschautext"}
					helpText={"Dieser Text wird in manchen Mailprogrammen angezeigt"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>

				<ValidatedField<FormState, string>
					name={"body"}
					label={"Nachricht"}
					component={TextEditorComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>

				<h3>Verfügbare Variablen</h3>
				<p>
					Diese Variablen können in allen obigen Feldern eingefügt werden und werden durch
					die entsprechenden Texte ersetzt.
				</p>
				<ul>
					{variables.map((variable) => {
						return (
							<li key={variable}>
								<strong>%%{variable}%%</strong> - {EMAIL_VARIABLES[variable]}
							</li>
						);
					})}
				</ul>

				<Button disabled={loading} type="submit" label="Speichern" className="p-mt-2" />
			</form>
		</>
	);
};

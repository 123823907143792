/**
 * @generated SignedSource<<550f4785e4c571c8956d93e0c426cdc3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectRewardEditor_TreeNodeFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"RewardEditor_TreeNodeFragment">;
  readonly " $fragmentType": "ProjectRewardEditor_TreeNodeFragment";
};
export type ProjectRewardEditor_TreeNodeFragment = ProjectRewardEditor_TreeNodeFragment$data;
export type ProjectRewardEditor_TreeNodeFragment$key = {
  readonly " $data"?: ProjectRewardEditor_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectRewardEditor_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectRewardEditor_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RewardEditor_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "2088aa6eeee4880c980556f9c43187db";

export default node;

import { graphql } from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { useFragment, useMutation } from "react-relay";
import { CreateCertificatesRewardButton_CertificateTemplatesConnectionV2Fragment$key } from "../../../../__generated__/CreateCertificatesRewardButton_CertificateTemplatesConnectionV2Fragment.graphql";
import { CreateCertificatesRewardButton_AddCertificateRewardV2Mutation } from "../../../../__generated__/CreateCertificatesRewardButton_AddCertificateRewardV2Mutation.graphql";
import { CreateCertificatesRewardButton_TreeNodeFragment$key } from "../../../../__generated__/CreateCertificatesRewardButton_TreeNodeFragment.graphql";
import { toast } from "react-toastify";

const CERTIFICATE_TEMPLATES_CONNECTION_FRAGMENT = graphql`
	fragment CreateCertificatesRewardButton_CertificateTemplatesConnectionV2Fragment on CertificateTemplatesConnection {
		edges {
			node {
				id
			}
		}
	}
`;

const TREE_NODE_FRAGMENT = graphql`
	fragment CreateCertificatesRewardButton_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ELearningContentTypeDefinition {
				rewards {
					id
					kind
				}
			}
		}
	}
`;

const ADD_CERTIFICATE_REWARD_MUTATION = graphql`
	mutation CreateCertificatesRewardButton_AddCertificateRewardV2Mutation(
		$input: AddCertificateRewardV2Input!
	) {
		Admin {
			Tree {
				addCertificateReward(input: $input) {
					contentNode {
						...RewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: CreateCertificatesRewardButton_TreeNodeFragment$key;
	certificateTemplatesConnectionFragmentRef: CreateCertificatesRewardButton_CertificateTemplatesConnectionV2Fragment$key;
}

export const CreateCertificatesRewardButton = ({
	treeNodeFragmentRef,
	certificateTemplatesConnectionFragmentRef,
}: OwnProps) => {
	const node = useFragment<CreateCertificatesRewardButton_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const certificateTemplatesConnection =
		useFragment<CreateCertificatesRewardButton_CertificateTemplatesConnectionV2Fragment$key>(
			CERTIFICATE_TEMPLATES_CONNECTION_FRAGMENT,
			certificateTemplatesConnectionFragmentRef,
		);
	const [addCertificateReward, isAddingCertificateReward] =
		useMutation<CreateCertificatesRewardButton_AddCertificateRewardV2Mutation>(
			ADD_CERTIFICATE_REWARD_MUTATION,
		);

	const noCertificateTemplates = certificateTemplatesConnection.edges?.length === 0;

	return (
		<Button
			className={"mr-2"}
			disabled={isAddingCertificateReward || noCertificateTemplates}
			tooltipOptions={{ showOnDisabled: true, position: "top" }}
			tooltip={
				noCertificateTemplates
					? "Bitte legen Sie zuerst eine Zertifikatsvorlage an"
					: "Zertifikat vergeben"
			}
			icon="pi pi-star"
			onClick={() => {
				const rewards = node.typeDefinition.rewards?.map((r) => r.kind);
				if (!rewards?.includes("PharmaPoints"))
					return toast.error(
						"Zertifikat Belohnung kann ohne BAK-Punkte Belohnung nicht zugewiesen werden",
					);

				addCertificateReward({
					variables: {
						input: {
							contentNodeId: node.id,
							certificateTemplateId:
								certificateTemplatesConnection.edges![0]!.node.id,
						},
					},
				});
			}}
		/>
	);
};

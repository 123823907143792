import { PrivateRouteDefinition } from "../../infecto-lms-admin/components/core/model/PrivateRouteDefinition";
import { FilesScreen } from "../../infecto-lms-admin/screens/FilesScreen";

export const FilesModuleRoutes: PrivateRouteDefinition[] = [
	{
		path: "/files",
		element: <FilesScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_Nodes_ReadNodes",
		],
	},
];

/**
 * @generated SignedSource<<98757fee3c07d6d65d601076f8b59efa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IsRewardedToColumn_TreeNodeFragment$data = {
  readonly structureDefinition: {
    readonly extension?: {
      readonly configConsequences?: {
        readonly isRewardedTo: string | null;
      };
    };
  };
  readonly " $fragmentType": "IsRewardedToColumn_TreeNodeFragment";
};
export type IsRewardedToColumn_TreeNodeFragment = IsRewardedToColumn_TreeNodeFragment$data;
export type IsRewardedToColumn_TreeNodeFragment$key = {
  readonly " $data"?: IsRewardedToColumn_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"IsRewardedToColumn_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IsRewardedToColumn_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TreeConfigConsequences",
                      "kind": "LinkedField",
                      "name": "configConsequences",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isRewardedTo",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "RootExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "123d8ce4a269dfba527afb533409e884";

export default node;

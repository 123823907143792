import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import graphql from "babel-plugin-relay/macro";
import { readInlineData } from "react-relay";
import styled from "styled-components";
import { TagsColumn } from "../../../../infecto-lms-admin/components/tree/tree/table/TagsColumn";
import { TitleColumn } from "../../../../infecto-lms-admin/components/tree/tree/table/TitleColumn";
import { ContentColumn } from "../../../../infecto-lms-admin/components/tree/tree/table/ContentColumn";
import { ReleasedAtColumn } from "../../../../infecto-lms-admin/components/tree/tree/table/ReleasedAtColumn";
import { ConfigurationBadgesColumn } from "../../../../infecto-lms-admin/components/tree/tree/table/ConfigurationBadgesColumn";
import { IsRewardedToColumn } from "../../../../infecto-lms-admin/components/tree/tree/table/IsRewardedToColumn";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../../lms-admin-impl/i18n/tree.i18n";
import { Button } from "primereact/button";
import moment from "moment-timezone";
import { ActionColumn } from "./ActionColumn";

const TREE_NODE_FRAGMENT = graphql`
	fragment RootNodesTable_TreeNodeFragment on TreeNode @inline {
		structureDefinition {
			... on RootStructureDefinition {
				extension {
					... on RootExtensionImpl {
						configConsequences {
							rewardExpired
						}
					}
				}
				visibilityConfigs {
					configType
					... on OnlySpecificPermissionsVisibilityTreeConfig {
						id
						configType
						dateTime
					}
					... on NotAfterDateTimeVisibilityTreeConfig {
						dateTime
					}
					... on AfterDateTimeVisibilityTreeConfig {
						dateTime
					}
				}
			}
		}
		...ActionColumn_TreeNodeFragment
		...ConfigurationBadgesColumn_TreeNodeFragment
		...ContentColumn_TreeNodeFragment
		...IsRewardedToColumn_TreeNodeFragment
		...ReleasedAtColumn_TreeNodeFragment
		...TagsColumn_TreeNodeFragment
		...TitleColumn_TreeNodeFragment
	}
`;

interface Props {
	rootNodesConnectionId: string;
	rootNodesConnection: any;
	hasPrevious: boolean;
	loadPrevious: (pageSize: number) => void;
	hasNext: boolean;
	loadNext: (pageSize: number) => void;
}

export const RootNodesTable = ({
	rootNodesConnectionId,
	rootNodesConnection,
	hasPrevious,
	loadPrevious,
	hasNext,
	loadNext,
}: Props) => {
	function hasToBeVisibleAfterADate(isBefore: boolean, date?: string) {
		const dateLimit = moment(date, "YYYY-MM-DDTHH:mm:ss.SSSZ");
		const now = moment();
		return dateLimit.isValid() && isBefore ? now.isBefore(dateLimit) : now.isAfter(dateLimit);
	}
	const isExpiredRowClass = (item: {
		structureDefinition: {
			extension?: { configConsequences?: { rewardExpired: boolean } };
			visibilityConfigs: [{ configType: string; dateTime: string }];
		};
	}) => {
		const isHiddenFromAll = () => {
			if (item.structureDefinition.visibilityConfigs) {
				return (
					item.structureDefinition.visibilityConfigs.filter(
						(conf) => conf.configType == "VisibilityTree_Hide",
					).length > 0
				);
			}
		};
		const isVisibleOnlyForAdminDateTime = () => {
			if (item.structureDefinition.visibilityConfigs) {
				return item.structureDefinition.visibilityConfigs
					.filter((conf) => conf.configType === "VisibilityTree_OnlySpecificPermissions")
					.map((date) => date.dateTime)[0];
			}
		};
		const isNotVisibleAfterDateTime = () => {
			if (item.structureDefinition.visibilityConfigs) {
				return item.structureDefinition.visibilityConfigs
					.filter((conf) => conf.configType === "VisibilityTree_NotAfterDateTime")
					.map((date) => date.dateTime)[0];
			}
		};
		const isVisibleAfterDateTime = () => {
			if (item.structureDefinition.visibilityConfigs) {
				return item.structureDefinition.visibilityConfigs
					.filter((conf) => conf.configType === "VisibilityTree_AfterDateTime")
					.map((date) => date.dateTime)[0];
			}
		};

		return {
			"is-expired-row":
				item.structureDefinition.extension?.configConsequences?.rewardExpired ||
				hasToBeVisibleAfterADate(true, isVisibleOnlyForAdminDateTime()) ||
				isHiddenFromAll() ||
				hasToBeVisibleAfterADate(false, isNotVisibleAfterDateTime()) ||
				hasToBeVisibleAfterADate(true, isVisibleAfterDateTime()),
		};
	};

	return (
		<>
			<StyledDataTable
				className="mb-3"
				value={
					rootNodesConnection.edges
						?.filter((e: any) => !!e?.node)
						.map((e: any) => e!.node)
						.map((item: any) => readInlineData(TREE_NODE_FRAGMENT, item)) || []
				}
				emptyMessage={`Keine passenden ${TREE_I18N_MAP(TREE_I18N_KEY.treePlural)}`}
				rowClassName={isExpiredRowClass}
			>
				<Column
					header="Titel"
					body={(item) => <TitleColumn treeNodeFragmentRef={item} />}
				/>
				<Column
					header="Inhalt"
					body={(item) => <ContentColumn treeNodeFragmentRef={item} />}
				/>
				<Column
					header="Schlagworte"
					body={(item) => <TagsColumn treeNodeFragmentRef={item} />}
				/>
				<Column
					header="Veröffentlicht am"
					body={(item) => <ReleasedAtColumn treeNodeFragmentRef={item} />}
				/>
				<Column
					header="Belohnung läuft ab am"
					body={(item) => <IsRewardedToColumn treeNodeFragmentRef={item} />}
				/>
				<Column
					header="Eigenschaften"
					body={(item) => <ConfigurationBadgesColumn treeNodeFragmentRef={item} />}
				/>
				<Column
					header="Aktionen"
					style={{ width: "20%" }}
					body={(item) => (
						<ActionColumn
							treeNodeFragmentRef={item}
							rootNodesConnectionId={rootNodesConnectionId}
						/>
					)}
				/>
			</StyledDataTable>

			<div className="flex justify-content-center align-items-center">
				<Button
					disabled={!hasPrevious}
					onClick={() => loadPrevious(20)}
					className="mr-3 p-button-secondary"
				>
					Zurück
				</Button>
				<Button
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={() => loadNext(20)}
				>
					Weiter
				</Button>
			</div>
		</>
	);
};

const StyledDataTable = styled(DataTable)`
	.is-expired-row {
		background: repeating-linear-gradient(
			45deg,
			#eeeeee,
			#eeeeee 10px,
			#ffffff 10px,
			#ffffff 20px
		) !important;
	}
`;

import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { CertificateTemplatesTable } from "../components/certificate/CertificateTemplatesTable";
import { CertificateTemplatesScreen_CertificateTemplatesQuery } from "../../__generated__/CertificateTemplatesScreen_CertificateTemplatesQuery.graphql";
import { BaseScreen } from "./BaseScreen";
import { selectHasPermissions } from "../redux/slices/CurrentUserSlice";
import { useSelector } from "react-redux";
import { Permission } from "../../__generated__/MainNavigation_Query.graphql";

const QUERY = graphql`
	query CertificateTemplatesScreen_CertificateTemplatesQuery {
		...CertificateTemplatesTable_QueryFragment
	}
`;

export const CertificateTemplatesScreen = () => {
	const data = useLazyLoadQuery<CertificateTemplatesScreen_CertificateTemplatesQuery>(
		QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);

	const checkPermission = (permissions: Permission[]) =>
		useSelector((state: any) => selectHasPermissions(state, permissions));

	const isAdmin = checkPermission([
		"AccountPermission_System_Root",
		"UserInAccountPermission_System_Owner",
	]);

	if (!isAdmin) {
		return <div>Kein Zugriff</div>;
	}

	return (
		<BaseScreen>
			<h1>Zertifikatsvorlagen</h1>
			<CertificateTemplatesTable queryFragmentRef={data} />
		</BaseScreen>
	);
};

import { useNavigate } from "react-router-dom";
import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import { StagingScreenV2_ResetStagingTreesMutation } from "../../__generated__/StagingScreenV2_ResetStagingTreesMutation.graphql";
import { StagingScreenV2_ResetLearnStatesContentSubmissionsAndIssuedCertificatesMutation } from "../../__generated__/StagingScreenV2_ResetLearnStatesContentSubmissionsAndIssuedCertificatesMutation.graphql";
import { BaseScreen } from "./BaseScreen";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../lms-admin-impl/i18n/tree.i18n";
import { getTreeEditorPath } from "../../app/routes/GraphEditorRoutes";
import { ProjectExtendStagingButtons } from "../../lms-admin-impl/components/ui/ProjectExtendStagingButtons";
import { useSelector } from "react-redux";
import { selectHasPermissions } from "../redux/slices/CurrentUserSlice";
import { Permission } from "../../__generated__/MainNavigation_Query.graphql";

const RESET_STAGING_TREE_MUTATION = graphql`
	mutation StagingScreenV2_ResetStagingTreesMutation {
		Admin {
			Staging {
				resetAllStagingData(input: {}) {
					clientMutationId
				}
			}
		}
	}
`;

const RESET_LEARN_STATES_CONTENT_SUBMISSIONS_AND_ISSUED_CERTIFICATES_MUTATION = graphql`
	mutation StagingScreenV2_ResetLearnStatesContentSubmissionsAndIssuedCertificatesMutation {
		Admin {
			Staging {
				cleanUpUserInputRelatedToStaging(input: {}) {
					clientMutationId
				}
			}
		}
	}
`;

export const StagingScreenV2 = () => {
	const navigate = useNavigate();

	const [resetStagingTrees, isResettingStagingTrees] =
		useMutation<StagingScreenV2_ResetStagingTreesMutation>(RESET_STAGING_TREE_MUTATION);

	const [
		resetLearnStatesContentSubmissionsAndIssuedCertificates,
		isResettingLearnStatesContentSubmissionsAndIssuedCertificates,
	] = useMutation<StagingScreenV2_ResetLearnStatesContentSubmissionsAndIssuedCertificatesMutation>(
		RESET_LEARN_STATES_CONTENT_SUBMISSIONS_AND_ISSUED_CERTIFICATES_MUTATION,
	);

	const checkPermission = (permissions: Permission[]) =>
		useSelector((state: any) => selectHasPermissions(state, permissions));

	const isAdmin = checkPermission([
		"AccountPermission_System_Root",
		"UserInAccountPermission_System_Owner",
	]);

	if (!isAdmin) {
		return <div>Kein Zugriff</div>;
	}

	return (
		<BaseScreen>
			<h1>Staging-Funktionen</h1>

			<div className="flex flex-column">
				<Button
					className={"mb-5"}
					label={`Staging ${TREE_I18N_MAP(TREE_I18N_KEY.treePlural)} resetten`}
					disabled={isResettingStagingTrees}
					onClick={() => {
						resetStagingTrees({
							variables: {},
							onCompleted: () => {
								navigate(getTreeEditorPath(btoa("TreeNode:staging-root")));
							},
						});
					}}
				/>

				<Button
					className={"mb-5"}
					label={"Staging Lern-Fortschritte resetten"}
					disabled={isResettingLearnStatesContentSubmissionsAndIssuedCertificates}
					onClick={() => {
						resetLearnStatesContentSubmissionsAndIssuedCertificates({
							variables: {},
							onCompleted: () => {
								toast.success("Lern-Fortschritte erfolgreich zurückgesetzt.");
							},
						});
					}}
				/>
				<ProjectExtendStagingButtons />
			</div>
		</BaseScreen>
	);
};

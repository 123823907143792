import { PassContentConfigType } from "../../../__generated__/PassContentConfigEditor_TreeNodeFragment.graphql";
import { RestartIfFailedContentConfigType } from "../../../__generated__/RestartIfFailedContentConfigEditor_TreeNodeFragment.graphql";
import { RestartIfPassedContentConfigType } from "../../../__generated__/RestartIfPassedContentConfigEditor_TreeNodeFragment.graphql";
import { RewardContentConfigType } from "../../../__generated__/RewardContentConfigEditor_TreeNodeFragment.graphql";
import { VisibilityTreeConfigType } from "../../../__generated__/VisibilityTreeConfigEditor_TreeNodeFragment.graphql";
import { StartTreeConfigType } from "../../../__generated__/StartTreeConfigEditor_TreeNodeFragment.graphql";
import { ShowAnswerTreeConfigType } from "../../../__generated__/ShowAnswerTreeConfigEditor_TreeNodeFragment.graphql";
import { RewardTreeConfigType } from "../../../__generated__/RewardTreeConfigEditor_TreeNodeFragment.graphql";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../lms-admin-impl/i18n/tree.i18n";
import { EXTEND_AND_OVERRIDE_CONFIGS_TRANSLATIONS } from "../../../lms-admin-impl/i18n/extendAndOverrideConfigsTranslations.i18n";
import { StartContentConfigType } from "../../../__generated__/StartContentConfigEditor_TreeNodeFragment.graphql";

export type ConfigsLocalization = Partial<
	Record<
		| PassContentConfigType
		| RestartIfFailedContentConfigType
		| RestartIfPassedContentConfigType
		| VisibilityTreeConfigType
		| StartTreeConfigType
		| StartContentConfigType
		| ShowAnswerTreeConfigType
		| RewardTreeConfigType
		| RewardContentConfigType,
		string
	>
>;

export const CONFIGS_TRANSLATIONS: ConfigsLocalization = {
	"%future added value": "",
	PassContent_Always: "Bestehe unabhängig von der Anzahl korrekter Antworten",
	PassContent_OnPercentage:
		"Bestehe nur, wenn eine bestimmte Prozentzahl von Fragen richtig beantwortet wurde",
	RestartIfFailedContent_CanNot: `${TREE_I18N_MAP(
		TREE_I18N_KEY.thisContentNodeUppercase,
	)} ist nicht wiederholbar falls es nicht bestanden wurde`,
	RestartIfFailedContent_BlockTemporarily: "Blockiere temporär für X Tage",
	RestartIfFailedContent_BlockPermanently: "Blockiere permanent nach X Fehlversuchen",
	RestartIfPassedContent_Can: `${TREE_I18N_MAP(
		TREE_I18N_KEY.thisContentNodeUppercase,
	)} ist nach dem Bestehen wiederholbar`,
	RestartIfPassedContent_CanNot: `${TREE_I18N_MAP(
		TREE_I18N_KEY.thisContentNodeUppercase,
	)} ist nach dem Bestehen nicht wiederholbar`,
	RewardContent_Do: "Wird belohnt",
	RewardContent_IfRepeatedDoNot: "Wird bei Wiederholung nicht belohnt",
	VisibilityTree_Hide: `${TREE_I18N_MAP(TREE_I18N_KEY.thisContentNodeUppercase)} verstecken`,
	VisibilityTree_OnlyAdmins: `${TREE_I18N_MAP(TREE_I18N_KEY.tree)} verstecken (außer für Admins)`,
	VisibilityTree_OnlyIfTreeState: `${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)} verstecken (außer für Nutzer mit aktivem Lernstatus)`,
	VisibilityTree_NotAfterDateTime: `${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)} verstecken (ab einem bestimmten Datum)`,
	VisibilityTree_AfterDateTime: `${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)} anzeigen (ab einem bestimmten Datum)`,
	StartTree_CanNot: "Kann nicht starten",
	ShowAnswerTree_AfterDateTimeDo: "Zeige Lösungen (ab einem bestimmten Datum)",
	ShowAnswerTree_DoNot: "Zeige Lösungen nicht",
	RewardTree_DoNot: "Es werden keine Belohnungen verteilt",
	RewardTree_IfRepeatedDoNot: "Bei Wiederholung werden keine Belohnungen verteilt",
	RewardTree_AfterDateTimeDoNot:
		"Es werden keine Belohnungen verteilt (ab einem bestimmten Datum)",
	...EXTEND_AND_OVERRIDE_CONFIGS_TRANSLATIONS,
};

import React from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import * as Yup from "yup";
import styled from "styled-components";
// @ts-ignore
import { classNames } from "primereact/utils";
import { ValidatedField } from "../core/form/ValidatedField";
import { useMutation } from "react-relay";
import { useDispatch } from "react-redux";
import { LoginPrompt_LoginMutation } from "../../../__generated__/LoginPrompt_LoginMutation.graphql";
import { JwtTokenData, setLoggedIn } from "../../redux/slices/AuthSlice";
import graphql from "babel-plugin-relay/macro";

interface FormState {
	email: string;
	password: string;
}

const LOGIN_MUTATION = graphql`
	mutation LoginPrompt_LoginMutation($input: LoginInput!) {
		Auth {
			login(input: $input) {
				jwtTokens {
					accessToken
					refreshToken
				}
			}
		}
	}
`;

export const LoginPrompt = () => {
	const dispatch = useDispatch();
	const [login, isLoggingIn] = useMutation<LoginPrompt_LoginMutation>(LOGIN_MUTATION);

	const formik = useFormik<FormState>({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email("Bitte geben Sie eine gültige E-Mail ein")
				.required("Das Feld E-Mail wird benötigt."),
			password: Yup.string()
				.min(8, "Ein Passwort muss mindestens 8 Stellen lang sein.")
				.required("Das Feld Passwort wird benötigt."),
		}),
		onSubmit: (data) => {
			login({
				variables: {
					input: {
						email: data.email,
						password: data.password,
					},
				},
				onCompleted: (response) => {
					if (response.Auth.login?.jwtTokens) {
						dispatch(
							setLoggedIn({
								tokenData: response.Auth.login?.jwtTokens as JwtTokenData,
							}),
						);
					}
				},
			});
		},
	});

	return (
		<div className="flex justify-content-center align-items-center p-sidebar-full">
			<Card>
				<h1 className="text-center">Login</h1>
				<FormContainer onSubmit={formik.handleSubmit} className="p-fluid">
					<ValidatedField<FormState, string>
						name={"email"}
						label={"E-Mail"}
						iconClass={"pi-envelope"}
						formikConfig={formik}
						component={({ fieldValue, updateField, fieldName, isValid }) => {
							return (
								<InputText
									id={fieldName}
									name={fieldName}
									value={fieldValue}
									onChange={(e) => updateField(e.target.value)}
									className={classNames({ "p-invalid": !isValid })}
								/>
							);
						}}
					/>
					<ValidatedField<FormState, string>
						name={"password"}
						label={"Passwort"}
						formikConfig={formik}
						component={({ fieldValue, updateField, fieldName, isValid }) => {
							return (
								<Password
									id={fieldName}
									name={fieldName}
									value={fieldValue}
									onChange={(e) => updateField(e.target.value)}
									toggleMask
									feedback={false}
									className={classNames({ "p-invalid": !isValid })}
								/>
							);
						}}
					/>
					<Button
						disabled={isLoggingIn}
						type="submit"
						label="Einloggen"
						className="p-mt-2"
					/>
				</FormContainer>
			</Card>
		</div>
	);
};

const FormContainer = styled.form`
	min-width: 400px;
`;

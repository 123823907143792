import { graphql } from "babel-plugin-relay/macro";
import { commitMutation, Environment } from "react-relay";
import {
	AddHideForFachberaterVisibilityTreeConfigInput,
	HideForFachberaterVisibilityConfig_AddMutation,
} from "../../../../../../../../__generated__/HideForFachberaterVisibilityConfig_AddMutation.graphql";

const MUTATION = graphql`
	mutation HideForFachberaterVisibilityConfig_AddMutation(
		$input: AddHideForFachberaterVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				AddHideForFachberaterVisibilityTreeConfig(input: $input) {
					rootNode {
						... on TreeNode {
							id
							structureDefinition {
								... on RootStructureDefinition {
									visibilityConfigs {
										... on HideForFachberaterVisibilityTreeConfig {
											id
											configType
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

function CommitHideForFachberaterVisibilityConfig(
	environment: Environment,
	input: AddHideForFachberaterVisibilityTreeConfigInput,
) {
	return commitMutation<HideForFachberaterVisibilityConfig_AddMutation>(environment, {
		mutation: MUTATION,
		variables: { input },
		onCompleted: () => {},
		onError: () => {},
	});
}

export { CommitHideForFachberaterVisibilityConfig as AddInfectopharmHideForFachberaterVisibilityConfig };

import React from "react";
import { useMatch, useNavigate } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { ConnectionHandler, useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { EditLandingPageV2Screen_Query } from "../../__generated__/EditLandingPageV2Screen_Query.graphql";
import { EditLandingPageV2Screen_LandingPageV2Fragment$key } from "../../__generated__/EditLandingPageV2Screen_LandingPageV2Fragment.graphql";
import { EditLandingPageV2Screen_CreateLandingPageMutation } from "../../__generated__/EditLandingPageV2Screen_CreateLandingPageMutation.graphql";
import { EditLandingPageV2Screen_UpdateLandingPageMutation } from "../../__generated__/EditLandingPageV2Screen_UpdateLandingPageMutation.graphql";
import { BaseScreen } from "./BaseScreen";
import { EditLandingPageV2Form } from "../components/landing-pages/EditLandingPageV2Form";
import {
	getEditLandingPagePathV2,
	LANDING_PAGE_ID_URL_PARAM_V2,
	LANDING_PAGES_PATH_V2,
} from "../../app/routes/LandingPageModuleRoutes";

const QUERY = graphql`
	query EditLandingPageV2Screen_Query($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on LandingPageV2 {
				...EditLandingPageV2Screen_LandingPageV2Fragment
			}
		}
	}
`;

const LANDING_PAGE_FRAGMENT = graphql`
	fragment EditLandingPageV2Screen_LandingPageV2Fragment on LandingPageV2 {
		id
		title
		description
		urlParam
		image {
			id
			url
		}
		rootNodes {
			... on LearnOpportunitiesV2Connection {
				edges {
					node {
						id
					}
				}
			}
		}
	}
`;

const CREATE_MUTATION = graphql`
	mutation EditLandingPageV2Screen_CreateLandingPageMutation(
		$input: CreateLandingPageV2Input!
		$connections: [ID!]!
	) {
		Admin {
			LandingPageV2 {
				createLandingPageV2(input: $input) {
					data @appendEdge(connections: $connections) {
						node {
							...EditLandingPageV2Screen_LandingPageV2Fragment
						}
					}
				}
			}
		}
	}
`;

const UPDATE_MUTATION = graphql`
	mutation EditLandingPageV2Screen_UpdateLandingPageMutation($input: UpdateLandingPageV2Input!) {
		Admin {
			LandingPageV2 {
				updateLandingPageV2(input: $input) {
					data {
						node {
							...EditLandingPageV2Screen_LandingPageV2Fragment
						}
					}
				}
			}
		}
	}
`;

export const EditLandingPageV2Screen = () => {
	const navigate = useNavigate();

	const match = useMatch(getEditLandingPagePathV2(LANDING_PAGE_ID_URL_PARAM_V2));

	const query = useLazyLoadQuery<EditLandingPageV2Screen_Query>(QUERY, {
		id: match?.params.landingpageId || "",
		skip: !match?.params.landingpageId || match?.params.landingpageId === "new",
	});

	const landingPage = useFragment<EditLandingPageV2Screen_LandingPageV2Fragment$key>(
		LANDING_PAGE_FRAGMENT,
		query.node || null,
	);

	const [create, isCreating] =
		useMutation<EditLandingPageV2Screen_CreateLandingPageMutation>(CREATE_MUTATION);
	const [update, isUpdating] =
		useMutation<EditLandingPageV2Screen_UpdateLandingPageMutation>(UPDATE_MUTATION);
	const rootNodes = landingPage?.rootNodes.edges?.map((n) => n?.node!);
	return (
		<BaseScreen>
			<h1 className="mb-3 text-center">Seite Bearbeiten</h1>

			<EditLandingPageV2Form
				initialValues={{
					title: landingPage?.title ?? "Neue Seite",
					description: landingPage?.description ?? "Neue Bescreibung",
					urlParam: landingPage?.urlParam ?? "new-page",
					imageId: landingPage?.image?.id,
					rootNodeIds: rootNodes?.map((rootNode) => rootNode.id) ?? [],
				}}
				loading={isCreating || isUpdating}
				onSubmit={(values) => {
					if (landingPage) {
						update({
							variables: {
								input: {
									id: landingPage.id,
									data: values,
								},
							},
							onCompleted: () => {
								navigate(LANDING_PAGES_PATH_V2);
							},
						});
					} else {
						create({
							variables: {
								input: {
									data: values,
								},
								connections: [
									ConnectionHandler.getConnectionID(
										"client:root:Admin:LandingPageV2",
										"LandingPagesV2Table_LandingPages",
									),
								],
							},
							onCompleted: () => {
								navigate(LANDING_PAGES_PATH_V2);
							},
						});
					}
				}}
			/>
		</BaseScreen>
	);
};

/**
 * @generated SignedSource<<cf2c0aed53cc3a449816f9ade03651f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetAfterDateTimeDoShowAnswerTreeConfigInput = {
  dateTime: string;
  rootNodeId: string;
  clientMutationId?: string | null;
};
export type ShowAnswerTreeConfigEditor_SetAfterDateTimeDoShowAnswerTreeConfigMutation$variables = {
  input: SetAfterDateTimeDoShowAnswerTreeConfigInput;
};
export type ShowAnswerTreeConfigEditor_SetAfterDateTimeDoShowAnswerTreeConfigMutationVariables = ShowAnswerTreeConfigEditor_SetAfterDateTimeDoShowAnswerTreeConfigMutation$variables;
export type ShowAnswerTreeConfigEditor_SetAfterDateTimeDoShowAnswerTreeConfigMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly setAfterDateTimeDoShowAnswerTreeConfig: {
        readonly rootNode: {
          readonly " $fragmentSpreads": FragmentRefs<"ShowAnswerTreeConfigEditor_TreeNodeFragment">;
        };
      } | null;
    };
  };
};
export type ShowAnswerTreeConfigEditor_SetAfterDateTimeDoShowAnswerTreeConfigMutationResponse = ShowAnswerTreeConfigEditor_SetAfterDateTimeDoShowAnswerTreeConfigMutation$data;
export type ShowAnswerTreeConfigEditor_SetAfterDateTimeDoShowAnswerTreeConfigMutation = {
  variables: ShowAnswerTreeConfigEditor_SetAfterDateTimeDoShowAnswerTreeConfigMutationVariables;
  response: ShowAnswerTreeConfigEditor_SetAfterDateTimeDoShowAnswerTreeConfigMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShowAnswerTreeConfigEditor_SetAfterDateTimeDoShowAnswerTreeConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetAfterDateTimeDoShowAnswerTreeConfigPayload",
                "kind": "LinkedField",
                "name": "setAfterDateTimeDoShowAnswerTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ShowAnswerTreeConfigEditor_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShowAnswerTreeConfigEditor_SetAfterDateTimeDoShowAnswerTreeConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetAfterDateTimeDoShowAnswerTreeConfigPayload",
                "kind": "LinkedField",
                "name": "setAfterDateTimeDoShowAnswerTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "showAnswerConfig",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "configType",
                                    "storageKey": null
                                  },
                                  (v2/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "dateTime",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "AfterDateTimeDoShowAnswerTreeConfig",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bf777de2b0a7cb586c39644aed2565b6",
    "id": null,
    "metadata": {},
    "name": "ShowAnswerTreeConfigEditor_SetAfterDateTimeDoShowAnswerTreeConfigMutation",
    "operationKind": "mutation",
    "text": "mutation ShowAnswerTreeConfigEditor_SetAfterDateTimeDoShowAnswerTreeConfigMutation(\n  $input: SetAfterDateTimeDoShowAnswerTreeConfigInput!\n) {\n  Admin {\n    Tree {\n      setAfterDateTimeDoShowAnswerTreeConfig(input: $input) {\n        rootNode {\n          ...ShowAnswerTreeConfigEditor_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment AfterDateTimeDoShowAnswerTreeConfigForm_AfterDateTimeDoShowAnswerTreeConfigFragment on AfterDateTimeDoShowAnswerTreeConfig {\n  id\n  configType\n  dateTime\n}\n\nfragment AfterDateTimeDoShowAnswerTreeConfigForm_TreeNodeFragment on TreeNode {\n  id\n}\n\nfragment ShowAnswerTreeConfigEditor_TreeNodeFragment on TreeNode {\n  id\n  structureDefinition {\n    __typename\n    ... on RootStructureDefinition {\n      showAnswerConfig {\n        __typename\n        configType\n        ... on AfterDateTimeDoShowAnswerTreeConfig {\n          dateTime\n        }\n        ...AfterDateTimeDoShowAnswerTreeConfigForm_AfterDateTimeDoShowAnswerTreeConfigFragment\n        id\n      }\n    }\n  }\n  ...AfterDateTimeDoShowAnswerTreeConfigForm_TreeNodeFragment\n}\n"
  }
};
})();

(node as any).hash = "fbe3472077faad1dab60cc208bdda79e";

export default node;

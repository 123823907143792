import { useMatch, useNavigate } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { ConnectionHandler, useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { EditInstructorScreen_Query } from "../../__generated__/EditInstructorScreen_Query.graphql";
import { EditInstructorScreen_InstructorFragment$key } from "../../__generated__/EditInstructorScreen_InstructorFragment.graphql";
import { EditInstructorForm } from "../components/instructors/EditInstructorForm";
import { EditInstructorScreen_CreateMutation } from "../../__generated__/EditInstructorScreen_CreateMutation.graphql";
import { EditInstructorScreen_UpdateMutation } from "../../__generated__/EditInstructorScreen_UpdateMutation.graphql";
import { BaseScreen } from "./BaseScreen";

const QUERY = graphql`
	query EditInstructorScreen_Query($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on Instructor {
				...EditInstructorScreen_InstructorFragment
			}
		}
	}
`;

const INSTRUCTOR_FRAGMENT = graphql`
	fragment EditInstructorScreen_InstructorFragment on InstructorImpl {
		id
		superId
		name
		title
		description
		location
		portraitFile {
			id
			url
		}
		signatureFile {
			id
			url
		}
	}
`;

const CREATE_MUTATION = graphql`
	mutation EditInstructorScreen_CreateMutation(
		$input: CreateInstructorImplInput!
		$connections: [ID!]!
	) {
		Admin {
			Instructor {
				createInstructorImpl(input: $input) {
					data @appendEdge(connections: $connections) {
						node {
							...EditInstructorScreen_InstructorFragment
						}
					}
				}
			}
		}
	}
`;

const UPDATE_MUTATION = graphql`
	mutation EditInstructorScreen_UpdateMutation($input: UpdateInstructorImplInput!) {
		Admin {
			Instructor {
				updateInstructorImpl(input: $input) {
					data {
						node {
							id
							superId
							name
							...EditInstructorScreen_InstructorFragment
						}
					}
				}
			}
		}
	}
`;

export const EDIT_INSTRUCTOR_ROUTE = "/instructors/:instructorId/edit";

export const EditInstructorScreen = () => {
	const navigate = useNavigate();
	const match = useMatch(EDIT_INSTRUCTOR_ROUTE);
	const query = useLazyLoadQuery<EditInstructorScreen_Query>(QUERY, {
		id: match?.params.instructorId || "",
		skip: !match?.params.instructorId || match?.params.instructorId === "new",
	});

	const instructor = useFragment<EditInstructorScreen_InstructorFragment$key>(
		INSTRUCTOR_FRAGMENT,
		query.node || null,
	);
	const [create, isCreating] = useMutation<EditInstructorScreen_CreateMutation>(CREATE_MUTATION);
	const [update, isUpdating] = useMutation<EditInstructorScreen_UpdateMutation>(UPDATE_MUTATION);

	return (
		<BaseScreen>
			<h1 className="mb-3">Bearbeite Lehrenden</h1>
			<EditInstructorForm
				initialValues={{
					name: instructor?.name ?? "Neuer Lehrender",
					title: instructor?.title || undefined,
					description: instructor?.description || undefined,
					location: instructor?.location || undefined,
					portraitFileRef: instructor?.portraitFile?.id,
					signatureFileRef: instructor?.signatureFile?.id,
				}}
				loading={isCreating || isUpdating}
				onSubmit={(values) => {
					if (instructor) {
						update({
							variables: {
								input: {
									id: instructor.superId,
									data: values,
								},
							},
							onCompleted: () => {
								navigate("/instructors");
							},
						});
					} else {
						create({
							variables: {
								input: {
									data: values,
								},
								connections: [
									ConnectionHandler.getConnectionID(
										"client:root:Admin:Instructor",
										"InstructorsTable_Instructors",
									),
								],
							},
							onCompleted: () => {
								navigate("/instructors");
							},
						});
					}
				}}
			/>
		</BaseScreen>
	);
};

/**
 * @generated SignedSource<<1381f9dceb741514ce0aa5c61e305d5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateNewstickerMessageInput = {
  text: string;
  forwardingUrl?: string | null;
  clientMutationId?: string | null;
};
export type NewsTickerDefinitionOverviewScreen_CreateNewsTickerDefinitionMutation$variables = {
  input: CreateNewstickerMessageInput;
};
export type NewsTickerDefinitionOverviewScreen_CreateNewsTickerDefinitionMutationVariables = NewsTickerDefinitionOverviewScreen_CreateNewsTickerDefinitionMutation$variables;
export type NewsTickerDefinitionOverviewScreen_CreateNewsTickerDefinitionMutation$data = {
  readonly Admin: {
    readonly Newsticker: {
      readonly createNewstickerMessage: {
        readonly newstickerMessage: {
          readonly id: string;
          readonly text: string;
          readonly forwardingUrl: string | null;
          readonly isActive: boolean | null;
        };
      } | null;
    };
  };
};
export type NewsTickerDefinitionOverviewScreen_CreateNewsTickerDefinitionMutationResponse = NewsTickerDefinitionOverviewScreen_CreateNewsTickerDefinitionMutation$data;
export type NewsTickerDefinitionOverviewScreen_CreateNewsTickerDefinitionMutation = {
  variables: NewsTickerDefinitionOverviewScreen_CreateNewsTickerDefinitionMutationVariables;
  response: NewsTickerDefinitionOverviewScreen_CreateNewsTickerDefinitionMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NewstickerAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Newsticker",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "CreateNewstickerMessagePayload",
            "kind": "LinkedField",
            "name": "createNewstickerMessage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NewstickerMessage",
                "kind": "LinkedField",
                "name": "newstickerMessage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "text",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "forwardingUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isActive",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewsTickerDefinitionOverviewScreen_CreateNewsTickerDefinitionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewsTickerDefinitionOverviewScreen_CreateNewsTickerDefinitionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "81e4de27cbf96276a81d75026e7af348",
    "id": null,
    "metadata": {},
    "name": "NewsTickerDefinitionOverviewScreen_CreateNewsTickerDefinitionMutation",
    "operationKind": "mutation",
    "text": "mutation NewsTickerDefinitionOverviewScreen_CreateNewsTickerDefinitionMutation(\n  $input: CreateNewstickerMessageInput!\n) {\n  Admin {\n    Newsticker {\n      createNewstickerMessage(input: $input) {\n        newstickerMessage {\n          id\n          text\n          forwardingUrl\n          isActive\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "06ecd9f8fcf2e05cbdd0a0c0f1623f41";

export default node;

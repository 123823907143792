import { PrivateRouteDefinition } from "../../infecto-lms-admin/components/core/model/PrivateRouteDefinition";
import { TagsScreen } from "../../infecto-lms-admin/screens/TagsScreen";
import { EditTagScreen } from "../../infecto-lms-admin/screens/EditTagScreen";

export const EDIT_TAG_PATH = "/tags/:tagId/edit";

export const TagModuleRoutes: PrivateRouteDefinition[] = [
	{
		path: "/tags",
		element: <TagsScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_Tags_ReadTags",
		],
	},
	{
		path: EDIT_TAG_PATH,
		element: <EditTagScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_Tags_UpdateTags",
		],
	},
];

import { useFormik } from "formik";
import { Button } from "primereact/button";
import React from "react";
import * as Yup from "yup";
import { LimitedFileSelectionField } from "../files/FileSelectionField";
import { ValidatedField } from "../core/form/ValidatedField";
import { DefaultTextFieldComponent } from "../core/form/DefaultTextInput";
import { DefaultTextEditorComponent } from "../core/form/DefaultTextEditorComponent";
import { WarningUnsavedChangesDialog } from "../core/dialog/WarningUnsavedChangesDialog";
import { useTypedDispatch, useTypedSelector } from "../../redux/Store";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
	selectArrayOfEditedForms,
} from "../../redux/slices/CoreSlice";
import { useCallbackPrompt } from "../../functions/hooks/UseCallBackPrompt";

export interface FormState {
	name: string;
	title?: string;
	description?: string;
	portraitFileRef?: string;
	signatureFileRef?: string;
	location?: string;
}

interface OwnProps {
	loading?: boolean;
	initialValues?: FormState;
	onSubmit: (values: FormState) => void;
}

export const EditInstructorForm = ({ loading, initialValues, onSubmit }: OwnProps) => {
	const dispatch = useTypedDispatch();
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);
	const [showPrompt, confirmNavigation, cancelNavigation, setShowPrompt] = useCallbackPrompt(
		arrayOfEditedForms.length > 0,
	);

	const formId = "EditInstructorForm";
	const formik = useFormik<FormState>({
		initialValues: {
			name: initialValues?.name ?? "Neuer Lehrender",
			title: initialValues?.title,
			description: initialValues?.description,
			portraitFileRef: initialValues?.portraitFileRef,
			location: initialValues?.location,
			signatureFileRef: initialValues?.signatureFileRef,
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Name wird benötigt"),
		}),
		onSubmit: (values, { setSubmitting }) => {
			onSubmit({
				...values,
			});
			setSubmitting(false);
			dispatch(resetArrayOfEditedForms());
		},
	});

	return (
		<>
			{" "}
			{showPrompt && (
				<WarningUnsavedChangesDialog
					confirmNavigation={confirmNavigation}
					setShowDialog={setShowPrompt}
					cancelNavigation={cancelNavigation}
				/>
			)}
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, string>
					name={"name"}
					label={"Name"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<FormState, string>
					name={"title"}
					label={"Titel"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<FormState, string>
					name={"description"}
					label={"Beschreibung"}
					component={DefaultTextEditorComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					showListButtons={false}
					formikConfig={formik}
				/>
				<ValidatedField<FormState, string>
					name={"location"}
					label={"Wirkungsort"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<FormState, string>
					name={"portraitFileRef"}
					label={"Profile Bild"}
					helpText={"Profile Bild"}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={LimitedFileSelectionField(["image/png", "image/jpg", "image/jpeg"])}
					formikConfig={formik}
				/>
				<ValidatedField<FormState, string>
					name={"signatureFileRef"}
					label={"Signatur-Bild"}
					helpText={"Signatur für das Zertifikat."}
					component={LimitedFileSelectionField(["image/png", "image/jpg", "image/jpeg"])}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>

				<Button disabled={loading} type="submit" label="Speichern" className="p-mt-2" />
			</form>
		</>
	);
};

import React from "react";
import * as Yup from "yup";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation, Environment, useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import {
	RenderConfig,
	ValidatedField,
} from "../../../../../../../../infecto-lms-admin/components/core/form/ValidatedField";
import { TreeConfigurationEditDialogProps } from "../../../../../../../../infecto-lms-admin/components/tree/editor/node/config/components/Config.interfaces";
import { MultiSelect } from "primereact/multiselect";
import { ConfigDatePicker } from "../../../../../../../../infecto-lms-admin/components/tree/editor/node/config/components/ConfigDatePicker";
import { CONFIGS_TRANSLATIONS } from "../../../../../../../../infecto-lms-admin/i18n/config/i18n";
import {
	AddSpecificUserStatusesCanStartTreeConfigInput,
	SpecificUserStatusesCanStartTreeConfigForm_AddMutation,
	UserStatusInput,
} from "../../../../../../../../__generated__/SpecificUserStatusesCanStartTreeConfigForm_AddMutation.graphql";
import { SpecificUserStatusesCanStartTreeConfigForm_TreeNodeFragment$key } from "../../../../../../../../__generated__/SpecificUserStatusesCanStartTreeConfigForm_TreeNodeFragment.graphql";
import {
	SpecificUserStatusesCanStartTreeConfigForm_StartTreeConfigFragment$key,
	UserStatus,
} from "../../../../../../../../__generated__/SpecificUserStatusesCanStartTreeConfigForm_StartTreeConfigFragment.graphql";
import { SpecificUserStatusesCanStartTreeConfigForm_EditMutation } from "../../../../../../../../__generated__/SpecificUserStatusesCanStartTreeConfigForm_EditMutation.graphql";
import { GLOBAL_USER_STATUS_CONTENT_TRANSLATIONS } from "../../../../../../../../infecto-lms-admin/i18n/user-statuu-types";

const CONFIGURATION_FRAGMENT = graphql`
	fragment SpecificUserStatusesCanStartTreeConfigForm_StartTreeConfigFragment on StartTreeConfig {
		... on SpecifiedUserStatusesCanStartTreeConfig {
			id
			configType
			dateTime
			userStatuses
		}
	}
`;

const TREE_NODE_FRAGMENT = graphql`
	fragment SpecificUserStatusesCanStartTreeConfigForm_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			... on RootStructureDefinition {
				startConfigs {
					...SpecificUserStatusesCanStartTreeConfigForm_StartTreeConfigFragment
				}
			}
		}
	}
`;

const ADD_CONFIGURATION_MUTATION = graphql`
	mutation SpecificUserStatusesCanStartTreeConfigForm_AddMutation(
		$input: AddSpecificUserStatusesCanStartTreeConfigInput!
	) {
		Admin {
			Tree {
				addSpecificUserStatusesCanStartTreeConfig(input: $input) {
					rootNode {
						...SpecificUserStatusesCanStartTreeConfigForm_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const EDIT_CONFIGURATION_MUTATION = graphql`
	mutation SpecificUserStatusesCanStartTreeConfigForm_EditMutation(
		$input: EditSpecificUserStatusesCanStartTreeConfigInput!
	) {
		Admin {
			Tree {
				EditSpecificUserStatusesCanStartTreeConfig(input: $input) {
					rootNode {
						...SpecificUserStatusesCanStartTreeConfigForm_TreeNodeFragment
					}
				}
			}
		}
	}
`;

function CommitAddSpecificUserStatusesCanStartTreeConfig(
	environment: Environment,
	input: AddSpecificUserStatusesCanStartTreeConfigInput,
) {
	return commitMutation<SpecificUserStatusesCanStartTreeConfigForm_AddMutation>(environment, {
		mutation: ADD_CONFIGURATION_MUTATION,
		variables: { input },
		onCompleted: () => {},
		onError: () => {},
	});
}

interface FormState {
	date: string;
	userStatuses: UserStatusInput[];
}

interface Props extends TreeConfigurationEditDialogProps {
	treeNodeFragmentRef: SpecificUserStatusesCanStartTreeConfigForm_TreeNodeFragment$key;
	configurationFragmentRef: SpecificUserStatusesCanStartTreeConfigForm_StartTreeConfigFragment$key;
	onBack?: () => void;
}

export const SpecificUserStatusesCanStartTreeConfigForm = ({
	configurationFragmentRef,
	treeNodeFragmentRef,
	onBack,
}: Props) => {
	const treeNode = useFragment<SpecificUserStatusesCanStartTreeConfigForm_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const configuration =
		useFragment<SpecificUserStatusesCanStartTreeConfigForm_StartTreeConfigFragment$key>(
			CONFIGURATION_FRAGMENT,
			configurationFragmentRef,
		);
	const [edit, isEditing] = useMutation<SpecificUserStatusesCanStartTreeConfigForm_EditMutation>(
		EDIT_CONFIGURATION_MUTATION,
	);

	const formik = useFormik<FormState>({
		initialValues: {
			date: configuration.dateTime as string,
			userStatuses: [
				...(configuration.userStatuses?.filter(
					(e: any) => e !== "noProcess",
				) as UserStatusInput[]),
			],
		},
		validationSchema: Yup.object().shape({
			userStatuses: Yup.array().required("Das Feld Benötigte Stati wird benötigt."),
		}),
		onSubmit: (values, formikHelpers) => {
			edit({
				variables: {
					input: {
						rootNodeId: treeNode.id,
						configId: configuration.id as string,
						newDateTime: values.date,
						newUserStatuses: values.userStatuses,
					},
				},
				onCompleted: () => {
					formikHelpers.setTouched({});
					onBack!();
				},
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, UserStatus[]>
				name={"userStatuses"}
				label={CONFIGS_TRANSLATIONS[configuration.configType!]}
				component={({ fieldName, updateField, fieldValue }) => {
					return (
						<MultiSelect
							name={fieldName}
							value={fieldValue}
							onChange={(e) => {
								updateField(e.value);
							}}
							options={Object.entries(GLOBAL_USER_STATUS_CONTENT_TRANSLATIONS).map(
								(x) => {
									return {
										value: x[0],
										label: x[1],
									};
								},
							)}
						/>
					);
				}}
				formikConfig={formik}
			/>

			<ValidatedField<FormState, string>
				name={"date"}
				label={"Datum"}
				className="mb-4"
				formikConfig={formik}
				component={({
					fieldName,
					fieldValue,
					updateField,
					disabled,
				}: RenderConfig<string>) => {
					return (
						<ConfigDatePicker
							name={fieldName}
							value={fieldValue}
							onUpdate={updateField}
							disabled={disabled}
							isEndOfTheDay={true}
						/>
					);
				}}
			/>

			<Button
				disabled={Object.entries(formik.touched).length === 0 || isEditing}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					// formik.resetForm();
					onBack!();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};

export { CommitAddSpecificUserStatusesCanStartTreeConfig as AddSpecificUserStatusesCanStartTreeConfigMutation };

/**
 * @generated SignedSource<<5fa0a0beb588502ce79515f059044ecb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StructureType = "root" | "child" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SetNodeExtensionsForm_TreeNodeFragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly definitionType: StructureType;
    readonly extension?: {
      readonly vNumbers?: ReadonlyArray<{
        readonly number: string;
        readonly from: string;
        readonly to: string;
        readonly password: string;
      }>;
      readonly category?: string | null;
      readonly certifier?: string | null;
      readonly certifierRef?: string | null;
      readonly location?: string | null;
      readonly internalCode?: string | null;
      readonly signatureFile?: string;
      readonly lengthInSeconds?: number | null;
      readonly lengthInSecondsOverride?: number | null;
    };
  };
  readonly typeDefinition: {
    readonly extension?: {
      readonly lengthInSecondsOverride?: number | null;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"VNumbersEditor_TreeNodeFragment">;
  readonly " $fragmentType": "SetNodeExtensionsForm_TreeNodeFragment";
};
export type SetNodeExtensionsForm_TreeNodeFragment = SetNodeExtensionsForm_TreeNodeFragment$data;
export type SetNodeExtensionsForm_TreeNodeFragment$key = {
  readonly " $data"?: SetNodeExtensionsForm_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SetNodeExtensionsForm_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lengthInSecondsOverride",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SetNodeExtensionsForm_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "definitionType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "VExtension",
                      "kind": "LinkedField",
                      "name": "vNumbers",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "number",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "from",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "to",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "password",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "category",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "certifier",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "certifierRef",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "location",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "internalCode",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "signatureFile",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lengthInSeconds",
                      "storageKey": null
                    },
                    (v0/*: any*/)
                  ],
                  "type": "RootExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "type": "ContentExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VNumbersEditor_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "ecdcdfd237c510b9b2525f0322b3df63";

export default node;

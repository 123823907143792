import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

export function useUrlFilterState<FilterStateType>(keepUrl?: boolean) {
	const [searchParams, setSearchParams] = useSearchParams();

	const initialFiltersString = searchParams.get("filters");
	let initialFilters = {};
	try {
		initialFilters = initialFiltersString ? JSON.parse(atob(initialFiltersString)) : {};
	} catch {}

	const [state, setState] = useState<FilterStateType>(initialFilters as FilterStateType);

	useEffect(() => {
		if (Object.values(state).length > 0) {
			const filterJson = btoa(JSON.stringify(state));
			if (!keepUrl) {
				setSearchParams({ filters: filterJson }, { replace: true });
			}
		}
		// eslint-disable-next-line
	}, [state]);

	return {
		state,
		setState,
	};
}

import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ContentNodeTitleColumn_PointsAdditionFragment$key } from "../../../../__generated__/ContentNodeTitleColumn_PointsAdditionFragment.graphql";

const POINTS_ADDITION_FRAGMENT = graphql`
	fragment ContentNodeTitleColumn_PointsAdditionFragment on PointsAdditionV2 {
		contentSubmission {
			learnOpportunity {
				structureDefinition {
					title
				}
			}
		}
	}
`;

interface OwnProps {
	pointsAdditionFragmentRef: ContentNodeTitleColumn_PointsAdditionFragment$key;
}

export const ContentNodeTitleColumn = ({ pointsAdditionFragmentRef }: OwnProps) => {
	const pointsAddition = useFragment<ContentNodeTitleColumn_PointsAdditionFragment$key>(
		POINTS_ADDITION_FRAGMENT,
		pointsAdditionFragmentRef,
	);

	return (
		<div>{pointsAddition.contentSubmission?.learnOpportunity?.structureDefinition.title}</div>
	);
};

/**
 * @generated SignedSource<<4fcf300865c7e65e48c76b99e4a840e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShowCertificateButton_CertificateFragment$data = {
  readonly name: string;
  readonly templateFile: {
    readonly url: string | null;
  } | null;
  readonly " $fragmentType": "ShowCertificateButton_CertificateFragment";
};
export type ShowCertificateButton_CertificateFragment = ShowCertificateButton_CertificateFragment$data;
export type ShowCertificateButton_CertificateFragment$key = {
  readonly " $data"?: ShowCertificateButton_CertificateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShowCertificateButton_CertificateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShowCertificateButton_CertificateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "templateFile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CertificateTemplate",
  "abstractKey": null
};

(node as any).hash = "68a1fea44b2c391aff26a3034b160d54";

export default node;

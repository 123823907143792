/**
 * @generated SignedSource<<c13d078d5e9151a42888331a186ec0a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StartContentConfigType = "StartContent_SpecificUserStatusesCan" | "StartContent_CanIfInTreeFlow" | "StartContent_CanNot" | "%future added value";
export type UserStatus = "noProcess" | "initialValidation" | "changeInProgress" | "doccheck" | "inactive" | "efnNeeded" | "active" | "activeEfnNeeded" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SpecificUserStatusesCanStartContentConfigForm_StartContentConfigFragment$data = {
  readonly id?: string;
  readonly configType?: StartContentConfigType;
  readonly dateTime?: string | null;
  readonly requiredUserStatuses?: ReadonlyArray<UserStatus>;
  readonly " $fragmentType": "SpecificUserStatusesCanStartContentConfigForm_StartContentConfigFragment";
};
export type SpecificUserStatusesCanStartContentConfigForm_StartContentConfigFragment = SpecificUserStatusesCanStartContentConfigForm_StartContentConfigFragment$data;
export type SpecificUserStatusesCanStartContentConfigForm_StartContentConfigFragment$key = {
  readonly " $data"?: SpecificUserStatusesCanStartContentConfigForm_StartContentConfigFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SpecificUserStatusesCanStartContentConfigForm_StartContentConfigFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpecificUserStatusesCanStartContentConfigForm_StartContentConfigFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "configType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dateTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiredUserStatuses",
          "storageKey": null
        }
      ],
      "type": "SpecifiedUserStatusesCanStartContentConfig",
      "abstractKey": null
    }
  ],
  "type": "StartContentConfig",
  "abstractKey": "__isStartContentConfig"
};

(node as any).hash = "a0a155d8a5856c14236700fd7ba92147";

export default node;

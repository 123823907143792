import { MessageDefinitionOverviewScreen } from "../../infecto-lms-admin/screens/MessageDefinitionOverviewScreen";
import { PrivateRouteDefinition } from "../../infecto-lms-admin/components/core/model/PrivateRouteDefinition";
import {
	MessageSenderScreen,
	SEND_MESSAGE_DEFINITION_ROUTE,
} from "../../infecto-lms-admin/screens/MessageSenderScreen";
import {
	EDIT_MESSAGE_DEFINITION_ROUTE,
	EditMessageDefinitionScreen,
} from "../../infecto-lms-admin/screens/EditMessageDefinitionScreen";

export const MessagingRoutes: PrivateRouteDefinition[] = [
	{
		path: "/messaging",
		element: <MessageDefinitionOverviewScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_Messaging_ReadMessages",
		],
	},
	{
		path: EDIT_MESSAGE_DEFINITION_ROUTE,
		element: <EditMessageDefinitionScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_Messaging_UpdateMessages",
		],
	},
	{
		path: SEND_MESSAGE_DEFINITION_ROUTE,
		element: <MessageSenderScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_Messaging_SendMessages",
		],
	},
];

import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { DateTimeDisplay } from "../../core/datetime/DateTimeDisplay";
import { LastUpdatedAtColumn_ContentSubmissionFragment$key } from "../../../../__generated__/LastUpdatedAtColumn_ContentSubmissionFragment.graphql";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment LastUpdatedAtColumn_ContentSubmissionFragment on ContentSubmission {
		lastUpdated
	}
`;

interface OwnProps {
	contentSubmissionFragmentRef: LastUpdatedAtColumn_ContentSubmissionFragment$key;
}

export const LastUpdatedAtColumn = ({ contentSubmissionFragmentRef }: OwnProps) => {
	const contentSubmission = useFragment<LastUpdatedAtColumn_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	return <DateTimeDisplay value={contentSubmission.lastUpdated} />;
};

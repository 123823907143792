import { ValidatedField } from "../../../../infecto-lms-admin/components/core/form/ValidatedField";
import { Button } from "primereact/button";
import React, { useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import { InfectoDefaultCalendarComponent } from "../../../../infecto-lms-admin/components/form/InfectoDefaultCalendarComponent";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import { FachberaterCertificatesAndLettersExporter_ExportCertificatesMutation } from "../../../../__generated__/FachberaterCertificatesAndLettersExporter_ExportCertificatesMutation.graphql";
import { FachberaterCertificatesAndLettersExporter_ExportLettersMutation } from "../../../../__generated__/FachberaterCertificatesAndLettersExporter_ExportLettersMutation.graphql";
import { useTypedSelector } from "../../../../infecto-lms-admin/redux/Store";
import {
	selectDecodedAccountId,
	selectJwtClaimData,
	selectLoginData,
} from "../../../../infecto-lms-admin/redux/slices/AuthSlice";
import { RefreshToken } from "../../../../infecto-lms-admin/redux/slices/RefreshToken";

interface FormState {
	fromDate?: string;
	toDate?: string;
}

interface OwnProps {
	nodeId: string;
}

const EXPORT_CERTIFICATES_MUTATION = graphql`
	mutation FachberaterCertificatesAndLettersExporter_ExportCertificatesMutation(
		$input: ExportFachberaterCertificatesInput!
	) {
		Admin {
			Tree {
				exportFachberaterCertificates(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;

const EXPORT_LETTERS_MUTATION = graphql`
	mutation FachberaterCertificatesAndLettersExporter_ExportLettersMutation(
		$input: ExportFachberaterLettersInput!
	) {
		Admin {
			Tree {
				exportFachberaterLetters(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;

type ExportType = "certificates" | "letters";

export const FachberaterCertificatesAndLettersExporter = ({ nodeId }: OwnProps) => {
	const loginData = useTypedSelector(selectLoginData);
	const accountId = useTypedSelector(selectDecodedAccountId);
	const jwtClaimData = useTypedSelector(selectJwtClaimData);
	let accessToken = loginData?.accessToken;

	const [selectedButton, setSelectedButton] = useState<ExportType>("certificates");

	const [isDownloadCertificatesButtonVisible, setDownloadCertificatesButtonVisibility] =
		useState(false);
	const [isDownloadLettersButtonVisible, setDownloadLettersButtonVisibility] = useState(false);
	const [isGeneratingCertificatesData, setIsGeneratingCertificatesData] = useState(false);
	const [isGeneratingLettersData, setIsGeneratingLettersData] = useState(false);
	const [isCertificatesFileDownloading, setIsCertificatesFileDownloading] = useState(false);
	const [isLettersFileDownloading, setIsLettersFileDownloading] = useState(false);

	const [exportCertificates] =
		useMutation<FachberaterCertificatesAndLettersExporter_ExportCertificatesMutation>(
			EXPORT_CERTIFICATES_MUTATION,
		);

	const [exportLetters] =
		useMutation<FachberaterCertificatesAndLettersExporter_ExportLettersMutation>(
			EXPORT_LETTERS_MUTATION,
		);

	const startCertificatesPolling = () => {
		const checkGenerationPoll = setInterval(() => {
			fetch(
				`${process.env.REACT_APP_API_BASE}/api/check-fachberater-certificates?token=${accessToken}&accountId=${accountId}`,
			).then(async (res) => {
				if (res.status === 200) {
					clearInterval(checkGenerationPoll);
					setIsGeneratingCertificatesData(false);
					setDownloadCertificatesButtonVisibility(true);
				} else if (res.status === 401) {
					accessToken = await RefreshToken(
						loginData?.refreshToken as string,
						jwtClaimData?.accountId as string,
					);
				}
			});
		}, 5000);
	};

	const startLettersPolling = () => {
		const checkGenerationPoll = setInterval(() => {
			fetch(
				`${process.env.REACT_APP_API_BASE}/api/check-fachberater-letters?token=${accessToken}&accountId=${accountId}`,
			).then(async (res) => {
				if (res.status === 200) {
					clearInterval(checkGenerationPoll);
					setIsGeneratingLettersData(false);
					setDownloadLettersButtonVisibility(true);
				} else if (res.status === 401) {
					accessToken = await RefreshToken(
						loginData?.refreshToken as string,
						jwtClaimData?.accountId as string,
					);
				}
			});
		}, 5000);
	};

	const formik = useFormik<FormState>({
		enableReinitialize: true,
		initialValues: {
			fromDate: undefined,
			toDate: undefined,
		},
		onSubmit: (values: FormState) => {
			if (selectedButton === "certificates") {
				exportCertificates({
					variables: {
						input: {
							contentNodeId: nodeId,
							fromDateOpt: values.fromDate,
							toDateOpt: values.toDate,
						},
					},
					onCompleted: () => {
						setDownloadCertificatesButtonVisibility(false);
						setIsGeneratingCertificatesData(true);
						startCertificatesPolling();
					},
					onError: () => {
						toast.error("Export könnte nicht gestartet werden");
					},
				});
			} else {
				exportLetters({
					variables: {
						input: {
							contentNodeId: nodeId,
							fromDateOpt: values.fromDate,
							toDateOpt: values.toDate,
						},
					},
					onCompleted: () => {
						setDownloadLettersButtonVisibility(false);
						setIsGeneratingLettersData(true);
						startLettersPolling();
					},
					onError: () => {
						toast.error("Export könnte nicht gestartet werden");
					},
				});
			}
		},
	});

	return (
		<>
			<h2>Fachberater Zertifikate Exporter</h2>
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<FachberaterCertificatesExporterDatesWrapper>
					<FachberaterCertificatesExporterDatePicker className={"mr-1"}>
						<ValidatedField<FormState, string>
							name={"fromDate"}
							label={"von"}
							helpText={"Der Zeitpunkt der Veranstaltung"}
							component={({
								isValid,
								fieldName,
								updateField,
								fieldValue,
								disabled,
								required,
								onChange,
							}) => (
								<InfectoDefaultCalendarComponent
									isValid={isValid}
									fieldName={fieldName}
									updateField={updateField}
									fieldValue={fieldValue}
									disabled={disabled}
									required={required}
									onChange={onChange}
									dateFormat={"YYYY-MM-DDTHH:mmZ"}
								/>
							)}
							formikConfig={formik}
						/>
					</FachberaterCertificatesExporterDatePicker>

					<FachberaterCertificatesExporterDatePicker className={"ml-1"}>
						<ValidatedField<FormState, string>
							name={"toDate"}
							label={"bis"}
							helpText={"Der Zeitpunkt der Veranstaltung"}
							component={({
								isValid,
								fieldName,
								updateField,
								fieldValue,
								disabled,
								required,
								onChange,
							}) => (
								<InfectoDefaultCalendarComponent
									isValid={isValid}
									fieldName={fieldName}
									updateField={updateField}
									fieldValue={fieldValue}
									disabled={disabled}
									required={required}
									onChange={onChange}
									dateFormat={"YYYY-MM-DDTHH:mmZ"}
								/>
							)}
							formikConfig={formik}
						/>
					</FachberaterCertificatesExporterDatePicker>
				</FachberaterCertificatesExporterDatesWrapper>

				<span className="p-buttonset mb-2">
					<FachberaterCertificatesExporterButton
						onClick={() => setSelectedButton("certificates")}
						loading={isGeneratingCertificatesData}
						label="Zertifikate Exportieren"
						className={"mr-1"}
						type={"submit"}
					/>
					{isDownloadCertificatesButtonVisible && (
						<Button
							icon="pi pi-download"
							loading={isCertificatesFileDownloading}
							onClick={() => {
								setIsCertificatesFileDownloading(true);

								RefreshToken(
									loginData?.refreshToken as string,
									jwtClaimData?.accountId as string,
								).then((currentAccessToken) => {
									setDownloadCertificatesButtonVisibility(false);
									setIsCertificatesFileDownloading(false);

									window.open(
										`${process.env.REACT_APP_API_BASE}/api/download-fachberater-certificates?token=${currentAccessToken}&accountId=${accountId}`,
									);
								});
							}}
						/>
					)}
				</span>

				<span className="p-buttonset">
					<FachberaterCertificatesExporterButton
						onClick={() => setSelectedButton("letters")}
						loading={isGeneratingLettersData}
						label="Anschreiben Exportieren"
						className={"mr-1"}
						type={"submit"}
					/>
					{isDownloadLettersButtonVisible && (
						<Button
							icon="pi pi-download"
							loading={isLettersFileDownloading}
							onClick={() => {
								setIsLettersFileDownloading(true);

								RefreshToken(
									loginData?.refreshToken as string,
									jwtClaimData?.accountId as string,
								).then((currentAccessToken) => {
									setIsLettersFileDownloading(false);
									setDownloadLettersButtonVisibility(false);

									window.open(
										`${process.env.REACT_APP_API_BASE}/api/download-fachberater-letters?token=${currentAccessToken}&accountId=${accountId}`,
									);
								});
							}}
						/>
					)}
				</span>
			</form>
		</>
	);
};

const FachberaterCertificatesExporterDatesWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const FachberaterCertificatesExporterDatePicker = styled.div`
	flex: 1;
`;

const FachberaterCertificatesExporterButton = styled(Button)`
	flex: 4;
`;

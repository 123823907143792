import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import React from "react";
import { DefaultIntegerFieldComponent } from "../../../../infecto-lms-admin/components/core/form/DefaultTextInput";
import { ValidatedField } from "../../../../infecto-lms-admin/components/core/form/ValidatedField";
import { useTypedDispatch } from "../../../../infecto-lms-admin/redux/Store";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../../infecto-lms-admin/redux/slices/CoreSlice";
import { EditAwardPharmaPointsConfigurationForm_AwardPharmaPointsFragment$key } from "../../../../__generated__/EditAwardPharmaPointsConfigurationForm_AwardPharmaPointsFragment.graphql";
import { EditAwardPharmaPointsConfigurationForm_TreeNodeFragment$key } from "../../../../__generated__/EditAwardPharmaPointsConfigurationForm_TreeNodeFragment.graphql";
import { EditAwardPharmaPointsConfigurationForm_EditMutation } from "../../../../__generated__/EditAwardPharmaPointsConfigurationForm_EditMutation.graphql";

const REWARD_FRAGMENT = graphql`
	fragment EditAwardPharmaPointsConfigurationForm_AwardPharmaPointsFragment on AwardPharmaPointsRewardConfiguration {
		id
		amount
	}
`;

const TREE_NODE_FRAGMENT = graphql`
	fragment EditAwardPharmaPointsConfigurationForm_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ContentTypeDefinition {
				rewards {
					id
					... on PointsRewardConfiguration {
						pointPool {
							name
						}
					}
				}
			}
		}
	}
`;

const MUTATION = graphql`
	mutation EditAwardPharmaPointsConfigurationForm_EditMutation(
		$input: EditPharmaPointsRewardInput!
	) {
		Admin {
			Tree {
				editPharmaPointsReward(input: $input) {
					contentNode {
						...RewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface FormState {
	amount: number;
}

interface OwnProps {
	rewardConfigurationFragmentRef: EditAwardPharmaPointsConfigurationForm_AwardPharmaPointsFragment$key;
	treeNodeFragmentRef: EditAwardPharmaPointsConfigurationForm_TreeNodeFragment$key;
	onBack: () => void;
}

export const EditAwardPharmaPointsConfigurationForm = ({
	rewardConfigurationFragmentRef,
	treeNodeFragmentRef,
	onBack,
}: OwnProps) => {
	const node = useFragment<EditAwardPharmaPointsConfigurationForm_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const rewardConfiguration =
		useFragment<EditAwardPharmaPointsConfigurationForm_AwardPharmaPointsFragment$key>(
			REWARD_FRAGMENT,
			rewardConfigurationFragmentRef,
		);
	const [editReward, isEditing] =
		useMutation<EditAwardPharmaPointsConfigurationForm_EditMutation>(MUTATION);
	const formId = "EditAwardPharmaPointsConfigurationForm";

	const dispatch = useTypedDispatch();

	const formik = useFormik<FormState>({
		enableReinitialize: true,
		initialValues: {
			amount: rewardConfiguration.amount,
		},
		validationSchema: Yup.object().shape({
			amount: Yup.number().min(0).required("Das Feld Anzahl wird benötigt."),
		}),
		onSubmit: (values, formikHelpers) => {
			editReward({
				variables: {
					input: {
						contentNodeId: node.id,
						rewardId: rewardConfiguration.id,
						amount: values.amount,
					},
				},
				onCompleted: () => {
					formikHelpers.setTouched({});
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, number>
				name={"amount"}
				label={"Anzahl"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
				component={DefaultIntegerFieldComponent}
				formikConfig={formik}
			/>

			<Button
				disabled={Object.entries(formik.touched).length === 0 || isEditing}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};

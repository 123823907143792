/**
 * @generated SignedSource<<76e1a7f403ac29f057f0924e0fd0d752>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditMessageDefinitionPageInput = {
  internalTitle: string;
  id: string;
  imageRef?: string | null;
  clientMutationId?: string | null;
};
export type EditMessageDefinitionPageForm_UpdateMessageDefinitionPageMutation$variables = {
  input: EditMessageDefinitionPageInput;
};
export type EditMessageDefinitionPageForm_UpdateMessageDefinitionPageMutationVariables = EditMessageDefinitionPageForm_UpdateMessageDefinitionPageMutation$variables;
export type EditMessageDefinitionPageForm_UpdateMessageDefinitionPageMutation$data = {
  readonly Admin: {
    readonly Messaging: {
      readonly editMessageDefinitionPage: {
        readonly edge: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"EditMessageDefinitionPageForm_MessageDefinitionPageFragment">;
          };
        };
      } | null;
    };
  };
};
export type EditMessageDefinitionPageForm_UpdateMessageDefinitionPageMutationResponse = EditMessageDefinitionPageForm_UpdateMessageDefinitionPageMutation$data;
export type EditMessageDefinitionPageForm_UpdateMessageDefinitionPageMutation = {
  variables: EditMessageDefinitionPageForm_UpdateMessageDefinitionPageMutationVariables;
  response: EditMessageDefinitionPageForm_UpdateMessageDefinitionPageMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditMessageDefinitionPageForm_UpdateMessageDefinitionPageMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessagingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Messaging",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditMessageDefinitionPagePayload",
                "kind": "LinkedField",
                "name": "editMessageDefinitionPage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessageDefinitionPagesEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MessageDefinitionPage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditMessageDefinitionPageForm_MessageDefinitionPageFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditMessageDefinitionPageForm_UpdateMessageDefinitionPageMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessagingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Messaging",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditMessageDefinitionPagePayload",
                "kind": "LinkedField",
                "name": "editMessageDefinitionPage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessageDefinitionPagesEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MessageDefinitionPage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "internalTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "image",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "fileType",
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "uploadDateTime",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "007995bab9f7e0463988bba97d91c082",
    "id": null,
    "metadata": {},
    "name": "EditMessageDefinitionPageForm_UpdateMessageDefinitionPageMutation",
    "operationKind": "mutation",
    "text": "mutation EditMessageDefinitionPageForm_UpdateMessageDefinitionPageMutation(\n  $input: EditMessageDefinitionPageInput!\n) {\n  Admin {\n    Messaging {\n      editMessageDefinitionPage(input: $input) {\n        edge {\n          node {\n            ...EditMessageDefinitionPageForm_MessageDefinitionPageFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditMessageDefinitionPageForm_MessageDefinitionPageFragment on MessageDefinitionPage {\n  id\n  internalTitle\n  image {\n    fileType\n    id\n    name\n    uploadDateTime\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ec2918199a01b6a7318d3cc8b2fa796";

export default node;

/**
 * @generated SignedSource<<4d331c8ef25d1ab0a62e3cee64b3f450>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateTagInput = {
  data: TagInput;
  clientMutationId?: string | null;
};
export type TagInput = {
  name: string;
  colorHex: string;
  clientMutationId?: string | null;
};
export type EditTagScreen_CreateTagMutation$variables = {
  input: CreateTagInput;
  connections: ReadonlyArray<string>;
};
export type EditTagScreen_CreateTagMutationVariables = EditTagScreen_CreateTagMutation$variables;
export type EditTagScreen_CreateTagMutation$data = {
  readonly Admin: {
    readonly Tag: {
      readonly createTag: {
        readonly data: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"EditTagScreen_TagFragment">;
          };
        };
      } | null;
    };
  };
};
export type EditTagScreen_CreateTagMutationResponse = EditTagScreen_CreateTagMutation$data;
export type EditTagScreen_CreateTagMutation = {
  variables: EditTagScreen_CreateTagMutationVariables;
  response: EditTagScreen_CreateTagMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditTagScreen_CreateTagMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TagAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tag",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateTagPayload",
                "kind": "LinkedField",
                "name": "createTag",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TagsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Tag",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditTagScreen_TagFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditTagScreen_CreateTagMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TagAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tag",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateTagPayload",
                "kind": "LinkedField",
                "name": "createTag",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TagsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Tag",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "colorHex",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "data",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5fe0d60312db9905cb68ede8c2c957cd",
    "id": null,
    "metadata": {},
    "name": "EditTagScreen_CreateTagMutation",
    "operationKind": "mutation",
    "text": "mutation EditTagScreen_CreateTagMutation(\n  $input: CreateTagInput!\n) {\n  Admin {\n    Tag {\n      createTag(input: $input) {\n        data {\n          node {\n            ...EditTagScreen_TagFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditTagScreen_TagFragment on Tag {\n  id\n  name\n  colorHex\n}\n"
  }
};
})();

(node as any).hash = "653036ce707850496d5870bfed4baeb3";

export default node;

import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { TitleColumn_TreeStateFragment$key } from "../../../../__generated__/TitleColumn_TreeStateFragment.graphql";

const TREE_STATE_FRAGMENT = graphql`
	fragment TitleColumn_TreeStateFragment on TreeState {
		rootNode {
			structureDefinition {
				... on RootStructureDefinition {
					title
				}
			}
		}
	}
`;

interface OwnProps {
	treeStateFragmentRef: TitleColumn_TreeStateFragment$key;
}

export const TitleColumn = ({ treeStateFragmentRef }: OwnProps) => {
	const treeState = useFragment<TitleColumn_TreeStateFragment$key>(
		TREE_STATE_FRAGMENT,
		treeStateFragmentRef,
	);

	return <div>{treeState.rootNode.structureDefinition.title}</div>;
};

/**
 * @generated SignedSource<<43de34f87728dac461640ea907d0946c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserContentSubmissionDialog_UserFragment$data = {
  readonly id: string;
  readonly extension: {
    readonly firstName?: string | null;
    readonly lastName?: string | null;
  };
  readonly " $fragmentType": "UserContentSubmissionDialog_UserFragment";
};
export type UserContentSubmissionDialog_UserFragment = UserContentSubmissionDialog_UserFragment$data;
export type UserContentSubmissionDialog_UserFragment$key = {
  readonly " $data"?: UserContentSubmissionDialog_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserContentSubmissionDialog_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserContentSubmissionDialog_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extension",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            }
          ],
          "type": "InfectopharmUserExtensionImpl",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "5bab28fba8708ac9c9dc36cfa2d70f10";

export default node;

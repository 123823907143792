/**
 * @generated SignedSource<<cbe44fdd7626b5033c86c9ff63b5d71f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MessageDefinitionButtonType = "link" | "confirm" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditMessageDefinitionForm_MessageDefinitionFragment$data = {
  readonly id: string;
  readonly internalTitle: string;
  readonly buttonOpt: {
    readonly type: MessageDefinitionButtonType;
    readonly title: string;
    readonly link?: string;
  } | null;
  readonly showUntilOpt: any | null;
  readonly notificationDefinitionOpt: {
    readonly title: string;
    readonly body: string;
  } | null;
  readonly " $fragmentType": "EditMessageDefinitionForm_MessageDefinitionFragment";
};
export type EditMessageDefinitionForm_MessageDefinitionFragment = EditMessageDefinitionForm_MessageDefinitionFragment$data;
export type EditMessageDefinitionForm_MessageDefinitionFragment$key = {
  readonly " $data"?: EditMessageDefinitionForm_MessageDefinitionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditMessageDefinitionForm_MessageDefinitionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditMessageDefinitionForm_MessageDefinitionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "buttonOpt",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "link",
              "storageKey": null
            }
          ],
          "type": "MessageDefinitionLinkButton",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showUntilOpt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NotificationDefinitionType",
      "kind": "LinkedField",
      "name": "notificationDefinitionOpt",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MessageDefinition",
  "abstractKey": null
};
})();

(node as any).hash = "7dc570ebba140bbcf44fcd9b16ec787b";

export default node;

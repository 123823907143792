/**
 * @generated SignedSource<<b12f475374c6f82fa2ccecbc0a21b338>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreatePersonalizedNotificationTemplateInput = {
  data: PersonalizedNotificationTemplateInput;
  clientMutationId?: string | null;
};
export type PersonalizedNotificationTemplateInput = {
  key: string;
  title: string;
  message: string;
  variables: ReadonlyArray<string>;
};
export type NewNotificationTemplateButton_CreateMutation$variables = {
  input: CreatePersonalizedNotificationTemplateInput;
};
export type NewNotificationTemplateButton_CreateMutationVariables = NewNotificationTemplateButton_CreateMutation$variables;
export type NewNotificationTemplateButton_CreateMutation$data = {
  readonly Admin: {
    readonly Notification: {
      readonly createPersonalizedNotificationTemplate: {
        readonly data: {
          readonly node: {
            readonly id: string;
          };
        };
      } | null;
    };
  };
};
export type NewNotificationTemplateButton_CreateMutationResponse = NewNotificationTemplateButton_CreateMutation$data;
export type NewNotificationTemplateButton_CreateMutation = {
  variables: NewNotificationTemplateButton_CreateMutationVariables;
  response: NewNotificationTemplateButton_CreateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NotificationAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Notification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "CreatePersonalizedNotificationTemplatePayload",
            "kind": "LinkedField",
            "name": "createPersonalizedNotificationTemplate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PersonalizedNotificationTemplatesEdge",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonalizedNotificationTemplate",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewNotificationTemplateButton_CreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewNotificationTemplateButton_CreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bb07b6261d637b6a99bea909a1d8ddac",
    "id": null,
    "metadata": {},
    "name": "NewNotificationTemplateButton_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation NewNotificationTemplateButton_CreateMutation(\n  $input: CreatePersonalizedNotificationTemplateInput!\n) {\n  Admin {\n    Notification {\n      createPersonalizedNotificationTemplate(input: $input) {\n        data {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6113ff4131ef9d80938327df5de42c7d";

export default node;

import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ActionColumn_TreeNodeFragment$key } from "../../../../__generated__/ActionColumn_TreeNodeFragment.graphql";
import { ActionColumn_DeleteUnpublishedTreeMutation } from "../../../../__generated__/ActionColumn_DeleteUnpublishedTreeMutation.graphql";
import { useDialogLogic } from "../../../../infecto-lms-admin/components/core/dialog/useDialogLogic";
import { getTreeEditorPath } from "../../../routes/GraphEditorRoutes";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../../lms-admin-impl/i18n/tree.i18n";
import { ProjectExtendActionButtons } from "../../../../lms-admin-impl/components/relay/ProjectExtendActionButtons";
import { ProjectCloneTreeButton } from "../../../../lms-admin-impl/components/relay/ProjectCloneTreeButton";

const TREE_NODE_FRAGMENT = graphql`
	fragment ActionColumn_TreeNodeFragment on TreeNode {
		id
		hasBeenPublishedOnce
		structureDefinition {
			... on RootStructureDefinition {
				extension {
					... on RootExtensionImpl {
						displayType
					}
				}
			}
		}
		...ProjectExtendActionButtons_TreeNodeFragment
	}
`;

const DELETE_UNPUBLISHED_TREE_MUTATION = graphql`
	mutation ActionColumn_DeleteUnpublishedTreeMutation(
		$input: DeleteUnpublishedTreeInput!
		$connections: [ID!]!
	) {
		Admin {
			Tree {
				deleteUnpublishedTree(input: $input) {
					rootNodeId @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: ActionColumn_TreeNodeFragment$key;
	rootNodesConnectionId: string;
}

export const ActionColumn = ({ treeNodeFragmentRef, rootNodesConnectionId }: OwnProps) => {
	const treeNode = useFragment<ActionColumn_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const [deleteUnpublishedTree, isDeletingUnpublishedTree] =
		useMutation<ActionColumn_DeleteUnpublishedTreeMutation>(DELETE_UNPUBLISHED_TREE_MUTATION);

	const history = useNavigate();

	const { showDialog, dialogComponent } = useDialogLogic();

	return (
		<>
			{dialogComponent}

			<ProjectCloneTreeButton
				rootNodesConnectionId={rootNodesConnectionId}
				className="mr-2"
				rootNodeId={treeNode.id}
			/>

			<Button
				className="mr-2"
				onClick={() => {
					history(getTreeEditorPath(treeNode.id));
				}}
				icon={"pi pi-pencil"}
			/>
			<Button
				disabled={treeNode.hasBeenPublishedOnce || isDeletingUnpublishedTree}
				onClick={() =>
					showDialog({
						title: `${TREE_I18N_MAP(TREE_I18N_KEY.tree)} löschen`,
						content: `Möchten Sie ${TREE_I18N_MAP(
							TREE_I18N_KEY.thisTree,
						)} wirklich löschen? Das kann nicht rückgängig gemacht werden.`,
						dialogCallback: (result) => {
							if (result === "Accept") {
								deleteUnpublishedTree({
									variables: {
										input: {
											rootNodeId: treeNode.id,
										},
										connections: [rootNodesConnectionId],
									},
								});
							}
						},
					})
				}
				icon={"pi pi-trash"}
			/>
			<ProjectExtendActionButtons treeNodeFragmentRef={treeNode} />
		</>
	);
};

/**
 * @generated SignedSource<<aee368801c3ea8ca9f2528dd678d89cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly visibilityConfigs?: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment">;
    }>;
  };
  readonly " $fragmentType": "OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment";
};
export type OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment = OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment$data;
export type OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment$key = {
  readonly " $data"?: OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "visibilityConfigs",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "43a28b95e033c74a71b023c853e454d9";

export default node;

import { PrivateRouteDefinition } from "../../infecto-lms-admin/components/core/model/PrivateRouteDefinition";
import { MaintenanceScreen } from "../../infecto-lms-admin/screens/MaintenanceScreen";

export const MaintenanceModuleRoutes: PrivateRouteDefinition[] = [
	{
		path: "/maintenance",
		element: <MaintenanceScreen />,
		requiredPermissions: ["AccountPermission_System_Root"],
	},
];

import React from "react";
import { Button } from "primereact/button";

interface OwnProps {
	createMessageDefinition: () => void;
}

export const CreateMessageDefinitionButton = ({ createMessageDefinition }: OwnProps) => {
	return (
		<div className="flex justify-content-start pb-4">
			<Button label={"Erstellen"} onClick={createMessageDefinition} />
		</div>
	);
};

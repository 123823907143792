/**
 * @generated SignedSource<<a5690ca07d72b008a849b6f50b34feea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteMessageDefinitionPageInput = {
  ids: ReadonlyArray<string>;
  clientMutationId?: string | null;
};
export type MessageDefinitionPagesOrderList_DeleteMessageDefinitionPageMutation$variables = {
  input: DeleteMessageDefinitionPageInput;
  connections: ReadonlyArray<string>;
};
export type MessageDefinitionPagesOrderList_DeleteMessageDefinitionPageMutationVariables = MessageDefinitionPagesOrderList_DeleteMessageDefinitionPageMutation$variables;
export type MessageDefinitionPagesOrderList_DeleteMessageDefinitionPageMutation$data = {
  readonly Admin: {
    readonly Messaging: {
      readonly deleteMessageDefinitionPage: {
        readonly deletedIds: ReadonlyArray<string>;
      } | null;
    };
  };
};
export type MessageDefinitionPagesOrderList_DeleteMessageDefinitionPageMutationResponse = MessageDefinitionPagesOrderList_DeleteMessageDefinitionPageMutation$data;
export type MessageDefinitionPagesOrderList_DeleteMessageDefinitionPageMutation = {
  variables: MessageDefinitionPagesOrderList_DeleteMessageDefinitionPageMutationVariables;
  response: MessageDefinitionPagesOrderList_DeleteMessageDefinitionPageMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedIds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageDefinitionPagesOrderList_DeleteMessageDefinitionPageMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessagingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Messaging",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteMessageDefinitionPagePayload",
                "kind": "LinkedField",
                "name": "deleteMessageDefinitionPage",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MessageDefinitionPagesOrderList_DeleteMessageDefinitionPageMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessagingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Messaging",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteMessageDefinitionPagePayload",
                "kind": "LinkedField",
                "name": "deleteMessageDefinitionPage",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "deleteEdge",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "deletedIds",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ac698df612592c176b12a5e7501e1998",
    "id": null,
    "metadata": {},
    "name": "MessageDefinitionPagesOrderList_DeleteMessageDefinitionPageMutation",
    "operationKind": "mutation",
    "text": "mutation MessageDefinitionPagesOrderList_DeleteMessageDefinitionPageMutation(\n  $input: DeleteMessageDefinitionPageInput!\n) {\n  Admin {\n    Messaging {\n      deleteMessageDefinitionPage(input: $input) {\n        deletedIds\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "57ec8f267903fb4d906ea62d9b965862";

export default node;

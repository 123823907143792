import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RenderConfig, ValidatedField } from "../../../../../../core/form/ValidatedField";
import { FileSelectionField } from "../../../../../../files/FileSelectionField";
import { DefaultTextEditorComponent } from "../../../../../../core/form/DefaultTextEditorComponent";
import { FileElementForm_EditFileElemenV2tMutation } from "../../../../../../../../__generated__/FileElementForm_EditFileElemenV2tMutation.graphql";
import { FileElementForm_FileElementV2Fragment$key } from "../../../../../../../../__generated__/FileElementForm_FileElementV2Fragment.graphql";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../../../../../redux/slices/CoreSlice";
import { useTypedDispatch } from "../../../../../../../redux/Store";

const FILE_ELEMENT_FRAGMENT = graphql`
	fragment FileElementForm_FileElementV2Fragment on FileElementV2 {
		id
		title
		file {
			id
		}
	}
`;

const EDIT_FILE_ELEMENT_MUTATION = graphql`
	mutation FileElementForm_EditFileElemenV2tMutation($input: EditFileElementV2Input!) {
		Admin {
			ElearningV2 {
				editFileElement(input: $input) {
					fileElement {
						...FileElementForm_FileElementV2Fragment
					}
				}
			}
		}
	}
`;

interface FormState {
	title: string;
	fileId?: string;
}

interface OwnProps {
	fileElementFragmentRef: FileElementForm_FileElementV2Fragment$key;
	onBack: () => void;
}

export const FileElementForm = ({ fileElementFragmentRef, onBack }: OwnProps) => {
	const fileElement = useFragment<FileElementForm_FileElementV2Fragment$key>(
		FILE_ELEMENT_FRAGMENT,
		fileElementFragmentRef,
	);

	const formId = "FileElementForm";
	const [editFileElement, isEditingFileElement] =
		useMutation<FileElementForm_EditFileElemenV2tMutation>(EDIT_FILE_ELEMENT_MUTATION);
	const dispatch = useTypedDispatch();
	const formik = useFormik<FormState>({
		initialValues: {
			title: fileElement.title,
			fileId: fileElement.file?.id || undefined,
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Das Feld Titel wird benötigt."),
			fileId: Yup.string().required("Das Feld Datei wird benötigt."),
		}),
		onSubmit: (values) => {
			editFileElement({
				variables: {
					input: {
						fileElementId: fileElement.id,
						title: values.title,
						fileId: values.fileId,
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, string>
				name={"title"}
				label={"Titel"}
				component={DefaultTextEditorComponent}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				formikConfig={formik}
			/>
			<ValidatedField<FormState, string>
				name={"fileId"}
				label={"Datei"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				component={({
					fieldName,
					fieldValue,
					updateField,
					onChange,
				}: RenderConfig<string>) => {
					return (
						<FileSelectionField
							name={fieldName}
							selectedFileId={fieldValue}
							setSelectedFileId={updateField}
							onChange={onChange}
						/>
					);
				}}
				formikConfig={formik}
			/>

			<Button
				disabled={isEditingFileElement}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};

import { graphql } from "babel-plugin-relay/macro";
import { Card } from "primereact/card";
import React from "react";
import {
	RewardTreeConfigEditor_TreeNodeFragment$key,
	RewardTreeConfigType,
} from "../../../../../../../__generated__/RewardTreeConfigEditor_TreeNodeFragment.graphql";
import { useFragment, useMutation } from "react-relay";
import { RewardTreeConfigEditor_RemoveRewardConfigMutation } from "../../../../../../../__generated__/RewardTreeConfigEditor_RemoveRewardConfigMutation.graphql";
import {
	AddAfterDateTimeDoNotRewardTreeConfigInput,
	RewardTreeConfigEditor_AddAfterDateTimeDoNotRewardConfigMutation,
} from "../../../../../../../__generated__/RewardTreeConfigEditor_AddAfterDateTimeDoNotRewardConfigMutation.graphql";
import {
	AddDoNotRewardTreeConfigInput,
	RewardTreeConfigEditor_AddDoNotRewardConfigMutation,
} from "../../../../../../../__generated__/RewardTreeConfigEditor_AddDoNotRewardConfigMutation.graphql";
import {
	AddIfRepeatedDoNotRewardTreeConfigInput,
	RewardTreeConfigEditor_AddDoNotRewardIfRepeatedConfigMutation,
} from "../../../../../../../__generated__/RewardTreeConfigEditor_AddDoNotRewardIfRepeatedConfigMutation.graphql";
import { ConfigurationGraphqlInterface, Config } from "../components/Config.interfaces";
import { AddHideVisibilityTreeConfigInput } from "../../../../../../../__generated__/VisibilityTreeConfigEditor_AddHideVisibilityConfigMutation.graphql";
import { AddOnlyAdminsVisibilityTreeConfigInput } from "../../../../../../../__generated__/VisibilityTreeConfigEditor_AddOnlyAdminsVisibilityConfigMutation.graphql";
import { AddNotAfterDateTimeVisibilityTreeConfigInput } from "../../../../../../../__generated__/VisibilityTreeConfigEditor_AddNotAfterDateTimeVisibilityConfigMutation.graphql";
import moment from "moment-timezone";
import { ConfigDropdown, ConfigDropdownOptions } from "../components/ConfigDropdown";
import { ConfigPreview } from "../components/ConfigPreview";
import { ConfigItem } from "../components/ConfigItem";
import { NotAfterDateTimeRewardConfigForm } from "./reward/NotAfterDateTimeRewardConfigForm";
import { NotAfterDateTimeRewardConfigForm_AfterDateTimeDoNotRewardTreeConfigFragment$key } from "../../../../../../../__generated__/NotAfterDateTimeRewardConfigForm_AfterDateTimeDoNotRewardTreeConfigFragment.graphql";
import { utcFormatDateTime } from "../../../../../core/datetime/DateTimeDisplay";

const TREE_NODE_FRAGMENT = graphql`
	fragment RewardTreeConfigEditor_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			... on RootStructureDefinition {
				rewardConfigs {
					id
					configType
					... on AfterDateTimeDoNotRewardTreeConfig {
						dateTime
					}
					...NotAfterDateTimeRewardConfigForm_AfterDateTimeDoNotRewardTreeConfigFragment
				}
			}
		}
		...NotAfterDateTimeRewardConfigForm_TreeNodeFragment
	}
`;

const ADD_AFTER_DATETIME_DO_NOT_REWARD_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation RewardTreeConfigEditor_AddAfterDateTimeDoNotRewardConfigMutation(
		$input: AddAfterDateTimeDoNotRewardTreeConfigInput!
	) {
		Admin {
			Tree {
				addAfterDateTimeDoNotRewardTreeConfig(input: $input) {
					rootNode {
						...RewardTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const ADD_DO_NOT_REWARD_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation RewardTreeConfigEditor_AddDoNotRewardConfigMutation(
		$input: AddDoNotRewardTreeConfigInput!
	) {
		Admin {
			Tree {
				addDoNotRewardTreeConfig(input: $input) {
					rootNode {
						...RewardTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const ADD_DO_NOT_REWARD_IF_REPEATED_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation RewardTreeConfigEditor_AddDoNotRewardIfRepeatedConfigMutation(
		$input: AddIfRepeatedDoNotRewardTreeConfigInput!
	) {
		Admin {
			Tree {
				addIfRepeatedDoNotRewardTreeConfig(input: $input) {
					rootNode {
						...RewardTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const REMOVE_REWARD_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation RewardTreeConfigEditor_RemoveRewardConfigMutation(
		$input: RemoveRewardTreeConfigInput!
	) {
		Admin {
			Tree {
				removeRewardTreeConfig(input: $input) {
					rootNode {
						...RewardTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface Props {
	treeNodeFragmentRef: RewardTreeConfigEditor_TreeNodeFragment$key;
}

export const RewardTreeConfigEditor = ({ treeNodeFragmentRef }: Props) => {
	const treeNode = useFragment<RewardTreeConfigEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const [addAfterDatetimeDoNotRewardConfig] =
		useMutation<RewardTreeConfigEditor_AddAfterDateTimeDoNotRewardConfigMutation>(
			ADD_AFTER_DATETIME_DO_NOT_REWARD_CONDITION_CONFIGURATION_MUTATION,
		);
	const [addDoNotRewardConfig] = useMutation<RewardTreeConfigEditor_AddDoNotRewardConfigMutation>(
		ADD_DO_NOT_REWARD_CONDITION_CONFIGURATION_MUTATION,
	);
	const [addDoNotRewardIfRepeatedConfig] =
		useMutation<RewardTreeConfigEditor_AddDoNotRewardIfRepeatedConfigMutation>(
			ADD_DO_NOT_REWARD_IF_REPEATED_CONDITION_CONFIGURATION_MUTATION,
		);
	const [removeRewardConfig, isRemovingRewardConfig] =
		useMutation<RewardTreeConfigEditor_RemoveRewardConfigMutation>(
			REMOVE_REWARD_CONDITION_CONFIGURATION_MUTATION,
		);

	const doNotRewardTreeConfig: Config<RewardTreeConfigType, AddDoNotRewardTreeConfigInput> = {
		configKey: "RewardTree_DoNot",
		addMutation: (input: AddHideVisibilityTreeConfigInput) => {
			addDoNotRewardConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const doNotRewardIfRepeatedTreeConfig: Config<
		RewardTreeConfigType,
		AddIfRepeatedDoNotRewardTreeConfigInput
	> = {
		configKey: "RewardTree_IfRepeatedDoNot",
		addMutation: (input: AddOnlyAdminsVisibilityTreeConfigInput) => {
			addDoNotRewardIfRepeatedConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const doNotRewardAfterDateTimeTreeConfig: Config<
		RewardTreeConfigType,
		AddAfterDateTimeDoNotRewardTreeConfigInput
	> = {
		configKey: "RewardTree_AfterDateTimeDoNot",
		addMutation: (input: AddNotAfterDateTimeVisibilityTreeConfigInput) => {
			addAfterDatetimeDoNotRewardConfig({
				variables: {
					input,
				},
			});
		},
		addMutationPayload: {
			dateTime: moment().add(1, "year").format(),
		},
		editable: true,
	};

	const configs: Config<RewardTreeConfigType, any | never>[] = [
		doNotRewardTreeConfig,
		doNotRewardIfRepeatedTreeConfig,
		doNotRewardAfterDateTimeTreeConfig,
	];

	const options: ConfigDropdownOptions<RewardTreeConfigType>[] = configs.map((c) => {
		return {
			label: c.configKey,
			value: c.configKey,
		};
	});

	const previewOptions: ConfigDropdownOptions<RewardTreeConfigType>[] = configs.map((c) => {
		const selectedConfig = treeNode.structureDefinition.rewardConfigs?.find(
			(r) => r.configType === c.configKey,
		);

		if (
			selectedConfig &&
			selectedConfig?.dateTime &&
			c.configKey === "RewardTree_AfterDateTimeDoNot"
		) {
			return {
				label: `(${utcFormatDateTime(selectedConfig?.dateTime as string, false)})`,
				value: c.configKey,
			};
		} else {
			return {
				label: c.configKey,
				value: c.configKey,
			};
		}
	});

	return (
		<Card className="mb-2">
			<h2>Belohnungen</h2>

			<ConfigDropdown<RewardTreeConfigType>
				configOptions={options}
				onChange={(e) => {
					const selectedConfig = configs.find((c) => c.configKey === e.value);

					if (selectedConfig) {
						selectedConfig.addMutation({
							rootNodeId: treeNode.id,
							...selectedConfig.addMutationPayload,
						});
					}
				}}
				isPresentational={false}
			/>

			<ConfigPreview<ConfigurationGraphqlInterface<RewardTreeConfigType>>
				selectedConfigs={treeNode.structureDefinition.rewardConfigs as any[]}
				template={(configuration: ConfigurationGraphqlInterface<RewardTreeConfigType>) => (
					<>
						<ConfigItem<RewardTreeConfigType, string>
							isPresentational={true}
							isLoading={isRemovingRewardConfig}
							configType={configuration.configType}
							canEdit={
								configs.find((c) => c.configKey === configuration.configType)
									?.editable as boolean
							}
							configOptions={previewOptions}
							onDelete={() => {
								removeRewardConfig({
									variables: {
										input: {
											rootNodeId: treeNode.id,
											configId: configuration.id,
										},
									},
								});
							}}
							editDialog={() => (
								<>
									{configuration.configType ===
										"RewardTree_AfterDateTimeDoNot" && (
										<NotAfterDateTimeRewardConfigForm
											treeNodeFragmentRef={treeNode}
											configurationFragmentRef={
												configuration as unknown as NotAfterDateTimeRewardConfigForm_AfterDateTimeDoNotRewardTreeConfigFragment$key
											}
										/>
									)}
								</>
							)}
						/>
					</>
				)}
			/>
		</Card>
	);
};

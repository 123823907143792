import { Button } from "primereact/button";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { ImportMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutation } from "../../__generated__/ImportMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutation.graphql";

const IMPORT_MUTATION = graphql`
	mutation ImportMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutation(
		$input: ImportMultipleChoiceElementsInput!
	) {
		Admin {
			ElearningV2 {
				importMultipleChoiceElements(input: $input) {
					eLearningContentNode {
						...ContentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface OwnProps {
	eLearningContentNodeId: string;
}

export const ImportMultipleChoiceElementsButton = ({ eLearningContentNodeId }: OwnProps) => {
	const [importMutation, isImporting] =
		useMutation<ImportMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutation>(
			IMPORT_MUTATION,
		);

	function importDialog() {
		const input = document.createElement("input");

		input.type = "file";

		input.onchange = () => {
			const files = Array.from(input.files || []);
			const reader = new FileReader();
			reader.onload = function (evt) {
				if (evt.target?.readyState !== 2) return;
				if (evt.target.error) {
					alert("Error while reading file");
					return;
				}

				const content = evt.target.result;

				importMutation({
					variables: {
						input: {
							eLearningContentNodeId: eLearningContentNodeId,
							fileContent: content as string,
						},
					},
				});
			};

			reader.readAsText(files[0]);
		};
		input.click();
	}

	return (
		<>
			<Button
				disabled={isImporting}
				icon="pi pi-cloud-upload"
				tooltip="Bausteine importieren"
				onClick={() => {
					importDialog();
				}}
			/>
		</>
	);
};

import { useFragment, useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { EditNodeForm_TreeNodeFragment$key } from "../../../../../__generated__/EditNodeForm_TreeNodeFragment.graphql";
import { ValidatedField } from "../../../core/form/ValidatedField";
import { DefaultTextFieldComponent } from "../../../core/form/DefaultTextInput";
import { DefaultTextEditorComponent } from "../../../core/form/DefaultTextEditorComponent";
import { FileSelectionField } from "../../../files/FileSelectionField";
import { EditNodeForm_EditNodeCoreMutation } from "../../../../../__generated__/EditNodeForm_EditNodeCoreMutation.graphql";
import { SHORT_DESCRIPTION } from "../../../../i18n/graph/shortDescription-label";
import { InstructorsMultiSelect } from "./InstructorsMultiSelect";
import * as React from "react";
import { useTypedDispatch } from "../../../../redux/Store";
import {
	removeEditedFormFromEditedFormsArray,
	addEditedFormToEditedFormsArray,
} from "../../../../redux/slices/CoreSlice";

const TREE_NODE_FRAGMENT = graphql`
	fragment EditNodeForm_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			title
			definitionType
		}
		description
		shortDescription
		instructors {
			superId
		}
		imageId
	}
`;

const EDIT_NODE_CORE_MUTATION = graphql`
	mutation EditNodeForm_EditNodeCoreMutation($input: EditNodeCoreInput!) {
		Admin {
			Tree {
				editNodeCore(input: $input) {
					editedNode {
						id
						...EditNodeForm_TreeNodeFragment
						...Node_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const SHORT_DESCRIPTION_MAX_LENGTH = 200;

interface OwnProps {
	treeNodeFragmentRef: EditNodeForm_TreeNodeFragment$key;
}

interface FormState {
	title: string;
	description?: string;
	shortDescription?: string;
	instructors: any;
	imageId?: string;
}

export const EditNodeForm = ({ treeNodeFragmentRef }: OwnProps) => {
	const node = useFragment<EditNodeForm_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const isRoot = node?.structureDefinition.definitionType == "root";
	const dispatch = useTypedDispatch();
	const [editNodeCore, isEditingNodeCore] =
		useMutation<EditNodeForm_EditNodeCoreMutation>(EDIT_NODE_CORE_MUTATION);

	const formId = "EditNodeForm";
	const formik = useFormik<FormState>({
		enableReinitialize: true,
		initialValues: {
			title: node.structureDefinition.title,
			description: node.description || undefined,
			shortDescription: node.shortDescription || undefined,
			imageId: node.imageId || undefined,
			instructors: node.instructors.map((i) => i.superId) || null,
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Titel wird benötigt"),
			shortDescription: Yup.string().max(
				SHORT_DESCRIPTION_MAX_LENGTH,
				`Überschreitet ${SHORT_DESCRIPTION_MAX_LENGTH} Zeichen`,
			),
		}),

		onSubmit: (values, { setSubmitting }) => {
			editNodeCore({
				variables: {
					input: {
						nodeId: node.id,
						title: values.title,
						description: values.description,
						shortDescription: values.shortDescription,
						imageId: values.imageId,
						instructorIds: values.instructors || [],
					},
				},
				onCompleted: () => {
					formik.setTouched({});
					setSubmitting(false);
					dispatch(removeEditedFormFromEditedFormsArray({ form: formId }));
				},
			});
		},
	});

	return (
		<Card className="mb-2">
			<h2>Ordner-Einstellungen</h2>
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, string>
					name={"title"}
					label={"Titel"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				{isRoot && (
					<ValidatedField<FormState, string>
						name={"description"}
						label={"Beschreibung"}
						component={DefaultTextEditorComponent}
						onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
						formikConfig={formik}
						showListButtons={true}
					/>
				)}
				<ValidatedField<FormState, string[]>
					name={"instructors"}
					label={"Expert:innen"}
					helpText={"Wählen Sie die für das Modul verantwortlichen Lehrenden."}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={InstructorsMultiSelect}
					formikConfig={formik}
				/>
				<ValidatedField<FormState, string>
					name={"shortDescription"}
					label={SHORT_DESCRIPTION["short_description"]}
					helpText={`Maximal ${SHORT_DESCRIPTION_MAX_LENGTH} Zeichen (${
						formik.values.shortDescription?.length || 0
					} / ${SHORT_DESCRIPTION_MAX_LENGTH})`}
					component={DefaultTextEditorComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>

				<ValidatedField<FormState, string>
					name={"imageId"}
					label={"Bild"}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={({ fieldName, fieldValue, updateField, onChange }) => {
						return (
							<FileSelectionField
								name={fieldName}
								selectedFileId={fieldValue}
								setSelectedFileId={updateField}
								filterByFileTypes={["image/png", "image/jpg", "image/jpeg"]}
								onChange={onChange}
							/>
						);
					}}
					formikConfig={formik}
				/>

				<Button
					disabled={Object.entries(formik.touched).length === 0 || isEditingNodeCore}
					type="submit"
					label="Speichern"
					className="p-mt-2"
				/>
			</form>
		</Card>
	);
};

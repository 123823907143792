/**
 * @generated SignedSource<<281b47566a43406145f2460e0e4c8947>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StartContentConfigType = "StartContent_SpecificUserStatusesCan" | "StartContent_CanIfInTreeFlow" | "StartContent_CanNot" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectExtendStartContentConfigEditDialogs_StartContentConfigFragment$data = {
  readonly configType: StartContentConfigType;
  readonly " $fragmentSpreads": FragmentRefs<"SpecificUserStatusesCanStartContentConfigForm_StartContentConfigFragment">;
  readonly " $fragmentType": "ProjectExtendStartContentConfigEditDialogs_StartContentConfigFragment";
};
export type ProjectExtendStartContentConfigEditDialogs_StartContentConfigFragment = ProjectExtendStartContentConfigEditDialogs_StartContentConfigFragment$data;
export type ProjectExtendStartContentConfigEditDialogs_StartContentConfigFragment$key = {
  readonly " $data"?: ProjectExtendStartContentConfigEditDialogs_StartContentConfigFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectExtendStartContentConfigEditDialogs_StartContentConfigFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectExtendStartContentConfigEditDialogs_StartContentConfigFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "configType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SpecificUserStatusesCanStartContentConfigForm_StartContentConfigFragment"
    }
  ],
  "type": "StartContentConfig",
  "abstractKey": "__isStartContentConfig"
};

(node as any).hash = "69cd21793755fa2f7030e8615052af2e";

export default node;

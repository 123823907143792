import React from "react";

export const ProjectAttachmentFormDescriptionInput = () => {
	return (
		<>
			{/*	<ValidatedField<FormState, string>
				name={"description"}
				label={"Beschreibung"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
				component={DefaultTextEditorComponent}
				formikConfig={formik}
			/>*/}
		</>
	);
};

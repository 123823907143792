import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useContext, useState } from "react";
import { CertificateTag, FilesSearchContext } from "./FileSearchContainer";
import { MultiSelect } from "primereact/multiselect";
import moment from "moment-timezone";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

enum ShowFilesMode {
	All,
	AllButCertificates,
	OnlyCertificates,
}

export const FileFilters = () => {
	const { filters, setFilters, clearFilters } = useContext(FilesSearchContext);
	const defaultCertificateFilter = ShowFilesMode.AllButCertificates;
	const [showFilesMode, setShowFilesMode] = useState(defaultCertificateFilter);

	return (
		<div className="flex mb-3 gap-2">
			<InputText
				value={filters.filterByName || ""}
				onChange={(e) =>
					setFilters({
						...filters,
						filterByName: e.target.value,
					})
				}
				placeholder={"Name"}
			/>

			<MultiSelect
				value={filters.filterByFileTypes}
				onChange={(e) =>
					setFilters({
						...filters,
						filterByFileTypes: e.target.value,
					})
				}
				options={[
					{ label: "png", value: "image/png" },
					{ label: "jpg", value: "image/jpg" },
					{ label: "jpeg", value: "image/jpeg" },
					{ label: "bmp", value: "image/bmp" },
					{ label: "pdf", value: "application/pdf" },
					{ label: "html", value: "text/html" },
					{ label: "mp4", value: "video/mp4" },
				]}
				showSelectAll={false}
				placeholder="Typ"
			/>

			<div className="flex align-items-center">
				<span className="mr-1">Zertifikate:</span>
				<Dropdown
					value={showFilesMode}
					options={[
						{ label: "mit anzeigen", value: ShowFilesMode.All },
						{ label: "nicht anzeigen", value: ShowFilesMode.AllButCertificates },
						{ label: "allein anzeigen", value: ShowFilesMode.OnlyCertificates },
					]}
					onChange={(e) => {
						const showMode: ShowFilesMode = e.target.value;
						setShowFilesMode(showMode);
						const issuedCertificateTags = [CertificateTag];
						if (showMode === ShowFilesMode.OnlyCertificates) {
							setFilters({
								...filters,
								filterFileTagsIncluded: issuedCertificateTags,
								filterFileTagsExcluded: [],
							});
						} else if (showMode === ShowFilesMode.AllButCertificates) {
							setFilters({
								...filters,
								filterFileTagsIncluded: undefined,
								filterFileTagsExcluded: issuedCertificateTags,
							});
						} else {
							setFilters({
								...filters,
								filterFileTagsIncluded: undefined,
								filterFileTagsExcluded: [],
							});
						}
					}}
				/>
			</div>

			<Calendar
				showTime
				hourFormat="24"
				dateFormat={"dd.mm.yy"}
				placeholder={"Erstellt am (von)"}
				value={
					filters.filterByFromDateTimeInclusive
						? moment(
								filters.filterByFromDateTimeInclusive.replace("[UTC]", ""),
						  ).toDate()
						: undefined
				}
				onChange={(e) =>
					setFilters({
						...filters,
						filterByFromDateTimeInclusive: e.value
							? moment(e.value as Date).format("YYYY-MM-DDTHH:mmZ")
							: undefined,
					})
				}
			/>

			<Calendar
				showTime
				hourFormat="24"
				dateFormat={"dd.mm.yy"}
				placeholder={"Erstellt am (bis)"}
				value={
					filters.filterByToDateTimeInclusive
						? moment(filters.filterByToDateTimeInclusive.replace("[UTC]", "")).toDate()
						: undefined
				}
				onChange={(e) =>
					setFilters({
						...filters,
						filterByToDateTimeInclusive: e.value
							? moment(e.value as Date).format("YYYY-MM-DDTHH:mmZ")
							: undefined,
					})
				}
			/>

			<div className="flex align-items-center">
				<Button
					className=""
					label={"Zurücksetzen"}
					icon="pi pi-times"
					onClick={() => {
						clearFilters();
						setShowFilesMode(defaultCertificateFilter);
					}}
				/>
			</div>
		</div>
	);
};

import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { NotificationTemplatesScreen_NotificationTemplatesQuery } from "../../__generated__/NotificationTemplatesScreen_NotificationTemplatesQuery.graphql";
import { BaseScreen } from "./BaseScreen";
import { NotificationTemplatesTable } from "../components/notifications/NotificationTemplatesTable";
import { Permission } from "../../__generated__/MainNavigation_Query.graphql";
import { useSelector } from "react-redux";
import { selectHasPermissions } from "../redux/slices/CurrentUserSlice";

const QUERY = graphql`
	query NotificationTemplatesScreen_NotificationTemplatesQuery {
		...NotificationTemplatesTable_NotificationTemplatesListFragment
	}
`;

export const NotificationTemplatesScreen = () => {
	const data = useLazyLoadQuery<NotificationTemplatesScreen_NotificationTemplatesQuery>(
		QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);

	const checkPermission = (permissions: Permission[]) =>
		useSelector((state: any) => selectHasPermissions(state, permissions));

	const isAdmin = checkPermission([
		"AccountPermission_System_Root",
		"UserInAccountPermission_System_Owner",
	]);

	if (!isAdmin) {
		return <div>Kein Zugriff</div>;
	}

	return (
		<BaseScreen>
			<h1>Benachrichtigungs-Vorlagen</h1>
			<NotificationTemplatesTable notificationTemplatesFragmentRef={data} />
		</BaseScreen>
	);
};

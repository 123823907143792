import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { RelayEnvironmentProvider } from "react-relay";
import * as Sentry from "@sentry/react";
import { ToastContainer } from "react-toastify";
import { RelayEnvironment } from "../infecto-lms-admin/relay/RelayEnvironment";
import { AuthContextProvider } from "../infecto-lms-admin/context/AuthContext";
import { Loader } from "../infecto-lms-admin/components/core/Loader";
import { MainNavigation } from "./routes/MainNavigation";
import { ImplContainer } from "../infecto-lms-admin/containers/ImplContainer";
import { selectLoginData } from "../infecto-lms-admin/redux/slices/AuthSlice";

function App() {
	const jwtTokenData = useSelector(selectLoginData);

	return (
		<>
			<ToastContainer autoClose={5000} newestOnTop={true} />
			<Sentry.ErrorBoundary onError={(e) => console.error(e)}>
				<Suspense fallback={<Loader />}>
					<RelayEnvironmentProvider environment={RelayEnvironment}>
						<ImplContainer>
							<AuthContextProvider jwtTokenData={jwtTokenData}>
								<MainNavigation />
							</AuthContextProvider>
						</ImplContainer>
					</RelayEnvironmentProvider>
				</Suspense>
			</Sentry.ErrorBoundary>
		</>
	);
}

export default App;

import React from "react";
import { useUrlFilterState } from "../../../infecto-lms-admin/components/core/filters/useUrlFilterState";

export interface RootNodesFilters {
	filterByIsRewardedByPoints?: boolean;
	filterByIsRewardedByCertificate?: boolean;
	filterByTagIds?: string[] | null;
	filterByTitleOrDescriptionOrShortDescription?: string;
	filterByVnr?: string;
	filterByInternalVnr?: string;
	filterByVisibility?: boolean;
}

interface Search {
	filters: RootNodesFilters;
	setFilters: (filters: RootNodesFilters) => void;
	clearFilters: () => void;
}

export const ProjectRootNodesSearchContext = React.createContext<Search>({
	setFilters: () => {},
	clearFilters: () => {},
	filters: {},
});

interface OwnProps {
	children: any;
}

export const ProjectRootNodesSearchContainer = ({ children }: OwnProps) => {
	const { state, setState } = useUrlFilterState<RootNodesFilters>();

	return (
		<ProjectRootNodesSearchContext.Provider
			value={{
				filters: state,
				setFilters: (filters) => {
					setState(() => filters);
				},
				clearFilters: () => {
					setState({});
				},
			}}
		>
			{children}
		</ProjectRootNodesSearchContext.Provider>
	);
};

import React from "react";
import { EmailTesterDialogContainer } from "./EmailTesterDialogContainer";
import { Button } from "primereact/button";

interface OwnProps {
	modalVisible: boolean;
	setModalVisible: (boolean: boolean) => void;
	headerText: string;
	disabled: boolean;
	onClick: () => void;
	description: string;
}
export const EmailTesterDialog = ({
	modalVisible,
	setModalVisible,
	headerText,
	disabled,
	onClick,
	description,
}: OwnProps) => {
	return (
		<EmailTesterDialogContainer
			headerText={headerText}
			setModalVisible={() => setModalVisible(false)}
			modalVisible={modalVisible}
		>
			<p>{description}</p>
			<Button
				disabled={disabled}
				onClick={() => onClick()}
				label="Senden"
				className="p-mt-2 w-full"
			/>
		</EmailTesterDialogContainer>
	);
};

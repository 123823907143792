import React from "react";
import * as Yup from "yup";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation, Environment, useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import {
	AddSpecificUserRolesCanStartTreeConfigInput,
	SpecificUserRolesCanStartTreeConfigForm_AddMutation,
	UserRolesInput,
} from "../../../../../../../../__generated__/SpecificUserRolesCanStartTreeConfigForm_AddMutation.graphql";
import { TreeConfigurationEditDialogProps } from "../../../../../../../../infecto-lms-admin/components/tree/editor/node/config/components/Config.interfaces";
import {
	SpecificUserRolesCanStartTreeConfigForm_StartTreeConfigFragment$key,
	UserRoles,
} from "../../../../../../../../__generated__/SpecificUserRolesCanStartTreeConfigForm_StartTreeConfigFragment.graphql";
import { SpecificUserRolesCanStartTreeConfigForm_TreeNodeFragment$key } from "../../../../../../../../__generated__/SpecificUserRolesCanStartTreeConfigForm_TreeNodeFragment.graphql";
import { SpecificUserRolesCanStartTreeConfigForm_EditMutation } from "../../../../../../../../__generated__/SpecificUserRolesCanStartTreeConfigForm_EditMutation.graphql";
import { ValidatedField } from "../../../../../../../../infecto-lms-admin/components/core/form/ValidatedField";
import { CONFIGS_TRANSLATIONS } from "../../../../../../../../infecto-lms-admin/i18n/config/i18n";
import { MultiSelect } from "primereact/multiselect";
import { GLOBAL_USER_ROLES_TRANSLATIONS } from "../../../../../../../../infecto-lms-admin/i18n/user-statuu-types";

const CONFIGURATION_FRAGMENT = graphql`
	fragment SpecificUserRolesCanStartTreeConfigForm_StartTreeConfigFragment on StartTreeConfig {
		... on SpecifiedUserRolesCanStartTreeConfig {
			id
			configType
			userRoles
		}
	}
`;

const TREE_NODE_FRAGMENT = graphql`
	fragment SpecificUserRolesCanStartTreeConfigForm_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			... on RootStructureDefinition {
				startConfigs {
					...SpecificUserRolesCanStartTreeConfigForm_StartTreeConfigFragment
				}
			}
		}
	}
`;

const ADD_CONFIGURATION_MUTATION = graphql`
	mutation SpecificUserRolesCanStartTreeConfigForm_AddMutation(
		$input: AddSpecificUserRolesCanStartTreeConfigInput!
	) {
		Admin {
			Tree {
				addSpecificUserRolesCanStartTreeConfig(input: $input) {
					rootNode {
						...SpecificUserRolesCanStartTreeConfigForm_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const EDIT_CONFIGURATION_MUTATION = graphql`
	mutation SpecificUserRolesCanStartTreeConfigForm_EditMutation(
		$input: EditSpecificUserRolesCanStartTreeConfigInput!
	) {
		Admin {
			Tree {
				EditSpecificUserRolesCanStartTreeConfig(input: $input) {
					rootNode {
						...SpecificUserRolesCanStartTreeConfigForm_TreeNodeFragment
					}
				}
			}
		}
	}
`;

function CommitAddSpecificUserRolesCanStartTreeConfig(
	environment: Environment,
	input: AddSpecificUserRolesCanStartTreeConfigInput,
) {
	return commitMutation<SpecificUserRolesCanStartTreeConfigForm_AddMutation>(environment, {
		mutation: ADD_CONFIGURATION_MUTATION,
		variables: { input },
		onCompleted: () => {},
		onError: () => {},
	});
}

interface FormState {
	userRoles: UserRolesInput[];
}

interface Props extends TreeConfigurationEditDialogProps {
	treeNodeFragmentRef: SpecificUserRolesCanStartTreeConfigForm_TreeNodeFragment$key;
	configurationFragmentRef: SpecificUserRolesCanStartTreeConfigForm_StartTreeConfigFragment$key;
	onBack?: () => void;
}

export const SpecificUserRolesCanStartTreeConfigForm = ({
	configurationFragmentRef,
	treeNodeFragmentRef,
	onBack,
}: Props) => {
	const treeNode = useFragment<SpecificUserRolesCanStartTreeConfigForm_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const configuration =
		useFragment<SpecificUserRolesCanStartTreeConfigForm_StartTreeConfigFragment$key>(
			CONFIGURATION_FRAGMENT,
			configurationFragmentRef,
		);
	const [edit, isEditing] = useMutation<SpecificUserRolesCanStartTreeConfigForm_EditMutation>(
		EDIT_CONFIGURATION_MUTATION,
	);

	const formik = useFormik<FormState>({
		initialValues: {
			userRoles: [
				...(configuration.userRoles?.filter(
					(e: any) => e !== "noProcess",
				) as UserRolesInput[]),
			],
		},
		validationSchema: Yup.object().shape({
			userRoles: Yup.array().required("Das Feld Benötigte Rollen wird benötigt."),
		}),
		onSubmit: (values, formikHelpers) => {
			edit({
				variables: {
					input: {
						rootNodeId: treeNode.id,
						configId: configuration.id as string,
						newUserRoles: values.userRoles,
					},
				},
				onCompleted: () => {
					formikHelpers.setTouched({});
					onBack!();
				},
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, UserRoles[]>
				name={"userRoles"}
				label={CONFIGS_TRANSLATIONS[configuration.configType!]}
				component={({ fieldName, updateField, fieldValue }) => {
					return (
						<MultiSelect
							name={fieldName}
							value={fieldValue}
							onChange={(e) => {
								updateField(e.value);
							}}
							options={Object.entries(GLOBAL_USER_ROLES_TRANSLATIONS).map((x) => {
								return {
									value: x[0],
									label: x[1],
								};
							})}
						/>
					);
				}}
				formikConfig={formik}
			/>

			<Button
				disabled={Object.entries(formik.touched).length === 0 || isEditing}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					// formik.resetForm();
					onBack!();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};

export { CommitAddSpecificUserRolesCanStartTreeConfig as AddSpecificUserRolesCanStartTreeConfigMutation };

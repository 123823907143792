/**
 * @generated SignedSource<<c76ff29b4bd3dc53953925be7443065e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CertificateTemplatesTable_Refetch$variables = {
  after?: string | null;
  filterByName?: string | null;
  first?: number | null;
};
export type CertificateTemplatesTable_RefetchVariables = CertificateTemplatesTable_Refetch$variables;
export type CertificateTemplatesTable_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CertificateTemplatesTable_QueryFragment">;
};
export type CertificateTemplatesTable_RefetchResponse = CertificateTemplatesTable_Refetch$data;
export type CertificateTemplatesTable_Refetch = {
  variables: CertificateTemplatesTable_RefetchVariables;
  response: CertificateTemplatesTable_Refetch$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filterByName",
    "variableName": "filterByName"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CertificateTemplatesTable_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "CertificateTemplatesTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CertificateTemplatesTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CertificatesAdminSchema",
            "kind": "LinkedField",
            "name": "Certificates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CertificateTemplatesConnection",
                "kind": "LinkedField",
                "name": "CertificateTemplates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CertificateTemplatesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CertificateTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "templateFile",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "filterByName"
                ],
                "handle": "connection",
                "key": "CertificateTemplatesTable_CertificateTemplates",
                "kind": "LinkedHandle",
                "name": "CertificateTemplates"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1fce9bac918df72ce79a505f86104ef2",
    "id": null,
    "metadata": {},
    "name": "CertificateTemplatesTable_Refetch",
    "operationKind": "query",
    "text": "query CertificateTemplatesTable_Refetch(\n  $after: String\n  $filterByName: String\n  $first: Int\n) {\n  ...CertificateTemplatesTable_QueryFragment_3KLhx3\n}\n\nfragment CertificateTemplatesTable_QueryFragment_3KLhx3 on Query {\n  Admin {\n    Certificates {\n      CertificateTemplates(first: $first, after: $after, filterByName: $filterByName) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            name\n            ...ShowCertificateButton_CertificateFragment\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n\nfragment ShowCertificateButton_CertificateFragment on CertificateTemplate {\n  name\n  templateFile {\n    url\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "301e39e0fb480b4d7726c4e248874149";

export default node;

import { Tree, TreeExpandedKeysType } from "primereact/tree";
import { TreeNode } from "primereact/treenode";
import { graphql } from "babel-plugin-relay/macro";
import { readInlineData, useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { useContext, useState } from "react";
import { TreeContainer_TreeFragment$key } from "../../../../__generated__/TreeContainer_TreeFragment.graphql";
import {
	TreeContainer_TreeNodeFragment,
	TreeContainer_TreeNodeFragment$key,
} from "../../../../__generated__/TreeContainer_TreeNodeFragment.graphql";
import { TreeContainer_MoveChildNodeMutation } from "../../../../__generated__/TreeContainer_MoveChildNodeMutation.graphql";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../../lms-admin-impl/i18n/tree.i18n";
import { TreeSelectionContext } from "../tree/TreeSelectionContext";
import { selectArrayOfEditedForms } from "../../../../infecto-lms-admin/redux/slices/CoreSlice";
import { WarningUnsavedChangesDialog } from "../../../../infecto-lms-admin/components/core/dialog/WarningUnsavedChangesDialog";
import { AddNodeButton } from "../../../../infecto-lms-admin/components/tree/editor/tree/AddNodeButton";
import { StartPublishingButton } from "../../../../infecto-lms-admin/components/tree/editor/tree/StartPublishingButton";
import { DeleteChildNodeButton } from "../../../../infecto-lms-admin/components/tree/editor/tree/DeleteChildNodeButton";
import { ElementNode } from "../../../../infecto-lms-admin/components/tree/editor/tree/ElementNode";
import { Node } from "../../../../infecto-lms-admin/components/tree/editor/tree/Node";
import { TreeContainer_PublishingQuery } from "../../../../__generated__/TreeContainer_PublishingQuery.graphql";
import { useTypedSelector } from "../../../../infecto-lms-admin/redux/Store";

const PUBLISHING_QUERY = graphql`
	query TreeContainer_PublishingQuery($input: ID!) {
		Admin {
			PublishingV2 {
				ActivePublishing(rootId: $input) {
					status
				}
			}
		}
	}
`;

const TREE_FRAGMENT = graphql`
	fragment TreeContainer_TreeFragment on Tree {
		id
		rootNode {
			id
			structureDefinition {
				title
			}
			...TreeContainer_TreeNodeFragment
		}
		nodes {
			...TreeContainer_TreeNodeFragment
		}
	}
`;

const TREE_NODE_FRAGMENT = graphql`
	fragment TreeContainer_TreeNodeFragment on TreeNode @inline {
		id
		hasBeenPublishedOnce
		parentId
		structureDefinition {
			coordinates {
				parentRef
			}
			definitionType
			title
		}
		typeDefinition {
			definitionType
			... on BranchTypeDefinition {
				childRefs
			}
			... on ELearningContentTypeDefinition {
				elements {
					id
					elementType
					...ElementNode_ElementV2Fragment
				}
			}
		}
		...Node_TreeNodeFragment
		...DeleteChildNodeButton_TreeNodeFragment
	}
`;

const MOVE_CHILD_NODE_MUTATION = graphql`
	mutation TreeContainer_MoveChildNodeMutation($input: MoveChildNodeInput!) {
		Admin {
			Tree {
				moveChildNode(input: $input) {
					tree {
						...TreeEditorScreen_TreeFragment
					}
				}
			}
		}
	}
`;

interface OwnProps {
	treeFragmentRef: TreeContainer_TreeFragment$key;
}

export const TreeContainer = ({ treeFragmentRef }: OwnProps) => {
	const { selectedNodeId, setSelectedNodeId } = useContext(TreeSelectionContext);
	const tree = useFragment<TreeContainer_TreeFragment$key>(TREE_FRAGMENT, treeFragmentRef);

	const publishingQuery = useLazyLoadQuery<TreeContainer_PublishingQuery>(
		PUBLISHING_QUERY,
		{ input: tree.id! },
		{ fetchPolicy: "network-only" },
	);

	const [moveChildNode, isMovingChildNode] =
		useMutation<TreeContainer_MoveChildNodeMutation>(MOVE_CHILD_NODE_MUTATION);

	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);

	const nodes: TreeContainer_TreeNodeFragment[] = tree.nodes.map((node) =>
		readInlineData<TreeContainer_TreeNodeFragment$key>(TREE_NODE_FRAGMENT, node),
	);

	const selectedNode = nodes.find((n) => n.id === selectedNodeId);

	const treeExpandedKeys = selectedNode ? determineExpandedKeys(selectedNode, nodes) : {};

	const [expandedKeys, setExpandedKeys] = useState<TreeExpandedKeysType>(treeExpandedKeys);
	const [selectedId, setSelectedId] = useState("");
	const [showDialog, setShowDialog] = useState(false);

	const rootNode = nodes.find((node) => node.id === tree.rootNode?.id);

	if (!rootNode) return null;

	const treeNodes: TreeNode[] = [generateGraph(nodes, rootNode)];

	return (
		<>
			{showDialog && (
				<WarningUnsavedChangesDialog
					setShowDialog={setShowDialog}
					callback={setSelectedNodeId}
					value={selectedId}
				/>
			)}
			<Tree
				expandedKeys={expandedKeys}
				onToggle={(e) => setExpandedKeys(e.value)}
				header={() => (
					<div>
						<div className="flex flex-column justify-content-end mb-2 align-items-start">
							<h2 className="mr-auto">
								{TREE_I18N_MAP(TREE_I18N_KEY.tree)}{" "}
								{rootNode.structureDefinition.title}
							</h2>
							<AddNodeButton
								parentBranchNodeId={
									selectedNode?.typeDefinition.definitionType === "branch"
										? selectedNodeId
										: undefined
								}
								onSuccessful={(newId) => {
									if (selectedNodeId)
										setExpandedKeys(() => ({
											...expandedKeys,
											[selectedNodeId]: true,
										}));
									setSelectedNodeId(newId);
								}}
							/>
							<div className="flex flex-1 mt-2 mb-2">
								<StartPublishingButton className="mr-2" rootNodeId={rootNode.id} />
								<DeleteChildNodeButton
									className="mr-2"
									treeNodeFragmentRef={selectedNode}
									onSuccessful={() =>
										setSelectedNodeId(selectedNode?.parentId || undefined)
									}
								/>
							</div>
						</div>
					</div>
				)}
				dragdropScope="GraphTree"
				disabled={
					isMovingChildNode ||
					publishingQuery.Admin.PublishingV2.ActivePublishing?.status !== undefined
				}
				nodeTemplate={(node) =>
					node.data.elementType ? (
						<ElementNode key={node.key} elementFragmentRef={node.data} />
					) : (
						<Node key={node.key} treeNodeFragmentRef={node.data} />
					)
				}
				onDragDrop={(e) => {
					const dragNode = nodes.find((c) => c.id === e.dragNode?.key);
					const dropNode = nodes.find((c) => c.id === e.dropNode?.key);

					if (dropNode?.typeDefinition.definitionType !== "branch") return;

					let realDropIndex = e.dropIndex;
					if (e.dropIndex + 1 === dropNode.typeDefinition.childRefs?.length) {
						// Workaround for bug in primereact which assigns the wrong drop index when you drop after the last element
						if ((e.originalEvent.target as any).nextSibling === null) {
							realDropIndex = e.dropIndex + 1;
						}
					}

					const previousSibling =
						realDropIndex === 0
							? undefined
							: nodes.find(
									(c) =>
										c.id ===
										dropNode?.typeDefinition.childRefs![realDropIndex - 1],
							  );

					if (dragNode && dropNode) {
						moveChildNode({
							variables: {
								input: {
									childNodeId: dragNode.id,
									newParentNodeId: dropNode.id,
									previousSiblingChildNodeIdOpt: previousSibling?.id,
								},
							},
						});
					}
				}}
				selectionKeys={selectedNode ? { [selectedNode?.id]: true } : {}}
				onSelectionChange={(e) => {
					const selectedId = e.value as any as string;
					if (arrayOfEditedForms.length > 0) {
						setShowDialog(true);
						setSelectedId(selectedId);
					} else {
						setSelectedNodeId(selectedId);
					}
				}}
				selectionMode={"single"}
				value={treeNodes}
			/>
		</>
	);
};

const generateGraph = (
	nodes: ReadonlyArray<TreeContainer_TreeNodeFragment>,
	node: TreeContainer_TreeNodeFragment,
	depth = 0,
): TreeNode => {
	const children: TreeNode[] = (() => {
		switch (node.typeDefinition.definitionType) {
			case "branch":
				return (
					node.typeDefinition.childRefs?.map((childId) =>
						generateGraph(
							nodes,
							nodes.find((node) => childId === node.id)!,
							(depth = depth + 1),
						),
					) || []
				);
			case "content":
				return (
					node.typeDefinition.elements?.map((el) => ({
						key: el.id,
						draggable: false,
						droppable: false,
						data: el,
						selectable: false,
						children: [],
					})) || []
				);
			default:
				return [];
		}
	})();

	return {
		key: node.id,
		// draggable: node.structureDefinition.definitionType !== "root" && !node.hasBeenPublishedOnce,
		// droppable: node.typeDefinition.definitionType === "branch",
		draggable: false,
		droppable: false,
		data: node,
		children: children,
	};
};

function determineExpandedKeys(
	selectedNode: TreeContainer_TreeNodeFragment,
	tree: ReadonlyArray<TreeContainer_TreeNodeFragment>,
) {
	const initiallyOpenedContainers = [];

	let node: TreeContainer_TreeNodeFragment | undefined = selectedNode;
	while (node) {
		initiallyOpenedContainers.push(node.id);
		// eslint-disable-next-line no-loop-func
		node = tree.find((c) => c.id === node?.structureDefinition.coordinates.parentRef);
	}
	return initiallyOpenedContainers.map((c) => ({ [c]: true })).reduce((x, y) => ({ ...x, ...y }));
}

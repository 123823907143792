import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { DateTimeDisplay } from "../../../core/datetime/DateTimeDisplay";
import { IsRewardedToColumn_TreeNodeFragment$key } from "../../../../../__generated__/IsRewardedToColumn_TreeNodeFragment.graphql";

const TREE_NODE_FRAGMENT = graphql`
	fragment IsRewardedToColumn_TreeNodeFragment on TreeNode {
		structureDefinition {
			... on RootStructureDefinition {
				extension {
					... on RootExtensionImpl {
						configConsequences {
							isRewardedTo
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: IsRewardedToColumn_TreeNodeFragment$key;
}

export const IsRewardedToColumn = ({ treeNodeFragmentRef }: OwnProps) => {
	const treeNode = useFragment<IsRewardedToColumn_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	return (
		<DateTimeDisplay
			value={treeNode.structureDefinition.extension?.configConsequences?.isRewardedTo}
		/>
	);
};

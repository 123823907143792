/**
 * @generated SignedSource<<5927188505aec53f6f5a717205e2987f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EmailTesterInformExpirationOrRemindRetryForm_EmailTemplatesQuery$variables = {
  id: string;
};
export type EmailTesterInformExpirationOrRemindRetryForm_EmailTemplatesQueryVariables = EmailTesterInformExpirationOrRemindRetryForm_EmailTemplatesQuery$variables;
export type EmailTesterInformExpirationOrRemindRetryForm_EmailTemplatesQuery$data = {
  readonly node: {
    readonly id?: string;
    readonly template?: {
      readonly key: string;
      readonly subject: string;
      readonly variables: ReadonlyArray<string>;
    };
  } | null;
};
export type EmailTesterInformExpirationOrRemindRetryForm_EmailTemplatesQueryResponse = EmailTesterInformExpirationOrRemindRetryForm_EmailTemplatesQuery$data;
export type EmailTesterInformExpirationOrRemindRetryForm_EmailTemplatesQuery = {
  variables: EmailTesterInformExpirationOrRemindRetryForm_EmailTemplatesQueryVariables;
  response: EmailTesterInformExpirationOrRemindRetryForm_EmailTemplatesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "EmailTemplate",
  "kind": "LinkedField",
  "name": "template",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "variables",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailTesterInformExpirationOrRemindRetryForm_EmailTemplatesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "PersonalizedEmailTemplate",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailTesterInformExpirationOrRemindRetryForm_EmailTemplatesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "PersonalizedEmailTemplate",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a1af9cf312c6f90489f48c0441cc1b0b",
    "id": null,
    "metadata": {},
    "name": "EmailTesterInformExpirationOrRemindRetryForm_EmailTemplatesQuery",
    "operationKind": "query",
    "text": "query EmailTesterInformExpirationOrRemindRetryForm_EmailTemplatesQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on PersonalizedEmailTemplate {\n      id\n      template {\n        key\n        subject\n        variables\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "965bb357d1b37388fb1d955788360673";

export default node;

import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { UserColumn_FeedbackFragment$key } from "../../../__generated__/UserColumn_FeedbackFragment.graphql";
import React from "react";
import { ProjectUserDescription } from "../../../lms-admin-impl/components/relay/ProjectUserDescription";

const FEEDBACK_FRAGMENT = graphql`
	fragment UserColumn_FeedbackFragment on FeedbackV2 {
		user {
			...ProjectUserDescription_UserFragment
		}
	}
`;

interface OwnProps {
	feedbackFragmentRef: UserColumn_FeedbackFragment$key;
}

export const UserColumn = ({ feedbackFragmentRef }: OwnProps) => {
	const feedback = useFragment<UserColumn_FeedbackFragment$key>(
		FEEDBACK_FRAGMENT,
		feedbackFragmentRef,
	);

	return <div>{<ProjectUserDescription userFragmentRef={feedback.user} />}</div>;
};

import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ReceivedAtColumn_PointsAdditionFragment$key } from "../../../../__generated__/ReceivedAtColumn_PointsAdditionFragment.graphql";
import { DateTimeDisplay } from "../../core/datetime/DateTimeDisplay";

const POINTS_ADDITION_FRAGMENT = graphql`
	fragment ReceivedAtColumn_PointsAdditionFragment on PointsAdditionV2 {
		receivedAt
	}
`;

interface OwnProps {
	pointsAdditionFragmentRef: ReceivedAtColumn_PointsAdditionFragment$key;
}

export const ReceivedAtColumn = ({ pointsAdditionFragmentRef }: OwnProps) => {
	const pointsAddition = useFragment<ReceivedAtColumn_PointsAdditionFragment$key>(
		POINTS_ADDITION_FRAGMENT,
		pointsAdditionFragmentRef,
	);

	return <DateTimeDisplay value={pointsAddition.receivedAt} />;
};

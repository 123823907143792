/**
 * @generated SignedSource<<49f69e6cec0d8b33b5d81218e9621336>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GenerateTestCertificateInput = {
  contentNodeId: string;
  clientMutationId?: string | null;
};
export type GenerateTestCertificateModal_GenerateTestCertificateMutation$variables = {
  input: GenerateTestCertificateInput;
};
export type GenerateTestCertificateModal_GenerateTestCertificateMutationVariables = GenerateTestCertificateModal_GenerateTestCertificateMutation$variables;
export type GenerateTestCertificateModal_GenerateTestCertificateMutation$data = {
  readonly Admin: {
    readonly CertificatesV2: {
      readonly generateTestCertificate: {
        readonly certificateFile: {
          readonly url: string | null;
        };
      } | null;
    };
  };
};
export type GenerateTestCertificateModal_GenerateTestCertificateMutationResponse = GenerateTestCertificateModal_GenerateTestCertificateMutation$data;
export type GenerateTestCertificateModal_GenerateTestCertificateMutation = {
  variables: GenerateTestCertificateModal_GenerateTestCertificateMutationVariables;
  response: GenerateTestCertificateModal_GenerateTestCertificateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GenerateTestCertificateModal_GenerateTestCertificateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CertificatesV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "CertificatesV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "GenerateTestCertificatePayload",
                "kind": "LinkedField",
                "name": "generateTestCertificate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "certificateFile",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GenerateTestCertificateModal_GenerateTestCertificateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CertificatesV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "CertificatesV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "GenerateTestCertificatePayload",
                "kind": "LinkedField",
                "name": "generateTestCertificate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "certificateFile",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0dbbc309385c7cffbbf7ecf239ee9d1b",
    "id": null,
    "metadata": {},
    "name": "GenerateTestCertificateModal_GenerateTestCertificateMutation",
    "operationKind": "mutation",
    "text": "mutation GenerateTestCertificateModal_GenerateTestCertificateMutation(\n  $input: GenerateTestCertificateInput!\n) {\n  Admin {\n    CertificatesV2 {\n      generateTestCertificate(input: $input) {\n        certificateFile {\n          url\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6d72e038e74e386591236bf315f4329c";

export default node;

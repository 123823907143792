/**
 * @generated SignedSource<<177cb3fc732a68d88309d5a2df06e524>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VisibilityTreeConfigType = "VisibilityTree_OnlyIfTreeStateOrAdmin" | "VisibilityTree_OnlyAdmins" | "VisibilityTree_OnlyBasicSeminar" | "VisibilityTree_OnlyFachrichtungen" | "VisibilityTree_AfterDateTime" | "VisibilityTree_OnlySpecificPermissions" | "VisibilityTree_HideForFachberater" | "VisibilityTree_Hide" | "VisibilityTree_OnlyFachberater" | "VisibilityTree_NotAfterDateTime" | "VisibilityTree_OnlyIfTreeState" | "%future added value";
export type AddHideForFachberaterVisibilityTreeConfigInput = {
  rootNodeId: string;
  clientMutationId?: string | null;
};
export type HideForFachberaterVisibilityConfig_AddMutation$variables = {
  input: AddHideForFachberaterVisibilityTreeConfigInput;
};
export type HideForFachberaterVisibilityConfig_AddMutationVariables = HideForFachberaterVisibilityConfig_AddMutation$variables;
export type HideForFachberaterVisibilityConfig_AddMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly AddHideForFachberaterVisibilityTreeConfig: {
        readonly rootNode: {
          readonly id: string;
          readonly structureDefinition: {
            readonly visibilityConfigs?: ReadonlyArray<{
              readonly id?: string;
              readonly configType?: VisibilityTreeConfigType;
            }>;
          };
        };
      } | null;
    };
  };
};
export type HideForFachberaterVisibilityConfig_AddMutationResponse = HideForFachberaterVisibilityConfig_AddMutation$data;
export type HideForFachberaterVisibilityConfig_AddMutation = {
  variables: HideForFachberaterVisibilityConfig_AddMutationVariables;
  response: HideForFachberaterVisibilityConfig_AddMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "configType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HideForFachberaterVisibilityConfig_AddMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddHideForFachberaterVisibilityTreeConfigPayload",
                "kind": "LinkedField",
                "name": "AddHideForFachberaterVisibilityTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "visibilityConfigs",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v2/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "type": "HideForFachberaterVisibilityTreeConfig",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HideForFachberaterVisibilityConfig_AddMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddHideForFachberaterVisibilityTreeConfigPayload",
                "kind": "LinkedField",
                "name": "AddHideForFachberaterVisibilityTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "visibilityConfigs",
                                "plural": true,
                                "selections": [
                                  (v4/*: any*/),
                                  (v2/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v3/*: any*/)
                                    ],
                                    "type": "HideForFachberaterVisibilityTreeConfig",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "750de68ae52f3ac7fe550b1b9bbc64de",
    "id": null,
    "metadata": {},
    "name": "HideForFachberaterVisibilityConfig_AddMutation",
    "operationKind": "mutation",
    "text": "mutation HideForFachberaterVisibilityConfig_AddMutation(\n  $input: AddHideForFachberaterVisibilityTreeConfigInput!\n) {\n  Admin {\n    Tree {\n      AddHideForFachberaterVisibilityTreeConfig(input: $input) {\n        rootNode {\n          id\n          structureDefinition {\n            __typename\n            ... on RootStructureDefinition {\n              visibilityConfigs {\n                __typename\n                ... on HideForFachberaterVisibilityTreeConfig {\n                  id\n                  configType\n                }\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "21827835cabf71b43c0f27a2bd7d559a";

export default node;

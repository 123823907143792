/**
 * @generated SignedSource<<216939670c3a795cda6a9dc379465331>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ShowAnswerTreeConfigType = "ShowAnswerTree_DoNot" | "ShowAnswerTree_AfterDateTimeDo" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShowAnswerTreeConfigEditor_TreeNodeFragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly showAnswerConfig?: {
      readonly configType: ShowAnswerTreeConfigType;
      readonly dateTime?: string;
      readonly " $fragmentSpreads": FragmentRefs<"AfterDateTimeDoShowAnswerTreeConfigForm_AfterDateTimeDoShowAnswerTreeConfigFragment">;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"AfterDateTimeDoShowAnswerTreeConfigForm_TreeNodeFragment">;
  readonly " $fragmentType": "ShowAnswerTreeConfigEditor_TreeNodeFragment";
};
export type ShowAnswerTreeConfigEditor_TreeNodeFragment = ShowAnswerTreeConfigEditor_TreeNodeFragment$data;
export type ShowAnswerTreeConfigEditor_TreeNodeFragment$key = {
  readonly " $data"?: ShowAnswerTreeConfigEditor_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShowAnswerTreeConfigEditor_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShowAnswerTreeConfigEditor_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "showAnswerConfig",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "configType",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dateTime",
                      "storageKey": null
                    }
                  ],
                  "type": "AfterDateTimeDoShowAnswerTreeConfig",
                  "abstractKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AfterDateTimeDoShowAnswerTreeConfigForm_AfterDateTimeDoShowAnswerTreeConfigFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AfterDateTimeDoShowAnswerTreeConfigForm_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "ddc4630076e27cacd53f04faf3079aea";

export default node;

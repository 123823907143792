/**
 * @generated SignedSource<<086475eec25a855b4f268e6fbdfa73d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewsTickerDefinitionOverviewScreen_PaginationFragment$data = {
  readonly Admin: {
    readonly Newsticker: {
      readonly NewstickerMessages: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly text: string;
            readonly forwardingUrl: string | null;
            readonly isActive: boolean | null;
          };
        } | null> | null;
      };
    };
  };
  readonly " $fragmentType": "NewsTickerDefinitionOverviewScreen_PaginationFragment";
};
export type NewsTickerDefinitionOverviewScreen_PaginationFragment = NewsTickerDefinitionOverviewScreen_PaginationFragment$data;
export type NewsTickerDefinitionOverviewScreen_PaginationFragment$key = {
  readonly " $data"?: NewsTickerDefinitionOverviewScreen_PaginationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewsTickerDefinitionOverviewScreen_PaginationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Admin",
  "Newsticker",
  "NewstickerMessages"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./NewsTickerDefinitionOverviewScreen_PaginationFragmentRefetch.graphql')
    }
  },
  "name": "NewsTickerDefinitionOverviewScreen_PaginationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NewstickerAdminSchema",
          "kind": "LinkedField",
          "name": "Newsticker",
          "plural": false,
          "selections": [
            {
              "alias": "NewstickerMessages",
              "args": null,
              "concreteType": "NewstickerMessagesConnection",
              "kind": "LinkedField",
              "name": "__NewsTickerDefinitionOverviewScreen_NewstickerMessages_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NewstickerMessagesEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "NewstickerMessage",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "text",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forwardingUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isActive",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__id",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "58285c6d94fdf4bc556616569ca2e3e3";

export default node;

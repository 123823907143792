import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ProjectExtendRootNodesTabs_NodeFragment$key } from "../../../__generated__/ProjectExtendRootNodesTabs_NodeFragment.graphql";
import { MultipleChoiceElementsPool } from "../../../app/components/tree/elementspool/MultipleChoiceElementsPool";
import { CreateElementsPoolButton } from "../../../app/components/tree/elementspool/CreateElementsPoolButton";
import { ProjectExtendRootNodeTab } from "../../../app/components/tree/editor/node/NodePart";

const TREE_NODE_FRAGMENT = graphql`
	fragment ProjectExtendRootNodesTabs_NodeFragment on TreeNode {
		id
		structureDefinition {
			... on RootStructureDefinition {
				extension {
					... on RootExtensionImpl {
						elementsPoolId
					}
				}
			}
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: ProjectExtendRootNodesTabs_NodeFragment$key;
	isRoot: boolean;
}

const ElementsPoolTab = ({ treeNodeFragmentRef }: OwnProps) => {
	const node = useFragment<ProjectExtendRootNodesTabs_NodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const hasElementsPool =
		node.structureDefinition.extension?.elementsPoolId !=
		"TXVsdGlwbGVDaG9pY2VFbGVtZW50c1Bvb2w6";

	return (
		<>
			{hasElementsPool && (
				<MultipleChoiceElementsPool
					elementsPoolId={node.structureDefinition.extension?.elementsPoolId as string}
				/>
			)}
			{!hasElementsPool && <CreateElementsPoolButton rootNodeId={node.id} />}
		</>
	);
};

export const ProjectExtendRootNodesTabs = ({
	treeNodeFragmentRef,
	isRoot,
}: OwnProps): ProjectExtendRootNodeTab[] => {
	const tabs = [];

	if (isRoot)
		tabs.push({
			tabTitle: "Fragen Pool",
			tabContent: (
				<ElementsPoolTab treeNodeFragmentRef={treeNodeFragmentRef} isRoot={isRoot} />
			),
		});

	return tabs;
};

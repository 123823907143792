import React, { Suspense } from "react";
import { useFormik } from "formik";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery, useMutation } from "react-relay";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { MessageSenderScreen_Query } from "../../__generated__/MessageSenderScreen_Query.graphql";
import { MessageSenderScreen_sendMessagesMutation } from "../../__generated__/MessageSenderScreen_sendMessagesMutation.graphql";
import { useMatch, useNavigate } from "react-router-dom";
import { ValidatedField } from "../components/core/form/ValidatedField";
import { toast } from "react-toastify";
import { BaseScreen } from "./BaseScreen";

const QUERY = graphql`
	query MessageSenderScreen_Query($messageDefinitionId: ID!) {
		node(id: $messageDefinitionId) {
			id

			... on MessageDefinition {
				internalTitle
			}
		}
		#        ...AccountsSelect_QueryFragment @arguments(ids: [])
		#        ...UsersSelect_QueryFragment @arguments(ids: [])
		#        ...CoursesSelect_QueryFragment @arguments(ids: [])
	}
`;

const SEND_CUSTOMER_MESSAGES_MUTATION = graphql`
	mutation MessageSenderScreen_sendMessagesMutation(
		$messageDefinitionId: ID!
		$selectAllUsers: Boolean!
		$selectedUserIds: [ID!]
	) {
		Admin {
			Messaging {
				sendMessages(
					input: {
						definitionRef: $messageDefinitionId
						selectAllUsers: $selectAllUsers
						selectUserIds: $selectedUserIds
					}
				) {
					clientMutationId
				}
			}
		}
	}
`;

interface FormState {
	selectUsers: string[];
	selectAllUsers: boolean;
}

export const SEND_MESSAGE_DEFINITION_ROUTE = "/messaging/:messageDefinitionId/send";

export const MessageSenderScreen = () => {
	const navigate = useNavigate();

	const router = useMatch(SEND_MESSAGE_DEFINITION_ROUTE);

	const query = useLazyLoadQuery<MessageSenderScreen_Query>(QUERY, {
		messageDefinitionId: router?.params.messageDefinitionId!,
	});

	const [send, isSending] = useMutation<MessageSenderScreen_sendMessagesMutation>(
		SEND_CUSTOMER_MESSAGES_MUTATION,
	);

	const formik = useFormik<FormState>({
		initialValues: {
			selectUsers: [],
			selectAllUsers: false,
		},
		onSubmit: (values, { setSubmitting }) => {
			if (!query.node) {
				setSubmitting(false);
				return;
			}

			const sendMessage = () => {
				send({
					variables: {
						messageDefinitionId: query.node!.id,
						selectedUserIds: values.selectUsers,
						selectAllUsers: values.selectAllUsers,
					},
					onCompleted: () => {
						navigate(`/messaging`);
						toast.success("Nachrichtenversand erfolgreich gestartet.");
					},
				});
			};

			if (values.selectAllUsers) {
				confirmDialog({
					message:
						"Nachricht wirklich senden? Das Senden kann nicht rückgängig gemacht werden.",
					header: "Nachricht senden?",
					icon: "pi pi-exclamation-triangle",
					acceptLabel: "Nachricht senden",
					rejectLabel: "Abbrechen",
					acceptClassName: "p-button-danger",
					accept: () => sendMessage(),
				});
			} else sendMessage();

			setSubmitting(false);
		},
	});

	return query.node ? (
		<BaseScreen>
			<Suspense fallback={<ProgressSpinner />}>
				<h1>Nachricht {query.node.internalTitle} senden</h1>

				{!isSending ? (
					<form onSubmit={formik.handleSubmit} className="p-fluid">
						<Card>
							<h5>Empfänger</h5>

							{/*<ValidatedField<FormState, string[]>*/}
							{/*    name={"selectUsers"}*/}
							{/*    label={"Benutzer auswählen"}*/}
							{/*    component={Sele}*/}
							{/*    formikConfig={formik}*/}
							{/*/>*/}
							<ValidatedField<FormState, boolean>
								name={"selectAllUsers"}
								label={"An alle senden"}
								component={({ fieldValue, updateField }) => {
									return (
										<Suspense fallback={<ProgressSpinner />}>
											<Dropdown
												value={fieldValue}
												options={[
													{
														label: "Nein",
														value: false,
													},
													{
														label: "Ja",
														value: true,
													},
												]}
												onChange={(e) => updateField(e.value)}
											/>
										</Suspense>
									);
								}}
								formikConfig={formik}
							/>
						</Card>

						<div className="flex pt-5">
							<Button
								disabled={false}
								type="submit"
								label={"Senden"}
								className="mr-5"
							/>
							<Button
								disabled={false}
								onClick={() => navigate("/messaging")}
								label={"Abbrechen"}
								className="ml-5 p-button-secondary"
							/>
						</div>
					</form>
				) : (
					<ProgressSpinner />
				)}
			</Suspense>
		</BaseScreen>
	) : null;
};

import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ValidatedField } from "../../../../../../core/form/ValidatedField";
import { DefaultTextEditorComponent } from "../../../../../../core/form/DefaultTextEditorComponent";
import { TextElementForm_TextElementV2Fragment$key } from "../../../../../../../../__generated__/TextElementForm_TextElementV2Fragment.graphql";
import { TextElementForm_EditTextElementV2Mutation } from "../../../../../../../../__generated__/TextElementForm_EditTextElementV2Mutation.graphql";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../../../../../redux/slices/CoreSlice";
import { useTypedDispatch } from "../../../../../../../redux/Store";

const TEXT_ELEMENT_FRAGMENT = graphql`
	fragment TextElementForm_TextElementV2Fragment on TextElementV2 {
		id
		title
		text
	}
`;

const EDIT_TEXT_ELEMENT_MUTATION = graphql`
	mutation TextElementForm_EditTextElementV2Mutation($input: EditTextElementV2Input!) {
		Admin {
			ElearningV2 {
				editTextElement(input: $input) {
					textElement {
						...TextElementForm_TextElementV2Fragment
					}
				}
			}
		}
	}
`;

interface FormState {
	title: string;
	text: string;
}

interface OwnProps {
	textElementFragmentRef: TextElementForm_TextElementV2Fragment$key;
	onBack: () => void;
}

export const TextElementForm = ({ textElementFragmentRef, onBack }: OwnProps) => {
	const element = useFragment<TextElementForm_TextElementV2Fragment$key>(
		TEXT_ELEMENT_FRAGMENT,
		textElementFragmentRef,
	);
	const [editTextElement, isEditingTextElement] =
		useMutation<TextElementForm_EditTextElementV2Mutation>(EDIT_TEXT_ELEMENT_MUTATION);

	const dispatch = useTypedDispatch();
	const formId = "TextElementForm";
	const formik = useFormik<FormState>({
		enableReinitialize: true,
		initialValues: {
			title: element.title,
			text: element.text,
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Das Feld Titel wird benötigt."),
			text: Yup.string().required("Das Feld Text wird benötigt."),
		}),
		onSubmit: (values) => {
			editTextElement({
				variables: {
					input: {
						textElementId: element.id,
						title: values.title,
						text: values.text,
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, string>
				name={"title"}
				label={"Titel"}
				component={DefaultTextEditorComponent}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				formikConfig={formik}
			/>
			<ValidatedField<FormState, string>
				name={"text"}
				label={"Text"}
				component={DefaultTextEditorComponent}
				showListButtons={true}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				formikConfig={formik}
			/>

			<Button
				disabled={isEditingTextElement}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};

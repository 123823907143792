/**
 * @generated SignedSource<<a87ee0ba0c0fa29b096cad78d256a612>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StartContentConfigType = "StartContent_SpecificUserStatusesCan" | "StartContent_CanIfInTreeFlow" | "StartContent_CanNot" | "%future added value";
export type UserStatus = "noProcess" | "initialValidation" | "changeInProgress" | "doccheck" | "inactive" | "efnNeeded" | "active" | "activeEfnNeeded" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectExtendStartContentConfigEditDialogs_TreeNodeFragment$data = {
  readonly id: string;
  readonly typeDefinition: {
    readonly startConfigs?: ReadonlyArray<{
      readonly configType: StartContentConfigType;
      readonly requiredUserStatuses?: ReadonlyArray<UserStatus>;
      readonly dateTime?: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"SpecificUserStatusesCanStartContentConfigForm_StartContentConfigFragment">;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"SpecificUserStatusesCanStartContentConfigForm_TreeNodeFragment">;
  readonly " $fragmentType": "ProjectExtendStartContentConfigEditDialogs_TreeNodeFragment";
};
export type ProjectExtendStartContentConfigEditDialogs_TreeNodeFragment = ProjectExtendStartContentConfigEditDialogs_TreeNodeFragment$data;
export type ProjectExtendStartContentConfigEditDialogs_TreeNodeFragment$key = {
  readonly " $data"?: ProjectExtendStartContentConfigEditDialogs_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectExtendStartContentConfigEditDialogs_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectExtendStartContentConfigEditDialogs_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "startConfigs",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "configType",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "requiredUserStatuses",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dateTime",
                      "storageKey": null
                    }
                  ],
                  "type": "SpecifiedUserStatusesCanStartContentConfig",
                  "abstractKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SpecificUserStatusesCanStartContentConfigForm_StartContentConfigFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SpecificUserStatusesCanStartContentConfigForm_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "1d02d179a6d97c9bc5daa653d7a328be";

export default node;

import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { ProjectRewardEditor_TreeNodeFragment$key } from "../../../__generated__/ProjectRewardEditor_TreeNodeFragment.graphql";
import { RewardEditor } from "../../../app/components/rewards/RewardEditor";
import { ProjectRewardEditor_Query } from "../../../__generated__/ProjectRewardEditor_Query.graphql";

const TREE_NODE_FRAGMENT = graphql`
	fragment ProjectRewardEditor_TreeNodeFragment on TreeNode {
		id
		...RewardEditor_TreeNodeFragment
	}
`;

const QUERY = graphql`
	query ProjectRewardEditor_Query {
		Admin {
			Certificates {
				CertificateTemplates {
					...RewardEditor_CertificateTemplatesConnectionV2Fragment
				}
			}
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: ProjectRewardEditor_TreeNodeFragment$key;
}

export const ProjectRewardEditor = ({ treeNodeFragmentRef }: OwnProps) => {
	const node = useFragment<ProjectRewardEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const data = useLazyLoadQuery<ProjectRewardEditor_Query>(QUERY, {
		id: node.id,
		skip: !node,
	});

	return (
		<RewardEditor
			treeNodeFragmentRef={node}
			certificateTemplatesConnectionFragmentRef={data.Admin.Certificates.CertificateTemplates}
		/>
	);
};

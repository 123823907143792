/**
 * @generated SignedSource<<4f950c19ac26165d5ce39f4beba5c3a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StartTreeConfigType = "StartTree_CMECertifiedNeedsSpecificGlobalUserStatus" | "StartTree_SpecificUserRolesCan" | "StartTree_CanNot" | "StartTree_SpecificUserStatusesCan" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StartTreeConfigEditor_TreeNodeFragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly startConfigs?: ReadonlyArray<{
      readonly id: string;
      readonly configType: StartTreeConfigType;
      readonly " $fragmentSpreads": FragmentRefs<"ProjectExtendStartTreeConfigEditDialogs_StartTreeConfigFragment">;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ProjectExtendStartTreeConfigEditDialogs_TreeNodeFragment">;
  readonly " $fragmentType": "StartTreeConfigEditor_TreeNodeFragment";
};
export type StartTreeConfigEditor_TreeNodeFragment = StartTreeConfigEditor_TreeNodeFragment$data;
export type StartTreeConfigEditor_TreeNodeFragment$key = {
  readonly " $data"?: StartTreeConfigEditor_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"StartTreeConfigEditor_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StartTreeConfigEditor_TreeNodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "startConfigs",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "configType",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProjectExtendStartTreeConfigEditDialogs_StartTreeConfigFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectExtendStartTreeConfigEditDialogs_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "3ef5c1a7066201c1cbf2c90d55f4888a";

export default node;

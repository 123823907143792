/**
 * @generated SignedSource<<59031d91ff0fcf1af707ac3b0adecac3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RestartIfPassedContentConfigType = "RestartIfPassedContent_CanNot" | "RestartIfPassedContent_Can" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RestartIfPassedContentConfigEditor_TreeNodeFragment$data = {
  readonly id: string;
  readonly typeDefinition: {
    readonly restartIfPassedConfig?: {
      readonly configType: RestartIfPassedContentConfigType;
    };
  };
  readonly " $fragmentType": "RestartIfPassedContentConfigEditor_TreeNodeFragment";
};
export type RestartIfPassedContentConfigEditor_TreeNodeFragment = RestartIfPassedContentConfigEditor_TreeNodeFragment$data;
export type RestartIfPassedContentConfigEditor_TreeNodeFragment$key = {
  readonly " $data"?: RestartIfPassedContentConfigEditor_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RestartIfPassedContentConfigEditor_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RestartIfPassedContentConfigEditor_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "restartIfPassedConfig",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "configType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ContentTypeDefinition",
          "abstractKey": "__isContentTypeDefinition"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "05219d325c70639e4a42df47888414d7";

export default node;

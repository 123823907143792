/**
 * @generated SignedSource<<e998e60a740d9027f145586ee687ff2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentsColumn_LandingPageFragment$data = {
  readonly rootNodes: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly structureDefinition: {
          readonly title: string;
          readonly extension?: {
            readonly isVisibleOnlyForAdmins?: boolean;
          };
        };
      };
    } | null> | null;
  };
  readonly " $fragmentType": "ContentsColumn_LandingPageFragment";
};
export type ContentsColumn_LandingPageFragment = ContentsColumn_LandingPageFragment$data;
export type ContentsColumn_LandingPageFragment$key = {
  readonly " $data"?: ContentsColumn_LandingPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentsColumn_LandingPageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentsColumn_LandingPageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunitiesV2Connection",
      "kind": "LinkedField",
      "name": "rootNodes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnOpportunitiesV2Edge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LearnOpportunityV2",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "structureDefinition",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "extension",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "isVisibleOnlyForAdmins",
                                  "storageKey": null
                                }
                              ],
                              "type": "LearnOpportunityRootExtensionImpl",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "LearnOpportunityRootStructureDefinition",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LandingPageV2",
  "abstractKey": null
};

(node as any).hash = "e78a864802c98425fa03f8fd016a0920";

export default node;

/**
 * @generated SignedSource<<296919663730407ff3032301eb9aeffb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedbacksTableV2_FeedbackFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ActionColumn_FeedbackFragment" | "ContentColumn_FeedbackFragment" | "CreatedAtColumn_FeedbackFragment" | "ScreenColumn_FeedbackFragment" | "UserColumn_FeedbackFragment">;
  readonly " $fragmentType": "FeedbacksTableV2_FeedbackFragment";
};
export type FeedbacksTableV2_FeedbackFragment = FeedbacksTableV2_FeedbackFragment$data;
export type FeedbacksTableV2_FeedbackFragment$key = {
  readonly " $data"?: FeedbacksTableV2_FeedbackFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedbacksTableV2_FeedbackFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "FeedbacksTableV2_FeedbackFragment"
};

(node as any).hash = "cf1004f4215724145cb29e9b77a60ffe";

export default node;

import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { TitleColumn_TreeNodeFragment$key } from "../../../../../__generated__/TitleColumn_TreeNodeFragment.graphql";

const TREE_NODE_FRAGMENT = graphql`
	fragment TitleColumn_TreeNodeFragment on TreeNode {
		structureDefinition {
			... on RootStructureDefinition {
				icon {
					url
				}
				title
			}
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: TitleColumn_TreeNodeFragment$key;
}

export const TitleColumn = ({ treeNodeFragmentRef }: OwnProps) => {
	const treeNode = useFragment<TitleColumn_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	return (
		<div className="flex flex-row align-items-center">
			{treeNode.structureDefinition.icon?.url && (
				<img
					alt={treeNode.structureDefinition.title}
					className="mr-2"
					src={treeNode.structureDefinition.icon.url}
					height={50}
				/>
			)}
			<div>{treeNode.structureDefinition.title}</div>
		</div>
	);
};

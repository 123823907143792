/**
 * @generated SignedSource<<253f582fbde6c74b02005235858a0e94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectRewardEditor_Query$variables = {};
export type ProjectRewardEditor_QueryVariables = ProjectRewardEditor_Query$variables;
export type ProjectRewardEditor_Query$data = {
  readonly Admin: {
    readonly Certificates: {
      readonly CertificateTemplates: {
        readonly " $fragmentSpreads": FragmentRefs<"RewardEditor_CertificateTemplatesConnectionV2Fragment">;
      };
    };
  };
};
export type ProjectRewardEditor_QueryResponse = ProjectRewardEditor_Query$data;
export type ProjectRewardEditor_Query = {
  variables: ProjectRewardEditor_QueryVariables;
  response: ProjectRewardEditor_Query$data;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectRewardEditor_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CertificatesAdminSchema",
            "kind": "LinkedField",
            "name": "Certificates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CertificateTemplatesConnection",
                "kind": "LinkedField",
                "name": "CertificateTemplates",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "RewardEditor_CertificateTemplatesConnectionV2Fragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProjectRewardEditor_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CertificatesAdminSchema",
            "kind": "LinkedField",
            "name": "Certificates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CertificateTemplatesConnection",
                "kind": "LinkedField",
                "name": "CertificateTemplates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CertificateTemplatesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CertificateTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9e99e2c4b2f8c7514fa5f5d2f76aa355",
    "id": null,
    "metadata": {},
    "name": "ProjectRewardEditor_Query",
    "operationKind": "query",
    "text": "query ProjectRewardEditor_Query {\n  Admin {\n    Certificates {\n      CertificateTemplates {\n        ...RewardEditor_CertificateTemplatesConnectionV2Fragment\n      }\n    }\n  }\n}\n\nfragment CreateCertificatesRewardButton_CertificateTemplatesConnectionV2Fragment on CertificateTemplatesConnection {\n  edges {\n    node {\n      id\n    }\n  }\n}\n\nfragment CreateFachberaterRewardButton_CertificateTemplatesConnectionV2Fragment on CertificateTemplatesConnection {\n  edges {\n    node {\n      id\n    }\n  }\n}\n\nfragment RewardEditor_CertificateTemplatesConnectionV2Fragment on CertificateTemplatesConnection {\n  ...CreateCertificatesRewardButton_CertificateTemplatesConnectionV2Fragment\n  ...CreateFachberaterRewardButton_CertificateTemplatesConnectionV2Fragment\n}\n"
  }
};

(node as any).hash = "d3a418c6bf917db0511cf5f5a20bebc0";

export default node;

import { InstructorsTable } from "../components/instructors/InstructorsTable";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { InstructorsScreen_InstructorsQuery } from "../../__generated__/InstructorsScreen_InstructorsQuery.graphql";
import { BaseScreen } from "./BaseScreen";

export const InstructorsScreen = () => {
	const data = useLazyLoadQuery<InstructorsScreen_InstructorsQuery>(
		graphql`
			query InstructorsScreen_InstructorsQuery {
				...InstructorsTable_InstructorsListFragment
			}
		`,
		{},
		{ fetchPolicy: "network-only" },
	);

	return (
		<BaseScreen>
			<h1>Lehrende</h1>
			<InstructorsTable instructorsFragmentRef={data} />
		</BaseScreen>
	);
};

/**
 * @generated SignedSource<<e5c96350d6cf2c3ff9b23d784a613fc8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LandingPagesV2Table_LandingPageFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ActionColumn_LandingPageFragment" | "ContentsColumn_LandingPageFragment" | "DescriptionColumn_LandingPageFragment" | "TitleColumn_LandingPageFragment" | "UrlPathColumn_LandingPageFragment">;
  readonly " $fragmentType": "LandingPagesV2Table_LandingPageFragment";
};
export type LandingPagesV2Table_LandingPageFragment = LandingPagesV2Table_LandingPageFragment$data;
export type LandingPagesV2Table_LandingPageFragment$key = {
  readonly " $data"?: LandingPagesV2Table_LandingPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LandingPagesV2Table_LandingPageFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "LandingPagesV2Table_LandingPageFragment"
};

(node as any).hash = "81c6900702047efbc76e01c2561e22b9";

export default node;

/**
 * @generated SignedSource<<f9c1b58179d907af7e29a9cf49c45dde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PointsAdditionsTable_PointsAdditionFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ContentNodeTitleColumn_PointsAdditionFragment" | "PointsPoolColumn_PointsAdditionFragment" | "ReceivedAtColumn_PointsAdditionFragment" | "UserColumn_PointsAdditionFragment">;
  readonly " $fragmentType": "PointsAdditionsTable_PointsAdditionFragment";
};
export type PointsAdditionsTable_PointsAdditionFragment = PointsAdditionsTable_PointsAdditionFragment$data;
export type PointsAdditionsTable_PointsAdditionFragment$key = {
  readonly " $data"?: PointsAdditionsTable_PointsAdditionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PointsAdditionsTable_PointsAdditionFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "PointsAdditionsTable_PointsAdditionFragment"
};

(node as any).hash = "7b12f6065a74fc0c08c10d7898761b7b";

export default node;

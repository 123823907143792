import { useMatch, useNavigate } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { EDIT_TEMPLATE_PATH } from "../../app/routes/EmailModuleRoutes";
import { EditEmailTemplateScreen_Query } from "../../__generated__/EditEmailTemplateScreen_Query.graphql";
import { EditEmailTemplateScreen_EmailTemplateFragment$key } from "../../__generated__/EditEmailTemplateScreen_EmailTemplateFragment.graphql";
import { EditEmailTemplateScreen_UpdatePersonalizedEmailTemplateMutation } from "../../__generated__/EditEmailTemplateScreen_UpdatePersonalizedEmailTemplateMutation.graphql";
import { BaseScreen } from "./BaseScreen";
import { EditEmailTemplateForm } from "../components/emails/EditEmailTemplateForm";
import { Permission } from "../../__generated__/MainNavigation_Query.graphql";
import { useSelector } from "react-redux";
import { selectHasPermissions } from "../redux/slices/CurrentUserSlice";

const QUERY = graphql`
	query EditEmailTemplateScreen_Query($id: ID!) {
		node(id: $id) {
			... on PersonalizedEmailTemplate {
				...EditEmailTemplateScreen_EmailTemplateFragment
			}
		}
	}
`;

const EMAIL_TEMPLATE_FRAGMENT = graphql`
	fragment EditEmailTemplateScreen_EmailTemplateFragment on PersonalizedEmailTemplate {
		id
		template {
			key
			subject
			previewText
			body
			variables
		}
	}
`;

const UPDATE_PERSONALIZED_EMAIL_TEMPLATE_MUTATION = graphql`
	mutation EditEmailTemplateScreen_UpdatePersonalizedEmailTemplateMutation(
		$input: UpdatePersonalizedEmailTemplateInput!
	) {
		Admin {
			Email {
				updatePersonalizedEmailTemplate(input: $input) {
					data {
						node {
							...EditEmailTemplateScreen_EmailTemplateFragment
						}
					}
				}
			}
		}
	}
`;

export const EditEmailTemplateScreen = () => {
	const navigate = useNavigate();
	const match = useMatch(EDIT_TEMPLATE_PATH);

	const query = useLazyLoadQuery<EditEmailTemplateScreen_Query>(QUERY, {
		id: match?.params.emailTemplateId!,
	});

	const emailTemplate = useFragment<EditEmailTemplateScreen_EmailTemplateFragment$key>(
		EMAIL_TEMPLATE_FRAGMENT,
		query.node,
	)!;

	const [updatePersonalizedEmailTemplate, isUpdatingPersonalizedEmailTemplate] =
		useMutation<EditEmailTemplateScreen_UpdatePersonalizedEmailTemplateMutation>(
			UPDATE_PERSONALIZED_EMAIL_TEMPLATE_MUTATION,
		);

	const checkPermission = (permissions: Permission[]) =>
		useSelector((state: any) => selectHasPermissions(state, permissions));

	const isAdmin = checkPermission([
		"AccountPermission_System_Root",
		"UserInAccountPermission_System_Owner",
	]);

	if (!isAdmin) {
		return <div>Kein Zugriff</div>;
	}

	return (
		<BaseScreen>
			<h1 className="mb-3">Bearbeite Email-Vorlage</h1>

			<EditEmailTemplateForm
				templateKey={emailTemplate.template.key}
				initialValues={{
					subject: emailTemplate.template.subject,
					preview: emailTemplate.template.previewText,
					body: emailTemplate.template.body,
				}}
				variables={emailTemplate.template.variables}
				loading={isUpdatingPersonalizedEmailTemplate}
				onSubmit={(values) => {
					const data = {
						key: emailTemplate.template.key,
						subject: values.subject!,
						previewText: values.preview!,
						body: values.body!,
						variables: [...emailTemplate.template.variables],
					};
					if (emailTemplate) {
						updatePersonalizedEmailTemplate({
							variables: {
								input: {
									id: emailTemplate.id,
									data: data,
								},
							},
							onCompleted: () => navigate(`/email-templates`),
						});
					}
				}}
			/>
		</BaseScreen>
	);
};

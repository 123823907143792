import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Node_TreeNodeFragment$key } from "../../../../../__generated__/Node_TreeNodeFragment.graphql";
import { NodeConfigurationBadges } from "./NodeConfigurationBadges";

const TREE_NODE_FRAGMENT = graphql`
	fragment Node_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			title
		}
		typeDefinition {
			definitionType
			... on BranchTypeDefinition {
				childRefs
			}
		}
		...NodeConfigurationBadges_TreeNodeFragment
		...ProjectExtendNodeConfigurationBadges_TreeNodeFragment
	}
`;

interface OwnProps {
	treeNodeFragmentRef: Node_TreeNodeFragment$key;
}

export const Node = ({ treeNodeFragmentRef }: OwnProps) => {
	const treeNode = useFragment<Node_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const branchNodeIsEmpty =
		treeNode.typeDefinition.definitionType === "branch" &&
		(!treeNode.typeDefinition.childRefs || treeNode.typeDefinition.childRefs.length === 0);

	return treeNode ? (
		<div
			key={treeNode.id}
			className={`flex align-items-center ${branchNodeIsEmpty ? "opacity-50" : ""}`}
		>
			<span className="mr-2">
				{treeNode.structureDefinition.title}{" "}
				{treeNode.typeDefinition.definitionType === "content" && "(E-Learning)"}{" "}
				{branchNodeIsEmpty && "(leer)"}
			</span>
			<NodeConfigurationBadges treeNodeFragmentRef={treeNode} />
		</div>
	) : null;
};

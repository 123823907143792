import { useMatch, useNavigate } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { EditNotificationTemplateScreen_Query } from "../../__generated__/EditNotificationTemplateScreen_Query.graphql";
import { EditNotificationTemplateScreen_NotificationTemplateFragment$key } from "../../__generated__/EditNotificationTemplateScreen_NotificationTemplateFragment.graphql";
import { EditNotificationTemplateScreen_UpdatePersonalizedNotificationTemplateMutation } from "../../__generated__/EditNotificationTemplateScreen_UpdatePersonalizedNotificationTemplateMutation.graphql";
import { BaseScreen } from "./BaseScreen";
import { EditNotificationTemplateForm } from "../components/notifications/EditNotificationTemplateForm";
import { Permission } from "../../__generated__/MainNavigation_Query.graphql";
import { useSelector } from "react-redux";
import { selectHasPermissions } from "../redux/slices/CurrentUserSlice";

const QUERY = graphql`
	query EditNotificationTemplateScreen_Query($id: ID!) {
		node(id: $id) {
			... on PersonalizedNotificationTemplate {
				...EditNotificationTemplateScreen_NotificationTemplateFragment
			}
		}
	}
`;

const Notification_TEMPLATE_FRAGMENT = graphql`
	fragment EditNotificationTemplateScreen_NotificationTemplateFragment on PersonalizedNotificationTemplate {
		id
		template {
			key
			title
			message
			variables
		}
	}
`;

const UPDATE_PERSONALIZED_Notification_TEMPLATE_MUTATION = graphql`
	mutation EditNotificationTemplateScreen_UpdatePersonalizedNotificationTemplateMutation(
		$input: UpdatePersonalizedNotificationTemplateInput!
	) {
		Admin {
			Notification {
				updatePersonalizedNotificationTemplate(input: $input) {
					data {
						node {
							...EditNotificationTemplateScreen_NotificationTemplateFragment
						}
					}
				}
			}
		}
	}
`;

export const EDIT_TEMPLATE_PATH = "/notification-templates/:notificationTemplateIds/edit";

export const EditNotificationTemplateScreen = () => {
	const navigate = useNavigate();
	const match = useMatch(EDIT_TEMPLATE_PATH);

	const query = useLazyLoadQuery<EditNotificationTemplateScreen_Query>(QUERY, {
		id: match?.params.notificationTemplateIds!,
	});

	const notificationTemplate =
		useFragment<EditNotificationTemplateScreen_NotificationTemplateFragment$key>(
			Notification_TEMPLATE_FRAGMENT,
			query.node,
		)!;

	const [updatePersonalizedNotificationTemplate, isUpdatingPersonalizedNotificationTemplate] =
		useMutation<EditNotificationTemplateScreen_UpdatePersonalizedNotificationTemplateMutation>(
			UPDATE_PERSONALIZED_Notification_TEMPLATE_MUTATION,
		);

	const checkPermission = (permissions: Permission[]) =>
		useSelector((state: any) => selectHasPermissions(state, permissions));

	const isAdmin = checkPermission([
		"AccountPermission_System_Root",
		"UserInAccountPermission_System_Owner",
	]);

	if (!isAdmin) {
		return <div>Kein Zugriff</div>;
	}

	return (
		<BaseScreen>
			<h1 className="mb-3">Bearbeite Benachrichtigungs-Vorlage</h1>

			<EditNotificationTemplateForm
				initialValues={{
					title: notificationTemplate.template.title,
					message: notificationTemplate.template.message,
				}}
				variables={notificationTemplate.template.variables}
				loading={isUpdatingPersonalizedNotificationTemplate}
				onSubmit={(values) => {
					const data = {
						key: notificationTemplate.template.key,
						title: values.title!,
						message: values.message!,
						variables: [...notificationTemplate.template.variables],
					};
					if (notificationTemplate) {
						updatePersonalizedNotificationTemplate({
							variables: {
								input: {
									id: notificationTemplate.id,
									data: data,
								},
							},
							onCompleted: () => navigate(`/notification-templates`),
						});
					}
				}}
			/>
		</BaseScreen>
	);
};

/**
 * @generated SignedSource<<4b94f4ecd2cee6078d45b7d0f84570f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditNodeCoreInput = {
  title: string;
  description?: string | null;
  shortDescription?: string | null;
  nodeId: string;
  imageId?: string | null;
  instructorIds: ReadonlyArray<string>;
  clientMutationId?: string | null;
};
export type EditNodeForm_EditNodeCoreMutation$variables = {
  input: EditNodeCoreInput;
};
export type EditNodeForm_EditNodeCoreMutationVariables = EditNodeForm_EditNodeCoreMutation$variables;
export type EditNodeForm_EditNodeCoreMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly editNodeCore: {
        readonly editedNode: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"EditNodeForm_TreeNodeFragment" | "Node_TreeNodeFragment">;
        };
      } | null;
    };
  };
};
export type EditNodeForm_EditNodeCoreMutationResponse = EditNodeForm_EditNodeCoreMutation$data;
export type EditNodeForm_EditNodeCoreMutation = {
  variables: EditNodeForm_EditNodeCoreMutationVariables;
  response: EditNodeForm_EditNodeCoreMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "definitionType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditNodeForm_EditNodeCoreMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditNodeCorePayload",
                "kind": "LinkedField",
                "name": "editNodeCore",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "editedNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "EditNodeForm_TreeNodeFragment"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "Node_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditNodeForm_EditNodeCoreMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditNodeCorePayload",
                "kind": "LinkedField",
                "name": "editNodeCore",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "editedNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AdvertisementConfiguration",
                                "kind": "LinkedField",
                                "name": "advertisementConfiguration",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isFeatured",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "extension",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "displayType",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "TreeConfigConsequences",
                                        "kind": "LinkedField",
                                        "name": "configConsequences",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isRewardedTo",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "RootExtensionImpl",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shortDescription",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "instructors",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "superId",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "imageId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "typeDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "childRefs",
                                "storageKey": null
                              }
                            ],
                            "type": "BranchTypeDefinition",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "rewards",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "kind",
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "ContentTypeDefinition",
                            "abstractKey": "__isContentTypeDefinition"
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Versioning",
                        "kind": "LinkedField",
                        "name": "versioning",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "draftVersion",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "releaseVersion",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a7d7a35df57efdbb668029dffd66bc8f",
    "id": null,
    "metadata": {},
    "name": "EditNodeForm_EditNodeCoreMutation",
    "operationKind": "mutation",
    "text": "mutation EditNodeForm_EditNodeCoreMutation(\n  $input: EditNodeCoreInput!\n) {\n  Admin {\n    Tree {\n      editNodeCore(input: $input) {\n        editedNode {\n          id\n          ...EditNodeForm_TreeNodeFragment\n          ...Node_TreeNodeFragment\n        }\n      }\n    }\n  }\n}\n\nfragment EditNodeForm_TreeNodeFragment on TreeNode {\n  id\n  structureDefinition {\n    __typename\n    title\n    definitionType\n  }\n  description\n  shortDescription\n  instructors {\n    __typename\n    superId\n    id\n  }\n  imageId\n}\n\nfragment NodeConfigurationBadges_TreeNodeFragment on TreeNode {\n  id\n  structureDefinition {\n    __typename\n    definitionType\n    ... on RootStructureDefinition {\n      advertisementConfiguration {\n        isFeatured\n      }\n      extension {\n        __typename\n        ... on RootExtensionImpl {\n          displayType\n          configConsequences {\n            isRewardedTo\n          }\n        }\n      }\n    }\n  }\n  typeDefinition {\n    __typename\n    definitionType\n    ... on ContentTypeDefinition {\n      __isContentTypeDefinition: __typename\n      rewards {\n        __typename\n        kind\n        id\n      }\n    }\n  }\n  versioning {\n    draftVersion\n    releaseVersion\n  }\n}\n\nfragment Node_TreeNodeFragment on TreeNode {\n  id\n  structureDefinition {\n    __typename\n    title\n  }\n  typeDefinition {\n    __typename\n    definitionType\n    ... on BranchTypeDefinition {\n      childRefs\n    }\n  }\n  ...NodeConfigurationBadges_TreeNodeFragment\n  ...ProjectExtendNodeConfigurationBadges_TreeNodeFragment\n}\n\nfragment ProjectExtendNodeConfigurationBadges_TreeNodeFragment on TreeNode {\n  typeDefinition {\n    __typename\n    definitionType\n    ... on ContentTypeDefinition {\n      __isContentTypeDefinition: __typename\n      rewards {\n        __typename\n        kind\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "528f41618a1f5585a44547cab2445e8e";

export default node;

import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { FileElementForm } from "./file/FileElementForm";
import { ElementForm_ElementV2Fragment$key } from "../../../../../../../__generated__/ElementForm_ElementV2Fragment.graphql";
import { MultipleChoiceElementForm } from "./multiplechoice/MultipleChoiceElementForm";
import { PodcastElementForm } from "./podcast/PodcastElementForm";
import { VideoElementForm } from "./video/VideoElementForm";
import { TextElementForm } from "./text/TextElementForm";
import { selectArrayOfEditedForms } from "../../../../../../redux/slices/CoreSlice";
import { useTypedSelector } from "../../../../../../redux/Store";
import { htmlTagsInTextRemover } from "../../../../../../functions/htmlTagsInTextRemover";
import { ProjectExtendElementForms } from "../../../../../../../lms-admin-impl/components/relay/ProjectExtendElementForms";

const ELEMENT_FRAGMENT = graphql`
	fragment ElementForm_ElementV2Fragment on ElementV2 {
		title
		elementType
		... on TextElementV2 {
			...TextElementForm_TextElementV2Fragment
		}
		... on FileElementV2 {
			...FileElementForm_FileElementV2Fragment
		}
		... on VideoElement {
			...VideoElementForm_VideoElementFragment
		}
		... on MultipleChoiceElementV2 {
			...MultipleChoiceElementForm_MultipleChoiceElementV2Fragment
		}
		... on PodcastElementV2 {
			...PodcastElementForm_PodcastElementV2Fragment
		}
		...ProjectExtendElementForms_ElementV2Fragment
	}
`;

interface OwnProps {
	elementFragmentRef: ElementForm_ElementV2Fragment$key;
	onBack: () => void;
}

export const ElementForm = ({ elementFragmentRef, onBack }: OwnProps) => {
	const element = useFragment<ElementForm_ElementV2Fragment$key>(
		ELEMENT_FRAGMENT,
		elementFragmentRef,
	);
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);

	const Form = (() => {
		switch (element.elementType) {
			case "text":
				return <TextElementForm textElementFragmentRef={element} onBack={onBack} />;
			case "file":
				return <FileElementForm fileElementFragmentRef={element} onBack={onBack} />;
			case "video":
				return <VideoElementForm videoElementFragmentRef={element} onBack={onBack} />;
			case "multipleChoice":
				return (
					<MultipleChoiceElementForm
						multipleChoiceElementFragmentRef={element}
						onBack={onBack}
					/>
				);
			case "podcast":
				return <PodcastElementForm podcastElementFragmentRef={element} onBack={onBack} />;
			default:
				return <ProjectExtendElementForms elementFragmentRef={element} onBack={onBack} />;
		}
	})();
	return (
		<div>
			<div className="flex align-items-center">
				<h2>Bearbeite Element {htmlTagsInTextRemover(element.title)}</h2>
				<Button
					disabled={arrayOfEditedForms.length > 0}
					className="ml-auto"
					icon="pi pi-times"
					onClick={() => {
						onBack();
					}}
				/>
			</div>
			{Form}
		</div>
	);
};

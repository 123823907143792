/**
 * @generated SignedSource<<4477c6275f44b0c46e06d4a8203a3b9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserNameColumn_Fragment$data = {
  readonly extension: {
    readonly firstName?: string | null;
    readonly lastName?: string | null;
  };
  readonly " $fragmentType": "UserNameColumn_Fragment";
};
export type UserNameColumn_Fragment = UserNameColumn_Fragment$data;
export type UserNameColumn_Fragment$key = {
  readonly " $data"?: UserNameColumn_Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserNameColumn_Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserNameColumn_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extension",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            }
          ],
          "type": "InfectopharmUserExtensionImpl",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "bedffb2b9e0ebfb959b0ccca26500138";

export default node;

import React from "react";
import { Suspense } from "react";
import { BaseScreen } from "./BaseScreen";
import { PublishedNodesSearchContainer } from "../components/published-node-users/PublishedNodesSearchContainer";
import { PublishedNodesTable } from "../components/published-node-users/PublishedNodesTable";
import { PublishedNodesFilters } from "../components/published-node-users/PublishedNodesFilters";

export const PublishedNodesAndUsersScreen = () => {
	return (
		<BaseScreen>
			<PublishedNodesSearchContainer>
				<PublishedNodesFilters />
				<Suspense fallback={<div>Lade...</div>}>
					<PublishedNodesTable />
				</Suspense>
			</PublishedNodesSearchContainer>
		</BaseScreen>
	);
};

import { graphql } from "babel-plugin-relay/macro";
import { PoolElementForm_ElementV2Fragment$key } from "../../../../__generated__/PoolElementForm_ElementV2Fragment.graphql";
import { MultipleChoicePoolElementForm } from "./MultipleChoicePoolElementForm";
import { useFragment } from "react-relay";
import { useTypedSelector } from "../../../../infecto-lms-admin/redux/Store";
import { selectArrayOfEditedForms } from "../../../../infecto-lms-admin/redux/slices/CoreSlice";
import { htmlTagsInTextRemover } from "../../../../infecto-lms-admin/functions/htmlTagsInTextRemover";
import { Button } from "primereact/button";

const POOL_ELEMENT_FRAGMENT = graphql`
	fragment PoolElementForm_ElementV2Fragment on ElementV2 {
		title
		elementType
		... on MultipleChoiceElementV2 {
			...MultipleChoicePoolElementForm_MultipleChoiceElementV2Fragment
		}
	}
`;

interface OwnProps {
	poolId: string;
	elementFragmentRef: PoolElementForm_ElementV2Fragment$key;
	onBack: () => void;
}

export const PoolElementForm = ({ poolId, elementFragmentRef, onBack }: OwnProps) => {
	const element = useFragment<PoolElementForm_ElementV2Fragment$key>(
		POOL_ELEMENT_FRAGMENT,
		elementFragmentRef,
	);

	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);

	return (
		<div>
			<div className="flex align-items-center">
				<h2>Bearbeite Element {htmlTagsInTextRemover(element.title)}</h2>
				<Button
					disabled={arrayOfEditedForms.length > 0}
					className="ml-auto"
					icon="pi pi-times"
					onClick={() => {
						onBack();
					}}
				/>
			</div>

			<MultipleChoicePoolElementForm
				poolId={poolId}
				elementFragmentRef={element}
				onBack={onBack}
			></MultipleChoicePoolElementForm>
		</div>
	);
};

/**
 * @generated SignedSource<<887677fca02435e62a9c9a88914db286>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ShowAnswerTreeConfigType = "ShowAnswerTree_DoNot" | "ShowAnswerTree_AfterDateTimeDo" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AfterDateTimeDoShowAnswerTreeConfigForm_AfterDateTimeDoShowAnswerTreeConfigFragment$data = {
  readonly id: string;
  readonly configType: ShowAnswerTreeConfigType;
  readonly dateTime: string;
  readonly " $fragmentType": "AfterDateTimeDoShowAnswerTreeConfigForm_AfterDateTimeDoShowAnswerTreeConfigFragment";
};
export type AfterDateTimeDoShowAnswerTreeConfigForm_AfterDateTimeDoShowAnswerTreeConfigFragment = AfterDateTimeDoShowAnswerTreeConfigForm_AfterDateTimeDoShowAnswerTreeConfigFragment$data;
export type AfterDateTimeDoShowAnswerTreeConfigForm_AfterDateTimeDoShowAnswerTreeConfigFragment$key = {
  readonly " $data"?: AfterDateTimeDoShowAnswerTreeConfigForm_AfterDateTimeDoShowAnswerTreeConfigFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AfterDateTimeDoShowAnswerTreeConfigForm_AfterDateTimeDoShowAnswerTreeConfigFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AfterDateTimeDoShowAnswerTreeConfigForm_AfterDateTimeDoShowAnswerTreeConfigFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "configType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateTime",
      "storageKey": null
    }
  ],
  "type": "AfterDateTimeDoShowAnswerTreeConfig",
  "abstractKey": null
};

(node as any).hash = "9b1d12cfed1c84d10f44738f62ec0973";

export default node;

import { graphql } from "babel-plugin-relay/macro";
import { OrderList } from "primereact/orderlist";
import { useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import React, { useState } from "react";
import { RewardForm } from "./RewardForm";
import { Card } from "primereact/card";
import styled from "styled-components";
import { CreateCertificatesRewardButton } from "./certificate/CreateCertificatesRewardButton";
import { RewardEditor_TreeNodeFragment$key } from "../../../__generated__/RewardEditor_TreeNodeFragment.graphql";
import { RewardEditor_CertificateTemplatesConnectionV2Fragment$key } from "../../../__generated__/RewardEditor_CertificateTemplatesConnectionV2Fragment.graphql";
import { RewardEditor_DeleteRewardMutation } from "../../../__generated__/RewardEditor_DeleteRewardMutation.graphql";
import { RewardForm_RewardConfigurationV2Fragment$key } from "../../../__generated__/RewardForm_RewardConfigurationV2Fragment.graphql";
import { RECIPIENT_TYPE_TRANSLATIONS } from "../../../infecto-lms-admin/i18n/graph/reward-type";
import { WarningUnsavedChangesDialog } from "../../../infecto-lms-admin/components/core/dialog/WarningUnsavedChangesDialog";
import { selectArrayOfEditedForms } from "../../../infecto-lms-admin/redux/slices/CoreSlice";
import { toast } from "react-toastify";
import { CreateFachberaterRewardButton } from "./fachberater/CreateFachberaterRewardButton";
import { CreateAwardPacPointsRewardButton } from "./pacpoints/CreateAwardPacPointsRewardButton";
import { CreateAwardPharmaPointsRewardButton } from "./pharmapoints/CreateAwardPharmaPointsRewardButton";
import { useTypedSelector } from "../../../infecto-lms-admin/redux/Store";

const TREE_NODE_FRAGMENT = graphql`
	fragment RewardEditor_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ContentTypeDefinition {
				rewards {
					id
					kind
					... on EmailRewardConfiguration {
						recipientType
						subject
					}
					... on PointsRewardConfiguration {
						amount
						pointPool {
							name
						}
					}
					... on CertificateRewardConfiguration {
						certificateTemplate {
							name
						}
					}
					... on MakeFachberaterRewardConfiguration {
						certificateTemplate {
							name
						}
					}
					... on AwardPacPointsRewardConfiguration {
						amount
					}
					... on AwardPharmaPointsRewardConfiguration {
						amount
					}
					...RewardForm_RewardConfigurationV2Fragment
				}
			}
		}
		...RewardForm_TreeNodeFragment
		...CreateCertificatesRewardButton_TreeNodeFragment
		...CreateEmailRewardButton_TreeNodeFragment
		...CreateFachberaterRewardButton_TreeNodeFragment
		...CreateAwardPacPointsRewardButton_TreeNodeFragment
		...CreateAwardPharmaPointsRewardButton_TreeNodeFragment
	}
`;

const CERTIFICATE_TEMPLATES_CONNECTION_FRAGMENT = graphql`
	fragment RewardEditor_CertificateTemplatesConnectionV2Fragment on CertificateTemplatesConnection {
		...CreateCertificatesRewardButton_CertificateTemplatesConnectionV2Fragment
		...CreateFachberaterRewardButton_CertificateTemplatesConnectionV2Fragment
	}
`;

const REMOVE_REWARD_MUTATION = graphql`
	mutation RewardEditor_DeleteRewardMutation($input: DeleteRewardInput!) {
		Admin {
			Tree {
				deleteReward(input: $input) {
					contentNode {
						...RewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: RewardEditor_TreeNodeFragment$key;
	certificateTemplatesConnectionFragmentRef: RewardEditor_CertificateTemplatesConnectionV2Fragment$key;
}

export const RewardEditor = ({
	treeNodeFragmentRef,
	certificateTemplatesConnectionFragmentRef,
}: OwnProps) => {
	const node = useFragment<RewardEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const certificateTemplatesConnection =
		useFragment<RewardEditor_CertificateTemplatesConnectionV2Fragment$key>(
			CERTIFICATE_TEMPLATES_CONNECTION_FRAGMENT,
			certificateTemplatesConnectionFragmentRef,
		);

	const [removeReward, isRemovingReward] =
		useMutation<RewardEditor_DeleteRewardMutation>(REMOVE_REWARD_MUTATION);

	const [currentlyEditingReward, setCurrentlyEditingReward] = useState<
		RewardForm_RewardConfigurationV2Fragment$key | undefined
	>();

	const [showDialog, setShowDialog] = useState(false);
	const arrayOfEditedForm = useTypedSelector(selectArrayOfEditedForms);

	return (
		<Card className="mb-2">
			<h2>Belohnungen</h2>
			{currentlyEditingReward ? (
				<>
					{showDialog && (
						<WarningUnsavedChangesDialog
							setShowDialog={setShowDialog}
							callback={setCurrentlyEditingReward}
							value={undefined}
						/>
					)}
					<RewardForm
						treeNodeFragmentRef={node}
						onBack={() => {
							if (arrayOfEditedForm.length > 0) {
								setShowDialog(true);
							} else {
								setCurrentlyEditingReward(undefined);
							}
						}}
						rewardConfigurationFragmentRef={currentlyEditingReward}
					/>{" "}
				</>
			) : (
				<div>
					<div className="p-buttonset mb-2">
						{/* to enable this a special logic for handling two types of points (pac and bak) must be implemented */}
						{/*<CreateEmailRewardButton treeNodeFragmentRef={node} />*/}
						<CreateCertificatesRewardButton
							treeNodeFragmentRef={node}
							certificateTemplatesConnectionFragmentRef={
								certificateTemplatesConnection
							}
						/>
						<CreateFachberaterRewardButton
							treeNodeFragmentRef={node}
							certificateTemplatesConnectionFragmentRef={
								certificateTemplatesConnection
							}
						/>
						<CreateAwardPacPointsRewardButton treeNodeFragmentRef={node} />
						<CreateAwardPharmaPointsRewardButton treeNodeFragmentRef={node} />
					</div>

					<OrderListWithoutControls
						dragdrop={false}
						itemTemplate={(item) => {
							return (
								<div
									className="flex align-items-center"
									onDoubleClick={() => {
										setCurrentlyEditingReward(item);
									}}
								>
									{item.kind === "Email" && (
										<span>
											Versende E-Mail an{" "}
											{RECIPIENT_TYPE_TRANSLATIONS[item.recipientType]}:{" "}
											{item.subject}
										</span>
									)}
									{item.kind === "MakeFachberater" && (
										<span>Mache Benutzer zum Fachberater</span>
									)}
									{item.kind === "PacPoints" && (
										<span>
											Vergebe {item.amount} PAC-Punkt
											{item.amount > 1 ? "e" : ""}
										</span>
									)}
									{item.kind === "PharmaPoints" && (
										<span>
											Vergebe {item.amount} BAK-Punkt
											{item.amount > 1 ? "e" : ""}
										</span>
									)}
									{item.kind === "Points" && (
										<span>
											Vergebe {item.amount} Punkt{item.amount > 1 ? "e" : ""}{" "}
											in das {item.pointPool?.name || "[NICHT EXISTIERENDE]"}{" "}
											Punkte-Konto
										</span>
									)}
									{item.kind === "Certificate" && (
										<span>
											Vergebe ein{" "}
											{item.certificateTemplate?.name ||
												"[NICHT EXISTIERENDES]"}{" "}
											Zertifikat
										</span>
									)}
									<Button
										disabled={isRemovingReward}
										className="ml-auto"
										icon="pi pi-trash"
										onClick={() => {
											if (
												item.kind === "Certificate" ||
												item.kind === "PacPoints" ||
												item.kind === "PharmaPoints"
											) {
												if (
													node.typeDefinition.rewards
														?.map((r) => r.kind)
														.includes("Email")
												) {
													return toast.error(
														"kann nicht entfernt werden, Belohnung ist notwendig für die E-Mail-Belohnung",
													);
												}

												if (
													item.kind == "PharmaPoints" &&
													node.typeDefinition.rewards
														?.map((r) => r.kind)
														.includes("Certificate")
												) {
													return toast.error(
														"kann nicht entfernt werden, Belohnung ist notwendig für die Zertifikat Belohnung",
													);
												}
											}

											removeReward({
												variables: {
													input: {
														contentNodeId: node.id,
														rewardId: item.id,
													},
												},
											});
										}}
									/>
									<Button
										disabled={isRemovingReward}
										className="ml-2"
										icon="pi pi-pencil"
										onClick={() => {
											setCurrentlyEditingReward(item);
										}}
									/>
								</div>
							);
						}}
						value={[...(node.typeDefinition.rewards || [])]}
					/>
				</div>
			)}
		</Card>
	);
};

const OrderListWithoutControls = styled(OrderList)`
	.p-orderlist-controls {
		display: none;
	}
`;

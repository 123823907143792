import { Dropdown } from "primereact/dropdown";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery, useRelayEnvironment } from "react-relay";
import { useState } from "react";
import { PointPoolDropdown_Query } from "../../../../__generated__/PointPoolDropdown_Query.graphql";

const QUERY = graphql`
	query PointPoolDropdown_Query($filterByName: String, $first: Int!, $after: String) {
		Admin {
			Points {
				PointPools(first: $first, after: $after, filterByName: $filterByName) {
					edges {
						node {
							id
							name
						}
					}
				}
			}
		}
	}
`;

type PointsPool = { readonly id: string; readonly name: string };

interface OwnProps {
	selectedPointPoolId?: string;
	onSelect: (userId?: string) => void;
}

export const PointPoolDropdown = ({ selectedPointPoolId, onSelect }: OwnProps) => {
	const [pointPools, setPointPools] = useState<PointsPool[]>([]);
	const environment = useRelayEnvironment();

	return (
		<Dropdown
			className={"mr-2"}
			onFilter={(e) => {
				fetchQuery<PointPoolDropdown_Query>(environment, QUERY, {
					first: 10,
					filterByName: e.filter,
				})
					.toPromise()
					.then((result) => {
						setPointPools(result!.Admin.Points.PointPools.edges!.map((e) => e!.node!));
					});
			}}
			placeholder={"Tippen um nach Punkte-Pool zu suchen"}
			filter={true}
			showClear={true}
			value={selectedPointPoolId}
			options={pointPools.map((u) => ({
				value: u.id,
				label: u.name,
			}))}
			onChange={(e) => onSelect(e.value)}
		/>
	);
};

import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation, Environment, useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import {
	RenderConfig,
	ValidatedField,
} from "../../../../../../../../infecto-lms-admin/components/core/form/ValidatedField";
import { TreeConfigurationEditDialogProps } from "../../../../../../../../infecto-lms-admin/components/tree/editor/node/config/components/Config.interfaces";
import { CONFIGS_TRANSLATIONS } from "../../../../../../../../infecto-lms-admin/i18n/config/i18n";
import { OnlySpecificPermissionsVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation } from "../../../../../../../../__generated__/OnlySpecificPermissionsVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation.graphql";
import { OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment$key } from "../../../../../../../../__generated__/OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment.graphql";
import { OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment$key } from "../../../../../../../../__generated__/OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment.graphql";
import { OnlySpecificPermissionsVisibilityConfigForm_EditMutation } from "../../../../../../../../__generated__/OnlySpecificPermissionsVisibilityConfigForm_EditMutation.graphql";
import { ConfigDatePicker } from "../../../../../../../../infecto-lms-admin/components/tree/editor/node/config/components/ConfigDatePicker";
import { AddOnlySpecificPermissionsVisibilityTreeConfigInput } from "../../../../../../../../__generated__/OnlySpecificPermissionsVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation.graphql";

const CONFIGURATION_FRAGMENT = graphql`
	fragment OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment on VisibilityTreeConfig {
		... on OnlySpecificPermissionsVisibilityTreeConfig {
			id
			configType
			dateTime
		}
	}
`;

const TREE_NODE_FRAGMENT = graphql`
	fragment OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			... on RootStructureDefinition {
				visibilityConfigs {
					...OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment
				}
			}
		}
	}
`;

const ADD_INFECTOPHARM_ONLY_ADMINS_VISIBILITY_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation OnlySpecificPermissionsVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation(
		$input: AddOnlySpecificPermissionsVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				addOnlySpecificPermissionsVisibilityTreeConfig(input: $input) {
					rootNode {
						...OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const EDIT_CONFIGURATION_MUTATION = graphql`
	mutation OnlySpecificPermissionsVisibilityConfigForm_EditMutation(
		$input: EditOnlySpecificPermissionsVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				editOnlySpecificPermissionsVisibilityTreeConfig(input: $input) {
					rootNode {
						...OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment
					}
				}
			}
		}
	}
`;

function CommitAddInfectopharmOnlyAdminsVisibilityConfig(
	environment: Environment,
	input: AddOnlySpecificPermissionsVisibilityTreeConfigInput,
) {
	return commitMutation<OnlySpecificPermissionsVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation>(
		environment,
		{
			mutation: ADD_INFECTOPHARM_ONLY_ADMINS_VISIBILITY_CONDITION_CONFIGURATION_MUTATION,
			variables: { input },
			onCompleted: () => {},
			onError: () => {},
		},
	);
}

interface FormState {
	dateTime?: string;
}

interface Props extends TreeConfigurationEditDialogProps {
	treeNodeFragmentRef: OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment$key;
	configurationFragmentRef: OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment$key;
	onBack?: () => void;
}

export const OnlySpecificPermissionsVisibilityConfigForm = ({
	configurationFragmentRef,
	treeNodeFragmentRef,
	onBack,
}: Props) => {
	const treeNode = useFragment<OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const configuration =
		useFragment<OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment$key>(
			CONFIGURATION_FRAGMENT,
			configurationFragmentRef,
		);
	const [edit, isEditing] = useMutation<OnlySpecificPermissionsVisibilityConfigForm_EditMutation>(
		EDIT_CONFIGURATION_MUTATION,
	);

	const formik = useFormik<FormState>({
		initialValues: {
			dateTime: configuration.dateTime as string,
		},
		onSubmit: (values, formikHelpers) => {
			edit({
				variables: {
					input: {
						rootNodeId: treeNode.id,
						configId: configuration.id as string,
						newDateTime: values.dateTime as string,
					},
				},
				onCompleted: () => {
					formikHelpers.setTouched({});
					onBack!();
				},
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, string>
				name={"dateTime"}
				label={CONFIGS_TRANSLATIONS[configuration.configType!]}
				className="mb-4"
				formikConfig={formik}
				component={({
					fieldName,
					fieldValue,
					updateField,
					disabled,
				}: RenderConfig<string>) => {
					return (
						<ConfigDatePicker
							name={fieldName}
							value={fieldValue}
							onUpdate={updateField}
							disabled={disabled}
							withTimeInput={true}
						/>
					);
				}}
			/>

			<Button
				disabled={Object.entries(formik.touched).length === 0 || isEditing}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					onBack!();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};

export { CommitAddInfectopharmOnlyAdminsVisibilityConfig as AddInfectopharmOnlyAdminsVisibilityConfig };

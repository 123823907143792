import { useNavigate } from "react-router-dom";
import { readInlineData, usePaginationFragment } from "react-relay";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import graphql from "babel-plugin-relay/macro";
import { LandingPagesV2Table_QueryFragment$key } from "../../../../__generated__/LandingPagesV2Table_QueryFragment.graphql";
import { LandingPagesV2Table_Refetch } from "../../../../__generated__/LandingPagesV2Table_Refetch.graphql";
import { TitleColumn } from "./TitleColumn";
import { DescriptionColumn } from "./DescriptionColumn";
import { UrlPathColumn } from "./UrlPathColumn";
import { ContentsColumn } from "./ContentsColumn";
import { ActionColumn } from "./ActionColumn";
import { getEditLandingPagePathV2 } from "../../../../app/routes/LandingPageModuleRoutes";
import { Permission } from "../../../../__generated__/MainNavigation_Query.graphql";
import { useSelector } from "react-redux";
import { selectHasPermissions } from "../../../redux/slices/CurrentUserSlice";

const QUERY_FRAGMENT = graphql`
	fragment LandingPagesV2Table_QueryFragment on Query
	@refetchable(queryName: "LandingPagesV2Table_Refetch")
	@argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
		Admin {
			LandingPageV2 {
				LandingPages(first: $first, after: $after)
					@connection(key: "LandingPagesV2Table_LandingPages") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							...LandingPagesV2Table_LandingPageFragment
						}
					}
				}
			}
		}
	}
`;

const LANDING_PAGE_FRAGMENT = graphql`
	fragment LandingPagesV2Table_LandingPageFragment on LandingPageV2 @inline {
		...ActionColumn_LandingPageFragment
		...ContentsColumn_LandingPageFragment
		...DescriptionColumn_LandingPageFragment
		...TitleColumn_LandingPageFragment
		...UrlPathColumn_LandingPageFragment
	}
`;

interface OwnProps {
	queryFragmentRef: LandingPagesV2Table_QueryFragment$key;
}
export const LandingPagesV2Table = ({ queryFragmentRef }: OwnProps) => {
	const navigate = useNavigate();

	const { data, hasPrevious, hasNext, loadPrevious, loadNext } = usePaginationFragment<
		LandingPagesV2Table_Refetch,
		LandingPagesV2Table_QueryFragment$key
	>(QUERY_FRAGMENT, queryFragmentRef);

	const landingPagesConnection = data.Admin.LandingPageV2.LandingPages || [];
	const landingPagesConnectionId = data.Admin.LandingPageV2.LandingPages.__id;

	const checkPermissions = (permissions: Permission[]) =>
		useSelector((state: any) => selectHasPermissions(state, permissions));

	const hasPermissions =
		checkPermissions([
			"AccountPermission_System_Root",
			"UserInAccountPermission_System_Owner",
		]) ||
		checkPermissions([
			"AccountPermission_System_Root",
			"UserInAccountPermission_LandingPages_ReadLandingPages",
		]);

	return (
		<>
			{hasPermissions && (
				<div className="flex justify-content-end mb-3">
					<Button onClick={() => navigate(getEditLandingPagePathV2("new"))}>
						Neue Seite
					</Button>
				</div>
			)}

			<DataTable
				emptyMessage="Keine Seiten"
				className="mb-3"
				value={
					landingPagesConnection.edges
						?.filter((e) => !!e?.node)
						.map((e) => e!.node)
						.map((item: any) => readInlineData(LANDING_PAGE_FRAGMENT, item)) || []
				}
			>
				<Column
					header="Titel"
					body={(item) => <TitleColumn landingPageFragmentRef={item} />}
				/>
				<Column
					style={{ width: "20%" }}
					header="Beschreibung"
					body={(item) => <DescriptionColumn landingPageFragmentRef={item} />}
				/>
				<Column
					header="Url path"
					body={(item) => <UrlPathColumn landingPageFragmentRef={item} />}
				/>
				<Column
					style={{ width: "30%" }}
					header="Module"
					body={(item) => <ContentsColumn landingPageFragmentRef={item} />}
				/>

				{hasPermissions && (
					<Column
						header="Aktionen"
						style={{ width: "20%" }}
						body={(item) => (
							<ActionColumn
								landingPageFragmentRef={item}
								landingPagesConnectionId={landingPagesConnectionId}
							/>
						)}
					/>
				)}
			</DataTable>

			<div className="flex justify-content-center align-items-center">
				<Button
					disabled={!hasPrevious}
					onClick={() => loadPrevious(20)}
					className="mr-3 p-button-secondary"
				>
					Zurück
				</Button>
				<Button
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={() => loadNext(20)}
				>
					Weiter
				</Button>
			</div>
		</>
	);
};

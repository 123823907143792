import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { UserDescription_UserFragment$key } from "../../__generated__/UserDescription_UserFragment.graphql";

const USER_FRAGMENT = graphql`
	fragment UserDescription_UserFragment on User {
		extension {
			... on InfectopharmUserExtensionImpl {
				firstName
				lastName
				title
				salutation
			}
		}
	}
`;

interface OwnProps {
	userFragmentRef?: UserDescription_UserFragment$key | null;
}

export const UserDescription = ({ userFragmentRef }: OwnProps) => {
	const user = useFragment<UserDescription_UserFragment$key>(
		USER_FRAGMENT,
		userFragmentRef || null,
	);

	const salutation = (() => {
		switch (user?.extension.salutation || "") {
			case "Mr":
				return "Herr";
			case "Mrs":
				return "Frau";
			default:
				return "";
		}
	})();

	const title = user?.extension.title || "";
	const firstName = user?.extension.firstName || "";
	const lastName = user?.extension.lastName || "";

	return <>{[salutation, title, firstName, lastName].filter((c) => c.length > 0).join(" ")}</>;
};

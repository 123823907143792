import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { MaintenanceScreen_Query } from "../../__generated__/MaintenanceScreen_Query.graphql";
import { MaintenanceToggleButton } from "../components/maintenance/MaintenanceToggleButton";

const QUERY = graphql`
	query MaintenanceScreen_Query {
		Maintenance {
			MaintenanceUpdate {
				id
				...MaintenanceToggleButton_Fragment
			}
		}
	}
`;

export const MaintenanceScreen = () => {
	const data = useLazyLoadQuery<MaintenanceScreen_Query>(
		QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);
	return (
		<div className="flex justify-content-center">
			<MaintenanceToggleButton userFragmentRef={data.Maintenance.MaintenanceUpdate} />
		</div>
	);
};

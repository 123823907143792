/**
 * @generated SignedSource<<fd23dd0dc7da459c5f7a73a0c96328b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditOnlyFachrichtungenVisibilityTreeConfigInput = {
  newFachrichtungen: ReadonlyArray<string>;
  rootNodeId: string;
  configId: string;
  clientMutationId?: string | null;
};
export type OnlySpecializationVisibilityConfigForm_EditMutation$variables = {
  input: EditOnlyFachrichtungenVisibilityTreeConfigInput;
};
export type OnlySpecializationVisibilityConfigForm_EditMutationVariables = OnlySpecializationVisibilityConfigForm_EditMutation$variables;
export type OnlySpecializationVisibilityConfigForm_EditMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly editOnlyFachrichtungenVisibilityTreeConfig: {
        readonly rootNode: {
          readonly " $fragmentSpreads": FragmentRefs<"OnlySpecializationVisibilityConfigForm_TreeNodeFragment">;
        };
      } | null;
    };
  };
};
export type OnlySpecializationVisibilityConfigForm_EditMutationResponse = OnlySpecializationVisibilityConfigForm_EditMutation$data;
export type OnlySpecializationVisibilityConfigForm_EditMutation = {
  variables: OnlySpecializationVisibilityConfigForm_EditMutationVariables;
  response: OnlySpecializationVisibilityConfigForm_EditMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OnlySpecializationVisibilityConfigForm_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditOnlyFachrichtungenVisibilityTreeConfigPayload",
                "kind": "LinkedField",
                "name": "editOnlyFachrichtungenVisibilityTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "OnlySpecializationVisibilityConfigForm_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OnlySpecializationVisibilityConfigForm_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditOnlyFachrichtungenVisibilityTreeConfigPayload",
                "kind": "LinkedField",
                "name": "editOnlyFachrichtungenVisibilityTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "visibilityConfigs",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "TypeDiscriminator",
                                    "abstractKey": "__isVisibilityTreeConfig"
                                  },
                                  (v2/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "configType",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "fachrichtungen",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "OnlyFachrichtungenVisibilityTreeConfig",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4d82cefa92da598b3ea685dc4bba23c0",
    "id": null,
    "metadata": {},
    "name": "OnlySpecializationVisibilityConfigForm_EditMutation",
    "operationKind": "mutation",
    "text": "mutation OnlySpecializationVisibilityConfigForm_EditMutation(\n  $input: EditOnlyFachrichtungenVisibilityTreeConfigInput!\n) {\n  Admin {\n    Tree {\n      editOnlyFachrichtungenVisibilityTreeConfig(input: $input) {\n        rootNode {\n          ...OnlySpecializationVisibilityConfigForm_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment OnlySpecializationVisibilityConfigForm_TreeNodeFragment on TreeNode {\n  id\n  structureDefinition {\n    __typename\n    ... on RootStructureDefinition {\n      visibilityConfigs {\n        __typename\n        ...OnlySpecializationVisibilityConfigForm_VisibilityTreeConfigFragment\n        id\n      }\n    }\n  }\n}\n\nfragment OnlySpecializationVisibilityConfigForm_VisibilityTreeConfigFragment on VisibilityTreeConfig {\n  __isVisibilityTreeConfig: __typename\n  ... on OnlyFachrichtungenVisibilityTreeConfig {\n    id\n    configType\n    fachrichtungen\n  }\n}\n"
  }
};
})();

(node as any).hash = "fe3ddd3d2dc1887a26acf99e342a2c4d";

export default node;

import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery, useRelayEnvironment } from "react-relay";
import React, { useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import styled from "styled-components";
import { Tag } from "primereact/tag";
import { RenderConfig } from "../core/form/ValidatedField";
import { ContentsMultiselectV2_Query } from "../../../__generated__/ContentsMultiselectV2_Query.graphql";

const QUERY = graphql`
	query ContentsMultiselectV2_Query($first: Int, $after: String, $query: String) {
		LearnV2 {
			SearchLearnOpportunities(first: $first, after: $after, query: $query) {
				pageInfo {
					endCursor
					hasPreviousPage
					hasNextPage
					startCursor
				}
				edges {
					node {
						id
						structureDefinition {
							title
						}
					}
				}
			}
		}
	}
`;

interface TagProps {
	id: string;
	structureDefinition: {
		title: string;
	};
}

export const ContentsMultiselectV2 = ({
	fieldValue,
	updateField,
	onChange,
}: RenderConfig<string[]>) => {
	const environment = useRelayEnvironment();

	const [contents, setContents] = useState<TagProps[]>([]);

	useEffect(() => {
		fetchQuery<ContentsMultiselectV2_Query>(environment, QUERY, {})
			.toPromise()
			.then((result) => {
				setContents(() =>
					result!.LearnV2.SearchLearnOpportunities.edges!.map((e) => e!.node!),
				);
			});
		// eslint-disable-next-line
	}, []);

	const contentTemplate = (option: any) => {
		const content = contents?.find((content) => content.id === option.value);
		return (
			<StyledTag
				className="mr-2 pl-3 pr-3 text-xxs mb-2"
				value={content?.structureDefinition.title}
				rounded
			/>
		);
	};

	return (
		<MultiSelect
			className="m-1"
			value={fieldValue}
			options={contents.map((p) => ({ label: p.structureDefinition.title, value: p.id }))}
			onChange={(e) => {
				updateField(e.value);
				if (onChange) onChange();
			}}
			filter
			showClear
			showSelectAll={false}
			placeholder="Module auswählen"
			itemTemplate={contentTemplate}
			onFilter={(e) => {
				fetchQuery<ContentsMultiselectV2_Query>(environment, QUERY, {
					query: e.filter?.length > 0 ? e.filter : undefined,
				});
			}}
		/>
	);
};

const StyledTag = styled(Tag)`
	.p-tag-value {
		font-size: 0.8rem;
	}
`;

/**
 * @generated SignedSource<<adbf155acaf22683593f12ea0bd6d80e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserColumn_TreeStateFragment$data = {
  readonly user: {
    readonly " $fragmentSpreads": FragmentRefs<"ProjectUserDescription_UserFragment">;
  } | null;
  readonly " $fragmentType": "UserColumn_TreeStateFragment";
};
export type UserColumn_TreeStateFragment = UserColumn_TreeStateFragment$data;
export type UserColumn_TreeStateFragment$key = {
  readonly " $data"?: UserColumn_TreeStateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserColumn_TreeStateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserColumn_TreeStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectUserDescription_UserFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeState",
  "abstractKey": null
};

(node as any).hash = "e3ab9b4d086f96b390aeb2cbc44dd9b8";

export default node;

import { graphql } from "babel-plugin-relay/macro";
import { Card } from "primereact/card";
import React, { useState } from "react";
import {
	StartContentConfigEditor_TreeNodeFragment$key,
	StartContentConfigType,
} from "../../../../../../../__generated__/StartContentConfigEditor_TreeNodeFragment.graphql";
import { ConfigDropdown, ConfigDropdownOptions } from "../components/ConfigDropdown";
import { ConfigPreview } from "../components/ConfigPreview";
import { ConfigItem } from "../components/ConfigItem";
import {
	ConfigurationGraphqlInterface,
	Config,
	ConfigCustomLabel,
} from "../components/Config.interfaces";
import { useFragment, useMutation } from "react-relay";
import { StartContentConfigEditor_RemoveStartTreeConfigMutation } from "../../../../../../../__generated__/StartContentConfigEditor_RemoveStartTreeConfigMutation.graphql";
import { utcFormatDateTime } from "../../../../../core/datetime/DateTimeDisplay";
import {
	ProjectExtendStartContentConfigEditDialogs,
	ExtendStartContentConfigs,
} from "../../../../../../../lms-admin-impl/components/relay/ProjectExtendStartContentConfigEditDialogs";

const TREE_NODE_FRAGMENT = graphql`
	fragment StartContentConfigEditor_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ELearningContentTypeDefinition {
				startConfigs {
					id
					configType
					...ProjectExtendStartContentConfigEditDialogs_StartContentConfigFragment
				}
			}
		}
		...ProjectExtendStartContentConfigEditDialogs_TreeNodeFragment
	}
`;

const REMOVE_START_CONTENT_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation StartContentConfigEditor_RemoveStartTreeConfigMutation(
		$input: RemoveStartContentConfigInput!
	) {
		Admin {
			Tree {
				removeStartContentConfig(input: $input) {
					contentNode {
						...StartContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface Props {
	treeNodeFragmentRef: StartContentConfigEditor_TreeNodeFragment$key;
}

export const StartContentConfigEditor = ({ treeNodeFragmentRef }: Props) => {
	const treeNode = useFragment<StartContentConfigEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const [removeStartContentConfig, isRemovingStartContentConfig] =
		useMutation<StartContentConfigEditor_RemoveStartTreeConfigMutation>(
			REMOVE_START_CONTENT_CONDITION_CONFIGURATION_MUTATION,
		);

	const [previewOptions, setPreviewOptions] = useState<
		ConfigDropdownOptions<StartContentConfigType>[]
	>([]);

	let labels: ConfigCustomLabel<StartContentConfigType>[];

	const configs: Config<StartContentConfigType, any | never>[] = [...ExtendStartContentConfigs];

	const options: ConfigDropdownOptions<StartContentConfigType>[] = configs.map((c) => {
		return {
			label: c.configKey,
			value: c.configKey,
		};
	});

	const setLabels = (customLabels: ConfigCustomLabel<StartContentConfigType>[]) => {
		labels = customLabels;

		setPreviewOptions(
			configs.map((c) => {
				const selectedConfig = treeNode.typeDefinition.startConfigs?.find(
					(r) => r.configType === c.configKey,
				);
				const customLabel = labels.find((l) => l.configKey === c.configKey);

				if (selectedConfig && customLabel?.label) {
					return {
						label: `(${utcFormatDateTime(customLabel.label, false)})`,
						value: c.configKey,
					};
				} else {
					return {
						label: c.configKey,
						value: c.configKey,
					};
				}
			}),
		);
	};

	return (
		<Card className="mb-2">
			<h2>Starten</h2>

			<ConfigDropdown<StartContentConfigType>
				configOptions={options}
				onChange={(e) => {
					const selectedConfig = configs.find((c) => c.configKey === e.value);

					if (selectedConfig) {
						selectedConfig.addMutation({
							contentNodeId: treeNode.id,
							...selectedConfig.addMutationPayload,
						});
					}
				}}
				isPresentational={false}
			/>

			<ConfigPreview<ConfigurationGraphqlInterface<StartContentConfigType>>
				selectedConfigs={treeNode.typeDefinition.startConfigs as any[]}
				template={(
					configuration: ConfigurationGraphqlInterface<StartContentConfigType>,
				) => (
					<>
						<ProjectExtendStartContentConfigEditDialogs
							treeNodeFragmentRef={treeNode}
							// @ts-ignore
							configurationFragmentRef={configuration}
							setCustomLabels={(customLabels: any) => setLabels(customLabels)}
						/>

						<ConfigItem<StartContentConfigType, string>
							isPresentational={true}
							isLoading={isRemovingStartContentConfig}
							configType={configuration.configType}
							canEdit={
								configs.find((c) => c.configKey === configuration.configType)
									?.editable as boolean
							}
							configOptions={previewOptions}
							onDelete={() => {
								removeStartContentConfig({
									variables: {
										input: {
											contentNodeId: treeNode.id,
											configId: configuration.id,
										},
									},
								});
							}}
							editDialog={(props: any) => (
								<>
									<ProjectExtendStartContentConfigEditDialogs
										{...props}
										treeNodeFragmentRef={treeNode}
										// @ts-ignore
										configurationFragmentRef={configuration}
									/>
								</>
							)}
						/>
					</>
				)}
			/>
		</Card>
	);
};

import { useMatch, useNavigate } from "react-router-dom";
import { ConnectionHandler, useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { EditTagScreen_Query } from "../../__generated__/EditTagScreen_Query.graphql";
import { EditTagScreen_TagFragment$key } from "../../__generated__/EditTagScreen_TagFragment.graphql";
import { EditTagScreen_CreateTagMutation } from "../../__generated__/EditTagScreen_CreateTagMutation.graphql";
import { BaseScreen } from "./BaseScreen";
import { EditTagForm } from "../components/tags/EditTagForm";
import { EditTagScreen_UpdateTagMutation } from "../../__generated__/EditTagScreen_UpdateTagMutation.graphql";
import { EDIT_TAG_PATH } from "../../app/routes/TagModuleRoutes";
import { Permission } from "../../__generated__/MainNavigation_Query.graphql";
import { useSelector } from "react-redux";
import { selectHasPermissions } from "../redux/slices/CurrentUserSlice";

const QUERY = graphql`
	query EditTagScreen_Query($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on Tag {
				...EditTagScreen_TagFragment
			}
		}
	}
`;

const TAG_FRAGMENT = graphql`
	fragment EditTagScreen_TagFragment on Tag {
		id
		name
		colorHex
	}
`;

const CREATE_TAG_MUTATION = graphql`
	mutation EditTagScreen_CreateTagMutation($input: CreateTagInput!, $connections: [ID!]!) {
		Admin {
			Tag {
				createTag(input: $input) {
					data @appendEdge(connections: $connections) {
						node {
							...EditTagScreen_TagFragment
						}
					}
				}
			}
		}
	}
`;

const UPDATE_TAG_MUTATION = graphql`
	mutation EditTagScreen_UpdateTagMutation($input: UpdateTagInput!) {
		Admin {
			Tag {
				updateTag(input: $input) {
					data {
						node {
							...EditTagScreen_TagFragment
						}
					}
				}
			}
		}
	}
`;

export const EditTagScreen = () => {
	const navigate = useNavigate();
	const {
		params: { tagId },
	} = useMatch(EDIT_TAG_PATH)!;

	const query = useLazyLoadQuery<EditTagScreen_Query>(QUERY, {
		id: tagId!,
		skip: !tagId || tagId === "new",
	});

	const tag = useFragment<EditTagScreen_TagFragment$key>(TAG_FRAGMENT, query.node || null);

	const [createTag, isCreatingTag] =
		useMutation<EditTagScreen_CreateTagMutation>(CREATE_TAG_MUTATION);

	const [updateTag, isUpdatingTag] =
		useMutation<EditTagScreen_UpdateTagMutation>(UPDATE_TAG_MUTATION);

	const checkPermission = (permissions: Permission[]) =>
		useSelector((state: any) => selectHasPermissions(state, permissions));

	const isAdmin = checkPermission([
		"AccountPermission_System_Root",
		"UserInAccountPermission_System_Owner",
	]);

	if (!isAdmin) return <div>Kein Zugriff</div>;

	return (
		<BaseScreen>
			<h1 className="mb-3">Bearbeite Schlagwort</h1>

			<EditTagForm
				initialValues={{
					name: tag?.name,
					colorHex: tag?.colorHex,
				}}
				loading={isCreatingTag || isUpdatingTag}
				onSubmit={(values) => {
					const data = {
						name: values.name!,
						colorHex: values.colorHex!,
					};
					if (tag) {
						updateTag({
							variables: {
								input: {
									id: tag.id,
									data: data,
								},
							},
							onCompleted: () => navigate("/tags"),
						});
					} else {
						createTag({
							variables: {
								input: {
									data: data,
								},
								connections: [
									ConnectionHandler.getConnectionID(
										"client:root:Admin:Tag",
										"TagsTable_Tags",
									),
								],
							},
							onCompleted: () => navigate("/tags"),
						});
					}
				}}
			/>
		</BaseScreen>
	);
};

import { JwtTokenData, logout, refreshLogin } from "./AuthSlice";
import { ReduxStore } from "../Store";

/**
 * returns a new accessToken
 * @param refreshToken
 * @param accountId
 * @constructor
 */
export const RefreshToken = async (refreshToken: string, accountId: string): Promise<string> => {
	try {
		const res = await fetch(`${process.env.REACT_APP_API_BASE}/api/refresh-token`, {
			method: "POST",
			headers: new Headers({ "content-type": "application/json" }),
			body: JSON.stringify({
				refreshToken: refreshToken,
				accountId: accountId,
			}),
		});

		const json: JwtTokenData = await res.json();
		const token = json.accessToken;

		ReduxStore.dispatch(refreshLogin({ loginData: json }));

		return token;
	} catch (e) {
		ReduxStore.dispatch(logout());
		return "";
	}
};

/**
 * @generated SignedSource<<46a1d248aa90caa986c0b7ab6258abd2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TypeDefinitionType = "content" | "branch" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentCard_TreeNodeFragment$data = {
  readonly typeDefinition: {
    readonly definitionType: TypeDefinitionType;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ELearningContentEditor_TreeNodeFragment">;
  readonly " $fragmentType": "ContentCard_TreeNodeFragment";
};
export type ContentCard_TreeNodeFragment = ContentCard_TreeNodeFragment$data;
export type ContentCard_TreeNodeFragment$key = {
  readonly " $data"?: ContentCard_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentCard_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentCard_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "definitionType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ELearningContentEditor_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "b651936fe935af2c192acf73e1b4b32e";

export default node;

import { PrivateRouteDefinition } from "../../infecto-lms-admin/components/core/model/PrivateRouteDefinition";
import { PublishedNodesAndUsersScreen } from "../../infecto-lms-admin/screens/PublishedNodesAndUsersScreen";

import { UsersWhoMadeContentNodeScreen } from "../../infecto-lms-admin/screens/UsersWhoMadeContentNodeScreen";

export const MODULES_PATH = "/modules";

export const MODULES_ID_URL_PARAM = ":moduleId";
export const getSingleModulePath = (moduleId: string) => {
	return `/modules/${moduleId}`;
};

export const PublishedNodesAndUsersModuleRoutes: PrivateRouteDefinition[] = [
	{
		path: MODULES_PATH,
		element: <PublishedNodesAndUsersScreen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_Nodes_ReadNodes",
		],
	},
	{
		path: getSingleModulePath(MODULES_ID_URL_PARAM),
		element: <UsersWhoMadeContentNodeScreen />,
		requiredPermissions: ["AccountPermission_System_Root"],
	},
];

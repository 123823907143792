/**
 * @generated SignedSource<<ad603304adbfb7674e47245fd0594330>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewsTickerDefinitionOverviewScreen_Query$variables = {
  numItemsPerPage: number;
};
export type NewsTickerDefinitionOverviewScreen_QueryVariables = NewsTickerDefinitionOverviewScreen_Query$variables;
export type NewsTickerDefinitionOverviewScreen_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NewsTickerDefinitionOverviewScreen_PaginationFragment">;
};
export type NewsTickerDefinitionOverviewScreen_QueryResponse = NewsTickerDefinitionOverviewScreen_Query$data;
export type NewsTickerDefinitionOverviewScreen_Query = {
  variables: NewsTickerDefinitionOverviewScreen_QueryVariables;
  response: NewsTickerDefinitionOverviewScreen_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "numItemsPerPage"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "numItemsPerPage"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewsTickerDefinitionOverviewScreen_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "NewsTickerDefinitionOverviewScreen_PaginationFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewsTickerDefinitionOverviewScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NewstickerAdminSchema",
            "kind": "LinkedField",
            "name": "Newsticker",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "NewstickerMessagesConnection",
                "kind": "LinkedField",
                "name": "NewstickerMessages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NewstickerMessagesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NewstickerMessage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "text",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "forwardingUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isActive",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "NewsTickerDefinitionOverviewScreen_NewstickerMessages",
                "kind": "LinkedHandle",
                "name": "NewstickerMessages"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "81593d70c4e863870a2ed4aec3e5c696",
    "id": null,
    "metadata": {},
    "name": "NewsTickerDefinitionOverviewScreen_Query",
    "operationKind": "query",
    "text": "query NewsTickerDefinitionOverviewScreen_Query(\n  $numItemsPerPage: Int!\n) {\n  ...NewsTickerDefinitionOverviewScreen_PaginationFragment_2a4u4H\n}\n\nfragment NewsTickerDefinitionOverviewScreen_PaginationFragment_2a4u4H on Query {\n  Admin {\n    Newsticker {\n      NewstickerMessages(first: $numItemsPerPage) {\n        edges {\n          node {\n            id\n            text\n            forwardingUrl\n            isActive\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f433f3770d8657a18cb52db7acdecede";

export default node;

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import React from "react";
import { EmailTesterDialog } from "../../../infecto-lms-admin/components/email-tester/EmailTesterDialog";
import { EmailTesterRemindUserToFinishBasisseminarNodeMutation } from "../../../__generated__/EmailTesterRemindUserToFinishBasisseminarNodeMutation.graphql";

const SEND_EMAIL_MUTATION = graphql`
	mutation EmailTesterRemindUserToFinishBasisseminarNodeMutation(
		$input: SendEmailTestRemindUserBasisseminarInput!
	) {
		Admin {
			TesterEmail {
				EmailTestRemindUserBasisseminar(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
interface OwnProps {
	modalVisible: boolean;
	setModalVisible: (boolean: boolean) => void;
}
export const EmailTesterRemindUserToFinishBasisseminarNode = ({
	modalVisible,
	setModalVisible,
}: OwnProps) => {
	const [sendEmail, inFlight] =
		useMutation<EmailTesterRemindUserToFinishBasisseminarNodeMutation>(SEND_EMAIL_MUTATION);

	return (
		<div>
			<EmailTesterDialog
				headerText={"Reminder Online-Abschlussprüfung Fachberater*in Pädiatrie Tester"}
				setModalVisible={() => setModalVisible(false)}
				modalVisible={modalVisible}
				description={
					"Um die E-Mails effektiv zu testen, bereiten Sie bitte Ihre Nutzer darauf vor, dass sie als Teilnehmer am Basisseminar agieren. Nur ein Basisseminar Modul muss aktive sein. Diese E-Mails werden an den Benutzer verschickt, die das LEK-Basisseminar eine Woche vor dem Ende des LEK noch nicht erfolgreich abgeschlossen hat, aber noch mindestens einen Versuch zur Verfügung hat."
				}
				disabled={inFlight}
				onClick={() =>
					sendEmail({
						variables: {
							input: {},
						},
						onCompleted: () => {
							toast.success("Email gesendet");
						},
						onError: (err) => {
							toast.error(err.message);
						},
					})
				}
			></EmailTesterDialog>
		</div>
	);
};

import { PrivateRouteDefinition } from "../../infecto-lms-admin/components/core/model/PrivateRouteDefinition";
import { NotificationTemplatesScreen } from "../../infecto-lms-admin/screens/NotificationTemplatesScreen";
import {
	EDIT_TEMPLATE_PATH,
	EditNotificationTemplateScreen,
} from "../../infecto-lms-admin/screens/EditNotificationTemplateScreen";

export const NotificationModuleRoutes: PrivateRouteDefinition[] = [
	{
		path: "/notification-templates",
		element: <NotificationTemplatesScreen />,
		requiredPermissions: ["AccountPermission_System_Root"],
	},
	{
		path: EDIT_TEMPLATE_PATH,
		element: <EditNotificationTemplateScreen />,
		requiredPermissions: ["AccountPermission_System_Root"],
	},
];

/**
 * @generated SignedSource<<f1ba4a9fdada70016a72af5ce50d2b07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditAwardPharmaPointsConfigurationForm_TreeNodeFragment$data = {
  readonly id: string;
  readonly typeDefinition: {
    readonly rewards?: ReadonlyArray<{
      readonly id: string;
      readonly pointPool?: {
        readonly name: string;
      } | null;
    }>;
  };
  readonly " $fragmentType": "EditAwardPharmaPointsConfigurationForm_TreeNodeFragment";
};
export type EditAwardPharmaPointsConfigurationForm_TreeNodeFragment = EditAwardPharmaPointsConfigurationForm_TreeNodeFragment$data;
export type EditAwardPharmaPointsConfigurationForm_TreeNodeFragment$key = {
  readonly " $data"?: EditAwardPharmaPointsConfigurationForm_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditAwardPharmaPointsConfigurationForm_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditAwardPharmaPointsConfigurationForm_TreeNodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "rewards",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PointPool",
                      "kind": "LinkedField",
                      "name": "pointPool",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "PointsRewardConfiguration",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ContentTypeDefinition",
          "abstractKey": "__isContentTypeDefinition"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "a594dc7fb97e0996c10adc607a155a32";

export default node;

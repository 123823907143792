/**
 * @generated SignedSource<<c3c050800d5d82f180686830cfdde330>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditFileElementV2Input = {
  title: string;
  fileElementId: string;
  fileId?: string | null;
  clientMutationId?: string | null;
};
export type FileElementForm_EditFileElemenV2tMutation$variables = {
  input: EditFileElementV2Input;
};
export type FileElementForm_EditFileElemenV2tMutationVariables = FileElementForm_EditFileElemenV2tMutation$variables;
export type FileElementForm_EditFileElemenV2tMutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly editFileElement: {
        readonly fileElement: {
          readonly " $fragmentSpreads": FragmentRefs<"FileElementForm_FileElementV2Fragment">;
        };
      } | null;
    };
  };
};
export type FileElementForm_EditFileElemenV2tMutationResponse = FileElementForm_EditFileElemenV2tMutation$data;
export type FileElementForm_EditFileElemenV2tMutation = {
  variables: FileElementForm_EditFileElemenV2tMutationVariables;
  response: FileElementForm_EditFileElemenV2tMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FileElementForm_EditFileElemenV2tMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditFileElementV2Payload",
                "kind": "LinkedField",
                "name": "editFileElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FileElementV2",
                    "kind": "LinkedField",
                    "name": "fileElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "FileElementForm_FileElementV2Fragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FileElementForm_EditFileElemenV2tMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditFileElementV2Payload",
                "kind": "LinkedField",
                "name": "editFileElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FileElementV2",
                    "kind": "LinkedField",
                    "name": "fileElement",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "file",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c465c9b430501714658d4711e1966eb9",
    "id": null,
    "metadata": {},
    "name": "FileElementForm_EditFileElemenV2tMutation",
    "operationKind": "mutation",
    "text": "mutation FileElementForm_EditFileElemenV2tMutation(\n  $input: EditFileElementV2Input!\n) {\n  Admin {\n    ElearningV2 {\n      editFileElement(input: $input) {\n        fileElement {\n          ...FileElementForm_FileElementV2Fragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment FileElementForm_FileElementV2Fragment on FileElementV2 {\n  id\n  title\n  file {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fc4e31dc692b91c00a85cafb02c76f0e";

export default node;

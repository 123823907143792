import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { VimeoPodcastSourceField, VimeoPodcastSource } from "./VimeoPodcastSourceField";
import { ValidatedField } from "../../../../../../core/form/ValidatedField";
import { DefaultTextEditorComponent } from "../../../../../../core/form/DefaultTextEditorComponent";
import { VimeoPodcastElementForm_PodcastElementV2Fragment$key } from "../../../../../../../../__generated__/VimeoPodcastElementForm_PodcastElementV2Fragment.graphql";
import { VimeoPodcastElementForm_EditVimeoPodcastElementV2Mutation } from "../../../../../../../../__generated__/VimeoPodcastElementForm_EditVimeoPodcastElementV2Mutation.graphql";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../../../../../redux/slices/CoreSlice";
import { useTypedDispatch } from "../../../../../../../redux/Store";

const PODCAST_ELEMENT_FRAGMENT = graphql`
	fragment VimeoPodcastElementForm_PodcastElementV2Fragment on PodcastElementV2 {
		id
		title
		podcastSource {
			sourceType
			... on VimeoPodcastSourceV2 {
				sourceType
				vimeoId
			}
			... on PodigeePodcastSourceV2 {
				lengthInSeconds
				podigeeUrl
			}
			... on NoPodcastSourceV2 {
				sourceType
			}
		}
	}
`;

const EDIT_VIMEO_PODCAST_ELEMENT_MUTATION = graphql`
	mutation VimeoPodcastElementForm_EditVimeoPodcastElementV2Mutation(
		$input: EditVimeoPodcastElementV2Input!
	) {
		Admin {
			ElearningV2 {
				editVimeoPodcastElement(input: $input) {
					vimeoPodcastElement {
						...VimeoPodcastElementForm_PodcastElementV2Fragment
					}
				}
			}
		}
	}
`;

interface FormState {
	title: string;
	videoSource?: VimeoPodcastSource;
}

interface OwnProps {
	podcastElementFragmentRef: VimeoPodcastElementForm_PodcastElementV2Fragment$key;
	onBack: () => void;
	formId?: string;
}

export const VimeoPodcastElementForm = ({
	podcastElementFragmentRef,
	onBack,
	formId,
}: OwnProps) => {
	const podcastElement = useFragment<VimeoPodcastElementForm_PodcastElementV2Fragment$key>(
		PODCAST_ELEMENT_FRAGMENT,
		podcastElementFragmentRef,
	);
	const [editVimeoPodcastElement, isEditingVimeoPodcastElement] =
		useMutation<VimeoPodcastElementForm_EditVimeoPodcastElementV2Mutation>(
			EDIT_VIMEO_PODCAST_ELEMENT_MUTATION,
		);
	const dispatch = useTypedDispatch();
	const formik = useFormik<FormState>({
		initialValues: {
			title: podcastElement.title,
			videoSource: podcastElement.podcastSource as VimeoPodcastSource | undefined,
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Das Feld Titel wird benötigt."),
			videoSource: Yup.object().required("Das Feld Podcast wird benötigt."),
		}),
		onSubmit: (values) => {
			editVimeoPodcastElement({
				variables: {
					input: {
						vimeoPodcastElementId: podcastElement.id,
						title: values.title,
						vimeoId: (values.videoSource as VimeoPodcastSource)?.vimeoId,
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, string>
				name={"title"}
				label={"Titel"}
				component={DefaultTextEditorComponent}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
				formikConfig={formik}
			/>
			<ValidatedField<FormState, VimeoPodcastSource>
				name={"videoSource"}
				label={"Podcast"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
				component={({ fieldValue, updateField, onChange }) => {
					return (
						<VimeoPodcastSourceField
							onUpdate={updateField}
							videoSource={fieldValue}
							onChange={onChange}
						/>
					);
				}}
				formikConfig={formik}
			/>

			<Button
				disabled={isEditingVimeoPodcastElement}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};

/**
 * @generated SignedSource<<ae3cd9cf54c9021037f024e3b16d6811>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SingleFeedbackScreenV2_FeedbackFragment$data = {
  readonly id: string;
  readonly createdAt: string;
  readonly screen: string | null;
  readonly body: string;
  readonly learnOpportunity: {
    readonly structureDefinition: {
      readonly title: string;
    };
  } | null;
  readonly user: {
    readonly email: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectUserDescription_UserFragment">;
  } | null;
  readonly " $fragmentType": "SingleFeedbackScreenV2_FeedbackFragment";
};
export type SingleFeedbackScreenV2_FeedbackFragment = SingleFeedbackScreenV2_FeedbackFragment$data;
export type SingleFeedbackScreenV2_FeedbackFragment$key = {
  readonly " $data"?: SingleFeedbackScreenV2_FeedbackFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SingleFeedbackScreenV2_FeedbackFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SingleFeedbackScreenV2_FeedbackFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "screen",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "learnOpportunity",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "structureDefinition",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectUserDescription_UserFragment"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FeedbackV2",
  "abstractKey": null
};

(node as any).hash = "0eeecb4999c812c100ff532e30eb69fe";

export default node;

import { Button } from "primereact/button";
import { AnswerOptionV2Input } from "../../../../../../../../__generated__/MultipleChoiceElementForm_EditMultipleChoiceElementV2Mutation.graphql";
import { htmlTagsInTextRemover } from "../../../../../../../functions/htmlTagsInTextRemover";

export const CorrectSelectionText = "korrekte Auswahl";

interface OwnProps {
	answerOption: AnswerOptionV2Input;
	index: number;
	onDelete: () => void;
	onEdit: () => void;
}

export const AnswerOptionDisplay = ({ answerOption, index, onDelete, onEdit }: OwnProps) => {
	return (
		<div key={`${answerOption.answer}-${index}`} className="grid">
			<div className="col-10">
				<div>Antwort: {htmlTagsInTextRemover(answerOption.answer)}</div>
				{answerOption.imageRef ? "Mit Bild" : "Ohne Bild"} |{" "}
				{answerOption.isCorrect ? CorrectSelectionText : "Ist falsch"}
			</div>
			<div className="flex col-2 justify-content-end">
				<div>
					<Button className="ml-2" icon="pi pi-pencil" type="button" onClick={onEdit} />
					<Button className="ml-2" icon="pi pi-trash" type="button" onClick={onDelete} />
				</div>
			</div>
		</div>
	);
};

/**
 * @generated SignedSource<<7e74c4fd8b7a4904670ded2669ff3e22>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateInstructorImplInput = {
  data: InstructorImplInput;
  clientMutationId?: string | null;
};
export type InstructorImplInput = {
  name: string;
  title?: string | null;
  description?: string | null;
  location?: string | null;
  clientMutationId?: string | null;
  signatureFileRef?: string | null;
  portraitFileRef?: string | null;
};
export type EditInstructorScreen_CreateMutation$variables = {
  input: CreateInstructorImplInput;
  connections: ReadonlyArray<string>;
};
export type EditInstructorScreen_CreateMutationVariables = EditInstructorScreen_CreateMutation$variables;
export type EditInstructorScreen_CreateMutation$data = {
  readonly Admin: {
    readonly Instructor: {
      readonly createInstructorImpl: {
        readonly data: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"EditInstructorScreen_InstructorFragment">;
          };
        };
      } | null;
    };
  };
};
export type EditInstructorScreen_CreateMutationResponse = EditInstructorScreen_CreateMutation$data;
export type EditInstructorScreen_CreateMutation = {
  variables: EditInstructorScreen_CreateMutationVariables;
  response: EditInstructorScreen_CreateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditInstructorScreen_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InstructorAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Instructor",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateInstructorImplPayload",
                "kind": "LinkedField",
                "name": "createInstructorImpl",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InstructorImplEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InstructorImpl",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditInstructorScreen_InstructorFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditInstructorScreen_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InstructorAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Instructor",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateInstructorImplPayload",
                "kind": "LinkedField",
                "name": "createInstructorImpl",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InstructorImplEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InstructorImpl",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "superId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "location",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "portraitFile",
                            "plural": false,
                            "selections": (v4/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "signatureFile",
                            "plural": false,
                            "selections": (v4/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "data",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9e0e69bc09a8799e66f9149d284759fe",
    "id": null,
    "metadata": {},
    "name": "EditInstructorScreen_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation EditInstructorScreen_CreateMutation(\n  $input: CreateInstructorImplInput!\n) {\n  Admin {\n    Instructor {\n      createInstructorImpl(input: $input) {\n        data {\n          node {\n            ...EditInstructorScreen_InstructorFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditInstructorScreen_InstructorFragment on InstructorImpl {\n  id\n  superId\n  name\n  title\n  description\n  location\n  portraitFile {\n    id\n    url\n  }\n  signatureFile {\n    id\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "142cb0193063c094bbe68e4272d6eedc";

export default node;

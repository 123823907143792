/**
 * @generated SignedSource<<994ef30d4378c9945a25228bd6cb217d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateCertificateTemplateInput = {
  data: CertificateTemplateInput;
  id: string;
  clientMutationId?: string | null;
};
export type CertificateTemplateInput = {
  name: string;
  variableMappings: ReadonlyArray<VariableMappingInput>;
  clientMutationId?: string | null;
  templateFileRef: string;
};
export type VariableMappingInput = {
  templateVariable: string;
  certificateVariable: string;
};
export type EditCertificateTemplateScreen_UpdateCertificateTemplateMutation$variables = {
  input: UpdateCertificateTemplateInput;
};
export type EditCertificateTemplateScreen_UpdateCertificateTemplateMutationVariables = EditCertificateTemplateScreen_UpdateCertificateTemplateMutation$variables;
export type EditCertificateTemplateScreen_UpdateCertificateTemplateMutation$data = {
  readonly Admin: {
    readonly Certificates: {
      readonly updateCertificateTemplate: {
        readonly data: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"EditCertificateTemplateScreen_CertificateTemplateFragment">;
          };
        };
      } | null;
    };
  };
};
export type EditCertificateTemplateScreen_UpdateCertificateTemplateMutationResponse = EditCertificateTemplateScreen_UpdateCertificateTemplateMutation$data;
export type EditCertificateTemplateScreen_UpdateCertificateTemplateMutation = {
  variables: EditCertificateTemplateScreen_UpdateCertificateTemplateMutationVariables;
  response: EditCertificateTemplateScreen_UpdateCertificateTemplateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditCertificateTemplateScreen_UpdateCertificateTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CertificatesAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Certificates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateCertificateTemplatePayload",
                "kind": "LinkedField",
                "name": "updateCertificateTemplate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CertificateTemplatesEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CertificateTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditCertificateTemplateScreen_CertificateTemplateFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditCertificateTemplateScreen_UpdateCertificateTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CertificatesAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Certificates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateCertificateTemplatePayload",
                "kind": "LinkedField",
                "name": "updateCertificateTemplate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CertificateTemplatesEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CertificateTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "templateFileRef",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "VariableMapping",
                            "kind": "LinkedField",
                            "name": "variableMappings",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "certificateVariable",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "templateVariable",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9ae87f7a364d852e9488844b5493020c",
    "id": null,
    "metadata": {},
    "name": "EditCertificateTemplateScreen_UpdateCertificateTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation EditCertificateTemplateScreen_UpdateCertificateTemplateMutation(\n  $input: UpdateCertificateTemplateInput!\n) {\n  Admin {\n    Certificates {\n      updateCertificateTemplate(input: $input) {\n        data {\n          node {\n            ...EditCertificateTemplateScreen_CertificateTemplateFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditCertificateTemplateScreen_CertificateTemplateFragment on CertificateTemplate {\n  id\n  name\n  templateFileRef\n  variableMappings {\n    certificateVariable\n    templateVariable\n  }\n}\n"
  }
};
})();

(node as any).hash = "f4497ebe1c6425ae30f6cbbb1a728aa1";

export default node;

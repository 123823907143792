import { selectJwtClaimData, selectLoginData } from "../../redux/slices/AuthSlice";
import { useSelector } from "react-redux";
import { FileUploadBeforeSendEvent } from "primereact/fileupload";

interface HookProps {
	isFilesPublic: boolean;
	canOverrideFile: boolean;
}

export const useFileUpload = ({ isFilesPublic, canOverrideFile }: HookProps) => {
	const loginData = useSelector(selectLoginData);
	const jwtClaimData = useSelector(selectJwtClaimData);

	const onBeforeSend = (ev: FileUploadBeforeSendEvent) => {
		if (loginData && jwtClaimData) {
			ev.xhr.setRequestHeader("X-Auth-Token", loginData.accessToken!);
			ev.xhr.setRequestHeader("X-Auth-Account-Id", jwtClaimData.accountId);
			ev.formData.set("isPublic", JSON.stringify(isFilesPublic));
			ev.formData.set("canOverride", JSON.stringify(canOverrideFile));
		}
	};

	return {
		onBeforeSend,
	};
};

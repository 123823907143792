import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import graphql from "babel-plugin-relay/macro";
import { readInlineData, useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { useContext, useEffect, useMemo } from "react";
import debounce from "lodash.debounce";
import { TreeStatesTable_Query } from "../../../../__generated__/TreeStatesTable_Query.graphql";
import {
	TreeStatesFilterState,
	TreeStatesSearchContext,
} from "../../../containers/TreeStatesSearchContainer";
import { TreeStatesTable_Refetch } from "../../../../__generated__/TreeStatesTable_Refetch.graphql";
import { TreeStatesTable_QueryFragment$key } from "../../../../__generated__/TreeStatesTable_QueryFragment.graphql";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../../lms-admin-impl/i18n/tree.i18n";
import { TitleColumn } from "./TitleColumn";
import { UserColumn } from "./UserColumn";
import { ActionColumn } from "../ActionColumn";

const QUERY = graphql`
	query TreeStatesTable_Query($first: Int, $filterByRootNodeId: ID, $filterByUserId: ID) {
		...TreeStatesTable_QueryFragment
			@arguments(
				first: $first
				filterByRootNodeId: $filterByRootNodeId
				filterByUserId: $filterByUserId
			)
	}
`;

const QUERY_FRAGMENT = graphql`
	fragment TreeStatesTable_QueryFragment on Query
	@refetchable(queryName: "TreeStatesTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 10 }
		after: { type: "String" }
		filterByRootNodeId: { type: "ID" }
		filterByUserId: { type: "ID" }
	) {
		Admin {
			LearnV2 {
				GetTreeStates(
					first: $first
					after: $after
					filterByRootNodeId: $filterByRootNodeId
					filterByUserId: $filterByUserId
				) @connection(key: "TreeStatesTable_GetTreeStates") {
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							...TreeStatesTable_TreeStateFragment
						}
					}
				}
			}
		}
	}
`;

const TREE_NODE_FRAGMENT = graphql`
	fragment TreeStatesTable_TreeStateFragment on TreeState @inline {
		...ActionColumn_TreeStateFragment
		...TitleColumn_TreeStateFragment
		...UserColumn_TreeStateFragment
	}
`;

export const TreeStatesTable = () => {
	const query = useLazyLoadQuery<TreeStatesTable_Query>(QUERY, { first: 20 });
	const { filters } = useContext(TreeStatesSearchContext);

	const { data, hasPrevious, hasNext, refetch, loadPrevious, loadNext } = usePaginationFragment<
		TreeStatesTable_Refetch,
		TreeStatesTable_QueryFragment$key
	>(QUERY_FRAGMENT, query);

	const treeStatesConnection = data.Admin.LearnV2.GetTreeStates || [];

	const debouncedRefetch = (filters: TreeStatesFilterState) => {
		refetch({ ...filters, first: 20 }, { fetchPolicy: "network-only" });
	};

	const debouncedEventHandler = useMemo(
		() => debounce(debouncedRefetch, 1000),
		// eslint-disable-next-line
		[],
	);

	useEffect(() => {
		debouncedEventHandler(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	return (
		<>
			<DataTable
				className="mb-3"
				value={
					treeStatesConnection.edges
						?.filter((e) => !!e?.node)
						.map((e) => e!.node)
						.map((item: any) => readInlineData(TREE_NODE_FRAGMENT, item)) || []
				}
			>
				<Column
					header={TREE_I18N_MAP(TREE_I18N_KEY.tree)}
					body={(item) => <TitleColumn treeStateFragmentRef={item} />}
				/>
				<Column
					header="Benutzer"
					body={(item) => <UserColumn treeStateFragmentRef={item} />}
				/>
				<Column
					header="Aktionen"
					style={{ width: "20%" }}
					body={(item) => <ActionColumn treeStateFragmentRef={item} />}
				/>
			</DataTable>

			<div className="flex justify-content-center align-items-center">
				<Button
					disabled={!hasPrevious}
					onClick={() => loadPrevious(20)}
					className="mr-3 p-button-secondary"
				>
					Zurück
				</Button>
				<Button
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={() => loadNext(20)}
				>
					Weiter
				</Button>
			</div>
		</>
	);
};

/**
 * @generated SignedSource<<23b5316d2c10840bf75a9a96014383b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StructureType = "root" | "child" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type VNumbersEditor_TreeNodeFragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly definitionType: StructureType;
    readonly extension?: {
      readonly vNumbers?: ReadonlyArray<{
        readonly number: string;
        readonly from: string;
        readonly to: string;
        readonly password: string;
        readonly active: boolean;
      }>;
    };
  };
  readonly " $fragmentType": "VNumbersEditor_TreeNodeFragment";
};
export type VNumbersEditor_TreeNodeFragment = VNumbersEditor_TreeNodeFragment$data;
export type VNumbersEditor_TreeNodeFragment$key = {
  readonly " $data"?: VNumbersEditor_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VNumbersEditor_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VNumbersEditor_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "definitionType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "VExtension",
                      "kind": "LinkedField",
                      "name": "vNumbers",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "number",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "from",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "to",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "password",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "active",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "RootExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "a9ce47e34700aa2306ffd149a3de4276";

export default node;

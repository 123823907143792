/**
 * @generated SignedSource<<cbfdcd2b931b8872de953ced8ccb971d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TreeEditorScreen_TreeFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TreeContainer_TreeFragment">;
  readonly " $fragmentType": "TreeEditorScreen_TreeFragment";
};
export type TreeEditorScreen_TreeFragment = TreeEditorScreen_TreeFragment$data;
export type TreeEditorScreen_TreeFragment$key = {
  readonly " $data"?: TreeEditorScreen_TreeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TreeEditorScreen_TreeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TreeEditorScreen_TreeFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TreeContainer_TreeFragment"
    }
  ],
  "type": "Tree",
  "abstractKey": null
};

(node as any).hash = "614ca03c2fdbe80dfacb094bce084ee3";

export default node;

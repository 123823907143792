import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { Button } from "primereact/button";
import { DeleteContentSubmissionButton_DeleteMutation } from "../../../__generated__/DeleteContentSubmissionButton_DeleteMutation.graphql";
import { useDialogLogic } from "../core/dialog/useDialogLogic";

const DELETE_MUTATION = graphql`
	mutation DeleteContentSubmissionButton_DeleteMutation(
		$input: DeleteDeleteContentSubmissionAndElementsInput!
		$connections: [ID!]!
	) {
		Admin {
			DeleteContentSubmissionAndLearnElements {
				deleteDeleteContentSubmissionAndElements(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
interface OwnProps {
	failedContentSubmissionIds: string[];
	userContentSubmissionsConnectionId: string;
	refetch: () => void;
}
export const DeleteContentSubmissionButton = ({
	failedContentSubmissionIds,
	userContentSubmissionsConnectionId,
	refetch,
}: OwnProps) => {
	const { showDialog, dialogComponent } = useDialogLogic();
	const [deleteContentSubmissionAndLearnElements, isDeleting] =
		useMutation<DeleteContentSubmissionButton_DeleteMutation>(DELETE_MUTATION);

	return (
		<div className="align-self-end">
			{dialogComponent}

			<Button
				disabled={isDeleting}
				type="button"
				onClick={() =>
					showDialog({
						title: `Die fehlgeschlagenen Module löschen`,
						content: `Möchten Sie  die fehlgeschlagenen Module wirklich löschen? Das kann nicht rückgängig gemacht werden.`,
						dialogCallback: (result) => {
							if (result === "Accept") {
								deleteContentSubmissionAndLearnElements({
									variables: {
										input: {
											ids: failedContentSubmissionIds,
										},
										connections: [userContentSubmissionsConnectionId],
									},
									onCompleted: () => {
										refetch();
									},
								});
							}
						},
					})
				}
				label={"Modul freischalten"}
			/>
		</div>
	);
};

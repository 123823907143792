import { graphql } from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { useFragment, useMutation } from "react-relay";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useContext } from "react";
import { Dropdown } from "primereact/dropdown";
import { ValidatedField } from "../../../../core/form/ValidatedField";
import { DefaultFileSelectionField } from "../../../../files/FileSelectionField";
import { DefaultTextEditorComponent } from "../../../../core/form/DefaultTextEditorComponent";
import { ImplContextV2 } from "../../../../../impl/ImplContextV2";
import { useTypedDispatch } from "../../../../../redux/Store";
import {
	resetArrayOfEditedForms,
	addEditedFormToEditedFormsArray,
} from "../../../../../redux/slices/CoreSlice";
import { ProjectAttachmentFormDescriptionInput } from "../../../../../../lms-admin-impl/components/ui/ProjectAttachmentFormDescriptionInput";
import { AttachmentForm_InfectopharmFileAttachmentFragment$key } from "../../../../../../__generated__/AttachmentForm_InfectopharmFileAttachmentFragment.graphql";
import { AttachmentForm_AddAttachmentMutation } from "../../../../../../__generated__/AttachmentForm_AddAttachmentMutation.graphql";
import { AttachmentForm_EditAttachmentMutation } from "../../../../../../__generated__/AttachmentForm_EditAttachmentMutation.graphql";

const ATTACHMENT_FRAGMENT = graphql`
	fragment AttachmentForm_InfectopharmFileAttachmentFragment on InfectopharmFileAttachment {
		id
		file {
			id
		}
		title
		description
		attachmentType
	}
`;

const ADD_ATTACHMENT_MUTATION = graphql`
	mutation AttachmentForm_AddAttachmentMutation($input: AddInfectopharmAttachmentInput!) {
		Admin {
			Tree {
				addInfectopharmAttachment(input: $input) {
					node {
						...AttachmentEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const EDIT_ATTACHMENT_MUTATION = graphql`
	mutation AttachmentForm_EditAttachmentMutation($input: EditInfectopharmAttachmentInput!) {
		Admin {
			Tree {
				editInfectopharmAttachment(input: $input) {
					node {
						...AttachmentEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface FormState {
	fileId?: string;
	attachmentType?: string;
	description?: string;
	title?: string;
}

interface OwnProps {
	nodeId: string;
	attachmentFragmentRef?: AttachmentForm_InfectopharmFileAttachmentFragment$key | null;
	onBack: () => void;
	setCurrentEditingAttachment?: React.Dispatch<React.SetStateAction<any>> | undefined;
	formType: "add" | "edit";
}

export const AttachmentForm = ({
	nodeId,
	attachmentFragmentRef,
	onBack,
	setCurrentEditingAttachment,
	formType,
}: OwnProps) => {
	const attachment = useFragment<AttachmentForm_InfectopharmFileAttachmentFragment$key>(
		ATTACHMENT_FRAGMENT,
		attachmentFragmentRef || null,
	);
	const [addAttachment, isAddingAttachment] =
		useMutation<AttachmentForm_AddAttachmentMutation>(ADD_ATTACHMENT_MUTATION);
	const [editAttachment, isEditingAttachment] =
		useMutation<AttachmentForm_EditAttachmentMutation>(EDIT_ATTACHMENT_MUTATION);
	const dispatch = useTypedDispatch();
	const { node } = useContext(ImplContextV2);
	const formId = "AttachmentForm";

	const formik = useFormik<FormState>({
		initialValues: {
			fileId: attachment?.file?.id,
			title: attachment?.title || undefined,
			description: attachment?.description || undefined,
			attachmentType: attachment?.attachmentType || undefined,
		},
		validationSchema: Yup.object().shape({
			fileId: Yup.string().required("Das Feld Datei wird benötigt."),
		}),
		onSubmit: (values, { setSubmitting, setTouched }) => {
			const payload = {
				variables: {
					input: {
						nodeId: nodeId,
						fileId: values.fileId!,
						titleOpt: values.title,
						descriptionOpt: values.description,
						attachmentTypeOpt: values.attachmentType,
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
					if (setCurrentEditingAttachment) {
						setCurrentEditingAttachment(undefined);
					}
					setTouched({});
					setSubmitting(false);
				},
			};

			if (formType === "add") {
				addAttachment(payload);
			} else {
				editAttachment({
					...payload,
					variables: {
						input: {
							...payload.variables.input,
							attachmentId: attachment?.id as string,
						},
					},
				});
			}
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, string>
				name={"fileId"}
				label={"Datei"}
				required={true}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
				component={DefaultFileSelectionField}
				formikConfig={formik}
			/>
			<ValidatedField<FormState, string>
				name={"title"}
				label={"Titel"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
				component={DefaultTextEditorComponent}
				formikConfig={formik}
			/>

			{ProjectAttachmentFormDescriptionInput ? (
				<ProjectAttachmentFormDescriptionInput />
			) : (
				<ValidatedField<FormState, string>
					name={"description"}
					label={"Beschreibung"}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
					component={DefaultTextEditorComponent}
					formikConfig={formik}
				/>
			)}

			<ValidatedField<FormState, string>
				name={"attachmentType"}
				label={"Typ"}
				component={({ fieldName, fieldValue, updateField }) => (
					<Dropdown
						value={fieldValue}
						onChange={(e) => {
							updateField(e.value);
							dispatch(addEditedFormToEditedFormsArray({ form: formId! }));
						}}
						name={fieldName}
						options={(node?.attachmentTypes || []).map((attachmentType: any) => ({
							label: attachmentType,
							value: attachmentType,
						}))}
					/>
				)}
				formikConfig={formik}
			/>

			<Button
				disabled={
					Object.entries(formik.touched).length === 0 ||
					isAddingAttachment ||
					isEditingAttachment
				}
				type="submit"
				label="Anhängen"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};

import { RenderConfig } from "./ValidatedField";
import { Editor } from "primereact/editor";
import { classNames } from "primereact/utils";
import React from "react";

const colorOptions = [
	{ value: "#000000", label: "Black" },
	{ value: "#ffffff", label: "White" },
	{ value: "#ff0000", label: "Red" },
	{ value: "#0000ff", label: "Blue" },
	{ value: "#ffff00", label: "Yellow" },
	{ value: "#808080", label: "Gray" },
	{ value: "#4ba540", label: "Green-InfectoPharm" },
	{ value: "#0099cc", label: "Paedia Color" },
];

export function TextEditorComponent({
	fieldName,
	fieldValue,
	updateField,
	isValid,
	onChange,
}: RenderConfig<string>) {
	return (
		<Editor
			headerTemplate={
				<div id={"#editor-container"}>
					<span className="ql-formats">
						<button className="ql-bold" aria-label="Bold" />
						<button className="ql-italic" aria-label="Italic" />
						<button className="ql-underline" aria-label="Underline" />
						<button className="ql-list" value="ordered" aria-label="Geordnete Liste" />
						<button className="ql-list" value="bullet" aria-label="Ungeordnete Liste" />
						<select className="ql-color">
							{colorOptions.map((color) => (
								<option key={color.label} value={color.value} />
							))}
						</select>
						<button className="ql-link" aria-label="Insert Link"></button>
						<button className="ql-clean" aria-label="Style entfernen" />
					</span>
				</div>
			}
			id={fieldName}
			value={fieldValue}
			onTextChange={(e) => {
				updateField(e.htmlValue || undefined);
				if (onChange) {
					onChange();
				}
			}}
			className={classNames({ "p-invalid": !isValid })}
		/>
	);
}

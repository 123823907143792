import { Button } from "primereact/button";
import graphql from "babel-plugin-relay/macro";
import { PreviewPublishedNodeActionButton_TreeNodeFragment$key } from "../../../../../__generated__/PreviewPublishedNodeActionButton_TreeNodeFragment.graphql";
import { useFragment } from "react-relay";

const PREVIEW_NODE_FRAGMENT = graphql`
	fragment PreviewPublishedNodeActionButton_TreeNodeFragment on TreeNode {
		id
		hasBeenPublishedOnce
		structureDefinition {
			... on RootStructureDefinition {
				extension {
					... on RootExtensionImpl {
						displayType
					}
				}
			}
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: PreviewPublishedNodeActionButton_TreeNodeFragment$key;
}
export const PreviewPublishedNodeActionButton = ({ treeNodeFragmentRef }: OwnProps) => {
	const treeNode = useFragment<PreviewPublishedNodeActionButton_TreeNodeFragment$key>(
		PREVIEW_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const encodedPublishedRootNodeId = (id: string) => {
		const publishedRootNodeId = atob(id).replace("TreeNode", "LearnOpportunityV2");
		return btoa(publishedRootNodeId);
	};
	return (
		<>
			{" "}
			{treeNode.hasBeenPublishedOnce &&
				!treeNode.structureDefinition.extension?.displayType?.includes("notDisplayed") && (
					<a
						className="ml-1"
						style={{ textDecoration: "none" }}
						href={`${
							process.env.REACT_APP_APP_SYSTEM_BASE
						}/modul/${encodedPublishedRootNodeId(treeNode.id)}`}
						rel="noreferrer"
						target="_blank"
					>
						<Button icon={"pi pi-eye"} tooltip={"Link zu Modul"} />
					</a>
				)}
		</>
	);
};

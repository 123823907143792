import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { UserNameColumn_Fragment$key } from "../../../__generated__/UserNameColumn_Fragment.graphql";

const FRAGMENT = graphql`
	fragment UserNameColumn_Fragment on User {
		extension {
			... on InfectopharmUserExtensionImpl {
				firstName
				lastName
			}
		}
	}
`;

interface OwnProps {
	userFragmentRef: UserNameColumn_Fragment$key;
}

export const UserNameColumn = ({ userFragmentRef }: OwnProps) => {
	const user = useFragment<UserNameColumn_Fragment$key>(FRAGMENT, userFragmentRef);

	return (
		<div>
			{user.extension.lastName} {user.extension.firstName}
		</div>
	);
};

import { FormikState } from "formik";
import { useTypedDispatch } from "../../../infecto-lms-admin/redux/Store";
import { ValidatedField } from "../../../infecto-lms-admin/components/core/form/ValidatedField";
import { DefaultTextFieldComponent } from "../../../infecto-lms-admin/components/core/form/DefaultTextInput";
import { addEditedFormToEditedFormsArray } from "../../../infecto-lms-admin/redux/slices/CoreSlice";

interface FormState {
	internalContainerCode?: string;
	certifier?: string;
	certifierRef?: string;
	drupalQuizId?: number;
	lengthOverride?: number;
	category?: string;
	location?: string;
	signatureFile?: string;
}

interface ContainerCodeMetaFieldsProps {
	formik: FormikState<FormState> & {
		setFieldTouched: (
			field: string,
			touched?: boolean,
			shouldValidate?: boolean | undefined,
		) => any;
		setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
	};
	formId: string;
}

export const RootContainerCodeMetaFields = (props: ContainerCodeMetaFieldsProps) => {
	const { formik } = props;
	const { formId } = props;

	const dispatch = useTypedDispatch();
	return (
		<>
			<ValidatedField<FormState, string>
				name={"internalContainerCode"}
				label={"Veranstaltungsnummer (intern)"}
				helpText={
					"Eine Nummer die für automatische Exporte verwendet werden kann. Kann nur für den obersten Ordner festgelegt werden."
				}
				component={DefaultTextFieldComponent}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				formikConfig={formik}
			/>
		</>
	);
};

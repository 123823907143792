/**
 * @generated SignedSource<<f34215c3466872bfad366de8ae6c3ce2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LandingPagesV2Table_Refetch$variables = {
  after?: string | null;
  first?: number | null;
};
export type LandingPagesV2Table_RefetchVariables = LandingPagesV2Table_Refetch$variables;
export type LandingPagesV2Table_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LandingPagesV2Table_QueryFragment">;
};
export type LandingPagesV2Table_RefetchResponse = LandingPagesV2Table_Refetch$data;
export type LandingPagesV2Table_Refetch = {
  variables: LandingPagesV2Table_RefetchVariables;
  response: LandingPagesV2Table_Refetch$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LandingPagesV2Table_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "LandingPagesV2Table_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LandingPagesV2Table_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LandingPageV2AdminSchema",
            "kind": "LinkedField",
            "name": "LandingPageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "LandingPageV2Connection",
                "kind": "LinkedField",
                "name": "LandingPages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LandingPageV2Edge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LandingPageV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "urlParam",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LearnOpportunitiesV2Connection",
                            "kind": "LinkedField",
                            "name": "rootNodes",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LearnOpportunitiesV2Edge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LearnOpportunityV2",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "structureDefinition",
                                        "plural": false,
                                        "selections": [
                                          (v3/*: any*/),
                                          (v4/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": null,
                                                "kind": "LinkedField",
                                                "name": "extension",
                                                "plural": false,
                                                "selections": [
                                                  (v3/*: any*/),
                                                  {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "isVisibleOnlyForAdmins",
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "type": "LearnOpportunityRootExtensionImpl",
                                                    "abstractKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "type": "LearnOpportunityRootStructureDefinition",
                                            "abstractKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "LandingPagesV2Table_LandingPages",
                "kind": "LinkedHandle",
                "name": "LandingPages"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ad2c2d2640e436df810c4789e66ab0bb",
    "id": null,
    "metadata": {},
    "name": "LandingPagesV2Table_Refetch",
    "operationKind": "query",
    "text": "query LandingPagesV2Table_Refetch(\n  $after: String\n  $first: Int\n) {\n  ...LandingPagesV2Table_QueryFragment_2HEEH6\n}\n\nfragment ActionColumn_LandingPageFragment on LandingPageV2 {\n  id\n  urlParam\n}\n\nfragment ContentsColumn_LandingPageFragment on LandingPageV2 {\n  rootNodes {\n    edges {\n      node {\n        id\n        structureDefinition {\n          __typename\n          title\n          ... on LearnOpportunityRootStructureDefinition {\n            extension {\n              __typename\n              ... on LearnOpportunityRootExtensionImpl {\n                isVisibleOnlyForAdmins\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment DescriptionColumn_LandingPageFragment on LandingPageV2 {\n  description\n}\n\nfragment LandingPagesV2Table_LandingPageFragment on LandingPageV2 {\n  ...ActionColumn_LandingPageFragment\n  ...ContentsColumn_LandingPageFragment\n  ...DescriptionColumn_LandingPageFragment\n  ...TitleColumn_LandingPageFragment\n  ...UrlPathColumn_LandingPageFragment\n}\n\nfragment LandingPagesV2Table_QueryFragment_2HEEH6 on Query {\n  Admin {\n    LandingPageV2 {\n      LandingPages(first: $first, after: $after) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            ...LandingPagesV2Table_LandingPageFragment\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n\nfragment TitleColumn_LandingPageFragment on LandingPageV2 {\n  title\n}\n\nfragment UrlPathColumn_LandingPageFragment on LandingPageV2 {\n  urlParam\n}\n"
  }
};
})();

(node as any).hash = "669cee5d5fe0a6a025fb131d679835e0";

export default node;

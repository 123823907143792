import React, { useEffect } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import {
	AddSpecificUserStatusesCanStartContentConfigMutation,
	SpecificUserStatusesCanStartContentConfigForm,
} from "../../../app/components/tree/editor/node/config/content/startContent/SpecificUserStatusesCanStartContentConfigForm";
import { RelayEnvironment } from "../../../infecto-lms-admin/relay/RelayEnvironment";
import {
	ExtendConfigInterface,
	Config,
} from "../../../infecto-lms-admin/components/tree/editor/node/config/components/Config.interfaces";
import moment from "moment-timezone";
import { AddSpecificUserStatusesCanStartContentConfigInput } from "../../../__generated__/SpecificUserStatusesCanStartContentConfigForm_AddMutation.graphql";
import { ProjectExtendStartContentConfigEditDialogs_TreeNodeFragment$key } from "../../../__generated__/ProjectExtendStartContentConfigEditDialogs_TreeNodeFragment.graphql";
import { ProjectExtendStartContentConfigEditDialogs_StartContentConfigFragment$key } from "../../../__generated__/ProjectExtendStartContentConfigEditDialogs_StartContentConfigFragment.graphql";
import { StartContentConfigType } from "../../../__generated__/StartContentConfigEditor_TreeNodeFragment.graphql";

const TREE_NODE_FRAGMENT = graphql`
	fragment ProjectExtendStartContentConfigEditDialogs_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ELearningContentTypeDefinition {
				startConfigs {
					configType
					... on SpecifiedUserStatusesCanStartContentConfig {
						requiredUserStatuses
						dateTime
					}
					...SpecificUserStatusesCanStartContentConfigForm_StartContentConfigFragment
				}
			}
		}
		...SpecificUserStatusesCanStartContentConfigForm_TreeNodeFragment
	}
`;

const CONFIGURATION_FRAGMENT = graphql`
	fragment ProjectExtendStartContentConfigEditDialogs_StartContentConfigFragment on StartContentConfig {
		configType
		...SpecificUserStatusesCanStartContentConfigForm_StartContentConfigFragment
	}
`;

interface Props extends ExtendConfigInterface<StartContentConfigType> {
	treeNodeFragmentRef: ProjectExtendStartContentConfigEditDialogs_TreeNodeFragment$key;
	configurationFragmentRef: ProjectExtendStartContentConfigEditDialogs_StartContentConfigFragment$key;
}

export const ExtendStartContentConfigs: Config<
	StartContentConfigType,
	AddSpecificUserStatusesCanStartContentConfigInput
>[] = [
	{
		configKey: "StartContent_SpecificUserStatusesCan",
		addMutation: (input: AddSpecificUserStatusesCanStartContentConfigInput) =>
			AddSpecificUserStatusesCanStartContentConfigMutation(RelayEnvironment, input),
		addMutationPayload: {
			dateTime: moment().add(1, "year").format(),
			userStatuses: [],
		},
		editable: true,
	},
];

export const ProjectExtendStartContentConfigEditDialogs = (props: Props) => {
	const treeNode = useFragment<ProjectExtendStartContentConfigEditDialogs_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		props.treeNodeFragmentRef,
	);
	const configuration =
		useFragment<ProjectExtendStartContentConfigEditDialogs_StartContentConfigFragment$key>(
			CONFIGURATION_FRAGMENT,
			props.configurationFragmentRef,
		);

	useEffect(() => {
		if (props.setCustomLabels) {
			props.setCustomLabels([
				{
					configKey: "StartContent_SpecificUserStatusesCan",
					label: treeNode.typeDefinition.startConfigs?.find(
						(c) => c.configType === "StartContent_SpecificUserStatusesCan",
					)?.dateTime,
				},
			]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return props.setCustomLabels === undefined ? (
		<>
			{configuration.configType === "StartContent_SpecificUserStatusesCan" && (
				<SpecificUserStatusesCanStartContentConfigForm
					{...props}
					treeNodeFragmentRef={treeNode}
					configurationFragmentRef={configuration}
				/>
			)}
		</>
	) : (
		<></>
	);
};

import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { FilesScreen_FilesQuery } from "../../__generated__/FilesScreen_FilesQuery.graphql";
import { BaseScreen } from "./BaseScreen";
import { FilesTable } from "../components/files/FilesTable";

const QUERY = graphql`
	query FilesScreen_FilesQuery {
		...FilesTable_FilesListFragment
	}
`;

export const FilesScreen = () => {
	const data = useLazyLoadQuery<FilesScreen_FilesQuery>(
		QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);

	return (
		<BaseScreen>
			<h1>Dateien</h1>

			<FilesTable filesFragmentRef={data} />
		</BaseScreen>
	);
};

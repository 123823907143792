import React, { useContext } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { ProjectRootNodesSearchContext } from "./ProjectRootNodesSearchContainer";
import { TagMultiselect } from "../../../infecto-lms-admin/components/graph/editor/TagMultiselect";

export const ProjectRootNodesFilters = () => {
	const { filters, setFilters, clearFilters } = useContext(ProjectRootNodesSearchContext);

	const dropdownOptions = [
		{ label: "Ja", value: true },
		{ label: "Nein", value: false },
		{ label: "Alle", value: null },
	];

	return (
		<div className="flex mb-3 flex-wrap">
			<div>
				<InputText
					className="m-1 w-21rem"
					value={filters.filterByTitleOrDescriptionOrShortDescription || ""}
					onChange={(e) =>
						setFilters({
							...filters,
							filterByTitleOrDescriptionOrShortDescription: e.target.value,
						})
					}
					placeholder={"Titel, Beschreibung oder Kurzbeschreibung"}
				/>
			</div>
			<div>
				<TagMultiselect
					selectedTags={filters.filterByTagIds}
					onSelect={(tagIds) => {
						setFilters({
							...filters,
							filterByTagIds: tagIds?.length === 0 ? null : tagIds,
						});
					}}
				/>
			</div>
			<div>
				<Dropdown
					className="m-1"
					options={dropdownOptions.map((o) => ({
						...o,
						label: `Zertifikat: ${o.label}`,
					}))}
					value={filters.filterByIsRewardedByCertificate}
					placeholder={"mit Zertifikat"}
					onChange={(e) => {
						setFilters({
							...filters,
							filterByIsRewardedByCertificate: e.value,
						});
					}}
				/>
			</div>
			<div>
				<Dropdown
					className="m-1"
					options={dropdownOptions.map((o) => ({
						...o,
						label: `mit Punkten: ${o.label}`,
					}))}
					value={filters.filterByIsRewardedByPoints}
					placeholder={"Ist mit Punkten"}
					onChange={(e) => {
						setFilters({
							...filters,
							filterByIsRewardedByPoints: e.value,
						});
					}}
				/>
			</div>
			<div>
				<InputText
					className="m-1 w-21rem"
					value={filters.filterByVnr || ""}
					onChange={(e) =>
						setFilters({
							...filters,
							filterByVnr: e.target.value,
						})
					}
					placeholder={"Veranstaltungsnummer"}
				/>
			</div>
			<div>
				<InputText
					className="m-1 w-21rem"
					value={filters.filterByInternalVnr || ""}
					onChange={(e) =>
						setFilters({
							...filters,
							filterByInternalVnr: e.target.value,
						})
					}
					placeholder={"Interne Veranstaltungsnummer"}
				/>
			</div>
			<div>
				<Dropdown
					className="m-1"
					options={dropdownOptions.map((o) => ({
						...o,
						label: `Wird Angezeigt: ${o.label}`,
					}))}
					value={filters.filterByVisibility}
					placeholder={"Wird Angezeigt"}
					onChange={(e) => {
						setFilters({
							...filters,
							filterByVisibility: e.value,
						});
					}}
				/>
			</div>
			<div className="flex align-items-center m-1">
				<Button
					className=""
					label={"Zurücksetzen"}
					icon="pi pi-times"
					onClick={() => {
						clearFilters();
					}}
				/>
			</div>
		</div>
	);
};

import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ConfigurationBadgesColumn_TreeNodeFragment$key } from "../../../../../__generated__/ConfigurationBadgesColumn_TreeNodeFragment.graphql";
import { NodeConfigurationBadges } from "../../editor/tree/NodeConfigurationBadges";

const TREE_NODE_FRAGMENT = graphql`
	fragment ConfigurationBadgesColumn_TreeNodeFragment on TreeNode {
		...NodeConfigurationBadges_TreeNodeFragment
		...ProjectExtendNodeConfigurationBadges_TreeNodeFragment
	}
`;

interface OwnProps {
	treeNodeFragmentRef: ConfigurationBadgesColumn_TreeNodeFragment$key;
}

export const ConfigurationBadgesColumn = ({ treeNodeFragmentRef }: OwnProps) => {
	const treeNode = useFragment<ConfigurationBadgesColumn_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	return <NodeConfigurationBadges treeNodeFragmentRef={treeNode} />;
};

import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { UserColumn_PointsAdditionFragment$key } from "../../../../__generated__/UserColumn_PointsAdditionFragment.graphql";
import { ProjectUserDescription } from "../../../../lms-admin-impl/components/relay/ProjectUserDescription";

const POINTS_ADDITION_FRAGMENT = graphql`
	fragment UserColumn_PointsAdditionFragment on PointsAdditionV2 {
		user {
			id
			...ProjectUserDescription_UserFragment
		}
	}
`;

interface OwnProps {
	pointsAdditionFragmentRef: UserColumn_PointsAdditionFragment$key;
}

export const UserColumn = ({ pointsAdditionFragmentRef }: OwnProps) => {
	const pointsAddition = useFragment<UserColumn_PointsAdditionFragment$key>(
		POINTS_ADDITION_FRAGMENT,
		pointsAdditionFragmentRef,
	);

	return (
		<div>
			<ProjectUserDescription userFragmentRef={pointsAddition.user} />
		</div>
	);
};

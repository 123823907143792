import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { TreeConfigurationEditDialogProps } from "../../components/Config.interfaces";
import { useFragment, useMutation } from "react-relay";
import { ValidatedField } from "../../../../../../core/form/ValidatedField";
import { OnPercentagePassContentConfigForm_EditMutation } from "../../../../../../../../__generated__/OnPercentagePassContentConfigForm_EditMutation.graphql";
import { OnPercentagePassContentConfigForm_OnPercentagePassContentConfigFragment$key } from "../../../../../../../../__generated__/OnPercentagePassContentConfigForm_OnPercentagePassContentConfigFragment.graphql";
import { OnPercentagePassContentConfigForm_TreeNodeFragment$key } from "../../../../../../../../__generated__/OnPercentagePassContentConfigForm_TreeNodeFragment.graphql";
import { InputNumber } from "primereact/inputnumber";
import { CONFIGS_TRANSLATIONS } from "../../../../../../../i18n/config/i18n";

const CONFIGURATION_FRAGMENT = graphql`
	fragment OnPercentagePassContentConfigForm_OnPercentagePassContentConfigFragment on OnPercentagePassContentConfig {
		id
		configType
		requiredPercentage
	}
`;

const TREE_NODE_FRAGMENT = graphql`
	fragment OnPercentagePassContentConfigForm_TreeNodeFragment on TreeNode {
		id
	}
`;

const EDIT_CONFIGURATION_MUTATION = graphql`
	mutation OnPercentagePassContentConfigForm_EditMutation(
		$input: EditOnPercentagePassContentConfigInput!
	) {
		Admin {
			Tree {
				editOnPercentagePassContentConfig(input: $input) {
					contentNode {
						...PassContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface FormState {
	requiredPercentage: number;
}

interface Props extends TreeConfigurationEditDialogProps {
	configurationFragmentRef: OnPercentagePassContentConfigForm_OnPercentagePassContentConfigFragment$key;
	treeNodeFragmentRef: OnPercentagePassContentConfigForm_TreeNodeFragment$key;
	onBack?: () => void;
}

export const OnPercentagePassContentConfigForm = ({
	configurationFragmentRef,
	treeNodeFragmentRef,
	onBack,
}: Props) => {
	const treeNode = useFragment<OnPercentagePassContentConfigForm_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const configuration =
		useFragment<OnPercentagePassContentConfigForm_OnPercentagePassContentConfigFragment$key>(
			CONFIGURATION_FRAGMENT,
			configurationFragmentRef,
		);
	const [edit, isEditing] = useMutation<OnPercentagePassContentConfigForm_EditMutation>(
		EDIT_CONFIGURATION_MUTATION,
	);

	const formik = useFormik<FormState>({
		enableReinitialize: true,
		initialValues: {
			requiredPercentage: configuration.requiredPercentage,
		},
		onSubmit: (values, { setSubmitting, setTouched }) => {
			edit({
				variables: {
					input: {
						contentNodeId: treeNode.id,
						configId: configuration.id,
						newRequiredPercentage: values.requiredPercentage,
					},
				},
			});

			setSubmitting(false);
			setTouched({});
		},
	});

	return (
		<>
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, number>
					name={"requiredPercentage"}
					label={CONFIGS_TRANSLATIONS[configuration.configType]}
					component={({ fieldName, fieldValue, updateField }) => (
						<div>
							<InputNumber
								name={fieldName}
								step={1}
								value={fieldValue}
								onChange={(e) => updateField(e.value || 75)}
							/>
						</div>
					)}
					formikConfig={formik}
				/>

				<Button
					disabled={Object.entries(formik.touched).length === 0 || isEditing}
					type="submit"
					label="Speichern"
					className="mt-2"
				/>

				<Button
					type="button"
					onClick={() => {
						onBack!();
					}}
					label="Zurück"
					className="p-button-secondary mt-2"
				/>
			</form>
		</>
	);
};

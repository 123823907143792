/**
 * @generated SignedSource<<50fb6d364c0417133667f5fd3143cc48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserStatusInput = "noProcess" | "initialValidation" | "changeInProgress" | "doccheck" | "inactive" | "efnNeeded" | "active" | "activeEfnNeeded" | "%future added value";
export type EditSpecificUserStatusesCanStartContentConfigInput = {
  newDateTime?: string | null;
  contentNodeId: string;
  configId: string;
  newUserStatuses: ReadonlyArray<UserStatusInput>;
  clientMutationId?: string | null;
};
export type SpecificUserStatusesCanStartContentConfigForm_EditMutation$variables = {
  input: EditSpecificUserStatusesCanStartContentConfigInput;
};
export type SpecificUserStatusesCanStartContentConfigForm_EditMutationVariables = SpecificUserStatusesCanStartContentConfigForm_EditMutation$variables;
export type SpecificUserStatusesCanStartContentConfigForm_EditMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly EditSpecificUserStatusesCanStartContentConfig: {
        readonly contentNode: {
          readonly " $fragmentSpreads": FragmentRefs<"SpecificUserStatusesCanStartContentConfigForm_TreeNodeFragment">;
        };
      } | null;
    };
  };
};
export type SpecificUserStatusesCanStartContentConfigForm_EditMutationResponse = SpecificUserStatusesCanStartContentConfigForm_EditMutation$data;
export type SpecificUserStatusesCanStartContentConfigForm_EditMutation = {
  variables: SpecificUserStatusesCanStartContentConfigForm_EditMutationVariables;
  response: SpecificUserStatusesCanStartContentConfigForm_EditMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SpecificUserStatusesCanStartContentConfigForm_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditSpecificUserStatusesCanStartContentConfigPayload",
                "kind": "LinkedField",
                "name": "EditSpecificUserStatusesCanStartContentConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "contentNode",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "SpecificUserStatusesCanStartContentConfigForm_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SpecificUserStatusesCanStartContentConfigForm_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditSpecificUserStatusesCanStartContentConfigPayload",
                "kind": "LinkedField",
                "name": "EditSpecificUserStatusesCanStartContentConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "contentNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "typeDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "startConfigs",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "TypeDiscriminator",
                                    "abstractKey": "__isStartContentConfig"
                                  },
                                  (v2/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "configType",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "dateTime",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "requiredUserStatuses",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "SpecifiedUserStatusesCanStartContentConfig",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "ELearningContentTypeDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eeff45f08072768db0fbe92a1d2abe4c",
    "id": null,
    "metadata": {},
    "name": "SpecificUserStatusesCanStartContentConfigForm_EditMutation",
    "operationKind": "mutation",
    "text": "mutation SpecificUserStatusesCanStartContentConfigForm_EditMutation(\n  $input: EditSpecificUserStatusesCanStartContentConfigInput!\n) {\n  Admin {\n    Tree {\n      EditSpecificUserStatusesCanStartContentConfig(input: $input) {\n        contentNode {\n          ...SpecificUserStatusesCanStartContentConfigForm_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment SpecificUserStatusesCanStartContentConfigForm_StartContentConfigFragment on StartContentConfig {\n  __isStartContentConfig: __typename\n  ... on SpecifiedUserStatusesCanStartContentConfig {\n    id\n    configType\n    dateTime\n    requiredUserStatuses\n  }\n}\n\nfragment SpecificUserStatusesCanStartContentConfigForm_TreeNodeFragment on TreeNode {\n  id\n  typeDefinition {\n    __typename\n    ... on ELearningContentTypeDefinition {\n      startConfigs {\n        __typename\n        ...SpecificUserStatusesCanStartContentConfigForm_StartContentConfigFragment\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1c16b82a7726b7d20d29db6d19e753a8";

export default node;

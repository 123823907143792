/**
 * @generated SignedSource<<52a51228b76afe0627f50007e2f44b36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RewardForm_TreeNodeFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"EmailRewardConfigurationForm_TreeNodeFragment" | "EditAwardPacPointsConfigurationForm_TreeNodeFragment" | "EditAwardPharmaPointsConfigurationForm_TreeNodeFragment">;
  readonly " $fragmentType": "RewardForm_TreeNodeFragment";
};
export type RewardForm_TreeNodeFragment = RewardForm_TreeNodeFragment$data;
export type RewardForm_TreeNodeFragment$key = {
  readonly " $data"?: RewardForm_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RewardForm_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RewardForm_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmailRewardConfigurationForm_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditAwardPacPointsConfigurationForm_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditAwardPharmaPointsConfigurationForm_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "24b232f4392b55b3d185506f8f2d792a";

export default node;

/**
 * @generated SignedSource<<e0fa1e962133ccd46fb06494d59f6069>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TesterSendInformExpirationRemindRetryEmailInput = {
  rootNodeId: string;
  templateId: string;
  clientMutationId?: string | null;
};
export type EmailTesterInformExpirationOrRemindRetryForm_Mutation$variables = {
  input: TesterSendInformExpirationRemindRetryEmailInput;
};
export type EmailTesterInformExpirationOrRemindRetryForm_MutationVariables = EmailTesterInformExpirationOrRemindRetryForm_Mutation$variables;
export type EmailTesterInformExpirationOrRemindRetryForm_Mutation$data = {
  readonly Admin: {
    readonly TesterEmail: {
      readonly EmailTesterInformExpirationRemindRetryEmail: {
        readonly clientMutationId: string | null;
      } | null;
    };
  };
};
export type EmailTesterInformExpirationOrRemindRetryForm_MutationResponse = EmailTesterInformExpirationOrRemindRetryForm_Mutation$data;
export type EmailTesterInformExpirationOrRemindRetryForm_Mutation = {
  variables: EmailTesterInformExpirationOrRemindRetryForm_MutationVariables;
  response: EmailTesterInformExpirationOrRemindRetryForm_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TesterEmailAdminMutationSchema",
        "kind": "LinkedField",
        "name": "TesterEmail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "TesterSendInformExpirationRemindRetryEmailPayload",
            "kind": "LinkedField",
            "name": "EmailTesterInformExpirationRemindRetryEmail",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailTesterInformExpirationOrRemindRetryForm_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailTesterInformExpirationOrRemindRetryForm_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "425830b90aa714f3d27943fa89c70d97",
    "id": null,
    "metadata": {},
    "name": "EmailTesterInformExpirationOrRemindRetryForm_Mutation",
    "operationKind": "mutation",
    "text": "mutation EmailTesterInformExpirationOrRemindRetryForm_Mutation(\n  $input: TesterSendInformExpirationRemindRetryEmailInput!\n) {\n  Admin {\n    TesterEmail {\n      EmailTesterInformExpirationRemindRetryEmail(input: $input) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "35216e5c18384cc707bf35d41c96672e";

export default node;

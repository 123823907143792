import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import React from "react";
import { CertificatesRewardConfigurationForm_CertificateRewardConfigurationV2Fragment$key } from "../../../../__generated__/CertificatesRewardConfigurationForm_CertificateRewardConfigurationV2Fragment.graphql";
import { CertificatesRewardConfigurationForm_EditCertificateRewardV2Mutation } from "../../../../__generated__/CertificatesRewardConfigurationForm_EditCertificateRewardV2Mutation.graphql";
import { ValidatedField } from "../../../../infecto-lms-admin/components/core/form/ValidatedField";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../../infecto-lms-admin/redux/slices/CoreSlice";
import { CertificateTemplateSelectionField } from "../../tree/CertificateTemplateSelectionField";
import { useTypedDispatch } from "../../../../infecto-lms-admin/redux/Store";

const CERTIFICATE_REWARD_CONFIGURATION_FRAGMENT = graphql`
	fragment CertificatesRewardConfigurationForm_CertificateRewardConfigurationV2Fragment on CertificateRewardConfiguration {
		id
		certificateTemplateRef
	}
`;

const EDIT_CERTIFICATE_REWARD_MUTATION = graphql`
	mutation CertificatesRewardConfigurationForm_EditCertificateRewardV2Mutation(
		$input: EditCertificateRewardV2Input!
	) {
		Admin {
			Tree {
				editCertificateReward(input: $input) {
					contentNode {
						...RewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface FormState {
	certificateTemplateId: string;
}

interface OwnProps {
	contentNodeId: string;
	certificateRewardConfigurationFragmentRef: CertificatesRewardConfigurationForm_CertificateRewardConfigurationV2Fragment$key;
	onBack: () => void;
}

export const CertificatesRewardConfigurationForm = ({
	contentNodeId,
	certificateRewardConfigurationFragmentRef,
	onBack,
}: OwnProps) => {
	const certificateRewardConfiguration =
		useFragment<CertificatesRewardConfigurationForm_CertificateRewardConfigurationV2Fragment$key>(
			CERTIFICATE_REWARD_CONFIGURATION_FRAGMENT,
			certificateRewardConfigurationFragmentRef,
		);
	const [editCertificateReward, isEditingCertificateReward] =
		useMutation<CertificatesRewardConfigurationForm_EditCertificateRewardV2Mutation>(
			EDIT_CERTIFICATE_REWARD_MUTATION,
		);
	const dispatch = useTypedDispatch();
	const formId = "CertificatesRewardConfigurationForm";
	const formik = useFormik<FormState>({
		initialValues: {
			certificateTemplateId: certificateRewardConfiguration.certificateTemplateRef,
		},
		validationSchema: Yup.object().shape({
			certificateTemplateId: Yup.string().required(
				"Das Feld Zertifikats-Vorlage wird benötigt.",
			),
		}),
		onSubmit: (values, formikHelpers) => {
			editCertificateReward({
				variables: {
					input: {
						contentNodeId: contentNodeId,
						rewardId: certificateRewardConfiguration.id,
						certificateTemplateId: values.certificateTemplateId,
					},
				},
				onCompleted: () => {
					formikHelpers.setTouched({});
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, string>
				name={"certificateTemplateId"}
				label={"Zertifikats-Vorlage"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
				component={CertificateTemplateSelectionField}
				formikConfig={formik}
			/>

			<Button
				disabled={Object.entries(formik.touched).length === 0 || isEditingCertificateReward}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};

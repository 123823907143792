import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import graphql from "babel-plugin-relay/macro";
import { readInlineData, usePaginationFragment } from "react-relay";
import { ActionColumn } from "./ActionColumn";
import { FeedbacksTableV2_QueryFragment$key } from "../../../__generated__/FeedbacksTableV2_QueryFragment.graphql";
import { FeedbacksTableV2_Refetch } from "../../../__generated__/FeedbacksTableV2_Refetch.graphql";
import { CreatedAtColumn } from "./CreatedAtColumn";
import { UserColumn } from "./UserColumn";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../lms-admin-impl/i18n/tree.i18n";
import { ContentColumn } from "./ContentColumn";
import { ScreenColumn } from "./ScreenColumn";

const QUERY_FRAGMENT = graphql`
	fragment FeedbacksTableV2_QueryFragment on Query
	@refetchable(queryName: "FeedbacksTableV2_Refetch")
	@argumentDefinitions(first: { type: "Int", defaultValue: 20 }, after: { type: "String" }) {
		Admin {
			FeedbackV2 {
				Feedbacks(first: $first, after: $after)
					@connection(key: "FeedbacksTableV2_Feedbacks") {
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							...FeedbacksTableV2_FeedbackFragment
						}
					}
				}
			}
		}
	}
`;

const FEEDBACK_FRAGMENT = graphql`
	fragment FeedbacksTableV2_FeedbackFragment on FeedbackV2 @inline {
		...ActionColumn_FeedbackFragment
		...ContentColumn_FeedbackFragment
		...CreatedAtColumn_FeedbackFragment
		...ScreenColumn_FeedbackFragment
		...UserColumn_FeedbackFragment
	}
`;

interface OwnProps {
	queryFragmentRef: FeedbacksTableV2_QueryFragment$key;
}

export const FeedbacksTableV2 = ({ queryFragmentRef }: OwnProps) => {
	const { data, hasPrevious, hasNext, loadPrevious, loadNext } = usePaginationFragment<
		FeedbacksTableV2_Refetch,
		FeedbacksTableV2_QueryFragment$key
	>(QUERY_FRAGMENT, queryFragmentRef);

	const feedbacksConnection = data.Admin.FeedbackV2.Feedbacks || [];

	return (
		<>
			<DataTable
				emptyMessage="Keine Feedbacks"
				className="mb-3"
				value={
					feedbacksConnection.edges
						?.filter((e) => !!e?.node)
						.map((e) => e!.node)
						.map((item: any) => readInlineData(FEEDBACK_FRAGMENT, item)) || []
				}
			>
				<Column
					header="Datum"
					body={(item) => <CreatedAtColumn feedbackFragmentRef={item} />}
				/>
				<Column
					header="Benutzer"
					body={(item) => <UserColumn feedbackFragmentRef={item} />}
				/>
				<Column
					header={`${TREE_I18N_MAP(TREE_I18N_KEY.node)}`}
					body={(item) => <ContentColumn feedbackFragmentRef={item} />}
				/>
				<Column
					header="Bildschirm"
					body={(item) => <ScreenColumn feedbackFragmentRef={item} />}
				/>
				<Column
					header="Aktionen"
					style={{ width: "20%" }}
					body={(item) => <ActionColumn feedbackFragmentRef={item} />}
				/>
			</DataTable>
			<div className="flex justify-content-center align-items-center">
				<Button
					disabled={!hasPrevious}
					onClick={() => loadPrevious(20)}
					className="mr-3 p-button-secondary"
				>
					Zurück
				</Button>
				<Button
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={() => loadNext(20)}
				>
					Weiter
				</Button>
			</div>
		</>
	);
};

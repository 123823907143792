import { PrivateRouteDefinition } from "../../infecto-lms-admin/components/core/model/PrivateRouteDefinition";
import { LandingPagesV2Screen } from "../../infecto-lms-admin/screens/LandingPagesV2Screen";
import { EditLandingPageV2Screen } from "../../infecto-lms-admin/screens/EditLandingPageV2Screen";

export const LANDING_PAGES_PATH_V2 = "/landingpages";

export const LANDING_PAGE_ID_URL_PARAM_V2 = ":landingpageId";
export const getEditLandingPagePathV2 = (landingPageId: string) => {
	return `/landingpages/${landingPageId}/edit`;
};

export const LandingPageModuleRoutes: PrivateRouteDefinition[] = [
	{
		path: LANDING_PAGES_PATH_V2,
		element: <LandingPagesV2Screen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_System_Owner",
			"UserInAccountPermission_LandingPages_ReadLandingPages",
		],
	},
	{
		path: getEditLandingPagePathV2(LANDING_PAGE_ID_URL_PARAM_V2),
		element: <EditLandingPageV2Screen />,
		requiredPermissions: [
			"AccountPermission_System_Root",
			"UserInAccountPermission_System_Owner",
			"UserInAccountPermission_LandingPages_UpdateLandingPages",
		],
	},
];

import { PrivateRouteDefinition } from "../../infecto-lms-admin/components/core/model/PrivateRouteDefinition";
import { EmailTemplatesScreen } from "../../infecto-lms-admin/screens/EmailTemplatesScreen";
import { EditEmailTemplateScreen } from "../../infecto-lms-admin/screens/EditEmailTemplateScreen";

export const EDIT_TEMPLATE_PATH = "/email-templates/:emailTemplateId/edit";
export const EmailModuleRoutes: PrivateRouteDefinition[] = [
	{
		path: "/email-templates",
		element: <EmailTemplatesScreen />,
		requiredPermissions: ["AccountPermission_System_Root"],
	},
	{
		path: EDIT_TEMPLATE_PATH,
		element: <EditEmailTemplateScreen />,
		requiredPermissions: ["AccountPermission_System_Root"],
	},
];

/**
 * @generated SignedSource<<f6a2fcc49f71786483b9364f9cacef43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetOnPercentagePassContentConfigInput = {
  requiredPercentage: number;
  contentNodeId: string;
  clientMutationId?: string | null;
};
export type PassContentConfigEditor_SetOnPercentagePassContentConfigMutation$variables = {
  input: SetOnPercentagePassContentConfigInput;
};
export type PassContentConfigEditor_SetOnPercentagePassContentConfigMutationVariables = PassContentConfigEditor_SetOnPercentagePassContentConfigMutation$variables;
export type PassContentConfigEditor_SetOnPercentagePassContentConfigMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly setOnPercentagePassContentConfig: {
        readonly contentNode: {
          readonly " $fragmentSpreads": FragmentRefs<"PassContentConfigEditor_TreeNodeFragment">;
        };
      } | null;
    };
  };
};
export type PassContentConfigEditor_SetOnPercentagePassContentConfigMutationResponse = PassContentConfigEditor_SetOnPercentagePassContentConfigMutation$data;
export type PassContentConfigEditor_SetOnPercentagePassContentConfigMutation = {
  variables: PassContentConfigEditor_SetOnPercentagePassContentConfigMutationVariables;
  response: PassContentConfigEditor_SetOnPercentagePassContentConfigMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PassContentConfigEditor_SetOnPercentagePassContentConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetOnPercentagePassContentConfigPayload",
                "kind": "LinkedField",
                "name": "setOnPercentagePassContentConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "contentNode",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "PassContentConfigEditor_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PassContentConfigEditor_SetOnPercentagePassContentConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetOnPercentagePassContentConfigPayload",
                "kind": "LinkedField",
                "name": "setOnPercentagePassContentConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "contentNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "typeDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "passConfig",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "configType",
                                    "storageKey": null
                                  },
                                  (v2/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "requiredPercentage",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "OnPercentagePassContentConfig",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "ContentTypeDefinition",
                            "abstractKey": "__isContentTypeDefinition"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e9ffe2fa88f077fcc54f202c631b30ee",
    "id": null,
    "metadata": {},
    "name": "PassContentConfigEditor_SetOnPercentagePassContentConfigMutation",
    "operationKind": "mutation",
    "text": "mutation PassContentConfigEditor_SetOnPercentagePassContentConfigMutation(\n  $input: SetOnPercentagePassContentConfigInput!\n) {\n  Admin {\n    Tree {\n      setOnPercentagePassContentConfig(input: $input) {\n        contentNode {\n          ...PassContentConfigEditor_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment OnPercentagePassContentConfigForm_OnPercentagePassContentConfigFragment on OnPercentagePassContentConfig {\n  id\n  configType\n  requiredPercentage\n}\n\nfragment OnPercentagePassContentConfigForm_TreeNodeFragment on TreeNode {\n  id\n}\n\nfragment PassContentConfigEditor_TreeNodeFragment on TreeNode {\n  id\n  typeDefinition {\n    __typename\n    ... on ContentTypeDefinition {\n      __isContentTypeDefinition: __typename\n      passConfig {\n        __typename\n        configType\n        ... on OnPercentagePassContentConfig {\n          requiredPercentage\n        }\n        ...OnPercentagePassContentConfigForm_OnPercentagePassContentConfigFragment\n        id\n      }\n    }\n  }\n  ...OnPercentagePassContentConfigForm_TreeNodeFragment\n}\n"
  }
};
})();

(node as any).hash = "5fcc7d633e159692de56eb5ac4a499b7";

export default node;

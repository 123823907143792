/**
 * @generated SignedSource<<4045a0b5752369e8566eba90ee3482b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentConfigEditor_TreeNodeFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StartContentConfigEditor_TreeNodeFragment" | "PassContentConfigEditor_TreeNodeFragment" | "RestartIfFailedContentConfigEditor_TreeNodeFragment" | "RestartIfPassedContentConfigEditor_TreeNodeFragment" | "RewardContentConfigEditor_TreeNodeFragment">;
  readonly " $fragmentType": "ContentConfigEditor_TreeNodeFragment";
};
export type ContentConfigEditor_TreeNodeFragment = ContentConfigEditor_TreeNodeFragment$data;
export type ContentConfigEditor_TreeNodeFragment$key = {
  readonly " $data"?: ContentConfigEditor_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentConfigEditor_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentConfigEditor_TreeNodeFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StartContentConfigEditor_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PassContentConfigEditor_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RestartIfFailedContentConfigEditor_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RestartIfPassedContentConfigEditor_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RewardContentConfigEditor_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "ad64d04f08743aeee3c9bf54e2cc0b4e";

export default node;

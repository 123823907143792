/**
 * @generated SignedSource<<fb5ef0624aa164d58351f0372f0e709b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AnsweringTypeV2 = "allowOnlyOneAnswer" | "allowMultipleAnswersOnlySomeCorrectMustBeGiven" | "allowMultipleAnswersAllCorrectMustBeGiven" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MultipleChoiceElementForm_MultipleChoiceElementV2Fragment$data = {
  readonly id: string;
  readonly title: string;
  readonly question: string;
  readonly image: {
    readonly id: string;
  } | null;
  readonly answeringType: AnsweringTypeV2;
  readonly answerOptions: ReadonlyArray<{
    readonly answer: string;
    readonly isCorrect: boolean;
    readonly image: {
      readonly id: string;
    } | null;
  }>;
  readonly " $fragmentType": "MultipleChoiceElementForm_MultipleChoiceElementV2Fragment";
};
export type MultipleChoiceElementForm_MultipleChoiceElementV2Fragment = MultipleChoiceElementForm_MultipleChoiceElementV2Fragment$data;
export type MultipleChoiceElementForm_MultipleChoiceElementV2Fragment$key = {
  readonly " $data"?: MultipleChoiceElementForm_MultipleChoiceElementV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MultipleChoiceElementForm_MultipleChoiceElementV2Fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MultipleChoiceElementForm_MultipleChoiceElementV2Fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "question",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answeringType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerOptionV2",
      "kind": "LinkedField",
      "name": "answerOptions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCorrect",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "MultipleChoiceElementV2",
  "abstractKey": null
};
})();

(node as any).hash = "831f2f72fb0e88bb713e1ec8f44c9830";

export default node;

import React from "react";
import styled from "styled-components";

interface OwnProps {
	errortext: string;
}
export const ErrorText = ({ errortext }: OwnProps) => {
	return <ErrorParag>{errortext}</ErrorParag>;
};

const ErrorParag = styled.p`
	color: #e74c3c;
`;

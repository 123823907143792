import { Suspense } from "react";
import { BaseScreen } from "./BaseScreen";
import { ProjectRootNodesSearchContainer } from "../../lms-admin-impl/components/ui/ProjectRootNodesSearchContainer";
import { ProjectRootNodesFilters } from "../../lms-admin-impl/components/ui/ProjectRootNodesFilters";
import { ProjectRootNodesTable } from "../../lms-admin-impl/components/relay/ProjectRootNodesTable";

export const TreesScreen = () => {
	return (
		<BaseScreen>
			<ProjectRootNodesSearchContainer>
				<ProjectRootNodesFilters />
				<Suspense fallback={<div>Lade...</div>}>
					<ProjectRootNodesTable />
				</Suspense>
			</ProjectRootNodesSearchContainer>
		</BaseScreen>
	);
};

import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { TitleColumn_LandingPageFragment$key } from "../../../../__generated__/TitleColumn_LandingPageFragment.graphql";

const LANDING_PAGE_FRAGMENT = graphql`
	fragment TitleColumn_LandingPageFragment on LandingPageV2 {
		title
	}
`;

interface OwnProps {
	landingPageFragmentRef: TitleColumn_LandingPageFragment$key;
}

export const TitleColumn = ({ landingPageFragmentRef }: OwnProps) => {
	const landingPage = useFragment<TitleColumn_LandingPageFragment$key>(
		LANDING_PAGE_FRAGMENT,
		landingPageFragmentRef,
	);

	return <div>{landingPage.title}</div>;
};

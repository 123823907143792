import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { EmailTemplatesScreen_EmailTemplatesQuery } from "../../__generated__/EmailTemplatesScreen_EmailTemplatesQuery.graphql";
import { BaseScreen } from "./BaseScreen";
import { EmailTemplatesTable } from "../components/emails/EmailTemplatesTable";
import { Permission } from "../../__generated__/MainNavigation_Query.graphql";
import { useSelector } from "react-redux";
import { selectHasPermissions } from "../redux/slices/CurrentUserSlice";

const QUERY = graphql`
	query EmailTemplatesScreen_EmailTemplatesQuery {
		...EmailTemplatesTable_EmailTemplatesListFragment
	}
`;

export const EmailTemplatesScreen = () => {
	const data = useLazyLoadQuery<EmailTemplatesScreen_EmailTemplatesQuery>(
		QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);

	const checkPermission = (permissions: Permission[]) =>
		useSelector((state: any) => selectHasPermissions(state, permissions));

	const isAdmin = checkPermission([
		"AccountPermission_System_Root",
		"UserInAccountPermission_System_Owner",
	]);

	if (!isAdmin) {
		return <div>Kein Zugriff</div>;
	}

	return (
		<BaseScreen>
			<h1>E-Mail-Vorlagen</h1>
			<EmailTemplatesTable EmailTemplatesFragmentRef={data} />
		</BaseScreen>
	);
};

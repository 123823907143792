/**
 * @generated SignedSource<<9aa50dbe887a339bbd08d8e8d60d56aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditLandingPageV2Screen_LandingPageV2Fragment$data = {
  readonly id: string;
  readonly title: string;
  readonly description: string;
  readonly urlParam: string;
  readonly image: {
    readonly id: string;
    readonly url: string | null;
  } | null;
  readonly rootNodes: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    } | null> | null;
  };
  readonly " $fragmentType": "EditLandingPageV2Screen_LandingPageV2Fragment";
};
export type EditLandingPageV2Screen_LandingPageV2Fragment = EditLandingPageV2Screen_LandingPageV2Fragment$data;
export type EditLandingPageV2Screen_LandingPageV2Fragment$key = {
  readonly " $data"?: EditLandingPageV2Screen_LandingPageV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditLandingPageV2Screen_LandingPageV2Fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditLandingPageV2Screen_LandingPageV2Fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlParam",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunitiesV2Connection",
      "kind": "LinkedField",
      "name": "rootNodes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnOpportunitiesV2Edge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LearnOpportunityV2",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LandingPageV2",
  "abstractKey": null
};
})();

(node as any).hash = "ab3e9c1e7b1ebf987dc09fc34c17737d";

export default node;

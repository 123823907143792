import React, { ReactNode } from "react";
import { JwtTokenData } from "../redux/slices/AuthSlice";

interface Lms {
	jwtTokenData?: JwtTokenData;
}

export const AuthContext = React.createContext<Lms>({});

type LmsContextProps = {
	children: ReactNode;
	jwtTokenData?: JwtTokenData;
};

export const AuthContextProvider = ({ children, jwtTokenData }: LmsContextProps) => {
	return (
		<AuthContext.Provider
			value={{
				jwtTokenData,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

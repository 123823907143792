/**
 * @generated SignedSource<<1af9e3edd32a8d668e691342bca2e291>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateMessageDefinitionPageInput = {
  internalTitle: string;
  definitionRef: string;
  imageRef?: string | null;
  clientMutationId?: string | null;
};
export type MessageDefinitionPagesOrderList_CreateMessageDefinitionPageMutation$variables = {
  input: CreateMessageDefinitionPageInput;
  connections: ReadonlyArray<string>;
};
export type MessageDefinitionPagesOrderList_CreateMessageDefinitionPageMutationVariables = MessageDefinitionPagesOrderList_CreateMessageDefinitionPageMutation$variables;
export type MessageDefinitionPagesOrderList_CreateMessageDefinitionPageMutation$data = {
  readonly Admin: {
    readonly Messaging: {
      readonly createMessageDefinitionPage: {
        readonly edge: {
          readonly node: {
            readonly id: string;
            readonly internalTitle: string;
            readonly image: {
              readonly id: string;
            } | null;
          };
        };
      } | null;
    };
  };
};
export type MessageDefinitionPagesOrderList_CreateMessageDefinitionPageMutationResponse = MessageDefinitionPagesOrderList_CreateMessageDefinitionPageMutation$data;
export type MessageDefinitionPagesOrderList_CreateMessageDefinitionPageMutation = {
  variables: MessageDefinitionPagesOrderList_CreateMessageDefinitionPageMutationVariables;
  response: MessageDefinitionPagesOrderList_CreateMessageDefinitionPageMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "MessageDefinitionPagesEdge",
  "kind": "LinkedField",
  "name": "edge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MessageDefinitionPage",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "internalTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": [
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageDefinitionPagesOrderList_CreateMessageDefinitionPageMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessagingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Messaging",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateMessageDefinitionPagePayload",
                "kind": "LinkedField",
                "name": "createMessageDefinitionPage",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MessageDefinitionPagesOrderList_CreateMessageDefinitionPageMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessagingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Messaging",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateMessageDefinitionPagePayload",
                "kind": "LinkedField",
                "name": "createMessageDefinitionPage",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "edge",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eef90fd4b7cd3c9dce69d59eca791daf",
    "id": null,
    "metadata": {},
    "name": "MessageDefinitionPagesOrderList_CreateMessageDefinitionPageMutation",
    "operationKind": "mutation",
    "text": "mutation MessageDefinitionPagesOrderList_CreateMessageDefinitionPageMutation(\n  $input: CreateMessageDefinitionPageInput!\n) {\n  Admin {\n    Messaging {\n      createMessageDefinitionPage(input: $input) {\n        edge {\n          node {\n            id\n            internalTitle\n            image {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "24e2e267020988260f1491b1c3d219f3";

export default node;

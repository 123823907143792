import React from "react";
import { useUrlFilterState } from "../core/filters/useUrlFilterState";

export interface PublishedNodesFilterState {
	filterByTitleOrDescriptionOrShortDescription?: string;
}

interface Search {
	filters: PublishedNodesFilterState;
	setFilters: (filters: PublishedNodesFilterState) => void;
	clearFilters: () => void;
}

export const PublishedNodesSearchContext = React.createContext<Search>({
	setFilters: () => {},
	clearFilters: () => {},
	filters: {},
});

interface OwnProps {
	children: any;
}

export const PublishedNodesSearchContainer = ({ children }: OwnProps) => {
	const { state, setState } = useUrlFilterState<PublishedNodesFilterState>();

	return (
		<PublishedNodesSearchContext.Provider
			value={{
				filters: state,
				setFilters: (filters) => {
					setState(filters);
				},
				clearFilters: () => {
					setState({});
				},
			}}
		>
			{children}
		</PublishedNodesSearchContext.Provider>
	);
};

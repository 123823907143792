import { useMatch, useNavigate } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { ConnectionHandler, useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import {
	getEditPointPoolPath,
	POINT_POOL_ID_URL_PARAM,
	POINT_POOLS_PATH,
} from "../../app/routes/PointsModuleRoutes";
import { EditPointPoolScreen_Query } from "../../__generated__/EditPointPoolScreen_Query.graphql";
import { EditPointPoolScreen_PointPoolFragment$key } from "../../__generated__/EditPointPoolScreen_PointPoolFragment.graphql";
import {
	EditPointPoolScreen_CreatePointPoolMutation,
	PointPoolInput,
} from "../../__generated__/EditPointPoolScreen_CreatePointPoolMutation.graphql";
import { EditPointPoolScreen_UpdatePointPoolMutation } from "../../__generated__/EditPointPoolScreen_UpdatePointPoolMutation.graphql";
import { BaseScreen } from "./BaseScreen";
import { EditPointPoolsForm } from "../components/points/pools/EditPointPoolsForm";
import { Permission } from "../../__generated__/MainNavigation_Query.graphql";
import { useSelector } from "react-redux";
import { selectHasPermissions } from "../redux/slices/CurrentUserSlice";

const QUERY = graphql`
	query EditPointPoolScreen_Query($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on PointPool {
				...EditPointPoolScreen_PointPoolFragment
			}
		}
	}
`;

const POINT_POOL_FRAGMENT = graphql`
	fragment EditPointPoolScreen_PointPoolFragment on PointPool {
		id
		name
		description
		triggersExport
	}
`;

const CREATE_POINT_POOL_MUTATION = graphql`
	mutation EditPointPoolScreen_CreatePointPoolMutation(
		$input: CreatePointPoolInput!
		$connections: [ID!]!
	) {
		Admin {
			Points {
				createPointPool(input: $input) {
					data @appendEdge(connections: $connections) {
						node {
							...EditPointPoolScreen_PointPoolFragment
						}
					}
				}
			}
		}
	}
`;

const UPDATE_POINT_POOL_MUTATION = graphql`
	mutation EditPointPoolScreen_UpdatePointPoolMutation($input: UpdatePointPoolInput!) {
		Admin {
			Points {
				updatePointPool(input: $input) {
					data {
						node {
							...EditPointPoolScreen_PointPoolFragment
						}
					}
				}
			}
		}
	}
`;

export const EditPointPoolScreen = () => {
	const navigate = useNavigate();
	const {
		params: { pointPoolId },
	} = useMatch(getEditPointPoolPath(POINT_POOL_ID_URL_PARAM))!;

	const query = useLazyLoadQuery<EditPointPoolScreen_Query>(QUERY, {
		id: pointPoolId!,
		skip: !pointPoolId || pointPoolId === "new",
	});

	const pointPool = useFragment<EditPointPoolScreen_PointPoolFragment$key>(
		POINT_POOL_FRAGMENT,
		query.node || null,
	);

	const [createPointPool, isCreatingPointPool] =
		useMutation<EditPointPoolScreen_CreatePointPoolMutation>(CREATE_POINT_POOL_MUTATION);

	const [updatePointPool, isUpdatingPointPool] =
		useMutation<EditPointPoolScreen_UpdatePointPoolMutation>(UPDATE_POINT_POOL_MUTATION);

	const checkPermission = (permissions: Permission[]) =>
		useSelector((state: any) => selectHasPermissions(state, permissions));

	const isAdmin = checkPermission([
		"AccountPermission_System_Root",
		"UserInAccountPermission_System_Owner",
	]);

	if (!isAdmin) return <div>Kein Zugriff</div>;

	return (
		<BaseScreen>
			<h1 className="mb-3">Bearbeite Punkte-Konto</h1>

			<EditPointPoolsForm
				initialValues={{
					name: pointPool?.name,
					description: pointPool?.description || "",
					triggersExport: pointPool?.triggersExport || false,
				}}
				loading={isCreatingPointPool || isUpdatingPointPool}
				onSubmit={(values) => {
					const data: PointPoolInput = {
						name: values.name!,
						descriptionOpt: values.description!,
						triggersExport: values.triggersExport!,
					};
					if (pointPool) {
						updatePointPool({
							variables: {
								input: {
									id: pointPool.id,
									data: data,
								},
							},
							onCompleted: () => navigate(POINT_POOLS_PATH),
						});
					} else {
						createPointPool({
							variables: {
								input: {
									data: data,
								},
								connections: [
									ConnectionHandler.getConnectionID(
										"client:root:Admin:PointPool",
										"PointPoolsTable_PointPools",
									),
								],
							},
							onCompleted: () => navigate(POINT_POOLS_PATH),
						});
					}
				}}
			/>
		</BaseScreen>
	);
};

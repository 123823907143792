import { CreateRandomMultipleChoiceElementButton } from "../../../app/components/tree/editor/node/content/elearning/CreateRandomMultipleChoiceElementButton";

interface OwnProps {
	eLearningContentNodeId: string;
}

export const ProjectExtendElementButtons = ({ eLearningContentNodeId }: OwnProps) => {
	return (
		<CreateRandomMultipleChoiceElementButton eLearningContentNodeId={eLearningContentNodeId} />
	);
};

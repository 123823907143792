import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ProjectUserDescription_UserFragment$key } from "../../../__generated__/ProjectUserDescription_UserFragment.graphql";
import { UserDescription } from "../../../infecto-lms-admin/components/UserDescription";

const USER_FRAGMENT = graphql`
	fragment ProjectUserDescription_UserFragment on User {
		...UserDescription_UserFragment
	}
`;

interface Props {
	userFragmentRef?: ProjectUserDescription_UserFragment$key | null;
}

export const ProjectUserDescription = ({ userFragmentRef }: Props) => {
	const user = useFragment<ProjectUserDescription_UserFragment$key>(
		USER_FRAGMENT,
		userFragmentRef || null,
	);

	return <UserDescription userFragmentRef={user} />;
};

import { graphql } from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { CreateMultipleChoiceElementButton_CreateMultipleChoiceElementV2Mutation } from "../../../../../../../../__generated__/CreateMultipleChoiceElementButton_CreateMultipleChoiceElementV2Mutation.graphql";
import {
	E_LEARNING_I18N_KEY,
	E_LEARNING_I18N_MAP,
} from "../../../../../../../../lms-admin-impl/i18n/tree.i18n";

const CREATE_MULTIPLE_CHOICE_ELEMENT_MUTATION = graphql`
	mutation CreateMultipleChoiceElementButton_CreateMultipleChoiceElementV2Mutation(
		$input: CreateMultipleChoiceElementV2Input!
	) {
		Admin {
			ElearningV2 {
				createMultipleChoiceElement(input: $input) {
					eLearningContentNode {
						...ContentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface OwnProps {
	eLearningContentNodeId: string;
}

export const CreateMultipleChoiceElementButton = ({ eLearningContentNodeId }: OwnProps) => {
	const [createMultipleChoiceElement, isCreatingMultipleChoiceElement] =
		useMutation<CreateMultipleChoiceElementButton_CreateMultipleChoiceElementV2Mutation>(
			CREATE_MULTIPLE_CHOICE_ELEMENT_MUTATION,
		);

	return (
		<Button
			className={"mr-2"}
			disabled={isCreatingMultipleChoiceElement}
			tooltip={`${E_LEARNING_I18N_MAP(E_LEARNING_I18N_KEY.multipleChoiceElement)} anlegen`}
			icon="pi pi-list"
			onClick={() => {
				createMultipleChoiceElement({
					variables: {
						input: {
							title: "Neues Element",
							question: "",
							answeringType: "allowOnlyOneAnswer",
							answerOptions: [],
							eLearningContentNodeId: eLearningContentNodeId,
						},
					},
				});
			}}
		/>
	);
};

import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Tooltip } from "primereact/tooltip";
import { NodeConfigurationBadges_TreeNodeFragment$key } from "../../../../../__generated__/NodeConfigurationBadges_TreeNodeFragment.graphql";
import { formatDateTime } from "../../../core/datetime/DateTimeDisplay";
import { ProjectExtendNodeConfigurationBadges } from "../../../../../lms-admin-impl/components/relay/ProjectExtendNodeConfigurationBadges";
import { invalidCharacterCSSClassRemover } from "../../../../functions/invalidCharacterCSSClassRemover";

const TREE_NODE_FRAGMENT = graphql`
	fragment NodeConfigurationBadges_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			definitionType
			... on RootStructureDefinition {
				advertisementConfiguration {
					isFeatured
				}
				extension {
					... on RootExtensionImpl {
						displayType
						configConsequences {
							isRewardedTo
						}
					}
				}
			}
		}
		typeDefinition {
			definitionType
			... on ContentTypeDefinition {
				rewards {
					kind
				}
			}
		}
		versioning {
			draftVersion
			releaseVersion
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: NodeConfigurationBadges_TreeNodeFragment$key;
}

export const NodeConfigurationBadges = ({ treeNodeFragmentRef }: OwnProps) => {
	const treeNode = useFragment<NodeConfigurationBadges_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const isRoot = treeNode.structureDefinition.definitionType === "root";

	const isUnreleased = treeNode.versioning.draftVersion - treeNode.versioning.releaseVersion > 0;

	const cleanedNodeId = invalidCharacterCSSClassRemover(treeNode.id);
	const displayTypeSymbol = (() => {
		switch (treeNode.structureDefinition?.extension?.displayType) {
			case "displayedToEveryone":
				return (
					<>
						<span
							className={`displayed-to-everyone-tooltip-${cleanedNodeId} pi pi-eye text-blue-300 pr-1`}
						/>
						<Tooltip
							target={`.displayed-to-everyone-tooltip-${cleanedNodeId}`}
							content="Wird angezeigt"
						/>
					</>
				);
			case "displayedToSpecificUsers":
				return (
					<>
						<span
							className={`displayed-to-specific-users-tooltip-${cleanedNodeId}  pi pi-eye text-blue-200 pr-1`}
						/>
						<Tooltip
							target={`.displayed-to-specific-users-tooltip-${cleanedNodeId} `}
							content="Wird bestimmten Benutzern angezeigt"
						/>
					</>
				);
			case "notDisplayed":
				return (
					<>
						<span
							className={`not-displayed-tooltip-${cleanedNodeId} pi pi-eye-slash text-blue-300 pr-1`}
						/>
						<Tooltip
							target={`.not-displayed-tooltip-${cleanedNodeId}`}
							content="Wird nicht angezeigt"
						/>
					</>
				);
			default:
				return null;
		}
	})();

	return (
		<>
			{isRoot && treeNode.structureDefinition.advertisementConfiguration?.isFeatured && (
				<>
					<span
						className={`is-featured-advertisement-tooltip-${cleanedNodeId} pi pi-star-fill text-blue-300 pr-1`}
					/>
					<Tooltip
						target={`.is-featured-advertisement-tooltip-${cleanedNodeId}`}
						content="Ist gefeaturet"
					/>
				</>
			)}

			{isRoot && treeNode.structureDefinition.extension?.configConsequences?.isRewardedTo && (
				<>
					<span
						className={`expired-config-tooltip-${cleanedNodeId} pi pi-clock text-blue-300 pr-1`}
					/>
					<Tooltip
						target={`.expired-config-tooltip-${cleanedNodeId}`}
						content={`Belohnung läuft ab am ${formatDateTime(
							treeNode.structureDefinition.extension?.configConsequences.isRewardedTo,
						)}`}
					/>
				</>
			)}

			{isUnreleased && (
				<>
					<span
						className={`unreleased-tooltip-${cleanedNodeId} pi pi-exclamation-triangle text-yellow-300 pr-1`}
					/>
					<Tooltip
						target={`.unreleased-tooltip-${cleanedNodeId}`}
						content="Noch nicht veröffentlicht"
					/>
				</>
			)}

			<ProjectExtendNodeConfigurationBadges
				cleanedNodeId={cleanedNodeId}
				treeNodeFragmentRef={treeNodeFragmentRef}
			/>

			{displayTypeSymbol}
		</>
	);
};

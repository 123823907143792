/**
 * @generated SignedSource<<5ad562a7eaa27f0a322498a591601164>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DisplayType = "notDisplayed" | "displayedToSpecificUsers" | "displayedToEveryone" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ActionColumn_TreeNodeFragment$data = {
  readonly id: string;
  readonly hasBeenPublishedOnce: boolean;
  readonly structureDefinition: {
    readonly extension?: {
      readonly displayType?: DisplayType;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"ProjectExtendActionButtons_TreeNodeFragment">;
  readonly " $fragmentType": "ActionColumn_TreeNodeFragment";
};
export type ActionColumn_TreeNodeFragment = ActionColumn_TreeNodeFragment$data;
export type ActionColumn_TreeNodeFragment$key = {
  readonly " $data"?: ActionColumn_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActionColumn_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActionColumn_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasBeenPublishedOnce",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "displayType",
                      "storageKey": null
                    }
                  ],
                  "type": "RootExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectExtendActionButtons_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "c3199ffb3e527ac87f63dd74b4f1b9b3";

export default node;

import { graphql } from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { useFragment, useMutation } from "react-relay";
import { CreateAwardPacPointsRewardButton_AddEmailRewardV2Mutation } from "../../../../__generated__/CreateAwardPacPointsRewardButton_AddEmailRewardV2Mutation.graphql";
import { CreateAwardPacPointsRewardButton_TreeNodeFragment$key } from "../../../../__generated__/CreateAwardPacPointsRewardButton_TreeNodeFragment.graphql";

const MUTATION = graphql`
	mutation CreateAwardPacPointsRewardButton_AddEmailRewardV2Mutation(
		$input: AddPacPointsRewardInput!
	) {
		Admin {
			Tree {
				addPacPointsReward(input: $input) {
					contentNode {
						...RewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const FRAGMENT = graphql`
	fragment CreateAwardPacPointsRewardButton_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ELearningContentTypeDefinition {
				rewards {
					id
					kind
				}
			}
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: CreateAwardPacPointsRewardButton_TreeNodeFragment$key;
}

export const CreateAwardPacPointsRewardButton = ({ treeNodeFragmentRef }: OwnProps) => {
	const node = useFragment<CreateAwardPacPointsRewardButton_TreeNodeFragment$key>(
		FRAGMENT,
		treeNodeFragmentRef,
	);
	const [addReward, isAdding] =
		useMutation<CreateAwardPacPointsRewardButton_AddEmailRewardV2Mutation>(MUTATION);
	const hasMakeFachberaterReward = node.typeDefinition.rewards
		?.map((r) => r.kind)
		.includes("MakeFachberater");

	return (
		<Button
			className={"mr-2"}
			disabled={isAdding || hasMakeFachberaterReward}
			tooltip={
				hasMakeFachberaterReward
					? "Die PAC Punkte Belohnung und Mache zum Fachberater Belohnung können nicht zusammen verwendet werden"
					: "PAC Punkte vergeben"
			}
			tooltipOptions={{ position: "top", showOnDisabled: true }}
			icon="pi pi-box"
			onClick={() => {
				addReward({
					variables: {
						input: {
							contentNodeId: node.id,
							amount: 1,
						},
					},
				});
			}}
		/>
	);
};

import React, { Suspense } from "react";
import { BaseScreen } from "./BaseScreen";
import { UsersWhoMadeContentNodeTable } from "../components/published-node-users/UsersWhoMadeContentNodeTable";

export const UsersWhoMadeContentNodeScreen = () => {
	return (
		<BaseScreen>
			<Suspense fallback={<div>Lade...</div>}>
				<UsersWhoMadeContentNodeTable />
			</Suspense>
		</BaseScreen>
	);
};

import React, { Suspense } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useFragment, useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import {
	ButtonDefinition,
	EditMessageDefinitionButtonSubform,
} from "./EditMessageDefinitionButtonSubform";
import {
	EditMessageDefinitionNotificationDefinitionSubform,
	NotificationDefinition,
} from "./EditMessageDefinitionNotificationDefinitionSubform";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { EditMessageDefinitionForm_MessageDefinitionFragment$key } from "../../../../__generated__/EditMessageDefinitionForm_MessageDefinitionFragment.graphql";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { EditMessageDefinitionForm_EditCoreMutation } from "../../../../__generated__/EditMessageDefinitionForm_EditCoreMutation.graphql";
import { ValidatedField } from "../../core/form/ValidatedField";
import { DefaultTextFieldComponent } from "../../core/form/DefaultTextInput";
import { useTypedDispatch, useTypedSelector } from "../../../redux/Store";
import {
	addEditedFormToEditedFormsArray,
	removeEditedFormFromEditedFormsArray,
	selectArrayOfEditedForms,
} from "../../../redux/slices/CoreSlice";
import { useCallbackPrompt } from "../../../functions/hooks/UseCallBackPrompt";
import { WarningUnsavedChangesDialog } from "../../core/dialog/WarningUnsavedChangesDialog";

const CUSTOMER_MESSAGE_DEFINITION_FRAGMENT = graphql`
	fragment EditMessageDefinitionForm_MessageDefinitionFragment on MessageDefinition {
		id
		internalTitle
		buttonOpt {
			type
			title
			... on MessageDefinitionLinkButton {
				link
			}
		}
		showUntilOpt
		notificationDefinitionOpt {
			title
			body
		}
	}
`;

const EDIT_CORE_MUTATION = graphql`
	mutation EditMessageDefinitionForm_EditCoreMutation($input: EditMessageDefinitionInput!) {
		Admin {
			Messaging {
				editMessageDefinition(input: $input) {
					edge {
						node {
							...EditMessageDefinitionForm_MessageDefinitionFragment
						}
					}
				}
			}
		}
	}
`;

interface FormState {
	internalTitle: string;
	button?: ButtonDefinition;
	showUntil?: string;
	notificationDefinition?: NotificationDefinition;
}

const REGEX_FOR_HTML_LINK = new RegExp(
	"https://(www\\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)",
);

interface OwnProps {
	messageDefinitionFragmentRef: EditMessageDefinitionForm_MessageDefinitionFragment$key;
}

export const EditMessageDefinitionForm = ({ messageDefinitionFragmentRef }: OwnProps) => {
	const messageDefinition = useFragment<EditMessageDefinitionForm_MessageDefinitionFragment$key>(
		CUSTOMER_MESSAGE_DEFINITION_FRAGMENT,
		messageDefinitionFragmentRef,
	);

	const [editCoreRaw, isEditingCore] =
		useMutation<EditMessageDefinitionForm_EditCoreMutation>(EDIT_CORE_MUTATION);

	const dispatch = useTypedDispatch();
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);
	const formId = "EditMessageDefinitionForm";

	const [showPrompt, confirmNavigation, cancelNavigation, setShowPrompt] = useCallbackPrompt(
		arrayOfEditedForms.length > 0,
	);
	const formik = useFormik<FormState>({
		initialValues: {
			internalTitle: messageDefinition.internalTitle,
			button: messageDefinition.buttonOpt || { type: "none" },
			showUntil: messageDefinition.showUntilOpt
				? (messageDefinition.showUntilOpt as string)
				: undefined,
			notificationDefinition: messageDefinition.notificationDefinitionOpt
				? ({
						title: messageDefinition.notificationDefinitionOpt.title,
						body: messageDefinition.notificationDefinitionOpt.body,
				  } as NotificationDefinition)
				: undefined,
		},
		validationSchema: Yup.object().shape({
			internalTitle: Yup.string().required("Interner Titel wird benötigt."),
			button: Yup.object().test("", "", function (value) {
				const buttonDefinition = value as any as ButtonDefinition;
				if (buttonDefinition.type === "link" || buttonDefinition.type === "confirm") {
					if (!buttonDefinition.title)
						return this.createError({
							message: "Das Feld Titel wird benötigt.",
						});
				}

				if (buttonDefinition.type === "link") {
					if (!buttonDefinition.link?.match(REGEX_FOR_HTML_LINK))
						return this.createError({
							message: "Das Feld Link wird benötigt",
						});
				}

				return true;
			}),
			notificationDefinition: Yup.object().test("", "", function (value) {
				const typedValue = value as any as NotificationDefinition;

				if (!typedValue) return true;

				if (
					!typedValue.title ||
					typedValue.title.length < 10 ||
					typedValue.title.length > 65
				)
					return this.createError({
						message: "Der Titel muss zwischen 10 und 65 Zeichen lang sein.",
					});

				if (!typedValue.body || typedValue.body.length < 10 || typedValue.body.length > 178)
					return this.createError({
						message: "Die Nachricht muss zwischen 10 und 178 Zeichen lang sein.",
					});

				return true;
			}),
		}),
		onSubmit: (values, { setSubmitting }) => {
			editCoreRaw({
				variables: {
					input: {
						id: messageDefinition.id,
						internalTitle: values.internalTitle,
						buttonOpt:
							values.button?.type === "link" || values.button?.type === "confirm"
								? {
										buttonType: values.button.type,
										link: values.button.link,
										title: values.button.title!,
								  }
								: undefined,
						showUntilOpt: values.showUntil,
						notificationDefinitionOpt: values.notificationDefinition,
					},
				},
				onCompleted: () => {
					dispatch(removeEditedFormFromEditedFormsArray({ form: formId }));
				},
			});
			setSubmitting(false);
		},
	});

	return (
		<Card>
			<Suspense fallback={<ProgressSpinner />}>
				{!isEditingCore ? (
					<>
						{" "}
						{showPrompt && (
							<WarningUnsavedChangesDialog
								confirmNavigation={confirmNavigation}
								setShowDialog={setShowPrompt}
								cancelNavigation={cancelNavigation}
							/>
						)}
						<form onSubmit={formik.handleSubmit} className="p-fluid">
							<ValidatedField<FormState, string>
								name={"internalTitle"}
								label={"Interner Titel"}
								required={true}
								onChange={() =>
									dispatch(addEditedFormToEditedFormsArray({ form: formId }))
								}
								component={DefaultTextFieldComponent}
								formikConfig={formik}
							/>

							<ValidatedField<FormState, ButtonDefinition>
								name={"button"}
								label={"Button"}
								onChange={() =>
									dispatch(addEditedFormToEditedFormsArray({ form: formId }))
								}
								component={({ fieldValue, updateField, onChange }) => (
									<EditMessageDefinitionButtonSubform
										fieldValue={fieldValue}
										updateField={updateField}
										onChange={onChange}
									/>
								)}
								formikConfig={formik}
							/>

							<ValidatedField<FormState, string>
								name={"showUntil"}
								label={"Zeigen bis"}
								component={({
									fieldName,
									fieldValue,
									updateField,
								}: {
									fieldName: string;
									fieldValue: string | undefined;
									updateField: (showUntil?: string) => void;
								}) => {
									return (
										<Calendar
											name={fieldName}
											dateFormat={"dd.mm.yy"}
											value={
												fieldValue ? moment(fieldValue).toDate() : undefined
											}
											onChange={(e) => {
												updateField(
													e.value
														? moment(e.value as Date).format(
																"YYYY-MM-DD",
														  )
														: undefined,
												);
												dispatch(
													addEditedFormToEditedFormsArray({
														form: formId,
													}),
												);
											}}
										/>
									);
								}}
								formikConfig={formik}
							/>

							<ValidatedField<FormState, NotificationDefinition>
								name={"notificationDefinition"}
								label={"Benachrichtigung"}
								onChange={() =>
									dispatch(addEditedFormToEditedFormsArray({ form: formId }))
								}
								component={({ fieldValue, updateField, onChange }) => (
									<EditMessageDefinitionNotificationDefinitionSubform
										fieldValue={fieldValue}
										updateField={updateField}
										onChange={onChange}
									/>
								)}
								formikConfig={formik}
							/>

							<div className="flex pt-2">
								<Button
									disabled={false}
									type="submit"
									label={"Speichern"}
									className="mr-5"
								/>
							</div>
						</form>
					</>
				) : (
					<ProgressSpinner />
				)}
			</Suspense>
		</Card>
	);
};

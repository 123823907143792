/**
 * @generated SignedSource<<81bb55410fd06802eaaa26017eaaf32c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PointPoolDropdown_Query$variables = {
  filterByName?: string | null;
  first: number;
  after?: string | null;
};
export type PointPoolDropdown_QueryVariables = PointPoolDropdown_Query$variables;
export type PointPoolDropdown_Query$data = {
  readonly Admin: {
    readonly Points: {
      readonly PointPools: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly name: string;
          };
        } | null> | null;
      };
    };
  };
};
export type PointPoolDropdown_QueryResponse = PointPoolDropdown_Query$data;
export type PointPoolDropdown_Query = {
  variables: PointPoolDropdown_QueryVariables;
  response: PointPoolDropdown_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByName"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminQueryType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PointsAdminSchema",
        "kind": "LinkedField",
        "name": "Points",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "after",
                "variableName": "after"
              },
              {
                "kind": "Variable",
                "name": "filterByName",
                "variableName": "filterByName"
              },
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
              }
            ],
            "concreteType": "PointsPoolsConnection",
            "kind": "LinkedField",
            "name": "PointPools",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PointsPoolsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PointPool",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PointPoolDropdown_Query",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PointPoolDropdown_Query",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "37976b5a83926d35733112882fb95466",
    "id": null,
    "metadata": {},
    "name": "PointPoolDropdown_Query",
    "operationKind": "query",
    "text": "query PointPoolDropdown_Query(\n  $filterByName: String\n  $first: Int!\n  $after: String\n) {\n  Admin {\n    Points {\n      PointPools(first: $first, after: $after, filterByName: $filterByName) {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "95c21ccd7ba3f4ad6bdfc6f6647f2934";

export default node;

import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useLazyLoadQuery, useSubscription } from "react-relay";
import { useMatch } from "react-router-dom";
import { Suspense, useMemo } from "react";
import { GraphQLSubscriptionConfig } from "relay-runtime";
import { Card } from "primereact/card";
import { TreeEditorScreen_Query } from "../../__generated__/TreeEditorScreen_Query.graphql";
import { TreeEditorScreen_TreeFragment$key } from "../../__generated__/TreeEditorScreen_TreeFragment.graphql";
import { TreeEditorScreen_Subscription } from "../../__generated__/TreeEditorScreen_Subscription.graphql";
import { BaseScreenNoBackground } from "../../infecto-lms-admin/screens/BaseScreen";
import { PublishingPart } from "../../infecto-lms-admin/components/tree/editor/publishing/PublishingPart";
import {
	CHILD_NODE_ID_URL_PARAM,
	getTreeEditorChildNodePath,
	getTreeEditorChildNodeWithPagePath,
	getTreeEditorPath,
	getTreeEditorWithPagePath,
	PAGE_URL_PARAM,
	ROOT_NODE_ID_URL_PARAM,
} from "../routes/GraphEditorRoutes";
import { NodePart } from "../components/tree/editor/node/NodePart";
import { TreeSelectionContainer } from "../components/tree/tree/TreeSelectionContext";
import { TreeContainer } from "../components/tree/editor/TreeContainer";
import { TreeEditorScreen_PublishingQuery } from "../../__generated__/TreeEditorScreen_PublishingQuery.graphql";

const QUERY = graphql`
	query TreeEditorScreen_Query($input: ID!) {
		Admin {
			Tree {
				Tree(rootId: $input) {
					...TreeEditorScreen_TreeFragment
				}
			}
		}
	}
`;

const PUBLISHING_QUERY = graphql`
	query TreeEditorScreen_PublishingQuery($input: ID!) {
		Admin {
			PublishingV2 {
				ActivePublishing(rootId: $input) {
					status
					...PublishingPart_PublishingV2Fragment
				}
			}
		}
	}
`;

const TREE_FRAGMENT = graphql`
	fragment TreeEditorScreen_TreeFragment on Tree {
		...TreeContainer_TreeFragment
	}
`;

const SUBSCRIPTION = graphql`
	subscription TreeEditorScreen_Subscription {
		subscribeToPublishingUpdatesV2 {
			tree {
				id
				...TreeEditorScreen_TreeFragment
			}
			publishing {
				...PublishingPart_PublishingV2Fragment
			}
		}
	}
`;

export const TreeEditorScreen = () => {
	const match = useMatch(getTreeEditorPath(ROOT_NODE_ID_URL_PARAM));
	const matchWithChildNodeId = useMatch(
		getTreeEditorChildNodePath(ROOT_NODE_ID_URL_PARAM, CHILD_NODE_ID_URL_PARAM),
	);

	const matchPage = useMatch(getTreeEditorWithPagePath(ROOT_NODE_ID_URL_PARAM, PAGE_URL_PARAM));
	const matchWithChildNodeIdPage = useMatch(
		getTreeEditorChildNodeWithPagePath(
			ROOT_NODE_ID_URL_PARAM,
			CHILD_NODE_ID_URL_PARAM,
			PAGE_URL_PARAM,
		),
	);

	const rootNodeId =
		match?.params.rootNodeId ||
		matchPage?.params.rootNodeId ||
		matchWithChildNodeId?.params.rootNodeId ||
		matchWithChildNodeIdPage?.params.rootNodeId;
	const childNodeId =
		matchWithChildNodeId?.params.childNodeId || matchWithChildNodeIdPage?.params.childNodeId;

	const query = useLazyLoadQuery<TreeEditorScreen_Query>(
		QUERY,
		{ input: rootNodeId! },
		{ fetchPolicy: "network-only" },
	);

	const publishingQuery = useLazyLoadQuery<TreeEditorScreen_PublishingQuery>(
		PUBLISHING_QUERY,
		{ input: rootNodeId! },
		{ fetchPolicy: "network-only" },
	);

	const tree = useFragment<TreeEditorScreen_TreeFragment$key>(
		TREE_FRAGMENT,
		query.Admin.Tree.Tree,
	);

	const config = useMemo<GraphQLSubscriptionConfig<TreeEditorScreen_Subscription>>(
		() => ({
			variables: {},
			subscription: SUBSCRIPTION,
		}),
		[],
	);

	useSubscription<TreeEditorScreen_Subscription>(config);

	return (
		<TreeSelectionContainer
			treeId={rootNodeId!}
			initiallySelectedNodeId={rootNodeId || childNodeId}
		>
			<div className="grid flex-grow-1 surface-50">
				<div className="col-4 flex flex-column pl-4 mt-5">
					<TreeContainer treeFragmentRef={tree} />
				</div>
				<div className="col-8 flex">
					<Suspense
						fallback={
							<BaseScreenNoBackground>
								<Card>Lade...</Card>
							</BaseScreenNoBackground>
						}
					>
						{publishingQuery.Admin.PublishingV2.ActivePublishing &&
						publishingQuery.Admin.PublishingV2.ActivePublishing.status !==
							"acknowledged" ? (
							<PublishingPart
								publishingFragmentRef={
									publishingQuery.Admin.PublishingV2.ActivePublishing
								}
							/>
						) : (
							<NodePart />
						)}
					</Suspense>
				</div>
			</div>
		</TreeSelectionContainer>
	);
};

import { graphql } from "babel-plugin-relay/macro";
import { commitMutation, Environment } from "react-relay";
import {
	AddOnlyIfTreeStateOrAdminVisibilityTreeConfigInput,
	OnlyIfTreeStateOrAdminVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation,
} from "../../../../../../../../__generated__/OnlyIfTreeStateOrAdminVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation.graphql";

const ADD_INFECTOPHARM_ONLY_TREESTATE_VISIBILITY_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation OnlyIfTreeStateOrAdminVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation(
		$input: AddOnlyIfTreeStateOrAdminVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				AddOnlyIfTreeStateOrAdminVisibilityTreeConfig(input: $input) {
					rootNode {
						... on TreeNode {
							id
							structureDefinition {
								... on RootStructureDefinition {
									visibilityConfigs {
										... on OnlyIfTreeStateOrAdminVisibilityTreeConfig {
											id
											configType
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

function CommitAddInfectopharmOnlyIfTreeStateOrAdminVisibilityConfig(
	environment: Environment,
	input: AddOnlyIfTreeStateOrAdminVisibilityTreeConfigInput,
) {
	return commitMutation<OnlyIfTreeStateOrAdminVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation>(
		environment,
		{
			mutation: ADD_INFECTOPHARM_ONLY_TREESTATE_VISIBILITY_CONDITION_CONFIGURATION_MUTATION,
			variables: { input },
			onCompleted: () => {},
			onError: () => {},
		},
	);
}

export { CommitAddInfectopharmOnlyIfTreeStateOrAdminVisibilityConfig as AddInfectopharmOnlyIfTreeStateOrAdminVisibilityConfig };

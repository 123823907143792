export const ExtendErrorMessagesI18n: { [key: string]: string } = {
	tree_no_container_code_specified:
		"Es muss mindestens die 1. Veranstaltungsnummer (inkl. Zeitraum) angegeben werden.",
	tree_no_container_code_validity_range_specified:
		"Für die 1. Veranstaltungsnummer muss ein Zeitraum 'von - bis' angegeben werden.",
	tree_bad_container_code_validity_range:
		"Für die 1. Veranstaltungsnummer darf das 'bis' Datum nicht vor dem 'von' Datum liegen.",
	tree_no_container_code_two_specified:
		"Es muss ein Wert für 2. Veranstaltungsnummer angegeben werden.",
	tree_no_container_code_two_validity_range_specified:
		"Für die 2. Veranstaltungsnummer muss ein Zeitraum 'von - bis' angegeben werden.",
	tree_bad_container_code_two_validity_range:
		"Für die 2. Veranstaltungsnummer darf das 'bis' Datum nicht vor dem 'von' Datum liegen.",
	tree_no_primary_container_code_specified:
		"Es muss zurest die 1. Veranstaltungsnummer (inkl. Zeitraum) angegeben werden.",
};

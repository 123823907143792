/**
 * @generated SignedSource<<f5f4803e7a094d59adfa4ed26e687eae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditPodigeePodcastElementV2Input = {
  title: string;
  podigeeUrl: string;
  lengthInSeconds: number;
  podigeePodcastElementId: string;
  clientMutationId?: string | null;
};
export type PodigeePodcastElementForm_EditPodigeePodcastElementV2Mutation$variables = {
  input: EditPodigeePodcastElementV2Input;
};
export type PodigeePodcastElementForm_EditPodigeePodcastElementV2MutationVariables = PodigeePodcastElementForm_EditPodigeePodcastElementV2Mutation$variables;
export type PodigeePodcastElementForm_EditPodigeePodcastElementV2Mutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly editPodigeePodcastElement: {
        readonly podigeePodcastElement: {
          readonly " $fragmentSpreads": FragmentRefs<"PodigeePodcastElementForm_PodcastElementV2Fragment">;
        };
      } | null;
    };
  };
};
export type PodigeePodcastElementForm_EditPodigeePodcastElementV2MutationResponse = PodigeePodcastElementForm_EditPodigeePodcastElementV2Mutation$data;
export type PodigeePodcastElementForm_EditPodigeePodcastElementV2Mutation = {
  variables: PodigeePodcastElementForm_EditPodigeePodcastElementV2MutationVariables;
  response: PodigeePodcastElementForm_EditPodigeePodcastElementV2Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PodigeePodcastElementForm_EditPodigeePodcastElementV2Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditPodigeePodcastElementV2Payload",
                "kind": "LinkedField",
                "name": "editPodigeePodcastElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PodcastElementV2",
                    "kind": "LinkedField",
                    "name": "podigeePodcastElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "PodigeePodcastElementForm_PodcastElementV2Fragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PodigeePodcastElementForm_EditPodigeePodcastElementV2Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditPodigeePodcastElementV2Payload",
                "kind": "LinkedField",
                "name": "editPodigeePodcastElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PodcastElementV2",
                    "kind": "LinkedField",
                    "name": "podigeePodcastElement",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "podcastSource",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sourceType",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lengthInSeconds",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "podigeeUrl",
                                "storageKey": null
                              }
                            ],
                            "type": "PodigeePodcastSourceV2",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2af451d0a4bd531b2907549b3b732657",
    "id": null,
    "metadata": {},
    "name": "PodigeePodcastElementForm_EditPodigeePodcastElementV2Mutation",
    "operationKind": "mutation",
    "text": "mutation PodigeePodcastElementForm_EditPodigeePodcastElementV2Mutation(\n  $input: EditPodigeePodcastElementV2Input!\n) {\n  Admin {\n    ElearningV2 {\n      editPodigeePodcastElement(input: $input) {\n        podigeePodcastElement {\n          ...PodigeePodcastElementForm_PodcastElementV2Fragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment PodigeePodcastElementForm_PodcastElementV2Fragment on PodcastElementV2 {\n  id\n  title\n  podcastSource {\n    __typename\n    sourceType\n    ... on PodigeePodcastSourceV2 {\n      lengthInSeconds\n      podigeeUrl\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9acf896fabf8bce4b0d1d64df71e4965";

export default node;

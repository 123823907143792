import { graphql } from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { CreateRandomMultipleChoiceElementButton_CreateRandomMultipleChoiceElementMutation } from "../../../../../../../__generated__/CreateRandomMultipleChoiceElementButton_CreateRandomMultipleChoiceElementMutation.graphql";

const CREATE_RANDOM_MULTIPLE_CHOICE_ELEMENT_MUTATION = graphql`
	mutation CreateRandomMultipleChoiceElementButton_CreateRandomMultipleChoiceElementMutation(
		$input: CreateRandomMultipleChoiceElementInput!
	) {
		Admin {
			ElearningV2 {
				createRandomMultipleChoiceElement(input: $input) {
					eLearningContentNode {
						...ContentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface OwnProps {
	eLearningContentNodeId: string;
}

export const CreateRandomMultipleChoiceElementButton = ({ eLearningContentNodeId }: OwnProps) => {
	const [createMultipleChoiceElement, isCreatingMultipleChoiceElement] =
		useMutation<CreateRandomMultipleChoiceElementButton_CreateRandomMultipleChoiceElementMutation>(
			CREATE_RANDOM_MULTIPLE_CHOICE_ELEMENT_MUTATION,
		);

	return (
		<Button
			className={"mr-2"}
			disabled={isCreatingMultipleChoiceElement}
			tooltip={`Zufällige-Fragen-Baustein anlegen`}
			icon="pi pi-inbox"
			onClick={() => {
				createMultipleChoiceElement({
					variables: {
						input: {
							eLearningContentNodeId,
							count: 10,
						},
					},
				});
			}}
		/>
	);
};

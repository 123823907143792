/**
 * @generated SignedSource<<64e18fbb46f26b9f99ce0c8501dc33f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LandingPagesV2Table_QueryFragment$data = {
  readonly Admin: {
    readonly LandingPageV2: {
      readonly LandingPages: {
        readonly __id: string;
        readonly pageInfo: {
          readonly endCursor: string | null;
          readonly hasPreviousPage: boolean;
          readonly hasNextPage: boolean;
          readonly startCursor: string | null;
        };
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"LandingPagesV2Table_LandingPageFragment">;
          };
        } | null> | null;
      };
    };
  };
  readonly " $fragmentType": "LandingPagesV2Table_QueryFragment";
};
export type LandingPagesV2Table_QueryFragment = LandingPagesV2Table_QueryFragment$data;
export type LandingPagesV2Table_QueryFragment$key = {
  readonly " $data"?: LandingPagesV2Table_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LandingPagesV2Table_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Admin",
  "LandingPageV2",
  "LandingPages"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./LandingPagesV2Table_Refetch.graphql')
    }
  },
  "name": "LandingPagesV2Table_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LandingPageV2AdminSchema",
          "kind": "LinkedField",
          "name": "LandingPageV2",
          "plural": false,
          "selections": [
            {
              "alias": "LandingPages",
              "args": null,
              "concreteType": "LandingPageV2Connection",
              "kind": "LinkedField",
              "name": "__LandingPagesV2Table_LandingPages_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasPreviousPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startCursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LandingPageV2Edge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LandingPageV2",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "LandingPagesV2Table_LandingPageFragment",
                          "selections": [
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "ActionColumn_LandingPageFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "ContentsColumn_LandingPageFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "DescriptionColumn_LandingPageFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "TitleColumn_LandingPageFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "UrlPathColumn_LandingPageFragment"
                            }
                          ]
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__id",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "669cee5d5fe0a6a025fb131d679835e0";

export default node;

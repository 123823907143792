/**
 * @generated SignedSource<<974f6effee52252a24916390f7a296d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TitleColumn_LandingPageFragment$data = {
  readonly title: string;
  readonly " $fragmentType": "TitleColumn_LandingPageFragment";
};
export type TitleColumn_LandingPageFragment = TitleColumn_LandingPageFragment$data;
export type TitleColumn_LandingPageFragment$key = {
  readonly " $data"?: TitleColumn_LandingPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TitleColumn_LandingPageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TitleColumn_LandingPageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "LandingPageV2",
  "abstractKey": null
};

(node as any).hash = "5f96ea2091b7bae82f03e2b6520a03b9";

export default node;

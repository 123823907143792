/**
 * @generated SignedSource<<87db819708571f8bdf4c76f9bc98da5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CertificateTemplateSelectionField_Query$variables = {};
export type CertificateTemplateSelectionField_QueryVariables = CertificateTemplateSelectionField_Query$variables;
export type CertificateTemplateSelectionField_Query$data = {
  readonly Admin: {
    readonly Certificates: {
      readonly CertificateTemplates: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly name: string;
          };
        } | null> | null;
      };
    };
  };
};
export type CertificateTemplateSelectionField_QueryResponse = CertificateTemplateSelectionField_Query$data;
export type CertificateTemplateSelectionField_Query = {
  variables: CertificateTemplateSelectionField_QueryVariables;
  response: CertificateTemplateSelectionField_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminQueryType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CertificatesAdminSchema",
        "kind": "LinkedField",
        "name": "Certificates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CertificateTemplatesConnection",
            "kind": "LinkedField",
            "name": "CertificateTemplates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CertificateTemplatesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CertificateTemplate",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CertificateTemplateSelectionField_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CertificateTemplateSelectionField_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "9d432890d2161c6c15b37fae8c6f510f",
    "id": null,
    "metadata": {},
    "name": "CertificateTemplateSelectionField_Query",
    "operationKind": "query",
    "text": "query CertificateTemplateSelectionField_Query {\n  Admin {\n    Certificates {\n      CertificateTemplates {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1a54bed3f47f4b0a6982a98bc18d3ed6";

export default node;

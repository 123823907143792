/**
 * @generated SignedSource<<5cf6119bfd0ed10b903b5040dd286247>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VisibilityTreeConfigType = "VisibilityTree_OnlyIfTreeStateOrAdmin" | "VisibilityTree_OnlyAdmins" | "VisibilityTree_OnlyBasicSeminar" | "VisibilityTree_OnlyFachrichtungen" | "VisibilityTree_AfterDateTime" | "VisibilityTree_OnlySpecificPermissions" | "VisibilityTree_HideForFachberater" | "VisibilityTree_Hide" | "VisibilityTree_OnlyFachberater" | "VisibilityTree_NotAfterDateTime" | "VisibilityTree_OnlyIfTreeState" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectExtendVisibilityConfigEditDialogs_VisibilityTreeConfigFragment$data = {
  readonly configType: VisibilityTreeConfigType;
  readonly " $fragmentSpreads": FragmentRefs<"OnlySpecializationVisibilityConfigForm_VisibilityTreeConfigFragment" | "OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment">;
  readonly " $fragmentType": "ProjectExtendVisibilityConfigEditDialogs_VisibilityTreeConfigFragment";
};
export type ProjectExtendVisibilityConfigEditDialogs_VisibilityTreeConfigFragment = ProjectExtendVisibilityConfigEditDialogs_VisibilityTreeConfigFragment$data;
export type ProjectExtendVisibilityConfigEditDialogs_VisibilityTreeConfigFragment$key = {
  readonly " $data"?: ProjectExtendVisibilityConfigEditDialogs_VisibilityTreeConfigFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectExtendVisibilityConfigEditDialogs_VisibilityTreeConfigFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectExtendVisibilityConfigEditDialogs_VisibilityTreeConfigFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "configType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OnlySpecializationVisibilityConfigForm_VisibilityTreeConfigFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment"
    }
  ],
  "type": "VisibilityTreeConfig",
  "abstractKey": "__isVisibilityTreeConfig"
};

(node as any).hash = "2f8c2ae863d967700af03cca63a2fd3d";

export default node;

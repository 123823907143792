/**
 * @generated SignedSource<<d21bfbd40efcdc0c890e561301b86d86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReceivedAtColumn_PointsAdditionFragment$data = {
  readonly receivedAt: string;
  readonly " $fragmentType": "ReceivedAtColumn_PointsAdditionFragment";
};
export type ReceivedAtColumn_PointsAdditionFragment = ReceivedAtColumn_PointsAdditionFragment$data;
export type ReceivedAtColumn_PointsAdditionFragment$key = {
  readonly " $data"?: ReceivedAtColumn_PointsAdditionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReceivedAtColumn_PointsAdditionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReceivedAtColumn_PointsAdditionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receivedAt",
      "storageKey": null
    }
  ],
  "type": "PointsAdditionV2",
  "abstractKey": null
};

(node as any).hash = "e437d05ed8ee1143582f07aff4cfb94c";

export default node;

import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { RootNodeTitleColumn_ContentSubmissionFragment$key } from "../../../../__generated__/RootNodeTitleColumn_ContentSubmissionFragment.graphql";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment RootNodeTitleColumn_ContentSubmissionFragment on ContentSubmission {
		learnOpportunity {
			root {
				structureDefinition {
					title
				}
			}
		}
	}
`;

interface OwnProps {
	contentSubmissionFragmentRef: RootNodeTitleColumn_ContentSubmissionFragment$key;
}

export const RootNodeTitleColumn = ({ contentSubmissionFragmentRef }: OwnProps) => {
	const contentSubmission = useFragment<RootNodeTitleColumn_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	return <div>{contentSubmission.learnOpportunity?.root?.structureDefinition.title}</div>;
};

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import graphql from "babel-plugin-relay/macro";
import { useMutation, usePaginationFragment } from "react-relay";
import { InstructorsTable_Refetch } from "../../../__generated__/InstructorsTable_Refetch.graphql";
import { InstructorsTable_InstructorsListFragment$key } from "../../../__generated__/InstructorsTable_InstructorsListFragment.graphql";
import { useNavigate } from "react-router-dom";
import { InstructorsTable_DeleteMutation } from "../../../__generated__/InstructorsTable_DeleteMutation.graphql";
import { useDialogLogic } from "../core/dialog/useDialogLogic";
import { Permission } from "../../../__generated__/MainNavigation_Query.graphql";
import { useSelector } from "react-redux";
import { selectHasPermissions } from "../../redux/slices/CurrentUserSlice";

const BRANDS_FRAGMENT = graphql`
	fragment InstructorsTable_InstructorsListFragment on Query
	@refetchable(queryName: "InstructorsTable_Refetch")
	@argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
		Admin {
			Instructor {
				InstructorImpls(first: $first, after: $after)
					@connection(key: "InstructorsTable_InstructorImpls") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							superId
							name
							location
							title
							signatureFile {
								id
								url
							}
						}
					}
				}
			}
		}
	}
`;

const DELETE_MUTATION = graphql`
	mutation InstructorsTable_DeleteMutation(
		$input: DeleteInstructorImplInput!
		$connections: [ID!]!
	) {
		Admin {
			Instructor {
				deleteInstructorImpl(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;

interface OwnProps {
	instructorsFragmentRef: InstructorsTable_InstructorsListFragment$key;
}

export const InstructorsTable = ({ instructorsFragmentRef }: OwnProps) => {
	const navigate = useNavigate();
	const {
		data: Admin,
		hasPrevious,
		hasNext,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<
		InstructorsTable_Refetch,
		InstructorsTable_InstructorsListFragment$key
	>(BRANDS_FRAGMENT, instructorsFragmentRef);
	const instructors = Admin.Admin.Instructor.InstructorImpls.edges;
	const [deleteInstructor, isDeleting] =
		useMutation<InstructorsTable_DeleteMutation>(DELETE_MUTATION);
	const { showDialog, dialogComponent } = useDialogLogic();

	const checkPermissions = (permissions: Permission[]) =>
		useSelector((state: any) => selectHasPermissions(state, permissions));

	const hasPermissions =
		checkPermissions([
			"AccountPermission_System_Root",
			"UserInAccountPermission_System_Owner",
		]) ||
		checkPermissions([
			"AccountPermission_System_Root",
			"UserInAccountPermission_Instructors_UpdateInstructors",
		]);

	return (
		<>
			{dialogComponent}
			{hasPermissions && (
				<div className="flex justify-content-end mb-3">
					<Button onClick={() => navigate("/instructors/new/edit")}>
						Neuer Lehrender
					</Button>
				</div>
			)}
			<DataTable
				emptyMessage="Keine Lehrenden"
				className="mb-3"
				value={instructors?.map((b) => b!.node!) as any[]}
			>
				<Column header="Name" field={"name"} />
				<Column header="Titel" field={"title"} />
				<Column header="Wirkungsort" field={"location"} />

				{hasPermissions && (
					<Column
						header="Aktionen"
						style={{ width: "20%" }}
						body={(item) => (
							<>
								<Button
									className="mr-2"
									onClick={() => {
										navigate(`/instructors/${item.superId}/edit`);
									}}
									icon={"pi pi-pencil"}
								/>

								<Button
									disabled={isDeleting}
									onClick={() => {
										showDialog({
											title: "Lehrender löschen",
											content:
												"Möchten Sie diesen Lehrenden wirklich löschen? Das kann nicht rückgängig gemacht werden.",
											dialogCallback: (result) => {
												if (result === "Accept") {
													deleteInstructor({
														variables: {
															input: {
																ids: [item.id],
															},
															connections: [
																Admin.Admin.Instructor
																	.InstructorImpls.__id,
															],
														},
													});
												}
											},
										});
									}}
									icon={"pi pi-trash"}
								/>
							</>
						)}
					/>
				)}
			</DataTable>
			<div className="flex justify-content-center align-items-center">
				<Button
					disabled={!hasPrevious}
					onClick={() => loadPrevious(20)}
					className="mr-3 p-button-secondary"
				>
					Zurück
				</Button>
				<Button
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={() => loadNext(20)}
				>
					Weiter
				</Button>
			</div>
		</>
	);
};

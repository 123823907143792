import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ValidatedField } from "../../../../../../core/form/ValidatedField";
import { DefaultTextEditorComponent } from "../../../../../../core/form/DefaultTextEditorComponent";
import { PodigeePodcastElementForm_PodcastElementV2Fragment$key } from "../../../../../../../../__generated__/PodigeePodcastElementForm_PodcastElementV2Fragment.graphql";
import { PodigeePodcastElementForm_EditPodigeePodcastElementV2Mutation } from "../../../../../../../../__generated__/PodigeePodcastElementForm_EditPodigeePodcastElementV2Mutation.graphql";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../../../../../redux/slices/CoreSlice";
import { useTypedDispatch } from "../../../../../../../redux/Store";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React, { useRef, useState } from "react";

const PODCAST_ELEMENT_FRAGMENT = graphql`
	fragment PodigeePodcastElementForm_PodcastElementV2Fragment on PodcastElementV2 {
		id
		title
		podcastSource {
			sourceType
			... on PodigeePodcastSourceV2 {
				lengthInSeconds
				podigeeUrl
			}
		}
	}
`;

const EDIT_PODIGEE_PODCAST_ELEMENT_MUTATION = graphql`
	mutation PodigeePodcastElementForm_EditPodigeePodcastElementV2Mutation(
		$input: EditPodigeePodcastElementV2Input!
	) {
		Admin {
			ElearningV2 {
				editPodigeePodcastElement(input: $input) {
					podigeePodcastElement {
						...PodigeePodcastElementForm_PodcastElementV2Fragment
					}
				}
			}
		}
	}
`;

interface FormState {
	title: string;
	podigeeUrl?: string;
	lengthInSeconds: number;
}

interface OwnProps {
	podcastElementFragmentRef: PodigeePodcastElementForm_PodcastElementV2Fragment$key;
	onBack: () => void;
	formId?: string;
}

export const PodigeePodcastElementForm = ({
	podcastElementFragmentRef,
	onBack,
	formId,
}: OwnProps) => {
	const podcastElement = useFragment<PodigeePodcastElementForm_PodcastElementV2Fragment$key>(
		PODCAST_ELEMENT_FRAGMENT,
		podcastElementFragmentRef,
	);
	const [editPodigeePodcastElement, isEditingPodigeePodcastElement] =
		useMutation<PodigeePodcastElementForm_EditPodigeePodcastElementV2Mutation>(
			EDIT_PODIGEE_PODCAST_ELEMENT_MUTATION,
		);
	const [inputValue, setInputValue] = useState(podcastElement.podcastSource?.podigeeUrl || "");
	const hasFocused = useRef(false);

	const dispatch = useTypedDispatch();

	const formik = useFormik<FormState>({
		initialValues: {
			title: podcastElement.title,
			podigeeUrl: podcastElement.podcastSource?.podigeeUrl,
			lengthInSeconds: podcastElement.podcastSource?.lengthInSeconds || 1,
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Das Feld Titel wird benötigt."),
			podigeeUrl: Yup.string()
				.url("Das Feld Podigee URL muss eine gültige URL sein.")
				.required("Das Feld Podigee URL wird benötigt."),
		}),
		onSubmit: (values) => {
			editPodigeePodcastElement({
				variables: {
					input: {
						podigeePodcastElementId: podcastElement.id,
						title: values.title,
						podigeeUrl: values.podigeeUrl!,
						lengthInSeconds: values.lengthInSeconds || 1,
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});
	const urlExtension = "/embed?context=external";
	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, string>
				name={"title"}
				label={"Titel"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
				component={DefaultTextEditorComponent}
				formikConfig={formik}
			/>
			<ValidatedField<FormState, string>
				name={"podigeeUrl"}
				label={"Podigee URL"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
				helpText={
					"Der Zusatz /embed?context=external wird automatisch an das Ende des Textes angehängt."
				}
				component={({
					fieldValue,
					updateField,
					fieldName,
					disabled,
					isValid,
					onChange,
				}) => {
					return (
						<InputText
							id={fieldName}
							name={fieldName}
							value={fieldValue}
							onFocus={() => {
								if (!hasFocused.current) {
									let newValue = inputValue;
									if (newValue.endsWith(urlExtension)) {
										newValue = newValue.slice(0, -urlExtension.length);
										setInputValue(newValue);
										updateField(newValue);
									}
									hasFocused.current = true;
								}
							}}
							onChange={(e) => {
								setInputValue(e.target.value);
								updateField(e.target.value);
								if (onChange) {
									onChange();
								}
							}}
							onBlur={() => {
								let newValue = inputValue;
								if (newValue && !newValue.endsWith(urlExtension)) {
									newValue = `${newValue}${urlExtension}`;
									setInputValue(newValue);
									formik.setFieldValue("podigeeUrl", newValue);
								}
								hasFocused.current = false;
							}}
							disabled={disabled}
							className={classNames({ "p-invalid": !isValid })}
						/>
					);
				}}
				formikConfig={formik}
			/>

			<Button
				disabled={isEditingPodigeePodcastElement}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};

import React from "react";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { useNavigate } from "react-router-dom";

interface OwnProps {
	items: { id: string; text: string; forwardingUrl: string; isActive: boolean | string }[];
	deleteMessageDefinition: (id: string) => void;
}

export const NewsTickerDefinitionTable = ({ items, deleteMessageDefinition }: OwnProps) => {
	const navigate = useNavigate();

	const tableItems = items.map((i) => {
		return { ...i, isActive: i.isActive ? "Ja" : "Nein" };
	});

	return (
		<DataTable
			className="p-mb-2"
			value={tableItems}
			emptyMessage={"Keine Newsticker-Nachrichten"}
			dataKey="id"
		>
			<Column style={{ maxWidth: "360px" }} header={"Inhalt"} field="text" />
			<Column header={"Link"} field="forwardingUrl" />
			<Column header={"Aktiv"} field="isActive" />
			<Column
				header={"Aktionen"}
				body={(item) => (
					<>
						<Button
							icon={"pi pi-pencil"}
							onClick={() => navigate(`/newsticker/${item.id}/edit`)}
						/>
						<Button
							onClick={() => deleteMessageDefinition(item.id)}
							icon={"pi pi-trash"}
							className={"ml-2"}
						/>
					</>
				)}
			/>
		</DataTable>
	);
};

/**
 * @generated SignedSource<<08aa391797f6d9e202cd6ef86e5e875f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PodcastSourceTypeV2 = "vimeo" | "podigee" | "none" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type VimeoPodcastElementForm_PodcastElementV2Fragment$data = {
  readonly id: string;
  readonly title: string;
  readonly podcastSource: {
    readonly sourceType: PodcastSourceTypeV2;
    readonly vimeoId?: string;
    readonly lengthInSeconds?: number;
    readonly podigeeUrl?: string;
  };
  readonly " $fragmentType": "VimeoPodcastElementForm_PodcastElementV2Fragment";
};
export type VimeoPodcastElementForm_PodcastElementV2Fragment = VimeoPodcastElementForm_PodcastElementV2Fragment$data;
export type VimeoPodcastElementForm_PodcastElementV2Fragment$key = {
  readonly " $data"?: VimeoPodcastElementForm_PodcastElementV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VimeoPodcastElementForm_PodcastElementV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VimeoPodcastElementForm_PodcastElementV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "podcastSource",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sourceType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "vimeoId",
              "storageKey": null
            }
          ],
          "type": "VimeoPodcastSourceV2",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lengthInSeconds",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "podigeeUrl",
              "storageKey": null
            }
          ],
          "type": "PodigeePodcastSourceV2",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PodcastElementV2",
  "abstractKey": null
};

(node as any).hash = "0d298bf3508c536caaed6c88ea16f828";

export default node;

import React from "react";
import { useUrlFilterState } from "../components/core/filters/useUrlFilterState";

export interface TreeStatesFilterState {
	filterByUserId?: string;
	filterByRootNodeId?: string;
}

interface Search {
	filters: TreeStatesFilterState;
	setFilters: (filters: TreeStatesFilterState) => void;
	clearFilters: () => void;
}

export const TreeStatesSearchContext = React.createContext<Search>({
	setFilters: () => {},
	clearFilters: () => {},
	filters: {},
});

interface OwnProps {
	children: any;
}

export const TreeStatesSearchContainer = ({ children }: OwnProps) => {
	const { state, setState } = useUrlFilterState<TreeStatesFilterState>();

	return (
		<TreeStatesSearchContext.Provider
			value={{
				filters: state,
				setFilters: (filters) => {
					setState(filters);
				},
				clearFilters: () => {
					setState({});
				},
			}}
		>
			{children}
		</TreeStatesSearchContext.Provider>
	);
};

/**
 * @generated SignedSource<<bca54a1ae4eba1c5ffec3beefc9d36bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StartTreeConfigType = "StartTree_CMECertifiedNeedsSpecificGlobalUserStatus" | "StartTree_SpecificUserRolesCan" | "StartTree_CanNot" | "StartTree_SpecificUserStatusesCan" | "%future added value";
export type UserRoles = "Doctor" | "Apothecary" | "Midwife" | "PTA" | "PKA" | "MTA" | "PharmaceuticalEngineer" | "Doccheck" | "Unknown" | "Other" | "%future added value";
export type UserStatus = "noProcess" | "initialValidation" | "changeInProgress" | "doccheck" | "inactive" | "efnNeeded" | "active" | "activeEfnNeeded" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectExtendStartTreeConfigEditDialogs_TreeNodeFragment$data = {
  readonly structureDefinition: {
    readonly startConfigs?: ReadonlyArray<{
      readonly configType: StartTreeConfigType;
      readonly userStatuses?: ReadonlyArray<UserStatus>;
      readonly dateTime?: string | null;
      readonly userRoles?: ReadonlyArray<UserRoles>;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"SpecificUserStatusesCanStartTreeConfigForm_TreeNodeFragment" | "SpecificUserRolesCanStartTreeConfigForm_TreeNodeFragment">;
  readonly " $fragmentType": "ProjectExtendStartTreeConfigEditDialogs_TreeNodeFragment";
};
export type ProjectExtendStartTreeConfigEditDialogs_TreeNodeFragment = ProjectExtendStartTreeConfigEditDialogs_TreeNodeFragment$data;
export type ProjectExtendStartTreeConfigEditDialogs_TreeNodeFragment$key = {
  readonly " $data"?: ProjectExtendStartTreeConfigEditDialogs_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectExtendStartTreeConfigEditDialogs_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectExtendStartTreeConfigEditDialogs_TreeNodeFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SpecificUserStatusesCanStartTreeConfigForm_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SpecificUserRolesCanStartTreeConfigForm_TreeNodeFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "startConfigs",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "configType",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "userStatuses",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dateTime",
                      "storageKey": null
                    }
                  ],
                  "type": "SpecifiedUserStatusesCanStartTreeConfig",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "userRoles",
                      "storageKey": null
                    }
                  ],
                  "type": "SpecifiedUserRolesCanStartTreeConfig",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "9347000debb887f637d0268fdebbb117";

export default node;

/**
 * @generated SignedSource<<4544905ff08dc8dfbfa24a7dc183d1eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateInstructorImplInput = {
  data: InstructorImplInput;
  id: string;
  clientMutationId?: string | null;
};
export type InstructorImplInput = {
  name: string;
  title?: string | null;
  description?: string | null;
  location?: string | null;
  clientMutationId?: string | null;
  signatureFileRef?: string | null;
  portraitFileRef?: string | null;
};
export type EditInstructorScreen_UpdateMutation$variables = {
  input: UpdateInstructorImplInput;
};
export type EditInstructorScreen_UpdateMutationVariables = EditInstructorScreen_UpdateMutation$variables;
export type EditInstructorScreen_UpdateMutation$data = {
  readonly Admin: {
    readonly Instructor: {
      readonly updateInstructorImpl: {
        readonly data: {
          readonly node: {
            readonly id: string;
            readonly superId: string;
            readonly name: string;
            readonly " $fragmentSpreads": FragmentRefs<"EditInstructorScreen_InstructorFragment">;
          };
        };
      } | null;
    };
  };
};
export type EditInstructorScreen_UpdateMutationResponse = EditInstructorScreen_UpdateMutation$data;
export type EditInstructorScreen_UpdateMutation = {
  variables: EditInstructorScreen_UpdateMutationVariables;
  response: EditInstructorScreen_UpdateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "superId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditInstructorScreen_UpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InstructorAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Instructor",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateInstructorImplPayload",
                "kind": "LinkedField",
                "name": "updateInstructorImpl",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InstructorImplEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InstructorImpl",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditInstructorScreen_InstructorFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditInstructorScreen_UpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InstructorAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Instructor",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateInstructorImplPayload",
                "kind": "LinkedField",
                "name": "updateInstructorImpl",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InstructorImplEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InstructorImpl",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "location",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "portraitFile",
                            "plural": false,
                            "selections": (v5/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "signatureFile",
                            "plural": false,
                            "selections": (v5/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6f33161cd312bcdcc67acc36d55e0e3e",
    "id": null,
    "metadata": {},
    "name": "EditInstructorScreen_UpdateMutation",
    "operationKind": "mutation",
    "text": "mutation EditInstructorScreen_UpdateMutation(\n  $input: UpdateInstructorImplInput!\n) {\n  Admin {\n    Instructor {\n      updateInstructorImpl(input: $input) {\n        data {\n          node {\n            id\n            superId\n            name\n            ...EditInstructorScreen_InstructorFragment\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditInstructorScreen_InstructorFragment on InstructorImpl {\n  id\n  superId\n  name\n  title\n  description\n  location\n  portraitFile {\n    id\n    url\n  }\n  signatureFile {\n    id\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "76a466efb49be377a6204b1f89a1ea13";

export default node;

/**
 * @generated SignedSource<<01ac7a7d1bf49fbbf3aeb89c22170f5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnlySpecializationVisibilityConfigForm_TreeNodeFragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly visibilityConfigs?: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"OnlySpecializationVisibilityConfigForm_VisibilityTreeConfigFragment">;
    }>;
  };
  readonly " $fragmentType": "OnlySpecializationVisibilityConfigForm_TreeNodeFragment";
};
export type OnlySpecializationVisibilityConfigForm_TreeNodeFragment = OnlySpecializationVisibilityConfigForm_TreeNodeFragment$data;
export type OnlySpecializationVisibilityConfigForm_TreeNodeFragment$key = {
  readonly " $data"?: OnlySpecializationVisibilityConfigForm_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnlySpecializationVisibilityConfigForm_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnlySpecializationVisibilityConfigForm_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "visibilityConfigs",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "OnlySpecializationVisibilityConfigForm_VisibilityTreeConfigFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "1f17ee8a4e9afddcb9b96c378ba97411";

export default node;

import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import React from "react";
import { ValidatedField } from "../../../../infecto-lms-admin/components/core/form/ValidatedField";
import { useTypedDispatch } from "../../../../infecto-lms-admin/redux/Store";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../../infecto-lms-admin/redux/slices/CoreSlice";
import { CertificateTemplateSelectionField } from "../../tree/CertificateTemplateSelectionField";
import { EditFachberaterRewardConfigurationForm_MakeFachberaterRewardConfigurationFragment$key } from "../../../../__generated__/EditFachberaterRewardConfigurationForm_MakeFachberaterRewardConfigurationFragment.graphql";
import { EditFachberaterRewardConfigurationForm_MakeFachberaterRewardMutation } from "../../../../__generated__/EditFachberaterRewardConfigurationForm_MakeFachberaterRewardMutation.graphql";

const MAKE_FACHBERATER_REWARD_CONFIGURATION_FRAGMENT = graphql`
	fragment EditFachberaterRewardConfigurationForm_MakeFachberaterRewardConfigurationFragment on MakeFachberaterRewardConfiguration {
		id
		certificateTemplateRef
	}
`;

const MAKE_FACHBERATER_REWARD_MUTATION = graphql`
	mutation EditFachberaterRewardConfigurationForm_MakeFachberaterRewardMutation(
		$input: EditMakeFachberaterRewardInput!
	) {
		Admin {
			Tree {
				editMakeFachberaterReward(input: $input) {
					contentNode {
						...RewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface FormState {
	certificateTemplateId: string;
}

interface OwnProps {
	contentNodeId: string;
	makeFachberaterRewardConfigurationFragmentRef: EditFachberaterRewardConfigurationForm_MakeFachberaterRewardConfigurationFragment$key;
	onBack: () => void;
}

export const EditFachberaterRewardConfigurationForm = ({
	contentNodeId,
	makeFachberaterRewardConfigurationFragmentRef,
	onBack,
}: OwnProps) => {
	const makeFachberaterRewardConfiguration =
		useFragment<EditFachberaterRewardConfigurationForm_MakeFachberaterRewardConfigurationFragment$key>(
			MAKE_FACHBERATER_REWARD_CONFIGURATION_FRAGMENT,
			makeFachberaterRewardConfigurationFragmentRef,
		);
	const [editFachberaterReward, isEditingFachberaterReward] =
		useMutation<EditFachberaterRewardConfigurationForm_MakeFachberaterRewardMutation>(
			MAKE_FACHBERATER_REWARD_MUTATION,
		);
	const dispatch = useTypedDispatch();
	const formId = "EditFachberaterRewardConfigurationForm";
	const formik = useFormik<FormState>({
		initialValues: {
			certificateTemplateId: makeFachberaterRewardConfiguration.certificateTemplateRef,
		},
		validationSchema: Yup.object().shape({
			certificateTemplateId: Yup.string().required(
				"Das Feld Zertifikats-Vorlage wird benötigt.",
			),
		}),
		onSubmit: (values, formikHelpers) => {
			editFachberaterReward({
				variables: {
					input: {
						contentNodeId: contentNodeId,
						rewardId: makeFachberaterRewardConfiguration.id,
						certificateTemplateId: values.certificateTemplateId,
					},
				},
				onCompleted: () => {
					formikHelpers.setTouched({});
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, string>
				name={"certificateTemplateId"}
				label={"Zertifikats-Vorlage"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
				component={CertificateTemplateSelectionField}
				formikConfig={formik}
			/>

			<Button
				disabled={Object.entries(formik.touched).length === 0 || isEditingFachberaterReward}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};

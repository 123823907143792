import { useFormik } from "formik";
import { Button } from "primereact/button";
import React from "react";
import * as Yup from "yup";
import { ValidatedField } from "../core/form/ValidatedField";
import { NOTIFICATION_VARIABLES } from "../../i18n/notifications/i18n";
import { WarningUnsavedChangesDialog } from "../core/dialog/WarningUnsavedChangesDialog";
import { useTypedDispatch, useTypedSelector } from "../../redux/Store";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
	selectArrayOfEditedForms,
} from "../../redux/slices/CoreSlice";
import { useCallbackPrompt } from "../../functions/hooks/UseCallBackPrompt";
import { DefaultTextFieldComponent } from "../core/form/DefaultTextInput";

export interface FormState {
	title?: string;
	message?: string;
}

interface OwnProps {
	loading?: boolean;
	initialValues?: FormState;
	variables: readonly string[];
	onSubmit: (values: FormState) => void;
}

export const EditNotificationTemplateForm = ({
	variables,
	loading,
	initialValues,
	onSubmit,
}: OwnProps) => {
	const dispatch = useTypedDispatch();
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);
	const formId = "EditNotificationTemplateForm";
	const [showPrompt, confirmNavigation, cancelNavigation, setShowPrompt] = useCallbackPrompt(
		arrayOfEditedForms.length > 0,
	);
	const formik = useFormik<FormState>({
		initialValues: {
			title: initialValues?.title ?? "Titel",
			message: initialValues?.message ?? "Nachricht",
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Titel wird benötigt"),
			message: Yup.string().required("Nachricht wird benötigt"),
		}),
		onSubmit: (values, { setSubmitting }) => {
			onSubmit({
				...values,
			});
			setSubmitting(false);
			dispatch(resetArrayOfEditedForms());
		},
	});

	return (
		<>
			{showPrompt && (
				<WarningUnsavedChangesDialog
					confirmNavigation={confirmNavigation}
					setShowDialog={setShowPrompt}
					cancelNavigation={cancelNavigation}
				/>
			)}
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, string>
					name={"title"}
					label={"Titel"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<FormState, string>
					name={"message"}
					label={"Nachricht"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<h3>Verfügbare Variablen</h3>
				<p>
					Diese Variablen können in allen obigen Feldern eingefügt werden und werden durch
					die entsprechenden Texte ersetzt.
				</p>
				<ul>
					{variables.map((variable) => {
						return (
							<li key={variable}>
								<strong>%%{variable}%%</strong> - {NOTIFICATION_VARIABLES[variable]}
							</li>
						);
					})}
				</ul>

				<Button disabled={loading} type="submit" label="Speichern" className="p-mt-2" />
			</form>
		</>
	);
};

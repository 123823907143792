/**
 * @generated SignedSource<<766a8ac199b1900a8799ca772cbf45e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserActionColumn_NodeFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"UserContentSubmissionDialog_UserFragment">;
  readonly " $fragmentType": "UserActionColumn_NodeFragment";
};
export type UserActionColumn_NodeFragment = UserActionColumn_NodeFragment$data;
export type UserActionColumn_NodeFragment$key = {
  readonly " $data"?: UserActionColumn_NodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserActionColumn_NodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserActionColumn_NodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserContentSubmissionDialog_UserFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "8af933e1529bcef55226ad8b8ea2e0fc";

export default node;

/**
 * @generated SignedSource<<0cc41fd76f1b254a54e20d675d93db41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActionColumn_FeedbackFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "ActionColumn_FeedbackFragment";
};
export type ActionColumn_FeedbackFragment = ActionColumn_FeedbackFragment$data;
export type ActionColumn_FeedbackFragment$key = {
  readonly " $data"?: ActionColumn_FeedbackFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActionColumn_FeedbackFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActionColumn_FeedbackFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "FeedbackV2",
  "abstractKey": null
};

(node as any).hash = "35f38abe82712023d126eb3cea5bc80e";

export default node;

import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { TagSelectionField_Query } from "../../../__generated__/TagSelectionField_Query.graphql";
import { ListBox } from "primereact/listbox";
import { TagSelectionField_Refetch } from "../../../__generated__/TagSelectionField_Refetch.graphql";
import { Suspense, useEffect, useState } from "react";
import { RenderConfig } from "../core/form/ValidatedField";
import { TagSelectionField_QueryFragment$key } from "../../../__generated__/TagSelectionField_QueryFragment.graphql";

const QUERY = graphql`
	query TagSelectionField_Query(
		$tagIds: [ID!]!
		$first: Int!
		$skip: Boolean!
		$filterByName: String
		$after: String
	) {
		nodes(ids: $tagIds) @skip(if: $skip) {
			... on Tag {
				id
				name
				colorHex
			}
		}
		...TagSelectionField_QueryFragment
			@arguments(filterByName: $filterByName, first: $first, after: $after)
	}
`;

const QUERY_FRAGMENT = graphql`
	fragment TagSelectionField_QueryFragment on Query
	@refetchable(queryName: "TagSelectionField_Refetch")
	@argumentDefinitions(
		first: { type: "Int" }
		after: { type: "String" }
		filterByName: { type: "String" }
	) {
		Admin {
			Tag {
				Tags(first: $first, after: $after, filterByName: $filterByName)
					@connection(key: "TagSelectionField_Tags") {
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							name
							colorHex
						}
					}
				}
			}
		}
	}
`;

export const TagSelectionField = ({
	fieldValue,
	updateField,
	onChange,
}: RenderConfig<string[]>) => {
	const [filter, setFilter] = useState<string>("");
	const query = useLazyLoadQuery<TagSelectionField_Query>(QUERY, {
		tagIds: fieldValue || [],
		skip: !fieldValue,
		filterByName: undefined,
		first: 20,
	});

	useEffect(() => {
		refetch({ filterByName: filter });
		// eslint-disable-next-line
	}, [filter]);

	const {
		data: {
			Admin: {
				Tag: {
					Tags: { edges: tags },
				},
			},
		},
		refetch,
	} = usePaginationFragment<TagSelectionField_Refetch, TagSelectionField_QueryFragment$key>(
		QUERY_FRAGMENT,
		query,
	);

	const allNodes = [...(query.nodes || []), ...(tags?.map((e) => e!.node!) || [])].map((x) => x!);

	const map = new Map(allNodes.map((item) => [item?.id, item!]));
	const arrayUniqueByKey = Array.from(map.values())
		.filter((i) => i)
		.sort((x, y) => ((x.name || "") > (y.name || "") ? 1 : -1));

	return (
		<Suspense fallback={<div>Lade..</div>}>
			<ListBox
				value={fieldValue}
				options={arrayUniqueByKey.map((n) => ({ label: n!.name, value: n!.id }))}
				onChange={(e) => {
					updateField(e.value);
					if (onChange) {
						onChange();
					}
				}}
				multiple
				filter
				filterValue={filter}
				onFilterValueChange={(e) => setFilter(e.value)}
				listStyle={{ maxHeight: "250px" }}
			/>
		</Suspense>
	);
};

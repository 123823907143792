import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import {
	RecipientType,
	RewardResultKind,
	ProjectRewardsColumn_ContentSubmissionFragment$key,
} from "../../../__generated__/ProjectRewardsColumn_ContentSubmissionFragment.graphql";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment ProjectRewardsColumn_ContentSubmissionFragment on ContentSubmission {
		definition {
			... on PassedELearningContentSubmissionDefinition {
				rewardResults {
					kind
					... on PointsRewardWasPossibleResult {
						pointsPoolName
						amount
					}
					... on CertificateRewardWasPossibleResult {
						certificateName
					}
					... on EmailRewardWasPossibleResult {
						recipientType
					}
				}
			}
		}
	}
`;

interface OwnProps {
	contentSubmissionFragmentRef: ProjectRewardsColumn_ContentSubmissionFragment$key;
}

export const ProjectRewardsColumn = ({ contentSubmissionFragmentRef }: OwnProps) => {
	const contentSubmission = useFragment<ProjectRewardsColumn_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	const showRewardsFilter: RewardResultKind[] = [
		"PointsRewardWasPossibleResult",
		"CertificateRewardWasPossibleResult",
		"EmailRewardWasPossibleResult",
	];

	return (
		<>
			{contentSubmission.definition.rewardResults
				?.filter((rs) => showRewardsFilter.indexOf(rs.kind) > -1)
				.map(
					(
						rewardResult: {
							readonly kind: RewardResultKind;
							readonly pointsPoolName?: string;
							readonly amount?: number;
							readonly certificateName?: string;
							readonly recipientType?: RecipientType;
						},
						index: number,
					) => (
						<div key={index}>
							{rewardResult.kind === "CertificateRewardWasPossibleResult" && (
								<span>Zertifikat {rewardResult.certificateName}</span>
							)}

							{rewardResult.kind === "PointsRewardWasPossibleResult" && (
								<span>
									{rewardResult.amount} {rewardResult.pointsPoolName}
								</span>
							)}

							{rewardResult.kind === "EmailRewardWasPossibleResult" && (
								<span>E-Mail: {rewardResult.recipientType}</span>
							)}
						</div>
					),
				)}
		</>
	);
};

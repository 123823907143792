import { graphql } from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { useFragment } from "react-relay";
import { ShowCertificateButton_CertificateFragment$key } from "../../../__generated__/ShowCertificateButton_CertificateFragment.graphql";

const CERTIFICATE_TEMPLATE_FRAGMENT = graphql`
	fragment ShowCertificateButton_CertificateFragment on CertificateTemplate {
		name
		templateFile {
			url
		}
	}
`;

interface OwnProps {
	className?: string;
	certificateTemplateFragmentRef: ShowCertificateButton_CertificateFragment$key;
}

export const ShowCertificateButton = ({ className, certificateTemplateFragmentRef }: OwnProps) => {
	const certificateTemplate = useFragment<ShowCertificateButton_CertificateFragment$key>(
		CERTIFICATE_TEMPLATE_FRAGMENT,
		certificateTemplateFragmentRef,
	);

	const [modalVisible, setModalVisible] = useState(false);

	return certificateTemplate.templateFile?.url ? (
		<>
			{modalVisible && (
				<Dialog
					style={{ minHeight: 1000 }}
					header={<h1>Vorschau des Zertifikats</h1>}
					className="w-8 flex"
					visible={true}
					onHide={() => setModalVisible(false)}
				>
					<iframe
						className="flex-grow-1 w-12"
						style={{ minHeight: 700 }}
						title={certificateTemplate.name}
						src={certificateTemplate.templateFile?.url}
					/>
				</Dialog>
			)}
			<Button
				className={className}
				onClick={() => setModalVisible(true)}
				icon={"pi pi-eye"}
			/>
		</>
	) : null;
};

/**
 * @generated SignedSource<<28dfa036eb9debe07ca2701f866149fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetIfRepeatedDoNotRewardContentConfigInput = {
  contentNodeId: string;
  clientMutationId?: string | null;
};
export type RewardContentConfigEditor_SetIfRepeatedDoNotRewardContentConfigMutation$variables = {
  input: SetIfRepeatedDoNotRewardContentConfigInput;
};
export type RewardContentConfigEditor_SetIfRepeatedDoNotRewardContentConfigMutationVariables = RewardContentConfigEditor_SetIfRepeatedDoNotRewardContentConfigMutation$variables;
export type RewardContentConfigEditor_SetIfRepeatedDoNotRewardContentConfigMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly setIfRepeatedDoNotRewardContentConfig: {
        readonly contentNode: {
          readonly " $fragmentSpreads": FragmentRefs<"RewardContentConfigEditor_TreeNodeFragment">;
        };
      } | null;
    };
  };
};
export type RewardContentConfigEditor_SetIfRepeatedDoNotRewardContentConfigMutationResponse = RewardContentConfigEditor_SetIfRepeatedDoNotRewardContentConfigMutation$data;
export type RewardContentConfigEditor_SetIfRepeatedDoNotRewardContentConfigMutation = {
  variables: RewardContentConfigEditor_SetIfRepeatedDoNotRewardContentConfigMutationVariables;
  response: RewardContentConfigEditor_SetIfRepeatedDoNotRewardContentConfigMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RewardContentConfigEditor_SetIfRepeatedDoNotRewardContentConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetIfRepeatedDoNotRewardContentConfigPayload",
                "kind": "LinkedField",
                "name": "setIfRepeatedDoNotRewardContentConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "contentNode",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "RewardContentConfigEditor_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RewardContentConfigEditor_SetIfRepeatedDoNotRewardContentConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetIfRepeatedDoNotRewardContentConfigPayload",
                "kind": "LinkedField",
                "name": "setIfRepeatedDoNotRewardContentConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "contentNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "typeDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "rewardConfig",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "configType",
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "ContentTypeDefinition",
                            "abstractKey": "__isContentTypeDefinition"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "68728997115323aeae4190123bbf0344",
    "id": null,
    "metadata": {},
    "name": "RewardContentConfigEditor_SetIfRepeatedDoNotRewardContentConfigMutation",
    "operationKind": "mutation",
    "text": "mutation RewardContentConfigEditor_SetIfRepeatedDoNotRewardContentConfigMutation(\n  $input: SetIfRepeatedDoNotRewardContentConfigInput!\n) {\n  Admin {\n    Tree {\n      setIfRepeatedDoNotRewardContentConfig(input: $input) {\n        contentNode {\n          ...RewardContentConfigEditor_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment RewardContentConfigEditor_TreeNodeFragment on TreeNode {\n  id\n  typeDefinition {\n    __typename\n    ... on ContentTypeDefinition {\n      __isContentTypeDefinition: __typename\n      rewardConfig {\n        __typename\n        configType\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "00b1220e1a2a62bba401e1809c69efac";

export default node;

import { Suspense } from "react";
import { PointsAdditionFilters } from "../components/points/additions/PointsAdditionFilters";
import { BaseScreen } from "./BaseScreen";
import { PointsAdditionSearchContainer } from "../components/points/additions/PointsAdditionSearchContainer";
import { PointsAdditionsTable } from "../components/points/pointsAdditionsTable/PointsAdditionsTable";
import { PointsSum } from "../components/points/PointsSum";

export const PointsAdditionsScreen = () => {
	return (
		<BaseScreen>
			<PointsAdditionSearchContainer>
				<PointsAdditionFilters />
				<PointsSum />
				<Suspense fallback={<div>Lade...</div>}>
					<PointsAdditionsTable />
				</Suspense>
			</PointsAdditionSearchContainer>
		</BaseScreen>
	);
};

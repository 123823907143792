import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { FeedbacksScreenV2_Query } from "../../__generated__/FeedbacksScreenV2_Query.graphql";
import { BaseScreen } from "./BaseScreen";
import { FeedbacksTableV2 } from "../components/feedbacks/FeedbacksTableV2";
import { Permission } from "../../__generated__/MainNavigation_Query.graphql";
import { useSelector } from "react-redux";
import { selectHasPermissions } from "../redux/slices/CurrentUserSlice";

const QUERY = graphql`
	query FeedbacksScreenV2_Query {
		...FeedbacksTableV2_QueryFragment
	}
`;

export const FeedbacksScreenV2 = () => {
	const query = useLazyLoadQuery<FeedbacksScreenV2_Query>(
		QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);

	const checkPermission = (permissions: Permission[]) =>
		useSelector((state: any) => selectHasPermissions(state, permissions));

	const isAdmin = checkPermission([
		"AccountPermission_System_Root",
		"UserInAccountPermission_System_Owner",
	]);

	if (!isAdmin) {
		return <div>Kein Zugriff</div>;
	}

	return (
		<BaseScreen>
			<h1>Feedbacks</h1>
			<FeedbacksTableV2 queryFragmentRef={query} />
		</BaseScreen>
	);
};

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getTreeEditorChildNodePath, getTreeEditorPath } from "../../../routes/GraphEditorRoutes";

interface TreeSelectionContextProps {
	treeId: string;
	selectedNodeId?: string;
	setSelectedNodeId: (c: string | undefined) => void;
}

export const TreeSelectionContext = React.createContext<TreeSelectionContextProps>({
	treeId: "",
	selectedNodeId: undefined,
	setSelectedNodeId: () => {},
});

interface OwnProps {
	treeId: string;
	initiallySelectedNodeId?: string;
	children: any;
}

export const TreeSelectionContainer = ({ children, treeId, initiallySelectedNodeId }: OwnProps) => {
	const [selectedNodeId, setSelectedNodeId] = useState<string | undefined>(
		initiallySelectedNodeId,
	);

	const navigate = useNavigate();

	return (
		<TreeSelectionContext.Provider
			value={{
				treeId: treeId,
				setSelectedNodeId: (newId) => {
					if (newId !== treeId) {
						navigate(getTreeEditorChildNodePath(treeId, newId ? newId : ""));
					} else {
						navigate(getTreeEditorPath(treeId));
					}

					setSelectedNodeId(newId);
				},
				selectedNodeId: selectedNodeId,
			}}
		>
			{children}
		</TreeSelectionContext.Provider>
	);
};

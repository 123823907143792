/**
 * @generated SignedSource<<a40cc0d5bc5621aa4b641d4fd61d29ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditMessageDefinitionInput = {
  internalTitle: string;
  buttonOpt?: EditButtonInput | null;
  showUntilOpt?: any | null;
  notificationDefinitionOpt?: EditNotificationDefinition | null;
  id?: string | null;
  clientMutationId?: string | null;
};
export type EditButtonInput = {
  buttonType: string;
  title: string;
  link?: string | null;
};
export type EditNotificationDefinition = {
  title: string;
  body: string;
};
export type EditMessageDefinitionForm_EditCoreMutation$variables = {
  input: EditMessageDefinitionInput;
};
export type EditMessageDefinitionForm_EditCoreMutationVariables = EditMessageDefinitionForm_EditCoreMutation$variables;
export type EditMessageDefinitionForm_EditCoreMutation$data = {
  readonly Admin: {
    readonly Messaging: {
      readonly editMessageDefinition: {
        readonly edge: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"EditMessageDefinitionForm_MessageDefinitionFragment">;
          };
        };
      } | null;
    };
  };
};
export type EditMessageDefinitionForm_EditCoreMutationResponse = EditMessageDefinitionForm_EditCoreMutation$data;
export type EditMessageDefinitionForm_EditCoreMutation = {
  variables: EditMessageDefinitionForm_EditCoreMutationVariables;
  response: EditMessageDefinitionForm_EditCoreMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditMessageDefinitionForm_EditCoreMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessagingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Messaging",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditMessageDefinitionPayload",
                "kind": "LinkedField",
                "name": "editMessageDefinition",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessageDefinitionsEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MessageDefinition",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditMessageDefinitionForm_MessageDefinitionFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditMessageDefinitionForm_EditCoreMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessagingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Messaging",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditMessageDefinitionPayload",
                "kind": "LinkedField",
                "name": "editMessageDefinition",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessageDefinitionsEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MessageDefinition",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "internalTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "buttonOpt",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "link",
                                    "storageKey": null
                                  }
                                ],
                                "type": "MessageDefinitionLinkButton",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "showUntilOpt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "NotificationDefinitionType",
                            "kind": "LinkedField",
                            "name": "notificationDefinitionOpt",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "body",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "50749a016d23a1608243ab3272497bd3",
    "id": null,
    "metadata": {},
    "name": "EditMessageDefinitionForm_EditCoreMutation",
    "operationKind": "mutation",
    "text": "mutation EditMessageDefinitionForm_EditCoreMutation(\n  $input: EditMessageDefinitionInput!\n) {\n  Admin {\n    Messaging {\n      editMessageDefinition(input: $input) {\n        edge {\n          node {\n            ...EditMessageDefinitionForm_MessageDefinitionFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditMessageDefinitionForm_MessageDefinitionFragment on MessageDefinition {\n  id\n  internalTitle\n  buttonOpt {\n    __typename\n    type\n    title\n    ... on MessageDefinitionLinkButton {\n      link\n    }\n  }\n  showUntilOpt\n  notificationDefinitionOpt {\n    title\n    body\n  }\n}\n"
  }
};
})();

(node as any).hash = "bc393c8d803d869da664c55125304fe3";

export default node;

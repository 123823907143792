import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ScreenColumn_FeedbackFragment$key } from "../../../__generated__/ScreenColumn_FeedbackFragment.graphql";

const FEEDBACK_FRAGMENT = graphql`
	fragment ScreenColumn_FeedbackFragment on FeedbackV2 {
		screen
	}
`;

interface OwnProps {
	feedbackFragmentRef: ScreenColumn_FeedbackFragment$key;
}

export const ScreenColumn = ({ feedbackFragmentRef }: OwnProps) => {
	const feedback = useFragment<ScreenColumn_FeedbackFragment$key>(
		FEEDBACK_FRAGMENT,
		feedbackFragmentRef,
	);

	return <div>{feedback.screen || "Kein Bildschirm"}</div>;
};

import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";

import {
	AddOnlySpecializationVisibilityConfigMutation,
	OnlySpecializationVisibilityConfigForm,
} from "../../../app/components/tree/editor/node/config/tree/visibility/OnlySpecializationVisibilityConfigForm";

import { VisibilityTreeConfigType } from "../../../__generated__/VisibilityTreeConfigEditor_TreeNodeFragment.graphql";
import { AddOnlyFachrichtungenVisibilityTreeConfigInput } from "../../../__generated__/OnlySpecializationVisibilityConfigForm_AddOnlyFachrichtungenVisibilityConfigMutation.graphql";
import { RelayEnvironment } from "../../../infecto-lms-admin/relay/RelayEnvironment";
import { Config } from "../../../infecto-lms-admin/components/tree/editor/node/config/components/Config.interfaces";
import {
	AddInfectopharmOnlyAdminsVisibilityConfig,
	OnlySpecificPermissionsVisibilityConfigForm,
} from "../../../app/components/tree/editor/node/config/tree/visibility/OnlySpecificPermissionsVisibilityConfigForm";
import moment from "moment-timezone";
import { AddOnlySpecificPermissionsVisibilityTreeConfigInput } from "../../../__generated__/OnlySpecificPermissionsVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation.graphql";
import { AddOnlyIfTreeStateVisibilityTreeConfigInput } from "../../../__generated__/VisibilityTreeConfigEditor_AddOnlyIfTreeStateVisibilityConfigMutation.graphql";
import { AddInfectopharmOnlyIfTreeStateOrAdminVisibilityConfig } from "../../../app/components/tree/editor/node/config/tree/visibility/OnlyIfTreeStateOrAdminVisibilityConfig";
import { AddInfectopharmOnlyFachberaterVisibilityConfig } from "../../../app/components/tree/editor/node/config/tree/visibility/OnlyFachberaterVisibilityConfig";
import { AddInfectopharmHideForFachberaterVisibilityConfig } from "../../../app/components/tree/editor/node/config/tree/visibility/HideForFachberaterVisibilityConfig";
import { AddOnlyBasicSeminarVisibilityTreeConfigInput } from "../../../__generated__/OnlyBasicSeminarVisibilityConfig_AddMutation.graphql";
import { AddInfectopharmOnlyBasicSeminarVisibilityConfig } from "../../../app/components/tree/editor/node/config/tree/visibility/OnlyBasicSeminarVisibilityConfig";
import { ProjectExtendVisibilityConfigEditDialogs_VisibilityTreeConfigFragment$key } from "../../../__generated__/ProjectExtendVisibilityConfigEditDialogs_VisibilityTreeConfigFragment.graphql";
import { ProjectExtendVisibilityConfigEditDialogs_TreeNodeFragment$key } from "../../../__generated__/ProjectExtendVisibilityConfigEditDialogs_TreeNodeFragment.graphql";

const TREE_NODE_FRAGMENT = graphql`
	fragment ProjectExtendVisibilityConfigEditDialogs_TreeNodeFragment on TreeNode {
		...OnlySpecializationVisibilityConfigForm_TreeNodeFragment
		...OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment
		id
		structureDefinition {
			... on RootStructureDefinition {
				visibilityConfigs {
					... on OnlyIfTreeStateOrAdminVisibilityTreeConfig {
						id
						configType
					}
				}
			}
		}
	}
`;

const CONFIGURATION_FRAGMENT = graphql`
	fragment ProjectExtendVisibilityConfigEditDialogs_VisibilityTreeConfigFragment on VisibilityTreeConfig {
		configType
		...OnlySpecializationVisibilityConfigForm_VisibilityTreeConfigFragment
		...OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment
	}
`;

interface Props {
	treeNodeFragmentRef: ProjectExtendVisibilityConfigEditDialogs_TreeNodeFragment$key;
	configurationFragmentRef: ProjectExtendVisibilityConfigEditDialogs_VisibilityTreeConfigFragment$key;
}

export const ExtendVisibilityConfigs: Config<VisibilityTreeConfigType, any>[] = [
	{
		configKey: "VisibilityTree_OnlyFachrichtungen",
		addMutation: (input: AddOnlyFachrichtungenVisibilityTreeConfigInput) =>
			AddOnlySpecializationVisibilityConfigMutation(RelayEnvironment, input),
		addMutationPayload: {
			fachrichtungen: [],
		},
		editable: true,
	},
	{
		configKey: "VisibilityTree_OnlySpecificPermissions",
		addMutation: (input: AddOnlySpecificPermissionsVisibilityTreeConfigInput) =>
			AddInfectopharmOnlyAdminsVisibilityConfig(RelayEnvironment, input),
		addMutationPayload: {
			dateTime: moment().add(1, "week").format(),
		},
		editable: true,
	},
	{
		configKey: "VisibilityTree_OnlyIfTreeStateOrAdmin",
		addMutation: (input: AddOnlyIfTreeStateVisibilityTreeConfigInput) =>
			AddInfectopharmOnlyIfTreeStateOrAdminVisibilityConfig(RelayEnvironment, input),

		editable: false,
	},
	{
		configKey: "VisibilityTree_OnlyFachberater",
		addMutation: (input: AddOnlyIfTreeStateVisibilityTreeConfigInput) =>
			AddInfectopharmOnlyFachberaterVisibilityConfig(RelayEnvironment, input),

		editable: false,
	},
	{
		configKey: "VisibilityTree_HideForFachberater",
		addMutation: (input: AddOnlyIfTreeStateVisibilityTreeConfigInput) =>
			AddInfectopharmHideForFachberaterVisibilityConfig(RelayEnvironment, input),

		editable: false,
	},
	{
		configKey: "VisibilityTree_OnlyBasicSeminar",
		addMutation: (input: AddOnlyBasicSeminarVisibilityTreeConfigInput) => {
			AddInfectopharmOnlyBasicSeminarVisibilityConfig(RelayEnvironment, input);
		},
		editable: false,
	},
];

export const HiddenVisibilityConfigs: VisibilityTreeConfigType[] = [
	"VisibilityTree_OnlyAdmins",
	"VisibilityTree_OnlyIfTreeState",
];

export const ProjectExtendVisibilityConfigEditDialogs = (props: Props) => {
	const treeNode = useFragment<ProjectExtendVisibilityConfigEditDialogs_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		props.treeNodeFragmentRef,
	);

	const configuration =
		useFragment<ProjectExtendVisibilityConfigEditDialogs_VisibilityTreeConfigFragment$key>(
			CONFIGURATION_FRAGMENT,
			props.configurationFragmentRef,
		);

	return (
		<>
			{configuration.configType === "VisibilityTree_OnlyFachrichtungen" && (
				<OnlySpecializationVisibilityConfigForm
					{...props}
					treeNodeFragmentRef={treeNode}
					configurationFragmentRef={configuration}
				/>
			)}

			{configuration.configType === "VisibilityTree_OnlySpecificPermissions" && (
				<OnlySpecificPermissionsVisibilityConfigForm
					{...props}
					treeNodeFragmentRef={treeNode}
					configurationFragmentRef={configuration}
				/>
			)}
		</>
	);
};

import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { RenderConfig } from "../core/form/ValidatedField";

export function UrlTextFieldComponent({
	fieldName,
	fieldValue,
	updateField,
	isValid,
	disabled,
	onChange,
}: RenderConfig<string>) {
	const [errorWhiteSpace, setErrorWhiteSpace] = useState(false);
	const CheckWhiteSpace = (e: React.ChangeEvent<HTMLInputElement>) => {
		document.body.onkeyup = function (e) {
			if (e.key === " ") {
				setErrorWhiteSpace(true);
			} else {
				setErrorWhiteSpace(false);
			}
		};
		if (!/\s/.test(e.target.value)) {
			updateField(e.target.value);
		}
	};
	useEffect(() => {
		if (errorWhiteSpace) {
			setTimeout(() => setErrorWhiteSpace(false), 1000);
		}
	}, [fieldValue, errorWhiteSpace]);

	return (
		<div>
			{errorWhiteSpace && <ErrorParagr>Kein Leerzeichen erlaubt</ErrorParagr>}
			<InputText
				id={fieldName}
				name={fieldName}
				value={fieldValue}
				onChange={(e) => {
					CheckWhiteSpace(e);
					if (onChange) {
						onChange();
					}
				}}
				disabled={disabled}
				className={classNames({ "p-invalid": !isValid })}
			/>
		</div>
	);
}

const ErrorParagr = styled.p`
	font-size: 0.65rem;
	color: red;
`;

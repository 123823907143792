import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { NodeConfigurationBadges_TreeNodeFragment$key } from "../../../__generated__/NodeConfigurationBadges_TreeNodeFragment.graphql";
import { Tooltip } from "primereact/tooltip";

const TREE_NODE_FRAGMENT = graphql`
	fragment ProjectExtendNodeConfigurationBadges_TreeNodeFragment on TreeNode {
		typeDefinition {
			definitionType
			... on ContentTypeDefinition {
				rewards {
					kind
				}
			}
		}
	}
`;

interface Props {
	treeNodeFragmentRef: NodeConfigurationBadges_TreeNodeFragment$key;
	cleanedNodeId: string;
}

export const ProjectExtendNodeConfigurationBadges = ({
	treeNodeFragmentRef,
	cleanedNodeId,
}: Props) => {
	const treeNode = useFragment<NodeConfigurationBadges_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const isContent = treeNode.typeDefinition.definitionType === "content";

	return (
		<>
			{isContent && treeNode.typeDefinition.rewards?.map((r) => r.kind).includes("Points") && (
				<>
					<span
						className={`points-reward-tooltip-${cleanedNodeId} bi bi-123 text-blue-300 pr-1`}
					/>
					<Tooltip
						target={`.points-reward-tooltip-${cleanedNodeId}`}
						content="Belohnt mit Punkten"
					/>
				</>
			)}

			{isContent &&
				treeNode.typeDefinition.rewards?.map((r) => r.kind).includes("Certificate") && (
					<>
						<span
							className={`certificate-reward-tooltip-${cleanedNodeId} bi bi-trophy text-blue-300 pr-1`}
						/>
						<Tooltip
							target={`.certificate-reward-tooltip-${cleanedNodeId}`}
							content="Belohnt mit Zertifikat"
						/>
					</>
				)}
		</>
	);
};

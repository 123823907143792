import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import React from "react";
import { GenerateTestCertificateModal_GenerateTestCertificateMutation } from "../../../__generated__/GenerateTestCertificateModal_GenerateTestCertificateMutation.graphql";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../lms-admin-impl/i18n/tree.i18n";
import { ValidatedField } from "../core/form/ValidatedField";
import { ContentNodeSelectionField } from "./ContentNodeSelectionField";

const GENERATE_TEST_CERTIFICATE_MUTATION = graphql`
	mutation GenerateTestCertificateModal_GenerateTestCertificateMutation(
		$input: GenerateTestCertificateInput!
	) {
		Admin {
			CertificatesV2 {
				generateTestCertificate(input: $input) {
					certificateFile {
						url
					}
				}
			}
		}
	}
`;

interface State {
	contentNodeId?: string;
}

export const GenerateTestCertificateModal = () => {
	const [create, isCreating] =
		useMutation<GenerateTestCertificateModal_GenerateTestCertificateMutation>(
			GENERATE_TEST_CERTIFICATE_MUTATION,
		);

	const formik = useFormik<State>({
		initialValues: {
			contentNodeId: undefined,
		},
		validationSchema: Yup.object().shape({
			contentNodeId: Yup.string().required(
				`Das Feld ${TREE_I18N_MAP(TREE_I18N_KEY.contentNode)} wird benötigt.`,
			),
		}),
		onSubmit: (values) => {
			create({
				variables: {
					input: {
						contentNodeId: values.contentNodeId!,
					},
				},
				onCompleted: (result) => {
					window.open(
						result.Admin.CertificatesV2.generateTestCertificate?.certificateFile.url!,
						"_blank",
					);
				},
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<State, string>
				name={"contentNodeId"}
				label={`${TREE_I18N_MAP(TREE_I18N_KEY.contentNode)}`}
				component={ContentNodeSelectionField}
				formikConfig={formik}
				helpText={`Es wird das Zertifikat ${TREE_I18N_MAP(
					TREE_I18N_KEY.thisContentNodeAttributiveGenitive,
				)} verwendet.`}
			/>

			<Button
				disabled={Object.entries(formik.touched).length === 0 || isCreating}
				type="submit"
				label="Erstellen"
				className="mt-2"
			/>
		</form>
	);
};

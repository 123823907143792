import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { TagsScreen_TagsQuery } from "../../__generated__/TagsScreen_TagsQuery.graphql";
import { TagsTable } from "../components/tags/TagsTable";
import { BaseScreen } from "./BaseScreen";

const QUERY = graphql`
	query TagsScreen_TagsQuery {
		...TagsTable_QueryFragment
	}
`;

export const TagsScreen = () => {
	const data = useLazyLoadQuery<TagsScreen_TagsQuery>(QUERY, {}, { fetchPolicy: "network-only" });

	return (
		<BaseScreen>
			<h1>Schlagworte</h1>
			<TagsTable queryFragmentRef={data} />
		</BaseScreen>
	);
};

/**
 * @generated SignedSource<<86d5a90b3a7b92fa072bbc573344b006>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Salutation = "Mr" | "Mrs" | "Divers" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserDescription_UserFragment$data = {
  readonly extension: {
    readonly firstName?: string | null;
    readonly lastName?: string | null;
    readonly title?: string | null;
    readonly salutation?: Salutation | null;
  };
  readonly " $fragmentType": "UserDescription_UserFragment";
};
export type UserDescription_UserFragment = UserDescription_UserFragment$data;
export type UserDescription_UserFragment$key = {
  readonly " $data"?: UserDescription_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserDescription_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserDescription_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extension",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "salutation",
              "storageKey": null
            }
          ],
          "type": "InfectopharmUserExtensionImpl",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "ab9740f5c98959d3427731de5dfed62d";

export default node;

/**
 * @generated SignedSource<<c0a6d80113bd437e8844d2a9cca0006e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReleasedAtColumn_TreeNodeFragment$data = {
  readonly structureDefinition: {
    readonly firstReleasedAt?: string | null;
  };
  readonly " $fragmentType": "ReleasedAtColumn_TreeNodeFragment";
};
export type ReleasedAtColumn_TreeNodeFragment = ReleasedAtColumn_TreeNodeFragment$data;
export type ReleasedAtColumn_TreeNodeFragment$key = {
  readonly " $data"?: ReleasedAtColumn_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReleasedAtColumn_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReleasedAtColumn_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstReleasedAt",
              "storageKey": null
            }
          ],
          "type": "RootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "85ba4f797241963b9896cc973603b703";

export default node;

import React, { Suspense } from "react";
import { OrderList } from "primereact/orderlist";
import { Button } from "primereact/button";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { MessageDefinitionPagesOrderList_CreateMessageDefinitionPageMutation } from "../../../../../__generated__/MessageDefinitionPagesOrderList_CreateMessageDefinitionPageMutation.graphql";
import { MessageDefinitionPagesOrderList_DeleteMessageDefinitionPageMutation } from "../../../../../__generated__/MessageDefinitionPagesOrderList_DeleteMessageDefinitionPageMutation.graphql";
import { MessageDefinitionPagesOrderList_RearrangeMessageDefinitionPagesMutation } from "../../../../../__generated__/MessageDefinitionPagesOrderList_RearrangeMessageDefinitionPagesMutation.graphql";
import { MessageDefinitionPagesOrderList_MessageDefinitionFragment$key } from "../../../../../__generated__/MessageDefinitionPagesOrderList_MessageDefinitionFragment.graphql";
import { ProgressSpinner } from "primereact/progressspinner";
import { confirmDialog } from "primereact/confirmdialog";

const CUSTOMER_MESSAGE_DEFINITION__FRAGMENT = graphql`
	fragment MessageDefinitionPagesOrderList_MessageDefinitionFragment on MessageDefinition {
		id
		pages(after: null, first: null) @connection(key: "MessageDefinitionPagesOrderList_pages") {
			__id
			edges {
				node {
					id
					... on MessageDefinitionPage {
						internalTitle
						...EditMessageDefinitionPageForm_MessageDefinitionPageFragment
					}
				}
			}
		}
	}
`;

const CREATE_CUSTOMER_MESSAGE_DEFINITION_PAGE_MUTATION = graphql`
	mutation MessageDefinitionPagesOrderList_CreateMessageDefinitionPageMutation(
		$input: CreateMessageDefinitionPageInput!
		$connections: [ID!]!
	) {
		Admin {
			Messaging {
				createMessageDefinitionPage(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							id
							... on MessageDefinitionPage {
								internalTitle
								image {
									id
								}
							}
						}
					}
				}
			}
		}
	}
`;

const DELETE_CUSTOMER_MESSAGE_DEFINITION_PAGE_MUTATION = graphql`
	mutation MessageDefinitionPagesOrderList_DeleteMessageDefinitionPageMutation(
		$input: DeleteMessageDefinitionPageInput!
		$connections: [ID!]!
	) {
		Admin {
			Messaging {
				deleteMessageDefinitionPage(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;

const REARRANGE_CUSTOMER_MESSAGE_DEFINITION_PAGES_MUTATION = graphql`
	mutation MessageDefinitionPagesOrderList_RearrangeMessageDefinitionPagesMutation(
		$input: RearrangeMessageDefinitionPagesInput!
	) {
		Admin {
			Messaging {
				rearrangeMessageDefinitionPage(input: $input) {
					definition {
						...MessageDefinitionPagesOrderList_MessageDefinitionFragment
					}
				}
			}
		}
	}
`;

interface OwnProps {
	editPage: (item: any) => void;
	messageDefinitionFragmentRef: MessageDefinitionPagesOrderList_MessageDefinitionFragment$key;
}

export const MessageDefinitionPagesOrderList = ({
	editPage,
	messageDefinitionFragmentRef,
}: OwnProps) => {
	const messageDefinition =
		useFragment<MessageDefinitionPagesOrderList_MessageDefinitionFragment$key>(
			CUSTOMER_MESSAGE_DEFINITION__FRAGMENT,
			messageDefinitionFragmentRef,
		);

	const [createPageRaw, isCreatingPage] =
		useMutation<MessageDefinitionPagesOrderList_CreateMessageDefinitionPageMutation>(
			CREATE_CUSTOMER_MESSAGE_DEFINITION_PAGE_MUTATION,
		);

	const [deletePageRaw, isDeletingPage] =
		useMutation<MessageDefinitionPagesOrderList_DeleteMessageDefinitionPageMutation>(
			DELETE_CUSTOMER_MESSAGE_DEFINITION_PAGE_MUTATION,
		);

	const deletePageWithConfirmation = (internalTitle: string, pageId: string) =>
		confirmDialog({
			message: "Möchten Sie die Seite wirklich löschen?",
			header: "Seite löschen",
			icon: "pi pi-exclamation-triangle",
			acceptLabel: "Löschen",
			rejectLabel: "Abbrechen",
			acceptClassName: "p-button-danger",
			accept: () =>
				deletePageRaw({
					variables: {
						input: {
							ids: [pageId],
						},
						connections: [messageDefinition.pages.__id],
					},
				}),
		});

	const [rearrangePagesRaw, isRearrangingPages] =
		useMutation<MessageDefinitionPagesOrderList_RearrangeMessageDefinitionPagesMutation>(
			REARRANGE_CUSTOMER_MESSAGE_DEFINITION_PAGES_MUTATION,
		);

	const rearrangePages = (rearrangedPageIds: string[]) =>
		rearrangePagesRaw({
			variables: {
				input: {
					rearrangedPageIds: rearrangedPageIds,
				},
			},
		});

	const pages = messageDefinition.pages.edges?.filter((e) => !!e).map((e) => e!.node) || [];

	return (
		<Suspense fallback={<ProgressSpinner />}>
			{!(isCreatingPage || isDeletingPage || isRearrangingPages) ? (
				<>
					<Button
						onClick={() => {
							createPageRaw({
								variables: {
									input: {
										definitionRef: messageDefinition.id,
										internalTitle: "Neue Seite",
									},
									connections: [messageDefinition.pages.__id],
								},
							});
						}}
						label={"Neue Seite"}
						className="mb-3"
					/>
					<OrderList
						dragdrop={false}
						onChange={(e) =>
							rearrangePages(e.value.map((item: { id: string }) => item.id))
						}
						itemTemplate={(item) => {
							return (
								<div className="flex align-items-center">
									{item.internalTitle}
									<Button
										disabled={pages.length === 1}
										className="ml-auto"
										icon="pi pi-trash"
										onClick={() =>
											deletePageWithConfirmation(item.internalTitle, item.id)
										}
									/>
									<Button
										disabled={false}
										className="ml-2"
										icon="pi pi-pencil"
										onClick={() => editPage(item)}
									/>
								</div>
							);
						}}
						value={pages}
					/>
				</>
			) : (
				<ProgressSpinner />
			)}
		</Suspense>
	);
};

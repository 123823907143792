import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { DateTimeDisplay } from "../../../core/datetime/DateTimeDisplay";
import { ReleasedAtColumn_TreeNodeFragment$key } from "../../../../../__generated__/ReleasedAtColumn_TreeNodeFragment.graphql";

const TREE_NODE_FRAGMENT = graphql`
	fragment ReleasedAtColumn_TreeNodeFragment on TreeNode {
		structureDefinition {
			... on RootStructureDefinition {
				firstReleasedAt
			}
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef: ReleasedAtColumn_TreeNodeFragment$key;
}

export const ReleasedAtColumn = ({ treeNodeFragmentRef }: OwnProps) => {
	const treeNode = useFragment<ReleasedAtColumn_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	return <DateTimeDisplay value={treeNode.structureDefinition.firstReleasedAt} />;
};

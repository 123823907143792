import React from "react";
import { SetNodeExtensionsForm_TreeNodeFragment$key } from "../../__generated__/SetNodeExtensionsForm_TreeNodeFragment.graphql";

export interface ImplV2 {
	node: {
		createMetaForm: (node: SetNodeExtensionsForm_TreeNodeFragment$key) => JSX.Element;
		createTreeButton: (rootNodesConnectionId: string) => JSX.Element;
		cloneTreeButton: (rootNodesConnectionId: string, rootNodeId: string) => JSX.Element;
		generateTestCertificateButton: () => JSX.Element;
		elements: {
			createElementButtons: (eLearningContentNodeId: string) => JSX.Element | null;
		} | null;
		isTitleFieldForMultipleChoiceRequired?: boolean;
		attachmentTypes: string[] | null;
	} | null;
}

export const ImplContextV2 = React.createContext<ImplV2>({
	node: null,
});

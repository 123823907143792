import { useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { FetchPolicy } from "relay-runtime";

type RefetchFunction<T> = (
	params: T & { first: number },
	options: { fetchPolicy: FetchPolicy | undefined },
) => void;

export function useDebouncesFilters<T>(filterState: T, refetch: RefetchFunction<T>) {
	const [initialRender, setInitialRender] = useState(true);

	const debouncedRefetch = (filters: T) =>
		refetch({ ...filters, first: 20 }, { fetchPolicy: "network-only" });

	const debouncedEventHandler = useMemo(
		() => debounce(debouncedRefetch, 1000),
		// eslint-disable-next-line
		[],
	);

	useEffect(() => {
		if (!initialRender) {
			debouncedEventHandler(filterState);
		} else {
			setInitialRender(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterState]);
}

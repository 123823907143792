import React, { useContext, useEffect, useMemo } from "react";
import graphql from "babel-plugin-relay/macro";
import { readInlineData, useLazyLoadQuery, usePaginationFragment } from "react-relay";
import debounce from "lodash.debounce";
import {
	PublishedNodesFilterState,
	PublishedNodesSearchContext,
} from "./PublishedNodesSearchContainer";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { PublishedNodesTable_QueryFragment$key } from "../../../__generated__/PublishedNodesTable_QueryFragment.graphql";
import { PublishedNodesTable_Refetch } from "../../../__generated__/PublishedNodesTable_Refetch.graphql";
import { PublishedNodesTable_Query } from "../../../__generated__/PublishedNodesTable_Query.graphql";
import { TitleColumnPublishedNodes } from "./TitleColumnPublishedNodes";
import { PublishedNodesActionColumn } from "./PublishedNodesActionColumn";
import { Button } from "primereact/button";

const QUERY = graphql`
	query PublishedNodesTable_Query(
		$first: Int
		$filterByTitleOrDescriptionOrShortDescription: String
	) {
		...PublishedNodesTable_QueryFragment
			@arguments(
				first: $first
				filterByTitleOrDescriptionOrShortDescription: $filterByTitleOrDescriptionOrShortDescription
			)
	}
`;

const QUERY_FRAGMENT = graphql`
	fragment PublishedNodesTable_QueryFragment on Query
	@refetchable(queryName: "PublishedNodesTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 10 }
		after: { type: "String" }
		filterByTitleOrDescriptionOrShortDescription: { type: "String" }
	) {
		Admin {
			PublishedNodes {
				allNodes(
					first: $first
					after: $after
					filterByTitleOrDescriptionOrShortDescription: $filterByTitleOrDescriptionOrShortDescription
				) @connection(key: "PublishedNodesTable_allNodes") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							...PublishedNodesTable_PublishedNodesFragment
							typeDefinition {
								... on LearnOpportunityBranchTypeDefinition {
									children {
										id
										typeDefinition {
											... on LearnOpportunityELearningContentTypeDefinition {
												containedElementTypes
											}
										}
									}
								}
								definitionType
							}
						}
					}
				}
			}
		}
	}
`;

const TREE_NODE_FRAGMENT = graphql`
	fragment PublishedNodesTable_PublishedNodesFragment on LearnOpportunityV2 @inline {
		...TitleColumnPublishedNodes_PublishedNodesFragment
		...PublishedNodesActionColumn_NodeFragment
	}
`;

export const PublishedNodesTable = () => {
	const query = useLazyLoadQuery<PublishedNodesTable_Query>(QUERY, { first: 20 });
	const { filters } = useContext(PublishedNodesSearchContext);

	const { data, hasPrevious, hasNext, refetch, loadPrevious, loadNext } = usePaginationFragment<
		PublishedNodesTable_Refetch,
		PublishedNodesTable_QueryFragment$key
	>(QUERY_FRAGMENT, query);

	const publishedNodesConnection = data.Admin.PublishedNodes.allNodes || [];

	const debouncedRefetch = (filters: PublishedNodesFilterState) => {
		refetch({ ...filters, first: 20 }, { fetchPolicy: "network-only" });
	};

	const debouncedEventHandler = useMemo(
		() => debounce(debouncedRefetch, 1000),
		// eslint-disable-next-line
        [],
	);

	useEffect(() => {
		debouncedEventHandler(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);
	return (
		<div>
			<DataTable
				className="mb-3"
				value={
					publishedNodesConnection.edges
						?.filter((e) => !!e?.node)
						.map((e) => e!.node)
						.map((item: any) => readInlineData(TREE_NODE_FRAGMENT, item)) || []
				}
			>
				<Column
					header={"Titel"}
					body={(item) => <TitleColumnPublishedNodes publishedNodesFragmentRef={item} />}
				/>
				<Column
					header="Aktionen"
					style={{ width: "20%" }}
					body={(item) => <PublishedNodesActionColumn nodeFragmentRef={item} />}
				/>
			</DataTable>
			<div className="flex justify-content-center align-items-center">
				<Button
					disabled={!hasPrevious}
					onClick={() => loadPrevious(20)}
					className="mr-3 p-button-secondary"
				>
					Zurück
				</Button>
				<Button
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={() => loadNext(20)}
				>
					Weiter
				</Button>
			</div>
		</div>
	);
};

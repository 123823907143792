import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { useFragment } from "react-relay";
import { ContentConfigEditor_TreeNodeFragment$key } from "../../../../../../../__generated__/ContentConfigEditor_TreeNodeFragment.graphql";
import { PassContentConfigEditor } from "./PassContentConfigEditor";
import { RestartIfFailedContentConfigEditor } from "./RestartIfFailedContentConfigEditor";
import { RestartIfPassedContentConfigEditor } from "./RestartIfPassedContentConfigEditor";
import { RewardContentConfigEditor } from "./RewardContentConfigEditor";
import { StartContentConfigEditor } from "./StartContentConfigEditor";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../../../../../lms-admin-impl/i18n/tree.i18n";

const TREE_NODE_FRAGMENT = graphql`
	fragment ContentConfigEditor_TreeNodeFragment on TreeNode {
		...StartContentConfigEditor_TreeNodeFragment
		...PassContentConfigEditor_TreeNodeFragment
		...RestartIfFailedContentConfigEditor_TreeNodeFragment
		...RestartIfPassedContentConfigEditor_TreeNodeFragment
		...RewardContentConfigEditor_TreeNodeFragment
	}
`;

interface OwnProps {
	treeNodeFragmentRef: ContentConfigEditor_TreeNodeFragment$key;
}

export const ContentConfigEditor = ({ treeNodeFragmentRef }: OwnProps) => {
	const treeNodeFragment = useFragment<ContentConfigEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	return (
		<>
			<h2>Auf {TREE_I18N_MAP(TREE_I18N_KEY.contentNodeLevel)}</h2>
			<StartContentConfigEditor
				treeNodeFragmentRef={treeNodeFragment}
			></StartContentConfigEditor>
			<PassContentConfigEditor treeNodeFragmentRef={treeNodeFragment} />
			<RestartIfFailedContentConfigEditor treeNodeFragmentRef={treeNodeFragment} />
			<RestartIfPassedContentConfigEditor treeNodeFragmentRef={treeNodeFragment} />
			<RewardContentConfigEditor treeNodeFragmentRef={treeNodeFragment} />
		</>
	);
};

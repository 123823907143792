/**
 * @generated SignedSource<<00dfbcaeba8485f93e6e4d4120c067c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserStatusInput = "noProcess" | "initialValidation" | "changeInProgress" | "doccheck" | "inactive" | "efnNeeded" | "active" | "activeEfnNeeded" | "%future added value";
export type EditSpecificUserStatusesCanStartTreeConfigInput = {
  newDateTime?: string | null;
  rootNodeId: string;
  configId: string;
  newUserStatuses: ReadonlyArray<UserStatusInput>;
  clientMutationId?: string | null;
};
export type SpecificUserStatusesCanStartTreeConfigForm_EditMutation$variables = {
  input: EditSpecificUserStatusesCanStartTreeConfigInput;
};
export type SpecificUserStatusesCanStartTreeConfigForm_EditMutationVariables = SpecificUserStatusesCanStartTreeConfigForm_EditMutation$variables;
export type SpecificUserStatusesCanStartTreeConfigForm_EditMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly EditSpecificUserStatusesCanStartTreeConfig: {
        readonly rootNode: {
          readonly " $fragmentSpreads": FragmentRefs<"SpecificUserStatusesCanStartTreeConfigForm_TreeNodeFragment">;
        };
      } | null;
    };
  };
};
export type SpecificUserStatusesCanStartTreeConfigForm_EditMutationResponse = SpecificUserStatusesCanStartTreeConfigForm_EditMutation$data;
export type SpecificUserStatusesCanStartTreeConfigForm_EditMutation = {
  variables: SpecificUserStatusesCanStartTreeConfigForm_EditMutationVariables;
  response: SpecificUserStatusesCanStartTreeConfigForm_EditMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SpecificUserStatusesCanStartTreeConfigForm_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditSpecificUserStatusesCanStartTreeConfigPayload",
                "kind": "LinkedField",
                "name": "EditSpecificUserStatusesCanStartTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "SpecificUserStatusesCanStartTreeConfigForm_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SpecificUserStatusesCanStartTreeConfigForm_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditSpecificUserStatusesCanStartTreeConfigPayload",
                "kind": "LinkedField",
                "name": "EditSpecificUserStatusesCanStartTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "startConfigs",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "TypeDiscriminator",
                                    "abstractKey": "__isStartTreeConfig"
                                  },
                                  (v2/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "configType",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "dateTime",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "userStatuses",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "SpecifiedUserStatusesCanStartTreeConfig",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "873910fa3aa36476cf80c88a7aaa0fb2",
    "id": null,
    "metadata": {},
    "name": "SpecificUserStatusesCanStartTreeConfigForm_EditMutation",
    "operationKind": "mutation",
    "text": "mutation SpecificUserStatusesCanStartTreeConfigForm_EditMutation(\n  $input: EditSpecificUserStatusesCanStartTreeConfigInput!\n) {\n  Admin {\n    Tree {\n      EditSpecificUserStatusesCanStartTreeConfig(input: $input) {\n        rootNode {\n          ...SpecificUserStatusesCanStartTreeConfigForm_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment SpecificUserStatusesCanStartTreeConfigForm_StartTreeConfigFragment on StartTreeConfig {\n  __isStartTreeConfig: __typename\n  ... on SpecifiedUserStatusesCanStartTreeConfig {\n    id\n    configType\n    dateTime\n    userStatuses\n  }\n}\n\nfragment SpecificUserStatusesCanStartTreeConfigForm_TreeNodeFragment on TreeNode {\n  id\n  structureDefinition {\n    __typename\n    ... on RootStructureDefinition {\n      startConfigs {\n        __typename\n        ...SpecificUserStatusesCanStartTreeConfigForm_StartTreeConfigFragment\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0d679cc2f01ead06d5ce1564b3465880";

export default node;

/**
 * @generated SignedSource<<66be99f462540693e8cfbcba5fabee95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditEmailTemplateScreen_EmailTemplateFragment$data = {
  readonly id: string;
  readonly template: {
    readonly key: string;
    readonly subject: string;
    readonly previewText: string;
    readonly body: string;
    readonly variables: ReadonlyArray<string>;
  };
  readonly " $fragmentType": "EditEmailTemplateScreen_EmailTemplateFragment";
};
export type EditEmailTemplateScreen_EmailTemplateFragment = EditEmailTemplateScreen_EmailTemplateFragment$data;
export type EditEmailTemplateScreen_EmailTemplateFragment$key = {
  readonly " $data"?: EditEmailTemplateScreen_EmailTemplateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditEmailTemplateScreen_EmailTemplateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditEmailTemplateScreen_EmailTemplateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EmailTemplate",
      "kind": "LinkedField",
      "name": "template",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subject",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "previewText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "variables",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PersonalizedEmailTemplate",
  "abstractKey": null
};

(node as any).hash = "caf55fad662fdd72bd2024878f409221";

export default node;

/**
 * @generated SignedSource<<e98cf35205c114e5d0c7234a2dd65171>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LandingPagesV2Screen_Query$variables = {};
export type LandingPagesV2Screen_QueryVariables = LandingPagesV2Screen_Query$variables;
export type LandingPagesV2Screen_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LandingPagesV2Table_QueryFragment">;
};
export type LandingPagesV2Screen_QueryResponse = LandingPagesV2Screen_Query$data;
export type LandingPagesV2Screen_Query = {
  variables: LandingPagesV2Screen_QueryVariables;
  response: LandingPagesV2Screen_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LandingPagesV2Screen_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "LandingPagesV2Table_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LandingPagesV2Screen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LandingPageV2AdminSchema",
            "kind": "LinkedField",
            "name": "LandingPageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LandingPageV2Connection",
                "kind": "LinkedField",
                "name": "LandingPages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LandingPageV2Edge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LandingPageV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "urlParam",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LearnOpportunitiesV2Connection",
                            "kind": "LinkedField",
                            "name": "rootNodes",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LearnOpportunitiesV2Edge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LearnOpportunityV2",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v0/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "structureDefinition",
                                        "plural": false,
                                        "selections": [
                                          (v1/*: any*/),
                                          (v2/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": null,
                                                "kind": "LinkedField",
                                                "name": "extension",
                                                "plural": false,
                                                "selections": [
                                                  (v1/*: any*/),
                                                  {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "isVisibleOnlyForAdmins",
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "type": "LearnOpportunityRootExtensionImpl",
                                                    "abstractKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "type": "LearnOpportunityRootStructureDefinition",
                                            "abstractKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "connection",
                "key": "LandingPagesV2Table_LandingPages",
                "kind": "LinkedHandle",
                "name": "LandingPages"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9bcce91f72a294ba7383275c7ecd5dec",
    "id": null,
    "metadata": {},
    "name": "LandingPagesV2Screen_Query",
    "operationKind": "query",
    "text": "query LandingPagesV2Screen_Query {\n  ...LandingPagesV2Table_QueryFragment\n}\n\nfragment ActionColumn_LandingPageFragment on LandingPageV2 {\n  id\n  urlParam\n}\n\nfragment ContentsColumn_LandingPageFragment on LandingPageV2 {\n  rootNodes {\n    edges {\n      node {\n        id\n        structureDefinition {\n          __typename\n          title\n          ... on LearnOpportunityRootStructureDefinition {\n            extension {\n              __typename\n              ... on LearnOpportunityRootExtensionImpl {\n                isVisibleOnlyForAdmins\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment DescriptionColumn_LandingPageFragment on LandingPageV2 {\n  description\n}\n\nfragment LandingPagesV2Table_LandingPageFragment on LandingPageV2 {\n  ...ActionColumn_LandingPageFragment\n  ...ContentsColumn_LandingPageFragment\n  ...DescriptionColumn_LandingPageFragment\n  ...TitleColumn_LandingPageFragment\n  ...UrlPathColumn_LandingPageFragment\n}\n\nfragment LandingPagesV2Table_QueryFragment on Query {\n  Admin {\n    LandingPageV2 {\n      LandingPages {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            ...LandingPagesV2Table_LandingPageFragment\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n\nfragment TitleColumn_LandingPageFragment on LandingPageV2 {\n  title\n}\n\nfragment UrlPathColumn_LandingPageFragment on LandingPageV2 {\n  urlParam\n}\n"
  }
};
})();

(node as any).hash = "5c30bfa280f6b1750817733c3383e788";

export default node;

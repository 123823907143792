/**
 * @generated SignedSource<<8aa4bbf97c9958637f1475046ffe9cbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MaintenanceScreen_Query$variables = {};
export type MaintenanceScreen_QueryVariables = MaintenanceScreen_Query$variables;
export type MaintenanceScreen_Query$data = {
  readonly Maintenance: {
    readonly MaintenanceUpdate: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"MaintenanceToggleButton_Fragment">;
    };
  };
};
export type MaintenanceScreen_QueryResponse = MaintenanceScreen_Query$data;
export type MaintenanceScreen_Query = {
  variables: MaintenanceScreen_QueryVariables;
  response: MaintenanceScreen_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MaintenanceScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MaintenanceQueries",
        "kind": "LinkedField",
        "name": "Maintenance",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MaintenanceUpdate",
            "kind": "LinkedField",
            "name": "MaintenanceUpdate",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MaintenanceToggleButton_Fragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MaintenanceScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MaintenanceQueries",
        "kind": "LinkedField",
        "name": "Maintenance",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MaintenanceUpdate",
            "kind": "LinkedField",
            "name": "MaintenanceUpdate",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isUpdating",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "827d34a528607ac522144aad056c5fd4",
    "id": null,
    "metadata": {},
    "name": "MaintenanceScreen_Query",
    "operationKind": "query",
    "text": "query MaintenanceScreen_Query {\n  Maintenance {\n    MaintenanceUpdate {\n      id\n      ...MaintenanceToggleButton_Fragment\n    }\n  }\n}\n\nfragment MaintenanceToggleButton_Fragment on MaintenanceUpdate {\n  id\n  isUpdating\n}\n"
  }
};
})();

(node as any).hash = "2a31e61aad5213196bb044e3d27a4669";

export default node;

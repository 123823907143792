/**
 * @generated SignedSource<<0c0ca27fbf9c74482abff1abf26a52ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RecipientType = "user" | "admin" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EmailRewardConfigurationForm_EmailRewardConfigurationV2Fragment$data = {
  readonly id: string;
  readonly subject: string;
  readonly body: string;
  readonly previewText: string;
  readonly recipientType: RecipientType;
  readonly attachCertificates: boolean;
  readonly " $fragmentType": "EmailRewardConfigurationForm_EmailRewardConfigurationV2Fragment";
};
export type EmailRewardConfigurationForm_EmailRewardConfigurationV2Fragment = EmailRewardConfigurationForm_EmailRewardConfigurationV2Fragment$data;
export type EmailRewardConfigurationForm_EmailRewardConfigurationV2Fragment$key = {
  readonly " $data"?: EmailRewardConfigurationForm_EmailRewardConfigurationV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmailRewardConfigurationForm_EmailRewardConfigurationV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmailRewardConfigurationForm_EmailRewardConfigurationV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previewText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attachCertificates",
      "storageKey": null
    }
  ],
  "type": "EmailRewardConfiguration",
  "abstractKey": null
};

(node as any).hash = "b9a3198e286c2fbbe5c0cca64d898967";

export default node;

export const REWARD_KINDS: { [key: string]: string } = {
	Certificate: "Zertifikat",
	Email: "E-Mail-Behlohnt",
	MakeFachberater: "Fachberater",
	PacPoints: "PAC-Punkte",
	PharmaPoints: "BAK-Punkte",
	Points: "Punkte",
};

export const REWARD_RESULT_KINDS: { [key: string]: string } = {
	CertificateRewardAlreadyRewardedResult: "Bereits belohnt",
	CertificateRewardWasNotPossibleResult: "Nicht belohnt",
	CertificateRewardWasPossibleResult: "Belohnt",
	EmailRewardAlreadyRewardedResult: "Bereits belohnt",
	EmailRewardWasNotPossibleResult: "Nicht belohnt",
	EmailRewardWasPossibleResult: "Belohnt",
	FachberaterWasAwarded: "Belohnt",
	MakeFachberaterRewardAlreadyRewardedResult: "Bereits belohnt",
	MakeFachberaterRewardWasNotPossibleResult: "Nicht belohnt",
	PacPointsRewardAlreadyRewardedResult: "Bereits belohnt",
	PacPointsRewardWasNotPossibleResult: "Nicht belohnt",
	PacPointsRewardWasPossibleResult: "Belohnt",
	PharmaPointsRewardAlreadyRewardedResult: "Bereits belohnt",
	PharmaPointsRewardWasNotPossibleResult: "Nicht belohnt",
	PharmaPointsRewardWasPossibleResult: "Belohnt",
	PointsRewardAlreadyRewardedResult: "Bereits belohnt",
	PointsRewardWasNotPossibleResult: "Nicht belohnt",
	PointsRewardWasPossibleResult: "Belohnt",
};

/**
 * @generated SignedSource<<3c155d66af483421d118f42a89fc9ca5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RearrangeMessageDefinitionPagesInput = {
  rearrangedPageIds: ReadonlyArray<string>;
  clientMutationId?: string | null;
};
export type MessageDefinitionPagesOrderList_RearrangeMessageDefinitionPagesMutation$variables = {
  input: RearrangeMessageDefinitionPagesInput;
};
export type MessageDefinitionPagesOrderList_RearrangeMessageDefinitionPagesMutationVariables = MessageDefinitionPagesOrderList_RearrangeMessageDefinitionPagesMutation$variables;
export type MessageDefinitionPagesOrderList_RearrangeMessageDefinitionPagesMutation$data = {
  readonly Admin: {
    readonly Messaging: {
      readonly rearrangeMessageDefinitionPage: {
        readonly definition: {
          readonly " $fragmentSpreads": FragmentRefs<"MessageDefinitionPagesOrderList_MessageDefinitionFragment">;
        };
      } | null;
    };
  };
};
export type MessageDefinitionPagesOrderList_RearrangeMessageDefinitionPagesMutationResponse = MessageDefinitionPagesOrderList_RearrangeMessageDefinitionPagesMutation$data;
export type MessageDefinitionPagesOrderList_RearrangeMessageDefinitionPagesMutation = {
  variables: MessageDefinitionPagesOrderList_RearrangeMessageDefinitionPagesMutationVariables;
  response: MessageDefinitionPagesOrderList_RearrangeMessageDefinitionPagesMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageDefinitionPagesOrderList_RearrangeMessageDefinitionPagesMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessagingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Messaging",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "RearrangeMessageDefinitionPagesPayload",
                "kind": "LinkedField",
                "name": "rearrangeMessageDefinitionPage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessageDefinition",
                    "kind": "LinkedField",
                    "name": "definition",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "MessageDefinitionPagesOrderList_MessageDefinitionFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MessageDefinitionPagesOrderList_RearrangeMessageDefinitionPagesMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessagingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Messaging",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "RearrangeMessageDefinitionPagesPayload",
                "kind": "LinkedField",
                "name": "rearrangeMessageDefinitionPage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessageDefinition",
                    "kind": "LinkedField",
                    "name": "definition",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MessageDefinitionPagesConnection",
                        "kind": "LinkedField",
                        "name": "pages",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MessageDefinitionPagesEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MessageDefinitionPage",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "internalTitle",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "File",
                                    "kind": "LinkedField",
                                    "name": "image",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "fileType",
                                        "storageKey": null
                                      },
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "uploadDateTime",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "url",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "kind": "ClientExtension",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__id",
                                "storageKey": null
                              }
                            ]
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "filters": null,
                        "handle": "connection",
                        "key": "MessageDefinitionPagesOrderList_pages",
                        "kind": "LinkedHandle",
                        "name": "pages"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0ba19b90813a8e9e85e271349eba43f4",
    "id": null,
    "metadata": {},
    "name": "MessageDefinitionPagesOrderList_RearrangeMessageDefinitionPagesMutation",
    "operationKind": "mutation",
    "text": "mutation MessageDefinitionPagesOrderList_RearrangeMessageDefinitionPagesMutation(\n  $input: RearrangeMessageDefinitionPagesInput!\n) {\n  Admin {\n    Messaging {\n      rearrangeMessageDefinitionPage(input: $input) {\n        definition {\n          ...MessageDefinitionPagesOrderList_MessageDefinitionFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment EditMessageDefinitionPageForm_MessageDefinitionPageFragment on MessageDefinitionPage {\n  id\n  internalTitle\n  image {\n    fileType\n    id\n    name\n    uploadDateTime\n    url\n  }\n}\n\nfragment MessageDefinitionPagesOrderList_MessageDefinitionFragment on MessageDefinition {\n  id\n  pages {\n    edges {\n      node {\n        id\n        internalTitle\n        ...EditMessageDefinitionPageForm_MessageDefinitionPageFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6bc2ec6ba81b1c8f30c919e03e1fbcd3";

export default node;

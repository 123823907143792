import { Button } from "primereact/button";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { ImportRandomMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutation } from "../../../../__generated__/ImportRandomMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutation.graphql";

const IMPORT_MUTATION = graphql`
	mutation ImportRandomMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutation(
		$input: ImportRandomMultipleChoiceElementsInput!
	) {
		Admin {
			ImportRandomMultipleChoice {
				importRandomMultipleChoiceElements(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;

interface OwnProps {
	poolId: string;
	refresh: () => void;
}

export const ImportRandomMultipleChoiceElementsButton = ({ poolId, refresh }: OwnProps) => {
	const [importMutation, isImporting] =
		useMutation<ImportRandomMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutation>(
			IMPORT_MUTATION,
		);

	function importDialog() {
		const input = document.createElement("input");

		input.type = "file";

		input.onchange = () => {
			const files = Array.from(input.files || []);
			const reader = new FileReader();
			reader.onload = function (evt) {
				if (evt.target?.readyState !== 2) return;
				if (evt.target.error) {
					alert("Error while reading file");
					return;
				}

				const content = evt.target.result;

				importMutation({
					variables: {
						input: {
							id: poolId,
							fileContent: content as string,
						},
					},
					onCompleted: () => {
						refresh();
					},
				});
			};

			reader.readAsText(files[0]);
		};
		input.click();
	}

	return (
		<>
			<Button
				disabled={isImporting}
				icon="pi pi-cloud-upload"
				tooltip="Bausteine importieren"
				onClick={() => {
					importDialog();
				}}
			/>
		</>
	);
};

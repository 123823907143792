import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import { MaintenanceToggleButton_Fragment$key } from "../../../__generated__/MaintenanceToggleButton_Fragment.graphql";
import { MaintenanceToggleButton_UpdateMutation } from "../../../__generated__/MaintenanceToggleButton_UpdateMutation.graphql";

const FRAGMENT = graphql`
	fragment MaintenanceToggleButton_Fragment on MaintenanceUpdate {
		id
		isUpdating
	}
`;
const UPDATE_MUTATION = graphql`
	mutation MaintenanceToggleButton_UpdateMutation($input: UpdateMaintenanceUpdateInput!) {
		Admin {
			Maintenance {
				updateMaintenanceUpdate(input: $input) {
					data {
						node {
							id
							isUpdating
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	disabled?: boolean;
	userFragmentRef: MaintenanceToggleButton_Fragment$key;
}

export const MaintenanceToggleButton = ({ disabled, userFragmentRef }: OwnProps) => {
	const maintenance = useFragment<MaintenanceToggleButton_Fragment$key>(
		FRAGMENT,
		userFragmentRef,
	);

	const [maintenanceButton, isInFlight] =
		useMutation<MaintenanceToggleButton_UpdateMutation>(UPDATE_MUTATION);

	return (
		<div className="flex flex-column mt-8">
			<h2>App und Webapp</h2>
			<Button
				className="mt-4"
				type={"button"}
				disabled={disabled || isInFlight}
				onClick={() =>
					maintenanceButton({
						variables: {
							input: {
								id: maintenance.id,
								data: {
									id: maintenance.id,
									isUpdating: !maintenance.isUpdating,
								},
							},
						},
						onCompleted: () => {
							toast("MaintenanceStatus wurden erfolgreich geändert", {
								type: "success",
							});
						},
					})
				}
			>
				{maintenance.isUpdating ? "Wartung Screen verstecken" : "Wartung Screen zeigen"}
			</Button>
		</div>
	);
};

/**
 * @generated SignedSource<<deb7b38b742a3d2e411f88a3d7da6ea7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ContentsMultiselectV2_Query$variables = {
  first?: number | null;
  after?: string | null;
  query?: string | null;
};
export type ContentsMultiselectV2_QueryVariables = ContentsMultiselectV2_Query$variables;
export type ContentsMultiselectV2_Query$data = {
  readonly LearnV2: {
    readonly SearchLearnOpportunities: {
      readonly pageInfo: {
        readonly endCursor: string | null;
        readonly hasPreviousPage: boolean;
        readonly hasNextPage: boolean;
        readonly startCursor: string | null;
      };
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly structureDefinition: {
            readonly title: string;
          };
        };
      } | null> | null;
    };
  };
};
export type ContentsMultiselectV2_QueryResponse = ContentsMultiselectV2_Query$data;
export type ContentsMultiselectV2_Query = {
  variables: ContentsMultiselectV2_QueryVariables;
  response: ContentsMultiselectV2_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startCursor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContentsMultiselectV2_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LearnV2Queries",
        "kind": "LinkedField",
        "name": "LearnV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "LearnOpportunitiesV2Connection",
            "kind": "LinkedField",
            "name": "SearchLearnOpportunities",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnOpportunitiesV2Edge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LearnOpportunityV2",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ContentsMultiselectV2_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LearnV2Queries",
        "kind": "LinkedField",
        "name": "LearnV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "LearnOpportunitiesV2Connection",
            "kind": "LinkedField",
            "name": "SearchLearnOpportunities",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnOpportunitiesV2Edge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LearnOpportunityV2",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6514ebf3bc5fe1d57a1a52f979f19b5b",
    "id": null,
    "metadata": {},
    "name": "ContentsMultiselectV2_Query",
    "operationKind": "query",
    "text": "query ContentsMultiselectV2_Query(\n  $first: Int\n  $after: String\n  $query: String\n) {\n  LearnV2 {\n    SearchLearnOpportunities(first: $first, after: $after, query: $query) {\n      pageInfo {\n        endCursor\n        hasPreviousPage\n        hasNextPage\n        startCursor\n      }\n      edges {\n        node {\n          id\n          structureDefinition {\n            __typename\n            title\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d1a1568caad320709d3653feac2e340c";

export default node;

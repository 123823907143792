/**
 * @generated SignedSource<<7fac634a59238ee52c6472b828472b81>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserColumn_FeedbackFragment$data = {
  readonly user: {
    readonly " $fragmentSpreads": FragmentRefs<"ProjectUserDescription_UserFragment">;
  } | null;
  readonly " $fragmentType": "UserColumn_FeedbackFragment";
};
export type UserColumn_FeedbackFragment = UserColumn_FeedbackFragment$data;
export type UserColumn_FeedbackFragment$key = {
  readonly " $data"?: UserColumn_FeedbackFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserColumn_FeedbackFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserColumn_FeedbackFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectUserDescription_UserFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FeedbackV2",
  "abstractKey": null
};

(node as any).hash = "3f1c993dfdeeaba7b4827bdb38fa950f";

export default node;

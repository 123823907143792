/**
 * @generated SignedSource<<85379204cae4a93ef18fb09b9f936a4c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExportFachberaterLettersInput = {
  fromDateOpt?: string | null;
  toDateOpt?: string | null;
  contentNodeId: string;
  clientMutationId?: string | null;
};
export type FachberaterCertificatesAndLettersExporter_ExportLettersMutation$variables = {
  input: ExportFachberaterLettersInput;
};
export type FachberaterCertificatesAndLettersExporter_ExportLettersMutationVariables = FachberaterCertificatesAndLettersExporter_ExportLettersMutation$variables;
export type FachberaterCertificatesAndLettersExporter_ExportLettersMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly exportFachberaterLetters: {
        readonly clientMutationId: string | null;
      } | null;
    };
  };
};
export type FachberaterCertificatesAndLettersExporter_ExportLettersMutationResponse = FachberaterCertificatesAndLettersExporter_ExportLettersMutation$data;
export type FachberaterCertificatesAndLettersExporter_ExportLettersMutation = {
  variables: FachberaterCertificatesAndLettersExporter_ExportLettersMutationVariables;
  response: FachberaterCertificatesAndLettersExporter_ExportLettersMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TreeAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Tree",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "ExportFachberaterLettersPayload",
            "kind": "LinkedField",
            "name": "exportFachberaterLetters",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FachberaterCertificatesAndLettersExporter_ExportLettersMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FachberaterCertificatesAndLettersExporter_ExportLettersMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "646e1112f5be73f48dca65777dc6f33f",
    "id": null,
    "metadata": {},
    "name": "FachberaterCertificatesAndLettersExporter_ExportLettersMutation",
    "operationKind": "mutation",
    "text": "mutation FachberaterCertificatesAndLettersExporter_ExportLettersMutation(\n  $input: ExportFachberaterLettersInput!\n) {\n  Admin {\n    Tree {\n      exportFachberaterLetters(input: $input) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "34e006db667cc41ae204fb1731b53de8";

export default node;

/**
 * @generated SignedSource<<0561940dfb9eb17ffb129dc376655200>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditInfectopharmAttachmentInput = {
  titleOpt?: string | null;
  descriptionOpt?: string | null;
  attachmentTypeOpt?: string | null;
  nodeId: string;
  fileId: string;
  attachmentId: string;
  clientMutationId?: string | null;
};
export type AttachmentForm_EditAttachmentMutation$variables = {
  input: EditInfectopharmAttachmentInput;
};
export type AttachmentForm_EditAttachmentMutationVariables = AttachmentForm_EditAttachmentMutation$variables;
export type AttachmentForm_EditAttachmentMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly editInfectopharmAttachment: {
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"AttachmentEditor_TreeNodeFragment">;
        };
      } | null;
    };
  };
};
export type AttachmentForm_EditAttachmentMutationResponse = AttachmentForm_EditAttachmentMutation$data;
export type AttachmentForm_EditAttachmentMutation = {
  variables: AttachmentForm_EditAttachmentMutationVariables;
  response: AttachmentForm_EditAttachmentMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AttachmentForm_EditAttachmentMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditInfectopharmAttachmentPayload",
                "kind": "LinkedField",
                "name": "editInfectopharmAttachment",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "AttachmentEditor_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AttachmentForm_EditAttachmentMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditInfectopharmAttachmentPayload",
                "kind": "LinkedField",
                "name": "editInfectopharmAttachment",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "attachments",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "attachmentType",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "File",
                                "kind": "LinkedField",
                                "name": "file",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "InfectopharmFileAttachment",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "00b18eba9213334cfa94057090a3462d",
    "id": null,
    "metadata": {},
    "name": "AttachmentForm_EditAttachmentMutation",
    "operationKind": "mutation",
    "text": "mutation AttachmentForm_EditAttachmentMutation(\n  $input: EditInfectopharmAttachmentInput!\n) {\n  Admin {\n    Tree {\n      editInfectopharmAttachment(input: $input) {\n        node {\n          ...AttachmentEditor_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment AttachmentEditor_TreeNodeFragment on TreeNode {\n  id\n  attachments {\n    __typename\n    id\n    ... on InfectopharmFileAttachment {\n      title\n      description\n      attachmentType\n      file {\n        id\n        name\n      }\n    }\n    ...AttachmentForm_InfectopharmFileAttachmentFragment\n  }\n}\n\nfragment AttachmentForm_InfectopharmFileAttachmentFragment on InfectopharmFileAttachment {\n  id\n  file {\n    id\n  }\n  title\n  description\n  attachmentType\n}\n"
  }
};
})();

(node as any).hash = "25da7b1e67aec80083f67b598e26cc60";

export default node;

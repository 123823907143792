/**
 * @generated SignedSource<<175fff25e48cfab79d1066129c96e3a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditAwardPacPointsConfigurationForm_AwardPacPointsFragment$data = {
  readonly id: string;
  readonly amount: number;
  readonly " $fragmentType": "EditAwardPacPointsConfigurationForm_AwardPacPointsFragment";
};
export type EditAwardPacPointsConfigurationForm_AwardPacPointsFragment = EditAwardPacPointsConfigurationForm_AwardPacPointsFragment$data;
export type EditAwardPacPointsConfigurationForm_AwardPacPointsFragment$key = {
  readonly " $data"?: EditAwardPacPointsConfigurationForm_AwardPacPointsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditAwardPacPointsConfigurationForm_AwardPacPointsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditAwardPacPointsConfigurationForm_AwardPacPointsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    }
  ],
  "type": "AwardPacPointsRewardConfiguration",
  "abstractKey": null
};

(node as any).hash = "f00c6bb239ff90e748205e08e0d80b40";

export default node;

import { Editor } from "primereact/editor";
import { ErrorText } from "../errors/ErrorText";
import { RenderConfig } from "./ValidatedField";
import Quill from "quill";
import { useEffect, useState } from "react";

const block = Quill.import("blots/block");
block.tagName = "DIV";
Quill.register(block, true);

export const DefaultTextEditorComponent = ({
	fieldName,
	fieldValue,
	updateField,
	noLineBreak,
	onChange,
	showListButtons,
}: RenderConfig<string>) => {
	const [errorLineBreak, setErrorLineBreak] = useState(false);
	const updateFieldValue = (text: string | undefined) => {
		const updatedValue = text;
		if (noLineBreak && updatedValue) {
			updateField(
				updatedValue
					.replace(/<\/div><div><br><\/div><div>/, " ")
					.replace(/<\/div><div>/, " ")
					.replace(/<br>/, " ") || undefined,
			);
			if (updatedValue.includes("</div><div>")) {
				setErrorLineBreak(true);
			} else {
				setErrorLineBreak(false);
			}
		} else {
			updateField(updatedValue || undefined);
		}
	};

	useEffect(() => {
		if (noLineBreak) {
			if (fieldValue?.includes("</div><div>")) {
				setErrorLineBreak(true);
				fieldValue
					.replace(/<\/div><div><br><\/div><div>/, " ")
					.replace(/<\/div><div>/, " ")
					.replace(/<br>/, " ");
			}
		}
	}, [fieldValue, noLineBreak]);

	const replaceHtmlTags = (text: any) => {
		const regex = /(<([^>]+)>)/gi;
		if (text !== undefined) {
			return text.replace(regex, "");
		}
	};

	const checkIfTheFormIsGettingEdited = (e: any) => {
		if (
			replaceHtmlTags(fieldValue) !== replaceHtmlTags(e.htmlValue) &&
			fieldValue !== undefined
		) {
			if (onChange) {
				onChange();
			}
		}
	};
	return (
		<div>
			{errorLineBreak && (
				<ErrorText errortext={"Bitte keine Zeilenumbrüche einfügen"}></ErrorText>
			)}{" "}
			<Editor
				headerTemplate={
					<div className="p-editor-toolbar">
						<span className="ql-formats">
							<button className="ql-bold" aria-label="Bold" />
							<button className="ql-italic" aria-label="Italic" />
							<button className="ql-underline" aria-label="Underline" />
							{showListButtons && (
								<button
									className="ql-list"
									value="ordered"
									aria-label="Geordnete Liste"
								/>
							)}
							{showListButtons && (
								<button
									className="ql-list"
									value="bullet"
									aria-label="Ungeordnete Liste"
								/>
							)}
							<button className="ql-clean" aria-label="Style entfernen" />
						</span>
					</div>
				}
				id={fieldName}
				value={fieldValue}
				onTextChange={(e) => {
					const newValue = e.htmlValue || undefined;
					checkIfTheFormIsGettingEdited(e);
					setTimeout(() => updateFieldValue(newValue), 100);
				}}
			/>
		</div>
	);
};

import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { CreatedAtColumn_FeedbackFragment$key } from "../../../__generated__/CreatedAtColumn_FeedbackFragment.graphql";
import { DateTimeDisplay } from "../core/datetime/DateTimeDisplay";

const FEEDBACK_FRAGMENT = graphql`
	fragment CreatedAtColumn_FeedbackFragment on FeedbackV2 {
		createdAt
	}
`;

interface OwnProps {
	feedbackFragmentRef: CreatedAtColumn_FeedbackFragment$key;
}

export const CreatedAtColumn = ({ feedbackFragmentRef }: OwnProps) => {
	const feedback = useFragment<CreatedAtColumn_FeedbackFragment$key>(
		FEEDBACK_FRAGMENT,
		feedbackFragmentRef,
	);

	return <DateTimeDisplay value={feedback.createdAt} />;
};

import {
	DataTable,
	DataTableSelectionSingleChangeEvent,
	DataTableValueArray,
} from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { FilesTable_FilesListFragment$key } from "../../../__generated__/FilesTable_FilesListFragment.graphql";
import { DateTimeDisplay } from "../core/datetime/DateTimeDisplay";
import {
	FileUpload,
	FileUploadErrorEvent,
	FileUploadHeaderTemplateOptions,
} from "primereact/fileupload";
import { useFileUpload } from "../../functions/hooks/useFileUpload";
import styled from "styled-components";
import { FilesTable_Refetch } from "../../../__generated__/FilesTable_Refetch.graphql";
import { FileFilters } from "./FileFilters";
import { FileSearchContainer, FilesFilters, FilesSearchContext } from "./FileSearchContainer";
import { useDebouncesFilters } from "../core/filters/useDebouncesFilters";
import React, { useContext, useState } from "react";
import { FileIsUsedInNodeAttachmentsDialog } from "./FileIsUsedInNodeAttachmentsDialog";
import { FileIsAlreadyInDBDialog } from "./FileIsAlreadyInDBDialog";
import { FilesTable_Query } from "../../../__generated__/FilesTable_Query.graphql";
import { Permission } from "../../../__generated__/MainNavigation_Query.graphql";
import { useSelector } from "react-redux";
import { selectHasPermissions } from "../../redux/slices/CurrentUserSlice";

const FILES_FRAGMENT = graphql`
	fragment FilesTable_FilesListFragment on Query
	@refetchable(queryName: "FilesTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		filterByName: { type: "String" }
		filterByFileTypes: { type: "[String!]" }
		filterByFromDateTimeInclusive: { type: "ZonedDateTIme" }
		filterByToDateTimeInclusive: { type: "ZonedDateTIme" }
		filterFileTagsIncluded: { type: "[String!]" }
		# DefaultValue is here the CertificateTag (relay compiler does not allow us to use it directly here)
		filterFileTagsExcluded: { type: "[String!]", defaultValue: ["IssuedCertificate"] }
	) {
		Admin {
			Files {
				Files(
					first: $first
					after: $after
					name: $filterByName
					fileType: $filterByFileTypes
					fromDateTimeInclusive: $filterByFromDateTimeInclusive
					toDateTimeInclusive: $filterByToDateTimeInclusive
					tagsIncluded: $filterFileTagsIncluded
					tagsExcluded: $filterFileTagsExcluded
				) @connection(key: "FilesTable_Files") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							name
							fileType
							accessType
							uploadDateTime
							thumbnail
							url
						}
					}
				}
			}
		}
	}
`;

const QUERY = graphql`
	query FilesTable_Query($fileName: String) {
		Admin {
			FileAlreadyExist {
				Files(fileName: $fileName) {
					fileAlreadyExist {
						name
					}
				}
			}
		}
	}
`;

interface SelectionSettings {
	selection: any;
	onSelectionChange: (
		selection: DataTableSelectionSingleChangeEvent<DataTableValueArray>,
	) => void;
}

interface OwnProps {
	filesFragmentRef: FilesTable_FilesListFragment$key;
	selectionSettings?: SelectionSettings;
}

export const FilesTable = ({ filesFragmentRef, selectionSettings }: OwnProps) => (
	<FileSearchContainer>
		<FilesTableComponent
			filesFragmentRef={filesFragmentRef}
			selectionSettings={selectionSettings}
		/>
	</FileSearchContainer>
);

const FilesTableComponent = ({ filesFragmentRef, selectionSettings }: OwnProps) => {
	const { filters } = useContext(FilesSearchContext);

	const {
		data: { Admin },
		hasPrevious,
		hasNext,
		loadPrevious,
		loadNext,
		refetch,
	} = usePaginationFragment<FilesTable_Refetch, FilesTable_FilesListFragment$key>(
		FILES_FRAGMENT,
		filesFragmentRef,
	);

	useDebouncesFilters<FilesFilters>(filters, refetch);

	const [selectedFileName, setSelectedFileName] = useState("");
	const query = useLazyLoadQuery<FilesTable_Query>(
		QUERY,
		{
			fileName: selectedFileName,
		},
		{ fetchPolicy: "network-only" },
	);
	const isFileInDb =
		query.Admin.FileAlreadyExist.Files.map((file) => file.fileAlreadyExist).length !== 0;

	const files = Admin.Files.Files.edges;
	const [selectedFile, setSelectedFile] = useState("");
	const [showDialog, setShowDialog] = useState(false);
	const [shareable, setShareable] = useState(false);
	const [override, setOverride] = useState(false);

	const checkPermissions = (permissions: Permission[]) =>
		useSelector((state: any) => selectHasPermissions(state, permissions));

	const hasPermissions =
		checkPermissions([
			"AccountPermission_System_Root",
			"UserInAccountPermission_System_Owner",
		]) ||
		checkPermissions([
			"AccountPermission_System_Root",
			"UserInAccountPermission_Nodes_UpdateNodes",
		]);

	const { onBeforeSend } = useFileUpload({
		isFilesPublic: shareable,
		canOverrideFile: override,
	});

	const customFileUploadHeader = (options: FileUploadHeaderTemplateOptions) => {
		const { className, chooseButton, uploadButton, cancelButton } = options;

		return (
			<div
				className={className}
				style={{ backgroundColor: "transparent", display: "flex", alignItems: "center" }}
			>
				<ChooseButtonContainer selectedFileName={selectedFileName}>
					{chooseButton}
				</ChooseButtonContainer>

				<ShareableButton
					onClick={() => {
						setShareable(!shareable);
					}}
					sharable={shareable}
					label="Teilbar"
					icon="pi pi-share-alt"
				/>

				<UploadButtonContainer isFileInDb={isFileInDb}>
					{uploadButton}
				</UploadButtonContainer>

				{cancelButton}

				{isFileInDb && (
					<FileIsAlreadyInDBDialog
						showDialogUpload={isFileInDb}
						uploadButton={uploadButton}
						setOverride={setOverride}
						cancelButton={cancelButton}
					/>
				)}
			</div>
		);
	};

	return (
		<>
			{hasPermissions && (
				<FileUpload
					className="mb-3"
					name="file[]"
					url={`${process.env.REACT_APP_API_BASE}/api/upload`}
					onUpload={() => refetch({}, { fetchPolicy: "network-only" })}
					onBeforeSend={onBeforeSend}
					multiple={false}
					onRemove={() => {
						setSelectedFileName("");
						setOverride(false);
					}}
					onClear={() => {
						setSelectedFileName("");
						setOverride(false);
					}}
					onError={(e: FileUploadErrorEvent) => console.log(e)}
					headerTemplate={customFileUploadHeader}
					onSelect={(e) => {
						setSelectedFileName(e.files[0].name);
						setOverride(false);
					}}
				/>
			)}
			<FileFilters />
			{showDialog && (
				<FileIsUsedInNodeAttachmentsDialog
					id={selectedFile}
					connection={Admin.Files.Files.__id}
					showDialog={showDialog}
					setShowDialog={setShowDialog}
				/>
			)}
			<DataTable
				emptyMessage={"Keine passenden Dateien"}
				className="mb-3"
				value={files?.map((b) => b!.node!) as any[]}
				onSelectionChange={
					selectionSettings ? selectionSettings.onSelectionChange : undefined
				}
				selection={selectionSettings ? selectionSettings.selection : undefined}
				selectionMode={selectionSettings ? "single" : undefined}
			>
				<Column selectionMode="single" style={{ width: "3em" }} />
				<Column
					header="Datei"
					body={(row) => (
						<a href={row.url} rel="noreferrer nofollow" target={"_blank"}>
							{row.thumbnail ? (
								<img height={75} src={row.thumbnail} alt={row.name} />
							) : (
								<EmptyImageContainer className="flex justify-content-center align-items-center">
									Keine Vorschau
								</EmptyImageContainer>
							)}
						</a>
					)}
				/>
				<Column header="Name" field="name" />
				<Column
					header="Zugriff"
					body={(row) => <p>{row.accessType === "signedUrl" ? "private" : "public"}</p>}
				/>
				<Column header="Typ" field="fileType" />
				<Column
					header="Erstellt am"
					body={(row) => <DateTimeDisplay value={row.uploadDateTime} />}
				/>
				{hasPermissions && (
					<Column
						header="Aktionen"
						style={{ width: "20%" }}
						body={(item) => (
							<Button
								type="button"
								onClick={() => {
									setSelectedFile(item.id);
									setShowDialog(true);
								}}
								icon={"pi pi-trash"}
							/>
						)}
					/>
				)}
			</DataTable>
			<div className="flex justify-content-center align-items-center">
				<Button
					type="button"
					disabled={!hasPrevious}
					onClick={() => loadPrevious(20)}
					className="mr-3 p-button-secondary"
				>
					Zurück
				</Button>
				<Button
					type="button"
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={() => loadNext(20)}
				>
					Weiter
				</Button>
			</div>
		</>
	);
};

const EmptyImageContainer = styled.div`
	height: 75px;
	width: 150px;
`;

const ShareableButton = styled(Button)<{ sharable: boolean }>`
	${(props: any) => (props.sharable ? "opacity: 1" : "opacity: .5")}
`;

const ChooseButtonContainer = styled.div<{ selectedFileName: string }>`
	${(props: any) => (props.selectedFileName !== "" ? "opacity: 0.5" : "opacity: 1")};
	pointer-events: ${(props: any) => (props.selectedFileName !== "" ? "none" : "auto")};
`;

const UploadButtonContainer = styled.div<{ isFileInDb: boolean }>`
	${(props: any) => (props.isFileInDb ? "opacity: 0.5" : "opacity: 1")};
	pointer-events: ${(props: any) => (props.isFileInDb ? "none" : "auto")};
`;

import { graphql } from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { CreateElementsPoolButton_CreatePoolMutation } from "../../../../__generated__/CreateElementsPoolButton_CreatePoolMutation.graphql";

const CREATE_POOL_MUTATION = graphql`
	mutation CreateElementsPoolButton_CreatePoolMutation($input: CreateElementsPoolInput!) {
		Admin {
			Elementspool {
				createElementsPoolAdminMutation(input: $input) {
					node {
						id
						structureDefinition {
							... on RootStructureDefinition {
								extension {
									... on RootExtensionImpl {
										elementsPoolId
									}
								}
							}
						}
					}

					elementsPool {
						id
						elements {
							id
							elementType
							title
							...PoolElementForm_ElementV2Fragment
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	rootNodeId: string;
}

export const CreateElementsPoolButton = ({ rootNodeId }: OwnProps) => {
	const [createElementsPool, isCreatingElementsPool] =
		useMutation<CreateElementsPoolButton_CreatePoolMutation>(CREATE_POOL_MUTATION);

	return (
		<Button
			className={"mr-2"}
			disabled={isCreatingElementsPool}
			tooltip={`Fragen Pool anlegen`}
			icon="pi pi-plus"
			onClick={() => {
				createElementsPool({
					variables: {
						input: {
							rootNodeId: rootNodeId,
						},
					},
				});
			}}
		/>
	);
};

import { Dropdown } from "primereact/dropdown";
import { CertificateVariables } from "../../../__generated__/EditCertificateTemplateScreen_CertificateTemplateFragment.graphql";
import { VariableMappingInput } from "../../../__generated__/EditCertificateTemplateScreen_UpdateCertificateTemplateMutation.graphql";
import { RenderConfig } from "../core/form/ValidatedField";
import { EXTEND_VARIABLE_MAPPING_FIELD } from "../../../lms-admin-impl/ExtendVariableMappingField";

const OPTIONS: { label: string; value: CertificateVariables }[] = [
	{ label: "Name des Inhalts", value: "GraphName" },
	{ label: "Erhaltene Punkte", value: "PointsAwarded" },
	{ label: "Nicht zugeordnet", value: "NotMapped" },
	{ label: "Ausstellungsdatum", value: "IssuanceDate" },
	{ label: "Ausstellungsbeginn Datum", value: "ContainerCodeFrom" },
	{ label: "Ausstellungsende Datum", value: "ContainerCodeTo" },
	...EXTEND_VARIABLE_MAPPING_FIELD,
];

export const VariableMappingField = ({
	fieldValue,
	fieldName,
	updateField,
	onChange,
}: RenderConfig<VariableMappingInput[]>) => {
	const options = OPTIONS.concat(
		[
			{ label: "Name des Empfängers", value: "RecipientName" },
			{ label: "Ort des Empfängers", value: "RecipientLocation" },
			{ label: "OneKeyID des Empfängers", value: "OneKeyId" },
			{ label: "Ort der Veranstaltung", value: "Location" },
			{ label: "Kategorie der Veranstaltung", value: "Category" },
			{ label: "Veranstaltungsnummer", value: "ContainerCode" },
			{ label: "Wissenschaftlicher Leiter", value: "Certifier" },
			{ label: "Signatur des Leiters", value: "SignatureFileUrl" },
		] || [],
	);

	const values = fieldValue || [];
	return (
		<div>
			{values.map((variableMapping) => {
				const dropdownName = fieldName + "-" + variableMapping.templateVariable;
				return (
					<div className="field" key={variableMapping.templateVariable}>
						<label htmlFor={dropdownName}>
							Variable in der Vorlage: %%{variableMapping.templateVariable}%%
						</label>
						<Dropdown
							name={dropdownName}
							value={variableMapping.certificateVariable}
							options={options}
							onChange={(e) => {
								updateField(
									values.map((value) => {
										if (
											value.templateVariable ===
											variableMapping.templateVariable
										) {
											return { ...value, certificateVariable: e.value };
										} else {
											return value;
										}
									}),
								);
								if (onChange) {
									onChange();
								}
							}}
						/>
					</div>
				);
			})}
		</div>
	);
};

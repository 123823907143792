import { InputText } from "primereact/inputtext";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useState } from "react";
import { VimeoPodcastSourceField_GetVideoDataV2Mutation } from "../../../../../../../../__generated__/VimeoPodcastSourceField_GetVideoDataV2Mutation.graphql";
import { PodcastSourceTypeV2 } from "../../../../../../../../__generated__/VimeoPodcastElementForm_PodcastElementV2Fragment.graphql";

export interface VimeoPodcastSource {
	sourceType: PodcastSourceTypeV2;
	vimeoId: string;
}

interface OwnProps {
	videoSource?: VimeoPodcastSource;
	onUpdate: (videoSource?: VimeoPodcastSource) => void;
	onChange?: () => void;
}

const GET_VIDEO_DATA_MUTATION = graphql`
	mutation VimeoPodcastSourceField_GetVideoDataV2Mutation($input: GetVideoDataV2Input!) {
		Admin {
			ElearningV2 {
				getVideoData(input: $input) {
					videoData {
						name
						duration
						thumbnailUrl
					}
				}
			}
		}
	}
`;

interface PodcastDataState {
	name?: string;
	duration?: number;
	thumbnailUrl?: string;
}

export const VimeoPodcastSourceField = ({ videoSource, onUpdate, onChange }: OwnProps) => {
	const [getVideoData, isGettingVideoData] =
		useMutation<VimeoPodcastSourceField_GetVideoDataV2Mutation>(GET_VIDEO_DATA_MUTATION);
	const [state, setState] = useState<PodcastDataState | undefined>();
	return (
		<div>
			<div className="flex align-items-center">
				<div className="p-field flex-grow-1 mr-2">
					<InputText
						placeholder={"Vimeo-ID"}
						id="vimeoId"
						className="p-d-block"
						value={(videoSource as VimeoPodcastSource)?.vimeoId}
						onChange={(e) => {
							onUpdate({
								sourceType: "vimeo",
								vimeoId: e.target.value,
							});
							if (onChange) {
								onChange();
							}
						}}
					/>
				</div>
				<Button
					icon="pi pi-refresh"
					disabled={isGettingVideoData}
					onClick={() => {
						getVideoData({
							variables: {
								input: {
									vimeoId: (videoSource as VimeoPodcastSource).vimeoId,
								},
							},
							onCompleted: (response) => {
								setState(
									response.Admin.ElearningV2.getVideoData
										? {
												...response.Admin.ElearningV2.getVideoData
													?.videoData,
										  }
										: undefined,
								);
								if (onChange) {
									onChange();
								}
							},
						});
					}}
				/>
			</div>
			{state && (
				<div className="p-fluid p-3">
					<div className="field grid">
						<label
							htmlFor="firstname3"
							className="p-col-fixed"
							style={{ width: "70px" }}
						>
							Name:
						</label>
						<div className="p-col">{state.name}</div>
					</div>
					<div className="field grid">
						<label
							htmlFor="firstname3"
							className="p-col-fixed"
							style={{ width: "70px" }}
						>
							Dauer:
						</label>
						<div className="p-col">{state.duration} Sekunden</div>
					</div>

					<div>
						{state.thumbnailUrl && (
							<img height={200} alt={state.name} src={state.thumbnailUrl} />
						)}
					</div>
				</div>
			)}
		</div>
	);
};

import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Tag } from "primereact/tag";
import styled from "styled-components";
import { ContainerTag_TagFragment$key } from "../../../__generated__/ContainerTag_TagFragment.graphql";

const TAG_FRAGMENT = graphql`
	fragment ContainerTag_TagFragment on Tag {
		id
		name
		colorHex
	}
`;

interface OwnProps {
	tagFragmentRef: ContainerTag_TagFragment$key;
}

export const ContainerTag = ({ tagFragmentRef }: OwnProps) => {
	const tag = useFragment<ContainerTag_TagFragment$key>(TAG_FRAGMENT, tagFragmentRef);

	return (
		<StyledTag
			className="mr-2 pl-3 pr-3 text-xxs mb-2"
			value={tag.name}
			style={{ backgroundColor: tag.colorHex }}
			rounded
		/>
	);
};

const StyledTag = styled(Tag)`
	.p-tag-value {
		font-size: 0.65rem;
	}
`;

/**
 * @generated SignedSource<<06d43bee423a92f117ba72ddb3043887>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PodcastSourceTypeV2 = "vimeo" | "podigee" | "none" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PodcastElementForm_PodcastElementV2Fragment$data = {
  readonly id: string;
  readonly podcastSource: {
    readonly sourceType: PodcastSourceTypeV2;
  };
  readonly " $fragmentSpreads": FragmentRefs<"VimeoPodcastElementForm_PodcastElementV2Fragment" | "PodigeePodcastElementForm_PodcastElementV2Fragment">;
  readonly " $fragmentType": "PodcastElementForm_PodcastElementV2Fragment";
};
export type PodcastElementForm_PodcastElementV2Fragment = PodcastElementForm_PodcastElementV2Fragment$data;
export type PodcastElementForm_PodcastElementV2Fragment$key = {
  readonly " $data"?: PodcastElementForm_PodcastElementV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PodcastElementForm_PodcastElementV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PodcastElementForm_PodcastElementV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "podcastSource",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sourceType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VimeoPodcastElementForm_PodcastElementV2Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PodigeePodcastElementForm_PodcastElementV2Fragment"
    }
  ],
  "type": "PodcastElementV2",
  "abstractKey": null
};

(node as any).hash = "b78eb4fd55b4a6ce0a82cd74ddd2a698";

export default node;

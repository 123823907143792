import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import React from "react";
import { Button } from "primereact/button";
import { ActionColumn_DeleteMutation } from "../../../../__generated__/ActionColumn_DeleteMutation.graphql";
import { useNavigate } from "react-router-dom";
import { ActionColumn_LandingPageFragment$key } from "../../../../__generated__/ActionColumn_LandingPageFragment.graphql";
import { useDialogLogic } from "../../core/dialog/useDialogLogic";
import { getEditLandingPagePathV2 } from "../../../../app/routes/LandingPageModuleRoutes";

const LANDING_PAGE_FRAGMENT = graphql`
	fragment ActionColumn_LandingPageFragment on LandingPageV2 {
		id
		urlParam
	}
`;

const DELETE_MUTATION = graphql`
	mutation ActionColumn_DeleteMutation($input: DeleteLandingPageV2Input!, $connections: [ID!]!) {
		Admin {
			LandingPageV2 {
				deleteLandingPageV2(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;

interface OwnProps {
	landingPageFragmentRef: ActionColumn_LandingPageFragment$key;
	landingPagesConnectionId: string;
}

export const ActionColumn = ({ landingPageFragmentRef, landingPagesConnectionId }: OwnProps) => {
	const navigate = useNavigate();

	const { showDialog, dialogComponent } = useDialogLogic();

	const landingPage = useFragment<ActionColumn_LandingPageFragment$key>(
		LANDING_PAGE_FRAGMENT,
		landingPageFragmentRef,
	);

	const [deleteLandingPage, isDeletingLandingPage] =
		useMutation<ActionColumn_DeleteMutation>(DELETE_MUTATION);

	return (
		<>
			{dialogComponent}

			<a
				style={{ textDecoration: "none" }}
				href={`${process.env.REACT_APP_APP_SYSTEM_BASE}/webseite/${landingPage.urlParam}`}
				rel="noreferrer"
				target="_blank"
			>
				<Button className="mr-2" icon={"pi pi-eye"} />
			</a>
			<Button
				className="mr-2"
				onClick={() => {
					navigate(getEditLandingPagePathV2(landingPage.id));
				}}
				icon={"pi pi-pencil"}
			/>

			<Button
				disabled={isDeletingLandingPage}
				onClick={() => {
					showDialog({
						title: "Seite löschen",
						content:
							"Möchten Sie diese Seite wirklich löschen? Das kann nicht rückgängig gemacht werden.",
						dialogCallback: (result) => {
							if (result === "Accept") {
								deleteLandingPage({
									variables: {
										input: {
											ids: [landingPage.id],
										},
										connections: [landingPagesConnectionId],
									},
								});
							}
						},
					});
				}}
				icon={"pi pi-trash"}
			/>
		</>
	);
};

import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { useMatch } from "react-router-dom";
import { BaseScreen } from "../../infecto-lms-admin/screens/BaseScreen";
import { EditNewsTickerDefinitionForm } from "../components/newsticker/EditNewsTickerDefinitionForm";
import { EditNewsTickerDefinitionScreen_Query } from "../../__generated__/EditNewsTickerDefinitionScreen_Query.graphql";

const QUERY = graphql`
	query EditNewsTickerDefinitionScreen_Query($id: ID!) {
		node(id: $id) {
			... on NewstickerMessage {
				id
				...EditNewsTickerDefinitionForm_MessageDefinitionFragment
			}
		}
	}
`;

export const EDIT_NEWSTICKER_DEFINITION_ROUTE = "/newsticker/:newstickerDefinitionId/edit";

export const EditNewsTickerDefinitionScreen = () => {
	const match = useMatch(EDIT_NEWSTICKER_DEFINITION_ROUTE);

	const query = useLazyLoadQuery<EditNewsTickerDefinitionScreen_Query>(QUERY, {
		id: match?.params.newstickerDefinitionId!,
	});

	return (
		query.node && (
			<BaseScreen>
				<h1 className="mb-3">Bearbeite Newsticker</h1>
				<EditNewsTickerDefinitionForm newsTickerDefinitionFragmentRef={query.node} />
			</BaseScreen>
		)
	);
};

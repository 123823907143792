/**
 * @generated SignedSource<<02ecb2e09cc91207957cafe0a5edf9e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ImportRandomMultipleChoiceElementsInput = {
  fileContent: string;
  id: string;
  clientMutationId?: string | null;
};
export type ImportRandomMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutation$variables = {
  input: ImportRandomMultipleChoiceElementsInput;
};
export type ImportRandomMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutationVariables = ImportRandomMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutation$variables;
export type ImportRandomMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutation$data = {
  readonly Admin: {
    readonly ImportRandomMultipleChoice: {
      readonly importRandomMultipleChoiceElements: {
        readonly clientMutationId: string | null;
      } | null;
    };
  };
};
export type ImportRandomMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutationResponse = ImportRandomMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutation$data;
export type ImportRandomMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutation = {
  variables: ImportRandomMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutationVariables;
  response: ImportRandomMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ImportRandomMultipleChoiceAdminMutationSchema",
        "kind": "LinkedField",
        "name": "ImportRandomMultipleChoice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "ImportRandomMultipleChoiceElementsPayload",
            "kind": "LinkedField",
            "name": "importRandomMultipleChoiceElements",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImportRandomMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImportRandomMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "30a36504675339249940eb4d7d0ecf7e",
    "id": null,
    "metadata": {},
    "name": "ImportRandomMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutation",
    "operationKind": "mutation",
    "text": "mutation ImportRandomMultipleChoiceElementsButton_ImportMultipleChoiceElementsMutation(\n  $input: ImportRandomMultipleChoiceElementsInput!\n) {\n  Admin {\n    ImportRandomMultipleChoice {\n      importRandomMultipleChoiceElements(input: $input) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3b6945bd0e2a91c82b0c88c38df51eee";

export default node;

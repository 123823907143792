/**
 * @generated SignedSource<<a9c72d895ccf860e4ff61271e813e737>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StartTreeConfigType = "StartTree_CMECertifiedNeedsSpecificGlobalUserStatus" | "StartTree_SpecificUserRolesCan" | "StartTree_CanNot" | "StartTree_SpecificUserStatusesCan" | "%future added value";
export type UserRoles = "Doctor" | "Apothecary" | "Midwife" | "PTA" | "PKA" | "MTA" | "PharmaceuticalEngineer" | "Doccheck" | "Unknown" | "Other" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SpecificUserRolesCanStartTreeConfigForm_StartTreeConfigFragment$data = {
  readonly id?: string;
  readonly configType?: StartTreeConfigType;
  readonly userRoles?: ReadonlyArray<UserRoles>;
  readonly " $fragmentType": "SpecificUserRolesCanStartTreeConfigForm_StartTreeConfigFragment";
};
export type SpecificUserRolesCanStartTreeConfigForm_StartTreeConfigFragment = SpecificUserRolesCanStartTreeConfigForm_StartTreeConfigFragment$data;
export type SpecificUserRolesCanStartTreeConfigForm_StartTreeConfigFragment$key = {
  readonly " $data"?: SpecificUserRolesCanStartTreeConfigForm_StartTreeConfigFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SpecificUserRolesCanStartTreeConfigForm_StartTreeConfigFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpecificUserRolesCanStartTreeConfigForm_StartTreeConfigFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "configType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userRoles",
          "storageKey": null
        }
      ],
      "type": "SpecifiedUserRolesCanStartTreeConfig",
      "abstractKey": null
    }
  ],
  "type": "StartTreeConfig",
  "abstractKey": "__isStartTreeConfig"
};

(node as any).hash = "f4beab8ce0727e8f8c16554d3f4bef5b";

export default node;

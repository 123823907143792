/**
 * @generated SignedSource<<c5f8d8ba20d0b3393b297c1b22fe456c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectEditRootExtensionForm_RootExtensionFragment$data = {
  readonly doNotUse: string;
  readonly " $fragmentType": "ProjectEditRootExtensionForm_RootExtensionFragment";
};
export type ProjectEditRootExtensionForm_RootExtensionFragment = ProjectEditRootExtensionForm_RootExtensionFragment$data;
export type ProjectEditRootExtensionForm_RootExtensionFragment$key = {
  readonly " $data"?: ProjectEditRootExtensionForm_RootExtensionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectEditRootExtensionForm_RootExtensionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectEditRootExtensionForm_RootExtensionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "doNotUse",
      "storageKey": null
    }
  ],
  "type": "RootExtension",
  "abstractKey": "__isRootExtension"
};

(node as any).hash = "097e0a3b5e1e785134eda5f9f8429297";

export default node;

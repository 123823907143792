/**
 * @generated SignedSource<<e082116101de87bde40a26a04cb4013b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PointsPoolColumn_PointsAdditionFragment$data = {
  readonly pointsPool: {
    readonly name: string;
  };
  readonly " $fragmentType": "PointsPoolColumn_PointsAdditionFragment";
};
export type PointsPoolColumn_PointsAdditionFragment = PointsPoolColumn_PointsAdditionFragment$data;
export type PointsPoolColumn_PointsAdditionFragment$key = {
  readonly " $data"?: PointsPoolColumn_PointsAdditionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PointsPoolColumn_PointsAdditionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PointsPoolColumn_PointsAdditionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PointPool",
      "kind": "LinkedField",
      "name": "pointsPool",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PointsAdditionV2",
  "abstractKey": null
};

(node as any).hash = "7c01fb4b959be37b826ea63792933334";

export default node;

import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery, useRelayEnvironment } from "react-relay";
import React, { useEffect, useState } from "react";
import { TagMultiselect_Query } from "../../../../__generated__/TagMultiselect_Query.graphql";
import { MultiSelect } from "primereact/multiselect";
import styled from "styled-components";
import { Tag } from "primereact/tag";

const TAG_MULTISELECT_QUERY = graphql`
	query TagMultiselect_Query($first: Int, $after: String, $filterByName: String) {
		Admin {
			Tag {
				Tags(first: $first, after: $after, filterByName: $filterByName) {
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							name
							colorHex
							...ContainerTag_TagFragment
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	selectedTags?: string[] | null;
	onSelect: (tagIds?: string[]) => void;
}

interface TagProps {
	id: string;
	name: string;
	colorHex: string;
}

export const TagMultiselect = ({ selectedTags, onSelect }: OwnProps) => {
	const environment = useRelayEnvironment();

	const [tags, setTags] = useState<TagProps[]>([]);

	useEffect(() => {
		fetchQuery<TagMultiselect_Query>(environment, TAG_MULTISELECT_QUERY, {})
			.toPromise()
			.then((result) => {
				setTags(() => result!.Admin.Tag.Tags.edges!.map((e) => e!.node!));
			});
		// eslint-disable-next-line
	}, []);

	const tagTemplate = (option: any) => {
		const tag = tags?.find((tag) => tag.id === option.value);

		return (
			<StyledTag
				className="mr-2 pl-3 pr-3 text-xxs mb-2"
				value={tag?.name}
				style={{ backgroundColor: tag?.colorHex }}
				rounded
			/>
		);
	};

	return (
		<MultiSelect
			className="m-1"
			value={selectedTags}
			options={tags.map((p) => ({ label: p.name, value: p.id }))}
			onChange={(e) => onSelect(e.value)}
			filter
			showClear
			placeholder="Schlagworte"
			itemTemplate={tagTemplate}
			onFilter={(e) => {
				fetchQuery<TagMultiselect_Query>(environment, TAG_MULTISELECT_QUERY, {
					filterByName: e.filter?.length > 0 ? e.filter : undefined,
				})
					.toPromise()
					.then((result) => {
						setTags(() => result!.Admin.Tag.Tags.edges!.map((e) => e!.node!));
					});
			}}
		/>
	);
};

const StyledTag = styled(Tag)`
	.p-tag-value {
		font-size: 0.65rem;
	}
`;

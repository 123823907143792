/**
 * @generated SignedSource<<149e9e39c23db8ac9502b3b4c25830fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PointsAdditionsTable_Refetch$variables = {
  after?: string | null;
  filterByPointsPoolId?: string | null;
  filterByUserId?: string | null;
  first?: number | null;
};
export type PointsAdditionsTable_RefetchVariables = PointsAdditionsTable_Refetch$variables;
export type PointsAdditionsTable_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PointsAdditionsTable_QueryFragment">;
};
export type PointsAdditionsTable_RefetchResponse = PointsAdditionsTable_Refetch$data;
export type PointsAdditionsTable_Refetch = {
  variables: PointsAdditionsTable_RefetchVariables;
  response: PointsAdditionsTable_Refetch$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByPointsPoolId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByUserId"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filterByPointsPoolId",
    "variableName": "filterByPointsPoolId"
  },
  {
    "kind": "Variable",
    "name": "filterByUserId",
    "variableName": "filterByUserId"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PointsAdditionsTable_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "PointsAdditionsTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PointsAdditionsTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PointsV2AdminSchema",
            "kind": "LinkedField",
            "name": "PointsV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "PointsAdditionsV2Connection",
                "kind": "LinkedField",
                "name": "GetFilteredPointsAdditions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PointsAdditionsV2Edge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PointsAdditionV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContentSubmission",
                            "kind": "LinkedField",
                            "name": "contentSubmission",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LearnOpportunityV2",
                                "kind": "LinkedField",
                                "name": "learnOpportunity",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "structureDefinition",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PointPool",
                            "kind": "LinkedField",
                            "name": "pointsPool",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "receivedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "extension",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "firstName",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "lastName",
                                        "storageKey": null
                                      },
                                      (v4/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "salutation",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "InfectopharmUserExtensionImpl",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "filterByPointsPoolId",
                  "filterByUserId"
                ],
                "handle": "connection",
                "key": "PointsAdditionsTable_GetFilteredPointsAdditions",
                "kind": "LinkedHandle",
                "name": "GetFilteredPointsAdditions"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ea99e39ed89bf81d2d27c2e09e63914e",
    "id": null,
    "metadata": {},
    "name": "PointsAdditionsTable_Refetch",
    "operationKind": "query",
    "text": "query PointsAdditionsTable_Refetch(\n  $after: String\n  $filterByPointsPoolId: ID\n  $filterByUserId: ID\n  $first: Int = 20\n) {\n  ...PointsAdditionsTable_QueryFragment_16yilY\n}\n\nfragment ContentNodeTitleColumn_PointsAdditionFragment on PointsAdditionV2 {\n  contentSubmission {\n    learnOpportunity {\n      structureDefinition {\n        __typename\n        title\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment PointsAdditionsTable_PointsAdditionFragment on PointsAdditionV2 {\n  ...ContentNodeTitleColumn_PointsAdditionFragment\n  ...PointsPoolColumn_PointsAdditionFragment\n  ...ReceivedAtColumn_PointsAdditionFragment\n  ...UserColumn_PointsAdditionFragment\n}\n\nfragment PointsAdditionsTable_QueryFragment_16yilY on Query {\n  Admin {\n    PointsV2 {\n      GetFilteredPointsAdditions(first: $first, after: $after, filterByPointsPoolId: $filterByPointsPoolId, filterByUserId: $filterByUserId) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            ...PointsAdditionsTable_PointsAdditionFragment\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n\nfragment PointsPoolColumn_PointsAdditionFragment on PointsAdditionV2 {\n  pointsPool {\n    name\n    id\n  }\n}\n\nfragment ProjectUserDescription_UserFragment on User {\n  ...UserDescription_UserFragment\n}\n\nfragment ReceivedAtColumn_PointsAdditionFragment on PointsAdditionV2 {\n  receivedAt\n}\n\nfragment UserColumn_PointsAdditionFragment on PointsAdditionV2 {\n  user {\n    id\n    ...ProjectUserDescription_UserFragment\n  }\n}\n\nfragment UserDescription_UserFragment on User {\n  extension {\n    __typename\n    ... on InfectopharmUserExtensionImpl {\n      firstName\n      lastName\n      title\n      salutation\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6814c1ac1cdf8575a3f021a02d59e3ef";

export default node;

import { RenderConfig } from "../../../infecto-lms-admin/components/core/form/ValidatedField";
import { Dropdown } from "primereact/dropdown";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { InstructorDropdown_Query } from "../../../__generated__/InstructorDropdown_Query.graphql";

export const InstructorDropdown = ({
	fieldName,
	fieldValue,
	updateField,
	disabled,
	onChange,
}: RenderConfig<string>) => {
	const query = useLazyLoadQuery<InstructorDropdown_Query>(
		graphql`
			query InstructorDropdown_Query {
				Admin {
					Instructor {
						InstructorImpls(first: 100) {
							edges {
								node {
									id
									superId
									name
								}
							}
						}
					}
				}
			}
		`,
		{},
	);

	return (
		<div>
			<Dropdown
				disabled={disabled}
				value={fieldValue}
				onChange={(e) => {
					updateField(e.value);
					if (onChange) {
						onChange();
					}
				}}
				options={query.Admin.Instructor.InstructorImpls.edges?.map((i) => ({
					value: i!.node.superId,
					label: i!.node.name,
				}))}
				filter
				name={fieldName}
			/>
		</div>
	);
};

/**
 * @generated SignedSource<<f1d80de4f0755595031e6c0a4096dc3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StructureType = "root" | "child" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditNodeForm_TreeNodeFragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly title: string;
    readonly definitionType: StructureType;
  };
  readonly description: string | null;
  readonly shortDescription: string | null;
  readonly instructors: ReadonlyArray<{
    readonly superId: string;
  }>;
  readonly imageId: string | null;
  readonly " $fragmentType": "EditNodeForm_TreeNodeFragment";
};
export type EditNodeForm_TreeNodeFragment = EditNodeForm_TreeNodeFragment$data;
export type EditNodeForm_TreeNodeFragment$key = {
  readonly " $data"?: EditNodeForm_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditNodeForm_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditNodeForm_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "definitionType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "instructors",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "superId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageId",
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "b49331902232ef176b3dcc0d653fe7db";

export default node;

import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { PublishedNodesActionColumn_NodeFragment$key } from "../../../__generated__/PublishedNodesActionColumn_NodeFragment.graphql";
import { getSingleModulePath } from "../../../app/routes/PublishedNodesAndUsersModuleRoutes";

const NODE_FRAGMENT = graphql`
	fragment PublishedNodesActionColumn_NodeFragment on LearnOpportunityV2 {
		id
		typeDefinition {
			... on LearnOpportunityELearningContentTypeDefinition {
				containedElementTypes
			}
			... on LearnOpportunityBranchTypeDefinition {
				children {
					id
					typeDefinition {
						... on LearnOpportunityELearningContentTypeDefinition {
							containedElementTypes
						}
					}
				}
			}
			definitionType
		}
	}
`;

interface OwnProps {
	nodeFragmentRef: PublishedNodesActionColumn_NodeFragment$key;
}

export const PublishedNodesActionColumn = ({ nodeFragmentRef }: OwnProps) => {
	const node = useFragment<PublishedNodesActionColumn_NodeFragment$key>(
		NODE_FRAGMENT,
		nodeFragmentRef,
	);

	const history = useNavigate();
	const filteredValue = ["randomMultiplechoice", "multiplechoice"];

	const multipleChoiceId = () => {
		if (node.typeDefinition.children && node.typeDefinition.children.length > 0) {
			return node.typeDefinition.children
				?.filter(
					(ch) =>
						ch.typeDefinition.containedElementTypes &&
						ch.typeDefinition.containedElementTypes?.filter((e) =>
							filteredValue.includes(e),
						),
				)
				.filter(
					(ch) =>
						ch.typeDefinition.containedElementTypes?.includes("multipleChoice") ||
						ch.typeDefinition.containedElementTypes?.includes("randomMultipleChoice"),
				)
				.map((c) => c.id)[0];
		} else {
			if (
				(node.typeDefinition.containedElementTypes &&
					node.typeDefinition.containedElementTypes.includes("multipleChoice")) ||
				node.typeDefinition.containedElementTypes?.includes("randomMultipleChoice")
			) {
				return node.id;
			}
		}
	};

	return (
		<div>
			{multipleChoiceId() !== undefined && multipleChoiceId()!.length > 0 && (
				<Button
					className="mr-2"
					onClick={() => {
						history(getSingleModulePath(multipleChoiceId()!));
					}}
					icon={"pi pi-search"}
				/>
			)}
		</div>
	);
};

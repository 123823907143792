import { useContext, useState } from "react";
import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { Card } from "primereact/card";
import { useMatch, useNavigate } from "react-router-dom";
import { useLazyLoadQuery } from "react-relay";
import { TabPanel, TabView } from "primereact/tabview";
import { NodePart_Query } from "../../../../../__generated__/NodePart_Query.graphql";
import { ProjectRewardEditor } from "../../../../../lms-admin-impl/components/relay/ProjectRewardEditor";
import { ProjectEditRootExtensionForm } from "../../../../../lms-admin-impl/components/relay/ProjectEditRootExtensionForm";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../../../lms-admin-impl/i18n/tree.i18n";
import { ProjectExtendRootNodesTabs } from "../../../../../lms-admin-impl/components/relay/ProjectExtendRootNodesTabs";
import { ImplContextV2, ImplV2 } from "../../../../../infecto-lms-admin/impl/ImplContextV2";
import {
	CHILD_NODE_ID_URL_PARAM,
	getTreeEditorChildNodeWithPagePath,
	getTreeEditorWithPagePath,
	PAGE_URL_PARAM,
	ROOT_NODE_ID_URL_PARAM,
} from "../../../../routes/GraphEditorRoutes";
import { selectArrayOfEditedForms } from "../../../../../infecto-lms-admin/redux/slices/CoreSlice";
import { useCallbackPrompt } from "../../../../../infecto-lms-admin/functions/hooks/UseCallBackPrompt";
import { BaseScreenNoBackground } from "../../../../../infecto-lms-admin/screens/BaseScreen";
import { WarningUnsavedChangesDialog } from "../../../../../infecto-lms-admin/components/core/dialog/WarningUnsavedChangesDialog";
import { NodeTabsTop } from "../../../../../infecto-lms-admin/components/tree/editor/node/NodeTabsTop";
import { EditNodeForm } from "../../../../../infecto-lms-admin/components/tree/editor/node/EditNodeForm";
import { EditRootNodePartForm } from "../../../../../infecto-lms-admin/components/tree/editor/node/root/EditRootNodePartForm";
import { ContentCard } from "../../../../../infecto-lms-admin/components/tree/editor/node/content/ContentCard";
import { AttachmentEditor } from "../../../../../infecto-lms-admin/components/tree/editor/node/attachments/AttachmentEditor";
import { TreeConfigEditor } from "../../../../../infecto-lms-admin/components/tree/editor/node/config/tree/TreeConfigEditor";
import { ContentConfigEditor } from "../../../../../infecto-lms-admin/components/tree/editor/node/config/content/ContentConfigEditor";
import { TreeSelectionContext } from "../../tree/TreeSelectionContext";
import { FachberaterCertificatesAndLettersExporter } from "../../../rewards/fachberater/FachberaterCertificatesAndLettersExporter";
import { useTypedSelector } from "../../../../../infecto-lms-admin/redux/Store";
import { DeleteActiveContentSubmissionsButton } from "../../../../../infecto-lms-admin/components/learn/DeleteActiveContentSubmissionsButton";

const QUERY = graphql`
	query NodePart_Query($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			id
			... on TreeNode {
				attachments {
					... on InfectopharmFileAttachment {
						file {
							id
						}
					}
				}
				structureDefinition {
					definitionType
					... on RootStructureDefinition {
						extension {
							...ProjectEditRootExtensionForm_RootExtensionFragment
						}
					}
				}
				typeDefinition {
					definitionType
					... on ContentTypeDefinition {
						rewards {
							id
							kind
						}
					}
					... on ELearningContentTypeDefinition {
						elements {
							id
						}
					}
				}
				...AttachmentEditor_TreeNodeFragment
				...ContentCard_TreeNodeFragment
				...ContentConfigEditor_TreeNodeFragment
				...EditNodeForm_TreeNodeFragment
				...EditRootNodePartForm_TreeNodeFragment
				...ProjectRewardEditor_TreeNodeFragment
				...SetNodeExtensionsForm_TreeNodeFragment
				...TreeConfigEditor_TreeNodeFragment
			}
			...NodeTabsTop_TreeNodeFragment
			...ProjectExtendRootNodesTabs_NodeFragment
		}
	}
`;

type Page = "General" | "Advertisements" | "Expiration" | "Conditions" | "Rewards" | "Attachments";

const PageOrders: Page[] = [
	"General",
	"Advertisements",
	"Expiration",
	"Conditions",
	"Rewards",
	"Attachments",
];

export interface ProjectExtendRootNodeTab {
	tabTitle: string;
	tabContent: React.ReactElement<{ treeNodeFragmentRef: any }>;
}

export const NodePart = () => {
	const matchPage = useMatch(getTreeEditorWithPagePath(ROOT_NODE_ID_URL_PARAM, PAGE_URL_PARAM));
	const matchWithNodeIdPage = useMatch(
		getTreeEditorChildNodeWithPagePath(
			ROOT_NODE_ID_URL_PARAM,
			CHILD_NODE_ID_URL_PARAM,
			PAGE_URL_PARAM,
		),
	);

	const page: Page =
		(matchPage?.params.page as Page) || (matchWithNodeIdPage?.params.page as Page) || "General";

	const [activeIndex, setActiveIndex] = useState(PageOrders.indexOf(page));
	const implContext = useContext<ImplV2>(ImplContextV2);
	const navigate = useNavigate();
	const { selectedNodeId, treeId } = useContext(TreeSelectionContext);
	const arrayOfEditedForm = useTypedSelector(selectArrayOfEditedForms);
	const [showDialog, setShowDialog] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState<number>(activeIndex);
	const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
		arrayOfEditedForm.length > 0,
	);

	const data = useLazyLoadQuery<NodePart_Query>(QUERY, {
		id: selectedNodeId || "",
		skip: !selectedNodeId,
	});

	const isRoot = data.node?.structureDefinition?.definitionType === "root";
	const isContent = data.node?.typeDefinition?.definitionType === "content";
	const isBasisseminar =
		isContent && data.node.typeDefinition?.rewards?.find((r) => r.kind === "MakeFachberater");

	return (
		<BaseScreenNoBackground>
			{(showPrompt || showDialog) && (
				<WarningUnsavedChangesDialog
					setShowDialog={setShowDialog}
					confirmNavigation={confirmNavigation}
					cancelNavigation={cancelNavigation}
					callback={setActiveIndex}
					value={selectedIndex}
				/>
			)}
			{!data.node && <Card>Bitte einen Ordner auswählen</Card>}
			{data.node && (
				<TabView
					activeIndex={activeIndex}
					onTabChange={(e) => {
						if (arrayOfEditedForm.length > 0) {
							setShowDialog(true);
							setSelectedIndex(e.index);
						} else {
							setActiveIndex(e.index);
						}

						const newPageName = `${PageOrders[e.index] || "General"}`;

						switch (selectedNodeId) {
							case undefined:
								navigate(getTreeEditorWithPagePath(treeId, newPageName));
								return;
							case treeId:
								navigate(getTreeEditorWithPagePath(treeId, newPageName));
								return;
							default:
								navigate(
									getTreeEditorChildNodeWithPagePath(
										treeId,
										selectedNodeId,
										newPageName,
									),
								);
						}
					}}
				>
					<TabPanel header="Ordner">
						<NodeTabsTop treeNodeFragmentRef={data.node} />
						<EditNodeForm key={data.node.id} treeNodeFragmentRef={data.node} />
						{isRoot && <EditRootNodePartForm treeNodeFragmentRef={data.node} />}
						{isRoot && data.node.structureDefinition.extension && (
							<ProjectEditRootExtensionForm
								rootId={data.node.id}
								rootExtensionFragmentRef={data.node.structureDefinition.extension}
							/>
						)}
						{implContext.node?.createMetaForm(data.node)}
					</TabPanel>
					{
						<TabPanel
							header={(() => {
								switch (data.node?.typeDefinition?.definitionType) {
									case "branch":
										return `Inhalt: ${TREE_I18N_MAP(TREE_I18N_KEY.branchNode)}`;
									case "content":
										return `Inhalt: ${TREE_I18N_MAP(
											TREE_I18N_KEY.eLearningContentNode,
										)} (${data.node?.typeDefinition.elements?.length} Baustein${
											data.node?.typeDefinition.elements?.length === 1
												? ""
												: "e"
										})`;
									default:
										return null;
								}
							})()}
						>
							<NodeTabsTop treeNodeFragmentRef={data.node} />
							<ContentCard treeNodeFragmentRef={data.node} />
						</TabPanel>
					}
					<TabPanel header={`Anhänge (${data.node.attachments?.length})`}>
						<NodeTabsTop treeNodeFragmentRef={data.node} />
						<AttachmentEditor treeNodeFragmentRef={data.node} />
					</TabPanel>
					<TabPanel header="Bedingungen">
						<NodeTabsTop treeNodeFragmentRef={data.node} />
						{isRoot && <TreeConfigEditor treeNodeFragmentRef={data.node} />}
						{isContent && <ContentConfigEditor treeNodeFragmentRef={data.node} />}
					</TabPanel>
					{isContent && (
						<TabPanel
							header={`Belohnungen (${data.node.typeDefinition?.rewards?.length})`}
						>
							<NodeTabsTop treeNodeFragmentRef={data.node} />
							<ProjectRewardEditor treeNodeFragmentRef={data.node} />
						</TabPanel>
					)}
					{isBasisseminar && (
						<TabPanel header={`Export`}>
							<FachberaterCertificatesAndLettersExporter nodeId={data.node.id} />
						</TabPanel>
					)}
					{isContent && (
						<TabPanel header={`Actions`}>
							<DeleteActiveContentSubmissionsButton nodeId={data.node.id} />
						</TabPanel>
					)}
					{ProjectExtendRootNodesTabs({ treeNodeFragmentRef: data.node, isRoot }).map(
						(tab: ProjectExtendRootNodeTab, idx: number) => (
							<TabPanel
								key={`${tab.tabTitle.replace(" ", "_")}_${idx}`}
								header={tab.tabTitle}
							>
								{tab.tabContent}
							</TabPanel>
						),
					)}
				</TabView>
			)}
		</BaseScreenNoBackground>
	);
};

/**
 * @generated SignedSource<<688c30255e90ecbd6d0468d77e5bc68e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedbacksScreenV2_Query$variables = {};
export type FeedbacksScreenV2_QueryVariables = FeedbacksScreenV2_Query$variables;
export type FeedbacksScreenV2_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FeedbacksTableV2_QueryFragment">;
};
export type FeedbacksScreenV2_QueryResponse = FeedbacksScreenV2_Query$data;
export type FeedbacksScreenV2_Query = {
  variables: FeedbacksScreenV2_QueryVariables;
  response: FeedbacksScreenV2_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FeedbacksScreenV2_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "FeedbacksTableV2_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FeedbacksScreenV2_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FeedbackV2AdminSchema",
            "kind": "LinkedField",
            "name": "FeedbackV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v0/*: any*/),
                "concreteType": "FeedbacksConnection",
                "kind": "LinkedField",
                "name": "Feedbacks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FeedbacksEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FeedbackV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LearnOpportunityV2",
                            "kind": "LinkedField",
                            "name": "learnOpportunity",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "structureDefinition",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "screen",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "extension",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "firstName",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "lastName",
                                        "storageKey": null
                                      },
                                      (v3/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "salutation",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "InfectopharmUserExtensionImpl",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "Feedbacks(first:20)"
              },
              {
                "alias": null,
                "args": (v0/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "FeedbacksTableV2_Feedbacks",
                "kind": "LinkedHandle",
                "name": "Feedbacks"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ce602469f6b3b41e0f9495ad64b40c3b",
    "id": null,
    "metadata": {},
    "name": "FeedbacksScreenV2_Query",
    "operationKind": "query",
    "text": "query FeedbacksScreenV2_Query {\n  ...FeedbacksTableV2_QueryFragment\n}\n\nfragment ActionColumn_FeedbackFragment on FeedbackV2 {\n  id\n}\n\nfragment ContentColumn_FeedbackFragment on FeedbackV2 {\n  learnOpportunity {\n    structureDefinition {\n      __typename\n      title\n    }\n    id\n  }\n}\n\nfragment CreatedAtColumn_FeedbackFragment on FeedbackV2 {\n  createdAt\n}\n\nfragment FeedbacksTableV2_FeedbackFragment on FeedbackV2 {\n  ...ActionColumn_FeedbackFragment\n  ...ContentColumn_FeedbackFragment\n  ...CreatedAtColumn_FeedbackFragment\n  ...ScreenColumn_FeedbackFragment\n  ...UserColumn_FeedbackFragment\n}\n\nfragment FeedbacksTableV2_QueryFragment on Query {\n  Admin {\n    FeedbackV2 {\n      Feedbacks(first: 20) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            ...FeedbacksTableV2_FeedbackFragment\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n\nfragment ProjectUserDescription_UserFragment on User {\n  ...UserDescription_UserFragment\n}\n\nfragment ScreenColumn_FeedbackFragment on FeedbackV2 {\n  screen\n}\n\nfragment UserColumn_FeedbackFragment on FeedbackV2 {\n  user {\n    ...ProjectUserDescription_UserFragment\n    id\n  }\n}\n\nfragment UserDescription_UserFragment on User {\n  extension {\n    __typename\n    ... on InfectopharmUserExtensionImpl {\n      firstName\n      lastName\n      title\n      salutation\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "21d2e82916ef02dd07289eb6b4e67ed2";

export default node;

/**
 * @generated SignedSource<<9333bac6a549062ad8fac7a0b6193fb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreatedAtColumn_FeedbackFragment$data = {
  readonly createdAt: string;
  readonly " $fragmentType": "CreatedAtColumn_FeedbackFragment";
};
export type CreatedAtColumn_FeedbackFragment = CreatedAtColumn_FeedbackFragment$data;
export type CreatedAtColumn_FeedbackFragment$key = {
  readonly " $data"?: CreatedAtColumn_FeedbackFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatedAtColumn_FeedbackFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatedAtColumn_FeedbackFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "FeedbackV2",
  "abstractKey": null
};

(node as any).hash = "a7e8545b8efd4159f0ad8f5002ec4d33";

export default node;

import React, { useContext } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { PublishedNodesSearchContext } from "./PublishedNodesSearchContainer";

export const PublishedNodesFilters = () => {
	const { filters, setFilters, clearFilters } = useContext(PublishedNodesSearchContext);

	return (
		<div className="flex mb-3 flex-wrap">
			<div>
				<InputText
					className="m-1 w-21rem"
					value={filters.filterByTitleOrDescriptionOrShortDescription || ""}
					onChange={(e) =>
						setFilters({
							...filters,
							filterByTitleOrDescriptionOrShortDescription: e.target.value,
						})
					}
					placeholder={"Titel, Beschreibung oder Kurzbeschreibung"}
				/>
			</div>
			<div className="flex align-items-center m-1">
				<Button
					className=""
					label={"Zurücksetzen"}
					icon="pi pi-times"
					onClick={() => {
						clearFilters();
					}}
				/>
			</div>
		</div>
	);
};

import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import React from "react";
import { EditAwardPacPointsConfigurationForm_TreeNodeFragment$key } from "../../../../__generated__/EditAwardPacPointsConfigurationForm_TreeNodeFragment.graphql";
import { EditAwardPacPointsConfigurationForm_AwardPacPointsFragment$key } from "../../../../__generated__/EditAwardPacPointsConfigurationForm_AwardPacPointsFragment.graphql";
import { DefaultIntegerFieldComponent } from "../../../../infecto-lms-admin/components/core/form/DefaultTextInput";
import { ValidatedField } from "../../../../infecto-lms-admin/components/core/form/ValidatedField";
import { useTypedDispatch } from "../../../../infecto-lms-admin/redux/Store";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../../infecto-lms-admin/redux/slices/CoreSlice";
import { EditAwardPacPointsConfigurationForm_EditMutation } from "../../../../__generated__/EditAwardPacPointsConfigurationForm_EditMutation.graphql";

const REWARD_FRAGMENT = graphql`
	fragment EditAwardPacPointsConfigurationForm_AwardPacPointsFragment on AwardPacPointsRewardConfiguration {
		id
		amount
	}
`;

const TREE_NODE_FRAGMENT = graphql`
	fragment EditAwardPacPointsConfigurationForm_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ContentTypeDefinition {
				rewards {
					id
					... on PointsRewardConfiguration {
						pointPool {
							name
						}
					}
				}
			}
		}
	}
`;

const MUTATION = graphql`
	mutation EditAwardPacPointsConfigurationForm_EditMutation($input: EditPacPointsRewardInput!) {
		Admin {
			Tree {
				editPacPointsReward(input: $input) {
					contentNode {
						...RewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface FormState {
	amount: number;
}

interface OwnProps {
	rewardConfigurationFragmentRef: EditAwardPacPointsConfigurationForm_AwardPacPointsFragment$key;
	treeNodeFragmentRef: EditAwardPacPointsConfigurationForm_TreeNodeFragment$key;
	onBack: () => void;
}

export const EditAwardPacPointsConfigurationForm = ({
	rewardConfigurationFragmentRef,
	treeNodeFragmentRef,
	onBack,
}: OwnProps) => {
	const node = useFragment<EditAwardPacPointsConfigurationForm_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const rewardConfiguration =
		useFragment<EditAwardPacPointsConfigurationForm_AwardPacPointsFragment$key>(
			REWARD_FRAGMENT,
			rewardConfigurationFragmentRef,
		);
	const [editReward, isEditing] =
		useMutation<EditAwardPacPointsConfigurationForm_EditMutation>(MUTATION);
	const formId = "EditAwardPacPointsConfigurationForm";
	const dispatch = useTypedDispatch();
	const formik = useFormik<FormState>({
		enableReinitialize: true,
		initialValues: {
			amount: rewardConfiguration.amount,
		},
		validationSchema: Yup.object().shape({
			amount: Yup.number().min(0).required("Das Feld Anzahl wird benötigt."),
		}),
		onSubmit: (values, formikHelpers) => {
			editReward({
				variables: {
					input: {
						contentNodeId: node.id,
						rewardId: rewardConfiguration.id,
						amount: values.amount,
					},
				},
				onCompleted: () => {
					formikHelpers.setTouched({});
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, number>
				name={"amount"}
				label={"Anzahl"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId! }))}
				component={DefaultIntegerFieldComponent}
				formikConfig={formik}
			/>

			<Button
				disabled={Object.entries(formik.touched).length === 0 || isEditing}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};

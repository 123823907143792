/**
 * @generated SignedSource<<2116eef0bbdc3635e05a4513c9e2fc03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PublishedNodesTable_PublishedNodesFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TitleColumnPublishedNodes_PublishedNodesFragment" | "PublishedNodesActionColumn_NodeFragment">;
  readonly " $fragmentType": "PublishedNodesTable_PublishedNodesFragment";
};
export type PublishedNodesTable_PublishedNodesFragment = PublishedNodesTable_PublishedNodesFragment$data;
export type PublishedNodesTable_PublishedNodesFragment$key = {
  readonly " $data"?: PublishedNodesTable_PublishedNodesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PublishedNodesTable_PublishedNodesFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "PublishedNodesTable_PublishedNodesFragment"
};

(node as any).hash = "6a5643fc783db83164cbeadf37450fde";

export default node;

/**
 * @generated SignedSource<<d7571f4b29efbbbee4153e872d049c34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FilesTable_Query$variables = {
  fileName?: string | null;
};
export type FilesTable_QueryVariables = FilesTable_Query$variables;
export type FilesTable_Query$data = {
  readonly Admin: {
    readonly FileAlreadyExist: {
      readonly Files: ReadonlyArray<{
        readonly fileAlreadyExist: ReadonlyArray<{
          readonly name: string;
        } | null>;
      }>;
    };
  };
};
export type FilesTable_QueryResponse = FilesTable_Query$data;
export type FilesTable_Query = {
  variables: FilesTable_QueryVariables;
  response: FilesTable_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fileName"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "fileName",
    "variableName": "fileName"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FilesTable_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FileAlreadyExistAdminSchema",
            "kind": "LinkedField",
            "name": "FileAlreadyExist",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "FileAlreadyExist",
                "kind": "LinkedField",
                "name": "Files",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "fileAlreadyExist",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FilesTable_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FileAlreadyExistAdminSchema",
            "kind": "LinkedField",
            "name": "FileAlreadyExist",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "FileAlreadyExist",
                "kind": "LinkedField",
                "name": "Files",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "fileAlreadyExist",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "315ff7f71d1420df9485deba1093e50f",
    "id": null,
    "metadata": {},
    "name": "FilesTable_Query",
    "operationKind": "query",
    "text": "query FilesTable_Query(\n  $fileName: String\n) {\n  Admin {\n    FileAlreadyExist {\n      Files(fileName: $fileName) {\n        fileAlreadyExist {\n          name\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "69a8c06464c1a35a9032f68db126e68d";

export default node;

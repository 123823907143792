import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../../lms-admin-impl/i18n/tree.i18n";
import { ExtendErrorMessagesI18n } from "../../../../lms-admin-impl/i18n/extendErrorMessages.i18n";

export const ERROR_MESSAGES: { [key: string]: string } = {
	...ExtendErrorMessagesI18n,
	"auth-unspecified-auth-exception":
		"Login fehlgeschlagen. Bitte überprüfe Benutzername und Passwort.",
	certificates_certificate_template_not_found: "Kein Zertifikat gefunden.",
	file_already_exists: "Die Datei existiert bereits!",
	publishingv2_tree_for_publishing_does_not_contain_content_nodes_with_elements: `Keine Elemente im ${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)}.`,
	tree_cant_remove_first_published_date_for_published_root_node: `Veröffentlichungszeitpunkt kann nicht gelöscht werden, da ${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)} bereits veröffentlicht ist.`,
	tree_published_tree_parts_can_not_be_modified: `Bereits veröffentlichte Teile des ${TREE_I18N_MAP(
		TREE_I18N_KEY.treeAttributiveGenitive,
	)} können nicht verändert werden.`,
	auth_login_with_email_and_password_unspecified_auth: "Login fehlgeschlagen!",
	auth_not_enough_permissions_exception: "Keine Berichtigung!",
};

/**
 * @generated SignedSource<<df528e9efa0b859776c4a917c2937fe6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddVNumberRootNodeStructureDefinitionExtensionInput = {
  number: string;
  from: any;
  to: any;
  password: string;
  isActive: boolean;
  nodeId: string;
  clientMutationId?: string | null;
};
export type VNumbersEditor_AddVNumberMutation$variables = {
  input: AddVNumberRootNodeStructureDefinitionExtensionInput;
};
export type VNumbersEditor_AddVNumberMutationVariables = VNumbersEditor_AddVNumberMutation$variables;
export type VNumbersEditor_AddVNumberMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly addVNumberRootNodeStructureDefinitionExtension: {
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"VNumbersEditor_TreeNodeFragment">;
        };
      } | null;
    };
  };
};
export type VNumbersEditor_AddVNumberMutationResponse = VNumbersEditor_AddVNumberMutation$data;
export type VNumbersEditor_AddVNumberMutation = {
  variables: VNumbersEditor_AddVNumberMutationVariables;
  response: VNumbersEditor_AddVNumberMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VNumbersEditor_AddVNumberMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddVNumberRootNodeStructureDefinitionExtensionPayload",
                "kind": "LinkedField",
                "name": "addVNumberRootNodeStructureDefinitionExtension",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "VNumbersEditor_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VNumbersEditor_AddVNumberMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddVNumberRootNodeStructureDefinitionExtensionPayload",
                "kind": "LinkedField",
                "name": "addVNumberRootNodeStructureDefinitionExtension",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "definitionType",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "extension",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "VExtension",
                                        "kind": "LinkedField",
                                        "name": "vNumbers",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "number",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "from",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "to",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "password",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "active",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "RootExtensionImpl",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "308e86035e65ed67fa2c74ebf1e06468",
    "id": null,
    "metadata": {},
    "name": "VNumbersEditor_AddVNumberMutation",
    "operationKind": "mutation",
    "text": "mutation VNumbersEditor_AddVNumberMutation(\n  $input: AddVNumberRootNodeStructureDefinitionExtensionInput!\n) {\n  Admin {\n    Tree {\n      addVNumberRootNodeStructureDefinitionExtension(input: $input) {\n        node {\n          id\n          ...VNumbersEditor_TreeNodeFragment\n        }\n      }\n    }\n  }\n}\n\nfragment VNumbersEditor_TreeNodeFragment on TreeNode {\n  id\n  structureDefinition {\n    __typename\n    definitionType\n    ... on RootStructureDefinition {\n      extension {\n        __typename\n        ... on RootExtensionImpl {\n          vNumbers {\n            number\n            from\n            to\n            password\n            active\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "32a9a80f641bbf678b2d3e001e3a51e3";

export default node;

/**
 * @generated SignedSource<<add99186a0553aa98d25055e182b063e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EmailTesterFeedbackEmailInput = {
  email: string;
  feedbackId: string;
  clientMutationId?: string | null;
};
export type EmailTesterFeedbackEmailForm_Mutation$variables = {
  input: EmailTesterFeedbackEmailInput;
};
export type EmailTesterFeedbackEmailForm_MutationVariables = EmailTesterFeedbackEmailForm_Mutation$variables;
export type EmailTesterFeedbackEmailForm_Mutation$data = {
  readonly Admin: {
    readonly TesterEmail: {
      readonly EmailTesterFeedbackEmail: {
        readonly clientMutationId: string | null;
      } | null;
    };
  };
};
export type EmailTesterFeedbackEmailForm_MutationResponse = EmailTesterFeedbackEmailForm_Mutation$data;
export type EmailTesterFeedbackEmailForm_Mutation = {
  variables: EmailTesterFeedbackEmailForm_MutationVariables;
  response: EmailTesterFeedbackEmailForm_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TesterEmailAdminMutationSchema",
        "kind": "LinkedField",
        "name": "TesterEmail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "EmailTesterFeedbackEmailPayload",
            "kind": "LinkedField",
            "name": "EmailTesterFeedbackEmail",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailTesterFeedbackEmailForm_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailTesterFeedbackEmailForm_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fab30aa9d91b57c504181a516ce64e1d",
    "id": null,
    "metadata": {},
    "name": "EmailTesterFeedbackEmailForm_Mutation",
    "operationKind": "mutation",
    "text": "mutation EmailTesterFeedbackEmailForm_Mutation(\n  $input: EmailTesterFeedbackEmailInput!\n) {\n  Admin {\n    TesterEmail {\n      EmailTesterFeedbackEmail(input: $input) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8ac5a223335ad2d8990427dbcfa2af04";

export default node;

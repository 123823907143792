/**
 * @generated SignedSource<<816efa2269f9bc06d3215ac086c9c198>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UrlPathColumn_LandingPageFragment$data = {
  readonly urlParam: string;
  readonly " $fragmentType": "UrlPathColumn_LandingPageFragment";
};
export type UrlPathColumn_LandingPageFragment = UrlPathColumn_LandingPageFragment$data;
export type UrlPathColumn_LandingPageFragment$key = {
  readonly " $data"?: UrlPathColumn_LandingPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UrlPathColumn_LandingPageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UrlPathColumn_LandingPageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlParam",
      "storageKey": null
    }
  ],
  "type": "LandingPageV2",
  "abstractKey": null
};

(node as any).hash = "afe68408ed6fea07176e59e0b5a5c10b";

export default node;

import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { StartedAtColumn_ContentSubmissionFragment$key } from "../../../../__generated__/StartedAtColumn_ContentSubmissionFragment.graphql";
import { DateTimeDisplay } from "../../core/datetime/DateTimeDisplay";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment StartedAtColumn_ContentSubmissionFragment on ContentSubmission {
		startedAt
	}
`;

interface OwnProps {
	contentSubmissionFragmentRef: StartedAtColumn_ContentSubmissionFragment$key;
}

export const StartedAtColumn = ({ contentSubmissionFragmentRef }: OwnProps) => {
	const contentSubmission = useFragment<StartedAtColumn_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	return <DateTimeDisplay value={contentSubmission.startedAt} />;
};

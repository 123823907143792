import { graphql } from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { useFragment, useMutation } from "react-relay";
import { DeleteChildNodeButton_DeleteUnpublishedNodePlusDescendantsMutation } from "../../../../../__generated__/DeleteChildNodeButton_DeleteUnpublishedNodePlusDescendantsMutation.graphql";
import { DeleteChildNodeButton_TreeNodeFragment$key } from "../../../../../__generated__/DeleteChildNodeButton_TreeNodeFragment.graphql";

const TREE_NODE_FRAGMENT = graphql`
	fragment DeleteChildNodeButton_TreeNodeFragment on TreeNode {
		id
		hasBeenPublishedOnce
		rootId
	}
`;

const DELETE_UNPUBLISHED_NODE_PLUS_DESCENDANTS_MUTATION = graphql`
	mutation DeleteChildNodeButton_DeleteUnpublishedNodePlusDescendantsMutation(
		$input: DeleteUnpublishedChildNodePlusDescendantsInput!
	) {
		Admin {
			Tree {
				deleteUnpublishedChildNodePlusDescendants(input: $input) {
					tree {
						...TreeEditorScreen_TreeFragment
					}
				}
			}
		}
	}
`;

interface OwnProps {
	treeNodeFragmentRef?: DeleteChildNodeButton_TreeNodeFragment$key | null;
	className?: string;
	onSuccessful: () => void;
}

export const DeleteChildNodeButton = ({
	treeNodeFragmentRef,
	className,
	onSuccessful,
}: OwnProps) => {
	const nodeForDeletion = useFragment<DeleteChildNodeButton_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef || null,
	);

	const [deleteChildNode, isDeletingChildNode] =
		useMutation<DeleteChildNodeButton_DeleteUnpublishedNodePlusDescendantsMutation>(
			DELETE_UNPUBLISHED_NODE_PLUS_DESCENDANTS_MUTATION,
		);

	const tryingToDeleteRootContainer =
		nodeForDeletion && nodeForDeletion.id === nodeForDeletion.rootId;

	let tooltip = "Ordner löschen";
	if (tryingToDeleteRootContainer) {
		tooltip = "Der oberste Ordner kann nicht gelöscht werden.";
	} else if (!nodeForDeletion) {
		tooltip = "Es muss ein Ordner zum löschen ausgewählt werden.";
	} else if (nodeForDeletion.hasBeenPublishedOnce) {
		tooltip = "Bereits veröffentlichte Ordner können nicht gelöscht werden.";
	}

	return (
		<Button
			className={className}
			disabled={
				isDeletingChildNode ||
				!nodeForDeletion ||
				tryingToDeleteRootContainer ||
				nodeForDeletion.hasBeenPublishedOnce
			}
			tooltip={tooltip}
			tooltipOptions={{ showOnDisabled: true }}
			onClick={() =>
				deleteChildNode({
					variables: {
						input: {
							childNodeId: nodeForDeletion!.id,
						},
					},
					onCompleted: () => onSuccessful(),
				})
			}
			icon={"pi pi-trash"}
		/>
	);
};

/**
 * @generated SignedSource<<e6a5799d0c1695294953eed6555b5688>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotAfterDateTimeRewardConfigForm_TreeNodeFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "NotAfterDateTimeRewardConfigForm_TreeNodeFragment";
};
export type NotAfterDateTimeRewardConfigForm_TreeNodeFragment = NotAfterDateTimeRewardConfigForm_TreeNodeFragment$data;
export type NotAfterDateTimeRewardConfigForm_TreeNodeFragment$key = {
  readonly " $data"?: NotAfterDateTimeRewardConfigForm_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotAfterDateTimeRewardConfigForm_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotAfterDateTimeRewardConfigForm_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "02a42923076544e28ee115d9a553975c";

export default node;

import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery, useMutation, usePaginationFragment } from "react-relay";
import { MessageDefinitionOverviewScreen_PaginationFragmentRefetch } from "../../__generated__/MessageDefinitionOverviewScreen_PaginationFragmentRefetch.graphql";
import { CreateMessageDefinitionButton } from "../components/messaging/overview/CreateMessageDefinitionButton";
import { MessageDefinitionTable } from "../components/messaging/overview/MessageDefinitionTable";
import { MessageDefinitionOverviewScreen_Query } from "../../__generated__/MessageDefinitionOverviewScreen_Query.graphql";
import { MessageDefinitionOverviewScreen_PaginationFragment$key } from "../../__generated__/MessageDefinitionOverviewScreen_PaginationFragment.graphql";
import { MessageDefinitionOverviewScreen_CreateMessageDefinitionMutation } from "../../__generated__/MessageDefinitionOverviewScreen_CreateMessageDefinitionMutation.graphql";
import { MessageDefinitionOverviewScreen_DeleteMessageDefinitionMutation } from "../../__generated__/MessageDefinitionOverviewScreen_DeleteMessageDefinitionMutation.graphql";
import { confirmDialog } from "primereact/confirmdialog";
import { MessageDefinitionFilters } from "../components/messaging/overview/MessageDefinitionFilters";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { BaseScreen } from "./BaseScreen";

const NUM_ITEMS_PER_PAGE = 10;

const QUERY = graphql`
	query MessageDefinitionOverviewScreen_Query($numItemsPerPage: Int!) {
		...MessageDefinitionOverviewScreen_PaginationFragment
			@arguments(internalTitle: "", first: $numItemsPerPage, after: null)
	}
`;

const FRAGMENT = graphql`
	fragment MessageDefinitionOverviewScreen_PaginationFragment on Query
	@refetchable(queryName: "MessageDefinitionOverviewScreen_PaginationFragmentRefetch")
	@argumentDefinitions(
		internalTitle: { type: "String!" }
		first: { type: "Int!" }
		after: { type: "String" }
	) {
		Admin {
			Messaging {
				MessageDefinitions(internalTitle: $internalTitle, first: $first, after: $after)
					@connection(key: "MessageDefinitionOverviewScreen_MessageDefinitions") {
					__id
					edges {
						node {
							id
							... on MessageDefinition {
								internalTitle
							}
						}
					}
				}
			}
		}
	}
`;

const CREATE_CUSTOMER_MESSAGE_DEFINITION_MUTATION = graphql`
	mutation MessageDefinitionOverviewScreen_CreateMessageDefinitionMutation(
		$input: CreateMessageDefinitionInput!
		$connections: [ID!]!
	) {
		Admin {
			Messaging {
				createMessageDefinition(input: $input) {
					edge @prependEdge(connections: $connections) {
						node {
							id
							... on MessageDefinition {
								internalTitle
							}
						}
					}
				}
			}
		}
	}
`;

const DELETE_CUSTOMER_MESSAGE_DEFINITION_MUTATION = graphql`
	mutation MessageDefinitionOverviewScreen_DeleteMessageDefinitionMutation(
		$input: DeleteMessageDefinitionInput!
		$connections: [ID!]!
	) {
		Admin {
			Messaging {
				deleteMessageDefinition(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;

export const MessageDefinitionOverviewScreen = () => {
	const navigate = useNavigate();

	const query = useLazyLoadQuery<MessageDefinitionOverviewScreen_Query>(QUERY, {
		numItemsPerPage: NUM_ITEMS_PER_PAGE,
	});

	const {
		data: messageDefinitions,
		hasNext,
		loadNext,
		refetch,
	} = usePaginationFragment<
		MessageDefinitionOverviewScreen_PaginationFragmentRefetch,
		MessageDefinitionOverviewScreen_PaginationFragment$key
	>(FRAGMENT, query);

	const items =
		messageDefinitions.Admin.Messaging.MessageDefinitions.edges
			?.filter((e) => !!e)
			.map((e) => e!.node) || [];

	const [createMessageDefinitionRaw] =
		useMutation<MessageDefinitionOverviewScreen_CreateMessageDefinitionMutation>(
			CREATE_CUSTOMER_MESSAGE_DEFINITION_MUTATION,
		);

	const createMessageDefinition = () =>
		createMessageDefinitionRaw({
			variables: {
				input: {
					internalTitle: "Neue Nachricht",
					pageInternalTitle: "Neue Seite",
				},
				connections: [messageDefinitions.Admin.Messaging.MessageDefinitions.__id],
			},
			onCompleted: (response) => {
				const id = response.Admin.Messaging.createMessageDefinition?.edge.node.id;
				if (id) navigate("/messaging/" + id + "/edit");
			},
		});

	const [deleteMessageDefinitionRaw] =
		useMutation<MessageDefinitionOverviewScreen_DeleteMessageDefinitionMutation>(
			DELETE_CUSTOMER_MESSAGE_DEFINITION_MUTATION,
		);

	const deleteMessageDefinitionWithConfirmation = (internalTitle: string, id: string) =>
		confirmDialog({
			message: "Das Löschen kann nicht rückgängig gemacht werden.",
			header: "Nachrichten-Vorlage löschen",
			icon: "pi pi-exclamation-triangle",
			acceptLabel: "Löschen",
			rejectLabel: "Abbrechen",
			acceptClassName: "p-button-danger",
			accept: () =>
				deleteMessageDefinitionRaw({
					variables: {
						input: {
							ids: [id],
						},
						connections: [messageDefinitions.Admin.Messaging.MessageDefinitions.__id],
					},
				}),
		});

	return (
		<BaseScreen>
			<h1>Nachrichten-Vorlagen</h1>

			<CreateMessageDefinitionButton createMessageDefinition={createMessageDefinition} />

			<MessageDefinitionFilters
				refetch={(internalTitle: string) =>
					refetch({
						internalTitle: internalTitle,
						first: NUM_ITEMS_PER_PAGE,
					})
				}
			/>

			<MessageDefinitionTable
				items={items}
				deleteMessageDefinition={deleteMessageDefinitionWithConfirmation}
			/>
			<div className="flex justify-content-center align-items-center">
				<Button
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={() => loadNext(20)}
				>
					Weiter
				</Button>
			</div>
		</BaseScreen>
	);
};

/**
 * @generated SignedSource<<ecd448b903caf361ac64451ad96fcb19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewEmailTemplateButton_AvailableTemplatesFragment$data = {
  readonly Admin: {
    readonly Email: {
      readonly AvailableSystemTemplates: ReadonlyArray<{
        readonly key: string;
        readonly previewText: string;
        readonly subject: string;
        readonly body: string;
        readonly variables: ReadonlyArray<string>;
      }>;
    };
  };
  readonly " $fragmentType": "NewEmailTemplateButton_AvailableTemplatesFragment";
};
export type NewEmailTemplateButton_AvailableTemplatesFragment = NewEmailTemplateButton_AvailableTemplatesFragment$data;
export type NewEmailTemplateButton_AvailableTemplatesFragment$key = {
  readonly " $data"?: NewEmailTemplateButton_AvailableTemplatesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewEmailTemplateButton_AvailableTemplatesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewEmailTemplateButton_AvailableTemplatesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmailAdminSchema",
          "kind": "LinkedField",
          "name": "Email",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EmailTemplate",
              "kind": "LinkedField",
              "name": "AvailableSystemTemplates",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "key",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "previewText",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subject",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "body",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "variables",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "ed569c51c03a6c121f8650b7ef53b6be";

export default node;

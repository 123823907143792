import { CertificateVariables } from "../__generated__/EditCertificateTemplateScreen_CertificateTemplateFragment.graphql";

export const EXTEND_VARIABLE_MAPPING_FIELD: { label: string; value: CertificateVariables }[] = [
	{ label: "Verfallsdatum", value: "ExpireDate" },
	{ label: "Fachberater Anrede", value: "FachberaterSalutation" },
	{ label: "Vorname", value: "FirstName" },
	{ label: "Nachname", value: "LastName" },
	{ label: "Anrede", value: "Salutation" },
	{ label: "User Personalpronomen", value: "PersonalPronoun" },
	{ label: "Praxis", value: "Company" },
	{ label: "Strasse", value: "Street" },
	{ label: "Postzahl", value: "PostalCode" },
	{ label: "Proposition zu", value: "PrepositionTo" },
	{ label: "Stadt", value: "City" },
	{ label: "Land", value: "Country" },
	{ label: "AnredeSalutation", value: "RecipientSalutation" },
	{ label: "Export Datum", value: "ExportDate" },
	{ label: "Salutation Dativ", value: "SalutationDativ" },
	{ label: "Salutation mit Vorname für Divers", value: "SalutationWithNameForDivers" },
];

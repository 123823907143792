/**
 * @generated SignedSource<<876ec72d9ac57c9cd2078d84da8a3b65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StagingScreenV2_ResetStagingTreesMutation$variables = {};
export type StagingScreenV2_ResetStagingTreesMutationVariables = StagingScreenV2_ResetStagingTreesMutation$variables;
export type StagingScreenV2_ResetStagingTreesMutation$data = {
  readonly Admin: {
    readonly Staging: {
      readonly resetAllStagingData: {
        readonly clientMutationId: string | null;
      } | null;
    };
  };
};
export type StagingScreenV2_ResetStagingTreesMutationResponse = StagingScreenV2_ResetStagingTreesMutation$data;
export type StagingScreenV2_ResetStagingTreesMutation = {
  variables: StagingScreenV2_ResetStagingTreesMutationVariables;
  response: StagingScreenV2_ResetStagingTreesMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StagingAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Staging",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "input",
                "value": {}
              }
            ],
            "concreteType": "ResetAllStagingDataPayload",
            "kind": "LinkedField",
            "name": "resetAllStagingData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": "resetAllStagingData(input:{})"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StagingScreenV2_ResetStagingTreesMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StagingScreenV2_ResetStagingTreesMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "a0f8301e27cff1a68fc401ba88c27a07",
    "id": null,
    "metadata": {},
    "name": "StagingScreenV2_ResetStagingTreesMutation",
    "operationKind": "mutation",
    "text": "mutation StagingScreenV2_ResetStagingTreesMutation {\n  Admin {\n    Staging {\n      resetAllStagingData(input: {}) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "03e81220630cccfd5cca1bb3ddca1d01";

export default node;

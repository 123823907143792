/**
 * @generated SignedSource<<af1ebcc6a4f8fd29a7a41f3d55e29e7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateFachberaterRewardButton_CertificateTemplatesConnectionV2Fragment$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
    };
  } | null> | null;
  readonly " $fragmentType": "CreateFachberaterRewardButton_CertificateTemplatesConnectionV2Fragment";
};
export type CreateFachberaterRewardButton_CertificateTemplatesConnectionV2Fragment = CreateFachberaterRewardButton_CertificateTemplatesConnectionV2Fragment$data;
export type CreateFachberaterRewardButton_CertificateTemplatesConnectionV2Fragment$key = {
  readonly " $data"?: CreateFachberaterRewardButton_CertificateTemplatesConnectionV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateFachberaterRewardButton_CertificateTemplatesConnectionV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateFachberaterRewardButton_CertificateTemplatesConnectionV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CertificateTemplatesEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CertificateTemplate",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CertificateTemplatesConnection",
  "abstractKey": null
};

(node as any).hash = "8cd928d27a8529262497e30d8468fd56";

export default node;

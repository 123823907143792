import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import graphql from "babel-plugin-relay/macro";
import { ConnectionHandler, useMutation, usePaginationFragment } from "react-relay";
import { TagsTable_Refetch } from "../../../__generated__/TagsTable_Refetch.graphql";
import { useNavigate } from "react-router-dom";
import { useDialogLogic } from "../core/dialog/useDialogLogic";
import { TagsTable_QueryFragment$key } from "../../../__generated__/TagsTable_QueryFragment.graphql";
import { TagsTable_DeleteTagMutation } from "../../../__generated__/TagsTable_DeleteTagMutation.graphql";
import { Permission } from "../../../__generated__/MainNavigation_Query.graphql";
import { useSelector } from "react-redux";
import { selectHasPermissions } from "../../redux/slices/CurrentUserSlice";

const QUERY_FRAGMENT = graphql`
	fragment TagsTable_QueryFragment on Query
	@refetchable(queryName: "TagsTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int" }
		after: { type: "String" }
		filterByName: { type: "String" }
	) {
		Admin {
			Tag {
				Tags(first: $first, after: $after, filterByName: $filterByName)
					@connection(key: "TagsTable_Tags") {
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							name
							colorHex
						}
					}
				}
			}
		}
	}
`;

const DELETE_TAG_MUTATION = graphql`
	mutation TagsTable_DeleteTagMutation($input: DeleteTagInput!, $connections: [ID!]!) {
		Admin {
			Tag {
				deleteTag(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;

interface OwnProps {
	queryFragmentRef: TagsTable_QueryFragment$key;
}

export const TagsTable = ({ queryFragmentRef }: OwnProps) => {
	const navigate = useNavigate();

	const {
		data: {
			Admin: {
				Tag: {
					Tags: { edges: tags },
				},
			},
		},
		hasPrevious,
		hasNext,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<TagsTable_Refetch, TagsTable_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);

	const [deleteTag, isDeletingTag] =
		useMutation<TagsTable_DeleteTagMutation>(DELETE_TAG_MUTATION);

	const { showDialog, dialogComponent } = useDialogLogic();

	const checkPermissions = (permissions: Permission[]) =>
		useSelector((state: any) => selectHasPermissions(state, permissions));

	const hasPermissions =
		checkPermissions([
			"AccountPermission_System_Root",
			"UserInAccountPermission_System_Owner",
		]) ||
		checkPermissions([
			"AccountPermission_System_Root",
			"UserInAccountPermission_Tags_UpdateTags",
		]);

	return (
		<>
			{dialogComponent}
			{hasPermissions && (
				<div className="flex justify-content-end mb-3">
					<Button onClick={() => navigate("/tags/new/edit")}>Neues Schlagwort</Button>
				</div>
			)}
			<DataTable
				emptyMessage="Keine Schlagworte"
				className="mb-3"
				value={tags?.map((b) => b!.node!) as any[]}
			>
				<Column
					header="Name"
					body={(row) => <span style={{ color: row.tagColorHex }}>{row.name}</span>}
				/>
				{hasPermissions && (
					<Column
						header="Aktionen"
						style={{ width: "20%" }}
						body={(item) => (
							<>
								<Button
									className="mr-2"
									onClick={() => {
										navigate(`/tags/${item.id}/edit`);
									}}
									icon={"pi pi-pencil"}
								/>

								<Button
									disabled={isDeletingTag}
									onClick={() => {
										showDialog({
											title: "Schlagwort löschen",
											content:
												"Möchten Sie dieses Schlagwort wirklich löschen? Das kann nicht rückgängig gemacht werden.",
											dialogCallback: (result) => {
												if (result === "Accept") {
													deleteTag({
														variables: {
															input: {
																ids: [item.id],
															},
															connections: [
																ConnectionHandler.getConnectionID(
																	"client:root:Admin:Tag",
																	"TagsTable_Tags",
																),
															],
														},
													});
												}
											},
										});
									}}
									icon={"pi pi-trash"}
								/>
							</>
						)}
					/>
				)}
			</DataTable>
			<div className="flex justify-content-center align-items-center">
				<Button
					disabled={!hasPrevious}
					onClick={() => loadPrevious(20)}
					className="mr-3 p-button-secondary"
				>
					Zurück
				</Button>
				<Button
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={() => loadNext(20)}
				>
					Weiter
				</Button>
			</div>
		</>
	);
};

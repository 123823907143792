import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { AmountColumn_PointsAdditionFragment$key } from "../../../../__generated__/AmountColumn_PointsAdditionFragment.graphql";

const POINTS_ADDITION_FRAGMENT = graphql`
	fragment AmountColumn_PointsAdditionFragment on PointsAdditionV2 {
		amount
	}
`;

interface OwnProps {
	pointsAdditionFragmentRef: AmountColumn_PointsAdditionFragment$key;
}

export const AmountColumn = ({ pointsAdditionFragmentRef }: OwnProps) => {
	const pointsAddition = useFragment<AmountColumn_PointsAdditionFragment$key>(
		POINTS_ADDITION_FRAGMENT,
		pointsAdditionFragmentRef,
	);

	return <div>{pointsAddition.amount}</div>;
};

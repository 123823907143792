/**
 * @generated SignedSource<<cbad7c695b9d33bc155797ad4aa6e640>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessageDefinitionOverviewScreen_PaginationFragmentRefetch$variables = {
  after?: string | null;
  first: number;
  internalTitle: string;
};
export type MessageDefinitionOverviewScreen_PaginationFragmentRefetchVariables = MessageDefinitionOverviewScreen_PaginationFragmentRefetch$variables;
export type MessageDefinitionOverviewScreen_PaginationFragmentRefetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MessageDefinitionOverviewScreen_PaginationFragment">;
};
export type MessageDefinitionOverviewScreen_PaginationFragmentRefetchResponse = MessageDefinitionOverviewScreen_PaginationFragmentRefetch$data;
export type MessageDefinitionOverviewScreen_PaginationFragmentRefetch = {
  variables: MessageDefinitionOverviewScreen_PaginationFragmentRefetchVariables;
  response: MessageDefinitionOverviewScreen_PaginationFragmentRefetch$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "internalTitle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "internalTitle",
    "variableName": "internalTitle"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageDefinitionOverviewScreen_PaginationFragmentRefetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "MessageDefinitionOverviewScreen_PaginationFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MessageDefinitionOverviewScreen_PaginationFragmentRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessagingAdminSchema",
            "kind": "LinkedField",
            "name": "Messaging",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "MessageDefinitionsConnection",
                "kind": "LinkedField",
                "name": "MessageDefinitions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessageDefinitionsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MessageDefinition",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "internalTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "internalTitle"
                ],
                "handle": "connection",
                "key": "MessageDefinitionOverviewScreen_MessageDefinitions",
                "kind": "LinkedHandle",
                "name": "MessageDefinitions"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "50fb333ee9ed604f5753d733f54feb52",
    "id": null,
    "metadata": {},
    "name": "MessageDefinitionOverviewScreen_PaginationFragmentRefetch",
    "operationKind": "query",
    "text": "query MessageDefinitionOverviewScreen_PaginationFragmentRefetch(\n  $after: String\n  $first: Int!\n  $internalTitle: String!\n) {\n  ...MessageDefinitionOverviewScreen_PaginationFragment_aDe78\n}\n\nfragment MessageDefinitionOverviewScreen_PaginationFragment_aDe78 on Query {\n  Admin {\n    Messaging {\n      MessageDefinitions(internalTitle: $internalTitle, first: $first, after: $after) {\n        edges {\n          node {\n            id\n            internalTitle\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f85f409299f3ad6f0b9a67992f547ca";

export default node;

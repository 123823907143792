import { graphql } from "babel-plugin-relay/macro";
import { Card } from "primereact/card";
import React, { useState } from "react";
import {
	RestartIfFailedContentConfigEditor_TreeNodeFragment$key,
	RestartIfFailedContentConfigType,
} from "../../../../../../../__generated__/RestartIfFailedContentConfigEditor_TreeNodeFragment.graphql";
import { useFragment, useMutation } from "react-relay";
import {
	AddBlockTemporarilyRestartIfFailedContentConfigInput,
	RestartIfFailedContentConfigEditor_AddBlockTemporarilyRestartIfFailedContentConfigMutation,
} from "../../../../../../../__generated__/RestartIfFailedContentConfigEditor_AddBlockTemporarilyRestartIfFailedContentConfigMutation.graphql";
import { RestartIfFailedContentConfigEditor_RemoveNonLogicalRestartIfFailedContentConfigMutation } from "../../../../../../../__generated__/RestartIfFailedContentConfigEditor_RemoveNonLogicalRestartIfFailedContentConfigMutation.graphql";
import {
	AddBlockPermanentlyRestartIfFailedContentConfigInput,
	RestartIfFailedContentConfigEditor_AddBlockPermanentlyRestartIfFailedContentConfigMutation,
} from "../../../../../../../__generated__/RestartIfFailedContentConfigEditor_AddBlockPermanentlyRestartIfFailedContentConfigMutation.graphql";
import {
	AddCanNotRestartIfFailedContentConfigInput,
	RestartIfFailedContentConfigEditor_AddCanNotRestartIfFailedContentConfigMutation,
} from "../../../../../../../__generated__/RestartIfFailedContentConfigEditor_AddCanNotRestartIfFailedContentConfigMutation.graphql";
import {
	Config,
	ConfigCustomLabel,
	ConfigurationGraphqlInterface,
} from "../components/Config.interfaces";
import { ConfigDropdown, ConfigDropdownOptions } from "../components/ConfigDropdown";
import { ConfigPreview } from "../components/ConfigPreview";
import { ConfigItem } from "../components/ConfigItem";
import { BlockTemporarilyRestartIfFailedContentConfigForm } from "./restartIfFailed/BlockTemporarilyRestartIfFailedContentConfigForm";
import { BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment$key } from "../../../../../../../__generated__/BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment.graphql";
import { BlockPermanentlyRestartIfFailedContentConfigForm } from "./restartIfFailed/BlockPermanentlyRestartIfFailedContentConfigForm";
import { BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment$key } from "../../../../../../../__generated__/BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment.graphql";
import {
	ExtendRestartIfFailedConfigs,
	ProjectExtendRestartIfFailedContentConfigEditor,
} from "../../../../../../../lms-admin-impl/components/relay/ProjectExtendRestartIfFailedContentConfigEditor";

const TREE_NODE_FRAGMENT = graphql`
	fragment RestartIfFailedContentConfigEditor_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ContentTypeDefinition {
				restartIfFailedConfigs {
					id
					configType
					... on BlockTemporarilyRestartIfFailedContentConfig {
						daysToBlock
					}
					... on BlockPermanentlyRestartIfFailedContentConfig {
						maximumTries
					}
					...BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment
					...BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment
					...ProjectExtendRestartIfFailedContentConfigEditor_RestartIfFailedContentConfigFragment
				}
			}
		}
		...BlockTemporarilyRestartIfFailedContentConfigForm_TreeNodeFragment
		...BlockPermanentlyRestartIfFailedContentConfigForm_TreeNodeFragment
		...ProjectExtendRestartIfFailedContentConfigEditor_TreeNodeFragment
	}
`;

const ADD_CAN_NOT_RESTART_IF_FAILED_CONTENT_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation RestartIfFailedContentConfigEditor_AddCanNotRestartIfFailedContentConfigMutation(
		$input: AddCanNotRestartIfFailedContentConfigInput!
	) {
		Admin {
			Tree {
				addCanNotRestartIfFailedContentConfig(input: $input) {
					contentNode {
						...RestartIfFailedContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const ADD_BLOCK_TEMPORARILY_RESTART_IF_FAILED_CONTENT_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation RestartIfFailedContentConfigEditor_AddBlockTemporarilyRestartIfFailedContentConfigMutation(
		$input: AddBlockTemporarilyRestartIfFailedContentConfigInput!
	) {
		Admin {
			Tree {
				addBlockTemporarilyRestartIfFailedContentConfig(input: $input) {
					contentNode {
						...RestartIfFailedContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const ADD_BLOCK_PERMANENTLY_RESTART_IF_FAILED_CONTENT_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation RestartIfFailedContentConfigEditor_AddBlockPermanentlyRestartIfFailedContentConfigMutation(
		$input: AddBlockPermanentlyRestartIfFailedContentConfigInput!
	) {
		Admin {
			Tree {
				addBlockPermanentlyRestartIfFailedContentConfig(input: $input) {
					contentNode {
						...RestartIfFailedContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const REMOVE_RESTART_IF_FAILED_CONTENT_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation RestartIfFailedContentConfigEditor_RemoveNonLogicalRestartIfFailedContentConfigMutation(
		$input: RemoveRestartIfFailedContentConfigInput!
	) {
		Admin {
			Tree {
				removeRestartIfFailedContentConfig(input: $input) {
					contentNode {
						...RestartIfFailedContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

interface Props {
	treeNodeFragmentRef: RestartIfFailedContentConfigEditor_TreeNodeFragment$key;
}

export const RestartIfFailedContentConfigEditor = ({ treeNodeFragmentRef }: Props) => {
	const treeNode = useFragment<RestartIfFailedContentConfigEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const [
		removeNonLogicalRestartIfFailedContentConfig,
		isRemovingNonLogicalRestartIfFailedContentConfig,
	] = useMutation<RestartIfFailedContentConfigEditor_RemoveNonLogicalRestartIfFailedContentConfigMutation>(
		REMOVE_RESTART_IF_FAILED_CONTENT_CONDITION_CONFIGURATION_MUTATION,
	);
	const [addCanNotRestartIfFAiledRestartIfFailedCConfig] =
		useMutation<RestartIfFailedContentConfigEditor_AddCanNotRestartIfFailedContentConfigMutation>(
			ADD_CAN_NOT_RESTART_IF_FAILED_CONTENT_CONDITION_CONFIGURATION_MUTATION,
		);
	const [addBlockTemorarilyRestartIfFailedCConfig] =
		useMutation<RestartIfFailedContentConfigEditor_AddBlockTemporarilyRestartIfFailedContentConfigMutation>(
			ADD_BLOCK_TEMPORARILY_RESTART_IF_FAILED_CONTENT_CONDITION_CONFIGURATION_MUTATION,
		);
	const [addBlockPermanentlyRestartIfFailedContentConfig] =
		useMutation<RestartIfFailedContentConfigEditor_AddBlockPermanentlyRestartIfFailedContentConfigMutation>(
			ADD_BLOCK_PERMANENTLY_RESTART_IF_FAILED_CONTENT_CONDITION_CONFIGURATION_MUTATION,
		);

	const canNotRestartIfFailed: Config<
		RestartIfFailedContentConfigType,
		AddCanNotRestartIfFailedContentConfigInput
	> = {
		configKey: "RestartIfFailedContent_CanNot",
		addMutation: (input: AddCanNotRestartIfFailedContentConfigInput) => {
			addCanNotRestartIfFAiledRestartIfFailedCConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const blockTemporarly: Config<
		RestartIfFailedContentConfigType,
		AddBlockTemporarilyRestartIfFailedContentConfigInput
	> = {
		configKey: "RestartIfFailedContent_BlockTemporarily",
		addMutation: (input: AddBlockTemporarilyRestartIfFailedContentConfigInput) => {
			addBlockTemorarilyRestartIfFailedCConfig({
				variables: {
					input,
				},
			});
		},
		addMutationPayload: {
			daysToBlock: 30,
		},
		editable: true,
	};
	const blockPermanently: Config<
		RestartIfFailedContentConfigType,
		AddBlockPermanentlyRestartIfFailedContentConfigInput
	> = {
		configKey: "RestartIfFailedContent_BlockPermanently",
		addMutation: (input: AddBlockPermanentlyRestartIfFailedContentConfigInput) => {
			addBlockPermanentlyRestartIfFailedContentConfig({
				variables: {
					input,
				},
			});
		},
		addMutationPayload: {
			maximumTries: 3,
		},
		editable: true,
	};

	const configs: Config<RestartIfFailedContentConfigType, any | never>[] = [
		canNotRestartIfFailed,
		blockTemporarly,
		blockPermanently,
		...ExtendRestartIfFailedConfigs,
	];

	const [previewOptions, setPreviewOptions] = useState<
		ConfigDropdownOptions<RestartIfFailedContentConfigType>[]
	>([]);

	let labels: ConfigCustomLabel<RestartIfFailedContentConfigType>[];

	const options: ConfigDropdownOptions<RestartIfFailedContentConfigType>[] = configs.map((c) => {
		return {
			label: c.configKey,
			value: c.configKey,
		};
	});

	const setLabels = (customLabels: ConfigCustomLabel<RestartIfFailedContentConfigType>[]) => {
		labels = customLabels;

		setPreviewOptions(
			configs.map((c) => {
				const selectedConfig = treeNode.typeDefinition.restartIfFailedConfigs?.find(
					(r: any) => r.configType === c.configKey,
				);
				const customLabel = labels.find((l) => l.configKey === c.configKey);

				if (selectedConfig && customLabel?.label) {
					return {
						label: `(${customLabel?.label})`,
						value: c.configKey,
					};
				} else if (selectedConfig && selectedConfig.daysToBlock) {
					return {
						label: `(${selectedConfig?.daysToBlock})`,
						value: c.configKey,
					};
				} else if (selectedConfig && selectedConfig.maximumTries) {
					return {
						label: `(${selectedConfig?.maximumTries})`,
						value: c.configKey,
					};
				} else {
					return {
						label: c.configKey,
						value: c.configKey,
					};
				}
			}),
		);
	};

	return (
		<Card className="mb-2">
			<h2>Wiederholen falls noch nicht bestanden</h2>

			<ConfigDropdown<RestartIfFailedContentConfigType>
				configOptions={options}
				onChange={(e) => {
					const selectedConfig = configs.find((c) => c.configKey === e.value);

					if (selectedConfig) {
						selectedConfig.addMutation({
							contentNodeId: treeNode.id,
							...selectedConfig.addMutationPayload,
						});
					}
				}}
				isPresentational={false}
			/>

			<ConfigPreview<ConfigurationGraphqlInterface<RestartIfFailedContentConfigType>>
				selectedConfigs={treeNode.typeDefinition.restartIfFailedConfigs as any[]}
				template={(
					configuration: ConfigurationGraphqlInterface<RestartIfFailedContentConfigType>,
				) => (
					<>
						<ProjectExtendRestartIfFailedContentConfigEditor
							treeNodeFragmentRef={treeNode}
							// @ts-ignore
							configurationFragmentRef={configuration}
							setCustomLabels={(customLabels: any) => setLabels(customLabels)}
						/>
						<ConfigItem<RestartIfFailedContentConfigType, string>
							isPresentational={true}
							isLoading={isRemovingNonLogicalRestartIfFailedContentConfig}
							configType={configuration.configType}
							canEdit={
								configs.find((c) => c.configKey === configuration.configType)
									?.editable as boolean
							}
							configOptions={previewOptions}
							onDelete={() => {
								removeNonLogicalRestartIfFailedContentConfig({
									variables: {
										input: {
											contentNodeId: treeNode.id,
											configId: configuration.id,
										},
									},
								});
							}}
							editDialog={(props: any) => (
								<>
									{configuration.configType ===
										"RestartIfFailedContent_BlockTemporarily" && (
										<BlockTemporarilyRestartIfFailedContentConfigForm
											treeNodeFragmentRef={treeNode}
											configurationFragmentRef={
												configuration as unknown as BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment$key
											}
										/>
									)}
									{configuration.configType ===
										"RestartIfFailedContent_BlockPermanently" && (
										<BlockPermanentlyRestartIfFailedContentConfigForm
											treeNodeFragmentRef={treeNode}
											configurationFragmentRef={
												configuration as unknown as BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment$key
											}
										/>
									)}
									<ProjectExtendRestartIfFailedContentConfigEditor
										{...props}
										treeNodeFragmentRef={treeNode}
										configurationFragmentRef={configuration}
									/>
								</>
							)}
						/>
					</>
				)}
			/>
		</Card>
	);
};

import { useFragment, useLazyLoadQuery } from "react-relay";
import { useMatch } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import {
	FEEDBACK_ID_URL_PARAM,
	getSingleFeedbackV2Path,
} from "../../app/routes/FeedbackModuleRoutes";
import { SingleFeedbackScreenV2_Query } from "../../__generated__/SingleFeedbackScreenV2_Query.graphql";
import { SingleFeedbackScreenV2_FeedbackFragment$key } from "../../__generated__/SingleFeedbackScreenV2_FeedbackFragment.graphql";
import { BaseScreen } from "./BaseScreen";
import { DateTimeDisplay } from "../components/core/datetime/DateTimeDisplay";
import { ProjectUserDescription } from "../../lms-admin-impl/components/relay/ProjectUserDescription";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../lms-admin-impl/i18n/tree.i18n";
import { useSelector } from "react-redux";
import { selectHasPermissions } from "../redux/slices/CurrentUserSlice";
import { Permission } from "../../__generated__/MainNavigation_Query.graphql";

const QUERY = graphql`
	query SingleFeedbackScreenV2_Query($id: ID!) {
		node(id: $id) {
			... on FeedbackV2 {
				...SingleFeedbackScreenV2_FeedbackFragment
			}
		}
	}
`;

const FEEDBACK_FRAGMENT = graphql`
	fragment SingleFeedbackScreenV2_FeedbackFragment on FeedbackV2 {
		id
		createdAt
		screen
		body
		learnOpportunity {
			structureDefinition {
				title
			}
		}
		user {
			...ProjectUserDescription_UserFragment
			email
		}
	}
`;

export const SingleFeedbackScreenV2 = () => {
	const match = useMatch(getSingleFeedbackV2Path(FEEDBACK_ID_URL_PARAM));
	const query = useLazyLoadQuery<SingleFeedbackScreenV2_Query>(QUERY, {
		id: match?.params.feedbackId!,
	});

	const feedback = useFragment<SingleFeedbackScreenV2_FeedbackFragment$key>(
		FEEDBACK_FRAGMENT,
		query.node,
	);

	const checkPermission = (permissions: Permission[]) =>
		useSelector((state: any) => selectHasPermissions(state, permissions));

	const isAdmin = checkPermission([
		"AccountPermission_System_Root",
		"UserInAccountPermission_System_Owner",
	]);

	if (!isAdmin) {
		return <div>Kein Zugriff</div>;
	}

	return feedback ? (
		<BaseScreen>
			<h1 className="mb-3">Feedback</h1>

			<Card
				className="mb-4"
				header={
					<div className="p-2">
						<h3 className="flex align-items-center m-0">Daten</h3>
					</div>
				}
				footer={
					<div className="flex justify-content-end">
						<Button
							label="Antworten"
							onClick={() =>
								(window.location.href = `mailto:${feedback?.user?.email}`)
							}
							icon="pi pi-send"
							iconPos="right"
						/>
					</div>
				}
			>
				<div className="p-fluid">
					<div className="field grid">
						<label className="col-fixed font-bold" style={{ width: 150 }}>
							Erstellt
						</label>
						<div className="col">
							<DateTimeDisplay value={feedback.createdAt} />
						</div>
					</div>
					<div className="field grid">
						<label className="col-fixed font-bold" style={{ width: 150 }}>
							Benutzer
						</label>
						<div className="col">
							<ProjectUserDescription userFragmentRef={feedback.user} />
						</div>
					</div>
					<div className="field grid">
						<label className="col-fixed font-bold" style={{ width: 150 }}>
							{TREE_I18N_MAP(TREE_I18N_KEY.node)}
						</label>
						<div className="col">
							{feedback.learnOpportunity?.structureDefinition.title ||
								`Kein ${TREE_I18N_MAP(TREE_I18N_KEY.node)}`}
						</div>
					</div>
					<div className="field grid">
						<label className="col-fixed font-bold" style={{ width: 150 }}>
							Bildschirm
						</label>
						<div className="col">{feedback.screen || "Kein Bildschirm"}</div>
					</div>
					<div className="field grid">
						<label className="col-fixed font-bold" style={{ width: 150 }}>
							Text
						</label>
						<div className="col">{feedback.body}</div>
					</div>
				</div>
			</Card>
		</BaseScreen>
	) : null;
};

import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery, useMutation, usePaginationFragment } from "react-relay";
import { confirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { BaseScreen } from "../../infecto-lms-admin/screens/BaseScreen";
import { CreateNewsTickerDefinitionButton } from "../components/newsticker/CreateNewsTickerDefinitionButton";
import { NewsTickerDefinitionTable } from "../components/newsticker/NewsTickerDefinitionTable";
import { NewsTickerDefinitionOverviewScreen_PaginationFragmentRefetch } from "../../__generated__/NewsTickerDefinitionOverviewScreen_PaginationFragmentRefetch.graphql";
import { NewsTickerDefinitionOverviewScreen_Query } from "../../__generated__/NewsTickerDefinitionOverviewScreen_Query.graphql";
import { NewsTickerDefinitionOverviewScreen_DeleteNewsTickerDefinitionMutation } from "../../__generated__/NewsTickerDefinitionOverviewScreen_DeleteNewsTickerDefinitionMutation.graphql";
import { NewsTickerDefinitionOverviewScreen_PaginationFragment$key } from "../../__generated__/NewsTickerDefinitionOverviewScreen_PaginationFragment.graphql";
import { NewsTickerDefinitionOverviewScreen_CreateNewsTickerDefinitionMutation } from "../../__generated__/NewsTickerDefinitionOverviewScreen_CreateNewsTickerDefinitionMutation.graphql";

const NUM_ITEMS_PER_PAGE = 10;

const QUERY = graphql`
	query NewsTickerDefinitionOverviewScreen_Query($numItemsPerPage: Int!) {
		...NewsTickerDefinitionOverviewScreen_PaginationFragment
			@arguments(first: $numItemsPerPage, after: null)
	}
`;

const FRAGMENT = graphql`
	fragment NewsTickerDefinitionOverviewScreen_PaginationFragment on Query
	@refetchable(queryName: "NewsTickerDefinitionOverviewScreen_PaginationFragmentRefetch")
	@argumentDefinitions(first: { type: "Int!" }, after: { type: "String" }) {
		Admin {
			Newsticker {
				NewstickerMessages(first: $first, after: $after)
					@connection(key: "NewsTickerDefinitionOverviewScreen_NewstickerMessages") {
					__id
					edges {
						node {
							id
							... on NewstickerMessage {
								id
								text
								forwardingUrl
								isActive
							}
						}
					}
				}
			}
		}
	}
`;

const CREATE_NEWSTICKER_DEFINITION_MUTATION = graphql`
	mutation NewsTickerDefinitionOverviewScreen_CreateNewsTickerDefinitionMutation(
		$input: CreateNewstickerMessageInput!
	) {
		Admin {
			Newsticker {
				createNewstickerMessage(input: $input) {
					newstickerMessage {
						id
						text
						forwardingUrl
						isActive
					}
				}
			}
		}
	}
`;

const DELETE_NEWSTICKER_DEFINITION_MUTATION = graphql`
	mutation NewsTickerDefinitionOverviewScreen_DeleteNewsTickerDefinitionMutation(
		$input: DeleteNewstickerMessageInput!
		$connections: [ID!]!
	) {
		Admin {
			Newsticker {
				deleteNewstickerMessage(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;

export const NewsTickerDefinitionOverviewScreen = () => {
	const navigate = useNavigate();

	const query = useLazyLoadQuery<NewsTickerDefinitionOverviewScreen_Query>(
		QUERY,
		{
			numItemsPerPage: NUM_ITEMS_PER_PAGE,
		},
		{ fetchPolicy: "network-only" },
	);

	const {
		data: messageDefinitions,
		hasNext,
		loadNext,
	} = usePaginationFragment<
		NewsTickerDefinitionOverviewScreen_PaginationFragmentRefetch,
		NewsTickerDefinitionOverviewScreen_PaginationFragment$key
	>(FRAGMENT, query);

	const items =
		messageDefinitions.Admin.Newsticker.NewstickerMessages.edges
			?.filter((e: any) => !!e)
			.map((e: any) => e!.node) || [];

	const [createMessageDefinitionRaw] =
		useMutation<NewsTickerDefinitionOverviewScreen_CreateNewsTickerDefinitionMutation>(
			CREATE_NEWSTICKER_DEFINITION_MUTATION,
		);

	const createMessageDefinition = () =>
		createMessageDefinitionRaw({
			variables: {
				input: {
					text: "Newsticker Inhalt",
					forwardingUrl: "",
				},
			},
			onCompleted: (response) => {
				const id = response.Admin.Newsticker.createNewstickerMessage?.newstickerMessage.id;
				if (id) navigate("/newsticker/" + id + "/edit");
			},
		});

	const [deleteMessageDefinitionRaw] =
		useMutation<NewsTickerDefinitionOverviewScreen_DeleteNewsTickerDefinitionMutation>(
			DELETE_NEWSTICKER_DEFINITION_MUTATION,
		);

	const deleteMessageDefinitionWithConfirmation = (id: string) =>
		confirmDialog({
			message: "Das Löschen kann nicht rückgängig gemacht werden.",
			header: "Nachrichten-Vorlage löschen",
			icon: "pi pi-exclamation-triangle",
			acceptLabel: "Löschen",
			rejectLabel: "Abbrechen",
			acceptClassName: "p-button-danger",
			accept: () =>
				deleteMessageDefinitionRaw({
					variables: {
						input: {
							ids: [id],
						},
						connections: [messageDefinitions.Admin.Newsticker.NewstickerMessages.__id],
					},
				}),
		});

	return (
		<BaseScreen>
			<h1>Newsticker-Nachrichten</h1>

			<CreateNewsTickerDefinitionButton
				createNewsTickerDefinition={createMessageDefinition}
			/>

			{/*<NewsTickerDefinitionFilters
				refetch={() =>
					refetch({
						first: NUM_ITEMS_PER_PAGE,
					})
				}
			/>*/}

			<NewsTickerDefinitionTable
				items={items}
				deleteMessageDefinition={deleteMessageDefinitionWithConfirmation}
			/>

			<div className="flex justify-content-center mt-5 align-items-center">
				<Button
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={() => loadNext(20)}
				>
					Weiter
				</Button>
			</div>
		</BaseScreen>
	);
};

/**
 * @generated SignedSource<<82a37f409c3aa41363f0a671293bd9ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditNotificationTemplateScreen_Query$variables = {
  id: string;
};
export type EditNotificationTemplateScreen_QueryVariables = EditNotificationTemplateScreen_Query$variables;
export type EditNotificationTemplateScreen_Query$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"EditNotificationTemplateScreen_NotificationTemplateFragment">;
  } | null;
};
export type EditNotificationTemplateScreen_QueryResponse = EditNotificationTemplateScreen_Query$data;
export type EditNotificationTemplateScreen_Query = {
  variables: EditNotificationTemplateScreen_QueryVariables;
  response: EditNotificationTemplateScreen_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditNotificationTemplateScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditNotificationTemplateScreen_NotificationTemplateFragment"
              }
            ],
            "type": "PersonalizedNotificationTemplate",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditNotificationTemplateScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NotificationTemplate",
                "kind": "LinkedField",
                "name": "template",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "variables",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "PersonalizedNotificationTemplate",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bbc3aeb00628a8fb3c13fa28f1d48270",
    "id": null,
    "metadata": {},
    "name": "EditNotificationTemplateScreen_Query",
    "operationKind": "query",
    "text": "query EditNotificationTemplateScreen_Query(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on PersonalizedNotificationTemplate {\n      ...EditNotificationTemplateScreen_NotificationTemplateFragment\n    }\n    id\n  }\n}\n\nfragment EditNotificationTemplateScreen_NotificationTemplateFragment on PersonalizedNotificationTemplate {\n  id\n  template {\n    key\n    title\n    message\n    variables\n  }\n}\n"
  }
};
})();

(node as any).hash = "50ed59295169eb953daeb01ac907c556";

export default node;

import { Button } from "primereact/button";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import {
	CreateTreeForm,
	CreateTreeFormState,
} from "../../../app/components/tree/tree/CreateTreeForm";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getTreeEditorPath } from "../../../app/routes/GraphEditorRoutes";
import { ProjectCloneTreeButton_CloneTreeUsingAPredefinedTemplateAdminMutation } from "../../../__generated__/ProjectCloneTreeButton_CloneTreeUsingAPredefinedTemplateAdminMutation.graphql";

const CLONE_TREE_MUTATION = graphql`
	mutation ProjectCloneTreeButton_CloneTreeUsingAPredefinedTemplateAdminMutation(
		$input: CloneTreeUsingAPredefinedTemplateInput!
		$connections: [ID!]!
	) {
		Admin {
			Tree {
				cloneTreeUsingAPredefinedTemplate(input: $input) {
					clonedRootNode @appendEdge(connections: $connections) {
						node {
							id
							...RootNodesTable_TreeNodeFragment
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	rootNodeId: string;
	rootNodesConnectionId: string;
	className?: string;
}

export const ProjectCloneTreeButton = ({
	rootNodeId,
	rootNodesConnectionId,
	className,
}: OwnProps) => {
	const [cloneTree, isCloningTree] =
		useMutation<ProjectCloneTreeButton_CloneTreeUsingAPredefinedTemplateAdminMutation>(
			CLONE_TREE_MUTATION,
		);

	const navigate = useNavigate();
	const [displayMaximizable, setDisplayMaximizable] = useState(false);

	return (
		<>
			<Button
				label={""}
				className={className}
				disabled={isCloningTree}
				icon={"pi pi-copy"}
				tooltip={"Duplizieren"}
				onClick={() => setDisplayMaximizable(true)}
			/>

			<CreateTreeForm
				type={"clone"}
				displayMaximizable={displayMaximizable}
				setDisplayMaximizable={setDisplayMaximizable}
				onSubmitCallback={(formValues: CreateTreeFormState) => {
					cloneTree({
						variables: {
							input: {
								rootNodeId: rootNodeId,
								title: formValues.title,
							},
							connections: [rootNodesConnectionId],
						},
						onCompleted: (response) => {
							navigate(
								getTreeEditorPath(
									response.Admin.Tree.cloneTreeUsingAPredefinedTemplate
										?.clonedRootNode.node.id || "",
								),
							);
						},
					});
				}}
			/>
		</>
	);
};

/**
 * @generated SignedSource<<096ede1221c7079457e2748f2f8583f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SpecificUserStatusesCanStartContentConfigForm_TreeNodeFragment$data = {
  readonly id: string;
  readonly typeDefinition: {
    readonly startConfigs?: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"SpecificUserStatusesCanStartContentConfigForm_StartContentConfigFragment">;
    }>;
  };
  readonly " $fragmentType": "SpecificUserStatusesCanStartContentConfigForm_TreeNodeFragment";
};
export type SpecificUserStatusesCanStartContentConfigForm_TreeNodeFragment = SpecificUserStatusesCanStartContentConfigForm_TreeNodeFragment$data;
export type SpecificUserStatusesCanStartContentConfigForm_TreeNodeFragment$key = {
  readonly " $data"?: SpecificUserStatusesCanStartContentConfigForm_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SpecificUserStatusesCanStartContentConfigForm_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpecificUserStatusesCanStartContentConfigForm_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "startConfigs",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SpecificUserStatusesCanStartContentConfigForm_StartContentConfigFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "8569d219e3e5c6bb39d0bd963fd93a63";

export default node;
